import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { memo } from "react";

interface Props {
  isLoading?: boolean;
  textGenerate: string;
}

const StatusGenerateData = (props: Props) => {
  const { isLoading, textGenerate } = props;

  return (
    <Flex alignItems="center" gap="5px">
      {isLoading ? (
        <Spinner width="1rem" height="1rem"></Spinner>
      ) : (
        <Box className="checkmark" width="1rem" height="1rem" />
      )}
      <Text fontWeight="600" color="rgba(0,0,0,0.7)">
        {textGenerate}
      </Text>
    </Flex>
  );
};

export default memo(StatusGenerateData);

import { documentCategoryApi } from "apiClient/v2";
import { OPTION_ALL_FLOOR } from "constants/area";
import { DocumentTemplateType } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level } from "interfaces/models";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentSubCategory } from "interfaces/models/documentCategory";
import { DocumentGroup } from "interfaces/models/documentGroup";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { sortArrayByField } from "utils/array";
import { uuid } from "utils/common";
import { sortByTitle } from "utils/document";
import { getStatusVisibleOfItem } from "utils/documentItem";
import { arrayToObject } from "utils/object";
import { transformBodyForCombineData } from "utils/offline";
import { isDefaultSearchDocument } from "utils/search";
import { getAllCells, getCellPositionByCellId } from "utils/tableCell";
import {
  FilterDocumentCategoryByUserSettingProps,
  transformDocumentCategoryByUserSetting,
} from "./document";

export const sortDocumentCategories = (
  savedCategories: DocumentCategoryDTO[]
) => {
  const result = cloneDeep(savedCategories);
  result.sort((cat1, cat2) => {
    const title1 = cat1.title || "";
    const title2 = cat2.title || "";
    const level1 = cat1.level || "";
    const level2: string = cat2.level || "";

    return title1 > title2 || (title1 === title2 && level1 > level2) ? 1 : -1;
  });

  return result;
};

export const getItemBelongDocs = (
  category: DocumentCategoryDTO,
  docItems: DocumentItemDTO[]
) => {
  let categoryItems = [];
  const isSelfInspection = isSelfInspectionTemplate(category.documentType);
  const isAllFloor =
    category?.allFloor &&
    category?.level &&
    category?.allFloor === category.level;
  const isNoArea =
    category?.noArea && category?.level && category?.noArea === category.level;
  const mapDocumentItemCommon = (item: DocumentItemDTO) =>
    item.templateId === category?.templateId &&
    item.level === category?.level &&
    (!isSelfInspection || category.id === item.documentCategoryId);

  if (isAllFloor) {
    categoryItems = docItems.filter((item) => {
      return mapDocumentItemCommon(item) && !item.parentId;
    });
  } else if (isNoArea) {
    categoryItems = docItems.filter((item) => {
      return (
        mapDocumentItemCommon(item) && !item.parentId && !item?.areaIds?.length
      );
    });
  } else {
    categoryItems = docItems.filter((item) => {
      return (
        mapDocumentItemCommon(item) &&
        (isSelfInspection ||
          (!isSelfInspection &&
            category?.neptuneAreaIds?.length &&
            item.areaIds?.some((r) =>
              category?.neptuneAreaIds?.includes(r)
            ))) &&
        !item.parentId
      );
    });
  }

  return categoryItems;
};

export const doMapDocumentCategory = ({
  category,
  documentItems: _documentItems,
  template,
}: {
  category: DocumentCategoryDTO;
  documentItems: DocumentItemDTO[];
  template: DocumentTemplateDTO | undefined;
}) => {
  const isSelfInspection = isSelfInspectionTemplate(category.documentType);
  let documentItems = structuredClone(
    getItemBelongDocs(category, _documentItems)
  );

  // transform document items
  if (isSelfInspection) {
    const components = (template?.components || []) as Array<TemplateComponent>;
    const mapComponents = arrayToObject(components, "componentId");
    const mapAllCells = arrayToObject(
      components.map((com) => getAllCells(com)).flat(2),
      "cellId"
    );
    const mapComponentById = (
      Array.isArray(components) ? components : []
    )?.reduce((prev, cr) => {
      //@ts-ignore
      prev[cr.componentId] = cr;

      return prev;
    }, {});

    documentItems?.forEach((item, index) => {
      if (item.subItems?.length) {
        item.subItems = (item.subItems || []).sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }

      item.documentCategoryId = category.id;
      //@ts-ignore
      const componentText = mapComponentById[
        item?.linkedDataId || ""
      ] as TemplateComponent;
      if (componentText) {
        item.subItems = (item?.subItems || [])?.sort((a, b) => {
          const cell = mapAllCells?.[a?.linkedDataId || ""];
          if (!cell) {
            return 0;
          }
          const componentId = cell?.idRow?.split("-").at(0) || "";
          const component = mapComponents?.[componentId];
          if (!component) {
            return 0;
          }
          const aIndex =
            getCellPositionByCellId({
              cellId: a.linkedDataId || "",
              component,
            })?.index?.row || 0;
          const bIndex =
            getCellPositionByCellId({
              cellId: b.linkedDataId || "",
              component,
            })?.index?.row || 0;

          return aIndex - bIndex;
        });
      }
      let displayOrderOfComponent = componentText?.detail?.displayOrder;
      if (
        displayOrderOfComponent !== undefined &&
        displayOrderOfComponent !== null
      ) {
        displayOrderOfComponent = displayOrderOfComponent - 1;
      }
      const displayOrder =
        displayOrderOfComponent ?? item?.displayOrder ?? index;
      item.displayOrder = displayOrder;
    });

    documentItems = sortBy(
      documentItems,
      [
        (item) => Number(item?.displayOrder),
        ({ title }) => title?.toLocaleLowerCase(),
      ],
      ["asc", "asc"]
    ).map((item, index) => ({ ...item, displayOrder: index }));
  }

  const { mapItemIds } = handleSortDocItemByItemIds(
    category,
    documentItems,
    isSelfInspection
  );
  // Filter out redundant selectedExternalIds
  category.selectedExternalIds = category?.selectedExternalIds?.filter(
    (itemId) => mapItemIds.get(itemId) !== undefined
  );

  return category;
};

export const handleSortDocItemByItemIds = (
  category: DocumentCategoryDTO,
  documentItems: DocumentItemDTO[],
  isSelfInspection: boolean
) => {
  if (new Set(category?.itemIds || [])?.size !== documentItems?.length) {
    category.itemIds = sortByTitle(documentItems).map((item) => item.id);
  } else {
    const documentItemIds = documentItems.map((item) => item.id);
    const setDocumentItemId = new Set(documentItemIds);
    category.itemIds = (category?.itemIds || []).filter((id) =>
      setDocumentItemId.has(id)
    );
    if (category.itemIds.length !== setDocumentItemId.size) {
      category.itemIds = sortByTitle(documentItems).map((item) => item.id);
    }
  }

  const mapIndexOfValue = new Map();
  category.itemIds.forEach((itemId, index) => {
    mapIndexOfValue.set(itemId, index);
  });
  // with case self inspection docItem is sorted by template
  if (!isSelfInspection) {
    documentItems.forEach((item) => {
      item.documentCategoryId = category.id;
      item.displayOrder = mapIndexOfValue.get(item.id);

      if (item.subItems?.length) {
        item.subItems = (item.subItems || []).sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
    });
    documentItems.sort(
      (a, b) => (a?.displayOrder || 0) - (b?.displayOrder || 0)
    );
  }
  category.documentItems = documentItems;

  return { mapItemIds: mapIndexOfValue };
};

export const doMapDocumentCategories = ({
  documentCategories,
  documentItems,
  familyInstances,
  documentTemplates,
  isSortDocumentItem = true,
  skipRemappingDocumentItem = false,
}: {
  documentCategories: DocumentCategoryDTO[];
  documentItems: DocumentItemDTO[];
  familyInstances?: {
    [key: string]: FamilyInstanceDTO;
  };
  documentTemplates: DocumentTemplateDTO[];
  isSortDocumentItem?: boolean;
  skipRemappingDocumentItem?: boolean;
}) => {
  const mapDocumentTemplatesById: { [id: string]: DocumentTemplateDTO } =
    documentTemplates.reduce((prev, template) => {
      //@ts-ignore
      prev[template?.id || ""] = template;

      return prev;
    }, {});

  if (!skipRemappingDocumentItem) {
    if (isSortDocumentItem) {
      documentItems = sortArrayByField(documentItems, "indexId");
    }
    documentItems.forEach((item) => {
      if (!item?.externalId) {
        return;
      }
      const familyInstance = familyInstances?.[item.externalId];
      if (isSelfInspectionTemplate(item.documentType)) {
        return;
      }
      if (!familyInstance) {
        return;
      }
      item.objectTypes = familyInstances[item.externalId].objectTypes.map(
        (item) => item.id
      );

      if (!!item.templateId) {
        const template = mapDocumentTemplatesById[item.templateId];
        item.blackboardTemplateId = template?.blackboardTemplateDetail?.id;
        item.isShowBlackboard = item.isShowBlackboard ?? true;
      }
    });
  }

  const documentCategoryNeedUpdateItemIds: Partial<
    DocumentCategoryDTO & { currentCategory: DocumentCategoryDTO }
  >[] = [];
  documentCategories = documentCategories
    .map((category: DocumentCategoryDTO) => {
      const template = mapDocumentTemplatesById?.[category.templateId || ""];
      const currentItemIds = category?.itemIds || [];
      const newCategory = doMapDocumentCategory({
        category: { ...category },
        documentItems,
        template,
      });
      const newItemIds = newCategory?.itemIds || [];
      if (!isEqual(currentItemIds, newItemIds)) {
        documentCategoryNeedUpdateItemIds.push({
          id: category.id,
          itemIds: newItemIds,
          currentCategory: category,
        });
      }

      return newCategory;
    })
    .filter((cat) => !!cat) as DocumentCategoryDTO[];

  return {
    documentCategories,
    documentItems,
    documentTemplates,
    documentCategoryNeedUpdateItemIds,
  };
};

export function getFilterDocumentCategories({
  filterDocumentCategoryOptions,
  documentCategories,
  levelSelected,
  skipFilterByDocumentTemplate = false,
  documentGroups,
  isFilter,
}: {
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps;
  documentCategories: DocumentCategoryDTO[];
  levelSelected: Level;
  skipFilterByDocumentTemplate?: boolean;
  documentGroups: DocumentGroup[];
  isFilter?: boolean;
}) {
  const settings = filterDocumentCategoryOptions?.settings;
  const searchDocumentValue =
    filterDocumentCategoryOptions?.searchDocumentValue;
  const areas = filterDocumentCategoryOptions?.areas;
  const documentTemplates = filterDocumentCategoryOptions?.documentTemplates;
  const mapDocumentGroup = new Map(
    documentGroups.map((group) => [group.id, group])
  );

  const categories = sortDocumentCategories(
    documentCategories.filter(
      (category) =>
        category.level === levelSelected.label ||
        category.level === OPTION_ALL_FLOOR
    )
  );

  const isDefaultDocumentFilterData = isDefaultSearchDocument(
    filterDocumentCategoryOptions,
    new Set(categories.map((cate) => cate.id))
  );

  if (isDefaultDocumentFilterData) {
    return {
      categories,
      groups: documentGroups,
      allCategories: documentCategories,
    };
  }

  const result = categories.map((category) => {
    return transformDocumentCategoryByUserSetting({
      documentCategory: category,
      areas,
      settings,
      searchDocumentValue,
      documentTemplates,
      documentCategories,
      skipFilterByDocumentTemplate,
    });
  });

  const searchValueUpperCase = (searchDocumentValue || "").toUpperCase();

  const groupIdsByFilter = new Set<string>();

  const isFilterByForm =
    settings.documentAreas?.length ||
    settings.documentTypeDocuments?.length ||
    settings.documentUserAssigned?.length ||
    settings.documentUserCreated?.length ||
    settings.documentTags?.length;

  const resultList = result.filter((documentCategory, idx) => {
    if (!documentCategory) {
      return false;
    }

    // level is Prerequisites
    const filteredByLevel =
      !levelSelected.guid ||
      levelSelected.label === documentCategory.level ||
      documentCategory.level === OPTION_ALL_FLOOR;
    // when filter document group, if category not match with group then even if document items has data it is still not displayed
    let isMatchSearchDocumentCategory = true;
    const isDocumentCategoryContainTags = !!documentCategory?.tags?.filter(
      (tag) => settings?.documentTags?.includes(tag)
    )?.length;

    const group = documentCategory?.groupId
      ? mapDocumentGroup.get(documentCategory?.groupId)
      : null;

    if (searchDocumentValue) {
      isMatchSearchDocumentCategory =
        (documentCategory?.title?.toUpperCase() || "").includes(
          searchValueUpperCase
        ) ||
        (documentCategory?.level?.toUpperCase() || "").includes(
          searchValueUpperCase
        ) ||
        (
          areas
            .find((area) => area.id === documentCategory?.areaId)
            ?.name?.toUpperCase() || ""
        ).includes(searchValueUpperCase);
    }

    const documentTypeDocuments = settings?.documentTypeDocuments || [];

    const isCurrentUserAssign =
      !settings?.documentUserAssigned?.length ||
      (documentCategory?.userAssigned &&
        settings?.documentUserAssigned.some((sub) =>
          documentCategory?.userAssigned?.includes(sub)
        ));
    const isCurrentUserCreated =
      !settings?.documentUserCreated?.length ||
      (documentCategory?.templateId &&
        documentCategory.userCreated &&
        settings?.documentUserCreated?.includes(documentCategory.userCreated));

    const isTypeDocuments =
      !documentTypeDocuments?.length ||
      documentTypeDocuments?.includes(documentCategory.id);

    const isContainTags =
      !settings?.documentTags?.length || isDocumentCategoryContainTags;
    const isCurrentStatus =
      settings?.documentStatus?.length !== 5
        ? !!documentCategory.documentItems?.length
        : true;

    const filteredAreaIds = new Set(settings?.documentAreas || []);
    let isInArea =
      filteredAreaIds.size === 0 ||
      documentCategory.allFloor === documentCategory.level;
    if (
      !isInArea &&
      filteredAreaIds.size > 0 &&
      Array.isArray(documentCategory.neptuneAreaIds)
    ) {
      for (let i = 0; i < documentCategory.neptuneAreaIds.length; i++) {
        if (filteredAreaIds.has(documentCategory.neptuneAreaIds[i])) {
          isInArea = true;
          break;
        }
      }
    }

    const isMatchSearch = !searchDocumentValue || isMatchSearchDocumentCategory;
    const isMatchDocumentGroup =
      !settings.documentGroups?.length ||
      settings.documentGroups.includes(documentCategory.groupId || "");
    const isMatchImage =
      settings?.documentHasImage && !settings?.documentHasNoImage
        ? isPhotoLedgerTemplate(documentCategory.documentType) &&
          !!documentCategory.documentItems?.length
        : true;

    const isMatchGroup = searchValueUpperCase
      ? (group?.name?.toUpperCase() || "").includes(searchValueUpperCase)
      : true;

    const isValid =
      filteredByLevel &&
      isMatchImage &&
      isMatchDocumentGroup &&
      isInArea &&
      isTypeDocuments &&
      (documentCategory.documentItems?.length ||
        (isCurrentUserAssign &&
          isCurrentUserCreated &&
          isContainTags &&
          isCurrentStatus &&
          (isMatchSearch || isMatchGroup)));

    if (isValid && documentCategory.groupId) {
      groupIdsByFilter.add(documentCategory.groupId);
    }

    return isValid;
  });

  const mapFilteredDocumentCategoryByGroup = groupBy(resultList, "groupId");
  const mapDocumentCategoryByGroup = groupBy(documentCategories, "groupId");
  const groups = documentGroups.filter((group) => {
    if (isFilterByForm && !groupIdsByFilter.has(group.id)) {
      return false;
    }

    if (searchValueUpperCase) {
      let isFound =
        group.name.toUpperCase().indexOf(searchValueUpperCase) !== -1;

      if (
        isFound &&
        !mapFilteredDocumentCategoryByGroup[group.id]?.length &&
        mapDocumentCategoryByGroup[group.id]
      ) {
        isFound = true;
        mapDocumentCategoryByGroup[group.id].map((category) =>
          resultList.push(category)
        );
      } else if (mapFilteredDocumentCategoryByGroup[group.id]?.length) {
        isFound = true;
      }

      return isFound;
    }

    if (isFilter && !mapFilteredDocumentCategoryByGroup[group.id]?.length) {
      return false;
    }

    return true;
  });

  return { groups, categories: resultList, allCategories: documentCategories };
}

export const transformDocumentSubCategory = ({
  documentCategory,
  documentTemplate,
}: {
  documentCategory: DocumentCategoryDTO;
  documentTemplate: DocumentTemplateDTO;
}) => {
  if (
    !documentCategory?.itemIds?.length ||
    documentCategory?.itemIds?.length !==
      documentCategory?.documentItems?.length
  ) {
    documentCategory.itemIds = sortByTitle(documentCategory.documentItems).map(
      (item) => item.id
    );
  } else {
    const documentItemIds = (documentCategory?.documentItems || []).map(
      (item) => item.id
    );
    documentCategory.itemIds = documentCategory.itemIds.filter((id) =>
      documentItemIds.includes(id)
    );
  }

  documentCategory.documentItems = (documentCategory?.documentItems || [])
    .map((item) => ({
      ...item,
      documentCategoryId: documentCategory.id,
      exactDisplayOrder: documentCategory.itemIds?.findIndex(
        (id) => item.id === id
      ),
      displayOrder: documentCategory.itemIds?.findIndex((id) => item.id === id),
    }))
    .sort((a, b) => (a?.exactDisplayOrder || 0) - (b?.exactDisplayOrder || 0));
  const documentItems = sortBy(documentCategory?.documentItems || [], [
    (i) => i.createdAt,
  ]);

  let isCheckDisplayOrder = true;
  const documentItemsLength = documentItems?.length;
  const newDocumentItems: DocumentItemDTO[] = [];
  for (let i = 0; i < documentItemsLength; i++) {
    if (![0, null].includes(documentItems[i]?.displayOrder as number)) {
      isCheckDisplayOrder = false;
      break;
    }

    newDocumentItems.push({
      ...documentItems[i],
      displayOrder: i,
      exactDisplayOrder: i,
    });
  }

  // fix display order on projectBimFile
  if (documentItems?.length > 2 && isCheckDisplayOrder) {
    documentCategory.documentItems = newDocumentItems;
  }

  const mapSubCategory: { [key: string]: any } = {};

  documentCategory?.documentItems?.forEach((item) => {
    const subCategory =
      mapSubCategory[item.externalId!] ||
      ({
        id: item.externalId || uuid(),
        documentItems: [],
        title: "-",
        name: "-",
        externalId: item.externalId,
        documentCategoryId: documentCategory.id,
      } as any);

    item.blackboardTemplateId = documentTemplate?.blackboardTemplateDetail?.id;
    item.documentSubCategoryId = subCategory.id;
    item.isShowBlackboard = item.isShowBlackboard ?? true;

    subCategory.documentItems!.push(item);
    mapSubCategory[item.externalId!] = subCategory;
  });

  documentCategory.documentSubCategories = sortByTitle(
    Object.values(mapSubCategory)
  ) as DocumentSubCategory[];

  // documentCategory.documentSubCategories = sortByTitle(
  //   documentTemplate.documentSubCategories?.filter(
  //     (sub) =>
  //       sub.level === documentCategory.level &&
  //       documentCategory.neptuneAreaIds?.length &&
  //       sub.areaIds?.some((r) => documentCategory.neptuneAreaIds?.includes(r))
  //   ) || []
  // ) as DocumentSubCategory[];

  const sortDocumentItemsOfSub = (item: DocumentSubCategory) => {
    const documentItems = documentCategory.documentItems?.filter(
      (i) => i.externalId === item.externalId
    );
    let itemHasNotOrder: DocumentItemDTO[] = [];
    let itemHasOrder: DocumentItemDTO[] = [];
    documentItems?.forEach((i) =>
      [-1, undefined, null].includes(i.displayOrder)
        ? itemHasNotOrder.push(i)
        : itemHasOrder.push(i)
    );
    itemHasOrder = sortBy(itemHasOrder, [(i) => Number(i.displayOrder)]);
    itemHasNotOrder = sortBy(itemHasNotOrder, [(i) => Number(i.indexId)]);

    return [...itemHasOrder, ...itemHasNotOrder];
  };

  documentCategory.documentSubCategories =
    documentCategory.documentSubCategories.map((item) => ({
      ...item,
      title:
        item.documentItems?.find((i) => !!i.subCategoryTitle)
          ?.subCategoryTitle || item.title,
      documentItems: sortDocumentItemsOfSub(item),
      exactDisplayOrder: Math.min(
        ...(item.documentItems
          ?.filter((i) => i?.exactDisplayOrder !== -1)
          ?.map((i, index) => i.exactDisplayOrder ?? index) as any)
      ),
      displayOrder: Math.min(
        ...(item.documentItems
          ?.filter((i) => i?.displayOrder !== -1)
          ?.map((i, index) => i.displayOrder ?? index) as any)
      ),
    }));

  documentCategory.documentSubCategories = sortBy(
    documentCategory.documentSubCategories,
    [(item) => Number(item.exactDisplayOrder)]
  );

  documentCategory.documentSubCategories =
    documentCategory.documentSubCategories?.map((item, index) => {
      return {
        ...item,
        displayOrder: index,
        documentItems: sortDocumentItemsOfSub(item),
      };
    });

  documentCategory.itemIds = (documentCategory?.documentSubCategories || [])
    .map((sub) => (sub?.documentItems || [])?.map((item) => item.id || ""))
    .flat(1);

  return {
    documentCategory,
  };
};

export const updateOrderDocumentItems = (
  documentCategory: DocumentCategoryDTO
) => {
  const newMapIndexById = new Map();
  documentCategory.itemIds?.forEach((id: string, index: number) => {
    newMapIndexById.set(id, index);
  });
  documentCategory.documentItems = (documentCategory.documentItems || [])
    .map((item: DocumentItemDTO) => ({
      ...item,
      displayOrder: newMapIndexById.get(item.id),
    }))
    .sort(
      (a: DocumentItemDTO, b: DocumentItemDTO) =>
        (a?.displayOrder || 0) - (b?.displayOrder || 0)
    );
};

export const reMappingWidthDocItemAfterAddDoc = (
  changeCategories: DocumentCategoryDTO[],
  documentCategories: DocumentCategoryDTO[],
  documentItems: DocumentItemDTO[],
  documentTemplates: { [key: string]: DocumentTemplateDTO }
) => {
  const setAddDocumentCategoryId = new Set(
    changeCategories.map((item: DocumentCategoryDTO) => item.id)
  );
  let newAllDocumentCategories = documentCategories.filter(
    (item) => !setAddDocumentCategoryId.has(item.id)
  );
  newAllDocumentCategories = newAllDocumentCategories.concat(changeCategories);
  const newCloneDocumentCategories = newAllDocumentCategories.map((cate) => {
    return { ...cate, documentItems: [] };
  });
  const setDocumentItemIds = new Set();
  const changeDocumentItems: DocumentItemDTO[] = [];
  const changeCategoryIds: string[] = [];
  changeCategories.forEach((cate) => {
    changeCategoryIds.push(cate.id);
    (cate.documentItems || []).forEach((item: DocumentItemDTO) => {
      if (!setDocumentItemIds.has(item.id)) {
        setDocumentItemIds.add(item.id);
        changeDocumentItems.push(item);
      }
    });
  });

  const newAllDocumentItems = documentItems
    .filter((elm) => !setDocumentItemIds.has(elm.id))
    .concat(changeDocumentItems);
  const {
    documentCategories: newDocumentCategories,
    documentItems: newDocumentItems,
  } = doMapDocumentCategories({
    documentCategories: newCloneDocumentCategories,
    documentItems: structuredClone(newAllDocumentItems),
    skipRemappingDocumentItem: true,
    documentTemplates: Object.values(documentTemplates),
  });

  return { newDocumentCategories, newDocumentItems };
};

export const isPhotoLedgerTemplate = (documentType: string | undefined) =>
  documentType === DocumentTemplateType.PHOTO_LEDGER;
export const isSelfInspectionTemplate = (documentType: string | undefined) =>
  documentType === DocumentTemplateType.SELF_INSPECTION;
export const isParentTemplate = (documentType: string | undefined) =>
  documentType === DocumentTemplateType.PARENT_TEMPLATE;
export const isCommissiontingTableTemplate = (
  documentType: string | undefined
) => documentType === DocumentTemplateType.COMMISSIONING_TABLE;
export const isEquipmentDataSheetTemplate = (
  documentType: string | undefined
) => documentType === DocumentTemplateType.EQUIPMENT_DATA_SHEET;

export const isBlockDocumentCategory = (
  blockedBy: string | null | undefined
) => {
  return false;
  // Temporarily hide function check block pin, category
  //const user: User = getLocalStorage("userInfo");
  //if (user.id === blockedBy || !blockedBy) {
  //return false;
  //}
  //return true;
};

export const updateDocumentCategoriesWhenHidePhoto = async (params: {
  documentCategories: DocumentCategoryDTO[];
  documentItem: DocumentItemDTO;
  requestId?: string;
}) => {
  const { documentCategories, documentItem } = params;
  const { isShowAllItem, isHideAllItem } = getStatusVisibleOfItem(documentItem);

  await Promise.all(
    documentCategories.map((cate) => {
      const filterValid =
        cate.templateId === documentItem.templateId &&
        cate.level === documentItem.level;
      if (!filterValid) {
        return Promise.resolve();
      }

      const setSelectedExternalIds = new Set(cate?.selectedExternalIds || []);
      isHideAllItem && setSelectedExternalIds.delete(documentItem.id);
      isShowAllItem && setSelectedExternalIds.add(documentItem.id);
      const selectedExternalIds = Array.from(setSelectedExternalIds);
      if (isEqual(selectedExternalIds, cate.selectedExternalIds)) {
        return Promise.resolve();
      }

      return documentCategoryApi.updateCategory(
        transformBodyForCombineData({
          body: {
            id: cate.id,
            selectedExternalIds,
            requestId: params.requestId,
            updatedAt: new Date(),
          } as DocumentCategoryDTO,
          bodyBefore: cate,
          typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
        })
      );
    })
  );
};

export const getCategoryFieldNecessaryForOffline = (
  category: DocumentCategoryDTO
): Partial<DocumentCategoryDTO> => {
  return {
    level: category.level,
    neptuneAreaIds: category.neptuneAreaIds,
    templateId: category.templateId,
    documentType: category.documentType,
    bimFileId: category.bimFileId,
    blockedBy: category.blockedBy,
    isBlocked: category.isBlocked,
  };
};

import { Box, BoxProps } from "@chakra-ui/react";
import { CSSProperties, HTMLAttributes, useMemo, memo } from "react";

enum SIZE_DEFAULT {
  WIDTH = "24px",
  HEIGHT = "24px",
}

type IconProps = {
  icon: string;
  name?: string;
  color?: CSSProperties["color"];
  width?: string;
  height?: string;
  className?: string;
} & HTMLAttributes<HTMLDivElement> &
  BoxProps;

const Icon: React.FC<IconProps> = ({
  icon,
  name,
  style,
  color = "currentColor",
  className = "icon-base",
  width = SIZE_DEFAULT.WIDTH,
  height = SIZE_DEFAULT.HEIGHT,
  ...props
}) => {
  const styleIcon: CSSProperties = useMemo(
    () => ({
      mask: `url(${icon}) no-repeat center / contain`,
      WebkitMask: `url(${icon}) no-repeat center / contain`,
    }),
    [icon]
  );

  return (
    <Box
      className={className}
      style={{ ...styleIcon, ...style }}
      width={width}
      height={height}
      background={color}
      {...props}
    />
  );
};

export default memo(Icon);

import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconOffline(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#059669"
        d="M21 21.748c-.19 0-.38-.07-.53-.22l-1.89-1.89c-.35.07-.71.11-1.08.11h-11c-2.9 0-5.25-2.36-5.25-5.25 0-2.54 1.77-4.66 4.2-5.15.18-.7.47-1.37.86-1.98l-3.84-3.84a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l4.25 4.25s.05.04.07.07l11.43 11.43s.08.07.11.11l2.14 2.14c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22M7.4 8.458c-.29.52-.49 1.09-.58 1.68-.06.34-.33.59-.67.62-1.94.18-3.4 1.78-3.4 3.73 0 2.07 1.68 3.75 3.75 3.75h10.69zm14.3 8.4a.746.746 0 0 1-.7-1.02c.17-.43.25-.88.25-1.35 0-1.95-1.46-3.55-3.4-3.73a.744.744 0 0 1-.67-.62A5.234 5.234 0 0 0 12 5.748c-.38 0-.77.04-1.14.12-.41.09-.8-.17-.89-.57s.17-.8.57-.89c.48-.11.97-.16 1.46-.16a6.73 6.73 0 0 1 6.55 5.1c2.43.49 4.2 2.61 4.2 5.15 0 .65-.12 1.29-.35 1.89-.11.3-.4.48-.7.48z"
      />
    </Icon>
  );
}

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconEye(props: IconProps) {
  return (
    <Icon width="2rem" height="2rem" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M5.246 6.806C3.98 7.862 3.097 9.101 2.65 9.807l-.103.171v.046l.003.006.032.056.068.11c.446.706 1.33 1.945 2.595 3 1.262 1.054 2.86 1.889 4.754 1.889 1.896 0 3.492-.835 4.755-1.889 1.265-1.055 2.148-2.294 2.595-3l.103-.172v-.046l-.003-.006-.032-.056-.068-.109c-.447-.706-1.33-1.945-2.595-3-1.263-1.054-2.86-1.889-4.755-1.889s-3.492.835-4.754 1.888m-.962-1.151C5.715 4.46 7.641 3.418 10 3.418s4.285 1.042 5.716 2.237c1.43 1.193 2.41 2.573 2.902 3.352l.02.03c.098.155.218.344.279.61.05.215.05.494 0 .708a1.9 1.9 0 0 1-.28.61l-.019.031c-.491.778-1.472 2.159-2.902 3.352S12.36 16.585 10 16.585s-4.285-1.043-5.716-2.237c-1.429-1.193-2.41-2.574-2.901-3.352l-.02-.03a1.9 1.9 0 0 1-.28-.611 1.7 1.7 0 0 1 0-.707c.062-.267.182-.456.28-.61l.02-.031c.491-.779 1.472-2.16 2.901-3.352M10 8.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5M6.75 10a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0"
        clipRule="evenodd"
      />
    </Icon>
  );
}

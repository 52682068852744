import {
  Badge,
  Box,
  Flex,
  FormLabel,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Placement,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import Search from "components/ui/Search";
import { DEFAULT_DROP_BOX_HEIGHT } from "constants/dropdown";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Tag from "components/ui/TagInput/Tag";

export const AutoComplete = ({
  isFullWidth = false,
  label,
  options,
  value,
  disabled = false,
  rowHeight = undefined,
  isLoading,
  onChange,
  menuHeaderHeight = 65,
}: {
  isFullWidth?: boolean;
  label: string;
  value: any[];
  options: { label: any; value: any }[];
  disabled?: boolean;
  rowHeight?: number;
  isLoading?: boolean;
  menuHeaderHeight?: number;
  onChange(value: any[]): void;
}) => {
  const dropdownRef = useRef<any>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropBoxHeight, setDropBoxHeight] = useState(0);
  const [dropBoxPlacement, setDropBoxPlacement] = useState<
    Placement | undefined
  >(undefined);
  const [dropBoxOffsetY, setDropBoxOffsetY] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(options);

  useEffect(() => {
    setScrollTop(0);
    scrollRef.current?.scrollTo({ top: 0 });
  }, [options.length, rowHeight]);

  const handleSearch = useCallback((search: string) => {
    setScrollTop(0);
    scrollRef.current?.scrollTo({ top: 0 });
    setSearch(search);
  }, []);

  const displayInfo = useMemo(() => {
    let visibleItems = filter;
    let startIndex = 0;
    if (rowHeight) {
      const menuHeight = Math.min(dropBoxHeight, filter.length * rowHeight);
      startIndex = Math.floor(scrollTop / rowHeight);
      const endIndex = Math.min(
        startIndex + Math.ceil(menuHeight / rowHeight),
        filter.length
      );
      visibleItems = filter.slice(startIndex, endIndex);
    }

    return { visibleItems, startIndex };
  }, [scrollTop, rowHeight, filter, dropBoxHeight]);

  const handleScroll = useCallback((event: any) => {
    setScrollTop(event.target.scrollTop);
  }, []);

  /**
   * Reset new height after dropdown box is created
   */
  const calculatePlacementAndHeightDropBox = useCallback(() => {
    if (isOpen && scrollRef.current) {
      const windowHeight = window.innerHeight;
      const parentBounds =
        dropdownRef.current?.parentElement?.getBoundingClientRect();
      const boxBounds =
        dropdownRef.current?.childNodes[0]?.childNodes[1]?.getBoundingClientRect();

      const minHeightDropBox = 100;
      let maxHeightDropBox = 0;
      let placementValue = "bottom-start" as Placement;
      let offsetY = 0;

      // Check exists DOM
      if (boxBounds && parentBounds) {
        const marginTop = 5;
        const marginBottom = 15;
        const topHeight = Math.abs(
          parentBounds.top -
            (parentBounds.top > boxBounds.top
              ? parentBounds.top
              : boxBounds.top - marginTop)
        );
        const bottomHeight = Math.abs(
          windowHeight -
            (boxBounds.bottom > windowHeight
              ? windowHeight
              : boxBounds.bottom + marginBottom)
        );

        // Check suitable area for display
        if (topHeight > bottomHeight && topHeight >= minHeightDropBox) {
          maxHeightDropBox = topHeight;
          placementValue = "top-start";
        } else if (
          topHeight < bottomHeight &&
          bottomHeight >= minHeightDropBox
        ) {
          maxHeightDropBox = bottomHeight;
          placementValue = "bottom-start";
        } else {
          if (boxBounds.top < menuHeaderHeight) {
            offsetY = menuHeaderHeight;
          }
          maxHeightDropBox = DEFAULT_DROP_BOX_HEIGHT;
          placementValue = "right-start";
        }
      }
      setDropBoxOffsetY(offsetY);
      setDropBoxPlacement(placementValue);
      setDropBoxHeight(Math.min(maxHeightDropBox, DEFAULT_DROP_BOX_HEIGHT));
    }
  }, [isOpen, menuHeaderHeight]);

  const handleOnOpen = () => {
    setIsOpen(true);
    calculatePlacementAndHeightDropBox();
  };

  const handleOnClose = () => {
    setIsOpen(false);
    setDropBoxOffsetY(0);
    setDropBoxPlacement(undefined);
    setScrollTop(0);
    scrollRef.current?.scrollTo({ top: 0 });
  };

  const mapOptions = useMemo(
    () => new Map(options.map((option) => [option.value, option.label])),
    [options]
  );

  useEffect(() => {
    calculatePlacementAndHeightDropBox();
  }, [filter, calculatePlacementAndHeightDropBox]);

  useLayoutEffect(() => {
    setFilter(
      !search
        ? options
        : options.filter(({ label }: any) =>
            label?.toLowerCase().includes(search)
          )
    );
  }, [search, options]);

  const renderTag = useCallback(
    ({ title, id }: { title: string; id: string }) => {
      return (
        <HStack
          key={id}
          borderRadius="4px"
          display="flex"
          alignItems="center"
          p="0 5px"
          maxW="27rem"
        >
          <Tag
            key={id}
            text={title}
            boxProps={{
              maxW: "100%",
            }}
            styleText={{
              maxW: "90%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            isShowTooltip={true}
            remove={() => {
              onChange(value?.filter((v) => v !== id));
            }}
          />
        </HStack>
      );
    },
    [onChange, value]
  );

  const renderInputContents = useMemo(() => {
    return value?.map((id) => {
      return renderTag({
        title: mapOptions.get(id),
        id: id,
      });
    });
  }, [value, renderTag, mapOptions]);

  return (
    <FormLabel
      as="legend"
      ref={dropdownRef}
      position={"relative"}
      sx={{
        cursor: "pointer",
        ".chakra-menu__menu-button > span": {
          pointerEvents: "auto",
        },
        ".chakra-menu__menu-list": {
          position: "relative",
          top: `${dropBoxOffsetY}px`,
        },
      }}
    >
      <Stack>
        <Text>{label}</Text>
        <Menu
          closeOnSelect={false}
          onClose={handleOnClose}
          onOpen={handleOnOpen}
          flip={false}
        >
          <MenuButton ref={menuButtonRef} disabled={disabled}>
            <Flex
              padding="1rem"
              borderRadius="0.375rem"
              flexWrap="wrap"
              gap="0.5rem"
              minH="4.4rem"
              border="1px solid #e2e2e3"
              overflow="inherit"
              bgColor={disabled ? "#FAFAFA" : "#FFFFFFF"}
            >
              {renderInputContents}
            </Flex>
          </MenuButton>
          {isOpen && (
            <MenuList
              minW={
                isFullWidth ? Number(dropdownRef.current?.clientWidth) : "auto"
              }
              maxW={
                isFullWidth ? Number(dropdownRef.current?.clientWidth) : "auto"
              }
            >
              <Box
                px={"0.5rem"}
                pb={"0.5rem"}
                borderBottom={
                  "1px solid var(--chakra-colors-chakra-border-color)"
                }
              >
                <Search onSearch={handleSearch} />
              </Box>
              <Box
                maxHeight={`${dropBoxHeight}px`}
                overflowY="auto"
                overflowX="hidden"
                display={`${dropBoxPlacement ? "block" : "none"}`}
                onScroll={handleScroll}
                ref={scrollRef}
              >
                {displayInfo.visibleItems?.length ? (
                  <Box
                    height={
                      rowHeight ? `${rowHeight * filter.length}px` : undefined
                    }
                  >
                    <Box
                      sx={
                        rowHeight
                          ? {
                              position: "relative",
                              height: `${
                                displayInfo.visibleItems.length * rowHeight
                              }px`,
                              top: `${displayInfo.startIndex * rowHeight}px`,
                            }
                          : undefined
                      }
                    >
                      {displayInfo.visibleItems.map((option) => {
                        const isChecked = value?.includes(option.value);

                        return (
                          <Box
                            key={option.value}
                            p="0 1.5rem"
                            h={"40px"}
                            borderBottom="1px solid var(--primary-border-color)"
                            _last={{ borderBottom: "none" }}
                            style={{ maxWidth: "29rem" }}
                            onClick={() =>
                              onChange(
                                isChecked
                                  ? value?.filter((v) => v !== option.value)
                                  : [...value, option.value]
                              )
                            }
                            display={"flex"}
                            gap="0.5rem"
                            width={"100%"}
                            alignItems={"center"}
                          >
                            <Box w="3rem">
                              {isChecked && (
                                <SvgIcon src="/img/icon-action-check_circle.svg" />
                              )}
                            </Box>
                            <Badge
                              variant="pin"
                              bg="gray"
                              mr="3rem"
                              p="0.1rem 0.5rem"
                              minH="1.8rem"
                              h="auto"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              textTransform="none"
                            >
                              {option.label}
                            </Badge>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    p="1rem 1.5rem 0.5rem 1.5rem"
                    display={"flex"}
                    gap="0.5rem"
                    width={"100%"}
                    alignItems={"center"}
                  >
                    データがありません。
                  </Box>
                )}
              </Box>
            </MenuList>
          )}
        </Menu>
      </Stack>
      {isLoading && (
        <Box position={"absolute"} right={0} top={0} height={"100%"}>
          <Spinner />
        </Box>
      )}
    </FormLabel>
  );
};

import { Box, Table, TableContainer, Tbody, Tr } from "@chakra-ui/react";
import HeaderCommon from "components/editor-builder/component-preview/HeaderCommon";
import { GRID_TEMPLATE_SIZE } from "constants/document";
import { COMPONENT_SELECTED_OPACITY } from "constants/documentTemplate";
import { CellProperty, CellSizeSetting, ContentType } from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { Blackboard } from "interfaces/models/blackboard";
import {
  CellSize,
  CellType,
  DisplayComponent,
  RowType,
  TemplateComponent,
} from "interfaces/models/component";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
  iCommonDocumentTemplateProps,
  iDocumentTaskDataProps,
  iLinkedImageProps,
  iModuleChillerProps,
  iPacProps,
  iPreviewProps,
  iSelfInspectionProps,
  iSleevePipeProps,
  iTaskItemDataProps,
} from "interfaces/models/documentTemplate";
import { DEFAULT_BORDER_COLOR_ACTIVE } from "pages/document/template-page/hooks";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { hexToRgbA } from "utils/common";
import { getOriginalSize } from "utils/document";
import useTableComponentPreview from "./hooks";
import TableTdComponentPreview, {
  iTableTdComponentPreview,
} from "./TableTdComponentPreview";
import {
  checkTableFullWidth,
  checkTableOverlapRightSide,
  getMaxWidthTable,
} from "./utils/tableStyle";

export interface iUseTableComponentPreview {
  isOnlyView: boolean;
  componentSelected: TemplateComponent;
  selectedCells: CellType[];
  component: TemplateComponent;
  scanComponents: TemplateComponent[];
  zoomRatio: number;
  currentTemplate: DocumentTemplate;
}

export interface iPreviewDocumentCommonProps {
  contentType: ContentType;
  previewProps?: iPreviewProps;
  linkedImageProps?: iLinkedImageProps;
  pacProps?: iPacProps;
  flexibleDuctProps?: iSelfInspectionProps;
  moduleChillerProps?: iModuleChillerProps;
  sleevePipeProps?: iSleevePipeProps;
  blackboardData?: Blackboard;
  blackboardTemplateProps?: iBlackboardTemplateProps;
  documentTaskDataProps?: iDocumentTaskDataProps;
  taskItemDataProps?: iTaskItemDataProps;
}

export interface iTableComponentPreview extends iPreviewDocumentCommonProps {
  isResizable: boolean;
  isOnlyView?: boolean;
  isResizing?: boolean;
  isBlackboardTemplate?: boolean;
  isBlackboardTemplateImage?: boolean;
  isTaskSheetTemplate?: boolean;
  isTaskSheetTemplateItem?: boolean;

  sizePageRatio: number;
  pageDirectionRatio: number;
  zoomRatio: number;

  template?: DocumentTemplate;
  component: TemplateComponent;
  cellSize: CellSize;
  displaySize: DisplayComponent["size"];

  hasKeyNote?: boolean;
  isDisabled?: boolean;

  onChangeBlackboardData?: (data: Blackboard) => void;
}

export const getPreviewDocumentCommonProps = ({
  contentType = ContentType.EDITOR,
  previewProps,
  linkedImageProps,
  pacProps,
  flexibleDuctProps,
  sleevePipeProps,
  moduleChillerProps,
  blackboardTemplateProps,
  blackboardData,
  documentTaskDataProps,
  taskItemDataProps,
}: Partial<iPreviewDocumentCommonProps>) => {
  return {
    contentType,
    previewProps,
    linkedImageProps,
    pacProps,
    flexibleDuctProps,
    sleevePipeProps,
    moduleChillerProps,
    blackboardTemplateProps,
    blackboardData,
    documentTaskDataProps,
    taskItemDataProps,
  };
};

interface iTableTdComponentPreviewProps {
  row: RowType;
  cell: CellType;
  rowIndex: number;
  cellIndex: number;
}

const TableComponentPreview = (props: iTableComponentPreview) => {
  const {
    component,
    cellSize,
    zoomRatio,
    displaySize,
    isOnlyView = false,
    isResizable = false,
    isResizing = false,
    sizePageRatio,
    isBlackboardTemplate = false,
    isBlackboardTemplateImage = false,
    isTaskSheetTemplate = false,
    isTaskSheetTemplateItem = false,
    pageDirectionRatio,
    hasKeyNote,
    template,
    isDisabled,
    onChangeBlackboardData,
    ...rest
  } = props;

  const {
    componentSelected,
    selectedCells,
    components,
    documentContainerSize,
    currentTemplate,
    isComponentDragging,
  } = useSelector((state: RootState) => state.document);
  const { scanComponents } = useSelector((state: RootState) => state.editor);

  const {
    isTableSelected,
    isSelectTableOnly,
    flexContainerProps,
    listMapCellSelected,
    getTrProps,
    getBorderColor,
    handleSelectCell,
  } = useTableComponentPreview({
    isOnlyView,
    componentSelected,
    selectedCells,
    component,
    scanComponents,
    zoomRatio,
    currentTemplate,
  });

  const {
    contentType,
    blackboardTemplateProps,
    linkedImageProps,
    pacProps,
    flexibleDuctProps,
    sleevePipeProps,
    moduleChillerProps,
    taskItemDataProps,
  } = getPreviewDocumentCommonProps(rest);

  const getTableTdComponentPreviewProps = useCallback(
    ({
      row,
      cell,
      rowIndex,
      cellIndex,
    }: iTableTdComponentPreviewProps): iTableTdComponentPreview => ({
      tableId: component?.componentId || "",
      trId: row.idRow,
      cell,
      borderColor:
        !!listMapCellSelected[cell.cellId!] && !isOnlyView
          ? DEFAULT_BORDER_COLOR_ACTIVE
          : cell?.style?.border
          ? getBorderColor(cell?.style)
          : "",
      width: cell?.width
        ? Number(cell?.width) + 1
        : CellSizeSetting.DEFAULT_WIDTH,
      height: cell?.height ?? CellSizeSetting.DEFAULT_HEIGHT,
      component,
      displaySize,
      zoomRatio,
      isSelected: !!listMapCellSelected[cell.cellId!] && !isOnlyView,
      canEditCell: false,
      onSelectCell: isOnlyView ? () => {} : handleSelectCell,
      components,
      componentSelected,
      currentTemplate,
      isComponentDragging: !!isComponentDragging,
      selectedCells,
      documentContainerSize,
      cellSize: cellSize || {
        width: GRID_TEMPLATE_SIZE,
        height: GRID_TEMPLATE_SIZE,
      },
      isTableSelected,
      isResizing,
      sizePageRatio,
      pageDirectionRatio,
      isDuplicateRow: !!row?.isDuplicateRow,
      isRepeatedTable: cell.isRepeatedTable,
      rowIndex,
      isBlackboardTemplate,
      isTaskSheetTemplate,
      isTaskSheetTemplateItem,
      isBlackboardTemplateImage,
      isLastRow:
        (component.detail?.rows?.length || 0) === rowIndex + cell.rowSpan!,
      isLastColumn: (row.cells?.length || 0) === cellIndex + cell.colSpan!,
      onChangeBlackboardData,
      numOfColumns: Number(row?.cells?.length),
      columnIndex: cellIndex,
      prevCellEl: row?.cells?.[cellIndex - 1],
      nextCellEl: row?.cells?.[cellIndex + 1],
      numOfRows: Number(component.detail?.rows?.length),
      isOnlyView,
      ...getPreviewDocumentCommonProps(rest),
    }),
    [
      isTaskSheetTemplateItem,
      isTaskSheetTemplate,
      rest,
      zoomRatio,
      sizePageRatio,
      selectedCells,
      pageDirectionRatio,
      isTableSelected,
      listMapCellSelected,
      isComponentDragging,
      isOnlyView,
      isResizing,
      isBlackboardTemplate,
      isBlackboardTemplateImage,
      documentContainerSize,
      displaySize,
      currentTemplate,
      cellSize,
      component,
      componentSelected,
      components,
      onChangeBlackboardData,
      handleSelectCell,
      getBorderColor,
    ]
  );

  const renderRowsTypeFlexibleDuct = useMemo(() => {
    return component?.detail?.rows?.map((row, rowIndex) => {
      return (
        <Tr key={row.idRow} id={row.idRow}>
          {row?.cells?.map((cell, cellIndex) => {
            const flexibleDuctComponens = flexibleDuctProps?.components || [];
            const isLastColumn =
              (row.cells?.length || 0) === cellIndex + cell.colSpan!;
            const numRow = component?.detail?.rows?.length;
            let cloneRowIndex = rowIndex;

            // handle map data by case has multiple table linked to same sub
            const tableLinkedToSub = flexibleDuctComponens.filter(
              (com) =>
                com.subcategoryIdSelected === component.subcategoryIdSelected
            );
            if (tableLinkedToSub?.length > 1) {
              const componentIdx = tableLinkedToSub.findIndex(
                (com) => com.componentId === component.componentId
              );

              if (componentIdx !== 0 && componentIdx !== -1) {
                cloneRowIndex =
                  cloneRowIndex +
                  (tableLinkedToSub?.[componentIdx - 1]?.detail?.rows?.length ||
                    0);
              }
            }

            const tableTdComponentPreviewProps =
              getTableTdComponentPreviewProps({
                row,
                cell,
                rowIndex: cloneRowIndex,
                cellIndex,
              });

            const option: iCommonDocumentTemplateProps["option"] = {
              rowIndex: cloneRowIndex,
              cellIndex,
              subRowIndex: -1,
              subCellIndex: -1,
              currentCell: cell,
            };

            const tdProps = {
              "data-is-first-child": cellIndex === 0,
              "data-is-sub-table": !!cell?.subTable?.rows?.length,
              "data-is-last-child": isLastColumn,
              "data-is-last-row":
                rowIndex + 1 === component?.detail?.rows?.length,
              "data-num-row": numRow,
              "data-is-table": "true",
            } as any;

            return (
              <TableTdComponentPreview
                key={cell.cellId}
                {...tableTdComponentPreviewProps}
                flexibleDuctProps={{
                  ...(flexibleDuctProps || ({} as iSelfInspectionProps)),
                  option,
                }}
                tdProps={tdProps}
                hasKeyNote={hasKeyNote}
                isDisabled={isDisabled}
              />
            );
          })}
        </Tr>
      );
    });
  }, [
    isDisabled,
    component.detail?.rows,
    flexibleDuctProps,
    hasKeyNote,
    component.componentId,
    component.subcategoryIdSelected,
    getTableTdComponentPreviewProps,
  ]);

  const renderRowsTypeLinkedImage = useMemo(() => {
    const documentItems = linkedImageProps?.displayItems || [];

    let rowHasImageIndex = 0;
    let rowHasDocumentDataIndex = 0;
    let isRenderOther = true;

    return component?.detail?.rows?.map((row, rowIndex) => {
      return (
        <Tr key={row.idRow} id={row.idRow}>
          {row?.cells?.map((cell, cellIndex) => {
            const tableTdComponentPreviewProps =
              getTableTdComponentPreviewProps({
                row,
                cell,
                rowIndex,
                cellIndex,
              });

            const numRow = component?.detail?.rows?.length;
            const isLastColumn =
              (row.cells?.length || 0) === cellIndex + cell.colSpan!;

            const tdProps = {
              "data-is-first-child": cellIndex === 0,
              "data-is-sub-table": !!cell?.subTable?.rows?.length,
              "data-is-last-child": isLastColumn,
              "data-is-last-row": true,
              "data-num-row": numRow,
              "data-is-table": "true",
            } as any;

            const option: iLinkedImageProps["option"] = {
              rowIndex,
              cellIndex,
              subRowIndex: -1,
              subCellIndex: -1,
              currentCell: cell,
              documentItem: undefined,
            };

            let documentItem = null;

            if (
              cell.cellProperty === CellProperty.DOCUMENT_DATA &&
              !!cell.cellLinkedData?.field
            ) {
              const index = rowHasDocumentDataIndex;
              documentItem = documentItems?.[index] as any;
              rowHasDocumentDataIndex++;

              option.documentItem = documentItem;
            }

            if (cell.cellProperty === CellProperty.IMAGE) {
              const index = rowHasImageIndex;

              documentItem = documentItems?.[index] as any;
              rowHasImageIndex++;

              option.documentItem = documentItem;

              if (!documentItem) {
                isRenderOther = false;
              }
            }

            if (
              ![CellProperty.IMAGE, CellProperty.DOCUMENT_DATA].includes(
                (cell?.cellProperty || "") as any
              ) &&
              !documentItems?.[rowHasImageIndex]
            ) {
              isRenderOther = false;
            }

            if (!isRenderOther) {
              return (
                <TableTdComponentPreview
                  key={cell.cellId}
                  {...tableTdComponentPreviewProps}
                  linkedImageProps={{
                    ...(linkedImageProps || ({} as iLinkedImageProps)),
                    option,
                  }}
                  tdProps={{
                    ...tdProps,
                  }}
                  isDisabled={isDisabled}
                />
              );
            }

            return (
              <TableTdComponentPreview
                key={cell.cellId}
                {...tableTdComponentPreviewProps}
                linkedImageProps={{
                  ...(linkedImageProps || ({} as iLinkedImageProps)),
                  option,
                }}
                isDisabled={isDisabled}
                tdProps={tdProps}
              />
            );
          })}
        </Tr>
      );
    });
  }, [
    isDisabled,
    component.detail?.rows,
    linkedImageProps,
    getTableTdComponentPreviewProps,
  ]);

  const renderRowsTypeSleevePipe = useMemo(() => {
    const limitItemTableLinked = sleevePipeProps?.limitItemTableLinked || 0;

    return component?.detail?.rows?.map((row, rowIndex) => {
      // case over limit item per page
      if (rowIndex + 1 > limitItemTableLinked || limitItemTableLinked === 0) {
        return <Tr key={rowIndex}></Tr>;
      }

      return (
        <Tr key={row.idRow} id={row.idRow}>
          {row?.cells?.map((cell, cellIndex) => {
            const tableTdComponentPreviewProps =
              getTableTdComponentPreviewProps({
                row,
                cell,
                rowIndex,
                cellIndex,
              });

            const numRow = component?.detail?.rows?.length;
            const isLastRow = rowIndex + 1 === component?.detail?.rows?.length;
            const isLastColumn =
              (row.cells?.length || 0) === cellIndex + cell.colSpan!;

            const tdProps = {
              "data-is-first-child": cellIndex === 0,
              "data-is-sub-table": !!cell?.subTable?.rows?.length,
              "data-is-last-child": isLastColumn,
              "data-is-last-row": isLastRow,
              "data-num-row": numRow,
              "data-is-table": "true",
            } as any;

            const option: iCommonDocumentTemplateProps["option"] = {
              rowIndex,
              cellIndex,
              subRowIndex: -1,
              subCellIndex: -1,
              currentCell: cell,
            };

            return (
              <TableTdComponentPreview
                key={cell.cellId}
                {...tableTdComponentPreviewProps}
                sleevePipeProps={{
                  ...(sleevePipeProps || ({} as iSleevePipeProps)),
                  option,
                }}
                tdProps={tdProps}
                hasKeyNote={hasKeyNote}
                isDisabled={isDisabled}
              />
            );
          })}
        </Tr>
      );
    });
  }, [
    isDisabled,
    hasKeyNote,
    component?.detail?.rows,
    sleevePipeProps,
    getTableTdComponentPreviewProps,
  ]);

  const renderRowsTypePAC = useMemo(() => {
    const limitItemTableLinked = pacProps?.limitItemTableLinked || 0;
    const displayItems = pacProps?.displayItems || [];
    const rowIndex = pacProps?.option?.rowIndex || 0;
    const offsetItemLinked = pacProps?.offsetItemLinked || 0;
    const subRowIndex = pacProps?.option?.subRowIndex || 0;
    const subCellIndex = pacProps?.option?.subCellIndex || 0;
    const headerComponent = pacProps?.headerComponent;
    const documentItem: DocumentItemDTO = displayItems![
      rowIndex + offsetItemLinked
    ] as any;

    return new Array(limitItemTableLinked).fill(0).map((_, rowIndex) => {
      const row =
        component?.detail?.rows?.[rowIndex] || component?.detail?.rows?.[0];

      return (
        <Tr
          key={rowIndex}
          style={{
            height: Math.floor(
              (getOriginalSize(component.detail?.rows!, row?.cells?.[0]!)
                .height /
                component.size.height) *
                displaySize.height
            ),
          }}
        >
          {row?.cells?.map((cell, cellIndex) => {
            let newCell = structuredClone(cell);
            const isSubCell = !!cell?.isSubCell;
            const headerTitleId = isSubCell
              ? headerComponent?.[cellIndex]?.subTable?.rows?.[subRowIndex]
                  ?.cells?.[subCellIndex]?.cellId
              : headerComponent?.[cellIndex]?.cellId;

            if (rowIndex >= displayItems?.length) {
              newCell = { ...cell, value: "" } as CellType;
            }

            const option: iCommonDocumentTemplateProps["option"] = {
              rowIndex,
              cellIndex,
              subRowIndex: -1,
              subCellIndex: -1,
              currentCell: cell,
            };

            const tableTdComponentPreviewProps =
              getTableTdComponentPreviewProps({
                row,
                cell: newCell,
                rowIndex,
                cellIndex,
              });

            const isLastColumn =
              (row.cells?.length || 0) === cellIndex + cell.colSpan!;
            const numRow = component?.detail?.rows?.length;

            const tdProps = {
              "data-header-title-id": headerTitleId,
              "data-is-first-child": cellIndex === 0,
              "data-is-sub-table": !!cell?.subTable?.rows?.length,
              "data-is-last-child": isLastColumn,
              "data-is-last-row": rowIndex + 1 === numRow,
              "data-num-row": numRow,
              "data-is-table": "true",
              "data-document-id": documentItem?.id,
            } as any;

            return (
              <TableTdComponentPreview
                key={newCell.cellId}
                {...tableTdComponentPreviewProps}
                pacProps={{ ...(pacProps || ({} as iPacProps)), option }}
                tdProps={tdProps}
                isDisabled={isDisabled}
              />
            );
          })}
        </Tr>
      );
    });
  }, [
    isDisabled,
    component?.detail?.rows,
    pacProps,
    displaySize.height,
    component.size.height,
    getTableTdComponentPreviewProps,
  ]);

  const renderRowsTypeModuleChiller = useMemo(() => {
    return component?.detail?.rows?.map((row, rowIndex) => {
      return (
        <Tr key={row.idRow} id={row.idRow}>
          {row?.cells?.map((cell, cellIndex) => {
            const isLastColumn =
              (row.cells?.length || 0) === cellIndex + cell.colSpan!;
            const numRow = component?.detail?.rows?.length;

            const tableTdComponentPreviewProps =
              getTableTdComponentPreviewProps({
                row,
                cell,
                rowIndex,
                cellIndex,
              });

            const option: iCommonDocumentTemplateProps["option"] = {
              rowIndex,
              cellIndex,
              subRowIndex: -1,
              subCellIndex: -1,
              currentCell: cell,
            };

            const tdProps = {
              "data-is-first-child": cellIndex === 0,
              "data-is-sub-table": !!cell?.subTable?.rows?.length,
              "data-is-last-child": isLastColumn,
              "data-is-last-row":
                rowIndex + 1 === component?.detail?.rows?.length,
              "data-num-row": numRow,
              "data-is-table": "true",
            } as any;

            return (
              <TableTdComponentPreview
                key={cell.cellId}
                {...tableTdComponentPreviewProps}
                moduleChillerProps={{
                  ...(moduleChillerProps || ({} as iModuleChillerProps)),
                  option,
                }}
                tdProps={tdProps}
                isDisabled={isDisabled}
              />
            );
          })}
        </Tr>
      );
    });
  }, [
    isDisabled,
    component?.detail?.rows,
    moduleChillerProps,
    getTableTdComponentPreviewProps,
  ]);

  const renderRowsTypeEditor = useMemo(() => {
    return component?.detail?.rows?.map((row, rowIndex) => (
      <Tr
        key={row.idRow}
        id={row.idRow}
        {...getTrProps(rowIndex)}
        style={{
          height:
            (getOriginalSize(component.detail?.rows!, row.cells?.[0]!).height /
              component.size.height) *
            displaySize.height,
        }}
      >
        {row?.cells?.map((cell, cellIndex) => {
          if (cell.colSpan === 0 || cell.rowSpan === 0) {
            return null;
          }
          const tableTdComponentPreviewProps = getTableTdComponentPreviewProps({
            row,
            cell: { ...cell, isDisabled },
            rowIndex,
            cellIndex,
          });

          const isLastColumn =
            (row.cells?.length || 0) === cellIndex + cell.colSpan!;
          const numRow = component?.detail?.rows?.length;

          const tdProps = {
            "data-is-first-child": cellIndex === 0,
            "data-is-sub-table": !!cell?.subTable?.rows?.length,
            "data-is-last-child": isLastColumn,
            "data-is-last-row": rowIndex + 1 === numRow,
            "data-num-row": numRow,
            "data-is-table": "true",
          } as any;

          return (
            <TableTdComponentPreview
              key={cell.cellId}
              {...tableTdComponentPreviewProps}
              tdProps={tdProps}
              hasKeyNote={hasKeyNote}
              isDisabled={isDisabled}
            />
          );
        })}
      </Tr>
    ));
  }, [
    hasKeyNote,
    component.size.height,
    displaySize.height,
    getTrProps,
    component?.detail?.rows,
    getTableTdComponentPreviewProps,
    isDisabled,
  ]);

  const renderRowsTypeTaskItemData = useMemo(() => {
    return component?.detail?.rows?.map((row, rowIndex) => (
      <Tr
        key={row.idRow}
        id={row.idRow}
        {...getTrProps(rowIndex)}
        style={{
          height:
            (getOriginalSize(component.detail?.rows!, row.cells?.[0]!).height /
              component.size.height) *
            displaySize.height,
        }}
      >
        {row?.cells?.map((cell, cellIndex) => {
          if (cell.colSpan === 0 || cell.rowSpan === 0) {
            return null;
          }

          const tableTdComponentPreviewProps = getTableTdComponentPreviewProps({
            row,
            cell,
            rowIndex,
            cellIndex,
          });

          const isLastColumn =
            (row.cells?.length || 0) === cellIndex + cell.colSpan!;
          const numRow = component?.detail?.rows?.length;

          const option: iTaskItemDataProps["option"] = {
            rowIndex,
          };

          const tdProps = {
            "data-is-first-child": cellIndex === 0,
            "data-is-sub-table": !!cell?.subTable?.rows?.length,
            "data-is-last-child": isLastColumn,
            "data-is-last-row": rowIndex + 1 === numRow,
            "data-num-row": numRow,
            "data-is-table": "true",
          } as any;

          return (
            <TableTdComponentPreview
              key={cell.cellId}
              {...tableTdComponentPreviewProps}
              tdProps={tdProps}
              taskItemDataProps={{
                ...(taskItemDataProps || ({} as iTaskItemDataProps)),
                option,
              }}
              isDisabled={isDisabled}
            />
          );
        })}
      </Tr>
    ));
  }, [
    isDisabled,
    taskItemDataProps,
    component.size.height,
    displaySize.height,
    component?.detail?.rows,
    getTrProps,
    getTableTdComponentPreviewProps,
  ]);

  const renderRows = useMemo(() => {
    switch (contentType) {
      case ContentType.SELF_INSPECTION:
        return renderRowsTypeFlexibleDuct;

      case ContentType.LINKED_IMAGE:
        return renderRowsTypeLinkedImage;

      case ContentType.PHOTO_LEDGER:
        return renderRowsTypeSleevePipe;

      case ContentType.PAC:
        return renderRowsTypePAC;

      case ContentType.EQUIPMENT_DATA_SHEET:
        return renderRowsTypeModuleChiller;

      case ContentType.TASK_ITEM_DATA:
        return renderRowsTypeTaskItemData;

      default:
        return renderRowsTypeEditor;
    }
  }, [
    contentType,
    renderRowsTypeEditor,
    renderRowsTypeLinkedImage,
    renderRowsTypeFlexibleDuct,
    renderRowsTypeSleevePipe,
    renderRowsTypeModuleChiller,
    renderRowsTypePAC,
    renderRowsTypeTaskItemData,
  ]);

  const backgroundColor = useMemo(() => {
    const bgColor = component?.detail?.style?.backgroundColor || "#fff";

    if (!isTableSelected) {
      return bgColor;
    }

    return hexToRgbA(bgColor, COMPONENT_SELECTED_OPACITY);
  }, [component?.detail?.style?.backgroundColor, isTableSelected]);

  const maxWidth = useMemo(() => {
    return getMaxWidthTable({
      isBlackboardTemplate,
      templatePages: template?.pages || currentTemplate?.pages,
      componentPage: component.page,
    });
  }, [
    currentTemplate?.pages,
    template?.pages,
    component.page,
    isBlackboardTemplate,
  ]);

  const isFullWidth = useMemo(() => {
    return checkTableFullWidth({
      isBlackboardTemplate,
      maxWidth,
      resizeWidth: component?.realSize?.width,
      width: component?.size?.width,
    });
  }, [
    maxWidth,
    component?.size?.width,
    component?.realSize?.width,
    isBlackboardTemplate,
  ]);

  const isOverlapRightSide = useMemo(() => {
    return checkTableOverlapRightSide({
      components: template?.components || currentTemplate?.components,
      isFullWidth,
      component,
      documentContainerSize,
      zoomRatio,
    });
  }, [
    component,
    currentTemplate?.components,
    template?.components,
    documentContainerSize,
    zoomRatio,
    isFullWidth,
  ]);

  return (
    <Box
      style={{
        display: "flex",
        position: "relative",
        width: "100%",
        height: "100%",
      }}
      tabIndex={0}
      {...flexContainerProps}
    >
      <TableContainer
        style={{
          width: "100%",
          height: "100%",
          overflowY: "initial",
          overflowX: "initial",
          minWidth: cellSize?.width,
          maxWidth:
            (contentType === ContentType.EDITOR &&
              documentContainerSize?.width) ||
            "100%",
        }}
      >
        {isResizable && !isOnlyView && (
          <HeaderCommon
            components={components}
            isSelectComponentOnly={isSelectTableOnly}
            component={component}
          />
        )}
        <Table
          id={`table-${component.componentId}`}
          style={{
            backgroundColor: backgroundColor,
            width: `calc(100% + ${
              isOverlapRightSide ? 1 * (zoomRatio || 1) : 0
            }px)`,
            border:
              isTableSelected && !component?.detail?.isRepeat
                ? `2px solid ${DEFAULT_BORDER_COLOR_ACTIVE}`
                : blackboardTemplateProps?.isOnlyView &&
                  isBlackboardTemplateImage
                ? "none"
                : "",
          }}
          className={`table-component table-wrapper-parent ${
            !!component.linkedHeaderId ? "linked-table" : ""
          }`}
        >
          <Tbody>{renderRows}</Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableComponentPreview;

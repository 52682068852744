import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconShowAreaActive(props: IconProps) {
  return (
    <Icon width="24" height="23" fill="none" viewBox="0 0 24 23" {...props}>
      <path
        fill="#04c1ff"
        fillRule="evenodd"
        d="m2.158 19.033-.306.74c.353.146.741.227 1.148.227h1.083v-1.6H3q-.29-.002-.535-.106zM18.2 3.875h.8V3a3 3 0 0 0-.228-1.148l-.739.306-.739.307q.104.244.106.535v.875zM3 0h1.083v1.6H3q-.29.002-.535.106l-.307-.74-.306-.738A3 3 0 0 1 3 0M.8 16.125H0V17c0 .407.081.795.228 1.148l.739-.306.739-.306A1.4 1.4 0 0 1 1.6 17v-.875zm0-1.75H0v-1.75h1.6v1.75zm0-3.5H0v-1.75h1.6v1.75zm0-3.5H0v-1.75h1.6v1.75zm0-3.5H0V3c0-.407.081-.795.228-1.148l.739.306.739.307A1.4 1.4 0 0 0 1.6 3v.875zM6.25.8V0h2.167v1.6H6.25zm4.333 0V0h2.167v1.6h-2.167zm4.334 0V0H16c.407 0 .795.081 1.148.228l-.306.739-.306.739A1.4 1.4 0 0 0 16 1.6h-1.083zM18.2 5.625h.8v1.75h-1.6v-1.75zM8.417 19.2v.8H6.25v-1.6h2.167z"
        clipRule="evenodd"
      />
      <path
        stroke="#04c1ff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M12.31 15.385c-.073-.117-.11-.175-.13-.265a.6.6 0 0 1 0-.242c.02-.09.057-.149.13-.266.61-.965 2.425-3.405 5.19-3.405s4.58 2.44 5.19 3.405c.073.117.11.176.13.266a.6.6 0 0 1 0 .242c-.02.09-.057.148-.13.265-.61.965-2.425 3.405-5.19 3.405s-4.58-2.44-5.19-3.405"
      />
      <path
        stroke="#04c1ff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M17.5 16.624a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25"
      />
    </Icon>
  );
}

import { Attribute, mergeAttributes, Node } from "@tiptap/core";
import { DOMOutputSpec } from "@tiptap/pm/model";
import { NodeType } from "../type";

export interface TableComponentNodeAttrs {
  tableId: string;
  backgroundColor: string;
  offsetWidth: number;
}

export const TableComponentNode = Node.create({
  name: NodeType.TABLE_COMPONENT,
  group: "block",
  content: `${NodeType.TABLE_TR_COMPONENT}*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  addAttributes() {
    const attrs: { [key in keyof TableComponentNodeAttrs]: Attribute } = {
      tableId: { default: null },
      backgroundColor: { default: "#fff" },
      offsetWidth: {
        default: 0,
      },
    };

    return attrs;
  },

  renderHTML({ HTMLAttributes }) {
    const { backgroundColor, offsetWidth, tableId } =
      HTMLAttributes as TableComponentNodeAttrs;
    const table: DOMOutputSpec = [
      "table",
      mergeAttributes({
        id: tableId,
        style: `background-color: ${backgroundColor}; width: calc(100% + ${offsetWidth}px)`,
      }),
      ["tbody", 0],
    ];

    return table;
  },
});

import { message } from "components/base";
import { useAppIOSConnectionProvider } from "pages/forge-viewer/context/AppIosConnection";
import { Type } from "pages/forge-viewer/context/AppIosConnection/useAppIOSConnection";
import { useCallback, useEffect } from "react";

const useDeviceData = ({
  onReceived,
  identifyInfo,
  id,
}: {
  onReceived(data: any): void;
  identifyInfo: any;
  id: string;
}) => {
  const { requestDeviceDataAtNeptune, receivedData, clear, isConnected } =
    useAppIOSConnectionProvider();

  useEffect(() => {
    if (receivedData?.id === id) {
      try {
        const timeout = setTimeout(() => {
          onReceived({
            ...receivedData.data,
            type: receivedData.type,
          });
          clear();
        }, 500);

        return () => {
          clearTimeout(timeout);
        };
      } catch (error: any) {
        message.error(["Error when parse raw data to json"]);
        throw new Error(error);
      }
    }
  }, [clear, id, onReceived, receivedData]);

  const sendRequest = useCallback(() => {
    if (!isConnected[Type.DEVICE]) {
      message.warning(["Can't connect to device"]);
    }
    requestDeviceDataAtNeptune(id, identifyInfo);
  }, [id, identifyInfo, isConnected, requestDeviceDataAtNeptune]);

  return {
    sendRequest,
  };
};

export default useDeviceData;

import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { convertRGBAToHex } from "utils/color";
import { ColorPickerMicrosoftContext } from "../context";
import "./MoreColorsModal.scss";

type MoreColorsModalProps = Omit<ModalProps, "children"> & {
  children?: React.ReactNode;
};

const MoreColorsModal: React.FC<MoreColorsModalProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  const [color, setColor] = useState<string>("");
  const { colorPicker, onHandleChangeComplete } = useContext(
    ColorPickerMicrosoftContext
  );

  useEffect(() => {
    if (isOpen) {
      setColor(colorPicker.color);
    }
  }, [isOpen, colorPicker.color]);

  const onHandleChange = useCallback((color: ColorResult) => {
    setColor(convertRGBAToHex(color.rgb));
  }, []);

  const onHandleClose = useCallback(() => {
    setColor(colorPicker.color);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, onHandleChangeComplete]);

  const onHandleConfirm = useCallback(() => {
    onHandleChangeComplete({
      color,
    });
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, onHandleChangeComplete]);

  return (
    <Modal {...props} isOpen={isOpen} onClose={onClose} isCentered closeOnEsc>
      <ModalOverlay />
      <ModalContent maxWidth="420px" width="100%">
        <Box maxWidth="min-content" className="moreColorsModal">
          <SketchPicker color={color} onChange={onHandleChange} />
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={4} m="8px">
          <Button onClick={onHandleClose}>キャンセル</Button>
          <Button variant="filled" onClick={onHandleConfirm}>
            確認
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default memo(MoreColorsModal);

import { Box, Spinner, Text } from "@chakra-ui/react";
import { Attribute, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import useKeynoteImage from "components/modal/PreviewDocumentCategory/hooks/useKeynoteImage";
import KeyLabel from "components/ui/KeyLabel";
import { PresignedImageWithRef } from "components/ui/PresignedImage";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import { KEYNOTE_IMAGE_EDITOR_CLASSNAME } from "constants/styleProps";
import EditorDocumentContext from "contexts/EditorDocumentContext";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { KeynoteImageData } from "interfaces/models/documentKeynote";
import { getColorTextByStatus } from "models/document";
import { memo, useContext, useMemo, useState } from "react";
import { CustomNodeComponentProps, NodeType } from "../type";

export interface KeynoteNodeAttrs {
  componentHeight: number;
  componentWidth: number;
  keynoteImageDataProp: KeynoteImageData | undefined;
}

export const KeynoteNode = Node.create({
  name: NodeType.KEYNOTE,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: {
      [key in keyof KeynoteNodeAttrs]: Attribute;
    } = {
      componentHeight: { default: 0 },
      componentWidth: { default: 0 },
      keynoteImageDataProp: { default: undefined },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "keynote",
      },
    ];
  },
  renderHTML() {
    return ["keynote", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = memo((props: CustomNodeComponentProps<KeynoteNodeAttrs>) => {
  const { componentHeight, componentWidth, keynoteImageDataProp } =
    props.node.attrs;

  const [isImageError, setIsImageError] = useState(false);

  const { keynoteImageData: keynoteImageDataContext, isChangeSheet } =
    useContext(PreviewDocumentCategoryContext);
  const {
    isContentFromS3,
    isEditMode,
    isResetDataKeynote,
    isCreatingKeynote,
    callbackDragKeynoteStop,
  } = useContext(EditorDocumentContext);

  const keynoteImageData = keynoteImageDataProp || keynoteImageDataContext;

  const {
    keynoteContainerRef,
    keynoteImage,
    isLoadKeynoteImage,
    keyLabelsProps,
    keynoteImageSize,
    onLoadKeynoteImage,
  } = useKeynoteImage({
    isChangeSheet,
    keynoteImageData,
    keynoteImageClass: KEYNOTE_IMAGE_EDITOR_CLASSNAME,
  });

  const isLoading =
    keynoteImageData !== null &&
    (isLoadKeynoteImage || !keynoteImage || isChangeSheet);

  const renderContent = useMemo(() => {
    if (isCreatingKeynote || isChangeSheet) {
      return (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner color="blue.500" />
        </Box>
      );
    }

    if (!isEditMode && !isContentFromS3) {
      return <></>;
    }

    if (isImageError) {
      return (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              color: "#171717",
            }}
          >
            Image Error
          </Text>
        </Box>
      );
    }

    return (
      <>
        <Box
          ref={keynoteContainerRef}
          style={{
            position: "relative",
            width: "fit-content",
            margin: "0 auto",
            height: "fit-content",
          }}
        >
          <PresignedImageWithRef
            src={keynoteImage}
            id={KEYNOTE_IMAGE_EDITOR_CLASSNAME}
            isConvertToBase64={true}
            crossOrigin="anonymous"
            alt=""
            width="100%"
            height="auto"
            onLoad={onLoadKeynoteImage}
            callBackPreSignError={() => {
              setIsImageError(true);
            }}
            boxLoadingWidth={componentWidth}
            boxLoadingHeight={componentHeight}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: componentHeight ? `${componentHeight}px` : "100%",
              border: "1px solid #555",
              borderWidth: "0.5px 1px 0.5px 0.5px",
              margin: "auto",
            }}
          />

          {!isLoading &&
            keyLabelsProps?.map((item) => (
              <KeyLabel
                key={item.id}
                {...item}
                isEditMode={isEditMode}
                isResetData={isResetDataKeynote}
                imageSize={keynoteImageSize}
                color={
                  MapDocumentCategoryStatusTypeColor[
                    (item?.status ||
                      DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
                  ]
                }
                callbackDragStop={callbackDragKeynoteStop}
                colorText={getColorTextByStatus(item?.status)}
              />
            ))}
        </Box>
      </>
    );
  }, [
    isChangeSheet,
    isCreatingKeynote,
    isContentFromS3,
    keynoteImageSize,
    keynoteContainerRef,
    keynoteImage,
    keyLabelsProps,
    isLoading,
    componentWidth,
    componentHeight,
    isImageError,
    isEditMode,
    isResetDataKeynote,
    onLoadKeynoteImage,
    callbackDragKeynoteStop,
  ]);

  return (
    <NodeViewWrapper
      id="keynote-container"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {renderContent}
    </NodeViewWrapper>
  );
});

import { Checkbox, Flex, FlexProps } from "@chakra-ui/react";
import { DocumentStatusType, MapDocumentStatusTypeColor } from "constants/enum";
import React, { useEffect, useState } from "react";

interface Props {
  status?: string;
  numberOfValue?: number;
  onChange: (value: DocumentStatusType) => void;
  isDisabled?: boolean;
}

let onChangeTimer: NodeJS.Timer;

const StatusCheckbox = ({
  status,
  numberOfValue = 1,
  onChange,
  isDisabled,
  ...rest
}: Props & FlexProps) => {
  const [statusLocal, setStatusLocal] = useState(status);

  useEffect(() => {
    setStatusLocal(status);
  }, [status]);

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const newStatus = String(
      (Number(statusLocal || "0") + 1) % (numberOfValue + 1)
    );
    setStatusLocal(newStatus);

    clearTimeout(onChangeTimer);
    onChangeTimer = setTimeout(() => {
      onChange(newStatus as DocumentStatusType);
    }, 300);
  };

  if (statusLocal === DocumentStatusType.Complete) {
    return (
      <Checkbox
        isDisabled={isDisabled}
        onChange={handleClick as any}
        isChecked={true}
        sx={{
          ".chakra-checkbox__control": {
            boxShadow: "none !important",
          },
        }}
      />
    );
  }

  if (statusLocal === DocumentStatusType.NotGood) {
    return (
      <Checkbox
        onChange={handleClick as any}
        isChecked={true}
        isDisabled={isDisabled}
        sx={{
          ".chakra-checkbox__control": {
            background: MapDocumentStatusTypeColor[DocumentStatusType.NotGood],
            borderColor: MapDocumentStatusTypeColor[DocumentStatusType.NotGood],
            boxShadow: "none !important",
          },

          ".chakra-checkbox__control:hover": {
            background: MapDocumentStatusTypeColor[DocumentStatusType.NotGood],
            borderColor: MapDocumentStatusTypeColor[DocumentStatusType.NotGood],
          },
        }}
      />
    );
  }

  return (
    <Flex
      borderWidth="2px"
      borderColor="#A3A3A3"
      borderRadius="4px"
      flexShrink="0"
      w="1.8rem"
      h="1.8rem"
      justifyContent="center"
      alignItems="center"
      cursor={isDisabled ? "not-allowed" : "pointer"}
      onClick={(e) => {
        if (isDisabled) return;
        handleClick(e as any);
      }}
      {...rest}
    ></Flex>
  );
};

export default StatusCheckbox;

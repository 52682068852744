import { UserSetting } from "interfaces/models/user";
import { DEFAULT_DOCUMENT_FILTER_DATA } from "constants/document";
import FuzzySearch from "fuzzy-search";
import { iDocumentFilterData, Level } from "interfaces/models";
import isEqual from "lodash/isEqual";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import { areArraysEqual } from "./common";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DEFAULT_TASK_FILTER_DATA } from "constants/task";

export const fuzzySearch = (items: any[], searchValue: string, opts?: any) => {
  const searcher = new FuzzySearch(items, opts?.keys, {
    sort: true,
  });

  return searcher.search(searchValue);
};

export const isDefaultSearchDocument = (
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps,
  setDocumentCategoryId: Set<string>
) => {
  const searchDocumentValue =
    filterDocumentCategoryOptions?.searchDocumentValue;

  const settings = filterDocumentCategoryOptions?.settings || {};
  const documentFilterData: iDocumentFilterData = {
    documentUserCreated: settings.documentUserCreated,
    documentUserAssigned: settings.documentUserAssigned,
    documentHasImage: settings.documentHasImage,
    documentHasNoImage: settings.documentHasNoImage,
    documentTypeDocuments: (settings?.documentTypeDocuments || [])?.filter(
      (val) => setDocumentCategoryId.has(val)
    ),
    documentAreas: settings.documentAreas,
    documentWorkTypes: settings.documentWorkTypes,
    documentTypeInspections: settings.documentTypeInspections,
    documentTags: settings.documentTags || [],
    documentPriority: [...(settings.documentPriority || [])].sort(),
    documentStatus: [...(settings.documentStatus || [])].sort(),
    defaultDocumentEndDate: false,
    documentGroups: settings?.documentGroups || [],
  };

  return (
    isEqual(documentFilterData, DEFAULT_DOCUMENT_FILTER_DATA) &&
    !searchDocumentValue
  );
};

export const checkIsNotEqualFilterDataDocument = (
  settings: UserSetting,
  levelSelected: Level,
  documentCategories: DocumentCategoryDTO[]
) => {
  const categoryIds = documentCategories
    .filter(
      (category) =>
        !levelSelected.guid || levelSelected.label === category.level
    )
    .map((cate) => cate.id);
  const setDocumentCategoryId = new Set(categoryIds);

  const documentFilterData: iDocumentFilterData = {
    documentUserCreated: settings.documentUserCreated,
    documentUserAssigned: settings.documentUserAssigned,
    documentHasImage: settings.documentHasImage,
    documentHasNoImage: settings.documentHasNoImage,
    documentTypeDocuments: (settings?.documentTypeDocuments || []).filter(
      (val) => setDocumentCategoryId.has(val)
    ),
    documentAreas: settings.documentAreas,
    documentWorkTypes: settings.documentWorkTypes,
    documentTypeInspections: settings.documentTypeInspections,
    documentTags: settings.documentTags,
    documentPriority: settings.documentPriority,
    documentStatus: settings.documentStatus,
    defaultDocumentEndDate: settings.defaultDocumentEndDate,
    documentGroups: settings.documentGroups || [],
  };

  return !Object.keys(documentFilterData).every((key: string) => {
    const documentFilterDataValue =
      documentFilterData[key as keyof iDocumentFilterData];
    const defaultDocumentFilterData =
      DEFAULT_DOCUMENT_FILTER_DATA[key as keyof iDocumentFilterData];

    return Array.isArray(documentFilterDataValue)
      ? areArraysEqual(
          documentFilterDataValue,
          defaultDocumentFilterData as any[]
        )
      : isEqual(documentFilterDataValue, defaultDocumentFilterData);
  });
};

export const checkIsNotEqualFilterDataTask = (settings: UserSetting) => {
  const taskFilterData: Partial<UserSetting> = {
    attached: settings.attached,
    tags: settings.tags,
    status: settings.status,
    contents: settings.contents,
    userCreated: settings?.userCreated,
    userAssigned: settings.userAssigned,
    objectTypes: settings.objectTypes,
    hasImage: settings.hasImage,
    hasNoImage: settings.hasNoImage,
    documentTaskIds: settings?.documentTaskIds,
    taskDateFrom: settings?.taskDateFrom || undefined,
    taskDateTo: settings?.taskDateTo || undefined,
    deadlineFrom: settings.deadlineFrom || undefined,
    deadlineTo: settings.deadlineTo || undefined,
    taskPrintMode: settings.taskPrintMode,
    partnerCompanies: settings?.partnerCompanies || [],
    defaultDeadline: settings?.defaultDeadline,
    taskCategoryIds: settings?.taskCategoryIds,
  };

  return !Object.keys(taskFilterData).every((key: string) => {
    const taskFilterDataValue =
      taskFilterData[key as keyof typeof taskFilterData];
    const defaultTaskFilterDataValue =
      DEFAULT_TASK_FILTER_DATA[key as keyof typeof DEFAULT_TASK_FILTER_DATA];

    return Array.isArray(taskFilterDataValue)
      ? areArraysEqual(taskFilterDataValue, defaultTaskFilterDataValue as any[])
      : isEqual(taskFilterDataValue, defaultTaskFilterDataValue);
  });
};

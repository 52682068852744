import { message } from "components/base";
import { ModalType, SystemModeType } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDocumentCategorySelected,
  setDocumentItemSelected,
} from "redux/documentSlice";
import { setModalType, setSystemMode } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { setTaskSelected } from "redux/taskSlice";
import { logDev } from "utils/logs";

export enum ReceivedDeviceType {
  PRESSURE = "1",
}

export enum Type {
  PHOTO = "0",
  DEVICE = "1",
}

export interface AppIosResponse {
  identify: string;
  [key: string]: any;
}

export interface RequestPhotoDataAtNeptuneSubItem {
  documentCategoryId: string;
  documentItemId: string;
  subItemId: string;
}

export interface RequestPhotoDataAtNeptuneTask {
  taskId: string;
}

export interface UseAppIOSConnection {
  documentCategories: DocumentCategoryDTO[];
  tasks: TaskDTO[];
}

export type Response =
  | ({
      data: Omit<AppIosResponse, "signature">;
    } & { id: string; decrypted: any; type: Type })
  | null;

const useAppIOSConnection = ({
  documentCategories,
  tasks,
}: UseAppIOSConnection) => {
  const dispatch = useDispatch();
  const { documentCategorySelected, documentItemSelected } = useSelector(
    (state: RootState) => state.document
  );
  const { taskSelected } = useSelector((state: RootState) => state.task);
  const { systemMode, modalType } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const [response, setResponse] = useState<Response>(null);
  const documentRef = useRef({
    documentCategorySelected,
    documentItemSelected,
    systemMode,
    modalType,
    documentCategories,
    tasks,
    taskSelected,
  });
  documentRef.current = {
    documentCategorySelected,
    documentItemSelected,
    systemMode,
    modalType,
    documentCategories,
    tasks,
    taskSelected,
  };

  const identifyInfoRef = useRef<Record<string, any>>({});

  const requestPhotoDataAtNeptune = useCallback((id: string, payload: any) => {
    logDev("identify_info", id);

    identifyInfoRef.current[id] = payload;

    if (!window.webkit?.messageHandlers?.requestPhotoDataAtNeptune)
      return false;

    window.webkit.messageHandlers.requestPhotoDataAtNeptune.postMessage(
      JSON.stringify({ identify_info: id })
    );

    return true;
  }, []);

  const requestDeviceDataAtNeptune = useCallback((id: string, payload: any) => {
    logDev("identify_info", id);

    identifyInfoRef.current[id] = payload;

    if (!window.webkit?.messageHandlers?.requestDeviceDataAtNeptune)
      return false;

    window.webkit.messageHandlers.requestDeviceDataAtNeptune.postMessage(
      JSON.stringify({ identify_info: id, type: ReceivedDeviceType.PRESSURE })
    );

    return true;
  }, []);

  const receiveDataAtNeptune = useCallback(
    async (type: Type, response: any) => {
      if (!response) return;

      try {
        const result: AppIosResponse =
          typeof response === "string" ? JSON.parse(response) : response;
        const { identify_info, ...other } = result;

        const decrypted = identifyInfoRef.current[identify_info];

        if (!decrypted) {
          logDev("[receiveDataAtNeptune] Action not found", response);

          return;
        }
        delete identifyInfoRef.current[identify_info];

        const { documentCategoryId, documentItemId, subItemId, taskId } =
          decrypted;

        if (subItemId) {
          const documentCategory = documentRef.current.documentCategories.find(
            (item) => item.id === documentCategoryId
          );
          if (!documentCategory) return;

          const documentItem = documentCategory?.documentItems?.find(
            (item) => item.id === documentItemId
          );
          if (!documentItem) return;

          const subItem = documentItem?.subItems?.find(
            (item) => item.id === subItemId
          );
          if (!subItem) return;

          if (documentRef.current.systemMode !== SystemModeType.Document) {
            dispatch(setSystemMode(SystemModeType.Document));
          }

          if (documentRef.current.modalType !== ModalType.DOC_ITEM) {
            dispatch(setModalType(ModalType.DOC_ITEM));
          }

          if (
            documentRef.current.documentCategorySelected?.id !==
            documentCategory.id
          ) {
            dispatch(setDocumentCategorySelected(documentCategory));
          }

          if (
            documentRef.current.documentItemSelected?.id !== documentItem.id
          ) {
            dispatch(setDocumentItemSelected(documentItem));
          }
        } else if (taskId) {
          const task = documentRef.current.tasks.find(
            (item) => item.id === taskId
          );
          if (!task) return;

          if (documentRef.current.systemMode !== SystemModeType.Task) {
            dispatch(setSystemMode(SystemModeType.Task));
          }

          if (documentRef.current.modalType !== ModalType.TASK) {
            dispatch(setModalType(ModalType.TASK));
          }

          if (documentRef.current.documentItemSelected?.id !== taskId) {
            dispatch(setTaskSelected(task));
          }
        }

        setResponse({ data: other, id: identify_info, decrypted, type });
      } catch (error: any) {
        logDev(error);
        /** Ignore */
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    (window as any).receivePhotoDataAtNeptune = (message: any) => {
      receiveDataAtNeptune(Type.PHOTO, message);
    };
    (window as any).receiveDeviceDataAtNeptune = (message: any) => {
      receiveDataAtNeptune(Type.DEVICE, message);
    };
  }, [receiveDataAtNeptune]);

  useEffect(() => {
    return () => {
      delete (window as any).receivePhotoDataAtNeptune;
      delete (window as any).receiveDeviceDataAtNeptune;
    };
  }, []);

  const isConnected = useMemo(() => {
    return {
      [Type.PHOTO]: !!window.webkit?.messageHandlers?.requestPhotoDataAtNeptune,
      [Type.DEVICE]:
        !!window.webkit?.messageHandlers?.requestDeviceDataAtNeptune,
    };
  }, []);

  return {
    requestPhotoDataAtNeptune,
    requestDeviceDataAtNeptune,
    receivedData: response,
    isConnected,
    clear: useCallback(() => setResponse(null), []),
  };
};

export default useAppIOSConnection;

import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { Axis } from "constants/enum";
import { OPERATION } from "constants/task";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTask, MainImageData } from "interfaces/models/documentTask";
import { UserSetting } from "interfaces/models/user";
import {
  checkIsTaskLabelChangeStatusAndPosition,
  checkSheetImageValid,
} from "models/documentTask";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { fetchTaskSheetTemplates, resetTaskIdsCreated } from "redux/taskSlice";
import { setUserSetting } from "redux/userSlice";
import { sleep } from "utils/common";
import useTaskSheet from "./hooks";
import useGetSheetOptions from "./hooks/useGetSheetOptions";
import useGetTaskSheetTemplate from "./hooks/useGetTaskSheetTemplate";
import useKeyPlan from "./hooks/useKeyplan";
import usePreviewTaskSheetTemplate from "./hooks/usePreviewTaskSheetTemplate";
import useScrollTaskSheetPage from "./hooks/useScrollTaskSheetPage";
import TaskSheetHeader from "./TaskSheetHeader";
import VirutalScroll from "./VirtualScroll";
import { setNextPreviewPage } from "redux/documentSlice";
import React from "react";

interface Props {
  currentTaskList?: TaskDTO[];
  qrCodeImage: string;
  loading?: boolean;
  initTasks?: TaskDTO[];
  documentTask: DocumentTask;
  documentTasks: DocumentTask[];
  settings: UserSetting;
  bimFileId: string;
  onClose: () => void;
  onGotoForgeViewPage: (params?: { operation: string; data: string }) => void;
  onUpdateDocumentTask?: (documentTask: DocumentTask) => void;
  onChangeRatio: (axis: Axis) => Promise<any>;
  onChangeSheet: (value: string | undefined, isRecapture: boolean) => void;
  onChangeTaskSheetTemplate: (
    templateId: string,
    documentTitle: string
  ) => void;
}

const TaskSheet = ({
  bimFileId,
  currentTaskList,
  initTasks,
  documentTasks,
  qrCodeImage,
  loading,
  documentTask,
  onGotoForgeViewPage,
  onClose,
  onUpdateDocumentTask,
  onChangeRatio,
  onChangeSheet,
  onChangeTaskSheetTemplate,
}: Props) => {
  const dispatch = useDispatch();
  const taskSheetContainerRef =
    useRef() as React.MutableRefObject<HTMLDivElement>;
  const taskSheetsRef = useRef([]) as React.MutableRefObject<
    (HTMLDivElement | null)[]
  >;
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { settings } = useSelector((state: RootState) => state.user);

  const { sheetOptions, sheetIdSelected } = useGetSheetOptions({
    documentTask,
    dataProjectDetail,
  });

  const virutalScrollRef = useRef<any>();

  const scrollToPage = useCallback((pageIndex: number) => {
    virutalScrollRef.current?.scrollToRow?.(pageIndex);
  }, []);

  const {
    taskSheetTemplateList,
    componentsOfTemplate,
    taskSheetTemplate,
    taskSheetTemplateId,
  } = useGetTaskSheetTemplate({ documentTask, dataProjectDetail });

  const {
    isDiffTasksStatus,
    isCheckStatusDone,
    tasks,
    zoomPageValue,
    loadingDownload,
    loadingPrint,
    loadingEdit,
    editMode,
    documentTaskData,
    zoomPageValueRef,
    handleDownloadPDF,
    setEditMode,
    onEditDocumentTask,
    setDocumentTaskDataChange,
    onChangeZoomPage,
    setTasks,
    setZoomPageValue,
    clearCachePdf,
  } = useTaskSheet({
    bimFileId,
    sheetOptions,
    sheetIdSelected,
    currentTaskList,
    initTasks,
    documentTasks,
    documentTask,
    onUpdateDocumentTask,
    onChangeSheet,
    scrollToPage,
  });

  const {
    isChangeRatio,
    isResetDataKeyplan,
    keyplanContainerRef,
    keyLabelsProps,
    keyplanImage,
    keyplanImageSize,
    setIsChangeRatio,
    onLoadKeyplanImage,
    setKeyPlanImage,
    callbackDragKeynoteStop,
    setIsResetDataKeylan,
    handleTransformKeylabelEdited,
  } = useKeyPlan({
    editMode,
    loading: !!loading,
    tasks,
    documentTask,
  });

  const {
    setTitle,
    isAllImageOfTaskLoaded,
    axisKeyplanDefault,
    pages,
    renderPages,
    isKeyplanFullPage,
    isHasKeyplanComponent,
    isGeneratingPdf,
  } = usePreviewTaskSheetTemplate({
    keyplanImageSize,
    isChangeRatio,
    isResetDataKeyplan,
    zoomPageValueRef,
    bimFileId,
    taskSheetTemplate,
    loadingEdit,
    documentTasks,
    editMode,
    zoomPageValue,
    taskSheetContainerRef,
    componentsOfTemplate,
    keyLabelsProps,
    documentTask,
    taskSheetsRef,
    keyplanContainerRef,
    documentTaskData,
    qrCodeImage,
    tasks,
    keyplanImage,
    loading: !!loading,
    onLoadKeyplanImage,
    setDocumentTaskDataChange,
    setZoomPageValue,
    callbackDragKeynoteStop,
    onClose,
  });

  const { currentPage, onBlurPage, onPrevPage, onNextPage, onChangePage } =
    useScrollTaskSheetPage({
      totalPage: pages?.length ?? 0,
      scrollToPage,
      taskSheetsRef,
      taskSheetContainerRef,
      isScroll: !(loadingPrint || loadingDownload),
    });

  const handleChangeSheet = async (guid: string) => {
    let mainImageData: MainImageData = documentTask?.mainImageData || {};

    const { isImageValid } = await checkSheetImageValid({
      documentTask,
    });

    if (mainImageData?.guid === guid && isImageValid) {
      return;
    }

    mainImageData = { ...mainImageData, guid };

    const newDocumentTask = {
      ...documentTask,
      mainImageData,
    };

    const {
      isDiffStatus: isDiffTasksStatus,
      isDiffPosition: isDiffTasksPosition,
    } = checkIsTaskLabelChangeStatusAndPosition(newDocumentTask, tasks ?? []);

    const isRecapture = isDiffTasksStatus || isDiffTasksPosition;
    setKeyPlanImage(null as any);
    onChangeSheet(guid, isRecapture);
    clearCachePdf();
  };

  const handleClose = () => {
    setKeyPlanImage(null as any);
    setTasks(null);

    // Reset documentTaskIds in filter when back to forge viewer from task sheet
    if (!!settings?.documentTaskIds?.length) {
      dispatch(
        setUserSetting({ settings: { ...settings, documentTaskIds: [] } })
      );
    }

    onClose();
  };

  const handleOnViewTaskList = useCallback(() => {
    const filterDataJson = documentTask?.filterData || {};
    const data = {
      guid: sheetIdSelected,
      filterData: JSON.stringify(filterDataJson),
      settings: {
        documentTaskIds: [documentTask.id],
      },
    };

    dispatch(resetTaskIdsCreated());
    onGotoForgeViewPage({
      operation: OPERATION.ExportTask,
      data: JSON.stringify(data),
    });
  }, [sheetIdSelected, documentTask, onGotoForgeViewPage, dispatch]);

  const onCancelEdit = useCallback(() => {
    setEditMode(false);
    setIsResetDataKeylan(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEnableEditMode = useCallback(() => {
    setEditMode(true);
    setIsResetDataKeylan(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const beforeSaveDocumentTask = useCallback(() => {
    const mainImageData = handleTransformKeylabelEdited();

    onEditDocumentTask(mainImageData);
    setIsResetDataKeylan(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEditDocumentTask]);

  const beforeChangeRatio = useCallback(
    async (axis: Axis) => {
      clearCachePdf();
      setIsChangeRatio(true);
      await onChangeRatio(axis);
      virutalScrollRef.current.reset();
      await sleep(2000);
      setIsChangeRatio(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeRatio]
  );

  useEffect(() => {
    dispatch(setNextPreviewPage(0));
    if (taskSheetTemplateList.length) return;
    dispatch(fetchTaskSheetTemplates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      zIndex="modal"
      width="100vw"
      height="100%"
      background="#fafafb"
      flexDirection="column"
    >
      <TaskSheetHeader
        documentTasks={documentTasks}
        documentTaskData={documentTaskData}
        bimFileId={bimFileId}
        axisKeyplanDefault={axisKeyplanDefault}
        sheetIdSelected={sheetIdSelected}
        sheetOptions={sheetOptions}
        isKeyplanFullPage={isKeyplanFullPage}
        taskSheetTemplateId={taskSheetTemplateId || ""}
        taskSheetTemplateList={taskSheetTemplateList}
        zoomPageValue={zoomPageValue}
        documentTask={documentTask}
        editMode={editMode}
        loadingEdit={loadingEdit}
        loadingCheckTaskSheetTemplate={
          documentTaskData === null ||
          loading ||
          (!isCheckStatusDone && !isDiffTasksStatus)
        }
        loading={
          documentTaskData === null ||
          loading ||
          (keyLabelsProps === null && isHasKeyplanComponent) ||
          (!isCheckStatusDone && isHasKeyplanComponent)
        }
        loadingDownload={loadingDownload}
        loadingPrint={loadingPrint}
        currentPage={currentPage}
        totalPage={(pages || [])?.length}
        onViewTaskList={handleOnViewTaskList}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        onChangePage={onChangePage}
        onBlurPage={onBlurPage}
        onEditDocumentTask={beforeSaveDocumentTask}
        onClose={handleClose}
        onDownloadPDF={(isPrint) => () => handleDownloadPDF(isPrint)}
        onChangeRatio={beforeChangeRatio}
        onChangeSheet={handleChangeSheet}
        onChangeZoomPage={onChangeZoomPage}
        onChangeTaskSheetTemplate={(
          templateId: string,
          documentTitle: string
        ) => {
          clearCachePdf();
          onChangeTaskSheetTemplate(templateId, documentTitle);
        }}
        setTitle={setTitle}
        onUpdateDocumentTask={onUpdateDocumentTask}
        onCancelEdit={onCancelEdit}
        onEnableEditMode={onEnableEditMode}
      />

      {isGeneratingPdf && (
        <Box
          zIndex={123}
          bgColor="white"
          h="100%"
          w="100%"
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          opacity={0.9}
        >
          <Text fontSize="3rem" fontWeight="600" color="rgba(0,0,0,0.7)">
            読み込み中...
          </Text>
        </Box>
      )}

      {(loading || !isCheckStatusDone || documentTaskData === null) && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          zIndex="2"
          top="0px"
          left="0px"
          background="#fff"
        >
          <Spinner color="blue.500" size="lg" />
        </Flex>
      )}

      <Box
        w="100%"
        bgColor="#000"
        overflowY="auto"
        ref={taskSheetContainerRef}
        scrollBehavior="smooth"
        id="container-preview"
        flex={1}
      >
        {isAllImageOfTaskLoaded &&
          isCheckStatusDone &&
          documentTaskData !== null && (
            <VirutalScroll
              ref={virutalScrollRef}
              scale={zoomPageValue}
              containerRef={taskSheetContainerRef}
            >
              {renderPages}
            </VirutalScroll>
          )}
      </Box>
    </Flex>
  );
};

export default TaskSheet;

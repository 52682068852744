import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconNavigationLeftLight(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        data-name="↳Color"
        fill="#171717"
        fillRule="evenodd"
        d="M15.471 6.195c.26.26.26.683 0 .943L10.61 12l4.861 4.862a.667.667 0 1 1-.942.943L9.195 12.47a.667.667 0 0 1 0-.942l5.334-5.334c.26-.26.682-.26.942 0"
        clipRule="evenodd"
      />
    </Icon>
  );
}

import {
  Operation,
  StoreName,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { MessageType } from "constants/websocket";
import { useAppWebSocket } from "hooks/useAppWebSocket";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocumentGroup,
  removeDocumentCategory,
  removeDocumentGroup,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import { removeTask, setTask, setTaskSelected } from "redux/taskSlice";
import { getIndexedDb } from "utils/indexedDb";
import {
  getCurrentViewer,
  selectDbIds,
  clearForgeSelection,
} from "utils/forge";
import ReceiveDataSocketHelper from "helper/receiveDataSocketHelper";
import { addIndexedDBItemIfNotExist } from "utils/serviceWorker";

export async function saveIndexDB(
  store: StoreName,
  operation: Operation,
  data: any
) {
  const indexDb = await getIndexedDb();
  indexDb?.put(data.id, {
    requestTime: Date.now(),
    store,
    operation,
    status: UpdateToOnlineStatus.Success,
    data: operation === Operation.Delete ? data.id : data,
  } as iCachedItem);
}

const useSyncTaskData = () => {
  const dispatch = useDispatch();
  const { webSocketMessages, isWebSocketOpen, sendWebSocketMessage } =
    useAppWebSocket();

  const { taskSelected } = useSelector((state: RootState) => state.task);

  const { levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const taskSelectedRef = useRef(taskSelected);
  const levelSelectedRef = useRef(levelSelected);
  const receiveDataSocketHelper = useRef(
    new ReceiveDataSocketHelper({ dispatch })
  );

  levelSelectedRef.current = levelSelected;
  taskSelectedRef.current = taskSelected;

  useEffect(() => {
    handleWebsocketMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  const handleWebsocketMessage = () => {
    if (!webSocketMessages.length) {
      return;
    }

    webSocketMessages.forEach(e=>{
      const {
        type,
        data,
        docItemId,
        docGroupId,
        level: levelSended,
      } = e;
      const level = levelSelectedRef.current;
      if (levelSended && levelSended !== level.label) {
        return;
      }
  
      switch (type) {
        case MessageType.DELETE_TASK:
          dispatch(removeTask(data));
          if (taskSelectedRef.current?.id === data.id) {
            (getCurrentViewer()?.utilities as any).goHome();
            dispatch(setTaskSelected());
            selectDbIds([], {});
            clearForgeSelection();
          }
          // handle update indexDb for api get list task
          saveIndexDB(StoreName.TASKS, Operation.Delete, data);
          break;
  
        case MessageType.UPDATE_TASK:
          receiveDataSocketHelper.current.handleUpdateTask(data);
          break;
  
        case MessageType.ADD_TASK:
          data?.id && dispatch(setTask(data));
          saveIndexDB(StoreName.TASKS, Operation.Post, data);
          break;
  
        case MessageType.ADD_DOC_GROUP:
          dispatch(addDocumentGroup(data));
          saveIndexDB(StoreName.DOCUMENT_GROUP, Operation.Post, data);
          break;
  
        case MessageType.CHANGE_DOC_GROUP:
          receiveDataSocketHelper.current.handleUpdateDocGroup(docGroupId, data);
          break;
  
        case MessageType.DELETE_DOC_GROUP:
          dispatch(removeDocumentGroup(data));
          saveIndexDB(StoreName.DOCUMENT_GROUP, Operation.Delete, data);
          break;
  
        case MessageType.UPDATE_DOCUMENT_ITEM:
          receiveDataSocketHelper.current.handleUpdateDocItem(docItemId!, {
            level: levelSended,
            ...data,
          });
          break;
        case MessageType.UPDATE_SUB_ITEM:
        case MessageType.ADD_SUB_ITEM:
        case MessageType.DELETE_SUB_ITEM:
          receiveDataSocketHelper.current.handleCrudSubItem(e);
          break;
  
        case MessageType.UPDATE_BLACKBOARD:
          receiveDataSocketHelper.current.handleUpdateBlackboard(data);
          break;
  
        case MessageType.CREATE_UPDATE_DOCUMENT_TEMPLATE:
          receiveDataSocketHelper.current.fetchDocumentTemplate(data);
          break;
        case MessageType.DELETE_DOCUMENT_TEMPLATE:
          receiveDataSocketHelper.current.deleteDocumentTemplate(data);
          break;
        /**
         * Update in modal document category
         */
        case MessageType.CHANGE_DOCUMENT_CATEGORIES:
          receiveDataSocketHelper.current.handleChangeDocCategories(data);
          break;
  
        case MessageType.UPDATE_TASK_BY_DOCUMENT_ITEM_ID:
          receiveDataSocketHelper.current.handleTaskByDocumentItemId(data);
          break;
  
        case MessageType.ADD_DOCUMENT_ITEM:
          receiveDataSocketHelper.current.handleAddDocItem(data);
          break;
  
        case MessageType.DELETE_DOCUMENT_ITEMS:
          receiveDataSocketHelper.current.handleDeleteDocItem(data);
          break;
  
        case MessageType.DELETE_DOCUMENT_CATEGORY:
          dispatch(removeDocumentCategory(data));
          saveIndexDB(StoreName.DOCUMENT_CATEGORIES, Operation.Delete, {
            id: data,
          });
          break;
        case MessageType.CHANGE_VISIBLE_DOCUMENT_ITEM:
          receiveDataSocketHelper.current.handleChangeVisibleDocItem(data);
          break;
        case MessageType.ADD_SUB_ITEM_LOG:
        case MessageType.ADD_DOCUMENT_ITEM_LOG:
        case MessageType.ADD_DOCUMENT_CATEGORY_LOG:
        case MessageType.ADD_GROUP_LOG:
          const logs = Array.isArray(data) ? data : [data];
          logs.forEach((log) => {
            addIndexedDBItemIfNotExist({
              id: log.id,
              storeName: StoreName.DATA_LOGS,
              data: {
                data: log,
                status: UpdateToOnlineStatus.Success,
                operation: Operation.Post,
                store: StoreName.DATA_LOGS,
                requestTime: Date.now(),
              },
            });
          });
          break;
        case MessageType.ADD_TASK_COMMENTS:
          const taskComments = Array.isArray(data) ? data : [data];
          taskComments.forEach((taskComment: any) => {
            addIndexedDBItemIfNotExist({
              id: taskComment.id,
              storeName: StoreName.TASK_COMMENTS,
              data: {
                data: taskComment,
                status: UpdateToOnlineStatus.Success,
                operation: Operation.Post,
                store: StoreName.TASK_COMMENTS,
                requestTime: Date.now(),
              },
            });
          });
          break;
        default:
          break;
      }
    })
   
  };

  return { webSocketMessages, isWebSocketOpen, sendWebSocketMessage };
};

export default useSyncTaskData;

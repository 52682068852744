import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconZPlane(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M11.541 9.292a1.7 1.7 0 0 1 .785.01c.268.076.529.19.77.346l7.516 4.087c.255.123.5.277.724.475a.75.75 0 0 1-.124 1.214l-8.92 5.09a.75.75 0 0 1-.744 0l-8.92-5.09a.75.75 0 0 1-.105-1.23c.195-.161.434-.33.716-.455l7.495-4.09a2.9 2.9 0 0 1 .807-.357m-7.046 5.47L11.92 19l7.433-4.241-7.001-3.807-.058-.035a1.4 1.4 0 0 0-.35-.164.2.2 0 0 0-.054 0q-.197.06-.354.164l-.057.035zM11.47 3.47a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1-1.06 1.06L12 5.06 9.53 7.53a.75.75 0 0 1-1.06-1.06z"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M12 4.25a.75.75 0 0 1 .75.75v10a.75.75 0 0 1-1.5 0V5a.75.75 0 0 1 .75-.75"
        clipRule="evenodd"
      />
    </Icon>
  );
}

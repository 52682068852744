import { useDisclosure } from "@chakra-ui/react";
import { documentGroupApi } from "apiClient/v2";
import { DocumentCategoryStatusType, ModalType } from "constants/enum";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { message as messageObj } from "components/base";

import {
  addDocumentGroup,
  updateDocumentGroup,
  setDocumentCategorySelected,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  setDocumentSubCategorySelected,
} from "redux/documentSlice";
import { setIsCreateDocumentItem, setModalType } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { getCurrentViewer, selectDbIds } from "utils/forge";
import { getLocalStorageUser } from "utils/user";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { MessageType } from "constants/websocket";
import { useForgeViewerContext } from "../ForgeViewerContext";
import { uuid } from "utils/common";
import { insertDataLogToIndexedDb } from "models/dataLog";

const useAddDocumentGroup = ({
  filterDocumentCategoryOptions,
}: {
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps;
}) => {
  const { socket } = useForgeViewerContext();
  const [isAdding, setIsAdding] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { levelSelected, modalType, isFilter, isCreateDocumentItem } =
    useSelector((state: RootState) => state.forgeViewer);
  const { documentCategories, documentTemplates } = useSelector(
    (state: RootState) => state.document
  );

  const { bimFileId } = useParams();
  const dispatch = useDispatch();

  const onAfterAddDocumentGroup = ({
    message,
    status,
  }: {
    message: string | string[];
    status: "success" | "error";
  }) => {
    onClose();
    setIsAdding(false);
    dispatch(setDocumentItemSelected());
    dispatch(setDocumentSubCategorySelected());
    if (status === "error") {
      messageObj.error(message, {
        duration: 2000,
      });
    } else {
      messageObj.success(message, {
        duration: 2000,
      });
    }
  };

  const handleAddDocumentGroup = useCallback(
    async (data: Partial<DocumentGroup>) => {
      setIsAdding(true);
      if (!bimFileId) {
        return onAfterAddDocumentGroup({
          message: ["フォルダの追加", `フォルダの作成がエラーです。`],
          status: "error",
        });
      }
      if (isCreateDocumentItem) {
        dispatch(setIsCreateDocumentItem(false));
      }
      try {
        const requestId = uuid();
        const promise = data.id
          ? documentGroupApi.updateGroup({
              id: data.id,
              name: data.name?.trim() || "",
              requestId,
            })
          : documentGroupApi.createGroup({
              createdBy: getLocalStorageUser()?.id || "",
              name: data.name?.trim() || "",
              bimFileId,
              requestId,
            });
        const documentGroup = await promise.then((res) => res.data);
        await insertDataLogToIndexedDb({
          contentLogParams: {
            field: "name" as any,
            value: data.name?.trim() || "",
            requestId,
            isLogDefault: !data.id,
          },
          createdBy: getLocalStorageUser()?.id!,
          identifyValue: documentGroup.id,
          identifyCol: "groupId",
        });

        socket.addDocGroup(documentGroup);

        // create template and generate data success
        if (!!documentGroup) {
          const isSelectDocumentGroup = documentGroup && !isFilter;

          if (data.id) {
            dispatch(updateDocumentGroup(documentGroup));
          } else {
            dispatch(addDocumentGroup(documentGroup));
          }

          if (isSelectDocumentGroup) {
            dispatch(setDocumentGroupSelected(documentGroup));
            dispatch(setDocumentSubCategorySelected());
            dispatch(setDocumentItemSelected());
            dispatch(setDocumentCategorySelected());
            selectDbIds([], {
              color: DocumentCategoryStatusType.NotStarted,
            });
            (getCurrentViewer()?.utilities as any).goHome();
          }
          if (modalType !== ModalType.DOC_GROUP && isSelectDocumentGroup) {
            dispatch(setModalType(ModalType.DOC_GROUP));
          }

          return onAfterAddDocumentGroup({
            message: [
              "フォルダの追加",
              `${documentGroup.name}を追加しました。`,
            ],
            status: "success",
          });
        }

        return onAfterAddDocumentGroup({
          message: ["フォルダの追加", `フォルダの作成がエラーです。`],
          status: "error",
        });
      } catch {
        onAfterAddDocumentGroup({
          message: ["フォルダの追加", `フォルダの作成がエラーです。`],
          status: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bimFileId,
      documentTemplates,
      documentCategories,
      modalType,
      levelSelected.label,
      filterDocumentCategoryOptions,
      onAfterAddDocumentGroup,
      isCreateDocumentItem,
    ]
  );

  return {
    isAdding,
    isOpen,
    onOpen,
    onClose,
    handleAddDocumentGroup,
  };
};

export default useAddDocumentGroup;

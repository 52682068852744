import { Input, InputProps } from "@chakra-ui/react";
import { REGEX_INPUT_NUMBER, REGEX_ROUNDING_NUMBER } from "constants/document";
import { CellType } from "interfaces/models/component";

interface Props {
  keyOfField: string;
  index?: number;
  field: CellType;
  fieldValues: {
    [key: string]: string;
  };
  groupLabel?: string;
  label?: string;
  onChangeInput: (key: string, value: any, name?: string) => void;
  onChangeDynamicField: (params: {
    data: {
      [key: string]: any;
    };
    name?: string | undefined;
    groupName?: string;
  }) => Promise<void>;
  onCustomInputMeasure?: (
    value: string,
    valueIndex: number
  ) => {
    values?: string[] | undefined;
    totalValue?: string | undefined;
    unit?: string | undefined;
  };
}

const InputNumber = ({
  keyOfField,
  index,
  label = "",
  field,
  fieldValues,
  groupLabel = "",
  onChangeInput,
  onChangeDynamicField,
  onCustomInputMeasure,
  ...rest
}: Props & InputProps) => {
  const onChange = (e: any) => {
    let value = e.target.value;

    if (value === "-") {
      value = "";
    }

    if (value !== "" && !REGEX_INPUT_NUMBER.test(value)) {
      return;
    }

    if (Number.isInteger(index) && onCustomInputMeasure) {
      const valueMeasureField = onCustomInputMeasure(value, index as number);
      onChangeInput(keyOfField, valueMeasureField);

      return;
    }

    onChangeInput(keyOfField, value);
  };

  const onBlur = (e: any) => {
    let value = e.target.value;

    if (REGEX_ROUNDING_NUMBER.test(value)) {
      const arrStr = value.split(".");
      value = arrStr.length ? arrStr[0] : value;
    }

    if (value === "-0") {
      value = "0";
    }

    if (value === "-") {
      value = "";
    }

    if (Number.isInteger(index) && onCustomInputMeasure) {
      const valueMeasureField = onCustomInputMeasure(value, index as number);

      onChangeInput(keyOfField, valueMeasureField);
      onChangeDynamicField({
        data: {
          ...fieldValues,
          [keyOfField]: valueMeasureField,
        },
        name: label,
        groupName: groupLabel,
      });

      return;
    }

    onChangeInput(keyOfField, value);
    onChangeDynamicField({
      data: { ...fieldValues, [keyOfField]: value },
      name: label,
      groupName: groupLabel,
    });
  };

  return (
    <Input
      height="4.4rem"
      fontSize="1.4rem"
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    />
  );
};

export default InputNumber;

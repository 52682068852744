import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconError({ color, ...rest }: IconProps) {
  return (
    <Icon
      width="24"
      height="24"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...rest}
    >
      <circle cx="12" cy="12" r="10" />
      <path data-name="stroke" d="m15 9-6 6m0-6 6 6" />
    </Icon>
  );
}

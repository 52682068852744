import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconMic(props: IconProps) {
  return (
    <Icon width="1.6rem" height="2.021rem" viewBox="0 0 16 20.021" {...props}>
      <path
        fill="#767676"
        d="M12 10.021v-6A4 4 0 0 0 8.021 0a1 1 0 0 0-.209.021 4.006 4.006 0 0 0-3.812 4v6a4 4 0 0 0 8 0m-6 0v-6a2 2 0 0 1 2-2 1 1 0 0 0 .163-.015A2.007 2.007 0 0 1 10 4.021v6a2 2 0 0 1-4 0"
      />
      <path
        fill="#767676"
        d="M2 10.021H0a8.01 8.01 0 0 0 7 7.931v2.069h2v-2.069a8.01 8.01 0 0 0 7-7.931h-2a6 6 0 0 1-12 0"
      />
    </Icon>
  );
}

import { Extension, JSONContent } from "@tiptap/react";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    loadContent: {
      loadContent: (content: JSONContent) => ReturnType;
    };
  }
}

export const LoadContent = Extension.create({
  name: "loadContent",

  addCommands() {
    return {
      loadContent:
        (content) =>
        ({ tr, commands, editor, dispatch }) => {
          commands.setContent(content, true, { preserveWhitespace: "full" });
          if (dispatch) {
            // prevent pushing content to the history
            tr.setMeta("addToHistory", false);
          }

          // clear history
          const history = (editor.state as any)?.history$;
          if (history) {
            history.prevRanges = null;
            history.done.eventCount = 0;
          }

          return true;
        },
    };
  },
});

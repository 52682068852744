import { Img } from "@chakra-ui/react";
import { Attribute, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { CustomNodeComponentProps, NodeType } from "../type";

const ImgPresigned = withPresignedUrl(Img);

export interface ImageUploadedNodeAttrs {
  src: string;
  componentWidth: number;
  componentHeight: number;
}

export const ImageUploadedNode = Node.create({
  name: NodeType.IMAGE_UPLOADED,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: {
      [key in keyof ImageUploadedNodeAttrs]: Attribute;
    } = {
      src: { default: null },
      componentWidth: { default: 0 },
      componentHeight: { default: 0 },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "image-uploaded",
      },
    ];
  },
  renderHTML() {
    return ["image-uploaded", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = (props: CustomNodeComponentProps<ImageUploadedNodeAttrs>) => {
  const { src, componentHeight, componentWidth } = props.node.attrs;

  return (
    <NodeViewWrapper
      style={{
        width: "100%",
        height: "100%",
        border: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ImgPresigned
        src={src}
        alt=""
        boxLoadingWidth={componentWidth}
        boxLoadingHeight={componentHeight}
        style={{
          maxWidth: "98%",
          maxHeight: "98%",
          margin: "auto",
          display: "block",
          objectFit: "contain",
        }}
        crossOrigin="anonymous"
      />
    </NodeViewWrapper>
  );
};

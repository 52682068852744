import { BoxProps, Flex, Image } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { defaultImagePath } from "constants/file";
import { FileModel } from "interfaces/models";

interface PropsI extends BoxProps {
  index: number;
  item: FileModel;
  activeThumb: number;
  isCheckLoadingPresignedUrl?: boolean;
  onSelectImage: (index: number) => void;
  onError?: () => void;
  onLoad?: () => void;
}

const ImagePresigned = withPresignedUrl(Image);

const ImageItem = ({
  index,
  item,
  activeThumb,
  onSelectImage,
  isCheckLoadingPresignedUrl = true,
  onError,
  onLoad,
  ...rest
}: PropsI) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      onClick={() => onSelectImage(index)}
      border={
        index === activeThumb
          ? "1px solid #1795FE"
          : "1px solid var(--primary-border-color)"
      }
      className="product-images-slider-thumbs-wrapper"
      minH="60px"
      {...rest}
    >
      <ImagePresigned
        width="100%"
        height="55px"
        src={item.src || defaultImagePath}
        isCheckLoadingPresignedUrl={isCheckLoadingPresignedUrl}
        alt="product images"
        objectFit="contain"
        boxLoadingWidth="100%"
        boxLoadingHeight="55px"
        onLoad={onLoad}
        onError={onError}
      />
    </Flex>
  );
};

export default ImageItem;

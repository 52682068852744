import { Box } from "@chakra-ui/react";
import { message } from "components/base";
import useScissorsTitleViewerPropertyPanel from "hooks/useScissorsTitleViewerPropertyPanel";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsInitialized,
  setIsLoadedExternalId,
  setIsLoadedSheetTransformRatio,
  setIsLoadedViewer,
  setIsLoadedViewerModelData,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { debounce } from "utils/common";

import {
  getForgeToken,
  overwriteHandleKeyDownFunction,
  setCameraToTop,
  setCameraToTopStart,
} from "utils/forge";
import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { getLabelExtension } from "utils/forge/extensions/custom-label";
import { getThemingColor } from "utils/forge/extensions/custom-label/utils";
import { setSheetTransformMatrix, setViewer2d } from "utils/forge/forge2d";
import { setViewer3d } from "utils/forge/forge3d";
import { logDev } from "utils/logs";

import { transformDbIdForTasks } from "redux/taskSlice";
import {
  setMapExternalId,
  handleSetMapDbIdAndExternalId,
} from "utils/forge/data";

interface Props {
  urn: string;
  guid?: string;
  isMasterView?: boolean;
  extensions?: any;
}

function ForgeViewer({ urn, guid, isMasterView, extensions }: Props) {
  const {
    isLoadedViewerModelData,
    levelSelected,
    isDownloadPdfOnMobile,
    isCreateTask,
    isCreateDocumentItem,
    isMoveTaskLabel,
    isCreateSelfInspectionTask,
    isGeneratingFamilyInstances,
    isGeneratingSpaces,
    isInitialized,
  } = useSelector((state: RootState) => state.forgeViewer);

  const prevUrnRef = useRef("");
  const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D>();
  const [viewerDocument, setviewerDocument] =
    useState<Autodesk.Viewing.Document | null>(null);
  const [isLoadedGeom, setIsLoadedGeom] = useState<boolean>();
  const [isLoadedObjectTree, setIsLoadedObjectTree] = useState<boolean>();
  const viewerDomRef = useRef<HTMLDivElement>(null);
  const isDownloadPdfOnMobileBeforeRef = useRef(false);
  const objectHoveredRef = useRef<any>(null);
  const dispatch = useDispatch();

  useScissorsTitleViewerPropertyPanel();

  const onGeometryLoadedEvent = useCallback(async () => {
    setIsLoadedGeom(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onObjectTreeCreatedEvent = useCallback(() => {
    setIsLoadedObjectTree(true);
  }, []);

  useEffect(() => {
    if (
      !isLoadedViewerModelData ||
      isGeneratingFamilyInstances ||
      isGeneratingSpaces
    ) {
      dispatch(setIsLoadedExternalId(false));

      return setMapExternalId({});
    }
    (async () => {
      const result = await handleSetMapDbIdAndExternalId();

      if (result) {
        dispatch(setIsLoadedExternalId(true));
        dispatch(transformDbIdForTasks());
        logDev("load external id finish");
      } else {
        message.error("ExternalIdを取得できないです。");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedViewerModelData,
    isGeneratingFamilyInstances,
    isGeneratingSpaces,
  ]);

  const transformExtension = useCallback(() => {
    const extensionsToLoad = extensions;
    const extensionsWithConfig = [];
    const extensionsWithoutConfig = [];

    for (const key in extensionsToLoad) {
      if (extensionsToLoad[key].register) {
        extensionsToLoad[key].register();
      }

      const config = extensionsToLoad[key].options || {};
      if (Object.keys(config).length === 0) {
        extensionsWithoutConfig.push(key);
      } else {
        extensionsWithConfig.push(key);
      }
    }

    return {
      extensionsWithoutConfig,
      extensionsWithConfig,
      extensionsToLoad,
    };
  }, [extensions]);

  const initializeViewer = useCallback(async () => {
    dispatch(setIsInitialized(false));

    if (!viewerDomRef.current) {
      return;
    }

    const options = {
      language: "ja",
      env: "AutodeskProduction",
      api: "derivativeV2",
      // automatically renew token
      getAccessToken: async function (onTokenReady: any) {
        const tokenEp = await getForgeToken().catch(() => undefined);
        if (tokenEp) {
          onTokenReady(tokenEp.accessToken, tokenEp.expiresIn);
        } else {
          throw new Error("Get forge's token failed");
        }
      },
    };
    Autodesk.Viewing.Initializer(options, async function () {
      const {
        extensionsToLoad,
        extensionsWithoutConfig,
        extensionsWithConfig,
      } = transformExtension();

      const viewerConfig: Autodesk.Viewing.Viewer3DConfig = {
        extensions: extensionsWithoutConfig,
        disabledExtensions: {
          bimwalk: true,
          hyperlink: true,
          scalarisSimulation: true,
          measure: true,
          explode: true,
          layermanage: true,
          fusionOrbit: true,
        },
      };

      if (isMobile) {
        viewerConfig.loaderExtensions = { svf: "Autodesk.MemoryLimited" };
        viewerConfig.memory = {
          limit: 1024, //mb, It's only works for 3D view,
        };
      }

      const viewer = new Autodesk.Viewing.GuiViewer3D(
        viewerDomRef.current!,
        viewerConfig
      );

      viewer.start();
      viewer.setProgressiveRendering(true);
      viewer.setQualityLevel(true, false);

      viewer.setGroundShadow(false);
      viewer.prefs?.set("ghosting", false);
      viewer.prefs?.set("optimizeNavigation", true);
      extensionsWithConfig.forEach((ext) => {
        viewer.loadExtension(ext, extensionsToLoad[ext].options);
      });
      (viewer.navigation as any).FIT_TO_VIEW_VERTICAL_MARGIN = 0;
      (viewer.navigation as any).FIT_TO_VIEW_HORIZONTAL_MARGIN = 0;
      viewerRef.current = viewer;
      dispatch(setIsInitialized(true));
    });
  }, [transformExtension, dispatch]);

  useEffect(() => {
    const value = Boolean(isLoadedGeom && isLoadedObjectTree);
    if (isLoadedViewerModelData !== value) {
      dispatch(setIsLoadedViewerModelData(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedGeom, isLoadedObjectTree]);

  // load document
  useEffect(() => {
    if (!isInitialized || !urn) {
      return;
    }

    Autodesk.Viewing.Document.load(
      `urn:${urn}`,
      (viewerDocument) => {
        setviewerDocument(viewerDocument);
      },
      () => {
        logDev("Failed fetching Forge manifest");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  const loadModel = useCallback(() => {
    const viewer = viewerRef.current;
    dispatch(setIsLoadedViewer(false));
    if (!viewerDocument || !viewer) {
      return;
    }

    viewer.addEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      onGeometryLoadedEvent
    );

    viewer.addEventListener(
      Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
      onObjectTreeCreatedEvent
    );

    viewer.prefs?.set("viewCube", false);
    viewer.prefs?.set("viewCubeCompass", false);
    viewer.setActiveNavigationTool("pan");
    viewer.showModelStructurePanel(false);
    const bubbleNode = viewerDocument.getRoot() as any;
    let viewableNode: any;
    if (guid) {
      viewableNode = bubbleNode.findByGuid(guid);
    }
    if (!!isMasterView) {
      const masterViews: any[] = bubbleNode.getMasterViews();
      viewableNode = masterViews.reduce((prev, cur) => {
        return !prev || (cur && cur.data.size > prev.data.size) ? cur : prev;
      }, undefined);
    }
    if (!viewableNode) {
      const models: any[] = bubbleNode.search(
        Autodesk.Viewing.BubbleNode.MODEL_NODE
      );
      viewableNode = models.find((model) => {
        const name = model.data.name;

        return (
          !(name.toUpperCase().includes("F") || name.includes("階")) &&
          (name.includes("全体") || name.toUpperCase().includes("ALL"))
        );
      });
      if (!viewableNode) {
        let maxSize = 0;
        viewableNode = models[0];
        models.forEach((model) => {
          if (model.data.size > maxSize && !!model.data.ViewSets) {
            maxSize = model.data.size;
            viewableNode = model;
          }
        });
      }
    }

    const options: any = {
      //skipPropertyDb: !isMasterView,
      globalOffset: { x: 0, y: 0, z: 0 },
      // NOTE: If this option is set to false, unload will be executed implicitly (if set to true, models loaded later will be merged)
      keepCurrentModels: false,
    };
    if (viewableNode.data.role === "2d") {
      options.applyScaling = "mm";
    }

    const loadView = async () => {
      logDev("begin load model");
      await viewer.loadDocumentNode(viewerDocument, viewableNode, options);
      // await viewer.waitForLoadDone();
      logDev("end load model");
      viewer.unloadExtension("Autodesk.PDF");
      viewer.unloadExtension("Autodesk.PDFLoader");
      viewer.unloadExtension("Autodesk.PDF2D");
      const models = viewer.getAllModels();
      if (models.length > 1) {
        cleanUp();
        initializeViewer();

        return;
      }

      if (viewableNode.data.role === "3d") {
        setCameraToTopStart(viewer);
        setViewer3d(viewer);
        viewer.setActiveNavigationTool("orbit");
        viewer.prefs?.set("viewCube", true);
        viewer.prefs?.set("viewCubeCompass", true);
      } else {
        setCameraToTop(viewer);
        setViewer2d(viewer);
      }
      logDev(viewableNode.data.role === "3d", "loaded viewer");
      overwriteHandleKeyDownFunction(viewer);
      dispatch(setIsLoadedViewer(true));
    };

    loadView();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid, viewerDocument, levelSelected?.label, isDownloadPdfOnMobile]);

  // load model
  useEffect(() => {
    if (!levelSelected || !viewerDocument) {
      return;
    }
    loadModel();

    return () => {
      clearModel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid, viewerDocument, levelSelected?.label]);

  // clear model
  const clearModel = useCallback(() => {
    const viewer = viewerRef.current;

    getAreaExtension()?.clear(false);
    getLabelExtension()?.clear();
    try {
      if (viewer) {
        viewer.removeEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          onGeometryLoadedEvent
        );

        viewer.removeEventListener(
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
          onObjectTreeCreatedEvent
        );

        const models = viewer.getAllModels();
        models?.forEach((m) => viewer.unloadModel(m));
      }
    } catch {}
    setViewer2d(undefined);
    setViewer3d(undefined);
    setIsLoadedGeom(false);
    setIsLoadedObjectTree(false);
    dispatch(setIsLoadedViewer(false));
    setSheetTransformMatrix(undefined);
    dispatch(setIsLoadedSheetTransformRatio(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanUp = useCallback(() => {
    const viewer = viewerRef.current;

    getAreaExtension()?.clear(false);
    getLabelExtension()?.clear();
    try {
      if (viewer) {
        viewer.removeEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          onGeometryLoadedEvent
        );

        viewer.removeEventListener(
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
          onObjectTreeCreatedEvent
        );
        viewer?.tearDown();
        viewer?.finish();
        const models = viewer.getAllModels();
        models?.forEach((m) => viewer.unloadModel(m));
      }
    } catch {}

    setSheetTransformMatrix(undefined);
    dispatch(setIsLoadedSheetTransformRatio(false));
    setViewer2d(undefined);
    setViewer3d(undefined);
    Autodesk.Viewing.shutdown();
    dispatch(setIsLoadedViewer(false));
    dispatch(setIsInitialized(false));
    setviewerDocument(null);
    setIsLoadedGeom(false);
    setIsLoadedObjectTree(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGeometryLoadedEvent, onObjectTreeCreatedEvent]);

  // initialize viewer
  useEffect(() => {
    const viewer = viewerRef.current;
    if (!urn || !viewerDomRef.current) {
      return;
    }

    if (!viewer) {
      dispatch(setIsLoadedViewer(false));
      initializeViewer();
      prevUrnRef.current = urn;

      return;
    }

    const isDiffUrn = prevUrnRef.current && prevUrnRef.current !== urn;
    if (isDiffUrn) {
      cleanUp();
      setTimeout(initializeViewer);
      prevUrnRef.current = urn;

      return;
    }

    return () => {
      cleanUp();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urn]);

  // clean up
  useEffect(() => {
    return () => {
      cleanUp();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // unload modal when download pdf on mobile
  useEffect(() => {
    if (isDownloadPdfOnMobile && isLoadedViewerModelData) {
      isDownloadPdfOnMobileBeforeRef.current = true;
      clearModel();
    }

    if (
      !isDownloadPdfOnMobile &&
      !isLoadedViewerModelData &&
      isDownloadPdfOnMobileBeforeRef.current
    ) {
      isDownloadPdfOnMobileBeforeRef.current = false;
      loadModel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadPdfOnMobile, isLoadedViewerModelData]);

  // resize viewer
  useEffect(() => {
    const viewer = viewerRef.current;

    if (!viewer || !isInitialized || !viewer.canvasWrap) {
      return;
    }
    const onResize = debounce(() => {
      if (viewer?.container) {
        viewer?.resize();
        viewer?.impl?.invalidate(true);
      }
    }, 100);

    const observer = new ResizeObserver(onResize);
    observer.observe(viewer.canvasWrap);

    return () => {
      if (!viewer) {
        return;
      }
      observer.disconnect();
    };
  }, [isInitialized]);

  const resetObjectWithHovered = ({
    dbId,
    originalColor,
    action,
    viewer,
  }: {
    viewer: Autodesk.Viewing.GuiViewer3D;
    dbId: number;
    originalColor: THREE.Vector4;
    action: "move" | "create";
  }) => {
    try {
      if (action === "move") {
        viewer?.clearThemingColors?.(viewer.model);
      } else {
        viewer?.setThemingColor?.(dbId, originalColor);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const viewer = viewerRef.current;

    if (!viewer) {
      return;
    }
    let timeoutId = null as any;

    const handleObjectChange = (event: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      if (
        !isCreateDocumentItem &&
        !isCreateTask &&
        !isMoveTaskLabel &&
        !isCreateSelfInspectionTask
      ) {
        return;
      }
      timeoutId = setTimeout(() => {
        const { dbId: dbIdHovered, originalColor: originalColorHovered } =
          objectHoveredRef.current || {};
        if (event.dbId !== -1 && event.dbId) {
          // If the hovered object is the same as the previous one, do nothing

          if (event.dbId === dbIdHovered) {
            return;
          }

          // If the hovered object is different from the previous one, reset the color of the previous object
          if (objectHoveredRef.current) {
            viewer.setThemingColor(dbIdHovered, originalColorHovered);
          }
          objectHoveredRef.current = {
            dbId: event.dbId,
            originalColor: getThemingColor(viewer, event.dbId),
            action: isMoveTaskLabel ? "move" : "create",
          };

          const hoverColor = new THREE.Vector4(
            220 / 255,
            202 / 255,
            38 / 255,
            1
          ); // Set the color to #DCCA26 when hovering
          viewer.setThemingColor(event.dbId, hoverColor);
        } else if (objectHoveredRef.current) {
          // If the hovered object is null, save the color of the previous object and reset the color of the previous object
          viewer.setThemingColor(dbIdHovered, originalColorHovered);
          objectHoveredRef.current = null;
        }
      }, 200);
    };

    viewer.addEventListener(
      Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED,
      handleObjectChange
    );

    // reset color when unmount
    if (objectHoveredRef.current) {
      resetObjectWithHovered({ ...objectHoveredRef.current, viewer });
    }

    return () => {
      // reset color when unmount
      if (objectHoveredRef.current) {
        resetObjectWithHovered({ ...objectHoveredRef.current, viewer });
      }
      objectHoveredRef.current = null;
      viewer.removeEventListener(
        Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED,
        handleObjectChange
      );
    };
  }, [
    isCreateDocumentItem,
    viewerDocument,
    isCreateTask,
    isMoveTaskLabel,
    isCreateSelfInspectionTask,
  ]);

  return (
    <Box
      id="forge-viewer"
      className="forge-viewer"
      ref={viewerDomRef}
      h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
      position="relative"
      display="block"
      w="100%"
      sx={{
        ".adsk-viewing-viewer": {
          "#guiviewer3d-toolbar": {
            display: "none",
          },
        },

        ".canvas-wrap": {
          height: "100%",
        },
        ".canvas-wrap > canvas": {
          minWidth: "100%",
        },
      }}
    />
  );
}

export default memo(ForgeViewer);

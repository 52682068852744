import { InspectionItemType, SystemModeType } from "constants/enum";
import { NormalDisplayItem } from ".";
import { getStatusColor, mapTaskIconStatus, onLabelClick } from "./utils";

export const taskLabel = (
  item: NormalDisplayItem,
  options?: { isDisableLabelClick?: boolean; isSelected?: boolean }
) => {
  const status = item.status || InspectionItemType.Defect;
  const disableClick = options?.isDisableLabelClick ? "disable-click" : "";
  const selectedLabel = options?.isSelected ? "selected-label" : "";
  const $label = $(`<label class="task-label label-item markup update
${status} ${disableClick} ${selectedLabel}"  data-id="${item.id}">
    <div class="marker-pin" style="background-color: ${getStatusColor(
      item.status,
      SystemModeType.Task
    )}">
        <span class="marker-label" id="${status}"> ${mapTaskIconStatus(
    item.status
  )}</span>
    </div>
  </label>`);

  if (!options?.isDisableLabelClick) {
    $label?.on("click", () => onLabelClick(item, SystemModeType.Task));
  }

  return $label;
};

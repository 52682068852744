import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconLabelDisplaySelection(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M5.652 8.291a.75.75 0 0 1 .75-.75h3.024a.75.75 0 1 1 0 1.5H6.402a.75.75 0 0 1-.75-.75m6.598 0a.75.75 0 0 1 .75-.75h5.502a.75.75 0 1 1 0 1.5H13a.75.75 0 0 1-.75-.75m-6.598 5.292a.75.75 0 0 1 .75-.75h7.56a.75.75 0 1 1 0 1.5h-7.56a.75.75 0 0 1-.75-.75m11.098 0a.75.75 0 0 1 .75-.75h.998a.75.75 0 1 1 0 1.5H17.5a.75.75 0 0 1-.75-.75"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M10.995 7.53a.762.762 0 1 0 0 1.523.762.762 0 0 0 0-1.524m-2.262.761a2.262 2.262 0 1 1 4.524 0 2.262 2.262 0 0 1-4.524 0m6.799 4.532a.762.762 0 1 0 0 1.524.762.762 0 0 0 0-1.524m-2.262.762a2.262 2.262 0 1 1 4.524 0 2.262 2.262 0 0 1-4.524 0"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M5 3.75A1.25 1.25 0 0 0 3.75 5v14.617l.998-1.218a.75.75 0 0 1 .58-.274H19.9a1.25 1.25 0 0 0 1.25-1.25V5a1.25 1.25 0 0 0-1.25-1.25zm-1.945-.695A2.75 2.75 0 0 1 5 2.25h14.9A2.75 2.75 0 0 1 22.65 5v11.875a2.75 2.75 0 0 1-2.75 2.75H5.683L3.58 22.191a.75.75 0 0 1-1.33-.476V5c0-.73.29-1.429.805-1.945"
        clipRule="evenodd"
      />
    </Icon>
  );
}

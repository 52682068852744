import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import appReducer from "redux/appSlice";
import documentReducer from "redux/documentSlice";
import editorReducer from "redux/editorSlice";
import forgeViewerReducer from "redux/forgeViewerSlice";
import bimReducer from "redux/bimSlice";
import projectReducer from "redux/projectSlice";
import taskReducer from "redux/taskSlice";
import taskTypeReducer from "redux/taskTypeSlice";
import userReducer from "redux/userSlice";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { persistStore } from "redux-persist";
import { REDUCER_KEY } from "constants/redux";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [],
  blacklist: ["_persist"],
};

const configPersistApp = {
  key: REDUCER_KEY.APP,
  storage,
  whitelist: [
    "mapProjectCachedInfo",
    "isCheckedPrecacheMissStaticFile",
    "isInstallingServiceWorker",
    "isOnline",
    "timeBeginOffline",
    "syncDataOption",
  ],
  blacklist: ["_persist"],
};

const configPersistProject = {
  key: REDUCER_KEY.PROJECT,
  storage,
  whitelist: [],
  blacklist: ["_persist"],
};

const rootReducer = combineReducers({
  [REDUCER_KEY.APP]: persistReducer(configPersistApp, appReducer),
  [REDUCER_KEY.PROJECT]: persistReducer(configPersistProject, projectReducer),
  [REDUCER_KEY.FORGE_VIEWER]: forgeViewerReducer,
  [REDUCER_KEY.BIM]: bimReducer,
  [REDUCER_KEY.DOCUMENT]: documentReducer,
  [REDUCER_KEY.USER]: userReducer,
  [REDUCER_KEY.EDITOR]: editorReducer,
  [REDUCER_KEY.TASK]: taskReducer,
  [REDUCER_KEY.TASK_TYPE]: taskTypeReducer,
});

const persistRootReducer = persistReducer(rootPersistConfig, rootReducer);

export function makeStore() {
  return configureStore({
    reducer: persistRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
}

const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
export const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch<AppDispatch>();

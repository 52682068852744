import {
  getValueFromFamilyInstace,
  handleDynamicFieldItemContent,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  FamilyInstanceKey,
  LinkedDynamicFieldsType,
} from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { CellType } from "interfaces/models/component";
import { isCommissiontingTableTemplate } from "models/documentCategory";
import { getHeader } from "utils/document";
import { getContentNodeTypeNormalText, getContentTypeCheckbox } from "../utils";
import BaseStructureTable from "./BaseStructureTable";

class ContentTdTypeCommon extends BaseStructureTable {
  getStructure(params: {
    cell: CellType;
    currentCell: CellType;
    rowIndex: number;
    cellIndex: number;
    subRowIndex: number | undefined;
    subCellIndex: number | undefined;
    documentItem: DocumentItemDTO;
  }) {
    const {
      cell,
      currentCell,
      rowIndex: _rowIndex,
      cellIndex: _cellIndex,
      subRowIndex = 0,
      subCellIndex = 0,
      documentItem,
    } = params;
    let rowIndex = _rowIndex;
    let cellIndex = _cellIndex;
    const isSubCell = !!cell?.isSubCell;
    const headerCells =
      getHeader({ components: this.template?.components || [] })?.detail
        ?.rows?.[0]?.cells || [];
    const numberOfRows = this.component?.detail?.rows?.length || 0;
    const numberOfActualCell =
      (this.component?.detail?.rows?.[0]?.cells?.length || 0) /
      (this.component?.detail?.numOfRepeatTable || 1);

    if (cell?.isRepeatedTable && cell?.repeatedTableIndex) {
      rowIndex += numberOfRows * cell.repeatedTableIndex;
      cellIndex -= numberOfActualCell * cell.repeatedTableIndex;
    }

    const headerTitleId = isSubCell
      ? headerCells?.[cellIndex]?.subTable?.rows?.[subRowIndex]?.cells?.[
          subCellIndex
        ]?.cellId
      : headerCells?.[cellIndex]?.cellId;
    const familyInstance =
      this.familyInstances?.[documentItem?.externalId || ""];
    const linkedDataField = cell?.cellLinkedData?.field;
    const documentType = this.template?.documentType;

    if (
      isCommissiontingTableTemplate(documentType) &&
      rowIndex >= this.documentItems?.length
    ) {
      return null;
    }

    switch (cell?.cellProperty) {
      case CellProperty.DYNAMIC_FIELDS_FOR_ITEM:
        const dynamicFieldValue =
          handleDynamicFieldItemContent({
            cell: structuredClone(cell),
            headerComponent: headerCells,
            isSubCell,
            cellIndex,
            subCellIndex,
            headerTitleId: headerTitleId || "",
            documentItem,
            listUserById: this.listAllUserById,
          })?.value || "";

        if (
          cell?.cellLinkedData?.options?.dynamicFieldType ===
          LinkedDynamicFieldsType.CHECK
        ) {
          return getContentTypeCheckbox({
            cell: { ...cell, value: dynamicFieldValue },
            documentItem,
            documentType: this.template?.documentType,
            component: this.component,
          });
        }

        return getContentNodeTypeNormalText({
          text: dynamicFieldValue,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });

      case CellProperty.FAMILY_INSTANCE:
        let familyInstanceValue = getValueFromFamilyInstace(
          linkedDataField as FamilyInstanceKey,
          familyInstance
        );

        if (linkedDataField === FamilyInstanceKey.SIGN) {
          familyInstanceValue = this.component?.detail?.isRepeatTable
            ? this.familyInstances[
                (this.documentItems?.[Number(currentCell?.rowIndex) - 1] as any)
                  ?.externalId || ""
              ]?.sign || ""
            : this.familyInstances[documentItem?.externalId || ""]?.sign || "";
        } else if (
          linkedDataField === FamilyInstanceKey.ESTIMATE_CONSTRUCTION_CATEGORY
        ) {
          familyInstanceValue = this.component?.detail?.isRepeatTable
            ? this.familyInstances[
                (this.documentItems?.[Number(currentCell?.rowIndex) - 1] as any)
                  ?.externalId || ""
              ]?.estimateConstructionCategory || ""
            : this.familyInstances[documentItem?.externalId || ""]
                ?.estimateConstructionCategory || "";
        }

        return getContentNodeTypeNormalText({
          text: familyInstanceValue,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });

      default:
        return null;
    }
  }
}

export default ContentTdTypeCommon;

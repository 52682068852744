import { Condition } from "constants/family";
import { FamilyInstance } from "interfaces/models/familyInstance";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamilies } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";

export default function useFetchFamilies() {
  const dispatch = useDispatch();
  const { families } = useSelector((state: RootState) => state.forgeViewer);

  useEffect(() => {
    dispatch(fetchFamilies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getObjectTypesOfFamilyInstance = useCallback(
    (instance: FamilyInstance) => {
      return families.filter((family) => {
        return family.conditions.every((condition) => {
          const attribute =
            condition.attribute === "family" ? "typeName" : condition.attribute;
          const value = String(
            (instance as any)[attribute] || ""
          ).toUpperCase();
          const conditionValue = (condition.value || "").toUpperCase();
          switch (condition.condition) {
            case Condition.Contains:
              return !!value && value.includes(conditionValue);
            case Condition.Equal:
              return !!value && value === conditionValue;
            case Condition.Exists:
              return !!value;
            case Condition.Greater:
              return !!value && value > conditionValue;
            case Condition.GreaterOrEqual:
              return !!value && value >= conditionValue;
            case Condition.Less:
              return !!value && value < conditionValue;
            case Condition.LessOrEqual:
              return !!value && value <= conditionValue;
            case Condition.NotContains:
              return !!value && !value.includes(conditionValue);
            case Condition.NotEqual:
              return !!value && value !== conditionValue;
            case Condition.NotExists:
              return !value;
            default:
              return false;
          }
        });
      });
    },
    [families]
  );

  return { getObjectTypesOfFamilyInstance };
}

import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  Image,
  Link,
  Spinner,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { message } from "components/base";
import FileUpload from "components/FileUpload";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { SvgIcon } from "components/SvgIcon";
import { InspectionItemType } from "constants/enum";
import { TypeOfFile } from "constants/file";
import { SetTaskLogTypeComment } from "constants/task";
import { TaskComment } from "interfaces/models/task";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { tryJsonParse } from "utils/common";
import { isAudio, isDoc, isImage, isVideo, verifyFile } from "utils/file";
import { PreviewFileComponent } from "./PreviewFileComponent";
import AudioPlayer from "./record-audio/AudioPlayer";

const ImagePresigned = withPresignedUrl(Image);
const AudioPresigned = withPresignedUrl(AudioPlayer);

interface Props {
  comment: TaskComment;
  loadingImage: boolean;
  status: string | null;
  isOnline: boolean;

  onUpdateImageComment: (
    e: any,
    comment: TaskComment,
    srcImage: string
  ) => Promise<void>;
  onDeleteImage: (comment: TaskComment, srcImage: string) => Promise<void>;
  onPreviewImage: (src: string) => void;
}

const ChatComment = ({
  comment,
  loadingImage,
  status,
  isOnline,
  onUpdateImageComment,
  onDeleteImage,
  onPreviewImage,
}: Props) => {
  const contentComment = comment.content || "";
  const contentObj = tryJsonParse(contentComment);
  const [listFile, setListFile] = useState<any[]>([]);
  const [isHoverIconEdit, setIsHoverIconEdit] = useState(false);

  const indexStatus = useMemo(() => {
    return listFile.findIndex((file: any) => {
      const fileName = file.item?.split("?")[0].split("#")[0]?.toString();

      return isImage(fileName) || !isAudio(fileName);
    });
  }, [listFile]);

  useEffect(() => {
    if (comment && comment.images) {
      const temp = comment.images.map((item) => ({
        item,
        isLoading: false,
        isHover: false,
      }));
      setListFile(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment.images]);

  const deleteImage = (comment: TaskComment, indexImage: any, url: string) => {
    const listFileClone = [...listFile];
    listFileClone?.forEach((file, index) => {
      if (index === indexImage) {
        file.isLoading = true;
      }
    });
    setListFile(listFileClone);
    onDeleteImage(comment, url);
  };
  const onHover = (indexList: any, isHover: boolean) => {
    const listFileClone = [...listFile];
    listFileClone?.forEach((file, index) => {
      if (index === indexList) {
        file.isHover = isHover;
      }
    });
    setListFile(listFileClone);
  };
  const onUploadImage = async (
    indexList: any,
    e: any,
    comment: TaskComment,
    srcImage: string
  ) => {
    const fileList = e.target.files as FileList;
    const errorTitle = await verifyFile(fileList, TypeOfFile.ATTACH);

    if (errorTitle) {
      return message.error(errorTitle);
    }

    const listFileClone = [...listFile];
    listFileClone?.forEach((file, index) => {
      if (index === indexList) {
        file.isLoading = true;
      }
    });

    onUpdateImageComment(e, comment, srcImage);
    setListFile(listFileClone);
  };

  const renderFileByType = (file: any, index: number) => {
    const fileName = file.item?.split("?")[0].split("#")[0]?.toString() || "";

    if (isImage(fileName)) {
      const isShowIconAction = file.isHover && !loadingImage && isOnline;

      return (
        <Box zIndex={10} position="relative">
          {file.isLoading && (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              bgColor="rgb(0 0 0 / 60%)"
              position="absolute"
              zIndex={5}
              width="100%"
              height="100%"
            >
              <Spinner thickness="4px" size="xl" color="cyan.800" />
            </Flex>
          )}
          <Box
            onMouseLeave={() => onHover(index, false)}
            onMouseEnter={() => onHover(index, true)}
            position="relative"
            onClick={() => {
              if (isHoverIconEdit) return;
              onPreviewImage(file.item);
            }}
          >
            <Box
              position="absolute"
              top="0"
              right="0"
              left="0"
              bottom="0"
              bgColor={file.isHover && !loadingImage && "rgb(0 0 0 / 70%)"}
            />

            <ImagePresigned
              maxH="125px"
              minW="120px"
              objectFit="cover"
              borderRadius="5px"
              src={file.item}
            />
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();
                deleteImage(comment, index, file.item);
              }}
              color="white"
              height="1.8rem"
              width="1.8rem"
              cursor="pointer"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
              _hover={{ color: "var(--red)" }}
              display={isShowIconAction ? "block" : "none"}
            />
            <FormControl
              isRequired
              w="auto"
              onChange={(e: any) => {
                onUploadImage(index, e as any, comment, file.item);
              }}
              zIndex="10"
            >
              <FileUpload accept="image/*, .pdf, .mp4">
                <SvgIcon
                  width="3rem"
                  height="3rem"
                  cursor="pointer"
                  color="white"
                  position="absolute"
                  bottom="0.2rem"
                  right="0.2rem"
                  src="/img/icon-pencil-white.svg"
                  _hover={{
                    "path, rect, circle, line": {
                      fill: "var(--red)",
                    },
                  }}
                  display={isShowIconAction ? "block" : "none"}
                  onMouseLeave={() => setIsHoverIconEdit(false)}
                  onMouseEnter={() => setIsHoverIconEdit(true)}
                />
              </FileUpload>
            </FormControl>
          </Box>
        </Box>
      );
    }

    if (isAudio(fileName)) {
      return (
        <AudioPresigned
          src={file.item}
          readonly={!isOnline}
          onDelete={() => deleteImage(comment, index, file.item)}
        />
      );
    }

    if (isDoc(fileName) || isVideo(fileName)) {
      return (
        <PreviewFileComponent
          readonly={!isOnline}
          file={{ src: file.item }}
          onDelete={(e) => {
            e.stopPropagation();
            deleteImage(comment, index, file.item);
          }}
          isDelete={loadingImage}
        />
      );
    }

    return (
      <Link href={file.item} target="_blank">
        <Image height="115px" src="/img/doc7.svg" />
      </Link>
    );
  };

  if (SetTaskLogTypeComment.has(comment.type as any)) {
    return (
      <Box>
        <Flex>
          <Flex py="2px" ml="32px" display="block" w="calc(100% - 1.6rem)">
            <Box
              mb="4px"
              contentEditable="false"
              fontSize="1.2rem"
              color="#171717"
              dangerouslySetInnerHTML={{ __html: contentObj }}
            />

            <Wrap spacing="8px" position="relative">
              {!!listFile?.length &&
                listFile?.map((file, index) => {
                  return (
                    <Fragment key={index}>
                      <WrapItem position="relative">
                        <Box bg="#E9E9E9">{renderFileByType(file, index)}</Box>
                        {comment.statusChange &&
                          indexStatus === index &&
                          status &&
                          status !== InspectionItemType.Defect && (
                            <Box
                              bg={
                                comment.statusChange ===
                                InspectionItemType.Confirmed
                                  ? "#0284C7"
                                  : "#9E69AF"
                              }
                              color="#ffffff"
                              position="absolute"
                              zIndex={10}
                              left="0"
                              m="4px"
                              bottom="0rem"
                              height="18px"
                              width="80px"
                              fontSize="12px"
                              fontWeight={400}
                              alignItems="center"
                              textAlign="center"
                              borderRadius="2px"
                            >
                              {comment.statusChange
                                ? comment.statusChange ===
                                  InspectionItemType.Confirmed
                                  ? "確認済み"
                                  : "処置済み"
                                : ""}
                            </Box>
                          )}
                      </WrapItem>
                    </Fragment>
                  );
                })}
            </Wrap>
          </Flex>
        </Flex>
      </Box>
    );
  }

  return <></>;
};

export default memo(ChatComment);

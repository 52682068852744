import React, { memo } from "react";
import ModalInput, {
  ModalInputProps,
  ModalInputSection,
} from "../../ui/ModalInput";
import { css } from "@emotion/react";
import { Input, Text } from "@chakra-ui/react";
import IconFolder from "../../icon/IconFolder";
import { DocumentGroup } from "interfaces/models/documentGroup";
import useForm from "hooks/useForm";
import pick from "lodash/pick";

const ModalAddDocumentGroup = memo<{
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onApply: (data: Partial<DocumentGroup>) => Promise<any>;
  modalProps?: ModalInputProps;
  defaultValue?: Partial<DocumentGroup>;
}>(({ isOpen, onApply, onClose, isLoading, modalProps, defaultValue }) => {
  const { setValue, getValue, formValue } = useForm<Partial<DocumentGroup>>(
    pick(defaultValue, ["name", "id"])
  );

  const isValidTitle = !!formValue?.name?.trim();

  const handleApply = () => {
    if (!formValue) return;
    onApply(formValue);
  };

  return (
    <ModalInput
      {...modalProps}
      isOpen={isOpen}
      title={"フォルダを追加"}
      onClose={isLoading ? () => {} : onClose}
      okLabel={"追加"}
      cancelLabel={"キャンセル"}
      minWidth={"1200px"}
      modalContentProps={{
        sx: {
          borderRadius: 0,
        },
      }}
      onOk={handleApply}
      onCancel={onClose}
      modalHeaderProps={{
        pl: "4rem",
        pr: "4rem",
      }}
      modalFooterProps={{
        borderTop: "0 !important",
      }}
      btnPropsCancel={{
        isLoading,
        sx: {
          border: "1px solid #A3A3A3 !important",
          fontWeight: "500 !important",
        },
      }}
      btnPropsOk={{
        isLoading,
      }}
      okDisabled={isLoading || !isValidTitle}
      cancelDisabled={isLoading}
      icon={<IconFolder width="4rem" height="4rem" />}
    >
      <ModalInputSection
        label={
          <Text color="#737373" fontWeight="500">
            フォルダ名
          </Text>
        }
        tag={"必須"}
        tagColor={"red"}
      >
        <Input
          value={getValue("name", "")}
          onChange={(e) => setValue("name", e.target.value)}
          placeholder={"入力してください"}
          css={css`
            height: 4rem;
            font-size: 1.5rem; /* 24px */
            line-height: 2rem; /* 32px */
          `}
          _placeholder={{
            color: "#A3A3A3",
            opacity: "1",
          }}
          borderColor={"#A3A3A3"}
          _focus={{
            borderColor: "#009be0 !important",
          }}
        />
      </ModalInputSection>
    </ModalInput>
  );
});

export default ModalAddDocumentGroup;

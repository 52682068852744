import { Attribute, Node } from "@tiptap/core";
import { NodeType } from "../type";

export interface PreviewPageNodeAttrs {
  scale: number;
}

export const PreviewPageNode = Node.create({
  name: NodeType.PREVIEW_PAGE,
  group: "block",
  content: `${NodeType.PAGE_CONTENT}*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: { [key in keyof PreviewPageNodeAttrs]: Attribute } = {
      scale: {
        default: 1,
      },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { scale } = HTMLAttributes as PreviewPageNodeAttrs;

    return [
      "div",
      {
        style: `background: black; display: flex; flex-direction: column; align-items: center; padding: ${
          scale * 7
        }px ${scale * 3}rem; cursor: default; pointer-events: none; `,
      },
      0,
    ];
  },
});

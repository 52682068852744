import { Attribute, Node } from "@tiptap/core";
import { PaperDirectionType, PaperType } from "constants/enum";
import { TIPTAP_DOCUMENT_CONTAINER_CLASS } from "constants/styleProps";
import { NodeType } from "../type";

export interface PageContentNodeAttrs {
  scale: number;
  paddingX: number;
  paddingY: number;
  stylePageKeynote: any;
  pageIndex: number;
  width: number;
  height: number;
  isVertical?: boolean;
  pageSize?: PaperType;
  pageDirection?: PaperDirectionType;
}

export const PageContentNode = Node.create({
  name: NodeType.PAGE_CONTENT,
  group: "block",
  content: `${NodeType.PAGE_COMPONENT}*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: { [key in keyof PageContentNodeAttrs]: Attribute } = {
      scale: {
        default: 1,
      },
      paddingX: { default: 0 },
      paddingY: { default: 0 },
      pageIndex: {
        default: 0,
      },
      width: { default: 0 },
      height: { default: 0 },
      stylePageKeynote: { default: {} },
      isVertical: { default: true },
      pageSize: { default: PaperType.A4 },
      pageDirection: { default: PaperDirectionType.VERTICAL },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const {
      scale,
      paddingX,
      paddingY,
      pageIndex,
      width,
      height,
      isVertical,
      pageSize,
      pageDirection,
    } = HTMLAttributes as PageContentNodeAttrs;
    const _class = `${TIPTAP_DOCUMENT_CONTAINER_CLASS} page-index-${pageIndex}`;
    const id = `page-${pageIndex}`;
    const padding = isVertical
      ? `${scale * paddingY}px ${paddingX * scale}px`
      : `${paddingX * scale}px ${paddingY * scale}px`;
    const style = `pointer-events: none; cursor: default; background: white; padding: ${padding}; width: ${width}px; height: ${height}px; margin: 0 auto`;

    return [
      "div",
      {
        class: _class,
        id,
        "data-page-size": pageSize,
        "data-page-direction": pageDirection,
        style,
      },
      [
        "div",
        {
          style: `position: relative; transform: scale(${scale}); transform-origin: 0 0; cursor: default;`,
        },
        0,
      ],
    ];
  },
});

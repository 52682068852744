import { Box, Button, ButtonProps, Flex, Text } from "@chakra-ui/react";
import {
  LEFT_PANEL_CONTENT_ID,
  RIGHT_SIDEBAR_MODAL_CLASSNAME,
} from "constants/styleProps";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface iProps {
  title: string;
  description?: string;
  extra?: React.ReactNode;
  callback?: () => void;
  propButton?: ButtonProps;
}

const TooltipForgeView = ({
  title,
  description = "",
  extra,
  callback,
  propButton,
}: iProps) => {
  const { modalType } = useSelector((state: RootState) => state.forgeViewer);
  const { taskSelected } = useSelector((state: RootState) => state.task);
  const { documentItemSelected } = useSelector(
    (state: RootState) => state.document
  );
  const [left, setLeft] = useState<number>(0);
  const [right, setRight] = useState<number>(0);

  useEffect(() => {
    const element = document.getElementById(LEFT_PANEL_CONTENT_ID);
    if (!element) {
      setLeft(0);

      return;
    }
    const observer = new ResizeObserver((e) => {
      const resizeInfo = e[0];
      if (!resizeInfo?.contentRect) {
        return;
      }
      setLeft(resizeInfo.contentRect.width);
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(`.${RIGHT_SIDEBAR_MODAL_CLASSNAME}`);
    if (!element) {
      setRight(0);

      return;
    }
    const observer = new ResizeObserver((e) => {
      const resizeInfo = e[0];
      if (!resizeInfo?.contentRect) {
        return;
      }
      setRight(resizeInfo.contentRect.width);
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [modalType, taskSelected?.id, documentItemSelected?.id]);

  return (
    <>
      {Array.apply(null, Array(4)).map((_, key) => {
        const att =
          key === 0
            ? { top: 0, left: left, height: "100%", width: "5px" }
            : key === 1
            ? { top: 0, width: "100%", height: "5px", left: 0 }
            : key === 2
            ? { right: right, top: 0, width: "5px", height: "100%" }
            : { left: 0, bottom: 0, width: "100%", height: "5px" };

        return (
          <Box
            key={key}
            bgColor="#009BE0"
            style={{
              position: "absolute",
              zIndex: 6,
              ...{ ...att },
            }}
          />
        );
      })}

      <Flex
        position="absolute"
        top="5px"
        left={`${left + 5}px`}
        gap="1rem"
        zIndex="6"
        {...(extra
          ? {
              flexDirection: "column",
            }
          : {
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.3)",
            })}
      >
        <Button
          variant="filled"
          fontSize="2.7rem"
          borderRadius="0px 0px 4px 0px"
          {...propButton}
          {...(!!callback
            ? {
                onClick: callback,
              }
            : {
                _active: {
                  backgroundColor: "#009BE0",
                },
              })}
        >
          {title}
        </Button>

        {description && (
          <Text fontSize="1.2rem" color="#fff">
            {description}
          </Text>
        )}
        {extra}
      </Flex>
    </>
  );
};

export default memo(TooltipForgeView);

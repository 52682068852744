import PinOnMap from "components/PinOnMap";
import { CSSProperties, memo } from "react";

interface Props {
  no: any;
  width?: string;
  height?: string;
  color?: CSSProperties["color"];
  colorText?: CSSProperties["color"];
}

const FieldNoComponent = ({
  no,
  width = "40px",
  height = "40px",
  color = "#737373",
  colorText = "white",
}: Props) => {
  return (
    <PinOnMap
      no={no}
      width={width}
      height={height}
      iconProps={
        {
          color: color,
          width: `calc(${width} - 2px)`,
          height: `calc(${height} - 2px)`,
        } as any
      }
      textProps={{ color: colorText }}
    />
  );
};

export default memo(FieldNoComponent);

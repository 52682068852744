import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconSearch(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#737373"
        d="M21 22c-.26 0-.51-.1-.71-.29l-5.39-5.39A8 8 0 0 1 10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8c0 1.85-.63 3.55-1.68 4.9l5.39 5.39a.996.996 0 0 1-.71 1.7zM10 4c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6"
      />
    </Icon>
  );
}

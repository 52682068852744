import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { taskTypeApi } from "apiClient/v2";
import { REDUCER_KEY } from "constants/redux";
import {
  TaskTypesResponse,
  TaskType,
  TaskTypeActionThunkParams,
  CountTaskTypesResponse,
  TASK_TYPE_INITIAL,
} from "interfaces/models/taskType";
import { TaskTypesParams } from "../interfaces/models/taskType";
import * as model from "models/taskType";

// redux-thunk
export const fetchTaskTypes = createAsyncThunk(
  "taskType/get",
  async ({
    page,
    pageSize: limit,
    title,
    paging,
    deleted,
  }: TaskTypesParams): Promise<TaskTypesResponse> => {
    const { data, pagination } = await taskTypeApi.getTaskTypesList({
      page,
      paging,
      limit,
      title,
      deleted,
    });

    return {
      data: data ?? [],
      totalRecord: pagination?.total ?? 0,
      totalPage: pagination?.totalPage ?? 0,
    };
  }
);

// redux-thunk
export const fetchCountTaskTypes = createAsyncThunk(
  "taskType/count",
  async ({
    pageSize: limit,
    title,
    deleted,
  }: TaskTypesParams): Promise<CountTaskTypesResponse> => {
    const { data } = await taskTypeApi.countTaskTypeList({
      limit,
      title,
      deleted,
    });

    return data;
  }
);

export const updateTaskType = createAsyncThunk(
  "taskType/update",
  async (taskType: TaskTypeActionThunkParams["update"]) => {
    const { data } = await model.updateTaskType(taskType);

    return { ...data, columnIndex: taskType.columnIndex };
  }
);

export const upsertTaskType = createAsyncThunk(
  "taskType/upsertTaskType",
  async ({ action, taskType }: TaskTypeActionThunkParams["upsertTaskType"]) => {
    const result = await model.upsertTaskType({
      action,
      taskType,
    });

    if (!result) {
      return null;
    }

    return { ...result, columnIndex: taskType.columnIndex };
  }
);

export const copyTaskType = createAsyncThunk(
  "taskType/copy",
  async ({
    taskType,
  }: TaskTypeActionThunkParams["copy"]): Promise<TaskType | null> =>
    model.copyTaskType({
      taskType,
    })
);

export type MetaTaskType = {
  data: TaskType[];
  totalRecords: number;
  totalPage: number;
};

type Props = {
  isLoadingTaskTypes: boolean;
  isLoadingCountTaskType: boolean;
  isLoadingAction: boolean;
  metaTaskTypes: MetaTaskType;
  // taskTypeUpsert: TaskType | null;
};

const initialState: Props = {
  isLoadingTaskTypes: false,
  isLoadingAction: false,
  isLoadingCountTaskType: false,
  metaTaskTypes: {
    data: [],
    totalRecords: 0,
    totalPage: 0,
  },
  // taskTypeUpsert: null,
};

export const taskTypeSlice = createSlice({
  name: REDUCER_KEY.TASK_TYPE,
  initialState,
  extraReducers: (builder) => {
    // get task types
    builder.addCase(fetchTaskTypes.pending, (state) => {
      state.isLoadingTaskTypes = true;
    });
    builder.addCase(fetchCountTaskTypes.pending, (state) => {
      state.isLoadingCountTaskType = true;
    });
    builder.addCase(
      fetchCountTaskTypes.fulfilled,
      (state, action: PayloadAction<CountTaskTypesResponse>) => {
        state.isLoadingCountTaskType = false;
        state.metaTaskTypes.totalRecords = action.payload.totalItem;
        state.metaTaskTypes.totalPage = action.payload.totalPage;
      }
    );
    builder.addCase(
      fetchTaskTypes.fulfilled,
      (state, action: PayloadAction<TaskTypesResponse>) => {
        state.isLoadingTaskTypes = false;
        state.metaTaskTypes.data = action.payload.data;
      }
    );
    builder.addCase(fetchTaskTypes.rejected, (state) => {
      state.isLoadingTaskTypes = false;
    });
    // update task type
    builder.addCase(updateTaskType.pending, (state) => {
      state.isLoadingAction = true;
    });
    builder.addCase(updateTaskType.fulfilled, (state, action) => {
      state.isLoadingAction = false;
    });
    builder.addCase(updateTaskType.rejected, (state) => {
      state.isLoadingAction = false;
    });
    // update task type by status
    builder.addCase(upsertTaskType.pending, (state) => {
      state.isLoadingAction = true;
    });
    builder.addCase(upsertTaskType.fulfilled, (state, action) => {
      state.isLoadingAction = false;
    });
    builder.addCase(upsertTaskType.rejected, (state) => {
      state.isLoadingAction = false;
    });
  },
  reducers: {
    removeTaskTypeId: (state, action: PayloadAction<string>) => {
      state.metaTaskTypes.data = state.metaTaskTypes.data.filter(
        (taskType) => taskType.id !== action.payload
      );
    },
    insertTaskType: (state, action: PayloadAction<TaskType>) => {
      const isExistCreate = state.metaTaskTypes.data.some(
        (taskType) => taskType.id === TASK_TYPE_INITIAL.id
      );
      if (isExistCreate) {
        return;
      }
      state.metaTaskTypes.data.unshift(action.payload);
    },
    clearState: () => initialState,
    setTaskType: (
      state,
      action: PayloadAction<Partial<TaskType> & { id: string }>
    ) => {
      state.metaTaskTypes.data = state.metaTaskTypes.data.map((taskType) => {
        if (taskType.id !== action.payload.id) return taskType;

        return { ...taskType, ...action.payload };
      });
    },
  },
});

export const { removeTaskTypeId, insertTaskType, clearState, setTaskType } =
  taskTypeSlice.actions;

export default taskTypeSlice.reducer;

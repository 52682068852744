import { Box, BoxProps, Flex, useBoolean } from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";
import { useMemo, useRef } from "react";

interface Props {
  icon?: any;
  iconClass?: string;
  tooltip: string;
  isActive?: boolean;
  isDisabled?: boolean;
  popup?: any;
  isHoverStyle?: boolean;
  resetActiveControl?: () => boolean;
}

const ForgeToolbarButton = ({
  icon,
  iconClass,
  tooltip,
  isActive,
  isDisabled,
  popup,
  isHoverStyle,
  onClick,
  resetActiveControl,
  ...rest
}: Props & BoxProps) => {
  const buttonRef = useRef<HTMLDivElement | null>();
  const popupRef = useRef<HTMLDivElement | null>();
  const [isOpenPopUp, setOpenPopup] = useBoolean();

  const hoverStyle = useMemo(() => {
    return isHoverStyle && !isDisabled && !isActive
      ? {
          margin: "0px",
          border: "1px solid #009BE0",
          borderRadius: "4px",
          color: "#009BE0",
          svg: {
            "path, rect, circle, line": {
              fill: "#009BE0",
            },
          },
        }
      : {};
  }, [isActive, isDisabled, isHoverStyle]);

  const sxStyle = useMemo(() => {
    return {
      svg: isDisabled
        ? {
            "path, rect": {
              opacity: 0.5,
            },
          }
        : isActive
        ? {
            "path, rect": {
              fill: "#009BE0",
              opacity: 1,
            },
          }
        : {
            "path, rect": {
              opacity: 1,
            },
          },
    };
  }, [isActive, isDisabled]);

  const style = useMemo(() => {
    return isActive
      ? {
          boxShadow: "0 0 1px 1px #009BE0",
          borderRadius: "4px",
        }
      : {};
  }, [isActive]);

  const onHandleClickButtonGroup = (e: any) => {
    if (onClick && !isDisabled) {
      let hasActiveControl = false;
      if (resetActiveControl) {
        hasActiveControl = resetActiveControl();
      }
      if (isOpenPopUp) {
        setOpenPopup.off();
      }
      if (!hasActiveControl && !isOpenPopUp) {
        setOpenPopup.on();
      }
      onClick(e);
    }
  };

  const Icon = icon;

  return (
    <Flex position="relative">
      <PCTooltip
        bg="rgba(0,0,0,0.7)"
        color="white"
        placement="top"
        fontSize="1.2rem"
        borderRadius="2px"
        label={tooltip}
      >
        <Box
          ref={(e) => (buttonRef.current = e)}
          {...rest}
          display="inline-block"
          p="7px"
          m="1px"
          outline="none"
          boxSizing="border-box"
          cursor={isDisabled ? "not-allowed" : "pointer"}
          position="relative"
          {...style}
          sx={sxStyle}
          _hover={hoverStyle}
          onClick={onHandleClickButtonGroup}
        >
          <Box
            className="adsk-control adsk-button-arrow"
            right="-8px"
            top="-9px"
            opacity={isDisabled ? 0.2 : 1}
            color={isActive ? "#009BE0" : "unset"}
          >
            <Box className="adsk-button-icon" />
          </Box>
          {icon && <Icon w="24px" h="24px" />}
          {iconClass && (
            <Flex
              className={iconClass}
              alignItems="center"
              fontSize="2.1rem"
              color={isActive ? "#009BE0" : "unset"}
              opacity={isDisabled ? 0.2 : 1}
            />
          )}
        </Box>
      </PCTooltip>

      {popup && isOpenPopUp ? (
        <>
          <Box
            className="overlay"
            position="fixed"
            left={0}
            top={0}
            width="100vw"
            height="var(--app-height)"
            zIndex={1}
            onClick={() => setOpenPopup.off()}
          ></Box>
          <Box
            ref={(e) => (popupRef.current = e)}
            position="absolute"
            bgColor="#ffffff"
            borderRadius="4px"
            left={0}
            bottom="50px"
            zIndex={2}
            onClick={() => setOpenPopup.off()}
          >
            {popup}
          </Box>
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default ForgeToolbarButton;

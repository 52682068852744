import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconWarning({ color, ...rest }: IconProps) {
  return (
    <Icon
      width="24"
      height="24"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3M12 9v4m0 4h.01" />
    </Icon>
  );
}

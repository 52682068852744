import { axiosECS } from "services/baseAxios";

interface CreateUpdateCacheReq {
  key: string;
  value: string;
  ex?: number;
}

const path = "/v2/caches";

export const getCache = async (key: string) => {
  return axiosECS.get(path, { params: { key } });
};

export const createUpdateCache = async (data: CreateUpdateCacheReq) => {
  return axiosECS.post(path, data);
};

export const deleteCache = async (key: string) => {
  return axiosECS.delete(path, { data: { key } });
};

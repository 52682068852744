import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { areaApi, familyApi } from "apiClient/v2";
import { ModalType, SystemModeType, WidgetsMode } from "constants/enum";
import { DISPLAY_MODE } from "constants/forge";
import { REDUCER_KEY } from "constants/redux";
import { Level, ModalTypeT, ModelTree } from "interfaces/models";
import { NeptuneArea, Space } from "interfaces/models/area";
import { Family } from "interfaces/models/family";
import { FamilyInstance } from "interfaces/models/familyInstance";

export type PreviewMode = "2D" | "3D";

export const FORGE_DEFAULT_STATE = {
  modalType: ModalType.NONE as ModalTypeT,
  systemMode: SystemModeType.Task,
  spaces: [],
  isCreateTask: false,
  isCreateSelfInspectionTask: false,
  isCreateDocumentItem: false,
  isMoveTaskLabel: false,
  isMoveDocumentLabel: false,
  isShowArea: false,
  fetchingInspectionItemRecords: false,
  levels: [],
  levelSelected: {} as Level,
  displayMode: undefined,
  searchValue: "",
  systemList: {},
  families: [],
  familyInstanceSelected: {} as FamilyInstance,
  isCollapse: true,
  isLoadedLevels: false,
  isLoadedFamilyInstances: false,
  isLoadedSpaces: false,
  widgetsMode: WidgetsMode.NONE,
  isOpenFilter: false,
  isGeneratingFamilyInstances: false,
  mainModelDbIds: [],
  previewMode: "2D" as PreviewMode,
  isCreatingNewTask: false,
  isLoadedViewer: false,
  isLoadedViewerModelData: false,
  isWaittingCaptureKeynote: false,
  isFilter: false,
  isGeneratingSpaces: false,
  isLoadedSheetTransformRatio: false,
  isDownloadPdfOnMobile: false,
  isLoadedFamilies: false,
  neptuneAreas: [],
  neptuneAreaSelected: {} as NeptuneArea,
  isLoadedNeptuneAreas: false,
  isLoadedExternalId: false,
  isInitialized: false,
};

interface State {
  modalType: ModalTypeT;
  spaces: Space[];
  isCreateTask: boolean;
  isCreateSelfInspectionTask: boolean;
  isCreateDocumentItem: boolean;
  isMoveTaskLabel: boolean;
  isMoveDocumentLabel: boolean;
  isShowArea: boolean;
  modelTree?: ModelTree;
  fetchingInspectionItemRecords?: boolean;
  levels: Level[];
  levelSelected: Level;
  displayMode: DISPLAY_MODE | undefined;
  searchValue: string;
  systemList: { [key: string]: { [key: string]: number[] } };
  mainModelDbIds: number[];
  isCollapse: boolean;
  isLoadedLevels: boolean;
  isLoadedFamilyInstances: boolean;
  isGeneratingFamilyInstances: boolean;
  isGeneratingSpaces: boolean;
  isLoadedSpaces: boolean;
  isOpenFilter: boolean;
  widgetsMode: number;
  systemMode: string;
  previewMode: PreviewMode;
  isCreatingNewTask: boolean;
  isLoadedViewer: boolean;
  isLoadedViewerModelData: boolean;
  isWaittingCaptureKeynote: boolean;
  isFilter: boolean;
  isLoadedSheetTransformRatio: boolean;
  isDownloadPdfOnMobile: boolean;
  families: Family[];
  isLoadedFamilies: boolean;
  neptuneAreas: NeptuneArea[];
  isLoadedNeptuneAreas: boolean;
  neptuneAreaSelected: NeptuneArea;
  isLoadedExternalId: boolean;
  isInitialized: boolean;
}

const initialState: State = {
  ...FORGE_DEFAULT_STATE,
};

export const fetchNeptuneAreasByBimFile = createAsyncThunk(
  "forgeViewer/fetchAreasByBimFile",
  async ({ bimFileId }: { bimFileId: string }) => {
    const { data } = await areaApi.getNeptuneAreaList(bimFileId);

    return data;
  }
);

export const fetchFamilies = createAsyncThunk(
  "forgeViewer/fetchFamilies",
  async () => {
    const { data } = await familyApi.getFamilyList();

    return data;
  }
);

export const forgeViewerSlice = createSlice({
  name: REDUCER_KEY.FORGE_VIEWER,
  initialState,
  reducers: {
    resetToInitState: () => initialState,

    setNeptuneAreaSelected: (
      state,
      action: PayloadAction<NeptuneArea | undefined>
    ) => {
      state.neptuneAreaSelected = action.payload || ({} as NeptuneArea);
    },
    setCreateTask: (state, action: PayloadAction<boolean>) => {
      if (action.payload && state.isMoveTaskLabel) {
        state.isMoveTaskLabel = false;
      }

      state.isCreateTask = action.payload;
    },

    setCreateSelfInspectionTask: (state, action: PayloadAction<boolean>) => {
      state.isCreateSelfInspectionTask = action.payload;
    },
    setIsCreateDocumentItem: (state, action: PayloadAction<boolean>) => {
      if (action.payload && state.isMoveDocumentLabel) {
        state.isMoveDocumentLabel = false;
      }

      state.isCreateDocumentItem = action.payload;
    },
    setIsMoveTaskLabel: (state, action: PayloadAction<boolean>) => {
      if (action.payload && state.isCreateTask) {
        state.isCreateTask = false;
      }

      state.isMoveTaskLabel = action.payload;
    },
    setIsMoveDocumentLabel: (state, action: PayloadAction<boolean>) => {
      if (action.payload && state.isCreateDocumentItem) {
        state.isCreateDocumentItem = false;
      }

      state.isMoveDocumentLabel = action.payload;
    },
    setIsShowArea: (state, action: PayloadAction<boolean>) => {
      state.isShowArea = action.payload;
    },
    setModelTree: (state, action: PayloadAction<ModelTree>) => {
      state.modelTree = action.payload;
    },
    setLevelSelected: (state, action: PayloadAction<Level | undefined>) => {
      // when change level selected we need reset is loaded viewer model data
      if (
        state.levelSelected &&
        state.levelSelected.guid !== action.payload?.guid
      ) {
        state.isLoadedViewerModelData = false;
      }
      state.levelSelected = action.payload || ({} as Level);
    },
    setDisplayMode(state, action: PayloadAction<DISPLAY_MODE | undefined>) {
      state.displayMode = action.payload;
    },
    switchDisplayMode(state) {
      state.displayMode =
        state.displayMode === DISPLAY_MODE["2D"]
          ? DISPLAY_MODE["3D"]
          : DISPLAY_MODE["2D"];
    },
    setOpenFilter(state, action: PayloadAction<boolean>) {
      state.isOpenFilter = action.payload;
    },
    setLoadedFamilyInstances(state, action: PayloadAction<boolean>) {
      state.isLoadedFamilyInstances = action.payload;
    },
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    setSystemList(
      state,
      action: PayloadAction<{ [key: string]: { [key: string]: number[] } }>
    ) {
      state.systemList = action.payload;
    },
    setNeptuneAreas: (state, action: PayloadAction<NeptuneArea[]>) => {
      state.neptuneAreas = action.payload;
    },
    setSpaces: (state, action: PayloadAction<Space[]>) => {
      state.spaces = action.payload;
    },

    setLoadedLevels: (state, action: PayloadAction<boolean>) => {
      state.isLoadedLevels = action.payload;
    },

    setIsGeneratingFamilyIntances: (state, action: PayloadAction<boolean>) => {
      state.isGeneratingFamilyInstances = action.payload;
    },
    setIsGeneratingSpaces: (state, action: PayloadAction<boolean>) => {
      state.isGeneratingSpaces = action.payload;
    },
    setIsLoadedSpaces: (state, action: PayloadAction<boolean>) => {
      state.isLoadedSpaces = action.payload;
    },
    setIsLoadedNeptuneAreas: (state, action: PayloadAction<boolean>) => {
      state.isLoadedNeptuneAreas = action.payload;
    },
    setLevels: (state, action: PayloadAction<Level[]>) => {
      state.levels = action.payload;
    },
    setIsLoadedLevels: (state, action: PayloadAction<boolean>) => {
      state.isLoadedLevels = action.payload;
    },
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
    setMainModelDbIds: (state, action: PayloadAction<number[]>) => {
      state.mainModelDbIds = action.payload;
    },
    setModalType: (state, action: PayloadAction<ModalTypeT>) => {
      state.modalType = action.payload;
    },
    collapse: (state) => {
      state.isCollapse = !state.isCollapse;
    },
    setWidgetsMode: (state, action: PayloadAction<number>) => {
      state.widgetsMode = action.payload;
    },
    resetWidgetsMode: (state) => {
      state.widgetsMode = WidgetsMode.NONE;
    },
    setSystemMode: (state, action: PayloadAction<string>) => {
      const systemMode = action.payload;
      if (systemMode !== SystemModeType.Task && state.isMoveTaskLabel) {
        state.isMoveTaskLabel = false;
      }

      state.systemMode = systemMode;
    },
    setPreviewMode: (state, action: PayloadAction<PreviewMode>) => {
      state.previewMode = action.payload;
    },
    setIsCreatingNewTask: (state, action: PayloadAction<boolean>) => {
      state.isCreatingNewTask = action.payload;
    },
    setIsLoadedViewer: (state, action: PayloadAction<boolean>) => {
      state.isLoadedViewer = action.payload;
      if (!action.payload && state.isLoadedViewerModelData) {
        state.isLoadedViewerModelData = false;
      }
    },
    setIsLoadedViewerModelData: (state, action: PayloadAction<boolean>) => {
      state.isLoadedViewerModelData = action.payload;
      if (action.payload && !state.isLoadedViewer) {
        state.isLoadedViewer = true;
      }
    },
    setIsLoadedSheetTransformRatio: (state, action: PayloadAction<boolean>) => {
      state.isLoadedSheetTransformRatio = action.payload;
    },
    setIsWaittingCaptureKeynote: (state, action: PayloadAction<boolean>) => {
      state.isWaittingCaptureKeynote = action.payload;
    },
    setIsFilter: (state, action: PayloadAction<boolean>) => {
      state.isFilter = action.payload;
    },
    setIsLoadedExternalId: (state, action: PayloadAction<boolean>) => {
      state.isLoadedExternalId = action.payload;
    },
    setIsDownloadPdfOnMobile: (state, action: PayloadAction<boolean>) => {
      state.isDownloadPdfOnMobile = action.payload;
    },
    setFamilies: (state, action: PayloadAction<Family[]>) => {
      state.families = action.payload;
    },
    resetState: (state) => {
      state.isMoveTaskLabel = false;
      state.modalType = ModalType.NONE;
    },

    clearState: () => initialState,

    updateFamily: (state, action: PayloadAction<Family>) => {
      state.families = state.families.map((family) => {
        if (family.id === action.payload.id) {
          return { ...family, ...action.payload };
        }

        return family;
      });
    },

    addFamily: (state, action: PayloadAction<Family>) => {
      state.families.unshift(action.payload);
    },

    deleteFamily: (state, action: PayloadAction<string>) => {
      state.families = state.families.filter(
        (family) => family.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNeptuneAreasByBimFile.pending, (state) => {
      state.isLoadedNeptuneAreas = false;
    });
    builder.addCase(fetchNeptuneAreasByBimFile.fulfilled, (state, action) => {
      state.neptuneAreas = action.payload || [];
      state.isLoadedNeptuneAreas = true;
    });
    builder.addCase(fetchFamilies.pending, (state) => {
      state.isLoadedFamilies = false;
    });
    builder.addCase(fetchFamilies.fulfilled, (state, action) => {
      state.families = action.payload || [];
      state.isLoadedFamilies = true;
    });
  },
});

export const {
  setLevels,
  switchDisplayMode,
  setModalType,
  setNeptuneAreaSelected,
  setModelTree,
  setLevelSelected,
  setDisplayMode,
  setSearchValue,
  setSystemList,
  setOpenFilter,
  setNeptuneAreas,
  collapse,
  resetToInitState,
  setCreateTask,
  setCreateSelfInspectionTask,
  setLoadedFamilyInstances,
  resetState,
  setLoadedLevels,
  setIsShowArea,
  setWidgetsMode,
  resetWidgetsMode,
  setMainModelDbIds,
  setIsGeneratingFamilyIntances,
  setSystemMode,
  setPreviewMode,
  setIsCreatingNewTask,
  setIsLoadedViewer,
  setIsLoadedSpaces,
  setIsWaittingCaptureKeynote,
  setIsFilter,
  setIsGeneratingSpaces,
  setSpaces,
  setIsLoadedNeptuneAreas,
  setIsCreateDocumentItem,
  setIsMoveTaskLabel,
  setIsLoadedViewerModelData,
  clearState,
  setIsLoadedLevels,
  setIsLoadedSheetTransformRatio,
  setIsDownloadPdfOnMobile,
  setFamilies,
  updateFamily,
  addFamily,
  deleteFamily,
  setIsLoadedExternalId,
  setIsInitialized,
  setIsMoveDocumentLabel,
} = forgeViewerSlice.actions;

export default forgeViewerSlice.reducer;

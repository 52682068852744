/* eslint-disable no-console */
import { WSEVent } from "constants/websocket";
import { WSMessage, WSMessageAction } from "interfaces/models/websocket";
import { AppWebSocket } from "models/websocket";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Socket } from "socket.io-client";
import { logDev } from "utils/logs";

const DEFAULT_EVT_LISTEN = [
  WSEVent.CONNECT,
  WSEVent.DISCONNECT,
  WSEVent.CONNECT_ERROR,
  WSEVent.SOCKET_ERROR,
  WSEVent.DATA_CHANGE,
];

interface ContextProps {
  socket: Socket | null;
  isWebSocketOpen: boolean;
  messageList: any[];
  emitData: (message: WSMessage) => void;
  joinChannel: (channel: string) => void;
  leaveChannel: (channel: string) => void;
  clearMessageList: () => void;
}

export const SocketContext = createContext<ContextProps>({
  socket: null,
  isWebSocketOpen: false,
  messageList: [],
  emitData: () => {},
  joinChannel: () => {},
  leaveChannel: () => {},
  clearMessageList: () => {},
});

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const socket = useRef<Socket | null>(null);
  const [isWSConnected, setIsWSConnected] = useState(false);
  const [messageList, setMessageList] = useState<any[]>([]);
  const token = useSelector((state: RootState) => state.user.token);

  useEffect(() => {
    if (token && !isWSConnected && !socket.current) {
      const socketInstance = new AppWebSocket();
      socketInstance.initialize(token);
      socket.current = AppWebSocket.instance;
      socket.current.on(WSEVent.CONNECT, () => {
        logDev("socket connected");
        setIsWSConnected(true);
      });

      socket.current.on(WSEVent.DISCONNECT, () => {
        logDev("socket disconnected");
        setIsWSConnected(false);
      });

      socket.current.on(WSEVent.CONNECT_ERROR, (error: any) => {
        console.error("socket connect error", error);
      });

      socket.current.on(WSEVent.SOCKET_ERROR, (error: any) => {
        console.error("socket error", error);
      });

      socket.current.on(WSEVent.DATA_CHANGE, (data: any) => {
        if (data?.action === WSMessageAction.SEND_MESSAGE) {
          setMessageList((prev) => [...prev, data]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    return () => {
      if (socket.current && socket.current.connected) {
        DEFAULT_EVT_LISTEN.forEach((evt) => {
          socket.current?.off(evt);
        });
        socket.current.disconnect();
      }
    };
  }, []);

  const emitData = useCallback(
    (message: WSMessage) => {
      if (!socket.current || !isWSConnected) return;
      const channel = message.channel;
      const action = WSMessageAction.SEND_MESSAGE;
      const dataEvent = {
        channel,
        action,
        message,
      };
      socket.current.emit(WSEVent.DATA_CHANGE, dataEvent);
    },
    [isWSConnected]
  );

  const joinChannel = useCallback(
    (channel: string) => {
      if (!socket.current || !isWSConnected) return;
      socket.current.emitWithAck(WSEVent.JOIN_CHANNEL, { channel });
    },
    [isWSConnected]
  );

  const leaveChannel = useCallback(
    (channel: string) => {
      if (!socket.current || !isWSConnected) return;
      socket.current.emitWithAck(WSEVent.LEAVE_CHANNEL, { channel });
    },
    [isWSConnected]
  );

  const clearMessageList = useCallback(() => {
    setMessageList([]);
  }, [setMessageList]);

  return (
    <SocketContext.Provider
      value={{
        socket: socket.current,
        isWebSocketOpen: isWSConnected,
        emitData,
        joinChannel,
        leaveChannel,
        clearMessageList,
        messageList,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

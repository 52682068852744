import { Box, BoxProps } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/react";
import { memo, useMemo } from "react";
import SVG, { Props as SVGProps } from "react-inlinesvg";

interface Props {
  pathFill?: string;
  pathStroke?: string;
  rectFill?: string;
  ellipseFill?: string;
  ref?: any;
  children?: React.ReactNode;
}

export type SVGIconProps = Props & SVGProps & BoxProps;

function SvgIconPure({
  pathStroke,
  pathFill,
  rectFill,
  ellipseFill,
  ref,
  ...rest
}: SVGIconProps) {
  const style = useMemo(() => {
    const result: any = {};
    if (pathFill) {
      result["path"] = {
        fill: `${pathFill}`,
      };
    }

    if (pathStroke) {
      result["path"] = {
        stroke: `${pathStroke}`,
      };
    }

    if (rectFill) {
      result["rect"] = {
        fill: `${rectFill} !important`,
      };
    }

    if (ellipseFill) {
      result["ellipse"] = {
        stroke: `${ellipseFill} !important`,
      };
    }

    return result;
  }, [pathStroke, pathFill, rectFill, ellipseFill]);

  return <Box as={SVG} sx={style} ref={ref} loader={<Spinner />} {...rest} />;
}

export const SvgIcon = memo(SvgIconPure);

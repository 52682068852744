import { CellProperty, DocumentCategoryKey } from "constants/enum";
import {
  Operation,
  StoreName,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { TaskLogTypeKey } from "constants/task";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { Blackboard } from "interfaces/models/blackboard";
import { CellType } from "interfaces/models/component";
import { DataLog } from "interfaces/models/dataLog";
import { TaskComment, TaskLog } from "interfaces/models/task";
import store from "redux/store";
import { uuid } from "utils/common";
import { DocumentLogTypeKey } from "utils/data-logs";
import { getIndexedDb } from "utils/indexedDb";
import { arrayToObject } from "utils/object";
import { getAllCells } from "utils/tableCell";
import { getTaskContentLog } from "./commentLog";
import isArray from "lodash/isArray";

export enum OPERATION_ITEM_LOG {
  NONE = 0,
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
}

const FIELDS_TYPE_DYNAMIC = [
  CellProperty.DYNAMIC_FIELDS_FOR_ITEM,
  CellProperty.DYNAMIC_FIELDS_FOR_CATEGORY,
];

export type InsertDataLogToIndexedDb = {
  contentLogParams: GetContentLog;
  identifyCol: keyof DataLog;
  identifyValue: string;
  createdBy: string;
  type?: "category" | "item";
  createdAt?: Date;
};

export interface GetContentLog {
  field:
    | keyof DocumentCategoryDTO
    | keyof DocumentItemDTO
    | keyof DocumentSubItemDTO;
  value?: any;
  operation?: OPERATION_ITEM_LOG;
  nameDynamicField?: string;
  prefixFieldName?: string;
  requestId?: string;
  blackboardThumbnail?: string;
  blackboardImagePosition?: object;
  blackboardTemplateId?: string;
  blackboardData?: Blackboard;
  isTypeRevert?: boolean;
  isEditMedia?: boolean;
  isAddMedia?: boolean;
  isDeleteMedia?: boolean;
  displayValue?: string;
  displayValueType?: string;
  displayLabel?: string;
  isLogDefault?: boolean;
}

export const insertTaskLogToIndexedDb = async (params: {
  taskLog: TaskComment;
  requestId?: string;
}) => {
  const { taskLog, requestId } = params;
  const indexedDb = await getIndexedDb();
  indexedDb?.put(uuid(), {
    requestTime: Date.now(),
    requestId,
    store: StoreName.TASK_COMMENTS,
    operation: Operation.Post,
    status: UpdateToOnlineStatus.Success,
    data: taskLog,
  });
};

export const insertDataLogToIndexedDb = async ({
  contentLogParams,
  identifyCol,
  identifyValue,
  createdBy,
  createdAt,
}: InsertDataLogToIndexedDb) => {
  const now = createdAt || new Date();
  const {
    field,
    value,
    requestId,
    isTypeRevert,
    blackboardThumbnail,
    blackboardImagePosition,
    blackboardTemplateId,
    blackboardData,
    isEditMedia,
    isAddMedia,
    isDeleteMedia,
    displayValue,
    displayLabel,
    displayValueType,
    isLogDefault,
  } = contentLogParams;

  const offlineId = uuid();
  const log: DataLog = {
    id: offlineId,
    offlineId,
    field,
    value: {
      data: value,
      isRevertDataByLog: isTypeRevert,
      thumbnail: blackboardThumbnail,
      blackboardImagePosition,
      blackboardTemplateId,
      blackboardData,
      isEditMedia,
      isAddMedia,
      isDeleteMedia,
      displayValue,
      displayValueType,
      displayLabel,
      isLogDefault,
    },
    title: field,
    createdBy,
    createdAt: now,
    updatedAt: now,
    requestId,
    [identifyCol]: identifyValue,
  };
  const indexedDb = await getIndexedDb();
  indexedDb?.put(uuid(), {
    requestTime: Date.now(),
    store: StoreName.DATA_LOGS,
    operation: Operation.Post,
    status: UpdateToOnlineStatus.Success,
    data: log,
    requestId,
  });

  return log;
};

export const transformMapTitleKey = (logs: GetContentLog[]) => {
  const mapLogs: { [key: string]: GetContentLog } = {};
  const mapTitleKey: { [key: string]: string } = {};
  const mapDisplayValueKey: { [key: string]: string | undefined } = {};
  const mapDisplayLabelKey: { [key: string]: string | undefined } = {};
  const mapDisplayValueTypeKey: { [key: string]: string | undefined } = {};
  const state = store.getState();

  logs.forEach((log) => {
    const key = log.field as any;
    const logValue = structuredClone(log.value);
    const title = log.field;
    mapDisplayLabelKey[key] = log.displayLabel;
    mapDisplayValueTypeKey[key] = log.displayValueType;

    switch (key) {
      case DocumentCategoryKey.PARTNER_COMPANY:
        log.displayValue = state.project.partnerCompanies.find(
          (com) => com.id === logValue
        )?.name;
        mapDisplayValueKey[key] = log.displayValue;
        break;

      case DocumentCategoryKey.USER_ASSIGNED:
      case DocumentCategoryKey.USER_IMPLEMENT:
      case DocumentCategoryKey.USER_MEASURE:
      case DocumentCategoryKey.USER_MANAGE:
      case DocumentCategoryKey.MANAGER:
        const userNamesFiltered: string[] = [];
        const userIds = (
          Array.isArray(logValue) ? logValue : [logValue]
        ).sort();
        userIds.forEach((id) => {
          const user = state.user.users.find((u) => u.id === id);
          if (user) {
            userNamesFiltered.push(user.name || "");
          }
        });
        if (userNamesFiltered.length) {
          log.displayValue = userNamesFiltered.join(",");
          mapDisplayValueKey[key] = log.displayValue;
        }
        break;

      case DocumentCategoryKey.DATA:
        log.displayValue = state.user.users.find(
          (user) => user.id === Object.values(logValue || {}).at(0)
        )?.name;
        mapDisplayValueKey[key] = log.displayValue;
        break;

      default:
        break;
    }

    mapLogs[log.field] = log;

    if (title) {
      mapTitleKey[key] = title;
    }
  });

  return {
    mapLogs,
    mapTitleKey,
    mapDisplayValueKey,
    mapDisplayLabelKey,
    mapDisplayValueTypeKey,
  };
};

const checkIsDynamicFieldByCell = (cell: CellType) =>
  FIELDS_TYPE_DYNAMIC.includes((cell.cellLinkedData?.field || "") as any);

export const getMapDynamicFieldTitle = (
  components: DocumentTemplateDTO["components"] = []
) => {
  const mapDynamicFieldSection = arrayToObject(
    components.map((com) => com.detail?.dynamicFieldsSections || []).flat(1),
    "value"
  );
  const mapDynamicFieldTitle: Record<
    string,
    { label: string; groupName?: string; dynamicFieldType: string }
  > = {};

  components
    .map((com) => getAllCells(com))
    .flat(1)
    .forEach((cell) => {
      if (checkIsDynamicFieldByCell(cell)) {
        const cellOption = cell?.cellLinkedData?.options;
        mapDynamicFieldTitle[cell.cellId] = {
          dynamicFieldType: cellOption?.dynamicFieldType || "",
          label: cellOption?.dynamicFieldLabel || "",
          groupName:
            mapDynamicFieldSection[cellOption?.dynamicFieldSection?.id || ""]
              ?.name,
        };
      }
    });

  return mapDynamicFieldTitle;
};

export const addLogCreateTaskForDocItem = async (params: {
  taskTypeTitle?: string;
  requestId?: string;
  task: TaskDTO;
  insertDocumentItemLog?: (params: GetContentLog) => Promise<void>;
}) => {
  const { task, taskTypeTitle, requestId, insertDocumentItemLog } = params;

  await insertDocumentItemLog?.({
    field: DocumentLogTypeKey.CREATE_TASK as any,
    displayValue: taskTypeTitle,
    requestId,
  });
  const logs = getDefaultLogWhenCreateTask(task, task.id, taskTypeTitle);
  logs.forEach((log) => {
    insertTaskLogToIndexedDb({ taskLog: log, requestId });
  });
};

export const getDefaultLogWhenCreateTask = (
  task: TaskDTO,
  id: string,
  taskTypeTitle?: string
) => {
  const taskLogs: TaskLog[] = [];
  taskLogs.push(
    getTaskContentLog({
      field: "taskDate",
      taskId: id,
      value: task.taskDate,
      type: TaskLogTypeKey.TASK_DATE,
      isLogDefault: true,
    })
  );
  taskLogs.push(
    getTaskContentLog({
      field: "position",
      value: { ...task.position, externalId: task.externalId },
      taskId: id,
      createdAt: task.createdAt,
      type: TaskLogTypeKey.MOVE_TASK,
    }) as TaskLog
  );
  if (task.taskTypeId) {
    let log = getTaskContentLog({
      field: "taskTypeId",
      taskId: id,
      value: task.taskTypeId,
      type: TaskLogTypeKey.CONTENT_TYPE,
      isLogDefault: true,
    });
    log = {
      ...log,
      value: { ...log.value, displayValue: taskTypeTitle },
    };
    taskLogs.push(log);
  }
  if (task.tags?.length) {
    taskLogs.push(
      getTaskContentLog({
        field: "tags",
        taskId: id,
        value: task.tags,
        type: TaskLogTypeKey.TAGS,
        isLogDefault: true,
      })
    );
  }

  return taskLogs;
};

type Log = DataLog | TaskComment;

export const addOrUpdateLog: any =
  (log: Log | Log[], isUpdate?: boolean) => (prevState: Log[]) => {
    const list = isArray(log) ? log : [log];
    const nextState = [...prevState];
    const exist = new Map<any, number>(
      nextState.map(({ id }, index) => [id, index])
    );
    if (isUpdate) {
      list.forEach((log) => {
        const index = exist.get(log.id);
        if (typeof index !== "undefined") {
          nextState[index] = log;
        }
      });
    } else {
      list.forEach((log) => {
        if (!exist.has(log.id)) {
          nextState.push(log);
        }
      });
    }

    return nextState.sort(
      (a, b) =>
        new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
    );
  };

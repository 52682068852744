import { s3Api } from "apiClient/v2";
import { fetchRequest } from "./fetch";
import { getPreSignUrl } from "./file";

function concatenate(arrays: Uint8Array[]) {
  if (!arrays.length) return null;
  const totalLength = arrays.reduce((acc, value) => acc + value.length, 0);
  const result = new Uint8Array(totalLength);
  let length = 0;
  for (const array of arrays) {
    result.set(array, length);
    length += array.length;
  }

  return result;
}

function getBinaryContent(url: string, start: number, end: number, i: number) {
  return new Promise(async (resolve, reject) => {
    try {
      fetch(url, {
        method: "GET",
        headers: {
          range: `bytes=${start}-${end}`,
        },
      }).then(async (res) => {
        resolve({ index: i, buffer: await res.arrayBuffer() });
      });
    } catch (err: any) {
      reject(new Error(err));
    }
  });
}

async function asyncPool(
  concurrency: number,
  iterable: any[],
  iteratorFn: Function
) {
  const ret = []; // Store all asynchronous tasks
  const executing = new Set(); // Stores executing asynchronous tasks
  for (const item of iterable) {
    // Call the iteratorFn function to create an asynchronous task
    const p = Promise.resolve().then(() => iteratorFn(item, iterable));

    ret.push(p); // save new async task
    executing.add(p); // Save an executing asynchronous task

    const clean = () => executing.delete(p);
    p.then(clean).catch(clean);
    if (executing.size >= concurrency) {
      // Wait for faster task execution to complete
      await Promise.race(executing);
    }
  }

  return Promise.all(ret);
}

export async function downloadFileFromS3({
  fileName,
  filePath,
  shouldCache,
}: {
  fileName: string;
  filePath: string;
  shouldCache?: boolean;
}) {
  const bufferDefault = new Uint8Array(0);
  const url = await getPreSignUrl(filePath, fileName, false, shouldCache).catch(
    () => {
      if (shouldCache) {
        throw new Error("get presigned url error");
      }

      return undefined;
    }
  );

  if (!url) {
    if (shouldCache) {
      throw new Error("url empty");
    }

    return bufferDefault;
  }

  return await fetchRequest(url, {}, shouldCache)
    .then((res) => {
      if (!res.ok) {
        if (shouldCache) {
          throw new Error(res.statusText);
        }

        return bufferDefault;
      }

      return res.arrayBuffer();
    })
    .catch((err) => {
      if (shouldCache) {
        throw new Error(err.message);
      }

      return bufferDefault;
    });
}

export async function downloadMultipartFromS3({
  fileName,
  filePath,
  chunkSize,
  poolLimit = 10,
}: {
  fileName: string;
  filePath: string;
  chunkSize: number;
  poolLimit: number;
}) {
  try {
    const url = await getPreSignUrl(filePath, fileName);
    const contentLength =
      (await s3Api.getS3FileSize({ fileName, filePath }))?.data
        ?.contentLength || 0;

    if (!contentLength) {
      return new Uint8Array(0);
    }

    const chunks =
      typeof chunkSize === "number" ? Math.ceil(contentLength / chunkSize) : 1;

    const results = await asyncPool(
      poolLimit,
      [...new Array(chunks).keys()],
      (i: number) => {
        const start = i * chunkSize;
        const end =
          i + 1 === chunks ? contentLength - 1 : (i + 1) * chunkSize - 1;

        return getBinaryContent(url, start, end, i);
      }
    );

    const sortedBuffers = results.map((item) => new Uint8Array(item.buffer));

    return concatenate(sortedBuffers) || new Uint8Array(0);
  } catch (err) {
    return new Uint8Array(0);
  }
}

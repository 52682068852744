import { PayloadAction } from "@reduxjs/toolkit";
import { TYPE_USER } from "constants/app";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { ProjectDetail } from "interfaces/models/project";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDataProjectDetail, fetchProjectDetail } from "redux/projectSlice";
import { RootState, useAppDispatch } from "redux/store";
import { routePath } from "routes/path";
import { getBimFileInfo } from "utils/bim";
import { getLocalStorageUser } from "utils/user";

export const useProjectBimFile = () => {
  const { bimFileId, version } = useParams();
  const navigate = useNavigate();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const appDispatch = useAppDispatch();

  const loadProject = useCallback(
    async (bimFileId: string) => {
      const { payload: projectDetail } = (await appDispatch(
        fetchDataProjectDetail(bimFileId!)
      )) as PayloadAction<DataProjectModel>;
      if (!projectDetail.id) {
        navigate(routePath.NotFound);

        return;
      }

      const { payload: forgeProjectDetail } = (await appDispatch(
        fetchProjectDetail({ projectId: projectDetail.projectId })
      )) as PayloadAction<ProjectDetail>;

      if (!forgeProjectDetail) {
        navigate(routePath.NotFound);
      }
    },
    [appDispatch, navigate]
  );

  useEffect(() => {
    if (dataProjectDetail?.id === bimFileId) {
      return;
    }

    if (bimFileId) {
      loadProject(bimFileId);
    }
  }, [bimFileId, dataProjectDetail?.id, loadProject]);

  useEffect(() => {
    (async () => {
      if (dataProjectDetail && Object.keys(dataProjectDetail).length) {
        const { id, role } = getLocalStorageUser() || {};
        let accessLinkNotFound = !dataProjectDetail;
        if (bimFileId) {
          const urn =
            dataProjectDetail?.defaultBimPathId?.split("/").pop() || "";
          const infoBim = getBimFileInfo(urn);
          accessLinkNotFound =
            accessLinkNotFound ||
            infoBim?.bimFileId !== bimFileId ||
            (!!version && infoBim.version !== version);
        }

        if (accessLinkNotFound) {
          return navigate(routePath.NotFound);
        }
        if (
          role != TYPE_USER.ROLE_ADMIN &&
          !dataProjectDetail?.personInCharge?.includes(id || "") &&
          id !== dataProjectDetail?.userCreated
        ) {
          return navigate(routePath.AccessError);
        }
      }
    })();
  }, [bimFileId, dataProjectDetail, navigate, version]);
};

import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function Icon3D2D(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M8.007 8.068a1.3 1.3 0 0 1 .643.015c.184.057.366.14.528.24l4.72 2.568c.19.09.355.203.497.319a1 1 0 0 1 .094.092c.086.1.207.257.282.481l.022.08q.063.298.047.601v4.963q.01.06.01.123c0 .175-.013.37-.057.577l-.022.08c-.075.224-.196.38-.282.481a1 1 0 0 1-.095.093 2.5 2.5 0 0 1-.495.319l-4.705 2.56a2 2 0 0 1-.602.262 1.3 1.3 0 0 1-.643-.015 2.3 2.3 0 0 1-.528-.239L2.701 19.1a2.5 2.5 0 0 1-.496-.32 1 1 0 0 1-.095-.092 1.4 1.4 0 0 1-.282-.48l-.022-.08a2.2 2.2 0 0 1-.046-.602v-5.062a2.2 2.2 0 0 1 .046-.601l.022-.08c.075-.224.196-.38.282-.48a1 1 0 0 1 .095-.093 2.4 2.4 0 0 1 .496-.32l4.704-2.56a2 2 0 0 1 .602-.262m-4.747 5.05v4.432l-.002.054a1 1 0 0 0-.001.091q.05.033.097.054l.044.022 4.152 2.26v-4.585zm5.79 2.328v4.584l4.151-2.259.044-.022a1 1 0 0 0 .099-.055l.003-.04a1 1 0 0 1-.007-.104v-4.433zm3.476-3.594L8.3 14.147l-4.227-2.295 4.085-2.223a1 1 0 0 0 .083-.052.3.3 0 0 1 .055-.027 1 1 0 0 1 .101.053l.044.026z"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M13.5 3.54a.25.25 0 0 0-.25.25v7c0 .137.112.25.25.25h7a.25.25 0 0 0 .25-.25v-7a.25.25 0 0 0-.25-.25zm-1.75.25c0-.967.784-1.75 1.75-1.75h7c.966 0 1.75.783 1.75 1.75v7a1.75 1.75 0 0 1-1.75 1.75h-7a1.75 1.75 0 0 1-1.75-1.75zm8.71 14.45v-4.45h1v4.45c0 .825-.674 1.5-1.5 1.5h-4.45v-1h4.45c.274 0 .5-.227.5-.5"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="m21.304 13.453-.224.448zl.105.053 1.49 1.984a.5.5 0 0 1-.799.601L21 14.627l-1.1 1.464a.5.5 0 1 1-.8-.6l1.441-1.919.045-.044.026-.026.028-.021a.56.56 0 0 1 .354-.11.7.7 0 0 1 .264.062l.029.012.01.005.003.002h.002zM17.9 17.489a.5.5 0 0 1-.1.7l-1.464 1.1 1.464 1.1a.5.5 0 1 1-.6.8l-1.919-1.441-.045-.045-.025-.026-.021-.028a.56.56 0 0 1-.11-.354.7.7 0 0 1 .062-.264l.012-.028.005-.01.002-.004v-.002l.001-.001.448.223-.448-.223.053-.106 1.984-1.49a.5.5 0 0 1 .7.099"
        clipRule="evenodd"
      />
    </Icon>
  );
}

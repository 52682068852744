import { iKeyLabel } from "components/ui/KeyLabel";
import React from "react";

const EditorDocumentContext = React.createContext<{
  isContentFromS3: boolean;
  isResetDataKeynote: boolean;
  isEditMode: boolean;
  isCreatingKeynote: boolean;
  callbackDragKeynoteStop: (keylabel: Partial<iKeyLabel>) => void;
}>({} as any);

export default EditorDocumentContext;

import { UserSetting } from "interfaces/models/user";
import { InspectionItemType, Priority, TASK_PRINT_MODE } from "./enum";

export const listCustomTags = [
  {
    name: "スリーブインサート工事",
    value: "スリーブインサート工事",
  },
  {
    name: "ダクト工事",
    value: "ダクト工事",
  },
  {
    name: "空調配管工事",
    value: "空調配管工事",
  },
  {
    name: "冷媒配管工事",
    value: "冷媒配管工事",
  },
  {
    name: "衛生配管工事",
    value: "衛生配管工事",
  },
  {
    name: "消火配管工事",
    value: "消火配管工事",
  },
  {
    name: "保温工事",
    value: "保温工事",
  },
  {
    name: "自動制御工事",
    value: "自動制御工事",
  },
  {
    name: "機器据付工事",
    value: "機器据付工事",
  },
  {
    name: "電気工事",
    value: "電気工事",
  },
  {
    name: "仮設工事",
    value: "仮設工事",
  },
];

export const listSystemTags = [
  {
    name: "SA給気",
    value: "SA給気",
  },
  {
    name: "RA還気",
    value: "RA還気",
  },
  {
    name: "OA外気",
    value: "OA外気",
  },
  {
    name: "OSA外気処理給気",
    value: "OSA外気処理給気",
  },
  {
    name: "EA排気",
    value: "EA排気",
  },
  {
    name: "PAパス",
    value: "PAパス",
  },
  {
    name: "VOA換気外気",
    value: "VOA換気外気",
  },
  {
    name: "KEA厨房排気",
    value: "KEA厨房排気",
  },
  {
    name: "CH冷温水(往)",
    value: "CH冷温水(往)",
  },
  {
    name: "CHR冷温水(還)",
    value: "CHR冷温水(還)",
  },
  {
    name: "R冷媒2way",
    value: "R冷媒2way",
  },
  {
    name: "W加湿給水",
    value: "W加湿給水",
  },
  {
    name: "Dドレン",
    value: "Dドレン",
  },
  {
    name: "HW給湯(一過式)",
    value: "HW給湯(一過式)",
  },
];

export const listOfficeTags = [
  {
    name: "本社",
    value: "本社",
  },
  {
    name: "東京本店",
    value: "東京本店",
  },
  {
    name: "横浜支店",
    value: "横浜支店",
  },
  {
    name: "関信越支店",
    value: "関信越支店",
  },
  {
    name: "東北支店",
    value: "東北支店",
  },
  {
    name: "札幌支店",
    value: "札幌支店",
  },
  {
    name: "名古屋支店",
    value: "名古屋支店",
  },
  {
    name: "九州支店",
    value: "九州支店",
  },
  {
    name: "エンジニアリング事業部",
    value: "エンジニアリング事業部",
  },
  {
    name: "大阪支店",
    value: "大阪支店",
  },
  {
    name: "中四国支店",
    value: "中四国支店",
  },
  {
    name: "国際グループ事業統括部",
    value: "国際グループ事業統括部",
  },
];

export const DEFAULT_TASK_FILTER_DATA: Partial<UserSetting> = {
  attached: [],
  tags: [],
  status: [...Object.values(InspectionItemType)] as string[],
  priority: [
    ...Object.values(Priority).filter((t) => typeof t === "number"),
  ] as number[],
  contents: [],
  userCreated: [],
  userAssigned: [],
  objectTypes: [],
  hasImage: true,
  hasNoImage: true,
  documentTaskIds: [],
  partnerCompanies: [],
  taskPrintMode: TASK_PRINT_MODE.ALL,
  taskCategoryIds: [],
  defaultDeadline: false,
  taskDateFrom: undefined,
  taskDateTo: undefined,
  deadlineFrom: undefined,
  deadlineTo: undefined,
};

export const DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK = 3;
export const DEFAULT_ROW_NUMBER_EXPORT_SHEET_TASK = 20;
export const DEFAULT_SCALE_EXPORT_SHEET_TASK_PAGE_SECOND = 1.655;
export const DEFAULT_HEIGHT_EXPORT_SHEET_TASK_PAGE_SECOND = 700;
export const DEFAULT_WIDTH_FORGE_IMAGE = 640;
export const DEFAULT_HEIGHT_FORGE_IMAGE = 400;
export const DEFAULT_MARGIN_FIT_BOUNDS = 0.01;
export const LIMIT_COMMENT_PER_LOAD = 30;

export const OPERATION = {
  CreateTask: "create_task",
  ExportTask: "export_task",
  ShowModalDocumentCategory: "show_modal_document_category",
  ShowModalTask: "show_modal_task",
  CaptureKeyplan: "capture_keyplan",
  CaptureKeynote: "capture_keynote",
  backToForgeViewer: "back_to_forge_viewer",
  navigateDocumentItemModal: "navigate-document-item-modal",
};

export const DEFAULT_SPACING_OF_TOOLTIP = 5;

export const DEFAULT_TASK_MODAL_INFO = {
  status: InspectionItemType.Defect,
  priority: Priority.High,
  tags: [],
  contentType: "",
  deadline: new Date(),
};

export enum TaskLogTypeKey {
  CREATE_TASK = 0,
  ADD_COMMENT = 1,
  ADD_COMMENT_IMAGE = 11,
  ADD_COMMENT_AUDIO = 13,
  ADD_COMMENT_FILE = 14,
  STATUS = 2,
  ASSIGN = 3,
  TAGS = 4,
  TARGET_DATE = 5,
  DESCRIPTION = 6,
  IMAGE = 7,
  EDIT_IMAGE = 16,
  PRIORITY = 8,
  CONTENT_TYPE = 9,
  ATTACH = 10,
  USER_CREATED = 12,
  DELETE_IMAGE = 20,
  DELETE_ATTACH = 21,
  CONFIRM_DATE = 22,
  DESCRIPTION_CRITICISM = 23,
  PRINT_TARGET = 25,
  UNPRINT_TARGET = 26,
  POINTED_OUT_PHOTO = 27,
  EDIT_POINTED_OUT_PHOTO = 15,
  DELETE_POINTED_OUT_PHOTO = 28,
  PARTNERS = 29,
  COMPLETE_DATE = 30,
  MOVE_TASK = 31,
  REVERT_DATA = 32,
  TASK_DATE = 35,
}

export const SetTaskLogTypeComment: Set<TaskLogTypeKey> = new Set([
  TaskLogTypeKey.ADD_COMMENT,
  TaskLogTypeKey.ADD_COMMENT_IMAGE,
  TaskLogTypeKey.ADD_COMMENT_AUDIO,
  TaskLogTypeKey.ADD_COMMENT_FILE,
]);

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconInspectionDefect(props: IconProps) {
  return (
    <Icon width="2.4rem" height="2.4rem" viewBox="0 0 24 24" {...props}>
      <rect
        width="24"
        height="24"
        fill="#dc2626"
        data-name="Rectangle 8433"
        rx="2"
      />
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#fff"
        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12Z"
        data-name="↳Color"
      />
    </Icon>
  );
}

import {
  Box,
  Flex,
  IconButton,
  ListItem,
  Text,
  UnorderedList,
  useBoolean,
} from "@chakra-ui/react";
import { message } from "components/base";
import ConfirmModal from "components/modal/ConfirmDeleteModal";
import { SvgIcon } from "components/SvgIcon";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSyncDataOption } from "redux/appSlice";
import { RootState } from "redux/store";
import { clearAllIndexedDbAndCaches } from "utils/cache";
import { sleep } from "utils/common";
import IconDataBase from "./IconDatabase";

const ClearDatabaseCachedIcon = () => {
  const [isClearing, setIsClearing] = useBoolean();
  const [isOpenConfirmClearCache, setIsOpenConfirmClearCache] = useBoolean();

  const { isOnline } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();

  const { cachingBimFileId } = useSelector((state: RootState) => state.project);

  useEffect(() => {
    if (!isOnline && isOpenConfirmClearCache) {
      setIsOpenConfirmClearCache.off();
    }
  }, [isOnline]);

  const isNotAllowClearCache = useMemo(() => {
    return !isOnline || isClearing || !!cachingBimFileId;
  }, [cachingBimFileId, isClearing, isOnline]);

  const handleClearCache = useCallback(() => {
    (async () => {
      try {
        setIsClearing.on();
        // clear local storage, all data indexedDb, caches
        await clearAllIndexedDbAndCaches();
        dispatch(
          setSyncDataOption({
            mapSyncDataTimeByProject: {},
            mapModelCached: {},
          })
        );
        await sleep(2000);
        const registrations =
          (await navigator?.serviceWorker?.getRegistrations()) || [];
        registrations.forEach(async (registration) => {
          // send a message command to the service-worker to self-destroy, and the service-worker in turn reloads all controlled clients.
          registration?.waiting?.postMessage("unregister");
          registration?.installing?.postMessage("unregister");
          registration?.active?.postMessage("unregister");
          await sleep(2000);
          registration?.unregister();
        });

        window.location.reload();
        setIsClearing.off();
        setIsOpenConfirmClearCache.off();
      } catch {
        message.error("Clear cache failed.");

        setIsClearing.off();
        setIsOpenConfirmClearCache.off();
      }
    })();
  }, [setIsClearing, setIsOpenConfirmClearCache, dispatch]);

  return (
    <>
      <IconButton
        aria-label="clear-cache"
        width="4rem"
        height="4rem"
        variant="outline"
        isDisabled={isNotAllowClearCache}
        onClick={
          isNotAllowClearCache
            ? setIsOpenConfirmClearCache.off
            : setIsOpenConfirmClearCache.on
        }
      >
        <IconDataBase
          width="2rem"
          height="2rem"
          style={{
            stroke: isOnline ? "rgba(0,0,0,1)" : "rgba(0,0,0,0.5)",
          }}
        />
      </IconButton>

      {isOpenConfirmClearCache && (
        <ConfirmModal
          title=""
          size="none"
          isShowCloseIcon={false}
          modalContentProps={{ maxWidth: "65rem" }}
          content={
            <Flex gap="0.8rem">
              <SvgIcon
                src="/img/database-clear.svg"
                width="2.4rem"
                height="2.4rem"
              />
              <Box color="font.gray" fontSize="1.2rem">
                <Text
                  fontSize="1.8rem"
                  lineHeight="2.4rem"
                  mb="0.6rem"
                  fontWeight={700}
                >
                  ブラウザのキャッシュをクリアする
                </Text>
                <Text mb="1.2rem" lineHeight="1.8rem" fontWeight={700}>
                  （システムの調子がおかしいな、と思ったら、試してみてください）
                </Text>

                <Text lineHeight="2.16rem" mb="0.8rem">
                  下記が全て一度削除されることにより、動作が軽くなったり、不具合が解消される可能性があります。
                </Text>

                <UnorderedList
                  lineHeight="2.16rem"
                  sx={{
                    "li::marker": {
                      color: "#737373",
                    },
                  }}
                >
                  <ListItem>
                    オフラインモードを利用するために、iPadにダウンロードしたプロジェクトデータ
                  </ListItem>
                  <ListItem>
                    <Text> オフラインで作業してiPad内に保持しているデータ</Text>
                    {`(`}
                    <Text display="inline" color="font.danger">
                      消したくないオフライン作業済みのデータがある場合、アップロードしてから、実行してください。
                    </Text>
                    {`)`}
                  </ListItem>
                  <ListItem>
                    2回目以降の読込み速度を早くするために、ブラウザ内に保存しておいたjsなどの情報
                  </ListItem>
                </UnorderedList>
              </Box>
            </Flex>
          }
          isLoading={isClearing}
          buttonCancel="キャンセル"
          buttonConfirm="キャッシュをクリア"
          isOpen={isOpenConfirmClearCache}
          onClose={setIsOpenConfirmClearCache.off}
          onProcessing={handleClearCache}
        />
      )}
    </>
  );
};

export default memo(ClearDatabaseCachedIcon);

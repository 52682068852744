import { Box, BoxProps, Text } from "@chakra-ui/react";
import { memo, useCallback, useMemo, useState } from "react";

import MenuDropdown, { type MenuDropdownItem } from "components/MenuDropdown";
import ConfirmDeleteItemModal from "components/modal/DocumentItemModal/ConfirmDeleteItemModal";
import { useAuthorization } from "hooks/usePermission";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import IconEye from "components/icon/IconEye";
import IconEyeOff from "components/icon/IconEyeOff";
import IconTrash from "components/icon/IconTrash";

type Props = BoxProps & {
  documentItem?: DocumentItemDTO;
  contentModalDeleted?: React.ReactNode | React.ReactElement;
  isVisibled?: boolean;
  isDisableDelete: boolean;
  onToggleVisibleDocumentItem: (
    event: any,
    documentItem: DocumentItemDTO
  ) => Promise<void>;
  onDeletedDocumentItem: (
    documentItem: DocumentItemDTO,
    onOk?: (documentCategory?: DocumentCategoryDTO) => void
  ) => Promise<void>;
};

const DocumentItemMenu = ({
  documentItem,
  contentModalDeleted,
  isVisibled,
  isDisableDelete,
  onToggleVisibleDocumentItem,
  onDeletedDocumentItem,
  ...props
}: Props) => {
  const [isOpenDeleted, setIsOpenDeleted] = useState<boolean>(false);
  const roles = useAuthorization();
  const onCloseModalDeleted = useCallback(() => {
    setIsOpenDeleted(false);
  }, [setIsOpenDeleted]);
  const [isDeleting, setIsDeleting] = useState(false);

  const onProcessingDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      await onDeletedDocumentItem(documentItem!, onCloseModalDeleted);
    } catch (error) {
      /** Ignore */
    }
    setIsDeleting(false);
  }, [documentItem, onCloseModalDeleted, onDeletedDocumentItem]);

  const handleToggleVisible = useCallback(
    (event: any) => {
      if (!roles.canEditDocumentItem) return;
      onToggleVisibleDocumentItem(event, documentItem!);
    },
    [onToggleVisibleDocumentItem, documentItem, roles]
  );
  const options = useMemo((): MenuDropdownItem[] => {
    const options: MenuDropdownItem[] = [];

    if (roles.canEditDocumentItem) {
      options.push({
        title: (
          <Text
            color="#737373"
            lineHeight="1.2rem"
            fontSize="1.2rem"
            fontWeight="400"
          >
            欠番 ON/OFF
          </Text>
        ),
        icon: (
          <IconEye
            w="2.4rem"
            h="2.4rem"
            sx={{
              path: {
                fill: "icon.default",
              },
            }}
          />
        ),
        onClick: handleToggleVisible,
      });
    }

    if (roles.canDeleteDocumentItem) {
      options.push({
        title: (
          <Text color="#EF4444" fontSize="1.2rem" fontWeight="400">
            削除
          </Text>
        ),
        icon: (
          <IconTrash
            sx={{
              path: {
                fill: "#EF4444",
              },
            }}
          />
        ),
        isDisabled: isDisableDelete,
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          setIsOpenDeleted(true);
        },
      });
    }

    return options;
  }, [roles, isDisableDelete, handleToggleVisible]);

  return (
    <Box {...props}>
      {isVisibled ? (
        <MenuDropdown options={options} />
      ) : roles.canEditDocumentItem ? (
        <IconEyeOff
          opacity="0.6"
          mr="0.8rem"
          width="2.4rem"
          height="2.4rem"
          cursor={"pointer"}
          sx={{
            path: {
              fill: "icon.default",
            },
          }}
          onClick={handleToggleVisible}
        />
      ) : null}
      {isOpenDeleted && (
        <ConfirmDeleteItemModal
          size="6xl"
          title="以下のピンを削除します。よろしいですか？"
          isOpen={isOpenDeleted}
          content={contentModalDeleted}
          onClose={onCloseModalDeleted}
          isLoading={isDeleting}
          onProcessing={onProcessingDelete}
          buttonDelete="削除する"
        />
      )}
    </Box>
  );
};

export default memo(DocumentItemMenu);

import {
  Box,
  Checkbox,
  Flex,
  FlexProps,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Dropdown from "components/Dropdown";
import SelectUserSingle from "components/input/SelectUserSingle";
import { getValueFromFamilyInstace } from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/utils";
import { PCTooltip } from "components/PCTooltip";
import DayPicker from "components/ui/DayPicker";
import { DEFAULT_OPTION_WEATHER, EMPTY_OPTION } from "constants/document";
import { SelectUserSingleSx } from "constants/dropdown";
import {
  CellProperty,
  DocumentItemKey,
  FamilyInstanceKey,
  LinkedDynamicFieldsType,
  TypeFieldDynamic,
} from "constants/enum";
import dayjs from "dayjs";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import {
  CellType,
  DynamicFieldMeasure,
  DynamicFieldsSection,
} from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import cloneDeep from "lodash/cloneDeep";
import { GetContentLog } from "models/dataLog";
import {
  forwardRef,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import GroupDynamicFields from "./GroupDynamicFields";
import useDynamicFields from "./hook";
import useFamilyInstance from "hooks/useFamilyInstance";

import InputNumber from "./InputNumber";
import useUserOfProject from "hooks/useUserOfProject";
import { SvgIcon } from "components/SvgIcon";
import { IconBase } from "components/base";

export interface iUseDynamicFields extends FlexProps {
  type?: TypeFieldDynamic;
  inputHeight?: string;
  placeholderColor?: string;
  template: DocumentTemplate | undefined;
  documentCategorySelected?: DocumentCategoryDTO | undefined;
  documentItemData?: DocumentItemDTO;
  documentItemSelected?: DocumentItemDTO | undefined;
  insertDocumentItemLog?: (params: GetContentLog) => Promise<void>;
  onSaveDataForDocumentItem?:
    | ((newDocumentModalInfo: DocumentItemDTO) => Promise<void>)
    | ((subItem: DocumentSubItemDTO) => Promise<void>);
  onSaveDataForDocumentCategory?: (
    newDocumentModalInfo: DocumentCategoryDTO
  ) => Promise<void>;
  isDisabled?: boolean;
}

interface iProps extends Omit<iUseDynamicFields, "documentItemData"> {}

export type iDynamicFilesHandle = {
  onSaveData: () => Promise<{ [key: string]: string }>;
  getDynamicFieldData: () => { [key: string]: string };
  getDocumentItemData: () => DocumentItemDTO;
};

const DynamicFields = forwardRef<iDynamicFilesHandle, iProps>(
  (
    {
      type,
      inputHeight = "4rem",
      placeholderColor,
      template,
      documentCategorySelected,
      documentItemSelected,
      isDisabled,
      insertDocumentItemLog,
      onSaveDataForDocumentItem,
      onSaveDataForDocumentCategory,
      ...rest
    },
    ref
  ) => {
    const [fieldValues, setFieldValues] = useState<{ [key: string]: any }>({});
    const [documentItemData, setDocumentItemData] = useState<DocumentItemDTO>(
      {} as DocumentItemDTO
    );
    const { listUserById, isFetchingUserAssigned } = useUserOfProject();
    const { familyInstances } = useFamilyInstance();

    const getLabel = useCallback(
      (field: CellType, sections?: DynamicFieldsSection[]) => {
        if (field?.cellProperty === CellProperty.FAMILY_INSTANCE) {
          return field?.value || "";
        }

        if (sections?.length) {
          const label =
            sections.find(
              (s) =>
                s.value ===
                field?.cellLinkedData?.options?.dynamicFieldSection?.id
            )?.name || "";

          return label;
        }

        return field?.cellLinkedData?.options?.dynamicFieldLabel || "";
      },
      []
    );

    const {
      groupDynamicFields,
      dynamicFieldValues,
      handleChangeDynamicFields,
    } = useDynamicFields({
      type,
      template,
      documentCategorySelected,
      documentItemSelected,
      documentItemData,
      insertDocumentItemLog,
      onSaveDataForDocumentItem,
      onSaveDataForDocumentCategory,
    });

    useImperativeHandle(ref, () => ({
      getDocumentItemData: () => documentItemData,
      getDynamicFieldData: () => ({ ...dynamicFieldValues, ...fieldValues }),
      onSaveData: async () => {
        return fieldValues;
      },
    }));

    useEffect(() => {
      setFieldValues(dynamicFieldValues);
    }, [dynamicFieldValues]);

    const onChangeInput = (key: string, value?: string, name?: string) => {
      if (isDisabled) return;

      let newValue = value || "";

      if (
        name === LinkedDynamicFieldsType.DATE_TIME &&
        fieldValues[key] &&
        dayjs(value).isSame(fieldValues[key])
      ) {
        newValue = "";
      }

      setFieldValues((prev) => ({
        ...prev,
        [key]: newValue,
      }));

      if (type === TypeFieldDynamic.TYPE_ITEM) {
        const data = cloneDeep(documentItemSelected?.data) || {};
        data[key] = value;

        const newDocumentItem = {
          ...documentItemSelected,
          data: data,
          [DocumentItemKey.UPDATED_AT]: new Date(),
        } as DocumentItemDTO;
        setDocumentItemData(newDocumentItem);
      }
    };

    const renderByType = (
      field: CellType,
      sections?: DynamicFieldsSection[],
      groupLabel?: string
    ) => {
      const id =
        type === TypeFieldDynamic.TYPE_CATEGORY
          ? documentCategorySelected?.id
          : type === TypeFieldDynamic.TYPE_ITEM
          ? documentItemSelected?.id
          : "";
      const key = `${field.cellId}-${id}`;

      if (!type || !id) {
        return <></>;
      }

      const valueField = fieldValues?.[key];
      if (field.cellProperty === CellProperty.FAMILY_INSTANCE) {
        const familyInstance =
          familyInstances?.[documentItemSelected?.externalId!] || "";

        const familyInstanceValue = getValueFromFamilyInstace(
          field.cellLinkedData?.field as FamilyInstanceKey,
          familyInstance
        );

        return (
          <Flex height={inputHeight} alignItems="center">
            <Text textAlign="left">{familyInstanceValue}</Text>
          </Flex>
        );
      }

      const dynamicFieldType = field?.cellLinkedData?.options?.dynamicFieldType;
      switch (dynamicFieldType) {
        case LinkedDynamicFieldsType.MEASURE_AVERAGE_VALUE:
          return <></>;

        case LinkedDynamicFieldsType.MEASURE:
          let dynamicFieldMeasure: DynamicFieldMeasure | undefined =
            valueField || {};

          if (!dynamicFieldMeasure?.values?.length) {
            dynamicFieldMeasure =
              field?.cellLinkedData?.options?.dynamicFieldMeasure;
          }

          dynamicFieldMeasure = {
            ...dynamicFieldMeasure,
            unit: field?.cellLinkedData?.options?.dynamicFieldMeasure?.unit,
          };

          const handleCustomInputMeasure = (
            value: string,
            valueIndex: number
          ) => {
            const newDynamicFieldMeasure = {
              ...dynamicFieldMeasure,
            };
            const values = [...(newDynamicFieldMeasure.values || [])];
            values![valueIndex] = value;
            newDynamicFieldMeasure.values = values;
            const sum = values.reduce((cur, acc) => {
              if (acc === "-") {
                return Number(cur || 0);
              }

              return Number(cur || 0) + Number(acc || 0);
            }, 0);
            const totalValue = sum / 3;
            newDynamicFieldMeasure.totalValue =
              !sum && !values.some((value) => value.length && value !== "-")
                ? ""
                : Number.isInteger(totalValue)
                ? String(totalValue)
                : String(totalValue.toFixed(1));

            return newDynamicFieldMeasure;
          };

          const totalValue = dynamicFieldMeasure?.totalValue
            ? `${dynamicFieldMeasure?.totalValue}${dynamicFieldMeasure?.unit}`
            : `-${dynamicFieldMeasure?.unit}`;

          return (
            <Flex w="100%" gap="5px" alignItems="center">
              {field?.cellLinkedData?.options?.dynamicFieldMeasure?.values?.map(
                (_, valIndex) => (
                  <InputNumber
                    key={valIndex}
                    keyOfField={key}
                    index={valIndex}
                    value={dynamicFieldMeasure?.values?.[valIndex] || ""}
                    field={field}
                    groupLabel={groupLabel}
                    fieldValues={fieldValues}
                    onChangeInput={onChangeInput}
                    label={getLabel(field, sections)}
                    onChangeDynamicField={(params) =>
                      handleChangeDynamicFields({ ...params, dynamicFieldType })
                    }
                    onCustomInputMeasure={handleCustomInputMeasure}
                    flex={1}
                    flexShrink="0"
                    height={inputHeight}
                    fontSize="1rem"
                    px=".5rem"
                    isDisabled={isDisabled}
                  />
                )
              )}

              <PCTooltip label={totalValue}>
                <Text
                  flex={1}
                  textAlign="left"
                  className="text-ellipsis"
                  cursor="pointer"
                >
                  {totalValue}
                </Text>
              </PCTooltip>
            </Flex>
          );

        case LinkedDynamicFieldsType.MEASURE_VALUE:
        case LinkedDynamicFieldsType.TEXT:
          return (
            <Input
              placeholder={getLabel(field, sections)}
              value={valueField || ""}
              height={inputHeight}
              fontSize="1.4rem"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              onChange={(e) => {
                onChangeInput(key, e.target.value);
              }}
              onBlur={(e) => {
                let val = e.target.value;
                const isNotNumber = isNaN(Number(val as any));
                if (val === dynamicFieldValues?.[key]) {
                  return;
                }

                if (
                  dynamicFieldType === LinkedDynamicFieldsType.MEASURE_VALUE &&
                  isNotNumber
                ) {
                  val = "0";
                  onChangeInput(key, val);
                }

                handleChangeDynamicFields({
                  data: { ...fieldValues, [key]: e.target.value },
                  name: getLabel(field, sections),
                  groupName: groupLabel,
                  dynamicFieldType,
                });
              }}
              isDisabled={isDisabled}
            />
          );

        case LinkedDynamicFieldsType.CHECK:
          const valueOfCheckbox =
            field.cellLinkedData?.options?.valueOfCheckbox;
          const options = valueOfCheckbox?.split("・") || [];
          const numberOfCheckbox =
            field?.cellLinkedData?.options?.numberOfCheckbox || 1;
          const isMultipleCheckbox =
            numberOfCheckbox > 1 && options?.length > 1;

          if (!isMultipleCheckbox) {
            return (
              <Checkbox
                isChecked={valueField === "true"}
                onChange={(e) => {
                  onChangeInput(key, String(e.target.checked));
                  handleChangeDynamicFields({
                    data: {
                      ...fieldValues,
                      [key]: String(e.target.checked),
                    },
                    name: getLabel(field, sections),
                    groupName: groupLabel,
                    dynamicFieldType,
                  });
                }}
                isDisabled={isDisabled}
              />
            );
          }

          if (isMultipleCheckbox) {
            let optionCheckbox = [
              {
                name: EMPTY_OPTION,
                value: EMPTY_OPTION,
              },
            ];

            if (options.length) {
              const newOptions = options.map((c) => ({ name: c, value: c }));
              optionCheckbox = [...optionCheckbox, ...newOptions];
            } else {
              optionCheckbox = [
                ...optionCheckbox,
                { name: "良", value: "良" },
                { name: "不良", value: "不良" },
              ];
            }

            return (
              <Flex w="100%">
                <Dropdown
                  options={optionCheckbox}
                  onChange={(e) => {
                    onChangeInput(key, String(e));
                    handleChangeDynamicFields({
                      data: {
                        ...fieldValues,
                        [key]: e as string,
                      },
                      name: getLabel(field, sections),
                      groupName: groupLabel,
                      dynamicFieldType,
                    });
                  }}
                  inputHeight="4rem"
                  value={valueField || EMPTY_OPTION}
                  defaultColor={
                    valueField === options[0]
                      ? "#2084D0"
                      : valueField === options[1]
                      ? "#CE2121"
                      : "#333"
                  }
                  isDisabled={isDisabled}
                />
              </Flex>
            );
          }
          break;

        case LinkedDynamicFieldsType.USER_DROPDOWN:
          return (
            <SelectUserSingle
              users={listUserById || {}}
              value={valueField}
              isLoading={isFetchingUserAssigned}
              onChange={(e) => {
                onChangeInput(key, e?.value || "");
                handleChangeDynamicFields({
                  data: { ...fieldValues, [key]: e?.value || "" },
                  name: getLabel(field, sections),
                  groupName: groupLabel,
                  dynamicFieldType,
                });
              }}
              components={{
                DropdownIndicator: () => (
                  <IconBase
                    icon="/img/icon-react-select-custom-dropdown.svg"
                    mx=".8rem"
                  />
                ),
              }}
              sx={{
                ...SelectUserSingleSx,
                "div[class*='option']": {
                  borderBottom: "1px solid var(--primary-border-color)",
                  p: "0.5rem 1.5rem",
                },
                "div[class*='option']:last-child": {
                  borderBottom: "none",
                },
                ...(inputHeight === "4rem"
                  ? {
                      "div[class*='ValueContainer']": {
                        padding: `0.4rem 0.8rem`,
                      },
                    }
                  : {}),
              }}
              placeholderColor={placeholderColor}
              placeHolder="ユーザを選択します"
              isDisabled={isDisabled}
            />
          );

        case LinkedDynamicFieldsType.DATE_TIME:
          const handleUpdateDate = (date?: Date | string) => {
            let newDate = valueField;
            if (date === "") {
              newDate = "";
            }
            handleChangeDynamicFields({
              data: { ...fieldValues, [key]: newDate },
              name: getLabel(field, sections),
              groupName: groupLabel,
              dynamicFieldType,
            });
          };

          return (
            <DayPicker
              keyDynamic={key}
              value={dayjs(fieldValues[key]).isValid() ? fieldValues[key] : ""}
              inputHeight={inputHeight}
              onSelectedDay={() => {}}
              onSelectedDayDynamic={onChangeInput}
              onClose={handleUpdateDate}
              isDisabled={isDisabled}
            />
          );

        case LinkedDynamicFieldsType.WEATHER:
          return (
            <Flex w="100%">
              <Dropdown
                inputHeight={inputHeight}
                options={DEFAULT_OPTION_WEATHER}
                onChange={(e) => {
                  onChangeInput(key, String(e));
                  handleChangeDynamicFields({
                    data: {
                      ...fieldValues,
                      [key]: String(e),
                    },
                    name: getLabel(field, sections),
                    groupName: groupLabel,
                    dynamicFieldType,
                  });
                }}
                value={valueField || DEFAULT_OPTION_WEATHER[0].value}
                isDisabled={isDisabled}
              />
            </Flex>
          );

        case LinkedDynamicFieldsType.FIXED_VALUE:
          return (
            <Text
              display="flex"
              alignItems="center"
              height={inputHeight}
              fontSize="1.4rem"
            >
              {field?.cellLinkedData?.options?.dynamicFieldValue || ""}
            </Text>
          );

        case LinkedDynamicFieldsType.TEXT_AREA:
          return (
            <Textarea
              fontSize="1.4rem"
              minH="7rem"
              maxH="9rem"
              borderRadius={4}
              borderColor="border.default"
              w="100%"
              value={valueField || ""}
              onChange={(e) => {
                onChangeInput(key, e.target.value);
              }}
              onBlur={(e) => {
                const val = e.target.value;
                if (val === dynamicFieldValues?.[key]) {
                  return;
                }
                handleChangeDynamicFields({
                  data: { ...fieldValues, [key]: e.target.value },
                  name: getLabel(field, sections),
                  groupName: groupLabel,
                  dynamicFieldType,
                });
              }}
              isDisabled={isDisabled}
            />
          );

        case LinkedDynamicFieldsType.NUMBER:
          return (
            <InputNumber
              keyOfField={key}
              value={valueField || ""}
              field={field}
              height={inputHeight}
              label={getLabel(field, sections)}
              groupLabel={groupLabel}
              fieldValues={fieldValues}
              onChangeInput={onChangeInput}
              onChangeDynamicField={(params) =>
                handleChangeDynamicFields({ ...params, dynamicFieldType })
              }
              placeholder={getLabel(field, sections)}
              isDisabled={isDisabled}
            />
          );

        default:
          return (
            <Input
              placeholder={getLabel(field, sections)}
              value={valueField || ""}
              height="4rem"
              fontSize="1.4rem"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              onChange={(e) => {
                onChangeInput(key, e.target.value);
              }}
              onBlur={(e) => {
                const val = e.target.value;
                if (val === dynamicFieldValues?.[key]) {
                  return;
                }
                handleChangeDynamicFields({
                  data: { ...fieldValues, [key]: e.target.value },
                  name: getLabel(field, sections),
                  groupName: groupLabel,
                  dynamicFieldType,
                });
              }}
              isDisabled={isDisabled}
            />
          );
      }
    };

    const renderField = ({
      field,
      fieldIndex,
      sections,
      groupLabel,
    }: {
      field: CellType;
      fieldIndex: number;
      sections?: DynamicFieldsSection[];
      isSection?: boolean;
      groupLabel?: string;
    }) => {
      const dynamicFieldType = field?.cellLinkedData?.options?.dynamicFieldType;
      if (dynamicFieldType === LinkedDynamicFieldsType.MEASURE_AVERAGE_VALUE) {
        return <Fragment key={fieldIndex}></Fragment>;
      }

      return (
        <Flex key={fieldIndex} w="100%" alignItems="center" color="#171717">
          <Text
            color="color.default"
            textAlign="left"
            fontWeight={400}
            fontSize="1.4rem"
            flexBasis="8rem"
            pr="5px"
          >
            {getLabel(field, sections)}
          </Text>
          <Box w="calc(100% - 8rem)" cursor="initial" flex="1">
            {renderByType(field, sections, groupLabel)}
          </Box>
        </Flex>
      );
    };

    if (!groupDynamicFields.length) {
      return <></>;
    }

    return (
      <Flex
        w="100%"
        flexDirection="column"
        // pb={groupDynamicFields.length ? "1rem" : "0"}
        // borderBottom={groupDynamicFields.length ? "1px solid #A3A3A3" : "none"}
        zIndex="11"
        sx={{
          "& > section:last-of-type": {
            borderBottom: "1px solid #A3A3A3",
          },
        }}
        {...rest}
      >
        {groupDynamicFields?.map((dynamicField: any, index: number) => {
          if (dynamicField?.isSection) {
            return (
              <GroupDynamicFields
                key={index}
                title={dynamicField?.title}
                dynamicFieldMeasure={
                  dynamicField?.fields?.find(
                    (field: any) =>
                      field?.cellLinkedData?.options?.dynamicFieldType ===
                      LinkedDynamicFieldsType.MEASURE
                  )?.cellLinkedData?.options?.dynamicFieldMeasure
                }
              >
                <Flex gap="1rem" direction="column">
                  {dynamicField?.fields?.map((field: any, fieldIndex: number) =>
                    renderField({
                      field,
                      fieldIndex,
                      sections: dynamicField.sections,
                      isSection: true,
                      groupLabel: dynamicField?.title,
                    })
                  )}
                </Flex>
              </GroupDynamicFields>
            );
          }
          const label = getLabel(dynamicField);
          if (!label) {
            return <Fragment key={index}></Fragment>;
          }

          return (
            <Flex key={index} gap="1rem" flexDirection="column" p="1.5rem 0">
              {renderField({ field: dynamicField, fieldIndex: index })}
            </Flex>
          );
        })}
      </Flex>
    );
  }
);

export default memo(DynamicFields);

import { CellProperty, LinkedDataField } from "constants/enum";
import { GetContentTypeDocumentType } from "../type";
import { getContentNodeTypeNormalText, getContentTypeCheckbox } from "../utils";
import BaseStructureTable, {
  BaseStructureTableProps,
} from "./BaseStructureTable";
import ContentTdTypeCommon from "./ContentTdTypeCommon";

class ContentTdTypeEquipmentDataSheet extends BaseStructureTable {
  protected contentTdTypeCommon: ContentTdTypeCommon;

  constructor(params: BaseStructureTableProps) {
    super(params);
    this.contentTdTypeCommon = new ContentTdTypeCommon(params);
  }

  getStructure(params: GetContentTypeDocumentType) {
    if (!this.page || !this.component || !this.documentItem) {
      return null;
    }

    const {
      parentCell,
      cell,
      cellIndex,
      rowIndex,
      subRowIndex = 1,
      subCellIndex = 1,
    } = params;

    // return type common
    const commonData = this.contentTdTypeCommon.getStructure({
      cell,
      currentCell: parentCell ?? cell,
      rowIndex,
      cellIndex,
      subRowIndex,
      subCellIndex,
      documentItem: this.documentItem,
    });
    if (commonData) {
      return commonData;
    }

    switch (cell.cellProperty) {
      case CellProperty.DOCUMENT_DATA: {
        switch (cell?.cellLinkedData?.field) {
          case LinkedDataField.COMMON.CHECK_BOX: {
            return getContentTypeCheckbox({
              cell,
              documentItem: this.documentItem,
              documentType: this.template?.documentType,
              component: this.component,
            });
          }

          default: {
            return getContentNodeTypeNormalText({
              text: cell.value,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }
        }
      }

      default: {
        return getContentNodeTypeNormalText({
          text: cell.value,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }
    }
  }
}

export default ContentTdTypeEquipmentDataSheet;

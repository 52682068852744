export enum ROLE {
  PARTNER_MEMBER = "5", // craftman
  PARTNER_LEADER = "4", // foreman
  TAKASAGO_FIELD_AGENT = "1", // field agent
  TAKASAGO_ONSITE = "2", // onsite
  TAKASAGO_ADMIN = "3", // admin
}

export interface EditTaskModalPermission {
  canEditTaskTypeField?: boolean;
  canEditTagsField?: boolean;
  canEditImagesField?: boolean;
  canViewImageOriginal?: boolean;
  canEditMemoField?: boolean;
  canEditAssignedUserField?: boolean;
  canEditDeadlineField?: boolean;
  canEditTaskDateField?: boolean;
  canEditPartnerCompaniesField?: boolean;
  canEditConfirmedImagesField?: boolean;
  canEditConfirmedMemoField?: boolean;
  canEditEndDateScheduledField?: boolean;
  canEditConfirmedDateTimeField?: boolean;
  canEditConfirmedUserField?: boolean;
  canEditAttachsField?: boolean;
  canEditCommentField?: boolean;
}

export interface RoleSettings {
  canAddProject: boolean;
  canEditProject: boolean;
  canDeleteProject: boolean;
  canViewProject: boolean;
  canEditUserList: boolean;
  canViewUserList: boolean;
  canAccessDocumentTemplate: boolean;
  canAccessBlackboardTemplate: boolean;
  canAccessTaskTypeList: boolean;
  canAccessUserDashboard: boolean;
  canAccessProjectDashboard: boolean;
  canAccessForgeViewer: boolean;
  canAccessTask: boolean;
  canAccessTaskSheetDoc: boolean;
  canAccessDocument: boolean;
  canAccessCheckItem: boolean;
  canViewTaskModal: boolean;
  canEditTaskModal?: Partial<EditTaskModalPermission>;
  canAccessSettingFamily: boolean;
  canEditTaskList: boolean;

  // Folder
  canAddFolder: boolean;
  canEditFolder: boolean;
  canDeleteFolder: boolean;

  // Document category
  canAddDocumentCategory: boolean;
  canEditDocumentCategory: boolean;
  canEditDocumentCategoryTitle: boolean;
  canEditDocumentCategoryInfo: boolean;
  canDeleteDocumentCategory: boolean;
  canBlockDocumentCategory:boolean;

  // Document items (pin)
  canAddDocumentItem: boolean;
  canEditDocumentItem: boolean;
  canDeleteDocumentItem: boolean;

  // Task
  canEditTaskCorrection: boolean;
  canAddTask: boolean;
  canDeleteTask: boolean;
}

export interface EditDocumentCategoryPermission {
  canEditName: boolean;
  canEditOther: boolean;
  canEditArea: boolean;
  canEditFloor: boolean;
  canSave: boolean;
}

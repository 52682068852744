import { Box, BoxProps } from "@chakra-ui/react";
import {
  Axis,
  PaperDirectionType,
  PaperType,
  SystemMode,
  SystemModeType,
  TemplateComponentType,
} from "constants/enum";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { NeptuneArea } from "interfaces/models/area";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { TemplateComponent } from "interfaces/models/component";
import {
  DocumentCategory,
  DocumentSubCategory,
} from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentKeyNote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from "react-virtualized";
import "react-virtualized/styles.css";
import {
  setIsGeneratingPdf,
  setMapPresignedUrl,
  setNextPreviewPage,
  setTotalPagePreview,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import { getPreSignUrl, getPreSignUrls } from "utils/file";
import useKeynote from "./hooks/useKeynote";
import PreviewPage from "./PreviewPage";
import {
  MAP_PADDING_PREVIEW_PAGER_TYPE,
  PageInfo,
} from "components/TipTapEditorDoc/type";

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 50,
});

interface PreviewContainerProps extends BoxProps {
  isCreatingKeynote?: boolean;
  template?: DocumentTemplate;
  components?: TemplateComponent[];
  listUserById?: {
    [key: string]: User | null;
  };
  companiesById: Record<string, PartnerCompany>;
  documentItems: DocumentItem[] | BlackBoardInfo[];
  documentItem?: DocumentItem;
  documentCategories: DocumentCategory[];
  currentDocSubCategories?: DocumentSubCategory[];
  documentCategorySelected?: DocumentCategoryDTO;
  currentDocument: DocumentDTO | undefined | null;
  pageZoom?: number;
  documentKeynoteDataProp?: DocumentKeyNote;
  keynoteImageDataProp?: KeynoteImageData;
  neptuneAreas?: NeptuneArea[];
  blackboardTemplateProps?: iBlackboardTemplateProps;
  familyInstances: { [key: string]: FamilyInstanceDTO };
  projectName: string;
  previewScrollRef?: any;
  pages: PageInfo[];
  autoSizerListRef: React.MutableRefObject<List>;
}

const PreviewContainer = (props: PreviewContainerProps) => {
  const {
    projectName,
    isCreatingKeynote,
    components,
    listUserById,
    companiesById,
    documentItems,
    documentItem,
    template,
    currentDocSubCategories,
    documentCategories,
    documentCategorySelected,
    currentDocument,
    neptuneAreas,
    pageZoom,
    documentKeynoteDataProp,
    keynoteImageDataProp,
    style,
    blackboardTemplateProps,
    familyInstances,
    previewScrollRef,
    pages,
    autoSizerListRef,
    ...rest
  } = props;

  const { systemMode } = useSelector((state: RootState) => state.forgeViewer);
  const { mapPresignedUrl, nextPreviewPage } = useSelector(
    (state: RootState) => state.document
  );
  const { documentKeynoteData: documentKeynoteDataContext } = useContext(
    PreviewDocumentCategoryContext
  );
  const [imageLoading, setImageLoading] = useState(true);
  const documentKeynoteData =
    documentKeynoteDataProp || documentKeynoteDataContext;

  const { hasKeyNote, pageKeyNote, keynoteAxis } = useKeynote({
    components,
    template,
    documentKeynoteData,
  });

  const mapKeyNoteIndex = useMemo(() => {
    const map: { [key: string]: number } = {};

    documentItems?.forEach((item: any) => {
      Object.assign(map, {
        [item?.id]: item?.keyNoteIndex,
      });
    });

    return map;
  }, [documentItems]);

  const headerComponent = useMemo(() => {
    return (
      (components || []).filter(
        (e) => e.type === TemplateComponentType.TableHeader
      )?.[0]?.detail?.rows?.[0]?.cells || []
    );
  }, [components]);

  const mapLinkedTableIndex: { [key: string]: number } = useMemo(() => {
    const mapObject: { [k: string]: number } = {};
    components
      ?.filter((component) => !!component.linkedHeaderId)
      ?.forEach((component, index) => {
        if (mapObject[`${component.linkedHeaderId}`] >= 0) return;
        mapObject[`${component.linkedHeaderId}`] = index;
      });

    return mapObject;
  }, [components]);

  const scale = Number(pageZoom) / 100;

  const mapPageInfo = useMemo(() => {
    const mapData: {
      [k: number]: {
        width: number;
        height: number;
        isReverseAxis: boolean;
        stylePageKeynote: any;
        padding: { paddingX: number; paddingY: number };
        pageSize: string;
        pageDirection: string;
        page: any;
      };
    } = {};
    let maxH = 0;

    pages.forEach((page, idx) => {
      const pageHasKeyNote = page.components?.some(
        (component) => component?.detail?.checkedImage
      );

      const pageHasFullSizeComponent = page.components?.some(
        (component) =>
          component.type === TemplateComponentType.Image &&
          component?.detail?.isFullsize
      );

      let width = Number(page?.pageSize?.width) * scale;
      let height = Number(page?.pageSize?.height) * scale;
      let isReverseAxis = false;
      let stylePageKeynote: BoxProps["style"] = {};
      if (hasKeyNote && pageKeyNote?.pageId === page.pageId) {
        if (!pageHasFullSizeComponent && !pageHasKeyNote) {
          stylePageKeynote = { padding: 0 };
        }

        const axisDefault =
          pageKeyNote.pageDirection === PaperDirectionType.VERTICAL
            ? Axis.VERTICAL
            : Axis.HORIZONTAL;

        if (axisDefault !== keynoteAxis) {
          isReverseAxis = true;
        }
      }

      // Rotate page's direction
      if (isReverseAxis && systemMode === SystemMode[SystemModeType.Task]) {
        width = height;
        height = Number(page?.pageSize?.width) * scale;
      }

      const currentPage = template?.pages?.find(
        (pageOfTemplate) => pageOfTemplate?.pageId === page.pageId
      );
      const padding =
        MAP_PADDING_PREVIEW_PAGER_TYPE[currentPage?.pageSize ?? PaperType.A4];

      if (height > maxH) maxH = height;
      if (!mapData[idx]) {
        mapData[idx] = {
          width,
          height,
          isReverseAxis,
          stylePageKeynote,
          padding,
          pageSize: currentPage?.pageSize || PaperType.A4,
          pageDirection:
            currentPage?.pageDirection || PaperDirectionType.VERTICAL,
          page,
        };
      }
    });

    return { mapData, maxH };
  }, [
    hasKeyNote,
    keynoteAxis,
    pageKeyNote,
    pages,
    scale,
    systemMode,
    template?.pages,
  ]);

  const pageRenderer = useCallback(
    (pageIndex: number, pageId: number, documentItem?: DocumentItemDTO) => {
      const { mapData } = mapPageInfo;
      const {
        height,
        isReverseAxis,
        stylePageKeynote,
        width,
        padding: { paddingX, paddingY },
        pageDirection,
        pageSize,
        page,
      } = mapData[pageIndex] || { padding: {} };

      return (
        <PreviewPage
          pageId={pageId}
          companiesById={companiesById}
          documentItems={documentItems}
          familyInstances={familyInstances}
          headerComponent={headerComponent}
          height={height}
          page={page}
          isReverseAxis={isReverseAxis}
          mapKeyNoteIndex={mapKeyNoteIndex}
          mapLinkedTableIndex={mapLinkedTableIndex}
          paddingX={paddingX}
          paddingY={paddingY}
          pageDirection={pageDirection}
          pageIndex={pageIndex}
          pageSize={pageSize}
          projectName={projectName}
          scale={scale}
          stylePageKeynote={stylePageKeynote}
          systemMode={systemMode}
          width={width}
          blackboardTemplateProps={blackboardTemplateProps}
          documentCategorySelected={documentCategorySelected}
          documentItem={documentItem}
          hasKeyNote={hasKeyNote}
          isCreatingKeynote={isCreatingKeynote}
          key={pageIndex}
          keynoteImageDataProp={keynoteImageDataProp}
          listUserById={listUserById}
          neptuneAreas={neptuneAreas}
          template={template}
        />
      );
    },
    [
      blackboardTemplateProps,
      companiesById,
      documentCategorySelected,
      documentItems,
      familyInstances,
      hasKeyNote,
      headerComponent,
      isCreatingKeynote,
      keynoteImageDataProp,
      listUserById,
      mapKeyNoteIndex,
      mapLinkedTableIndex,
      mapPageInfo,
      neptuneAreas,
      projectName,
      scale,
      systemMode,
      template,
    ]
  );

  const rowRendererWithVS = ({ index, style, key, parent }: any) => {
    const pageIndex = index;

    return (
      <CellMeasurer
        key={key}
        parent={parent}
        cache={cache}
        columnIndex={0}
        rowIndex={index}
      >
        <Box style={style} page-review={pageIndex}>
          {pageRenderer(pageIndex, pageIndex, documentItem)}
        </Box>
      </CellMeasurer>
    );
  };

  const dispatch = useDispatch();

  const handleLoadS3Images = useCallback(async () => {
    setImageLoading(true);
    const imageList: string[] = [];
    if (documentCategorySelected) {
      const selectedExternalIds =
        documentCategorySelected.selectedExternalIds || ([] as string[]);
      (documentCategorySelected.documentItems || []).forEach((item) => {
        if (!selectedExternalIds.includes(item.id)) return;
        (item.subItems || []).forEach((sub) => {
          if (sub.images?.src && !mapPresignedUrl[sub.images?.src])
            imageList.push(sub.images?.src);
        });
      });
    }
    if (!imageList.length) {
      setImageLoading(false);

      return;
    }
    const _mapPresignedUrl: { [k: string]: string } = { ...mapPresignedUrl };
    const response = await getPreSignUrls(
      imageList.map((url) => ({ filePath: url, fileName: "" }))
    );
    await Promise.all(
      response?.map(async ({ url, presigned }) => {
        _mapPresignedUrl[url] = presigned;

        // handle fetch error
        return fetch(presigned).catch(() => {
          return;
        });
      })
    );
    dispatch(setMapPresignedUrl(_mapPresignedUrl));
    setImageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, documentCategorySelected]);

  const handleLoadKeynote = useCallback(async () => {
    if (hasKeyNote && documentKeynoteData.keynoteImageData) {
      const {
        keynoteImageData: { guid, mapLabelsByGuid },
      } = documentKeynoteData;
      const keynoteUrl = mapLabelsByGuid[`${guid}`].imageUrl;
      const presignedUrl = await getPreSignUrl(keynoteUrl, "");
      dispatch(
        setMapPresignedUrl({
          ...mapPresignedUrl,
          [`${keynoteUrl}`]: presignedUrl,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasKeyNote, documentKeynoteData, dispatch]);

  useEffect(() => {
    handleLoadS3Images();
  }, [handleLoadS3Images]);

  useEffect(() => {
    handleLoadKeynote();
  }, [handleLoadKeynote]);

  const totalPage = useMemo(() => pages.length, [pages.length]);

  useEffect(() => {
    dispatch(setTotalPagePreview(totalPage));

    return () => {
      dispatch(setNextPreviewPage(0));
      dispatch(setMapPresignedUrl({}));
      dispatch(setIsGeneratingPdf(false));
    };
  }, [dispatch, totalPage]);

  useEffect(() => {
    cache.clearAll();
  }, [scale]);

  if (imageLoading) return null;

  return (
    <Box
      id="wrapper-preview"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: `${scale * 0.5}rem`,
        padding: `${scale * 5}rem 0`,
        ...style,
      }}
      {...rest}
    >
      <WindowScroller
        scrollElement={previewScrollRef?.current}
        onResize={() => {
          cache.clearAll();
        }}
        onScroll={({ scrollTop }) => {
          autoSizerListRef?.current?.scrollToPosition(scrollTop);
        }}
      >
        {({ height, isScrolling, registerChild, onChildScroll }) => {
          if (!height) {
            return <></>;
          }

          return (
            <AutoSizer disableHeight>
              {({ width }) => {
                return (
                  <div ref={registerChild as any}>
                    <List
                      autoHeight
                      ref={autoSizerListRef}
                      width={width}
                      height={height ?? 0}
                      rowCount={totalPage}
                      rowHeight={cache.rowHeight}
                      overscanRowCount={5}
                      scrollToIndex={nextPreviewPage}
                      deferredMeasurementCache={cache}
                      rowRenderer={rowRendererWithVS}
                      onScroll={onChildScroll}
                      isScrolling={isScrolling}
                      id="preview-virtual-scroll"
                    />
                  </div>
                );
              }}
            </AutoSizer>
          );
        }}
      </WindowScroller>
    </Box>
  );
};

export default memo(PreviewContainer);

import { s3Api } from "apiClient/v2";
import { PresignedUrlMultipartUploadData } from "apiClient/v2/s3Api";
import { gzip } from "pako";

const CHUNK_SIZE_DEFAULT = 5 * 1024 * 1024;

export class Uploader {
  private parts: PresignedUrlMultipartUploadData[];
  private file: File | Uint8Array;
  private fileId: string | null;
  private chunkSize: number;
  private threadsQuantity: number;
  private timeout: number;
  private aborted: boolean;
  private fileName: string;
  private filePath: string;
  private uploadedSize: number;
  private progressCache: any;
  private activeConnections: any;
  private uploadedParts: { ETag: string; PartNumber: number }[];
  private fileKey: string | null;
  private numberOfparts: number;
  private onProgressFn: Function;
  private onErrorFn: Function;
  private onCompleteFn: Function;

  constructor(options: {
    file: any;
    fileName: string;
    filePath: string;
    chunkSize: number;
    threadsQuantity: number;
    onCompleteFn?: Function;
  }) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    options.chunkSize = options.chunkSize || 0;
    this.chunkSize = Math.max(
      1024 * 1024 * options.chunkSize,
      CHUNK_SIZE_DEFAULT
    );
    // number of parallel uploads
    options.threadsQuantity = options.threadsQuantity || 0;
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    // adjust the timeout value to activate exponential backoff retry strategy
    this.timeout = 0;
    this.file = options.file;
    this.fileName = options.fileName;
    this.filePath = options.filePath;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.onCompleteFn = options.onCompleteFn || (() => {});

    const file = this.file;
    const fileSize = (file as File)?.size || (file as Uint8Array)?.length || 0;
    const numberOfparts = Math.ceil(fileSize / this.chunkSize);
    this.numberOfparts = numberOfparts;
  }

  start() {
    this.initialize();
  }

  async initialize() {
    try {
      const initialMultipartUpload = await s3Api
        .initialMultipartUpload({
          fileName: this.fileName,
          filePath: this.filePath,
        })
        .then((res) => res?.data);

      this.fileId = initialMultipartUpload.fileId;
      this.fileKey = initialMultipartUpload.fileKey;

      // retrieving the pre-signed URLs
      const parts = await s3Api
        .presignedUrlMultipartUpload({
          fileId: this.fileId,
          fileKey: this.fileKey,
          numberOfparts: this.numberOfparts,
        })
        .then((res) => res?.data);

      this.parts.push(...parts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext(retry = 0) {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete(null);
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;

      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error: any) => {
          if (retry <= 6) {
            retry++;
            const wait = (ms: number) =>
              new Promise((res) => setTimeout(res, ms));
            //exponential backoff retry before giving up
            /* eslint-disable-next-line no-console */
            console.log(
              `Part#${part.PartNumber} failed to upload, backing off ${
                2 ** retry * 100
              } before retrying...`
            );
            wait(2 ** retry * 100).then(() => {
              this.parts.push(part);
              this.sendNext(retry);
            });
          } else {
            /* eslint-disable-next-line no-console */
            console.log(`Part#${part.PartNumber} failed to upload, giving up`);
            this.complete(error);
          }
        });
    }
  }

  async complete(error: any) {
    if (error && !this.aborted) {
      this.onErrorFn(error);

      return;
    }
    if (error) {
      this.onErrorFn(error);

      return;
    }
    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      await s3Api.completeMultipartUpload({
        fileId: this.fileId,
        fileKey: this.fileKey,
        Parts: this.uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber),
      });

      this.onCompleteFn(() => true);
    }
  }

  handleProgress(part: number, event: any) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const file = this.file;
      const fileSize =
        (file as File)?.size || (file as Uint8Array)?.length || 0;
      const sent = Math.min(this.uploadedSize + inProgress, fileSize);
      const total = fileSize;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  upload(
    file: any,
    part: PresignedUrlMultipartUploadData,
    sendChunkStarted: Function
  ) {
    return new Promise((resolve, reject) => {
      const throwXHRError = (
        error: any,
        part: PresignedUrlMultipartUploadData,
        abortFx?: any
      ) => {
        delete this.activeConnections[part.PartNumber - 1];
        reject(error);
        window.removeEventListener("offline", abortFx);
      };
      if (this.fileId && this.fileKey) {
        if (!window.navigator.onLine) reject(new Error("System is offline"));

        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new XMLHttpRequest());
        xhr.timeout = this.timeout;

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        );

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.presignedUrl);
        const abortXHR = () => xhr.abort();
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const etag = xhr.getResponseHeader("Etag");
            if (etag) {
              this.uploadedParts.push({
                ETag: etag.replaceAll('"', ""),
                PartNumber: part.PartNumber,
              });
            }

            resolve(xhr.status);
            delete this.activeConnections[part.PartNumber - 1];
            window.removeEventListener("offline", abortXHR);
          }
        };

        xhr.onerror = (error) => {
          throwXHRError(error, part, abortXHR);
        };
        xhr.ontimeout = (error) => {
          throwXHRError(error, part, abortXHR);
        };
        xhr.onabort = () => {
          throwXHRError(new Error("Upload canceled by user or system"), part);
        };
        window.addEventListener("offline", abortXHR);
        xhr.send(file);
      }
    });
  }

  sendChunk(
    chunk: any,
    part: PresignedUrlMultipartUploadData,
    sendChunkStarted: Function
  ) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));

            return;
          }

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  onProgress(onProgress: (params: { percentage: number }) => void) {
    this.onProgressFn = onProgress;

    return this;
  }

  onComplete(onComplete: () => void) {
    this.onCompleteFn = onComplete;

    return this;
  }

  onError(onError: (err: any) => void) {
    this.onErrorFn = onError;

    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}

export const uploadMultipartToS3 = async ({
  fileData,
  filePath,
  fileName,
  chunkSize = 5, // mb
  threadsQuantity = 10,
}: {
  fileData: Object;
  chunkSize?: number; // mb;
  threadsQuantity?: number;
  fileName: string;
  filePath: string;
}): Promise<boolean> => {
  const file = gzip(JSON.stringify(fileData));

  const uploader = new Uploader({
    file,
    filePath,
    fileName,
    chunkSize,
    threadsQuantity,
  });

  return new Promise((resolve, reject) => {
    uploader
      .onComplete(() => {
        resolve(true);
      })
      .onError((err) => {
        /* eslint-disable-next-line no-console */
        console.log("multipart upload file to S3 error: ", err);

        reject(false);
      });

    uploader.start();
  });
};

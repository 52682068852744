export interface TaskType {
  id: string;
  offlineId?: string;
  title: string;
  goodImageUrl?: string;
  badImageUrl?: string;
  goodDescription?: string;
  badDescription?: string;
  tags?: string[];

  badImageFile?: File;
  goodImageFile?: File;

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: string | null;
}

export type TaskTypesParams = {
  paging?: "cursor" | "offset" | undefined;
  page?: number;
  pageSize?: number;
  title?: string;
  deleted?: boolean;
};

export type TaskTypesResponse = {
  data: TaskType[];
  totalRecord: number;
  totalPage: number;
};

export type CountTaskTypesResponse = {
  totalItem: number;
  totalPage: number;
};

export type TaskTypeTableItem = TaskType & {
  columnIndex: number;
};

export type TaskTypeUpdateParams = TaskTypeTableItem;

export type TableMetaTaskTypeActionsParams = {
  updateRow: {
    taskType: TaskType;
    taskTypeIndex: number;
  };
  copyRow: {
    taskType: TaskType;
    taskTypeIndex: number;
  };
  removeRow: {
    taskType: TaskType;
  };
};

export type TaskTypeActionThunkParams = {
  update: TaskTypeTableItem;
  upsertTaskType: {
    action: "update" | "create";
    taskType: TaskTypeTableItem;
  };
  copy: {
    taskType: TaskTypeTableItem;
  };
};

export type TableMetaTaskTypes = {
  editedRows: Record<string, boolean>;
  setEditedRows: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  updateRow: (params: TableMetaTaskTypeActionsParams["updateRow"]) => void;
  copyRow: (params: TableMetaTaskTypeActionsParams["copyRow"]) => void;
  removeRow: (params: TableMetaTaskTypeActionsParams["removeRow"]) => void;
};

export type ColumnMetaTaskTypes = {
  type: "title" | "bad" | "good" | "tags";
};

export const TASK_TYPE_INITIAL: TaskType = {
  id: "CREATE",
  title: "",
};

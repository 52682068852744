import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconArrowDown({ fill, ...rest }: IconProps) {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" {...rest}>
      <path fill="none" d="M0 24V0h24v24z" />
      <path
        fill={fill as any}
        d="M8.6 7.41 13.174 12 8.6 16.59 10.008 18 16 12l-5.992-6Z"
        data-name="↳Color"
      />
    </Icon>
  );
}

import { HStack, VStack, Box } from "@chakra-ui/react";
import { THEME_COLORS } from "../constants";
import BoxColorItem from "./BoxColorItem";

type ThemColorsProps = {};

const ThemColors: React.FC<ThemColorsProps> = () => {
  return (
    <Box width="100%">
      <HStack>
        {Object.keys(THEME_COLORS).map((theme) => {
          return (
            <VStack key={theme}>
              {(
                THEME_COLORS[theme as keyof typeof THEME_COLORS] as string[]
              ).map((color: string, index: number) => {
                return <BoxColorItem key={`${color}-${index}`} color={color} />;
              })}
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export default ThemColors;

import { Box } from "@chakra-ui/react";
import { Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { NodeType } from "../type";

const Component = () => {
  return (
    <NodeViewWrapper
      style={{
        display: "flex",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        inset: 0,
      }}
    >
      <Box
        height="1px"
        width="100%"
        border="1px dashed rgba(128, 128, 128, 0.7)"
      />
    </NodeViewWrapper>
  );
};

export const LinkedImageLineNode = Node.create({
  name: NodeType.LINKED_IMAGE_LINE,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  parseHTML() {
    return [
      {
        tag: "cross-line",
      },
    ];
  },
  renderHTML() {
    return ["cross-line"];
  },
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

import { useBoolean } from "@chakra-ui/react";
import { dataLogApi } from "apiClient/v2";
import { GetDataLogReq } from "apiClient/v2/dataLogApi";
import { RIGHT_SIDEBAR_MODAL_CONTAINER_ID } from "constants/styleProps";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { DataLog } from "interfaces/models/dataLog";
import throttle from "lodash/throttle";
import { addOrUpdateLog, getMapDynamicFieldTitle } from "models/dataLog";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface Props {
  paramsGetDataLogs: Partial<GetDataLogReq>;
  documentTemplate: DocumentTemplateDTO | undefined;
}

const useDocumentLogs = (props: Props) => {
  const { paramsGetDataLogs, documentTemplate } = props;
  const [dataLogs, setDataLogs] = useState<DataLog[]>([]);
  const [isLoadMoreLogs, setIsLoadMoreLogs] = useBoolean();

  const nextTokenRef = useRef<string | undefined>(undefined);
  const isLoadMoreLogsRef = useRef(isLoadMoreLogs);
  isLoadMoreLogsRef.current = isLoadMoreLogs;

  const mapDynamicFieldTitle = useMemo(
    () => getMapDynamicFieldTitle(documentTemplate?.components || []),
    [documentTemplate?.components]
  );

  const getPinDetailContainerEle = () =>
    document.getElementById(RIGHT_SIDEBAR_MODAL_CONTAINER_ID);

  // handle load more log when scroll to bottom
  useEffect(() => {
    const pinDetailContainerEle = getPinDetailContainerEle();
    if (!pinDetailContainerEle) {
      return;
    }

    const debounceLoadLog = throttle(async () => {
      const nextToken = nextTokenRef.current;
      if (!nextToken) {
        return;
      }

      isLoadMoreLogsRef.current = true;
      setIsLoadMoreLogs.on();
      const documentItemLogsEp = await dataLogApi.handleGetDataLogList({
        ...paramsGetDataLogs,
        cursor: nextToken,
      });
      setDataLogs(addOrUpdateLog(documentItemLogsEp.items));
      nextTokenRef.current = documentItemLogsEp.pagination?.cursor;
      setIsLoadMoreLogs.off();
      isLoadMoreLogsRef.current = false;
    }, 500);

    const onScroll = () => {
      const isLoadMore =
        Math.ceil(
          pinDetailContainerEle.scrollHeight -
            pinDetailContainerEle.scrollTop -
            50
        ) <= pinDetailContainerEle.clientHeight &&
        !isLoadMoreLogsRef.current &&
        !!nextTokenRef.current;
      if (isLoadMore) {
        debounceLoadLog();
      }
    };

    pinDetailContainerEle.addEventListener("scroll", onScroll);

    return () => {
      pinDetailContainerEle.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoadingRef = useRef(false);
  const isInitRef = useRef(false);
  const initDataLogs = useCallback(async () => {
    if (isLoadingRef.current) return;
    try {
      isLoadingRef.current = true;
      let isHasScroll = false;
      nextTokenRef.current = undefined as any;
      do {
        const dataLogsEp = await dataLogApi.handleGetDataLogList({
          ...paramsGetDataLogs,
          cursor: nextTokenRef.current,
        });
        setDataLogs(addOrUpdateLog(dataLogsEp.items));
        nextTokenRef.current = dataLogsEp.pagination?.cursor;
        const pinDetailContainerEle = getPinDetailContainerEle();
        if (pinDetailContainerEle) {
          isHasScroll =
            pinDetailContainerEle.scrollHeight >
            pinDetailContainerEle.clientHeight;
        }
      } while (!isHasScroll && nextTokenRef.current);
    } catch (error) {}
    isLoadingRef.current = false;
    isInitRef.current = true;
  }, [paramsGetDataLogs]);

  return {
    mapDynamicFieldTitle,
    dataLogs,
    setDataLogs,
    isLoadMoreLogs,
    initDataLogs,
    isInit: isInitRef.current,
  };
};

export default useDocumentLogs;

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconStatusTreated(props: IconProps) {
  return (
    <Icon
      width="64"
      height="64"
      strokeWidth="11.9"
      viewBox="0 0 595.02 595.02"
      {...props}
    >
      <path
        data-name="stroke"
        fill="white"
        d="M507.529 87.493c-27.264-27.264-59.022-48.672-94.396-63.635C376.489 8.358 337.588.5 297.511.5c-40.078 0-78.979 7.858-115.624 23.358-35.373 14.961-67.132 36.371-94.395 63.635-27.264 27.263-48.673 59.022-63.635 94.395C8.358 218.532.5 257.434.5 297.511s7.858 78.979 23.358 115.623c14.961 35.373 36.371 67.132 63.635 94.396s59.022 48.672 94.395 63.634c36.645 15.5 75.546 23.358 115.624 23.358s78.979-7.858 115.623-23.358c35.373-14.961 67.133-36.371 94.396-63.634 27.263-27.264 48.673-59.022 63.634-94.396 15.499-36.645 23.358-75.546 23.358-115.623s-7.858-78.979-23.358-115.624c-14.963-35.372-36.373-67.131-63.636-94.394zM297.511 551.682c-140.375 0-254.171-113.797-254.171-254.171 0-140.375 113.796-254.171 254.171-254.171 140.374 0 254.171 113.796 254.171 254.171 0 140.374-113.797 254.171-254.171 254.171z"
      />
      <path d="M297.511 595.021c-40.146 0-79.112-7.872-115.818-23.397-35.433-14.988-67.245-36.434-94.553-63.741-27.31-27.31-48.755-59.122-63.742-94.555C7.872 376.623 0 337.656 0 297.511s7.872-79.112 23.397-115.818c14.987-35.432 36.433-67.245 63.742-94.553 27.308-27.309 59.12-48.755 94.553-63.742C218.399 7.872 257.366 0 297.511 0c40.146 0 79.112 7.872 115.817 23.397 35.435 14.988 67.247 36.434 94.555 63.742 27.31 27.31 48.755 59.123 63.741 94.553 15.525 36.706 23.397 75.673 23.397 115.818s-7.872 79.11-23.397 115.817c-14.985 35.432-36.432 67.244-63.741 94.555-27.31 27.31-59.122 48.755-94.555 63.741-36.705 15.526-75.672 23.398-115.817 23.398zm0-594.021C257.5 1 218.665 8.845 182.082 24.318c-35.314 14.937-67.02 36.311-94.236 63.528-27.218 27.217-48.591 58.923-63.528 94.236C8.845 218.665 1 257.5 1 297.511s7.845 78.847 23.318 115.429c14.936 35.312 36.31 67.019 63.528 94.236s58.922 48.59 94.236 63.526c36.582 15.474 75.417 23.319 115.429 23.319s78.847-7.846 115.429-23.319c35.312-14.936 67.019-36.309 94.236-63.526 27.219-27.22 48.592-58.925 63.526-94.236 15.474-36.584 23.319-75.42 23.319-115.429 0-40.011-7.846-78.847-23.319-115.429-14.935-35.312-36.309-67.017-63.526-94.236-27.217-27.216-58.922-48.59-94.236-63.528C376.357 8.845 337.521 1 297.511 1zm0 551.182c-68.025 0-131.979-26.49-180.08-74.592C69.33 429.489 42.84 365.535 42.84 297.511c0-68.025 26.49-131.979 74.591-180.08S229.486 42.84 297.511 42.84c68.024 0 131.979 26.49 180.079 74.591 48.102 48.101 74.592 112.055 74.592 180.08 0 68.024-26.49 131.979-74.592 180.079-48.101 48.101-112.055 74.592-180.079 74.592zm0-508.342c-67.758 0-131.46 26.386-179.373 74.298S43.84 229.753 43.84 297.511s26.386 131.46 74.298 179.372 111.615 74.299 179.373 74.299 131.46-26.387 179.372-74.299 74.299-111.614 74.299-179.372-26.387-131.46-74.299-179.373S365.269 43.84 297.511 43.84z" />
    </Icon>
  );
}

import { BoxProps, Text } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { memo, useMemo } from "react";
import MenuDropdown from "components/MenuDropdown";
import { useAuthorization } from "hooks/usePermission";
import IconTrash from "components/icon/IconTrash";

interface Props extends BoxProps {
  onSelectDeleteMenuItem(): void;
  onSelectUpdateMenuItem(): void;
  isDisabled?: boolean;
}

const DocumentGroupMenu = ({
  isDisabled,
  onSelectDeleteMenuItem,
  onSelectUpdateMenuItem,
  ...rest
}: Props) => {
  const { canDeleteFolder } = useAuthorization();
  const options = useMemo(() => {
    const options: any[] = [];

    if (canDeleteFolder) {
      options.push({
        title: (
          <Text color="#EF4444" fontSize="1.2rem" fontWeight="400">
            削除
          </Text>
        ) as any,
        icon: (
          <IconTrash
            sx={{
              path: {
                fill: "#EF4444",
              },
            }}
          />
        ),
        isDisabled,
        onClick: onSelectDeleteMenuItem,
      });
    }

    return options;
  }, [canDeleteFolder, isDisabled, onSelectDeleteMenuItem]);

  return <MenuDropdown {...rest} options={options} />;
};

export default memo(DocumentGroupMenu);

import { DataLog } from "interfaces/models/dataLog";
import { useEffect } from "react";
import { sortArrayByField } from "utils/array";

interface Props {
  fromExternalLogs: DataLog[] | undefined;
  setItemLogs: (value: React.SetStateAction<DataLog[]>) => void;
  clearFromExternalLogs: () => void;
}

const useListenFromExternalLog = (props: Props) => {
  const { fromExternalLogs, setItemLogs, clearFromExternalLogs } = props;

  useEffect(() => {
    if (!fromExternalLogs?.length) {
      return;
    }

    setItemLogs((prev) =>
      sortArrayByField<DataLog>(
        [...fromExternalLogs, ...prev],
        "createdAt",
        false
      )
    );
    clearFromExternalLogs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromExternalLogs]);
};

export default useListenFromExternalLog;

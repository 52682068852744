import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconInspectionTreated(props: IconProps) {
  return (
    <Icon width="2.4rem" height="2.4rem" viewBox="0 0 24 24" {...props}>
      <rect
        width="24"
        height="24"
        fill="#9766a7"
        data-name="Rectangle 8412"
        rx="2"
      />
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#fff"
        d="M12 20a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8m0-14.4a6.4 6.4 0 1 0 6.4 6.4A6.41 6.41 0 0 0 12 5.6"
        data-name="↳Color"
      />
    </Icon>
  );
}

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconEyeOff(props: IconProps) {
  return (
    <Icon
      width="2.5rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path
        fill="#737373"
        d="m16.944 13.302-1.45-1.45q.275-1.326-.688-2.263-.962-.937-2.312-.737l-1.45-1.45a4.2 4.2 0 0 1 1.8-.4q1.875 0 3.187 1.312 1.313 1.313 1.313 3.188 0 .475-.1.925t-.3.875m3.2 3.15-1.45-1.4q.95-.726 1.7-1.613t1.25-1.937a9.77 9.77 0 0 0-3.613-4.013 9.55 9.55 0 0 0-5.187-1.487q-.725 0-1.413.1-.687.099-1.387.3l-1.55-1.55q1.05-.425 2.137-.638a11.5 11.5 0 0 1 2.213-.212q3.424 0 6.262 1.8t4.338 4.85a1.9 1.9 0 0 1 .2.85q0 .224-.038.437-.037.213-.137.413-.55 1.224-1.4 2.262a11 11 0 0 1-1.925 1.838m-7.3 2.55q-3.35 0-6.125-1.813a13 13 0 0 1-4.375-4.762 1.6 1.6 0 0 1-.188-.438 2 2 0 0 1-.062-.487q0-.25.05-.475t.175-.45A13.6 13.6 0 0 1 3.48 8.664q.663-.912 1.513-1.662l-2.075-2.1a.97.97 0 0 1-.263-.713.98.98 0 0 1 .288-.687.95.95 0 0 1 .7-.275q.424 0 .7.275l17 17q.275.274.287.687a.93.93 0 0 1-.287.713.95.95 0 0 1-.7.275.95.95 0 0 1-.7-.275l-3.5-3.45a12 12 0 0 1-3.6.55m-6.45-10.6a10.7 10.7 0 0 0-1.325 1.425 9 9 0 0 0-1.025 1.675 9.77 9.77 0 0 0 3.612 4.012 9.55 9.55 0 0 0 5.188 1.488q.5 0 .975-.063.474-.062.975-.137l-.9-.95q-.276.075-.525.112a3.5 3.5 0 0 1-.525.038q-1.875 0-3.188-1.313-1.312-1.312-1.312-3.187 0-.276.037-.525.038-.25.113-.525z"
      />
    </Icon>
  );
}

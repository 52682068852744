import axios from "axios";
import { iPlanetsAuth, iPlanetsCookies } from "interfaces/models";
import { ApiResponse } from "interfaces/models/api";
import { User } from "interfaces/models/user";
import { axiosECS } from "services/baseAxios";

interface GetTokenRes {
  success: boolean;
  cookies: iPlanetsCookies;
}

const planetAuthPathV1 = `${process.env.REACT_APP_PLANETS_AUTH_HOST_URL}/v1`;

export const getForgeToken = async (): Promise<{
  access_token: string;
  expires_in: number;
  token_type: string;
  expires_at?: number;
}> => {
  return axiosECS.get("/v2/auth/aps-token");
};

export const getToken = async (): Promise<ApiResponse<GetTokenRes>> => {
  const { data } = await axios.get(
    process.env.REACT_APP_API_LOCAL_TOKEN || // use for run local server
      `${process.env.REACT_APP_API_HOST_URL}/v1/auth/token`,
    { withCredentials: true }
  );

  return data;
};

export const getCurrentUser = async (
  shouldCache?: boolean
): Promise<
  ApiResponse<{
    user: User;
    tokens: {
      expIn: string;
      idToken: string;
      refreshToken: string;
    };
  }>
> => {
  return axiosECS.get("/v2/auth/current-user", {
    params: {
      shouldCache,
    },
  });
};

export const logoutFromPlanet = async () => {
  const urlToken = `${planetAuthPathV1}/signOut`;
  await fetch(urlToken, {
    method: "post",
    credentials: "include",
  });

  return;
};

export const refreshTokenFromPlanet = async () => {
  const urlRefreshToken = `${planetAuthPathV1}/refreshToken`;

  const req = await fetch(urlRefreshToken, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const { status } = req;

  if (status !== 200) {
    return undefined;
  }

  const data: iPlanetsAuth = await req.json();

  return data;
};

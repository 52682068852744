import { Box, BoxProps, Center, IconProps, Text } from "@chakra-ui/react";
import { DocumentCategoryStatusType } from "constants/enum";
import { memo } from "react";
import { FONT_SIZE_DISPLAY_ORDER } from "utils/forge";
import IconPinOnMap from "./icon/IconPinOnMap";

interface Props extends BoxProps {
  status?: string;
  iconProps?: IconProps;
  textProps?: BoxProps;
  no: number;
}

const PinOnMap = (props: Props) => {
  const { no, status, iconProps, textProps, ...rest } = props;

  return (
    <Box
      width="32px"
      height="32px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      {...rest}
    >
      <IconPinOnMap width="3.2rem" height="3.2rem" {...iconProps} />
      <Center
        position="absolute"
        zIndex={1}
        inset={0}
        textAlign="center"
        width="100%"
        height="100%"
        fontSize={
          FONT_SIZE_DISPLAY_ORDER[
            no.toString().length as keyof typeof FONT_SIZE_DISPLAY_ORDER
          ]
        }
        fontWeight="400"
        color={
          status === DocumentCategoryStatusType.NotStarted
            ? "font.default"
            : "font.white"
        }
        {...textProps}
      >
        <Text mb="4px">{no}</Text>
      </Center>
    </Box>
  );
};

export default memo(PinOnMap);

import { Box, Flex, Text } from "@chakra-ui/react";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIcon,
  MapInspectionItemType,
} from "constants/enum";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { memo } from "react";

interface iProps {
  task: TaskDTO;
}

const PhotoBooksHeader = ({ task }: iProps) => {
  const InspectionIcon =
    MapInspectionItemIcon[
      (task.status || InspectionItemType.Defect) as InspectionItemType
    ];

  return (
    <Flex className="photo-books-header" alignItems="center" mb="1rem">
      <Box
        minWidth="7rem"
        height="3rem"
        backgroundColor="#F2F2F2"
        borderRadius="4px"
        mr="1rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight="bold"
      >
        #{task.indexId}
      </Box>
      <Box>
        <Box
          bg={
            MapInspectionItemColor[
              (task.status || InspectionItemType.Defect) as InspectionItemType
            ]
          }
          height="3rem"
          color="#fff"
          borderRadius="4px"
          minWidth="9rem"
          minHeight="3rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <InspectionIcon />
          <Text lineHeight="3rem" minHeight="3rem" minWidth="5rem">
            {
              MapInspectionItemType[
                (task.status || InspectionItemType.Defect) as InspectionItemType
              ]
            }
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default memo(PhotoBooksHeader);

import { useRef, useState } from "react";
import { MenuPlacement } from "react-select/dist/declarations/src";

const MAX_MENU_HEIGHT = 200;
enum DIRECTION {
  BOTTOM = "bottom",
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
}
const useDropdownDirection = () => {
  const initFormRef = {
    assignUserEl: null,
    partnerCompanyEl: null,
    endDateScheduledEl: null,
    taskDate: null,
  };

  const formRef = useRef<{
    assignUserEl: HTMLDivElement | null;
    partnerCompanyEl: HTMLDivElement | null;
    endDateScheduledEl: HTMLDivElement | null;
    taskDate: HTMLDivElement | null;
  }>(initFormRef);

  const [partnerCompanyDirection, setPartnerCompanyDirection] =
    useState<MenuPlacement>(DIRECTION.TOP);
  const [assignUserDirection, setAssignUserDirection] = useState<MenuPlacement>(
    DIRECTION.TOP
  );
  const [endDateScheduledDirection, setEndDateScheduledDirection] =
    useState<MenuPlacement>(DIRECTION.TOP);
  const [taskDateDirection, setTaskDateDirection] = useState<MenuPlacement>(
    DIRECTION.TOP
  );
  const scrollTopRef = useRef<number>(0);

  const getDirectionElm = (
    containerScrollTop: number,
    elm: HTMLDivElement | null
  ) => {
    const offsetTopElm = elm?.offsetTop || 0;

    return containerScrollTop + MAX_MENU_HEIGHT > offsetTopElm
      ? DIRECTION.BOTTOM
      : DIRECTION.TOP;
  };

  const calculateScrollTop = (e: React.UIEvent<HTMLElement>) => {
    const containerEl = e.target as HTMLElement;
    const scrollTop = containerEl?.scrollTop;
    if (!formRef.current?.partnerCompanyEl || !containerEl) {
      return;
    }
    setAssignUserDirection(
      getDirectionElm(scrollTop, formRef.current?.assignUserEl)
    );

    scrollTopRef.current = scrollTop;
  };

  const checkDropdownDirection = () => {
    const scrollTop = scrollTopRef.current;
    if (!scrollTop) {
      return;
    }
    // partner company
    setPartnerCompanyDirection(
      getDirectionElm(scrollTop, formRef.current?.partnerCompanyEl)
    );
    // endDateSchedule
    setEndDateScheduledDirection(
      getDirectionElm(scrollTop, formRef.current?.endDateScheduledEl)
    );
    // task date
    setTaskDateDirection(getDirectionElm(scrollTop, formRef.current?.taskDate));
  };

  return {
    partnerCompanyDirection,
    assignUserDirection,
    endDateScheduledDirection,
    formRef,
    taskDateDirection,
    calculateScrollTop,
    checkDropdownDirection,
  };
};

export default useDropdownDirection;

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconLabelFilter(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M11.651.25a.75.75 0 0 1 .75.75v20.847a.75.75 0 0 1-1.5 0V1a.75.75 0 0 1 .75-.75"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M4 4.066a1.25 1.25 0 0 0-1.25 1.25v15.016l1.047-1.277a.75.75 0 0 1 .58-.275h6.525V4.066zm-1.945-.694A2.75 2.75 0 0 1 4 2.566h7.652a.75.75 0 0 1 .75.75V19.53a.75.75 0 0 1-.75.75h-6.92L2.58 22.905a.75.75 0 0 1-1.33-.476V5.316c0-.729.29-1.428.805-1.944"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M10.901 3.316a.75.75 0 0 1 .75-.75h.957v1.5H12.4v.264h-1.5zm3.62-.75h1.912v1.5h-1.912zm3.825 0h.956q.273 0 .537.053l-.293 1.471a1.3 1.3 0 0 0-.244-.024h-.956zm2.484.464a2.8 2.8 0 0 1 .759.759l-1.247.833a1.3 1.3 0 0 0-.345-.345zM22 4.78q.051.264.052.536v1.018h-1.5V5.316q0-.124-.024-.243zM10.9 8.383V6.357h1.5v2.026zm11.151-.013v2.036h-1.5V8.37zm-11.15 4.067V10.41h1.5v2.027zm11.15.004v2.036h-1.5V12.44zm-11.15 4.05v-2.027h1.5v2.026zm11.15.022v1.017q0 .273-.052.537l-1.472-.293q.024-.12.024-.244v-1.017zm-.463 2.545a2.8 2.8 0 0 1-.759.759l-.833-1.247a1.3 1.3 0 0 0 .345-.345zM10.9 19.53v-1.013h1.5v.263h.207v1.5h-.957a.75.75 0 0 1-.75-.75m8.938.698a3 3 0 0 1-.537.052h-.956v-1.5h.956q.125 0 .244-.024zm-3.406.052H14.52v-1.5h1.912z"
        clipRule="evenodd"
      />
    </Icon>
  );
}

import SendDataSocketHelper from "helper/sendDataSocketHelper";
import { WSMessage } from "interfaces/models/websocket";
import { createContext, useContext } from "react";

export const ForgeViewerContext = createContext<{
  webSocketMessages: WSMessage[];
  sendWebSocketMessage(wsMessage: WSMessage): void;
  sendMessageToCommonChannel(wsMessage: WSMessage): void;
  socket: SendDataSocketHelper;
}>({} as any);

export const useForgeViewerContext = () => useContext(ForgeViewerContext);

import { Flex, Image, ImageProps, Spinner } from "@chakra-ui/react";
import { LegacyRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMapPresignedUrl } from "redux/documentSlice";
import { RootState } from "redux/store";
import { getPreSignUrl, imageUrlToBase64 } from "utils/file";
import { checkIsImageExists } from "utils/image";

export const PresignedImageWithRef = (
  props: ImageProps & {
    _ref?: LegacyRef<any>;
    isCheckLoadingPresignedUrl?: boolean;
    boxLoadingWidth?: number | string;
    boxLoadingHeight?: number | string;
    isConvertToBase64?: boolean;
    callBackPreSignError?: () => void;
  }
) => {
  const {
    src,
    _ref,
    isCheckLoadingPresignedUrl = true,
    boxLoadingWidth = 0,
    boxLoadingHeight = 0,
    isConvertToBase64 = false,
    callBackPreSignError,
    ...rest
  } = props;
  const [isLoadingPresignedUrl, setIsLoadingPresignedUrl] = useState(true);
  const [presignedUrl, setPresignedUrl] = useState<string>(src || "");
  const { mapPresignedUrl } = useSelector((state: RootState) => state.document);

  const dispatch = useDispatch();
  const getPresignedUrl = useCallback(async () => {
    try {
      let img = src || "";
      if (!img?.includes(`${process.env.REACT_APP_S3_URL}/`)) {
        setIsLoadingPresignedUrl(false);
        setPresignedUrl(img);

        return;
      }

      setIsLoadingPresignedUrl(true);
      if (
        src &&
        src.includes(`${process.env.REACT_APP_S3_URL}/`) &&
        !src.includes("X-Amz-SignedHeaders=host")
      ) {
        if (mapPresignedUrl[`${src}`]) {
          img = mapPresignedUrl[`${src}`];
        } else {
          img = await getPreSignUrl(src, "");
          dispatch(addMapPresignedUrl({ url: src, presigned: img }));
        }
      }

      if (isConvertToBase64 && !img?.includes(".mp4")) {
        img = (await imageUrlToBase64(img)) as string;
      }

      const isImageLoaded = await checkIsImageExists(img);
      if (!isImageLoaded) {
        callBackPreSignError?.();
      }

      setPresignedUrl(img);
      setIsLoadingPresignedUrl(false);
    } catch (err) {
      setIsLoadingPresignedUrl(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    getPresignedUrl();
  }, [getPresignedUrl]);

  if (isLoadingPresignedUrl && isCheckLoadingPresignedUrl) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        width={boxLoadingWidth}
        height={boxLoadingHeight}
      >
        <Spinner
          display={!!boxLoadingWidth && !!boxLoadingHeight ? "initial" : "none"}
          color="blue.500"
        />
      </Flex>
    );
  }

  return <Image {...rest} src={presignedUrl} ref={_ref} />;
};

import { PROJECT_CACHE_INFO } from "constants/serviceWorker";

import { getIndexedDb } from "../indexedDb";

export const deleteModelCache = async (urlsOfModel: string[]) => {
  const cacheNames = await caches.keys();
  const deleteRequests: Promise<any>[] = [];
  for (let i = 0; i < cacheNames.length; i++) {
    const cache = await caches.open(cacheNames[i]);
    (await cache.keys()).forEach((item) => {
      if (urlsOfModel.includes(item.url)) {
        deleteRequests.push(cache.delete(item));
      }
    });
  }
  await Promise.all(deleteRequests);
};

export const deleteProjectCache = async (projectId: string) => {
  const indexedDb = await getIndexedDb();

  return (await indexedDb?.deleteProjectProgress(projectId)) || 0;
};

export const clearAllIndexedDbAndCaches = async () => {
  const indexDb = await getIndexedDb();
  indexDb?.emptyStore(PROJECT_CACHE_INFO);
  const databases: any[] = await window.indexedDB.databases();
  for (let i = 0; i < databases.length; i++) {
    window.indexedDB.deleteDatabase(databases[i].name);
  }
  const cacheKeys = await caches.keys();
  await Promise.all(
    cacheKeys.map(async (cacheKey) => {
      await caches.delete(cacheKey);
    })
  );
};

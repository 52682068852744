import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconNavigationExpandMore(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        data-name="↳Color"
        fill="#171717"
        fillRule="evenodd"
        d="M6.53 8.863c.26-.26.682-.26.942 0l4.862 4.862 4.862-4.862a.667.667 0 1 1 .943.942l-5.334 5.334a.667.667 0 0 1-.942 0L6.529 9.805a.667.667 0 0 1 0-.942"
        clipRule="evenodd"
      />
    </Icon>
  );
}

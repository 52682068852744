import {
  DEFAULT_PERMISSION,
  PARTNER_ROLES,
  TAKASAGO_AND_PARTNER_LEADER_ROLES,
  TAKASAGO_ROLES,
} from "constants/user";
import { ROLE, RoleSettings } from "interfaces/models/role";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "redux/store";
import { getPermissionByRole } from "utils/user";

export const usePermission = (
  role?: ROLE | string | undefined
): RoleSettings => {
  const [params] = useSearchParams();
  const currentRole = params.get("role") || role;

  return getPermissionByRole(currentRole);
};

export const useRoles = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const userRole = currentUser?.role as (typeof ROLE)[keyof typeof ROLE];

  const isTakasagoGroup = useMemo(
    () => TAKASAGO_ROLES.includes(userRole),
    [userRole]
  );

  const isTakasagoGroupAndPartnerLeader = useMemo(
    () => TAKASAGO_AND_PARTNER_LEADER_ROLES.includes(userRole),
    [userRole]
  );

  const isAdmin = useMemo(() => {
    return userRole === ROLE.TAKASAGO_ADMIN;
  }, [userRole]);

  const isTakasagoAgent = useMemo(() => {
    return userRole === ROLE.TAKASAGO_FIELD_AGENT;
  }, [userRole]);

  const isPartner = useMemo(() => PARTNER_ROLES.includes(userRole), [userRole]);

  const isTakasagoUser = useMemo(() => {
    return !isAdmin && isTakasagoGroup;
  }, [isAdmin, isTakasagoGroup]);

  const isLeader = useMemo(() => userRole === ROLE.PARTNER_LEADER, [userRole]);

  return {
    isTakasagoGroup,
    isAdmin,
    isPartner,
    isTakasagoGroupAndPartnerLeader,
    isTakasagoUser,
    isTakasagoAgent,
    isLeader,
  };
};

export const useAuthorization = (): RoleSettings => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const permissions = usePermission(currentUser?.role);

  return currentUser?.role ? permissions : DEFAULT_PERMISSION;
};

import { ALL_LEVEL_LABEL } from "constants/forge";
import { Level } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setLevels, setLevelSelected } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";

const useChangeLevel = () => {
  const dispatch = useDispatch();

  /**
   *   --- Flow update state for redux  ---
   *  1. update levelSelected
   *  2. update levelData for dataProjectDetail
   */
  const changeLevelSelected = useCallback(
    (params: { level: Level; projectDetail: DataProjectModel }) => {
      const { level, projectDetail } = params;
      dispatch(setLevelSelected(level));
      const levelData = (structuredClone(projectDetail?.levelData) || {}) as {
        [key: string]: Level;
      };
      levelData[level.label || ""] = level;
      const levelsData = Object.values(levelData).filter(
        (level) => level.label !== ALL_LEVEL_LABEL
      );
      dispatch(setLevels(levelsData));
      const newProject = {
        ...projectDetail,
        levelData: levelData,
      };
      dispatch(setDataProjectDetail(newProject as any));

      return { levelData, newProject };
    },
    [dispatch]
  );

  return { changeLevelSelected };
};

export default useChangeLevel;

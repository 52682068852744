import { ChakraProvider } from "@chakra-ui/react";
import LoadingProvider from "contexts/LoadingContext";
import { SocketProvider } from "contexts/WebSocketContext";
import "react-circular-progressbar/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import store, { persistor } from "redux/store";
import App from "./App";
import "./assets/styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export function render(root: ReactDOM.Root, app: React.ReactNode) {
  root.render(
    <Provider store={store}>
      <PersistGate loading={<div />} persistor={persistor}>
        <LoadingProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ChakraProvider resetCSS theme={theme}>
              <SocketProvider>{app}</SocketProvider>
            </ChakraProvider>
          </BrowserRouter>
        </LoadingProvider>
      </PersistGate>
    </Provider>
  );
}

render(root, <App />);

reportWebVitals();

import { Editor, EditorContent } from "@tiptap/react";
import { memo } from "react";

interface Props {
  editor: Editor | null;
}

const TipTapEditorDoc = (props: Props) => {
  const { editor } = props;
  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
};

export default memo(TipTapEditorDoc);

import { Level } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentTask, MainImageData } from "interfaces/models/documentTask";
import { useEffect, useMemo, useState } from "react";
import { getLevelsData, getSheetsData } from "utils/forge/data";

interface iProps {
  dataProjectDetail?: DataProjectModel;
  documentTask: DocumentTask;
}

const useGetSheetOptions = ({ dataProjectDetail, documentTask }: iProps) => {
  const [_2dViewOptions, set2dViewOptions] = useState<
    | {
        title: string;
        options: { name: string; value: string }[];
      }
    | undefined
  >(undefined);

  // TODO: comment in if using 3D View option
  // const [_3dViewOptions, set3dViewOptions] = useState<{
  //   title: string;
  //   options: { name: string; value: string }[];
  // }>({} as any);

  const sheetIdSelected = useMemo(() => {
    const mainImageData: MainImageData = documentTask?.mainImageData || {};

    return mainImageData?.guid;
  }, [documentTask]);

  useEffect(() => {
    const urn = dataProjectDetail?.defaultBimPathId?.split("/").pop();
    if (!dataProjectDetail?.id || !documentTask?.id) {
      return;
    }

    if (!urn) {
      set2dViewOptions({ title: "2D Views", options: [] });

      return;
    }

    (async () => {
      const newProject = { ...dataProjectDetail };

      if (!dataProjectDetail.sheetData) {
        const sheetData = await getSheetsData({
          projectId: dataProjectDetail.projectId,
          versionId: decodeURIComponent(urn),
        });
        newProject.sheetData = sheetData;
      }

      if (!dataProjectDetail.levelData) {
        const levelData = await getLevelsData({
          projectId: dataProjectDetail.projectId,
          versionId: decodeURIComponent(urn),
        });

        const map = levelData.reduce(
          (map: { [key: string]: Level }, level: Level) => {
            map[level.label || ""] = level;

            return map;
          },
          {}
        );
        newProject.levelData = map;
      }

      const levelData = newProject.levelData! as {
        [key: string]: Level;
      };

      const sheetOptions =
        levelData?.[documentTask?.level]?.sheets?.map(({ name, guid }) => ({
          name,
          value: guid,
        })) || [];

      set2dViewOptions({ title: "2D Views", options: sheetOptions });

      // TODO: comment in if using 3D View option
      // set3dViewOptions({
      //   title: "3D Views",
      //   options: [{ name: "3D View", value: DISPLAY_MODE["3D"] }],
      // });
    })();
  }, [dataProjectDetail, documentTask?.level, documentTask?.id]);

  return { sheetOptions: _2dViewOptions, sheetIdSelected };
};

export default useGetSheetOptions;

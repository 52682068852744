import { documentKeyNoteApi } from "apiClient/v2";
import {
  Axis,
  PaperDirectionType,
  TemplateComponentType,
} from "constants/enum";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentKeyNote } from "interfaces/models/documentKeynote";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { useMemo } from "react";

interface iProps {
  components: TemplateComponent[] | undefined;
  template: DocumentTemplate | undefined;
  documentKeynoteData: DocumentKeyNote | undefined;
  setDocumentKeynoteData?: React.Dispatch<
    React.SetStateAction<DocumentKeyNote>
  >;
}

const useKeynote = ({
  components,
  template,
  documentKeynoteData,
  setDocumentKeynoteData,
}: iProps) => {
  const hasKeyNote = useMemo(() => {
    return (components || [])?.some(
      (component) =>
        component?.type === TemplateComponentType.Image &&
        component?.detail?.checkedImage
    );
  }, [components]);

  const pageKeyNote = useMemo(() => {
    if (!hasKeyNote) {
      return null;
    }

    const page =
      components?.find(
        (component) =>
          component?.type === TemplateComponentType.Image &&
          component?.detail?.checkedImage
      )?.page ?? -1;

    if (page === -1) {
      return null;
    }

    return template?.pages?.[page];
  }, [components, hasKeyNote, template]);

  const keynoteAxis = useMemo(() => {
    if (!documentKeynoteData?.axis) {
      return pageKeyNote?.pageDirection === PaperDirectionType.HORIZONTAL
        ? Axis.HORIZONTAL
        : Axis.VERTICAL;
    }

    return documentKeynoteData?.axis;
  }, [documentKeynoteData, pageKeyNote]);

  const onChangeKeynoteAxis = async (axis: string) => {
    const body = {
      ...documentKeynoteData,
      axis,
    };

    if (!documentKeynoteData || keynoteAxis === axis) {
      return;
    }

    documentKeyNoteApi.createKeynote(body);
    setDocumentKeynoteData?.(body);
  };

  return { hasKeyNote, pageKeyNote, keynoteAxis, onChangeKeynoteAxis };
};

export default useKeynote;

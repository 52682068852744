import { DEFAULT_DOCUMENT_FILTER_DATA } from "constants/document";
import { SystemModeType } from "constants/enum";
import {
  CURRENT_LEVEL_KEY,
  ALL_LEVEL_LABEL,
  DISPLAY_MODE,
} from "constants/forge";
import { DEFAULT_TASK_FILTER_DATA } from "constants/task";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleIsCaptureKeynoteByOperation,
  setIsLoadingDocument,
  setDocumentItemSelected,
  setDocumentCategorySelected,
  setDocumentSubCategorySelected,
  setDocumentGroupSelected,
  setDocumentCategories,
  setDocumentGroups,
  setDocumentItems,
} from "redux/documentSlice";
import {
  setIsShowArea,
  setIsFilter,
  setLevelSelected,
  setCreateTask,
  resetState,
  setDisplayMode,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { setIsLoadingTasks, setTaskSelected, setTasks } from "redux/taskSlice";
import { setUserSetting } from "redux/userSlice";
import { clearSelectedLabel } from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";
import { getLocalStorage, setLocalStorage } from "utils/storage";

const useLevel = (projectId?: string) => {
  const dispatch = useDispatch();
  const { levels, systemMode, isFilter, isCreateTask } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { taskSelected, tasks } = useSelector((state: RootState) => state.task);
  const {
    documentItemSelected,
    documentCategorySelected,
    documentGroupSelected,
    documentSubCategorySelected,
  } = useSelector((state: RootState) => state.document);
  const { settings } = useSelector((state: RootState) => state.user);

  const handleChangeFloor = useCallback(
    (guid: string | string[], isCloseAllDocumentDoc = true) => {
      dispatch(toggleIsCaptureKeynoteByOperation(false));
      dispatch(setIsShowArea(true));
      clearSelectedLabel();
      if (isFilter) {
        dispatch(setIsFilter(false));
        const dataFilter = {
          ...settings,
          ...(systemMode === SystemModeType.Task
            ? DEFAULT_TASK_FILTER_DATA
            : DEFAULT_DOCUMENT_FILTER_DATA),
        };
        dispatch(setUserSetting({ settings: dataFilter }));
      }

      const level = levels.find((f) => f.guid === guid);
      logDev({ level: level });
      dispatch(setLevelSelected(level));

      const currentLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};
      currentLevel[String(projectId)] = guid;

      setLocalStorage(CURRENT_LEVEL_KEY, currentLevel);
      dispatch(setDocumentCategories([]));
      dispatch(setDocumentGroups([]));
      dispatch(setDocumentItems([]));
      systemMode === SystemModeType.Task
        ? dispatch(setIsLoadingTasks(true))
        : dispatch(setIsLoadingDocument(true));
      isCreateTask && dispatch(setCreateTask(false));
      !!taskSelected?.id && dispatch(setTaskSelected());
      if (
        isCloseAllDocumentDoc &&
        (documentItemSelected?.id ||
          documentCategorySelected?.id ||
          documentSubCategorySelected?.id ||
          documentGroupSelected?.id)
      ) {
        dispatch(setDocumentItemSelected());
        dispatch(setDocumentCategorySelected());
        dispatch(setDocumentSubCategorySelected());
        dispatch(setDocumentGroupSelected());
      }
      if (isCloseAllDocumentDoc) {
        dispatch(resetState());
      }
      !!tasks.length && dispatch(setTasks([]));
      if (level?.label === ALL_LEVEL_LABEL || !level?.sheets?.length) {
        dispatch(setDisplayMode(DISPLAY_MODE["3D"]));
      } else {
        dispatch(setDisplayMode(DISPLAY_MODE["2D"]));
      }

      return level;
    },
    [
      settings,
      projectId,
      isFilter,
      levels,
      dispatch,
      systemMode,
      isCreateTask,
      tasks.length,
      taskSelected?.id,
      documentGroupSelected?.id,
      documentSubCategorySelected?.id,
      documentCategorySelected?.id,
      documentItemSelected?.id,
    ]
  );

  return {
    handleChangeFloor,
  };
};

export default useLevel;

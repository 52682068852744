import { Box, VStack, Text } from "@chakra-ui/layout";
import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/slider";
import { memo, useCallback, useContext } from "react";
import { convertRGBAToHex, hexToRgba } from "utils/color";
import { COLORS, MARKS } from "../constants";
import { ColorPickerMicrosoftContext } from "../context";

type SliderAlphaProps = {};

const SliderAlpha: React.FC<SliderAlphaProps> = () => {
  const { colorPicker, onHandleChangeComplete } = useContext(
    ColorPickerMicrosoftContext
  );

  const onHandleChange = useCallback(
    (value: number) => {
      const colorRgba = hexToRgba(colorPicker.color, value);
      const colorHex = convertRGBAToHex(colorRgba);

      onHandleChangeComplete({ color: colorHex, opacity: value });
    },
    [colorPicker, onHandleChangeComplete]
  );

  return (
    <VStack width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mb="-0.5rem"
      >
        <Text fontSize="0.8rem">透明度</Text>
        <Text color={COLORS.GRAY._8} fontSize="0.8rem">
          {colorPicker.opacity * 100}%
        </Text>
      </Box>
      <Slider
        aria-label="slider-ex-4"
        min={0}
        max={1}
        step={0.1}
        value={colorPicker.opacity}
        height={2}
        onChange={onHandleChange}
      >
        {MARKS.map((mark) => (
          <SliderMark
            key={mark}
            value={mark}
            mt="-3px"
            ml="-2"
            zIndex={1}
            background={colorPicker.opacity >= mark ? "black" : COLORS.GRAY._5}
            width="6px"
            height="6px"
            borderRadius="50%"
          />
        ))}
        <SliderTrack bg={COLORS.GRAY._5} height="2px">
          <SliderFilledTrack bg="black" />
        </SliderTrack>
        <SliderThumb
          background="black"
          boxSize={5}
          _focus={{
            boxShadow: "unset",
          }}
        />
      </Slider>
    </VStack>
  );
};

export default memo(SliderAlpha);

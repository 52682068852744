import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { message } from "components/base";
import ConfirmModal from "components/modal/ConfirmDeleteModal";
import OfflineModeTutorialModal, {
  Content,
  ContentProps,
} from "components/modal/OfflineModeTutorialModal";
import { SvgIcon } from "components/SvgIcon";
import {
  DATA_STORE,
  FILE_STORE,
  STATIC_FILES_CACHE,
} from "constants/serviceWorker";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsSyncOfflineData, setOnline } from "redux/appSlice";
import { sleep } from "utils/common";
import { formatDate } from "utils/date";
import { getDatabaseSize, getIndexedDb } from "utils/indexedDb";
import IconArrowDown from "./IconArrowDown";
import IconNetworkOffline from "./IconNetworkOffline";
import IconOffline from "./IconOffline";
import IconOnline from "./IconOnline";
import IconSupport from "./IconSupport";

interface Props {
  isOnline: boolean;
  actualNetworkStatus: boolean;
  timeBeginOffline: number;
  cachingBimFileId: string;
  isCacheFinished?: boolean;
  isPageForgeViewer?: boolean;
  isLoadingViewer?: boolean;
}

const CONTENTS: ContentProps[] = [
  {
    buttonText: "終了後",
    title: (
      <>
        <Text>オフラインモード使用後は、</Text>
        <Text>
          出来るだけ速やかに「オンラインモード」に戻して、データをアップロードしてください。
        </Text>
      </>
    ),
    descriptions:
      "情報を上書きしてしまう・されてしまう事故を防止することができます。",
    boxProps: {
      borderTop: "none",
      paddingBottom: "2.6rem",
    },
    boxContentContainer: {
      maxWidth: "100%",
    },
    buttonProps: {
      border: "1px solid #737373",
      borderRadius: 4,
    },
  },
  {
    buttonText: "仕組み",
    title: "優先されるのは「更新日時が新しいデータ」です。",
    descriptions: (
      <>
        <Text>
          検査項目ごとに「いつデータが変更されたか」という更新日時を記録しています。
        </Text>
        <Text display="inline" color="font.default" fontWeight="bold">
          更新日時がより新しいデータで、古いデータを上書きする仕組み
        </Text>
        <Text display="inline">です。</Text>
      </>
    ),
    boxProps: {
      paddingTop: "2.6rem",
    },
    boxContentContainer: {
      maxWidth: "100%",
    },
    buttonProps: {
      border: "1px solid #737373",
      borderRadius: 4,
    },
  },
];

const NetworkStatusIcon = (props: Props) => {
  const {
    cachingBimFileId,
    isOnline,
    actualNetworkStatus,
    timeBeginOffline,
    isCacheFinished,
    isPageForgeViewer,
    isLoadingViewer = false,
  } = props;

  const [isOpenDiscardOfflineData, setIsOpenDiscardOfflineData] = useBoolean();
  const [isOpenConfirmOverrideData, setIsOpenConfirmOverrideData] =
    useBoolean();
  const [isOpenConfirmSwitchOffline, setIsOpenConfirmSwitchOffline] =
    useBoolean();
  const [isOpenOfflineTutorial, setIsOpenOfflineTutorial] = useBoolean();

  useEffect(() => {
    if (
      !isOnline &&
      actualNetworkStatus &&
      isCacheFinished === false &&
      isPageForgeViewer
    ) {
      dispatch(setOnline(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualNetworkStatus]);

  const dispatch = useDispatch();

  const isDisabled = useMemo(() => {
    return !!cachingBimFileId || !actualNetworkStatus || isLoadingViewer;
  }, [actualNetworkStatus, cachingBimFileId, isLoadingViewer]);

  const handleToggleNetworkStatus = useCallback(
    async (isOpenConfirm: boolean) => {
      if (!isDisabled) {
        const newStatus = !isOnline;
        if (!newStatus && isCacheFinished === false && isPageForgeViewer) {
          message.error([
            "このRVTファイルはオフライン操作の準備ができていないため、オフラインモードへの移行に失敗しました。",
          ]);

          return;
        }
        const isHasDataSync = !!(await getDatabaseSize());

        if (newStatus && isOpenConfirm && isHasDataSync) {
          setIsOpenConfirmOverrideData.on();

          return;
        }

        if (!newStatus && isOpenConfirm) {
          setIsOpenConfirmSwitchOffline.on();

          return;
        }

        const indexedDb = await getIndexedDb();
        indexedDb.changeNetworkStatus(newStatus);
        dispatch(setOnline(newStatus));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isDisabled, isOnline, isPageForgeViewer, isCacheFinished]
  );

  const handleDiscardOfflineData = useCallback(async () => {
    const indexedDb = await getIndexedDb();
    const keys = ((await indexedDb.getAll(FILE_STORE)) as iCachedItem[]).map(
      (item) => item.id
    );

    // clear file cache
    const cache = await caches.open(STATIC_FILES_CACHE);
    const cacheKeys = await cache.keys();
    const promiseList: Promise<any>[] = [
      indexedDb.emptyStore(FILE_STORE) as any,
      indexedDb.emptyStore(DATA_STORE) as any,
    ];
    keys.forEach((key) => {
      const request = cacheKeys.find((req) => {
        const reqPathname = decodeURIComponent(
          decodeURIComponent(new URL(req.url).pathname)
        );
        const currentPathname = `/${decodeURIComponent(key)}`;

        return reqPathname === currentPathname;
      });

      if (request) {
        promiseList.push(
          cache.delete(request, {
            ignoreMethod: true,
            ignoreSearch: true,
            ignoreVary: true,
          })
        );
      }
    });

    await Promise.all(promiseList);
    setIsOpenConfirmOverrideData.off();
    setIsOpenDiscardOfflineData.off();
    indexedDb.changeNetworkStatus(true);
    dispatch(setOnline(true));

    // handle case discard offline data
    // need re-call api to discard offline data on UI
    // when change status sync from true => false
    // then page will re-load call api related task, document
    dispatch(setIsSyncOfflineData(true));
    await sleep(500);
    dispatch(setIsSyncOfflineData(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmOverrideData = useCallback(() => {
    handleToggleNetworkStatus(false);
    setIsOpenConfirmOverrideData.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleToggleNetworkStatus]);

  const handleConfirmSwitchOffline = useCallback(async () => {
    handleToggleNetworkStatus(false);
    setIsOpenConfirmSwitchOffline.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleToggleNetworkStatus]);

  const iconOnline = useMemo(() => {
    return (
      <IconOnline
        width="2.4rem"
        height="2.4rem"
        sx={{
          path: {
            fill: isDisabled ? "rgba(0,0,0,0.5)" : "currentColor",
          },
        }}
        opacity={!isDisabled ? 1 : 0.5}
      />
    );
  }, [isDisabled]);

  const iconOffline = useMemo(
    () => (
      <IconOffline
        width="2.4rem"
        height="2.4rem"
        sx={{
          path: {
            fill: isDisabled ? "rgba(5,150,105,0.5)" : "#059669",
          },
        }}
        opacity={!isDisabled ? 1 : 0.5}
      />
    ),
    [isDisabled]
  );

  const imageSyncDataType1 = useMemo(
    () => (
      <Image
        mx="auto"
        width="100%"
        maxWidth="32rem"
        src="/img/sync-data-info-1.svg"
      />
    ),
    []
  );

  const imageSyncDataType2 = useMemo(
    () => (
      <Image
        mx="auto"
        mb="2rem"
        width="100%"
        maxWidth="32rem"
        src="/img/sync-data-info-2.svg"
      />
    ),
    []
  );

  return (
    <>
      <Menu autoSelect={false} closeOnSelect={false}>
        {({ isOpen, onClose }) => (
          <>
            {isCacheFinished === false &&
            !actualNetworkStatus &&
            isPageForgeViewer ? (
              <Button
                variant="text"
                whiteSpace={"normal"}
                sx={{
                  opacity: "1 !important",
                }}
              >
                <IconNetworkOffline />
                <Text
                  fontSize="1.2rem"
                  margin="0 0.5rem"
                  variant="text"
                  color="#EF4444 !important"
                >
                  接続してください
                </Text>
                <IconArrowDown
                  transition="0.4s"
                  width="2.8rem"
                  height="2.8rem"
                  sx={{
                    'path[data-name="↳Color"]': {
                      fill: "#EF4444",
                    },
                  }}
                  transform={isOpen ? "rotate(270deg)" : "rotate(90deg)"}
                />
              </Button>
            ) : (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  aria-label="Options"
                  isDisabled={isDisabled}
                  variant="text"
                  //@ts-ignore
                  leftIcon={isOnline ? iconOnline : iconOffline}
                  border="none"
                  background="white !important"
                  fontSize="1.2rem"
                  whiteSpace={{ base: "normal", xl: "nowrap" }}
                  maxW={{
                    base: isPageForgeViewer ? "12rem" : "max-content",
                    xl: "max-content",
                  }}
                  p={{ base: 0, xl: "var(--chakra-space-4)" }}
                  color={isOnline ? "font.gray" : "#059669"}
                  rightIcon={
                    <IconArrowDown
                      transition="0.4s"
                      width="2.8rem"
                      height="2.8rem"
                      sx={{
                        'path[data-name="↳Color"]': {
                          fill: "#737373",
                        },
                      }}
                      transform={isOpen ? "rotate(270deg)" : "rotate(90deg)"}
                    />
                  }
                >
                  {isOnline ? "オンラインモード" : "オフラインモード"}
                </MenuButton>
                <MenuList
                  maxWidth="17rem"
                  minWidth="17rem"
                  padding="0px"
                  margin="0px"
                  background="#fff"
                  borderRadius="6px"
                  boxShadow="0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026"
                >
                  <MenuItem
                    p="0.4rem 1.2rem"
                    fontSize="1.2rem"
                    lineHeight="2.4rem"
                    color={isOnline ? "#059669" : "font.default"}
                    _hover={{ bgColor: "#F0F9FF !important" }}
                    isDisabled={isDisabled}
                    onClick={() => {
                      handleToggleNetworkStatus(true);
                      onClose();
                    }}
                    icon={isOnline ? iconOffline : iconOnline}
                  >
                    {isOnline ? "オフラインモード" : "オンラインモード"}
                  </MenuItem>
                  <MenuItem
                    p="0.4rem 1.2rem"
                    fontSize="1.2rem"
                    color="font.gray"
                    _hover={{ bgColor: "#F0F9FF !important" }}
                    onClick={setIsOpenOfflineTutorial.on}
                    icon={
                      <IconSupport
                        width="2.4rem"
                        height="2.4rem"
                        color="#171717"
                      />
                    }
                    lineHeight="1.8rem"
                    textAlign="left"
                  >
                    <Text>オフラインモードの</Text>
                    <Text>使い方・注意点</Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </>
        )}
      </Menu>

      {/* modal confirm sync data from offline */}
      {isOpenConfirmOverrideData && !isOpenDiscardOfflineData && (
        <ConfirmModal
          size="none"
          title=""
          modalContentProps={{
            maxWidth: "110rem",
            background: "background.secondary",
            borderRadius: "0px",
          }}
          iconCloseProps={{
            width: "6.4rem",
            height: "6.4rem",
            top: "0px",
            right: "0px",
            borderRadius: "unset",
            fill: "white",
            backgroundColor: "#73737380",
            _hover: {
              backgroundColor: "#73737380",
            },
            _focus: {
              backgroundColor: "#73737380",
            },
          }}
          content={
            <>
              <Box
                textAlign="center"
                color="font.default"
                fontWeight={700}
                fontSize="2.4rem"
                lineHeight="2.8rem"
                m="2.4rem -3.7rem 3.2rem -3.2rem"
                pb="1.6rem"
                borderBottom="1px solid #D4D4D4"
              >
                オフラインモードで作業したデータをアップロードします。よろしいですか？
              </Box>

              <Box pr="6.2rem" pl="6.8rem">
                {CONTENTS.map((content, index) => (
                  <Content
                    descriptionsProps={{
                      fontSize: "1.6rem",
                    }}
                    key={index}
                    {...content}
                  />
                ))}

                <Box
                  mt="1.4rem"
                  textAlign="left"
                  color="font.gray"
                  fontSize="1.9rem"
                  lineHeight="2.5rem"
                  fontWeight={500}
                >
                  <Text display="inline">確認！</Text>
                  <Text
                    display="inline"
                    mx="0.5rem"
                    color="font.default"
                    fontWeight="bold"
                  >
                    {formatDate(timeBeginOffline, "YYYY/MM/DD HH:mm")}
                  </Text>
                  <Text display="inline">
                    にダウンロードしたデータを元に、オフライン作業を実施しています。
                  </Text>
                </Box>
              </Box>
            </>
          }
          isOpen={isOpenConfirmOverrideData}
          footer={
            <Center paddingTop="2.4rem">
              <Stack gap="2.4rem" alignItems="center">
                <Button
                  variant="filled"
                  width="30rem"
                  fontSize="2.4rem"
                  height="6.4rem"
                  letterSpacing="1.25px"
                  leftIcon={
                    <SvgIcon
                      src="/img/upload-icon.svg"
                      width="2.8rem"
                      height="2.8rem"
                      color="white"
                    />
                  }
                  onClick={handleConfirmOverrideData}
                >
                  アップロードする
                </Button>
                <Button
                  variant="dangerText"
                  borderRadius="6px"
                  height="4rem"
                  width="23rem"
                  leftIcon={
                    <SvgIcon
                      src="/img/icon-trash-red.svg"
                      width="2.4rem"
                      height="2.4rem"
                    />
                  }
                  fontSize="1.4rem"
                  letterSpacing="1.25px"
                  onClick={setIsOpenDiscardOfflineData.on}
                >
                  作業データを破棄する
                </Button>
              </Stack>
            </Center>
          }
          onClose={setIsOpenConfirmOverrideData.off}
        />
      )}

      {/* modal confirm switch online to offline */}
      {isOpenConfirmSwitchOffline && !isOpenOfflineTutorial && (
        <ConfirmModal
          size="none"
          title=""
          modalContentProps={{
            maxWidth: "100rem",
            background: "background.secondary",
          }}
          content={
            <Flex flexDirection="column" alignItems="center">
              <Flex mb="0.4rem" justifyContent="center" alignItems="center">
                <SvgIcon
                  src="/img/warning-icon.svg"
                  width="5rem"
                  height="5rem"
                  sx={{
                    'path[data-name="↳Color"]': {
                      fill: "font.danger",
                    },
                  }}
                />
                <Text color="font.danger" fontSize="3.4rem" fontWeight={500}>
                  確認！
                </Text>
              </Flex>

              <Text
                textAlign="center"
                color="font.danger"
                fontSize="2.4rem"
                lineHeight="3.6rem"
                fontWeight={800}
                mb="3.2rem"
              >
                「オフライン作業中」ラベル
                <Text as="span" fontWeight={500}>
                  は表示しましたか？
                </Text>
              </Text>

              <Box textAlign="left">
                <Text
                  mb="0.8rem"
                  color="font.gray"
                  fontSize="1.6rem"
                  lineHeight="2.8rem"
                  fontWeight={500}
                >
                  オフラインモードを開始する前に
                </Text>

                <Text
                  mb="0.8rem"
                  color="font.gray"
                  fontSize="1.6rem"
                  lineHeight="2.8rem"
                  fontWeight={800}
                >
                  １）編集する書類の右にある「…」ボタンから
                </Text>

                {imageSyncDataType1}

                <Text
                  mb="0.8rem"
                  color="font.gray"
                  fontSize="1.6rem"
                  lineHeight="2.8rem"
                  fontWeight={800}
                >
                  ２）「オフライン作業中」ラベルを表示してください。
                </Text>

                {imageSyncDataType2}
              </Box>

              <Box
                mb="2rem"
                textAlign="center"
                color="font.gray"
                fontSize="1.4rem"
                fontWeight={700}
                lineHeight="2.3rem"
              >
                <Text display="inline">
                  ※オフライン作業中に、誰かが同じ書類を編集すると、
                </Text>
                <Text display="inline" color="font.danger">
                  記入したデータが消えてしまう可能性があります。
                </Text>
              </Box>

              <Text
                color="font.gray"
                fontSize="1.4rem"
                fontWeight={500}
                lineHeight="2.3rem"
                textAlign="center"
                mb="2rem"
              >
                オフラインでの作業を終えたら、すみやかに「オンラインモード」に変更し、
                <br />
                オフラインで編集したデータをアップロードしてください。
              </Text>

              <Text
                color="#009BE0"
                fontSize="1.4rem"
                fontWeight={500}
                lineHeight="2.1rem"
                textDecoration="underline"
                cursor="pointer"
                onClick={setIsOpenOfflineTutorial.on}
              >
                オフラインモードについての詳しい説明はこちら
              </Text>
            </Flex>
          }
          buttonCancel="キャンセル"
          buttonConfirm="オフラインモード開始"
          isOpen={isOpenConfirmSwitchOffline}
          onClose={setIsOpenConfirmSwitchOffline.off}
          onProcessing={handleConfirmSwitchOffline}
        />
      )}

      {/* modal confirm discard data changed from offline */}
      {isOpenDiscardOfflineData && (
        <ConfirmModal
          size="none"
          title=""
          modalContentProps={{
            maxWidth: "110rem",
            background: "background.secondary",
          }}
          content={
            <Box m="2.4rem -3.7rem 3.2rem -3.2rem">
              <Text
                pl="10rem"
                color="font.default"
                fontWeight={700}
                fontSize="2.4rem"
                lineHeight="2.8rem"
                pb="0.8rem"
                borderBottom="1px solid #D4D4D4"
              >
                オフラインで作業したデータを、アップロードせずに削除しますか？
              </Text>

              <Box
                pl="10rem"
                my="2.4rem"
                color="font.gray"
                fontSize="1.6rem"
                lineHeight="2.8rem"
                fontWeight={500}
              >
                <Text display="inline">オフラインモードを開始した日時：</Text>
                <Text display="inline" ml="1.5rem">
                  {formatDate(timeBeginOffline, "YYYY/MM/DD HH:mm")}
                </Text>
              </Box>

              <Text
                pl="10rem"
                color="font.danger"
                fontSize="1.6rem"
                lineHeight="2.8rem"
                fontWeight={700}
              >
                注意！ 削除したオフライン作業データは復元できません。
              </Text>
            </Box>
          }
          isOpen={isOpenDiscardOfflineData}
          footer={
            <Flex mt="5.6rem" justifyContent="right" gap="1.6rem">
              <Button onClick={setIsOpenDiscardOfflineData.off}>
                キャンセル
              </Button>
              <Button
                variant="danger"
                leftIcon={
                  <SvgIcon
                    src="/img/icon-trash-red.svg"
                    width="2.4rem"
                    height="2.4rem"
                    pathFill="white"
                  />
                }
                onClick={handleDiscardOfflineData}
              >
                削除する
              </Button>
            </Flex>
          }
          onClose={setIsOpenDiscardOfflineData.off}
        />
      )}

      {isOpenOfflineTutorial && (
        <OfflineModeTutorialModal
          isOpen={isOpenOfflineTutorial}
          onClose={setIsOpenOfflineTutorial.off}
        />
      )}
    </>
  );
};

export default memo(NetworkStatusIcon);

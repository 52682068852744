import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { generatePath } from "react-router-dom";
import store from "redux/store";
import { routePath } from "routes/path";
import { getBimFileInfo } from "utils/bim";

export const checkProjectValid = (project: DataProjectModel) => {
  const defaultBimPathId = project?.defaultBimPathId;
  const levelsOfProject = Object.values(project?.levelData || {}).filter(
    (i) => !!i?.guid
  );

  return defaultBimPathId && !!levelsOfProject?.length;
};

export const getNewURLFromBimFile = (dataProject: DataProjectModel) => {
  const { dataProjectDetail } = store.getState().project;

  /**
   * Reload forgeviewer page if the user change bimfile version
   */
  if (dataProjectDetail?.id === dataProject.id) {
    return generatePath(routePath.ForgeViewer, {
      projectId: dataProject.projectId,
      bimFileId: dataProjectDetail.id,
      version: `${dataProjectDetail.version}`,
    });
  }
};

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { taskApi, taskSheetTemplateApi, taskTypeApi } from "apiClient/v2";
import { REDUCER_KEY } from "constants/redux";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTask } from "interfaces/models/documentTask";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import { TaskType } from "interfaces/models/taskType";
import { orderBy, reverse } from "lodash";
import { getDbIdByExternalId, ___mapExternalId } from "utils/forge/data";

export interface TaskState {
  taskTypes: TaskType[];
  filteredTaskTypes: TaskType[];
  isLoadingTaskType: boolean;
  isFetchedTaskType: boolean;
  taskSheetTemplateList: TaskSheetTemplate[];
  tasks: TaskDTO[];
  documentTasks: DocumentTask[] | null;
  taskSelected: TaskDTO | undefined;
  isCreatingTask: boolean;
  isLoadingTask: boolean;
  isFetchedTasks: boolean;
  isLoadedDocumentTasks: boolean;
  taskIdsCreated: Array<string>;
  isFetchingTaskSheetTemplates: boolean;
  isProcessingTaskModal: boolean;
}

const initialState: TaskState = {
  isFetchingTaskSheetTemplates: false,
  taskTypes: [],
  filteredTaskTypes: [],
  isLoadingTaskType: false,
  taskSheetTemplateList: [],
  tasks: [],
  documentTasks: null as any,
  taskSelected: undefined,
  isProcessingTaskModal: false,
  isCreatingTask: false,
  isLoadingTask: false,
  isLoadedDocumentTasks: false,
  isFetchedTaskType: false,
  isFetchedTasks: false,
  taskIdsCreated: [],
};

export const getAllTaskTypes = async () => {
  return (await taskTypeApi.handleGetTaskTypes({})) || [];
};

export const fetchTaskSheetTemplates = createAsyncThunk(
  "forgeViewer/fetchTaskSheetTemplates",
  async () => {
    const { data } = (await taskSheetTemplateApi.getTemplateList({})) || [];

    return data;
  }
);

export const fetchTaskByBimFile = createAsyncThunk(
  "forgeViewer/fetchTaskByBimFile",
  async ({ bimFileId, level }: { bimFileId?: string; level?: string }) => {
    const res = await taskApi.handleGetTasks({ bimFileId, level });
    const tasks: TaskDTO[] = res.map((task) => {
      const dbId = ___mapExternalId
        ? getDbIdByExternalId(task.externalId)
        : task.dbId;

      return {
        ...task,
        dbId,
      };
    });

    return tasks;
  }
);

export const sortTaskTypes = (taskTypes: TaskType[]) =>
  reverse(
    orderBy(taskTypes, ({ createdAt }) => {
      if (typeof createdAt === "string") {
        return new Date(createdAt);
      }

      return createdAt;
    })
  );

export const fetchTaskTypes = createAsyncThunk(
  "task/fetchTaskType",
  getAllTaskTypes
);

export const taskSlice = createSlice({
  name: REDUCER_KEY.TASK,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTaskByBimFile.pending, (state) => {
      state.isLoadingTask = true;
      state.isFetchedTasks = false;
    });
    builder.addCase(fetchTaskByBimFile.fulfilled, (state, action) => {
      const tasks = action.payload || [];
      state.tasks = tasks;
      const selectedTask = tasks.find(
        (task) => state.taskSelected?.id === task.id
      );
      if (selectedTask) {
        state.taskSelected = selectedTask;
      } else if (state.taskSelected?.id) {
        // close task modal
        state.taskSelected = undefined as any;
      }
      state.isLoadingTask = false;
      state.isFetchedTasks = true;
    });
    builder.addCase(fetchTaskTypes.pending, (state, action) => {
      state.isLoadingTaskType = true;
    });
    builder.addCase(
      fetchTaskTypes.fulfilled,
      (state, action: PayloadAction<TaskType[]>) => {
        state.filteredTaskTypes = sortTaskTypes(action.payload);
        state.taskTypes = action.payload;
        state.isLoadingTaskType = false;
        state.isFetchedTaskType = true;
      }
    );
    builder.addCase(fetchTaskSheetTemplates.rejected, (state, action) => {
      state.isFetchingTaskSheetTemplates = false;
    });
    builder.addCase(fetchTaskSheetTemplates.pending, (state, action) => {
      state.isFetchingTaskSheetTemplates = true;
    });
    builder.addCase(
      fetchTaskSheetTemplates.fulfilled,
      (state, action: PayloadAction<TaskSheetTemplate[]>) => {
        state.isFetchingTaskSheetTemplates = false;
        state.taskSheetTemplateList = action.payload;
      }
    );
    builder.addCase(fetchTaskTypes.rejected, (state, action) => {
      state.isLoadingTaskType = false;
    });
  },
  reducers: {
    addTaskType: (state, action: PayloadAction<TaskType>) => {
      const currentTaskTypes = [...state.taskTypes];
      currentTaskTypes.push(action.payload);
      state.taskTypes = currentTaskTypes;
    },
    setTaskType: (state, action: PayloadAction<TaskType>) => {
      const index = state.taskTypes.findIndex(
        (taskType) => taskType.id === action.payload.id
      );
      if (index === -1) {
        state.taskTypes.push(action.payload);
      } else {
        state.taskTypes[index] = {
          ...state.taskTypes[index],
          ...action.payload,
        };
      }
    },
    deleteTaskType: (state, action: PayloadAction<string>) => {
      state.taskTypes = state.taskTypes.map((item) => {
        if (item.id !== action.payload) return item;

        return { ...item, deletedAt: new Date().toString() };
      });
    },
    setTaskTypes: (state, action: PayloadAction<TaskType[]>) => {
      state.taskTypes = action.payload;
    },
    setFilteredTaskTypes: (state, action: PayloadAction<TaskType[]>) => {
      state.filteredTaskTypes = sortTaskTypes(action.payload);
    },
    setTaskSheetTemplateList: (
      state,
      action: PayloadAction<TaskSheetTemplate[]>
    ) => {
      state.taskSheetTemplateList = action.payload;
    },
    updateTaskSheetTemplate: (
      state,
      action: PayloadAction<TaskSheetTemplate>
    ) => {
      if (action.payload.id) {
        const index = state.taskSheetTemplateList.findIndex(
          (template) => template.id === action.payload.id
        );
        if (index !== -1) {
          state.taskSheetTemplateList[index] = action.payload;

          return;
        }
      }
      state.taskSheetTemplateList.push(action.payload);
    },
    deleteTaskSheetTemplate: (state, action: PayloadAction<string>) => {
      state.taskSheetTemplateList = state.taskSheetTemplateList.filter(
        (template) => template.id !== action.payload
      );
    },
    setIsLoadedDocumentTasks: (state, action: PayloadAction<boolean>) => {
      state.isLoadedDocumentTasks = action.payload;
    },
    setTaskSelected: (state, action: PayloadAction<TaskDTO | undefined>) => {
      state.taskSelected = action.payload || ({} as TaskDTO);
    },
    setTasks: (state, action: PayloadAction<TaskDTO[]>) => {
      state.tasks = action.payload;
      if (!!state.taskSelected?.id) {
        const taskSelected = action.payload.find(
          (task) => task.id === state.taskSelected?.id
        );
        if (taskSelected) {
          state.taskSelected = taskSelected;
        }
      }
    },
    transformDbIdForTasks: (state) => {
      if (!state.tasks.length) {
        return;
      }

      const tasks = state.tasks.map((task) => ({
        ...task,
        dbId: getDbIdByExternalId(task.externalId),
      }));

      const taskSelected = tasks.find(
        (task) => task.id === state.taskSelected?.id
      );
      if (taskSelected) {
        state.taskSelected = taskSelected;
      }

      state.tasks = tasks;
    },
    removeTask: (state, action: PayloadAction<TaskDTO>) => {
      let newTasks = [...state.tasks];
      newTasks = newTasks.filter((task) => task.id !== action.payload.id);
      state.tasks = newTasks;
    },
    updateTaskByDocumentItemId: (
      state,
      action: PayloadAction<{
        documentItemIds: string[];
        data: Partial<TaskDTO>;
      }>
    ) => {
      const documentItemIds = action.payload.documentItemIds;
      const data = action.payload.data;
      state.tasks = state.tasks.map((task) => {
        if (documentItemIds.includes(task?.documentItemId || "")) {
          return { ...task, ...data };
        }

        return task;
      });

      const isExistTaskSelected = !!documentItemIds.find(
        (id) => state.taskSelected?.documentItemId === id
      );
      if (isExistTaskSelected && state.taskSelected?.id) {
        state.taskSelected = { ...state.taskSelected, ...data };
      }
    },
    setTask: (state, action: PayloadAction<TaskDTO>) => {
      const index = state.tasks.findIndex(
        (task) => task.id === action.payload.id
      );
      if (index === -1) {
        state.tasks.push(action.payload);
      } else {
        state.tasks[index] = { ...state.tasks[index], ...action.payload };
      }
      if (action.payload.id === state.taskSelected?.id) {
        state.taskSelected = { ...state.taskSelected, ...action.payload };
      }
    },
    updateTaskByIndex: (
      state,
      {
        payload: { task, index },
      }: PayloadAction<{ task: TaskDTO; index: number }>
    ) => {
      state.tasks[index] = { ...state.tasks[index], ...task };
      if (task.id === state.taskSelected?.id) {
        state.taskSelected = state.tasks[index];
      }
    },
    addDocumentTasks: (state, action: PayloadAction<DocumentTask[]>) => {
      const newDocumentTasks = action.payload;

      const docTaskIds = newDocumentTasks.map((doc) => doc.id);
      const arrExcludeDocumentTasks = (state.documentTasks || []).filter(
        (doc) => !docTaskIds.includes(doc.id)
      );

      state.documentTasks = [...newDocumentTasks, ...arrExcludeDocumentTasks];
    },
    setDocumentTasks: (state, action: PayloadAction<DocumentTask[] | null>) => {
      state.documentTasks = action.payload;
    },
    setRemoveDocumentTasks: (state, action: PayloadAction<string[]>) => {
      const ids = action.payload;
      state.documentTasks = (state.documentTasks || [])?.filter(
        (doc) => !ids.includes(doc.id)
      );
    },
    setIsLoadingTasks: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTask = action.payload;
    },
    setIsFetchedTasks: (state, action: PayloadAction<boolean>) => {
      state.isFetchedTasks = action.payload;
    },
    insertTaskIdCreated: (state, action: PayloadAction<string>) => {
      state.taskIdsCreated = [...state.taskIdsCreated, action.payload];
    },
    setStatusCreateTask: (state, action: PayloadAction<boolean>) => {
      state.isCreatingTask = action.payload;
    },
    setIsProcessingTaskModal: (state, action: PayloadAction<boolean>) => {
      state.isProcessingTaskModal = action.payload;
    },
    resetTaskIdsCreated: (state) => {
      state.taskIdsCreated = [];
    },
    clearState: () => initialState,
  },
});

export const {
  addTaskType,
  setTaskTypes,
  setTaskType,
  deleteTaskType,
  setFilteredTaskTypes,
  setTaskSheetTemplateList,
  removeTask,
  setDocumentTasks,
  addDocumentTasks,
  setRemoveDocumentTasks,
  setTask,
  setTaskSelected,
  setIsLoadedDocumentTasks,
  setTasks,
  setIsLoadingTasks,
  clearState,
  transformDbIdForTasks,
  updateTaskByDocumentItemId,
  updateTaskSheetTemplate,
  deleteTaskSheetTemplate,
  setIsFetchedTasks,
  insertTaskIdCreated,
  resetTaskIdsCreated,
  updateTaskByIndex,
  setStatusCreateTask,
  setIsProcessingTaskModal,
} = taskSlice.actions;

export default taskSlice.reducer;

import { Flex, FlexProps, IconButton } from "@chakra-ui/react";
import IconDocument from "components/icon/IconDocument";
import IconFolder from "components/icon/IconFolder";
import IconPlus from "components/icon/IconPlus";
import { useAuthorization } from "hooks/usePermission";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface Props extends FlexProps {
  isLoadingDocument: boolean;
  onOpenAddParentDocumentTemplateModal: () => void;
  onOpenAddDocumentTemplateModal: () => void;
}

const AddDocumentMenu = ({
  isLoadingDocument,
  onOpenAddParentDocumentTemplateModal,
  onOpenAddDocumentTemplateModal,
  ...rest
}: Props) => {
  const { isOnline } = useSelector((state: RootState) => state.app);
  const { canAddFolder, canAddDocumentCategory } = useAuthorization();

  const isDisabled = useMemo(
    () => !isOnline || isLoadingDocument,
    [isLoadingDocument, isOnline]
  );

  return (
    <Flex
      id="add-document-menu"
      alignItems="center"
      justifyContent="center"
      gap="1rem"
      p=".8rem"
      {...rest}
    >
      {canAddFolder && (
        <IconButton
          aria-label="icon-folder"
          height="4.4rem"
          w="6.8rem"
          bgColor="#fff"
          border="1px solid #D4D4D4"
          borderRadius="4px"
          boxShadow="0px 1px 3px 0px #0000004D"
          icon={
            <Flex alignItems="center">
              <IconPlus h="2.6rem" w="2.4rem" />
              <IconFolder
                sx={{
                  stroke: "#737373",
                }}
                w="2.4rem"
              />
            </Flex>
          }
          onClick={onOpenAddParentDocumentTemplateModal}
          isDisabled={isDisabled}
        />
      )}

      {canAddDocumentCategory && (
        <IconButton
          aria-label="icon-documents"
          height="4.4rem"
          w="6.8rem"
          bgColor="#fff"
          border="1px solid #D4D4D4"
          borderRadius="4px"
          boxShadow="0px 1px 3px 0px #0000004D"
          icon={
            <Flex alignItems="center">
              <IconPlus h="2.6rem" w="2.4rem" />
              <IconDocument w="2.4rem" />
            </Flex>
          }
          onClick={onOpenAddDocumentTemplateModal}
          isDisabled={isDisabled}
        />
      )}
    </Flex>
  );
};

export default memo(AddDocumentMenu);

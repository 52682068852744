export const __webSocketConnectionIds: Set<string> = new Set();
export const clearWebsocketConnectionIds = () => {
  __webSocketConnectionIds.clear();

  (window as any).__webSocketConnectionIds = __webSocketConnectionIds;
};
export const setWebsocketConnectionIds = (id: string) => {
  __webSocketConnectionIds.add(id);

  (window as any).__webSocketConnectionIds = __webSocketConnectionIds;
};
export const getWebsocketConnectionIds = () =>
  Array.from(__webSocketConnectionIds);

export const CHANNEL_ALL = "ALL";
export const CHANNEL_COMMON = "COMMON";
export const CHANNEL_SYNC_DATA = "CHANNEL_SYNC_DATA";

export enum MessageType {
  RELOAD_DOCUMENT = "RELOAD_DOCUMENT",
  RELOAD_TASK = "RELOAD_TASK",
  CHANGE_DOCUMENT_CATEGORIES = "CHANGE_DOCUMENT_CATEGORIES",

  ADD_TASK_COMMENTS = "ADD_TASK_COMMENTS",
  ADD_TASK_TYPE = "ADD_TASK_TYPE",
  UPDATE_TASK_TYPE = "UPDATE_TASK_TYPE",
  RELOAD_TASK_TYPE = "RELOAD_TASK_TYPE",
  DELETE_TASK_TYPE = "DELETE_TASK_TYPE",
  REFRESH_TASK_TYPE = "REFRESH_TASK_TYPE",
  ADD_DOCUMENT_CATEGORY_LOG = "ADD_DOCUMENT_CATEGORY_LOG",
  ADD_DOCUMENT_ITEM_LOG = "ADD_DOCUMENT_ITEM_LOG",
  ADD_SUB_ITEM_LOG = "ADD_SUB_ITEM_LOG",
  ADD_GROUP_LOG = "ADD_GROUP_LOG",
  ADD_SUB_ITEM = "ADD_SUB_ITEM",
  ADD_DOC_GROUP = "ADD_DOC_GROUP",

  UPDATE_TASK_COMMENT = "UPDATE_TASK_COMMENT",
  UPDATE_TASK = "UPDATE_TASK",
  ADD_TASK = "ADD_TASK",
  UPDATE_TASK_BY_DOCUMENT_ITEM_ID = "UPDATE_TASK_BY_DOCUMENT_ITEM_ID",
  UPDATE_DOCUMENT_CATEGORIES = "UPDATE_DOCUMENT_CATEGORIES",
  UPDATE_DOCUMENT_ITEM = "UPDATE_DOCUMENT_ITEM",
  UPDATE_SUB_ITEM = "UPDATE_SUB_ITEM",
  UPDATE_BLACKBOARD = "UPDATE_BLACKBOARD",
  ADD_DOCUMENT_ITEM = "ADD_DOCUMENT_ITEM",

  DELETE_DOC_GROUP = "DELETE_DOC_GROUP",
  DELETE_TASK = "DELETE_TASK",
  DELETE_SUB_ITEM = "DELETE_SUB_ITEM",
  DELETE_DOCUMENT_ITEMS = "DELETE_DOCUMENT_ITEMS",
  DELETE_DOCUMENT_CATEGORY = "DELETE_DOCUMENT_CATEGORY",

  CHANGE_DOC_GROUP = "CHANGE_DOC_GROUP",
  CHANGE_CURRENT_DOCUMENT = "CHANGE_CURRENT_DOCUMENT",
  CHANGE_DOC_CATEGORY = "CHANGE_DOC_CATEGORY",

  UPDATE_FAMILY = "UPDATE_FAMILY",
  ADD_FAMILY = "ADD_FAMILY",
  DELETE_FAMILY = "DELETE_FAMILY",

  FETCH_TASKSHEET_TEMPLATE = "FETCH_TASKSHEET_TEMPLATE",
  DELETE_TASKSHEET_TEMPLATE = "DELETE_TASKSHEET_TEMPLATE",

  CREATE_UPDATE_DOCUMENT_TEMPLATE = "CREATE_UPDATE_DOCUMENT_TEMPLATE",
  DELETE_DOCUMENT_TEMPLATE = "DELETE_DOCUMENT_TEMPLATE",

  DELETE_PROJECT_BIM_FILE = "DELETE_PROJECT_BIM_FILE",
  UPDATE_PROJECT_BIM_FILE = "UPDATE_PROJECT_BIM_FILE",

  DELETE_DOCUMENT_TASK = "DELETE_DOCUMENT_TASK",

  CHANGE_VISIBLE_DOCUMENT_ITEM = "CHANGE_VISIBLE_DOCUMENT_ITEM",
}

export enum WSEVent {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  CONNECT_ERROR = "connect_error",
  SOCKET_ERROR = "socket_error",
  DATA_CHANGE = "data_change",
  JOIN_CHANNEL = "join_channel",
  LEAVE_CHANNEL = "leave_channel",
}

import { PageInfo } from "components/TipTapEditorDoc/type";
import { DEFAULT_BLACKBOARD_CONTENT_SIZE } from "constants/blackBoardTemplate";
import {
  CellProperty,
  CellSizeSetting,
  PaperDirectionType,
  PaperSize,
  PaperType,
} from "constants/enum";
import {
  CellType,
  PageFormat,
  TableStyleType,
  TemplateComponent,
} from "interfaces/models/component";
import Component from "models/component";
import { DEFAULT_BORDER_COLOR } from "pages/document/template-page/hooks";
import { RootState } from "redux/store";
import { getCellPositionByCellId } from "utils/tableCell";

export const getMaxWidthTable = (params: {
  isBlackboardTemplate?: boolean;
  templatePages: PageFormat[];
  componentPage: number;
}) => {
  const { isBlackboardTemplate = false, templatePages, componentPage } = params;

  if (isBlackboardTemplate) {
    return DEFAULT_BLACKBOARD_CONTENT_SIZE.width;
  }

  const pageInfo = templatePages?.[componentPage];

  if (pageInfo) {
    const _pageSize = (pageInfo?.pageSize ||
      PaperType.A4) as keyof typeof PaperSize;
    let { numOfColumns, numOfRows } = PaperSize[_pageSize] || {};
    if (pageInfo?.pageDirection === PaperDirectionType.HORIZONTAL) {
      [numOfColumns, numOfRows] = [numOfRows, numOfColumns];
    }

    const maxWidth = numOfColumns * CellSizeSetting.MIN_WIDTH;

    return maxWidth;
  }

  return;
};

export const checkTableFullWidth = (params: {
  maxWidth: number | undefined;
  resizeWidth: number;
  width: number;
  isBlackboardTemplate?: boolean;
}) => {
  const { isBlackboardTemplate, maxWidth, resizeWidth, width } = params;

  if (!maxWidth) {
    return false;
  }

  if (isBlackboardTemplate) {
    return width === maxWidth;
  }

  return resizeWidth === maxWidth;
};

export const checkTableOverlapRightSide = (params: {
  components: TemplateComponent[];
  isFullWidth: boolean;
  component: TemplateComponent;
  documentContainerSize: RootState["document"]["documentContainerSize"];
  zoomRatio: number;
}) => {
  const {
    components,
    isFullWidth,
    component,
    documentContainerSize,
    zoomRatio,
  } = params;

  if (isFullWidth) {
    return false;
  }

  const filterComponents = components.filter(
    (com) => com.page === component.page
  );
  const componentIns = new Component({
    component,
    components: filterComponents,
    documentContainerSize,
    scale: zoomRatio,
  });

  return !componentIns.availableSpace.right;
};

export const getDisplaySizeScale = (page: PageInfo | undefined) => {
  const sizePageRatio = page?.sizePageRatio || 1;
  const pageDirectionRatio = page?.pageDirectionRatio || 1;

  return {
    x: 1 / sizePageRatio / pageDirectionRatio,
    y: (1 / sizePageRatio) * pageDirectionRatio,
    displayX: sizePageRatio * pageDirectionRatio,
    displayY: sizePageRatio / pageDirectionRatio,
  };
};

export const getBorderColorByTableStyle = (css: TableStyleType) => {
  if (!css?.border) {
    return `${css?.backgroundColor}`;
  }

  if (css?.border) {
    const top = css?.borderTop
      ? css?.borderColor || DEFAULT_BORDER_COLOR
      : css?.backgroundColor || "#fff";
    const right = css?.borderRight
      ? css?.borderColor || DEFAULT_BORDER_COLOR
      : css?.backgroundColor || "#fff";
    const bottom = css?.borderBottom
      ? css?.borderColor || DEFAULT_BORDER_COLOR
      : css?.backgroundColor || "#fff";
    const left = css?.borderLeft
      ? css?.borderColor || DEFAULT_BORDER_COLOR
      : css?.backgroundColor || "#fff";

    return `${top} ${right} ${bottom} ${left}`;
  }

  return `${css?.borderColor}`;
};

export const getBorderWidthByTableStyle = (params: {
  css: TableStyleType | null;
  prevHasSubTable: boolean | null;
  optionSubCell?: {
    subCell: CellType;
    component: TemplateComponent;
    isLastColumn: boolean;
    isParentLastColumn: boolean;
    isLastRow: boolean;
    isFirstColumn: boolean;
  };
}) => {
  const { optionSubCell, css, prevHasSubTable } = params;
  if (optionSubCell) {
    const {
      subCell,
      component,
      isFirstColumn,
      isLastColumn,
      isParentLastColumn,
      isLastRow,
    } = optionSubCell;
    const subCellIndex = getCellPositionByCellId({
      cellId: subCell?.cellId,
      component,
    })?.index;

    const borderTopWidth = subCellIndex?.row === 0 ? "0px" : "1px";
    const borderRightWidth = isLastColumn || isParentLastColumn ? "0px" : "1px";
    const borderBottomWidth = isLastRow ? "0px" : "1px";
    let borderLeftWidth =
      isFirstColumn && subCellIndex?.col === 0 ? "0px" : "1px";

    const isBlackboard = subCell.cellProperty === CellProperty.BLACK_BOARD_DATA;
    if (isBlackboard && (subCell?.colSpan || 1) > 1) {
      borderLeftWidth = "0px";
    }

    return `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`;
  }

  if (!css?.border) {
    return "0px";
  }

  const top = css?.borderTop ? "1px" : "0px";
  const right = css?.borderRight ? "1px" : "0px";

  const bottom = css?.borderBottom ? "1px" : "0px";
  const left = !css?.borderLeft || prevHasSubTable ? "0px" : "1px";

  return `${top} ${right} ${bottom} ${left}`;
};

import { useDisclosure } from "@chakra-ui/react";
import {
  documentApi,
  documentCategoryApi,
  documentKeyNoteApi,
} from "apiClient/v2";
import { message } from "components/base";
import { OPTION_ALL_AREA, OPTION_ALL_FLOOR } from "constants/area";
import {
  DocumentCategoryKey,
  DocumentCategoryStatusType,
  DocumentTemplateType,
  MapDocumentCategoryStatusTypeColor,
  TemplateComponentType,
} from "constants/enum";
import { DISPLAY_MODE } from "constants/forge";
import { OPERATION } from "constants/task";
import { MessageType } from "constants/websocket";
import useChangeLevel from "hooks/useChangeLevel";
import useFamilyInstance from "hooks/useFamilyInstance";
import useGetPartnerCompanies from "hooks/useGetPartnerCompanies";
import { useRoles } from "hooks/usePermission";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { Level, Vector3 } from "interfaces/models";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentCategory } from "interfaces/models/documentCategory";
import {
  DocumentKeyNote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { debounce } from "lodash";
import { transformMapTitleKey } from "models/dataLog";
import {
  checkKeynoteValid,
  FilterDocumentCategoryByUserSettingProps,
  getDocumentItemHasImage,
  processUpdateCurrentDocument,
  transformDocumentCategoryByUserSetting,
} from "models/document";
import { doMapDocumentCategory } from "models/documentCategory";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import useSupportSyncDataOffline, {
  TypeHandleInitData,
} from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  clearState as clearDocumentState,
  removeDocumentCategory,
  setDocumentCategorySelected,
  setStatusContentFromS3,
  toggleIsCaptureKeynoteByOperation,
  updateDocumentCategory,
} from "redux/documentSlice";
import {
  resetToInitState,
  setDisplayMode,
  setIsLoadedSheetTransformRatio,
  setIsWaittingCaptureKeynote,
  setLevelSelected,
  setModalType,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";
import { handleCaptureKeynote } from "utils/capture-keynote";
import { safelyParseJSON, sleep, uuid } from "utils/common";
import { fitToViewByPositions, selectDbIds } from "utils/forge";
import { selectLabel } from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";
import { transformBodyForCombineData } from "utils/offline";
import useDocumentCategoryLogs from "./useDocumentCategoryLogs";

interface iProps {
  filterDocumentCategoryOptions?: FilterDocumentCategoryByUserSettingProps;
  isDisableCaptureKeynote?: boolean;
  setLoadedViewerOff?: () => void;
}

const useDocumentCategory = ({
  filterDocumentCategoryOptions,
  isDisableCaptureKeynote,
  setLoadedViewerOff,
}: iProps) => {
  const dispatch = useDispatch();
  const { isTakasagoGroup } = useRoles();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { neptuneAreas, levelSelected, levels, displayMode } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const [isCreatingKeynote, setIsCreatingKeynote] = useState(false);
  const [template, setTemplate] = useState<DocumentTemplate>();
  const [components, setComponents] = useState<TemplateComponent[]>([]);
  const [documentCategoryModalInfo, setDocumentCategoryModalInfo] =
    useState<DocumentCategoryDTO>({} as DocumentCategory);
  const [currentDocument, setCurrentDocument] = useState<DocumentDTO | null>(
    null
  );
  const [keynoteImageData, setKeynoteImageData] = useState<KeynoteImageData>(
    {} as any
  );
  const [documentKeynoteData, setDocumentKeynoteData] =
    useState<DocumentKeyNote>({});
  const [isCaptureKeynote, setIsCaptureKeynote] = useState<boolean>(false);
  const [isChangeSheet, setIsChangeSheet] = useState<boolean>(false);
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  const { bimFileId, version } = useParams();
  const { familyInstances } = useFamilyInstance();

  const {
    documentCategorySelected,
    documentCategories,
    dataBlackboards,
    documentTemplates,
    isEditingFamily,
    isCaptureKeynoteByOperation,
    isContentFromS3,
    isLoadingDocument,
  } = useSelector((state: RootState) => state.document);
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  const lastLoadingDocumentRef = useRef(isLoadingDocument);

  const { webSocketMessages, socket } = useForgeViewerContext();
  const { changeLevelSelected } = useChangeLevel();

  const filterDocumentCategorySelected = useMemo(() => {
    if (!filterDocumentCategoryOptions) return documentCategorySelected;

    if (!Object.keys(filterDocumentCategoryOptions)?.length) {
      return documentCategorySelected;
    }

    const result = transformDocumentCategoryByUserSetting({
      ...filterDocumentCategoryOptions,
      documentCategory: documentCategorySelected,
      documentCategories,
      skipFilterByDocumentTemplate: true,
    });

    return result ?? documentCategorySelected;
  }, [
    documentCategorySelected,
    filterDocumentCategoryOptions,
    documentCategories,
  ]);

  const documentTemplate =
    documentTemplates[filterDocumentCategorySelected?.templateId || ""];

  const {
    documentCategoryLogs,
    isLoadMoreLogs,
    mapDynamicFieldTitle,
    insertDocumentCategoryLog,
    handleRevertCategoryByLogs,
  } = useDocumentCategoryLogs({
    levels,
    documentCategory: documentCategorySelected,
    currentUserId: currentUser?.id,
    isLoadingDocument,
    documentTemplate,
  });

  const { listUserById, listAllUserById, isFetchingUserAssigned } =
    useUserOfProject();

  const { partnerCompanies } = useGetPartnerCompanies({
    bimFileId: decodeURIComponent(bimFileId!),
  });
  const navigate = useNavigate();

  const documentCategoryIdRef = useRef<string | undefined>(undefined);
  const inputDocumentTitleRef = useRef<HTMLInputElement>(null);
  const {
    fieldUpdateDataRef,
    initDataRef: initCategoryDataRef,
    clearFieldUpdateData,
    handleUpdateFieldsChangeData,
    handleInitData: handleInitCategoryData,
  } = useSupportSyncDataOffline<DocumentCategoryDTO>();

  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();

  const {
    isOpen: isConfirmDeleteDocumentModalOpen,
    onOpen: openConfirmDeleteDocumentModal,
    onClose: closeConfirmDeleteDocumentModal,
  } = useDisclosure();

  useEffect(() => {
    if (isLoadingDocument) {
      clearFieldUpdateData();
    }

    if (
      documentCategorySelected?.id &&
      documentCategorySelected?.id === documentCategoryModalInfo?.id
    ) {
      handleInitCategoryData({
        data: documentCategorySelected,
        type: TypeHandleInitData.DOCUMENT_CATEGORY,
      });
    } else {
      clearFieldUpdateData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentCategorySelected?.id,
    documentCategoryModalInfo?.id,
    isLoadingDocument,
  ]);

  const companiesById = useMemo(() => {
    return partnerCompanies.reduce((acc, company) => {
      return {
        ...acc,
        [company.id]: company,
      };
    }, {} as Record<string, PartnerCompany>);
  }, [partnerCompanies]);

  const isParent = useMemo(() => {
    return (
      documentCategorySelected?.documentType ===
      DocumentTemplateType.PARENT_TEMPLATE
    );
  }, [documentCategorySelected]);

  const isDocumentCategoryCurrent = useMemo(
    () => {
      if (!documentCategoryIdRef.current) return false;

      return documentCategorySelected?.id === documentCategoryIdRef.current;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentCategorySelected, documentCategoryIdRef.current]
  );

  const documentType = useMemo(
    () => filterDocumentCategorySelected?.documentType,
    [filterDocumentCategorySelected?.documentType]
  );

  const documentItemsHasImage: any = useMemo(() => {
    if (!filterDocumentCategorySelected) {
      return;
    }

    const { documentItemsHaveImage: data } = getDocumentItemHasImage(
      filterDocumentCategorySelected,
      dataBlackboards
    );

    return data;
  }, [filterDocumentCategorySelected, dataBlackboards]);

  const handleClosePreview = useCallback(() => {
    setCurrentDocument(null);
    onClosePreview();

    if (isCaptureKeynoteByOperation) {
      dispatch(toggleIsCaptureKeynoteByOperation(false));
      dispatch(resetToInitState());

      navigate(
        generatePath(routePath.DocumentLevel, {
          bimFileId,
        })
      );
    }
  }, [
    dispatch,
    navigate,
    onClosePreview,
    isCaptureKeynoteByOperation,
    bimFileId,
  ]);

  // reset document keynote data
  useEffect(() => {
    if (!filterDocumentCategorySelected?.id) {
      return;
    }

    if (documentCategoryModalInfo.id !== filterDocumentCategorySelected.id) {
      setDocumentKeynoteData({} as any);
      setKeynoteImageData({} as any);
    }
  }, [filterDocumentCategorySelected?.id, documentCategoryModalInfo.id]);

  const updateDocumentCategoryModal = (forgeUpdate = false) => {
    if (!filterDocumentCategorySelected?.id) {
      return;
    }
    setDocumentCategoryModalInfo((documentCategory: DocumentCategoryDTO) => {
      if (
        documentCategory.id !== filterDocumentCategorySelected.id ||
        documentCategory.title !== filterDocumentCategorySelected.title ||
        forgeUpdate
      ) {
        if (documentCategoryIdRef.current) {
          documentCategoryIdRef.current = undefined;
        }
        loadingPreview && setLoadingPreview(false);
        isOpenPreview && onOpenPreview();

        return {
          ...(filterDocumentCategorySelected || {}),
          status:
            filterDocumentCategorySelected?.status ||
            DocumentCategoryStatusType.NotStarted,
          templateId: filterDocumentCategorySelected.templateId,
          documentItems: filterDocumentCategorySelected.documentItems,
          documentSubCategories:
            filterDocumentCategorySelected.documentSubCategories,
        } as DocumentCategoryDTO;
      }

      return { ...filterDocumentCategorySelected };
    });
  };

  useEffect(() => {
    if (
      !isLoadingDocument &&
      lastLoadingDocumentRef.current !== isLoadingDocument
    ) {
      updateDocumentCategoryModal(true);
    }
    lastLoadingDocumentRef.current = isLoadingDocument;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDocument]);

  useEffect(() => {
    updateDocumentCategoryModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterDocumentCategorySelected,
    currentUser,
    loadingPreview,
    isOpenPreview,
    isParent,
    onOpenPreview,
  ]);

  // sync data by web socket
  useEffect(() => {
    if (!webSocketMessages.length) return;
    webSocketMessages.forEach((e) => {
      const { type, data } = e;
      if (type === MessageType.CHANGE_CURRENT_DOCUMENT) {
        setCurrentDocument(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  const updateDataRedux = useCallback(
    (
      documentCategory?: DocumentCategoryDTO,
      isUpdateDocumentCategorySelect = true
    ) => {
      let newDocumentCategoryModal =
        documentCategory || documentCategoryModalInfo;

      const documentItems = newDocumentCategoryModal?.documentItems || [];
      newDocumentCategoryModal = doMapDocumentCategory({
        category: newDocumentCategoryModal,
        documentItems,
        // documentTemplates: [documentTemplate],
        template: documentTemplate,
      });

      const newDocumentCategorySelected = {
        ...filterDocumentCategorySelected,
        ...newDocumentCategoryModal,
      };

      dispatch(updateDocumentCategory(newDocumentCategoryModal));
      if (isUpdateDocumentCategorySelect) {
        dispatch(setDocumentCategorySelected(newDocumentCategorySelected));
      }
    },
    [
      dispatch,
      filterDocumentCategorySelected,
      documentTemplate,
      documentCategoryModalInfo,
    ]
  );

  const onSelectedDay = (day: Date | string, field: string = "deadline") => {
    setDocumentCategoryModalInfo((documentCategoryModalInfo) => ({
      ...documentCategoryModalInfo,
      [field]: day,
    }));
  };

  const handleSetColorLabel = useCallback(
    (documentItems?: DocumentItemDTO[], status?: string) => {
      if (documentItems) {
        const newDocumentItems = documentItems;
        const ids = newDocumentItems.map((item) => item.id);
        const externalIds = newDocumentItems.map(
          (item) => item.externalId || ""
        );

        selectLabel(ids);
        selectDbIds(externalIds, {
          color:
            MapDocumentCategoryStatusTypeColor[
              (status ||
                DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
            ],
        });
      }
    },
    []
  );

  const onBlurOtherForm = async (
    key: keyof DocumentCategoryDTO,
    value: any,
    documentCategory?: DocumentCategoryDTO
  ) => {
    const newDocumentCategory = documentCategory ?? documentCategoryModalInfo;
    const isValid =
      (!filterDocumentCategorySelected?.id && !documentCategory) ||
      newDocumentCategory?.[key as keyof DocumentCategoryDTO] === value;

    if (isValid) {
      return;
    }

    const newDocumentCategoryModalInfo = {
      ...newDocumentCategory,
      [key]: value,
    };
    handleUpdateFieldsChangeData([key as keyof DocumentCategoryDTO]);

    const now = new Date();
    newDocumentCategoryModalInfo.updatedAt = now;

    const requestId = uuid();
    const { mapLogs, mapTitleKey, mapDisplayValueKey } = transformMapTitleKey([
      {
        field: key as any,
        value,
      },
    ]);

    const { data: newCategory } = await documentCategoryApi.updateCategory(
      transformBodyForCombineData<DocumentCategoryDTO>({
        body: {
          id: newDocumentCategoryModalInfo.id,
          areaId: newDocumentCategoryModalInfo.areaId,
          updatedAt: now,
          [key]: value,
          mapTitleKey,
          mapDisplayValueKey,
          requestId,
        } as DocumentCategoryDTO,
        bodyBefore: initCategoryDataRef.current || ({} as any),
        typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
      })
    );

    handleInitCategoryData({
      data: newDocumentCategoryModalInfo,
      type: TypeHandleInitData.DOCUMENT_CATEGORY,
    });

    Object.values(mapLogs).forEach((params) => {
      insertDocumentCategoryLog({ ...params, requestId });
    });
    setDocumentCategoryModalInfo(newDocumentCategoryModalInfo);
    updateDataRedux(newDocumentCategoryModalInfo);
    socket.changeDocCategory(newDocumentCategoryModalInfo, {
      [key]: value,
      updatedAt: newCategory.updatedAt,
    });
  };

  const onBlurCombineOtherForm = (
    data: Partial<DocumentCategoryDTO>,
    documentCategory?: DocumentCategoryDTO
  ) => {
    const newDocumentCategoryModalInfo = {
      ...(documentCategory || documentCategoryModalInfo),
      ...data,
    };

    setDocumentCategoryModalInfo(newDocumentCategoryModalInfo);
    saveModalData(newDocumentCategoryModalInfo, false);

    if (Object.keys(data).includes(DocumentCategoryKey.STATUS)) {
      updateDataRedux(newDocumentCategoryModalInfo);
      socket.changeDocCategory(newDocumentCategoryModalInfo, {
        ...data,
        updatedAt: newDocumentCategoryModalInfo.updatedAt,
      });
    }
  };

  const debounceDocumentCategory = debounce(
    async (documentCategory: DocumentCategoryDTO) => {
      await documentCategoryApi.updateCategory(documentCategory);
    },
    500
  );

  const saveModalData = async (
    data: DocumentCategoryDTO,
    useDebounce = true
  ) => {
    if (!data.id) {
      return;
    }

    let bodyUpdate = {
      id: data.id,
      updatedAt: data.updatedAt || new Date(),
    } as DocumentCategoryDTO;

    fieldUpdateDataRef.current.forEach((key) => {
      if ([DocumentCategoryKey.DOCUMENT_ITEMS].includes(key as any)) {
        return;
      }

      let value = data[key];
      if (key === DocumentCategoryKey.NEPTUNE_AREA_IDS) {
        value = (value as string[]).filter(
          (id) => ![OPTION_ALL_AREA, OPTION_ALL_FLOOR].includes(id)
        );
      }

      bodyUpdate[key] = value as never;
    });

    bodyUpdate = transformBodyForCombineData<DocumentCategoryDTO>({
      body: bodyUpdate,
      bodyBefore: initCategoryDataRef.current || ({} as any),
      typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
    });

    if (useDebounce) {
      await debounceDocumentCategory(bodyUpdate)!;
    } else {
      await documentCategoryApi.updateCategory(bodyUpdate);
    }

    handleInitCategoryData({
      data,
      type: TypeHandleInitData.DOCUMENT_CATEGORY,
    });
  };

  const getDocTemplateDetail = useCallback(() => {
    const template =
      documentTemplates[filterDocumentCategorySelected?.templateId || ""];
    let templateComponents: TemplateComponent[] = [];
    if (template?.components?.length) {
      templateComponents = template?.components;
    }

    return { template, components: templateComponents };
  }, [documentTemplates, filterDocumentCategorySelected?.templateId]);

  useEffect(() => {
    if (template?.id !== filterDocumentCategorySelected?.templateId) {
      const { components, template } = getDocTemplateDetail();
      setTemplate(template);
      setComponents(components);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, filterDocumentCategorySelected]);

  const handleSideEffectKeynote = useCallback(
    async (isCaptureByOperation = false) => {
      const keynoteComponent = components.find(
        (component) =>
          component.type === TemplateComponentType.Image &&
          component.detail?.checkedImage
      );

      if (!keynoteComponent?.componentId || !documentCategorySelected?.id) {
        return;
      }

      const levelLabel = documentCategorySelected?.level as string;
      const targetLevel = levels.find((item) => item.label === levelLabel);

      if (!targetLevel) {
        return;
      }

      const isAllFloor = !levelSelected?.guid;
      const currentLevelSelected = isAllFloor ? targetLevel : levelSelected;
      let guid =
        currentLevelSelected?.sheetGuid ||
        currentLevelSelected?.sheets?.[0]?.guid ||
        DISPLAY_MODE["3D"];

      const documentKeynote = await documentKeyNoteApi.handleGetKeynoteList(
        documentCategorySelected.id
      );

      // case capture keynote when on the document level page
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const data: { guid: string } = safelyParseJSON(
        urlParams.get("data") || "{}"
      );
      if (isCaptureByOperation && data?.guid) {
        guid = data?.guid;
      }

      const {
        isCalculatePostion,
        isInstancesDiffKeynotes,
        keynoteImageData: currentKeynoteImageData,
        instances,
      } = await checkKeynoteValid({
        documentCategory: documentCategorySelected,
        documentKeynote,
        guid,
      });

      // check keynote
      if (isCalculatePostion && !isInstancesDiffKeynotes) {
        setDocumentKeynoteData(documentKeynote);
        setKeynoteImageData(currentKeynoteImageData);

        if (isCaptureByOperation) {
          dispatch(setIsWaittingCaptureKeynote(false));
          navigate(window.location.pathname, { replace: true });
        }

        return;
      }

      //if it's all floors or 3d mode has sheets, change it to other floor
      if (
        (isAllFloor || displayMode === DISPLAY_MODE["3D"]) &&
        currentLevelSelected?.sheets?.length
      ) {
        setLoadedViewerOff?.();
        dispatch(setLevelSelected(targetLevel));
        dispatch(setDisplayMode(DISPLAY_MODE["2D"]));
        setIsCaptureKeynote(true);

        return;
      }

      const { newKeynoteImageData, dataUpdate } = await handleCaptureKeynote({
        currentKeynoteImageData,
        documentCategory: documentCategorySelected,
        documentKeynote,
        guid,
        isInstancesDiffKeynotes,
        instances,
        setIsCreatingKeynote,
        isMobile,
      });

      if (newKeynoteImageData) {
        setKeynoteImageData(newKeynoteImageData);
      }
      if (dataUpdate) {
        setDocumentKeynoteData(dataUpdate);
      }

      setIsCreatingKeynote(false);

      if (isCaptureByOperation) {
        dispatch(setIsWaittingCaptureKeynote(false));
        navigate(window.location.pathname, { replace: true });
      }
    },

    [
      navigate,
      dispatch,
      isMobile,
      displayMode,
      components,
      levels,
      levelSelected,
      documentCategorySelected,
      setLoadedViewerOff,
    ]
  );

  const getDocTemplateDetailForParent = useCallback(
    (templateId?: string) => {
      if (!templateId) {
        return;
      }
      const template = documentTemplates[templateId];
      if (!template) {
        return;
      }

      return {
        template,
        components: (template?.components || []) as TemplateComponent[],
      };
    },
    [documentTemplates]
  );

  const handleSideEffectKeynoteParent = useCallback(async () => {
    const levelLabel = documentCategorySelected?.level as string;
    const isAllFloor = !levelSelected?.guid;
    const targetLevel = levels.find((item) => item.label === levelLabel);
    const currentLevelSelected = isAllFloor ? targetLevel : levelSelected;
    const guid =
      currentLevelSelected?.sheetGuid ||
      currentLevelSelected?.sheets?.[0]?.guid ||
      DISPLAY_MODE["3D"];
    const childTemplates = documentCategorySelected?.childTemplates || [];
    let needChangeFloor = false;

    // need call websocket ?
    const mapCategories = await Promise.all(
      childTemplates.map(async (category: DocumentCategoryDTO) => {
        const _template = getDocTemplateDetailForParent(category.templateId);

        if (!_template) return;

        const keynoteComponent = _template.components.find(
          (component) =>
            component.type === TemplateComponentType.Image &&
            component.detail?.checkedImage
        );
        if (!keynoteComponent?.componentId || !category?.id) {
          return;
        }

        const documentKeynote = await documentKeyNoteApi.handleGetKeynoteList(
          category.id
        );

        const {
          isCalculatePostion,
          isInstancesDiffKeynotes,
          keynoteImageData: currentKeynoteImageData,
          instances,
        } = await checkKeynoteValid({
          documentCategory: category,
          documentKeynote,
          guid,
        });

        if (!isCalculatePostion || isInstancesDiffKeynotes) {
          needChangeFloor = true;
        }

        return {
          category,
          isCalculatePostion,
          isInstancesDiffKeynotes,
          currentKeynoteImageData,
          instances,
          documentKeynote,
        };
      })
    );

    if (needChangeFloor && isAllFloor) {
      dispatch(setLevelSelected(targetLevel));
      dispatch(setDisplayMode(DISPLAY_MODE["2D"]));

      return;
    }

    if (
      !isCaptureKeynoteByOperation ||
      documentCategorySelected?.documentType !==
        DocumentTemplateType.PARENT_TEMPLATE ||
      !childTemplates?.length
    ) {
      return;
    }
    setIsCreatingKeynote(true);

    await Promise.all(
      mapCategories.map(async (mapData) => {
        if (!mapData) return;
        const {
          category,
          isCalculatePostion,
          isInstancesDiffKeynotes,
          instances,
          currentKeynoteImageData,
          documentKeynote,
        } = mapData;

        if (isCalculatePostion && !isInstancesDiffKeynotes) {
          return;
        }

        await handleCaptureKeynote({
          currentKeynoteImageData,
          documentCategory: category,
          documentKeynote,
          guid,
          instances,
          isInstancesDiffKeynotes,
          isMobile,
        });

        return category;
      })
    );

    setIsCreatingKeynote(false);

    if (isCaptureKeynote) {
      setIsCaptureKeynote(false);
      setIsChangeSheet(false);
    }

    if (isCaptureKeynoteByOperation) {
      dispatch(setIsWaittingCaptureKeynote(false));
      navigate(window.location.pathname, { replace: true });
    }
  }, [
    isMobile,
    isCaptureKeynoteByOperation,
    documentCategorySelected,
    levelSelected,
    levels,
    isCaptureKeynote,
    getDocTemplateDetailForParent,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (!isCaptureKeynote || isDisableCaptureKeynote) {
      return;
    }

    (async () => {
      isParent
        ? await handleSideEffectKeynoteParent()
        : await handleSideEffectKeynote();

      setIsCaptureKeynote(false);
      setIsChangeSheet(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptureKeynote, isDisableCaptureKeynote, isParent, isMobile]);

  const handleChangeSelectedSheet = useCallback(
    async (guid: string) => {
      if (!dataProjectDetail) {
        return;
      }

      setIsChangeSheet(true);
      const data = structuredClone(keynoteImageData);
      if (data?.guid === guid || !documentCategorySelected?.id) {
        setIsChangeSheet(false);

        return;
      }

      data.guid = guid;
      setKeynoteImageData(data);
      const { isCalculatePostion, isInstancesDiffKeynotes } =
        await checkKeynoteValid({
          documentCategory: documentCategorySelected,
          documentKeynote: documentKeynoteData || ({} as DocumentKeyNote),
          guid,
        });

      if (isCalculatePostion && !isInstancesDiffKeynotes) {
        const body = {
          ...documentKeynoteData,
          keynoteImageData: data,
        };
        // need call websocket ?

        documentKeyNoteApi.createKeynote(body);
        setDocumentKeynoteData(body);
        await sleep(100);
        setIsChangeSheet(false);

        return;
      }

      const levelLabel = documentCategorySelected?.level as string;
      const isAllFloor = !levelSelected?.guid;
      const targetLevel = levels.find((item) => item.label === levelLabel);
      const currentLevelSelected = isAllFloor ? targetLevel : levelSelected;

      if (!currentLevelSelected) {
        setIsCaptureKeynote(false);

        return;
      }

      dispatch(setDisplayMode(DISPLAY_MODE["2D"]));
      const level: Level = { ...currentLevelSelected, sheetGuid: guid };
      setLoadedViewerOff?.();
      dispatch(setIsLoadedSheetTransformRatio(false));
      changeLevelSelected({
        level,
        projectDetail: dataProjectDetail,
      });

      await sleep(100);
      setIsCaptureKeynote(true);
    },
    [
      dataProjectDetail,
      keynoteImageData,
      documentCategorySelected,
      documentKeynoteData,
      levelSelected,
      levels,
      setLoadedViewerOff,
      dispatch,
      changeLevelSelected,
    ]
  );

  const handleDisplayDocument = useCallback(
    async (isCaptureKeynoteByOperation = false, data?: DocumentCategoryDTO) => {
      if (!filterDocumentCategorySelected?.id) {
        return;
      }

      documentCategoryIdRef.current = filterDocumentCategorySelected?.id;

      try {
        setLoadingPreview(true);
        const keynoteComponent = components.find(
          (component) =>
            component.type === TemplateComponentType.Image &&
            component.detail?.checkedImage
        );

        if (keynoteComponent || isParent) {
          onOpenPreview();
        }

        const [
          { document: documentData, isDiffTemplateComponent, templateDetail },
        ] = await Promise.all([
          processUpdateCurrentDocument(
            data || documentCategoryModalInfo,
            bimFileId!,
            currentUser!
          ),

          isParent
            ? handleSideEffectKeynoteParent()
            : handleSideEffectKeynote(isCaptureKeynoteByOperation),
        ]);

        if (!documentData) {
          dispatch(setStatusContentFromS3(false));
        }

        if (!keynoteComponent || isParent) {
          onOpenPreview();
        }
        // check current components
        if (
          isDiffTemplateComponent ||
          JSON.stringify(components) !==
            JSON.stringify(templateDetail?.components)
        ) {
          setTemplate(templateDetail);
          templateDetail?.components?.length &&
            setComponents(templateDetail?.components || []);
        }

        setCurrentDocument(documentData);

        setLoadingPreview(false);
      } catch (err) {
        logDev(err, "handleDisplayDocument==");
        setLoadingPreview(false);
        dispatch(setIsWaittingCaptureKeynote(false));
      }
    },
    [
      filterDocumentCategorySelected?.id,
      documentCategoryModalInfo,
      bimFileId,
      currentUser,
      components,
      isParent,
      dispatch,
      onOpenPreview,
      handleSideEffectKeynote,
      handleSideEffectKeynoteParent,
    ]
  );

  // auto open preview when capture keynote on document level page
  useEffect(() => {
    const isAllFloor = !levelSelected?.guid;

    if (
      !isCaptureKeynoteByOperation ||
      isDisableCaptureKeynote !== false ||
      (displayMode === DISPLAY_MODE["3D"] && isAllFloor) ||
      !filterDocumentCategorySelected?.id ||
      !documentCategoryModalInfo?.id ||
      documentCategoryModalInfo?.id !== filterDocumentCategorySelected?.id
    ) {
      return;
    }

    handleDisplayDocument(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    levelSelected?.guid,
    isCaptureKeynoteByOperation,
    isDisableCaptureKeynote,
    displayMode,
    filterDocumentCategorySelected?.id,
    documentCategoryModalInfo?.id,
  ]);

  const handleUpdateCurrentDocument = useCallback(async () => {
    if (!filterDocumentCategorySelected) {
      message.error("ドキュメントのカテゴリーが不足しています。");

      return;
    }
    const { document } = await processUpdateCurrentDocument(
      filterDocumentCategorySelected,
      bimFileId!,
      currentUser!
    );
    setCurrentDocument(document);

    return document;
  }, [filterDocumentCategorySelected, bimFileId, currentUser]);

  const handleDeleteDocumentCategory = useCallback(
    (documentCategoryId?: string) => {
      if (!documentCategoryId) {
        return;
      }

      dispatch(removeDocumentCategory(documentCategoryId));
      dispatch(setModalType("NONE"));
      setTimeout(() => {
        documentCategoryApi.deleteCategoryList([documentCategoryId]);
      });
    },
    [dispatch]
  );

  const isStatusDoneAllDocumentItem = useMemo(
    () =>
      documentCategoryModalInfo?.documentItems?.every(
        (d) => d?.status === DocumentCategoryStatusType.Approved
      ),
    [documentCategoryModalInfo]
  );

  const handleDisplayPosition = useCallback(() => {
    if (documentCategoryModalInfo.neptuneAreaIds?.length) {
      // Nothing
    } else {
      handleSetColorLabel(
        documentCategoryModalInfo.documentItems,
        documentCategoryModalInfo.status
      );
      fitToViewByPositions(
        (documentCategoryModalInfo.documentItems
          ?.map((item) => item?.position)
          .filter((item) => !!item) || []) as Vector3[],
        false
      );
    }
  }, [
    documentCategoryModalInfo.neptuneAreaIds,
    documentCategoryModalInfo.documentItems,
    documentCategoryModalInfo.status,
    handleSetColorLabel,
  ]);

  const onSaveDynamicField = async (
    newDocumentModalInfo: DocumentCategoryDTO
  ) => {
    if (!newDocumentModalInfo) {
      return;
    }

    handleUpdateFieldsChangeData([
      DocumentCategoryKey.DATA,
      DocumentCategoryKey.MAP_TITLE_KEY,
      DocumentCategoryKey.MAP_DISPLAY_VALUE_KEY,
      DocumentCategoryKey.MAP_DISPLAY_LABEL_KEY,
      DocumentCategoryKey.MAP_DISPLAY_VALUE_TYPE_KEY,
    ]);
    setDocumentCategoryModalInfo((prev) => {
      const next = {
        ...newDocumentModalInfo,
        data: { ...(prev.data || {}), ...newDocumentModalInfo.data },
      };
      socket.changeDocCategory(newDocumentModalInfo, { data: next.data });

      return next;
    });
    await saveModalData(newDocumentModalInfo);
  };

  const onGotoDocumentTemplateDetailPage = () => {
    if (!bimFileId || !version) {
      return;
    }

    dispatch(clearDocumentState());
    dispatch(resetToInitState());
    let url = generatePath(routePath.DocumentTemplateEdit, {
      templateId: documentCategoryModalInfo.templateId,
    });

    const query = [
      `bimFileId=${bimFileId}`,
      `version=${version}`,
      `operation=${OPERATION.backToForgeViewer}`,
    ].join("&");
    url = `${url}?${query}`;
    navigate(url);
  };

  const handleSaveNewDocumentTitle = () => {
    if (!documentCategoryModalInfo?.id) {
      return;
    }
    const currentTitle = documentCategoryModalInfo?.title;
    const newTitle = inputDocumentTitleRef.current?.value;
    if (inputDocumentTitleRef.current) {
      inputDocumentTitleRef.current.value = newTitle || "-";
    }
    if (currentTitle === newTitle) {
      return;
    }

    const newDocumentCategoryModal: DocumentCategoryDTO = {
      ...documentCategoryModalInfo,
      title: newTitle,
    };

    setDocumentCategoryModalInfo(newDocumentCategoryModal);
    updateDataRedux(newDocumentCategoryModal);
    saveModalData(newDocumentCategoryModal);
    socket.changeDocCategory(newDocumentCategoryModal, {
      title: newTitle,
    });
  };

  const onChangeCurrentDocument = useCallback((content: any) => {
    setCurrentDocument(content);
  }, []);

  return {
    mapDynamicFieldTitle,
    isLoadMoreLogs,
    documentCategoryLogs,
    isEditingFamily,
    partnerCompanies,
    inputDocumentTitleRef,
    documentType,
    isCaptureKeynoteByOperation,
    isChangeSheet,
    documentKeynoteData,
    keynoteImageData,
    isCreatingKeynote,
    documentTemplates,
    documentCategorySelected: filterDocumentCategorySelected,
    documentItemsHasImage,
    dataBlackboards,
    listUserById,
    listAllUserById,
    companiesById,
    documentCategoryModalInfo,
    isOpenPreview,
    loadingPreview,
    currentDocument,
    neptuneAreas,
    bimFileId,
    currentUser,
    familyInstances,
    documentTemplate,
    template,
    components,
    levelSelected,
    isConfirmDeleteDocumentModalOpen,
    openConfirmDeleteDocumentModal,
    closeConfirmDeleteDocumentModal,
    setIsCreatingKeynote,
    setTemplate,
    setComponents,
    getDocTemplateDetail,
    handleUpdateCurrentDocument,
    setCurrentDocument,
    onOpenPreview,
    onClosePreview: handleClosePreview,
    handleDisplayDocument,
    onSelectedDay,
    // onBlurForm,
    onBlurOtherForm,
    saveModalData,
    handleDisplayPosition,
    isStatusDoneAllDocumentItem,
    onBlurCombineOtherForm,
    onSaveDynamicField,
    updateDataRedux,
    setLoadingPreview,
    isDocumentCategoryCurrent,
    handleChangeSelectedSheet,
    setDocumentKeynoteData,
    setKeynoteImageData,
    onGotoDocumentTemplateDetailPage,
    isTakasagoGroup,
    handleSaveNewDocumentTitle,
    handleDeleteDocumentCategory,
    insertDocumentCategoryLog,
    handleUpdateFieldsChangeData,
    isFetchingUserAssigned,
    handleRevertCategoryByLogs,
    onChangeCurrentDocument,
  };
};

export default useDocumentCategory;

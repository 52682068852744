import { Box, Flex, Text } from "@chakra-ui/react";
import AvatarUser from "components/AvatarUser";
import RevertButton from "components/RevertButton";
import { defaultAvatarPath } from "constants/file";
import { SYSTEM_NAME_JP_TEXT } from "constants/user";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { TaskComment, TaskLogDTO } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import isEqual from "lodash/isEqual";
import { memo, useMemo } from "react";
import { formatDate } from "utils/date";
import ChatComment from "./ChatComment";
import MediasByLog from "./MediasByLog";
import useRecordComment from "./useRecordComment";

interface Props {
  comment: TaskComment;
  listAssignedUserById: { [key: string]: UserDTO | null };
  loadingImage: boolean;
  status: any;
  isOnline: boolean;
  mapTaskType: Map<string, string>;
  mapPartnerCompany: { [key: string]: string };
  userCreatedLog: UserDTO | null;
  currentUser: User | null;
  taskCreatedAt: string | undefined;

  onUpdateImageComment: (
    e: any,
    comment: TaskComment,
    srcImage: string
  ) => Promise<void>;
  onDeleteImage: (comment: TaskComment, srcImage: string) => Promise<void>;
  onPreviewImage: (src: string) => void;
  onRevertDataByLog: (log: TaskLogDTO) => void;
}
const RecordComment = ({
  comment,
  listAssignedUserById,
  loadingImage,
  status,
  isOnline,
  mapTaskType,
  mapPartnerCompany,
  userCreatedLog,
  currentUser,
  taskCreatedAt,
  onUpdateImageComment,
  onDeleteImage,
  onPreviewImage,
  onRevertDataByLog,
}: Props) => {
  const { getLogText } = useRecordComment({
    currentUser,
    listUserById: listAssignedUserById,
    mapPartnerCompany,
    mapTaskType,
    taskCreatedAt,
  });

  const logAttribute = useMemo(
    () => getLogText(comment),
    [getLogText, comment]
  );

  return (
    <Flex
      background="#E5E5E5"
      p="0.5rem 1.6rem"
      flexDir="column"
      color="#737373"
    >
      <Flex mt="0.5rem" gap="1rem">
        <Flex alignItems="center" flexWrap="wrap" gap="0.5rem 1rem" flex={1}>
          <AvatarUser
            w="2.4rem"
            h="2.4rem"
            borderRadius="full"
            objectFit="cover"
            flexShrink={0}
            usingDefaultImage={true}
            src={
              comment?.value?.isCombine
                ? defaultAvatarPath
                : userCreatedLog?.avatarBase64
            }
          />
          <Box flex={1} fontSize="1rem" wordBreak="break-word">
            <Text as="b">
              {comment?.value?.isCombine
                ? SYSTEM_NAME_JP_TEXT
                : userCreatedLog?.name || "user name"}
            </Text>
            {logAttribute?.logText}
            {formatDate(comment?.createdAt)}
          </Box>
        </Flex>

        {comment.field && logAttribute?.isRevert && (
          <RevertButton
            isDisabled={logAttribute.isDisabledRevert}
            customToolTip={logAttribute.revertTooltip}
            onClick={() => onRevertDataByLog(comment as TaskLogDTO)}
          />
        )}
      </Flex>

      <MediasByLog log={comment} hasContainer={true} />

      <ChatComment
        isOnline={isOnline}
        comment={comment}
        status={status}
        loadingImage={loadingImage}
        onUpdateImageComment={onUpdateImageComment}
        onDeleteImage={onDeleteImage}
        onPreviewImage={onPreviewImage}
      />
    </Flex>
  );
};

export default memo(
  RecordComment,
  (prevProps: any, nextProps: any): boolean => {
    return isEqual(prevProps, nextProps);
  }
);

import { Attribute, Node } from "@tiptap/core";
import {
  getBorderColorByTableStyle,
  getBorderWidthByTableStyle,
} from "components/editor-builder/component-preview/TableComponentPreview/utils/tableStyle";
import { FontFamiLyType } from "constants/enum";
import { CellType, TableStyleType } from "interfaces/models/component";
import { DEFAULT_BORDER_COLOR } from "pages/document/template-page/hooks";
import { NodeType } from "../type";

export interface TableTdComponentNodeAttrs {
  width: number;
  height: number;
  textStyle: TableStyleType;
  hasSubTable: boolean;
  prevHasSubTable: boolean;
  tableBackground: string;
  cell: Pick<CellType, "cellId" | "colSpan" | "rowSpan">;
  parentCellStyle?: CellType["style"];
  customBorderWidth?: string;
}

export const TableTdComponentNode = Node.create({
  name: NodeType.TABLE_TD_COMPONENT,
  group: "block",
  content:
    // should put node type NORMAL_TEXT first
    // because when all text's deleted, the default node type will be NORMAL_TEXT
    `(${NodeType.NORMAL_TEXT} | ${NodeType.SUB_TABLE_COMPONENT} | ${NodeType.CROSS_LINE} | ` +
    `${NodeType.MULTIPLE_NORMAL_TEXT} | ${NodeType.IMAGE_WITH_BLACKBOARD} | ` +
    `${NodeType.TASK_LIST} | ${NodeType.JUSTIFY_TEXT} | ${NodeType.LINKED_IMAGE_LINE})*`,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  addAttributes() {
    const attrs: { [key in keyof TableTdComponentNodeAttrs]: Attribute } = {
      customBorderWidth: { default: null },
      parentCellStyle: { default: {} },
      cell: { default: {} },
      width: { default: 0 },
      height: { default: 0 },
      textStyle: { default: 0 },
      hasSubTable: { default: false },
      prevHasSubTable: { default: false },
      tableBackground: { default: "#fff" },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "td",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const {
      height,
      width,
      textStyle: css,
      tableBackground,
      hasSubTable,
      prevHasSubTable,
      cell,
      parentCellStyle,
      customBorderWidth,
    } = HTMLAttributes as TableTdComponentNodeAttrs;
    const fontSize = css?.fontSize || 14;
    const fontFamily =
      css?.fontFamily === FontFamiLyType.NOTO_SANS
        ? `NotoSansCJKjp`
        : css?.fontFamily === FontFamiLyType.NOTO_SERIF
        ? `Noto Serif JP, serif`
        : "inherit";
    const color = css?.color ? css?.color : parentCellStyle?.color || "";
    const backgroundColor =
      hasSubTable ||
      (!css?.backgroundColor && tableBackground) ||
      css?.backgroundColor === tableBackground
        ? "initial"
        : css?.backgroundColor || "#fff";
    const pointerEvents = hasSubTable ? "none" : "auto";
    const borderColor =
      css?.border === undefined
        ? DEFAULT_BORDER_COLOR
        : getBorderColorByTableStyle(css);
    const borderStyle = borderColor ? "solid" : "initial";
    const borderWidth =
      customBorderWidth || getBorderWidthByTableStyle({ css, prevHasSubTable });

    return [
      "td",
      {
        id: cell.cellId,
        rowspan: cell?.rowSpan ?? 1,
        colspan: cell?.colSpan ?? 1,
        style:
          `position: relative; padding: 0px; pointer-events: ${pointerEvents}; ` +
          "white-space: pre-wrap; text-overflow: ellipsis; overflow-wrap: anywhere; cursor: default; " +
          `max-width: ${width}px; width: ${width}px; max-height: ${height}px; height: ${height}px; ` +
          `font-size: ${fontSize}px; color: ${color}; background-color: ${backgroundColor}; ` +
          `border-color: ${borderColor}; border-style: ${borderStyle}; ` +
          `font-family: ${fontFamily}; border-width: ${borderWidth}; `,
      },
      0,
    ];
  },
});

import { BoxProps, ButtonProps, FlexProps, RadioProps } from "@chakra-ui/react";
import { SVGIconProps } from "components/SvgIcon";
import { CSSProperties } from "react";

export const DEFAULT_MAX_WIDTH_IPAD = 1336;

export const TIPTAP_DOCUMENT_CONTAINER_CLASS =
  "tiptap-document-content-container";
export const PREVIEW_DOCUMENT_CONTAINER_CLASS =
  "preview-document-content-container";
export const DOCUMENT_TASK_LIST_TABLE_ID = "document-task-list-table";
export const WEBCAM_CONTAINER_CLASS = "webcam_container";
export const DISABLE_LABEL_TITLE_TOOLTIP_CLASS = "disable-label-title-tooltip";
export const HIGHLIGHT_MOVE_DOCUMENT_ITEM_BACHGROUND_COLOR = "#E0F2FE";
export const RIGHT_SIDEBAR_MODAL_CLASSNAME = "pin-detail-modal";
export const RIGHT_SIDEBAR_MODAL_CONTAINER_ID = "pin-detail-container-modal";
export const MCE_EDITABLE_CLASSNAME = "mceEditable";
export const KEYLABEL_POINT_CLASSNAME = "keylabel-point";
export const KEYLABEL_LENGTH_CLASSNAME = "keylabel-length";
export const KEYNOTE_IMAGE_CLASSNAME = "keynote-image";
export const KEYNOTE_IMAGE_EDITOR_CLASSNAME = "keynote-image-editor";
export const DISABLE_LEFT_SIDEBAR_ID = "disable-left-sidebar";
export const CONTAINER_LAYOUT_ID = "container-layout";
export const DOCUMENT_TEMPLATE_LEFT_SIDEBAR_ID =
  "document-template-left-sidebar";
export const DOCUMENT_TEMPLATE_RIGHT_SIDEBAR_ID =
  "document-template-right-sidebar";

export const LEFT_PANEL_CONTENT_ID = "left-panel-content";

export const BUTTON_CREATE_CUSTOM_COMPONENT_ID = "btn-create-custom-component";
export const BLACKBOARD_CONTAINER_ID = "blackboard-container-id";
export const SELECT_BLACKBOARD_POSITION_CONTAINER_ID =
  "select-blackboard-position-container-id";
export const PREVIEW_DOCUMENT_CATEGORY_BOX_ID = "preview-document-category-box";

export const radioDefaultCSS: RadioProps["sx"] = {
  ".chakra-radio__label": {
    fontSize: "1.4rem",
    marginLeft: "1rem",
  },
  ".chakra-radio__control[data-checked]::before": {
    background: "var(--primary-color)",
  },
  ".chakra-radio__control[data-checked], .chakra-radio__control[data-checked]:hover":
    {
      borderColor: "var(--primary-color) !important",
    },
  ".chakra-radio__control": {
    borderColor: "var(--primary-border-color)",
    borderWidth: "1.5px",
  },

  ".chakra-radio__input": { display: "none" },
};

export const buttonSMStyle: ButtonProps = {
  padding: "0.6rem 0.8rem",
  height: "3.2rem",
  borderRadius: "6px",
  fontSize: "1rem",
  lineHeight: "1",
  fontWeight: "500",
  letterSpacing: "1.246px",
  textTransform: "capitalize",
  className: "text-ellipsis",
};

export const buttonDefaultStyle: ButtonProps = {
  padding: { base: "1rem", "2xl": "1.2rem 1.6rem", xl: "1rem" },
  height: "4rem",
  borderRadius: "6px",
  fontSize: { base: "1rem", "2xl": "1.4rem", xl: "1rem" },
  lineHeight: "1",
  fontWeight: "500",
  letterSpacing: "1.246px",
  textTransform: "capitalize",
  className: "text-ellipsis",
};

export const buttonPrimaryStyle: ButtonProps = {
  ...buttonDefaultStyle,
  color: "#fff",
  backgroundColor: "#009BE0",
  _hover: {
    backgroundColor: "#009BE0",
  },
};

export const buttonOutlineStyle: ButtonProps = {
  ...buttonDefaultStyle,
  color: "#009BE0",
  border: "1px solid #A3A3A3 !important",
  backgroundColor: "#fff",
  _hover: {
    backgroundColor: "#fff",
  },
};

export const buttonRedStyle: ButtonProps = {
  ...buttonDefaultStyle,
  color: "#fff",
  border: "1px solid #DC2626 !important",
  backgroundColor: "#DC2626",
  _hover: {
    backgroundColor: "#DC2626",
  },
};

export interface DatePickerFilterProps {
  crossIconProps?: { style: CSSProperties };
  calendarIconProps?: Partial<SVGIconProps>;
  dividerProps?: BoxProps;
}

export const datePickerFilterStyles: DatePickerFilterProps = {
  crossIconProps: {
    style: { width: "20px", height: "20px", fill: "#cccccc" },
  },
  dividerProps: {
    ml: 0,
    mr: "5px",
  },
  calendarIconProps: {
    width: "20px",
    height: "20px",
    mr: "25px",
  },
};

export const SUB_MENU_TAG_INPUT_CLASSNAME = "sub-menu-tag-input";

export const stylesSelectInputSmall = {
  ".containerSelectInput": {
    width: "100%",
    fontSize: "1.4rem",
    color: "white",
  },
  ".containerSelectInput:hover": {
    border: "none",
  },
  ".containerSelectInput > div": {
    background: "#0D370E",
  },
  ".containerSelectInput > div:hover": {
    boxShadow: "none !important",
  },
  "div[class*='placeholder']": {
    color: "white",
  },
  "div[class*='control']": {
    minHeight: "25px",
  },
  "div[class*='-Input']": {
    color: "white",
    padding: 0,
    margin: 0,
  },
  "div[class*='singleValue']": {
    color: "white",
  },
  "div[class*='indicatorContainer']": {
    color: "white",
    padding: "4px",
    svg: {
      width: "12px",
    },
  },
  "div[class*='menu']": {
    position: "absolute",
    background: "white",
    color: "black",
    zIndex: "99999",
  },
  "div[class*='MenuList'] > div": {
    fontSize: "12px",
    padding: "4px 8px",
  },
};

export const boxContentOfTaskForPhotoBooks: FlexProps = {
  flexDir: "column",
  justifyContent: "flex-start",
  p: "0.5rem 1rem",
  border: "1px solid #1A1A1A",
  bg: "#E6E6E6",
};

interface Props {
  style: React.CSSProperties;
}

export default function IconDrag(props: Props) {
  return (
    <svg
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        fill="#d4d4d4"
        d="M5 15a.97.97 0 0 1-.713-.287A.97.97 0 0 1 4 14q0-.424.287-.713A.97.97 0 0 1 5 13h14a.97.97 0 0 1 .712.287Q20 13.575 20 14c0 .425-.096.52-.288.713A.97.97 0 0 1 19 15zm0-4a.97.97 0 0 1-.713-.287A.97.97 0 0 1 4 10q0-.424.287-.713A.97.97 0 0 1 5 9h14a.97.97 0 0 1 .712.287Q20 9.575 20 10c0 .425-.096.52-.288.713A.97.97 0 0 1 19 11z"
      />
    </svg>
  );
}

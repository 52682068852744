import { Attribute, Node } from "@tiptap/core";
import { NodeType } from "../type";

export interface PreviewContainerNodeProps {
  scale: number;
}

export const PreviewContainerNode = Node.create({
  name: NodeType.PREVIEW_CONTAINER,
  group: "block",
  content: `${NodeType.PREVIEW_PAGE}*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: { [key in keyof PreviewContainerNodeProps]: Attribute } = {
      scale: {
        default: 1,
      },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { scale } = HTMLAttributes as PreviewContainerNodeProps;

    return [
      "div",
      {
        style: `display: flex; flex-direction: column; gap: ${
          scale * 0.5
        }rem; padding: ${
          scale * 5
        }rem 0; cursor: default; pointer-events: none; `,
      },
      0,
    ];
  },
});

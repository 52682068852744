import { useBoolean, useDisclosure } from "@chakra-ui/react";
import { HandleSelectDrawImageProps } from "components/modal/DrawToolModal";
import {
  InspectionItemType,
  MapInspectionItemColor,
  ModalType,
  SystemModeType,
} from "constants/enum";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTask } from "interfaces/models/documentTask";
import { SizePosition } from "interfaces/models/rnd";
import { getTasksFromFilter } from "models/task";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setCreateTask, setModalType } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import {
  fetchTaskTypes,
  setStatusCreateTask,
  setTaskSelected,
} from "redux/taskSlice";
import { uuid } from "utils/common";
import { clearForgeSelection, selectDbIds } from "utils/forge";
import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { clearSelectedLabel } from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";
import { getLocalStorageUser } from "utils/user";

interface Props {
  documentTasksByLevel: DocumentTask[];
  searchValue: string;
  clickInfo?: ClickInfo | null;
  imageURL: any;
  mapTaskType: Map<string, string>;
}

export default function useTask({
  documentTasksByLevel,
  searchValue,
  clickInfo,
  imageURL,
  mapTaskType,
}: Props) {
  const { systemMode, levelSelected, isCreateTask } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { settings } = useSelector((state: RootState) => state.user);
  const imageCaptured = useRef<File>();
  const [isOpenCameraModal, setOpenCameraModal] = useBoolean();
  const [isOpenDraw, setOpenDraw] = useBoolean();
  const { bimFileId } = useParams();
  const dispatch = useDispatch();
  const { tasks, isFetchedTaskType, taskIdsCreated } = useSelector(
    (state: RootState) => state.task
  );
  const { documentCategories, documentItemSelected } = useSelector(
    (state: RootState) => state.document
  );
  const navigate = useNavigate();

  const {
    isOpen: isOpenWarningCreateTaskModal,
    onOpen: onOpenWarningCreateTaskModal,
    onClose: onCloseWarningCreateTaskModal,
  } = useDisclosure();

  useEffect(() => {
    if (
      !isFetchedTaskType &&
      (systemMode === SystemModeType.Task || documentItemSelected)
    ) {
      dispatch(fetchTaskTypes());
    }
  }, [isFetchedTaskType, systemMode, documentItemSelected, dispatch]);

  useEffect(() => {
    if (systemMode !== SystemModeType.Task) {
      return;
    }
    if (clickInfo && isCreateTask && clickInfo.forgeData?.position) {
      if (!levelSelected.guid && !clickInfo.forgeData.dbId) {
        const hasPersist = localStorage.getItem("close-warning-create-task");
        if (!hasPersist) {
          onOpenWarningCreateTaskModal();
        }
      } else {
        if (imageURL.current) {
          setOpenDraw.on();
        } else {
          handleCreateTask();
        }
      }

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo]);

  const filterTasks = useMemo(() => {
    const res = getTasksFromFilter({
      tasks,
      settings,
      searchValue,
      documentTasks: documentTasksByLevel,
      mapTaskType,
      documentCategories,
      taskIdsCreated,
    });

    return res;
  }, [
    tasks,
    settings,
    searchValue,
    documentTasksByLevel,
    mapTaskType,
    documentCategories,
    taskIdsCreated,
  ]);

  const handleCreateTask = async () => {
    logDev("handleCreateTask", clickInfo);
    if (!clickInfo || !clickInfo.forgeData) {
      return;
    }
    clearSelectedLabel();
    if (systemMode === SystemModeType.Task && clickInfo.forgeData.externalId) {
      selectDbIds(clickInfo.forgeData.externalId, {
        color:
          MapInspectionItemColor[
            InspectionItemType.Defect as InspectionItemType
          ],
      });
    }
    const task = {
      dbId: clickInfo.forgeData.dbId,
      externalId: clickInfo.forgeData.externalId,
      position: {
        x: clickInfo.forgeData.position.x,
        y: clickInfo.forgeData.position.y,
        z: clickInfo.forgeData.position.z,
      },
      rotation: clickInfo.forgeData.rotation
        ? {
            x: clickInfo.forgeData.rotation.x,
            y: clickInfo.forgeData.rotation.y,
            z: clickInfo.forgeData.rotation.z,
          }
        : undefined,
      bimFileId,
      fov: clickInfo.forgeData.fov || "",
      direction: clickInfo.forgeData.direction || "",
      level: levelSelected.label || clickInfo.forgeData.level,
      tempId: uuid(),
      userCreated: getLocalStorageUser()?.id!,
    } as TaskDTO;

    dispatch(setStatusCreateTask(true));
    dispatch(setTaskSelected(task));
    dispatch(setModalType(ModalType.TASK));
    imageURL.current = "";
  };

  const handleSaveFileDrawled = ({ file }: HandleSelectDrawImageProps) => {
    imageCaptured.current = file;
    handleCreateTask();
  };

  const handleCameraError = () => {
    setOpenCameraModal.off();
    handleCreateTask();
  };

  const handleCloseTaskModal = () => {
    clearSelectedLabel();
    getAreaExtension()?.clearSelection();
    dispatch(setModalType(ModalType.NONE));
    dispatch(setTaskSelected());
    selectDbIds([], {});
    if (!isCreateTask) {
      clearForgeSelection();
    }
    imageCaptured.current = undefined;
  };

  const onToggleCreateTask = () => {
    dispatch(setCreateTask(!isCreateTask));
  };

  const handleCapture = async (file: File, pos: SizePosition) => {
    imageCaptured.current = file;
    imageURL.current = "";
    setOpenDraw.on();
  };

  const handleRecapture = () => {
    setOpenDraw.off();
    setOpenCameraModal.on();
  };

  const handleCloseDrawToolModal = useCallback(() => {
    setOpenDraw.off();
    imageURL.current = "";

    // remove string query
    navigate(window.location.pathname, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpenWarningCreateTaskModal,
    filterTasks,
    isOpenCameraModal,
    isOpenDraw,
    setOpenCameraModal,
    setOpenDraw,
    imageCaptured,
    handleCloseDrawToolModal,
    onToggleCreateTask,
    handleSaveFileDrawled,
    handleCameraError,
    handleCloseTaskModal,
    handleCapture,
    handleRecapture,
    onCloseWarningCreateTaskModal,
  };
}

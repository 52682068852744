import { Box, SlideFade } from "@chakra-ui/react";
import { PREVIEW_DOCUMENT_CATEGORY_BOX_ID } from "constants/styleProps";
import { useMemo } from "react";

interface Props {
  isOpen: boolean;
  isScreenWidthValid: boolean;

  onClose: () => void;
  children: React.ReactNode;
}

const PreviewDocumentSlideFade = (props: Props) => {
  const { isOpen, children, isScreenWidthValid, onClose } = props;

  const isCollapseLeftBar = useMemo(() => {
    if (!isOpen) return false;

    return localStorage.getItem("isCollapsedLeftBar") === "true";
  }, [isOpen]);

  return (
    <>
      <SlideFade
        id="preview-document-category-slidefade"
        in={isOpen}
        offsetY="20px"
      >
        <Box
          id={PREVIEW_DOCUMENT_CATEGORY_BOX_ID}
          position="absolute"
          zIndex={121}
          w={`${
            isScreenWidthValid && !isCollapseLeftBar
              ? `calc(100vw - 440px)`
              : `100vw`
          }`}
          maxW="unset"
          h={`calc(100vh - var(--header-height))`}
          right="0"
          top="0"
          p="0"
          overflow="hidden"
          mt={0}
          mb={0}
          bg="transparent"
          opacity={isOpen ? "1" : "0"}
          flexDir="row"
          ml={isScreenWidthValid ? "37rem" : "0px"}
          transform="none"
        >
          <Box
            h="100%"
            onClick={onClose}
            position="fixed"
            top={0}
            left={0}
            zIndex={122}
          />
          {children}
        </Box>
      </SlideFade>
    </>
  );
};

export default PreviewDocumentSlideFade;

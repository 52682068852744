import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { __familyInstances } from "utils/forge/data";

function useFamilyInstance() {
  const { isLoadedFamilyInstances } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const familyInstances = useMemo(() => {
    if (!isLoadedFamilyInstances) {
      return {};
    }

    return __familyInstances;
  }, [isLoadedFamilyInstances]);

  return { familyInstances, isLoadedFamilyInstances };
}

export default useFamilyInstance;

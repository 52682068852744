import { Attribute, Node } from "@tiptap/core";
import { NodeType } from "../type";

export interface PageComponentNodeAttrs {
  width: number;
  height: number;
  top: number;
  left: number;
}

export const PageComponentNode = Node.create({
  name: NodeType.PAGE_COMPONENT,
  group: "block",
  content: `(${NodeType.TABLE_COMPONENT} | ${NodeType.IMAGE_UPLOADED} | ${NodeType.KEYNOTE} | ${NodeType.TEXT_BOX})*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: { [key in keyof PageComponentNodeAttrs]: Attribute } = {
      width: { default: 0 },
      height: { default: 0 },
      top: { default: 0 },
      left: { default: 0 },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { width, height, top, left } =
      HTMLAttributes as PageComponentNodeAttrs;

    return [
      "div",
      {
        style: `position: absolute; height: ${height}px; width: ${width}px; top: ${top}px; left: ${left}px`,
      },
      0,
    ];
  },
});

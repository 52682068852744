import { Box, Stack, Text } from "@chakra-ui/react";
import SubTableComponentPreview, {
  iSubTableComponentPreviewProps,
} from "components/editor-builder/component-preview/TableComponentPreview/SubTableComponentPreview";
import Checkbox from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/Checkbox";
import CheckboxContainer from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/CheckboxContainer";
import TextContainer, {
  iTextContainer,
} from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/TextContainer";
import { DATE_JAPAN_FORMAT } from "constants/date";
import {
  CellProperty,
  CheckboxDirectionOptionsEnum,
  CheckboxDisplayOptionsEnum,
  ContentType,
  DocumentStatusType,
  DocumentTemplateType,
  FamilyInstanceKey,
  LinkedDataField,
  LinkedDynamicFieldsType,
  TextPosition,
} from "constants/enum";
import dayjs from "dayjs";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FileUploadInfo } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import {
  CellType,
  DynamicFieldMeasure,
  TemplateComponent,
} from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import { iCommonDocumentTemplateProps } from "interfaces/models/documentTemplate";
import { FamilyInstance } from "interfaces/models/familyInstance";
import { User } from "interfaces/models/user";
import { formatDate, formatDateCurrent } from "utils/date";
import { getDateModifiedDateFile } from "utils/file";
import NormalTextPreview from "../../NormalTextPreview";

interface iHandleDynamicFieldCategoryContent {
  cell: CellType;
  documentCategorySelected: DocumentCategoryDTO | undefined;
  documentItemSelected?: DocumentItemDTO;
  listUserById: { [key: string]: User | null } | undefined;
}

interface iRenderContentTypeCommon {
  contentType: ContentType;
  contentCommonProps: iCommonDocumentTemplateProps;
  cell: CellType;
  familyInstances: { [key: string]: FamilyInstance };
  documentItem: DocumentItemDTO;
  documentItems: DocumentItem[];
  component: TemplateComponent;
  subTableComponentPreviewProps: iSubTableComponentPreviewProps;
}

interface iRenderCommentManageExecuteText {
  cells: CellType[];
  familyInstance: FamilyInstance;
  zoomRatio: number;
  component: TemplateComponent;
}

interface iGetContentTypeComment {
  field: string | undefined;
  documentItem: DocumentSubItemDTO;
  blackBoard?: Blackboard;
  projectName?: string;
  listOptionUsers: { label: string; value: string }[];
  listAllUserById: { [key: string]: User | null } | undefined;
}

export const getDataTypeDynamicFieldAverageValue = ({
  cellLinkedMeasureValue = [],
  itemData,
  itemId,
}: {
  cellLinkedMeasureValue: string[];
  itemData: Record<string, any>;
  itemId: string;
}) => {
  const cellIds = cellLinkedMeasureValue?.filter((cellId) => {
    const value: any = Number(itemData?.[`${cellId}-${itemId}`] || 0);

    return !isNaN(value);
  });
  const isHasMeasureAverageValue = cellIds.every((cellId) => {
    const valueStr = itemData?.[`${cellId}-${itemId}`];
    if (valueStr === "") {
      return false;
    }
    const value: any = Number(valueStr);

    return !isNaN(value);
  });

  let measureAverageValue = "";
  if (isHasMeasureAverageValue) {
    measureAverageValue = parseFloat(
      (
        cellIds.reduce(
          (sum, cellId) =>
            sum + Number(Number(itemData?.[`${cellId}-${itemId}`] || 0)),
          0
        ) / (cellIds?.length || 1)
      ).toFixed(2)
    ).toString();
  }

  return measureAverageValue;
};

export const handleDynamicFieldCategoryContent = ({
  cell,
  documentCategorySelected,
  documentItemSelected,
  listUserById,
}: iHandleDynamicFieldCategoryContent) => {
  const docCateId = documentCategorySelected?.id || "";
  const docItemId = documentItemSelected?.id || "";
  const isModuleChiller =
    documentCategorySelected?.documentType ===
    DocumentTemplateType.EQUIPMENT_DATA_SHEET;
  const documentItem = documentCategorySelected?.documentItems?.find(
    (item) => item.id === documentItemSelected?.id
  );
  const mapDynamicFieldCateValue =
    documentCategorySelected?.data || ({} as any);
  const mapDynamicFieldDocItemValue =
    isModuleChiller && documentItem ? documentItem?.data || {} : undefined;
  const categoryKey = `${cell?.cellId}-${docCateId}`;
  const documentItemKey = `${cell?.cellId}-${docItemId}`;
  const dynamicFieldCateValue = mapDynamicFieldCateValue?.[categoryKey] || "";
  const dynamicFieldDocItemValue =
    mapDynamicFieldDocItemValue?.[documentItemKey] || "";
  const dynamicFieldValue = !isModuleChiller
    ? dynamicFieldCateValue
    : dynamicFieldDocItemValue;

  const dynamicFieldType = cell?.cellLinkedData?.options?.dynamicFieldType;
  switch (dynamicFieldType) {
    case LinkedDynamicFieldsType.NUMBER:
    case LinkedDynamicFieldsType.CHECK:
    case LinkedDynamicFieldsType.TEXT:
    case LinkedDynamicFieldsType.FIXED_VALUE:
      cell.value = dynamicFieldValue;
      break;

    case LinkedDynamicFieldsType.MEASURE_AVERAGE_VALUE:
      cell.value = getDataTypeDynamicFieldAverageValue({
        cellLinkedMeasureValue:
          cell?.cellLinkedData?.options?.cellLinkedMeasureValue || [],
        itemData: !isModuleChiller
          ? mapDynamicFieldCateValue
          : mapDynamicFieldDocItemValue,
        itemId: !isModuleChiller ? docCateId : docItemId,
      });
      break;

    case LinkedDynamicFieldsType.DATE_TIME:
      cell.value = dayjs(dynamicFieldValue).isValid()
        ? formatDateCurrent(
            dynamicFieldValue,
            cell?.cellLinkedData?.options?.dateFormat
          )
        : "";
      break;

    case LinkedDynamicFieldsType.MEASURE:
      cell.value = dynamicFieldValue?.totalValue || "";
      break;

    case LinkedDynamicFieldsType.USER_DROPDOWN:
      cell.value = listUserById?.[dynamicFieldValue]?.name || "";
      break;

    case LinkedDynamicFieldsType.WEATHER:
      if (mapDynamicFieldCateValue) {
        const key = Object.keys(mapDynamicFieldCateValue)?.find((key) =>
          key.includes(cell?.cellId)
        );

        if (key) {
          cell.value = mapDynamicFieldCateValue?.[key];
        }
      }
      break;

    default:
      cell.value = dynamicFieldValue;

      break;
  }

  return cell;
};

interface iHandleDynamicFieldItemContent {
  cell: CellType;
  headerComponent: CellType[];
  isSubCell: boolean;
  cellIndex: number;
  subCellIndex: number;
  headerTitleId: string;
  documentItem: DocumentItemDTO;
  listUserById: { [key: string]: User | null } | undefined;
}

export const handleDynamicFieldItemContent = ({
  cell,
  headerComponent,
  isSubCell,
  cellIndex,
  subCellIndex,
  headerTitleId,
  documentItem,
  listUserById,
}: iHandleDynamicFieldItemContent) => {
  // if it's sub table then take last row
  const lastRowIndex = isSubCell
    ? (headerComponent?.[cellIndex]?.subTable?.rows?.length || 1) - 1
    : 0;

  const idHeaderSub = isSubCell
    ? headerComponent?.[cellIndex]?.subTable?.rows?.[lastRowIndex].cells?.[
        subCellIndex
      ]?.cellId
    : headerTitleId;
  const cellHeader = isSubCell
    ? headerComponent?.[cellIndex]?.subTable?.rows?.[lastRowIndex].cells?.[
        subCellIndex
      ]
    : headerComponent?.[cellIndex];
  const data = (documentItem?.data || {}) as {
    [key: string]: string;
  };

  const dynamicFieldItem: any =
    data[`${idHeaderSub}-${documentItem?.id}`] || "";

  const dynamicFieldType =
    cellHeader?.cellLinkedData?.options?.dynamicFieldType;
  switch (dynamicFieldType) {
    case LinkedDynamicFieldsType.NUMBER:
    case LinkedDynamicFieldsType.TEXT_AREA:
    case LinkedDynamicFieldsType.CHECK:
    case LinkedDynamicFieldsType.TEXT:
      cell.value = dynamicFieldItem;
      break;

    case LinkedDynamicFieldsType.FIXED_VALUE:
      break;

    case LinkedDynamicFieldsType.MEASURE_AVERAGE_VALUE:
      cell.value = getDataTypeDynamicFieldAverageValue({
        cellLinkedMeasureValue:
          cellHeader?.cellLinkedData?.options?.cellLinkedMeasureValue || [],
        itemData: data,
        itemId: documentItem?.id,
      });
      break;

    case LinkedDynamicFieldsType.DATE_TIME:
      cell.value = dayjs(dynamicFieldItem).isValid()
        ? formatDateCurrent(
            dynamicFieldItem,
            cell?.cellLinkedData?.options?.dateFormat
          )
        : "";
      break;

    case LinkedDynamicFieldsType.USER_DROPDOWN:
      cell.value = listUserById?.[dynamicFieldItem]?.name || "";

      break;

    case LinkedDynamicFieldsType.MEASURE:
      const dynamicFieldMeasure: DynamicFieldMeasure = dynamicFieldItem || {};
      cell.value = dynamicFieldMeasure?.totalValue || "";

      break;

    default:
      cell.value = dynamicFieldItem;
      break;
  }

  return cell;
};

export const renderNormalText = (
  data: CellType,
  component: TemplateComponent,
  textContainerProps?: iTextContainer
) => {
  const { style = {}, ...textContainerPropsRest } = textContainerProps || {};

  return (
    <TextContainer
      key={data?.cellId}
      data-field-name={data.cellLinkedData?.field}
      autoReduceSize={!!component?.detail?.allowDynamicAdjustFontSize || false}
      alignText={data.style?.justifyContent || TextPosition.CENTER}
      style={{
        height: "fit-content",
        maxHeight: `${data?.height! - (data?.style?.borderColor ? 1 : 0)}px`,
        overflowWrap: "break-word",
        minWidth: "fit-content",
        ...style,
      }}
      {...textContainerPropsRest}
    >
      {String(data?.value || "")?.replace(/ /g, "\u00A0")}
    </TextContainer>
  );
};

export const renderCellPropertyCheckbox = (
  data: CellType,
  component: TemplateComponent,
  opt?: {
    contentType?: ContentType;
    documentItem?: DocumentItemDTO;
  }
) => {
  const options = (
    data.cellLinkedData?.options?.valueOfCheckbox?.split("・") || []
  ).filter((i) => !!i);
  const numberOfCheckbox = data?.cellLinkedData?.options?.numberOfCheckbox || 1;
  const isMultipleCheckbox = numberOfCheckbox > 1;

  if (!data.cellId) {
    return <></>;
  }

  const dynamicFieldValues = (opt?.documentItem?.data || {}) as {
    [key: string]: string;
  };
  const key = `${data.cellId}-${opt?.documentItem?.id}`;
  const value = dynamicFieldValues[key] || data.value;

  const renderDefault = () => {
    return (
      <CheckboxContainer
        style={
          isMultipleCheckbox
            ? {
                flexDirection:
                  (data?.cellLinkedData?.options
                    ?.directionOfCheckbox as CheckboxDirectionOptionsEnum) ||
                  CheckboxDirectionOptionsEnum.HORIZONTAL,
                alignItems:
                  (data?.cellLinkedData?.options?.directionOfCheckbox ||
                    CheckboxDirectionOptionsEnum.HORIZONTAL) ===
                  CheckboxDirectionOptionsEnum.HORIZONTAL
                    ? data.style?.justifyContent
                    : TextPosition.CENTER,
                justifyContent: "space-around",
              }
            : {
                flexDirection: "row",
                justifyContent:
                  data.style?.justifyContent || TextPosition.CENTER,
                alignItems: data.style?.justifyContent || TextPosition.CENTER,
              }
        }
      >
        {Array.apply(null, new Array(numberOfCheckbox)).map((_, key) => {
          let checked = value === options[key];
          if (numberOfCheckbox === 1) {
            checked = value === "true";
          }

          if (opt?.contentType === ContentType.SELF_INSPECTION) {
            const status = opt?.documentItem?.status;
            checked = status === DocumentStatusType.Complete;
            const isNoneLabel = !options[key];

            if (isMultipleCheckbox && isNoneLabel) {
              checked = false;
            }

            if (options[key]) {
              const value = options[Number(status) - 1];
              checked = value === options[key];
            }
          }

          return (
            <Checkbox
              key={key}
              checked={checked}
              label={options[key]}
              data-option-value={options[key]}
            />
          );
        })}
      </CheckboxContainer>
    );
  };

  switch (data.cellLinkedData?.options?.displayCheckbox) {
    case CheckboxDisplayOptionsEnum.Value:
      return (
        <NormalTextPreview
          data-field-type={`cb-type-${data.cellLinkedData?.options?.displayCheckbox}`}
          data={{
            ...data,
            value,
          }}
          style={{
            textAlign:
              data.style?.justifyContent || (TextPosition.CENTER as any),
          }}
        />
      );
    case CheckboxDisplayOptionsEnum.Options:
      if (!options.length) {
        return renderDefault();
      }

      return (
        <Stack
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            width: "100%",
          }}
          divider={<Text className="custom-divider">・</Text>}
        >
          {options.map((option, index) => (
            <NormalTextPreview
              key={index}
              data-field-type={`cb-type-${data?.cellLinkedData?.options?.displayCheckbox}`}
              data-option-value={option}
              style={{
                textAlign:
                  data.style?.justifyContent || (TextPosition.CENTER as any),
                padding: "5px",
                width: "fit-content",
              }}
              className={
                data?.value === option
                  ? "check-box-options-active"
                  : "check-box-options-inactive"
              }
              data={{ ...data, value: option }}
            />
          ))}
        </Stack>
      );

    default:
      return renderDefault();
  }
};

export const getValueFromFamilyInstace = (
  linkedData: FamilyInstanceKey | undefined,
  familyInstance: FamilyInstance
) => {
  const fixedValue = (value: string) => {
    const floatValue = parseFloat(value || "0").toFixed(2);

    return String(+floatValue);
  };

  const roundValue = (value: string) => {
    const floatValue = parseFloat(value || "0");

    return String(+Math.round(floatValue * 10) / 10);
  };

  switch (linkedData) {
    case FamilyInstanceKey.LEVEL:
      return familyInstance?.level || "";

    case FamilyInstanceKey.SYMBOL:
      return familyInstance?.symbol || "";

    case FamilyInstanceKey.ROOM:
      return "";

    case FamilyInstanceKey.TYPE_NAME:
      return familyInstance?.typeName || "";

    case FamilyInstanceKey.SIZE:
      return familyInstance?.size || "";

    case FamilyInstanceKey.AIR_VOLUME:
      return familyInstance?.airVolume
        ? fixedValue(familyInstance?.airVolume)
        : "";

    case FamilyInstanceKey.OPENING_RATE:
      return familyInstance?.openingRate
        ? fixedValue(familyInstance?.openingRate)
        : "";

    case FamilyInstanceKey.FACE_WIND_SPEED:
      return familyInstance?.faceWindSpeed
        ? roundValue(familyInstance?.faceWindSpeed)
        : "";

    case FamilyInstanceKey.DIAMETER_RADIUS:
      return "";

    default:
      return "";
  }
};

export const renderContentTypeCommon = ({
  contentType,
  contentCommonProps,
  cell,
  familyInstances,
  documentItem,
  documentItems,
  component,
  subTableComponentPreviewProps,
}: iRenderContentTypeCommon) => {
  const isSubCell = !!cell?.isSubCell;
  let rowIndex = contentCommonProps?.option?.rowIndex || 0;
  let cellIndex = contentCommonProps?.option?.cellIndex || 0;
  const subRowIndex = contentCommonProps?.option?.subRowIndex || 0;
  const subCellIndex = contentCommonProps?.option?.subCellIndex || 0;
  const listUserById = contentCommonProps?.listUserById;
  const currentCell = contentCommonProps?.option?.currentCell;
  const headerComponent = contentCommonProps?.headerComponent || [];
  const numberOfRows = component?.detail?.rows?.length || 0;
  const numberOfActualCell =
    (component?.detail?.rows?.[0]?.cells?.length || 0) /
    (component?.detail?.numOfRepeatTable || 1);

  if (cell?.isRepeatedTable && cell?.repeatedTableIndex) {
    rowIndex += numberOfRows * cell.repeatedTableIndex;
    cellIndex -= numberOfActualCell * cell.repeatedTableIndex;
  }

  const headerTitleId = isSubCell
    ? headerComponent?.[cellIndex]?.subTable?.rows?.[subRowIndex]?.cells?.[
        subCellIndex
      ]?.cellId
    : headerComponent?.[cellIndex]?.cellId;
  const familyInstance = familyInstances[documentItem?.externalId || ""];

  if (cell?.subTable?.rows?.length) {
    return <SubTableComponentPreview {...subTableComponentPreviewProps} />;
  }

  let data = structuredClone(cell);

  switch (data?.cellProperty) {
    case CellProperty.TEXT:
      if ("cellData" in cell && (cell as any).cellData === CellProperty.NO) {
        break;
      }

      return <NormalTextPreview data={data} />;

    case CellProperty.DYNAMIC_FIELDS_FOR_ITEM: {
      data = handleDynamicFieldItemContent({
        cell: data,
        headerComponent,
        isSubCell,
        cellIndex,
        subCellIndex,
        headerTitleId: headerTitleId || "",
        documentItem,
        listUserById,
      });

      break;
    }

    case CellProperty.FAMILY_INSTANCE: {
      data.value = getValueFromFamilyInstace(
        data.cellLinkedData?.field as FamilyInstanceKey,
        familyInstance
      );

      if (data.cellLinkedData?.field === FamilyInstanceKey.SIGN) {
        data.value = component?.detail?.isRepeatTable
          ? familyInstances[
              (documentItems?.[Number(currentCell?.rowIndex) - 1] as any)
                ?.externalId || ""
            ]?.sign
          : familyInstances[documentItem?.externalId || ""]?.sign;
      }

      if (
        data.cellLinkedData?.field ===
        FamilyInstanceKey.ESTIMATE_CONSTRUCTION_CATEGORY
      ) {
        data.value = component?.detail?.isRepeatTable
          ? familyInstances[
              (documentItems?.[Number(currentCell?.rowIndex) - 1] as any)
                ?.externalId || ""
            ]?.estimateConstructionCategory
          : familyInstances[documentItem?.externalId || ""]
              ?.estimateConstructionCategory;
      }

      break;
    }

    default:
      break;
  }

  if (!documentItem) return;

  if (
    (contentType === ContentType.EQUIPMENT_DATA_SHEET &&
      data?.cellProperty !== CellProperty.FAMILY_INSTANCE) ||
    (contentType === ContentType.PAC && rowIndex >= documentItems?.length)
  ) {
    return null;
  }

  switch (data?.cellProperty) {
    case CellProperty.FAMILY_INSTANCE:
      return <NormalTextPreview data={data} />;

    case CellProperty.DYNAMIC_FIELDS_FOR_ITEM:
      switch (data?.cellLinkedData?.options?.dynamicFieldType) {
        case LinkedDynamicFieldsType.CHECK: {
          return renderCellPropertyCheckbox(data, component, {
            documentItem,
            contentType,
          });
        }

        default:
          return <NormalTextPreview data={data} />;
      }
  }
};

export const renderCommentManageExecuteText = ({
  cells,
  familyInstance,
  zoomRatio,
  component,
}: iRenderCommentManageExecuteText) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: `${0.5 * zoomRatio}rem`,
        overflow: "hidden",
      }}
    >
      {cells?.map((cellOfBlackboard) => {
        const familyInstanceValue = getValueFromFamilyInstace(
          cellOfBlackboard?.cellLinkedData?.field as FamilyInstanceKey,
          familyInstance
        );
        let value = cellOfBlackboard?.value;

        if (cellOfBlackboard?.cellProperty === CellProperty.FAMILY_INSTANCE) {
          value = familyInstanceValue;
        }

        return renderNormalText(
          {
            ...cellOfBlackboard,
            value,
            style: {
              ...cellOfBlackboard?.style,
              justifyContent:
                cellOfBlackboard?.style?.justifyContent || TextPosition.START,
            },
          } as CellType,
          component,
          {
            style: { padding: 0, width: "100%", flexShrink: 0 },
            "data-comment-manage-execute": "true",
            parentLoop: 1,
          } as any
        );
      })}
    </Box>
  );
};

export const getFormatContentTypeDate = (value?: Date) => {
  if (!value) {
    return "";
  }

  return formatDate(value, DATE_JAPAN_FORMAT);
};

export const getContentTypeComment = ({
  field,
  documentItem,
  blackBoard,
  projectName,
  listAllUserById,
}: iGetContentTypeComment) => {
  if (!field) {
    return "";
  }

  let takeImageDates = "" as any;
  if (documentItem?.takeImageDates?.length) {
    const mDate = dayjs(documentItem?.takeImageDates?.[0] ?? "");
    if (mDate.isValid()) {
      takeImageDates = formatDate(documentItem?.takeImageDates?.[0]);
    }
  } else {
    takeImageDates = documentItem?.takeImageDates;
  }

  switch (field) {
    case LinkedDataField.DOCUMENT_ITEM.TITLE:
      return documentItem?.title;

    case LinkedDataField.DOCUMENT_ITEM.LEVEL:
      return documentItem?.level;

    case LinkedDataField.DOCUMENT_ITEM.TAKE_IMAGE_DATES:
      return takeImageDates;

    case LinkedDataField.BLACKBOARD.SHOOTING_FLOOR_TEXT:
      return blackBoard?.shootingFloorText?.trim() || documentItem?.level;

    case LinkedDataField.BLACKBOARD.START_TIME:
      return blackBoard?.startTime;

    case LinkedDataField.BLACKBOARD.SHOOTING_TIME:
      return documentItem?.images?.src && blackBoard?.shootingTime
        ? getFormatContentTypeDate(new Date(blackBoard.shootingTime))
        : "";

    case LinkedDataField.BLACKBOARD.END_TIME:
      return blackBoard?.endTime;

    case LinkedDataField.BLACKBOARD.CONSTRUCTION_NAME_TEXT:
      return projectName;

    case LinkedDataField.BLACKBOARD.CONSTRUCTION_ADDRESS_TEXT:
      return blackBoard?.[field as keyof Blackboard] || documentItem?.level;

    case LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE:
      break;

    case LinkedDataField.BLACKBOARD.SUPERVISOR_TEXT:
    case LinkedDataField.BLACKBOARD.USER_EXECUTE_TEXT:
      return blackBoard?.[field];

    case LinkedDataField.BLACKBOARD.IMAGE_UPLOAD_INFO:
      const imageInfo: FileUploadInfo = documentItem?.images || {
        src: "",
      };
      const user = imageInfo?.userUpload
        ? listAllUserById?.[imageInfo?.userUpload]?.name || "-"
        : "-";

      return (
        `${user} \n ${getDateModifiedDateFile(imageInfo)?.replaceAll(
          " ",
          "\n"
        )}` || "-"
      );

    default:
      return blackBoard?.[field as keyof Blackboard] || "";
  }
};

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconCheck(props: IconProps) {
  return (
    <Icon width="2.6rem" height="2.6rem" viewBox="0 0 26 26" {...props}>
      <g
        fill="#2185d0"
        stroke="#2185d0"
        strokeWidth="2"
        transform="translate(2 2)"
      >
        <circle data-name="stroke" cx="11" cy="11" r="11" stroke="none" />
        <circle cx="11" cy="11" r="12" fill="none" />
      </g>
      <path
        fill="#fff"
        d="m10.734 18.062-4.593-4.593a.707.707 0 0 1 0-1l1-1a.707.707 0 0 1 1 0l3.093 3.096 6.627-6.627a.707.707 0 0 1 1 0l1 1a.707.707 0 0 1 0 1l-8.127 8.124a.707.707 0 0 1-1 0"
      />
    </Icon>
  );
}

import { authApi } from "apiClient/v2";
import { InspectionItemType, MapInspectionItemColor } from "constants/enum";
import {
  DEFAULT_BOUND_2D,
  DEFAULT_BOUND_3D,
  DEFAULT_HEIGHT_ELEMENT,
  FIT_TO_VIEW_OFFSET,
  GRAY_OUT_OPACITY,
  GRAY_OUT_THEME_COLOR,
  RATIO_ZOOM_ON_FAMILY,
} from "constants/forge";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { Vector3 } from "interfaces/models";
import { FamilyInstance } from "interfaces/models/familyInstance";
import store from "redux/store";
import { logError } from "utils/logs";
import {
  covertToDbIds,
  getAllDbIds,
  getPropertiesByDbIds,
  traverseDbId,
} from "./data";
import {
  getLabelExtension,
  selectLabel,
  updateLabel,
} from "./extensions/custom-label";
import {
  calculatePositionOnSheet,
  find2DBounds,
  ___sheetTransformMatrix,
  ___viewer2d,
} from "./forge2d";
import { find3DBounds, ___viewer3d } from "./forge3d";

export let ___currentThemeColor: THREE.Vector4 | undefined = undefined;

export interface iSetSelectionMutilColorByDbId {
  viewer?: Autodesk.Viewing.GuiViewer3D;
  selections: { dbId: number | undefined; color: THREE.Vector4 }[];
}

export interface FamilyDataOnLevel {
  families: FamilyInstance[];
}

export interface LevelInfoGenerated {
  hasPac: boolean;
  hasSleeve: boolean;
  hasFlexible: boolean;
}

export const setThemingColor = (color?: THREE.Vector4) => {
  ___currentThemeColor = color;
};

export const showElements = async (showMain: boolean, showLinked: boolean) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }
  try {
    const dataInstances = await getPropertiesByDbIds(getAllDbIds());
    const main: number[] = [];
    const linked: number[] = [];
    for (const item of dataInstances) {
      if (!item.dbId || !item.externalId) {
        continue;
      }
      if (item?.externalId?.includes("/")) {
        linked.push(item.dbId);
      } else {
        main.push(item.dbId);
      }
    }
    if (showMain) {
      viewer.show(main);
    } else {
      viewer.hide(main);
    }

    if (showLinked) {
      viewer.show(linked);
    } else {
      viewer.hide(linked);
    }
  } catch (err) {
    logError(err);
  }
};

export const getCurrentViewer = () => {
  return ___viewer2d || ___viewer3d;
};

export const zoomInOut = (zoomScale: number) => {
  const viewer = getCurrentViewer();
  const dollyTarget = viewer?.navigation.getWorldPoint(0.5, 0.5)!;
  viewer?.navigation.dollyFromPoint(zoomScale, dollyTarget);
};

export const displayObjects = (show: boolean, dbIds: number[]) => {
  const viewer = getCurrentViewer();
  if (show) {
    viewer?.show(dbIds);
  } else {
    viewer?.hide(dbIds);
  }
};

export const setSelectionColor = ({
  viewer,
  color,
  shouldRender,
}: {
  viewer?: Autodesk.Viewing.GuiViewer3D;
  color: THREE.Color | string;
  shouldRender?: boolean;
}) => {
  if (!viewer) {
    viewer = getCurrentViewer();
  }
  if (!viewer?.model) {
    return;
  }

  const selectionColor = new THREE.Color(color);
  if (viewer.model.is2d()) {
    viewer.set2dSelectionColor(selectionColor, 1);
  } else {
    viewer.setSelectionColor(selectionColor, 0);
  }
  if (shouldRender) {
    viewer.impl.invalidate(true);
  }
};

export const createColor = (color: string) => {
  const { b, g, r } = new THREE.Color(color);

  return new THREE.Vector4(r, g, b);
};

export const handleGrayOut3d = (viewer: Autodesk.Viewing.GuiViewer3D) => {
  ___currentThemeColor = new THREE.Vector4(0.8, 0.8, 0.8, 1);
  traverseDbId((id) => {
    viewer.model.setThemingColor(id, ___currentThemeColor!);
  });
};

const handleGrayOut2d = (viewer: Autodesk.Viewing.GuiViewer3D) => {
  const fragments = viewer.model.getFragmentList();
  if (!fragments.dbIdOpacity) {
    return;
  }
  ___currentThemeColor = createColor(GRAY_OUT_THEME_COLOR);
  traverseDbId((id) => {
    viewer.model.setThemingColor(id, ___currentThemeColor!);
    fragments.dbIdOpacity[id] = GRAY_OUT_OPACITY;
  });
};

export const selectDbIds = (
  ids: number | string | number[] | string[] | undefined,
  options: { color?: string }
) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || !viewer?.impl) return;
  grayScaleForgeViewer(viewer);
  const dbIds = covertToDbIds(ids);
  if (options?.color) {
    setSelectionColor({ viewer, color: new THREE.Color(options.color) });
  } else {
    setSelectionColor({ viewer, color: new THREE.Color(0.4, 0.6, 1) });
  }
  viewer.select(dbIds);
  viewer.impl.invalidate(true, true);
};

export const clearSelectionId = (dbId: number, isResetColor = true) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || !viewer?.impl) return;
  const fragments = viewer.model.getFragmentList();
  viewer.select(viewer?.getSelection().filter((id) => id !== dbId));
  if (isResetColor && ___currentThemeColor) {
    viewer.model.setThemingColor(dbId, ___currentThemeColor!, true);
    fragments.dbIdOpacity[dbId] = 1;
  }
  viewer.impl.invalidate(true, true);
};

export const clearForgeSelection = (viewer?: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer) {
    viewer = getCurrentViewer();
  }
  if (!viewer?.model || !viewer?.impl) {
    return;
  }
  if (!!___currentThemeColor) {
    ___currentThemeColor = undefined;
    setSelectionColor({ viewer, color: new THREE.Color(0.4, 0.6, 1) });
    viewer.clearThemingColors(viewer.model);
    const fragments = viewer.model.getFragmentList();
    traverseDbId((id) => {
      fragments.dbIdOpacity[id] = 1;
    });
  }
  if (!!viewer.getSelectionCount()) {
    viewer.clearSelection();
  }
  viewer.impl.invalidate(true, true);
};

export const fitToViewByDbId = (
  ids?: number | string | number[] | string[],
  familyInstance?: FamilyInstance,
  imediate: boolean = true
) => {
  const viewer = getCurrentViewer();
  const dbIds = covertToDbIds(ids);
  if (!viewer || !dbIds.length || (___viewer2d && !___sheetTransformMatrix))
    return;

  viewer.fitToView(dbIds, undefined, imediate);
  const y = familyInstance?.bounds?.max?.y || DEFAULT_HEIGHT_ELEMENT;
  zoomInOut(RATIO_ZOOM_ON_FAMILY * y);
};

export const fitToViewByPositions = (
  positions: Vector3[] | THREE.Vector3[],
  imediate: boolean = true
) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || (___viewer2d && !___sheetTransformMatrix)) {
    return;
  }
  const is2d = viewer.model.is2d();

  const pos = positions.map((pos) => {
    const absolutePos = is2d ? calculatePositionOnSheet(pos) : pos;

    return new THREE.Vector3(absolutePos.x, absolutePos.y, absolutePos.z);
  });

  const bounds = viewer.model.is2d() ? DEFAULT_BOUND_2D : DEFAULT_BOUND_3D;
  let bound = new THREE.Box3().setFromPoints(pos);
  bound = new THREE.Box3(
    new THREE.Vector3(
      bound.min.x - bounds.size().x,
      bound.min.y - bounds.size().y,
      bound.min.z
    ),
    new THREE.Vector3(
      bound.max.x + bounds.size().x,
      bound.max.y + bounds.size().y,
      bound.max.z
    )
  );
  viewer.navigation.fitBounds(imediate, bound);
};

export const fitToViewByPosition = (
  position: Vector3 | THREE.Vector3,
  imediate: boolean = true,
  dbId?: number,
  offset = FIT_TO_VIEW_OFFSET
) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || (___viewer2d && !___sheetTransformMatrix)) {
    return;
  }

  const bound = new THREE.Box3(
    new THREE.Vector3(
      position.x - offset.x,
      position.y - offset.y,
      position.z - offset.z
    ),
    new THREE.Vector3(
      position.x + offset.x,
      position.y + offset.y,
      position.z + offset.z
    )
  );

  if (viewer?.model?.is2d() && ___sheetTransformMatrix) {
    bound.applyMatrix4(___sheetTransformMatrix);
  }

  if (dbId) {
    const instanceTree = viewer.model.getData().instanceTree;
    const fragList = viewer.model.getFragmentList();
    if (instanceTree && !!instanceTree.getNodeIndex(dbId) && fragList) {
      const objectBound = viewer.model.is2d()
        ? find2DBounds(fragList, instanceTree, dbId)
        : find3DBounds(fragList, instanceTree, dbId);
      bound.max.x = Math.max(bound.max.x, objectBound.max.x);
      bound.max.y = Math.max(bound.max.y, objectBound.max.y);
      bound.max.z = Math.max(bound.max.z, objectBound.max.z);
      bound.min.x = Math.min(bound.min.x, objectBound.min.x);
      bound.min.y = Math.min(bound.min.y, objectBound.min.y);
      bound.min.z = Math.min(bound.min.z, objectBound.min.z);
    }
  }

  viewer.navigation.fitBounds(imediate, bound);
};

export const isInsideSectionBox = (point: THREE.Vector3) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }

  const cutPlanes = viewer
    .getCutPlanes()
    .filter((plane) => Math.abs(plane.z) < 1000000000);
  let validPlane = 0;
  for (const cutPlane of cutPlanes) {
    if (
      cutPlane.x * point.x +
        cutPlane.y * point.y +
        cutPlane.z * point.z +
        cutPlane.w <
      0
    ) {
      validPlane++;
    }
  }

  return validPlane === cutPlanes.length;
};

export const fitViewerToCanvasHeight = (
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  if (!viewer?.model) {
    return;
  }

  const bound = viewer.model.getBoundingBox();
  const center = bound.getCenter();
  bound.min.x = center.x;
  bound.max.x = center.x;

  if (!!viewer.model.is2d()) {
    bound.max.y = viewer.model.getMetadata("page_dimensions", "page_height");
    bound.min.y = 0;
  }

  viewer.navigation.fitBounds(true, bound);
};

export const setCameraToTop = (viewer?: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer?.model) {
    return;
  }
  const bound = viewer.model.getBoundingBox();
  const target = new THREE.Vector3(
    bound.getCenter().x,
    bound.getCenter().y,
    bound.min.z
  );
  const position = new THREE.Vector3(
    bound.getCenter().x,
    bound.getCenter().y,
    bound.max.z + 120
  );
  viewer.navigation.setPosition(position);
  viewer.navigation.setTarget(target);
  viewer.navigation.orientCameraUp(true);
  viewer.autocam.setCurrentViewAsHome(true);
};

export const setCameraToTopStart = (viewer?: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer?.model) {
    return;
  }
  const bound = viewer.model.getBoundingBox();
  const target = new THREE.Vector3(
    bound.getCenter().x + (bound.max.z - bound.min.z) / 2,
    bound.getCenter().y + (bound.max.z - bound.min.z) / 2,
    bound.min.z
  );
  const position = new THREE.Vector3(
    bound.min.x,
    bound.min.y,
    bound.max.z + 120
  );
  viewer.navigation.setPosition(position);
  viewer.navigation.setTarget(target);
  viewer.navigation.orientCameraUp(true);
  viewer.autocam.setCurrentViewAsHome(true);
};

const waitLastRenderByCameraChanges = async (
  viewer: Autodesk.Viewing.GuiViewer3D,
  changeCamera: () => void
) => {
  return new Promise(function (resolve) {
    const listener = function (event: any) {
      if (event.value.finalFrame) {
        viewer.removeEventListener(
          Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
          listener
        );

        resolve(true);
      }
    };

    // Wait for last render caused by camera changes
    viewer.addEventListener(
      Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
      listener
    );

    changeCamera();
  });
};

export const setCameraToHomeAsync = async (
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  const viewerUtil: any = viewer?.utilities;
  await waitLastRenderByCameraChanges(viewer, () => {
    viewerUtil?.goHome?.();
  });
};

export const setCameraToTopAsync = async (
  viewer?: Autodesk.Viewing.GuiViewer3D
) => {
  if (!viewer) {
    return;
  }

  await waitLastRenderByCameraChanges(viewer, () => {
    setCameraToTop(viewer);
  });
};

export const convertPositionWorldToClient = (pos: Vector3) => {
  const viewer = getCurrentViewer();

  return viewer?.worldToClient(new THREE.Vector3(pos.x, pos.y, pos.z));
};

export const getSizeOfModelBoundingBox = () => {
  const viewer = getCurrentViewer();
  const bounding = viewer?.model.getBoundingBox();

  const boundingMin = convertPositionWorldToClient(bounding?.min as Vector3);
  const boundingMax = convertPositionWorldToClient(bounding?.max as Vector3);

  const width = Math.abs(Number(boundingMax?.x) - Number(boundingMin?.x));
  const height = Math.abs(Number(boundingMax?.y) - Number(boundingMin?.y));

  return {
    width,
    height,
  };
};

export const overwriteHandleKeyDownFunction = (
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  const handleKeyDownEvent = (viewer.impl as any).controls.handleKeyDown;
  if (!handleKeyDownEvent) {
    return;
  }
  (viewer.impl as any).controls.handleKeyDown = function (e: any) {
    const modalType = store.getState().forgeViewer.modalType;
    if (modalType) {
      return;
    }
    handleKeyDownEvent.call(this, e);
  };
};

export const getForgeToken = async () => {
  return await authApi.getForgeToken().then((response) => {
    return {
      accessToken: response.access_token,
      expiresIn: response.expires_in,
      tokenType: response.token_type,
      expiresAt: response.expires_at,
    };
  });
};

export const getLeafFragIds = (
  model: Autodesk.Viewing.Model,
  leafId: number
) => {
  const instanceTree = model.getData().instanceTree;
  const fragIds: number[] = [];

  instanceTree.enumNodeFragments(leafId, function (fragId: number) {
    fragIds.push(fragId);
  });

  return fragIds;
};

export const pointerToRaycaster = (
  domElement: any,
  camera: any,
  pointer: any
) => {
  const pointerVector = new THREE.Vector3();
  const pointerDir = new THREE.Vector3();
  const ray = new THREE.Raycaster();

  const rect = domElement.getBoundingClientRect();

  const x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1;
  const y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1;

  if (camera.isPerspective) {
    pointerVector.set(x, y, 0.5);
    pointerVector.unproject(camera);

    ray.set(camera.position, pointerVector.sub(camera.position).normalize());
  } else {
    pointerVector.set(x, y, -1);
    pointerVector.unproject(camera);
    pointerDir.set(0, 0, -1);

    ray.set(pointerVector, pointerDir.transformDirection(camera.matrixWorld));
  }

  return ray;
};

export const setSelectionMutilColorByDbId = ({
  viewer,
  selections,
}: iSetSelectionMutilColorByDbId) => {
  if (!viewer) {
    viewer = getCurrentViewer();
  }
  if (!viewer?.model) {
    return;
  }
  grayScaleForgeViewer(viewer);
  const fragments = viewer.model.getFragmentList();

  selections.forEach(({ dbId, color }) => {
    if (dbId) {
      viewer!.model.setThemingColor(dbId, color, true);
      fragments.dbIdOpacity[dbId] = 1;
    }
  });
  getLabelExtension()?.updateLabels();
  viewer.impl.invalidate(true, true);
};

export const FONT_SIZE_DISPLAY_ORDER = {
  1: "16px",
  2: "14px",
  3: "11px",
  4: "9px",
  5: "8px",
};

export const grayScaleForgeViewer = (viewer?: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer) {
    viewer = getCurrentViewer();
  }

  if (!viewer?.model) {
    return;
  }

  const is3d = viewer.model.is3d();
  if (is3d) {
    handleGrayOut3d(viewer);
  } else {
    handleGrayOut2d(viewer);
  }
};

export const highlighTaskPin = (task: TaskDTO, title: string) => {
  // data relative to label temp will deleted in func updateLabel
  updateLabel(task.id, {
    id: task.id,
    tempId: task?.tempId,
    position: task.position,
    title: title || "-",
    indexId: task.indexId,
    showImage: Number(task?.images?.length) > 0,
    status: task?.status,
    externalId: task.externalId,
  });

  setSelectionColor({
    color:
      MapInspectionItemColor[
        (task?.status || InspectionItemType.Defect) as InspectionItemType
      ],
    shouldRender: true,
  });
  selectDbIdOfTask(task);
  selectLabel([task.id]);
};

export const selectDbIdOfTask = (task: TaskDTO) => {
  if (task.dbId) {
    selectDbIds([task.dbId], {
      color:
        MapInspectionItemColor[
          (task.status || InspectionItemType.Defect) as InspectionItemType
        ],
    });
  }
};

import { Box } from "@chakra-ui/react";
import { DEFAULT_NAVIGATOR_LENGTH } from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/KeyNote";
import {
  KEYLABEL_LENGTH_CLASSNAME,
  KEYLABEL_POINT_CLASSNAME,
} from "constants/styleProps";
import { Vector3 } from "interfaces/models";
import {
  distanceBetweenTwoPoint,
  handleMoveKeynoteElement,
} from "models/documentTask";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";

const MAX_LOOP_CHECK_KEYLABEL_IS_OUTSIDE = 50;

export interface iPositionKeyLabel {
  x: number;
  y: number;
}

export interface iKeyLabel {
  id: string;
  position: iPositionKeyLabel;
  positionFromForge?: Vector3;
  rotation: number;
  navigatorLength: number;
  label: string;
  labelLength: number;
  status?: any;
  color?: string;
  colorText?: string;
}

export const POINT_SIZE = 3;
const ICON_COLOR = "rgba(56, 161, 105, 1)";

export const ICON_SIZE = {
  width: 14,
  height: 14,
};

export const DEFAULT_MIN_NAVIGATOR_LENGTH = 28;

export const transformLabelPosition = ({
  transformRotation,
  navigatorLength,
  iconWidth,
  iconHeight,
}: {
  transformRotation: number;
  navigatorLength: number;
  iconWidth: number;
  iconHeight: number;
}) => {
  const ro =
    transformRotation > 180
      ? Math.abs(270 - transformRotation)
      : Math.abs(transformRotation - 90);

  const top =
    navigatorLength * Math.cos((ro / 180) * Math.PI) +
    iconHeight / 2 -
    POINT_SIZE / 2;

  const left =
    navigatorLength * Math.sin((ro / 180) * Math.PI) +
    iconWidth / 2 -
    POINT_SIZE / 2;

  return {
    [`${
      transformRotation > 0 && transformRotation < 180 ? "bottom" : "top"
    }`]: `-${top}px`,
    [`${
      transformRotation > 90 && transformRotation < 270 ? "left" : "right"
    }`]: `-${left}px`,
  };
};

const KeyLabel = (
  props: iKeyLabel & {
    loadingEdit?: boolean;
    isChangeRatio?: boolean;
    isEditMode?: boolean;
    imageSize: { width: number; height: number };
    isResetData?: boolean;
    callbackDragStop?: (props: Partial<iKeyLabel>) => void;
  }
) => {
  const {
    id: keylabelId,
    loadingEdit,
    isChangeRatio = false,
    isEditMode = false,
    position,
    rotation = 45,
    navigatorLength = DEFAULT_NAVIGATOR_LENGTH,
    label,
    labelLength = 2,
    color = ICON_COLOR,
    isResetData,
    colorText = "white",
    imageSize,
    callbackDragStop,
  } = props;

  const pointRef = useRef<HTMLDivElement>(null);
  const lengthRef = useRef<HTMLDivElement>(null);

  const iconWidth = useMemo(() => {
    const width = (labelLength * ICON_SIZE.width) / 2;

    return width < ICON_SIZE.width ? ICON_SIZE.width : width;
  }, [labelLength]);
  const iconHeight = useMemo(() => {
    const height = (labelLength * ICON_SIZE.height) / 2;

    return height < ICON_SIZE.height ? ICON_SIZE.height : height;
  }, [labelLength]);

  const transformRotation = useMemo(() => {
    return rotation < 0 ? 360 - Math.abs(rotation) : rotation;
  }, [rotation]);

  const navigatorLengthRef = useRef<number>();
  const labelPositionRef = useRef<{ [key: string]: string }>();

  const labelPosition = useMemo(
    () =>
      transformLabelPosition({
        transformRotation,
        navigatorLength,
        iconWidth,
        iconHeight,
      }),
    [navigatorLength, transformRotation, iconWidth, iconHeight]
  );

  useEffect(() => {
    labelPositionRef.current = labelPosition;
  }, [labelPosition]);

  useEffect(() => {
    navigatorLengthRef.current = navigatorLength;
  }, [navigatorLength]);

  // reset data keylabel when cancel edit
  useEffect(() => {
    const lineElement = lengthRef.current;
    const pointElement = pointRef.current;
    const _labelPosition = labelPositionRef.current;
    const _navigatorLength = navigatorLengthRef.current;

    if (
      !lineElement ||
      !isResetData ||
      !pointElement ||
      !_labelPosition ||
      !_navigatorLength
    ) {
      return;
    }

    lineElement.style.width = `${_navigatorLength}px`;
    lineElement.style.transform = `rotate(${transformRotation}deg)`;
    pointElement.style.top = null as any;
    pointElement.style.left = null as any;
    pointElement.style.left = null as any;
    pointElement.style.right = null as any;
    Object.keys(_labelPosition).forEach((key) => {
      pointElement.style[key as any] = _labelPosition[key];
    });
  }, [isResetData, navigatorLength, transformRotation, labelPosition]);

  const handleKeyLabelIsOutside = useCallback(
    ({
      prePositionOfPoint,
    }: {
      prePositionOfPoint: {
        x: number;
        y: number;
      };
    }) => {
      const pointElement = pointRef.current;
      const lineElement = lengthRef.current;

      if (
        !pointElement ||
        !lineElement ||
        !imageSize.width ||
        !imageSize.height
      ) {
        return;
      }

      const leftAbsolute = pointElement.offsetLeft + position.x;
      const topAbsolute = pointElement.offsetTop + position.y;
      const keynoteImageWidth = imageSize.width - iconWidth;
      const keynoteImageHeight = imageSize.height - iconHeight;
      let left = pointElement.offsetLeft;
      let top = pointElement.offsetTop;
      let flagCheck = false;

      if (leftAbsolute < 0) {
        left = -position.x;
        flagCheck = true;
      }
      if (leftAbsolute >= keynoteImageWidth) {
        left = keynoteImageWidth - position.x;
        flagCheck = true;
      }
      if (topAbsolute < 0) {
        top = -position.y;
        flagCheck = true;
      }
      if (topAbsolute >= keynoteImageHeight) {
        top = keynoteImageHeight - position.y;
        flagCheck = true;
      }

      if (!flagCheck) {
        return;
      }

      pointElement.style.top = `${top}px`;
      pointElement.style.left = `${left}px`;

      const positionOfPoint = {
        x:
          pointElement.offsetLeft +
          position.x +
          iconHeight / 2 -
          POINT_SIZE / 2,
        y: pointElement.offsetTop + position.y + iconWidth / 2 - POINT_SIZE / 2,
      };
      const navigatorLength = distanceBetweenTwoPoint(
        positionOfPoint,
        position
      );
      const edgeLength1 = distanceBetweenTwoPoint(prePositionOfPoint, position);
      const edgeLength2 = distanceBetweenTwoPoint(
        prePositionOfPoint,
        positionOfPoint
      );
      const cos =
        (Math.pow(navigatorLength, 2) +
          Math.pow(edgeLength1, 2) -
          Math.pow(edgeLength2, 2)) /
        (2 * navigatorLength * edgeLength1);
      let deg = (Math.acos(cos) * 180) / Math.PI;
      const ret = Math.sign(
        (positionOfPoint.x - position.x) * (positionOfPoint.y - position.y) -
          (positionOfPoint.y - position.y) * (positionOfPoint.x - position.x)
      );
      deg = deg * (ret || 1);
      let newRotation = transformRotation + deg;
      newRotation =
        newRotation > 360
          ? Math.abs(360 - newRotation)
          : newRotation < 0
          ? 360 - Math.abs(newRotation)
          : newRotation;

      lineElement.style.width = `${navigatorLength}px`;
      lineElement.style.transform = `rotate(${newRotation}deg)`;

      const labelPosition = transformLabelPosition({
        transformRotation,
        navigatorLength,
        iconWidth,
        iconHeight,
      });

      pointElement.style.top = null as any;
      pointElement.style.left = null as any;
      pointElement.style.left = null as any;
      pointElement.style.right = null as any;
      navigatorLengthRef.current = navigatorLength;
      labelPositionRef.current = labelPosition;
      Object.keys(labelPosition).forEach((key) => {
        pointElement.style[key as any] = labelPosition[key];
      });
    },
    [imageSize, position, iconWidth, iconHeight, transformRotation]
  );

  // handle pointElement is outside
  useEffect(() => {
    const pointElement = pointRef.current;
    const lineElement = lengthRef.current;
    let keynoteImageWidth = imageSize?.width;
    let keynoteImageHeight = imageSize?.height;

    if (
      !pointElement ||
      !lineElement ||
      !keynoteImageWidth ||
      !keynoteImageHeight ||
      isChangeRatio
    ) {
      return;
    }

    const checkIsOutside = () => {
      let flagCheck = false;

      if (!keynoteImageWidth || !keynoteImageHeight) {
        return flagCheck;
      }

      const leftAbsolute = pointElement.offsetLeft + position.x;
      const topAbsolute = pointElement.offsetTop + position.y;
      keynoteImageWidth = keynoteImageWidth - iconWidth;
      keynoteImageHeight = keynoteImageHeight - iconHeight;

      if (leftAbsolute < 0) {
        flagCheck = true;
      }
      if (leftAbsolute > keynoteImageWidth) {
        flagCheck = true;
      }
      if (topAbsolute < 0) {
        flagCheck = true;
      }
      if (topAbsolute > keynoteImageHeight) {
        flagCheck = true;
      }

      return flagCheck;
    };
    let isOutside = checkIsOutside();
    if (!isOutside) {
      return;
    }

    const prePositionOfPoint = {
      x: pointElement.offsetLeft + position.x + iconHeight / 2 - POINT_SIZE / 2,
      y: pointElement.offsetTop + position.y + iconWidth / 2 - POINT_SIZE / 2,
    };

    let maxLoop = MAX_LOOP_CHECK_KEYLABEL_IS_OUTSIDE;

    // using timeout to handle large amount of point, will not freeze the screen
    setTimeout(() => {
      while (isOutside) {
        handleKeyLabelIsOutside({ prePositionOfPoint });
        isOutside = checkIsOutside();
        if (isOutside) {
          maxLoop--;
        }

        if (maxLoop <= 0) {
          break;
        }
      }
    }, 1);
  }, [
    isChangeRatio,
    imageSize,
    position,
    iconWidth,
    iconHeight,
    handleKeyLabelIsOutside,
  ]);

  useEffect(() => {
    const pointElement = pointRef.current;
    const lineElement = lengthRef.current;

    if (
      !pointElement ||
      !lineElement ||
      !imageSize.width ||
      !imageSize.height
    ) {
      return;
    }

    if (!isEditMode || loadingEdit) {
      document.onmouseup = null;
      document.onmousemove = null;
      pointElement.onmousedown = null;

      return;
    }

    handleMoveKeynoteElement({
      id: keylabelId,
      label,
      iconWidth,
      iconHeight,
      pointElement,
      lineElement,
      imageSize,
      rotationInit: transformRotation,
      positionInit: position,
      callbackDragStop,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingEdit,
    position,
    iconWidth,
    iconHeight,
    transformRotation,
    label,
    isEditMode,
    keylabelId,
  ]);

  return (
    <Box
      style={{
        height: `${POINT_SIZE}px`,
        width: `${POINT_SIZE}px`,
        borderRadius: "50%",
        backgroundColor: color,
        position: "absolute",
        top: `${position?.y}px`,
        left: `${position?.x}px`,
        zIndex: 1,
      }}
    >
      <Box
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          id={`${KEYLABEL_LENGTH_CLASSNAME}-${label}`}
          data-rotation={transformRotation}
          ref={lengthRef}
          style={{
            width: `${navigatorLength}px`,
            height: "1px",
            borderTop: "1px solid",
            borderColor: color,
            position: "absolute",
            top: `${POINT_SIZE / 2}px`,
            left: `${POINT_SIZE / 2}px`,
            transform: `rotate(${transformRotation}deg)`,
            transformOrigin: "0",
          }}
        />

        <Box
          id={`${KEYLABEL_POINT_CLASSNAME}-${label}`}
          className={KEYLABEL_POINT_CLASSNAME}
          data-keylabel-id={keylabelId}
          data-icon-width={iconWidth}
          data-icon-height={iconHeight}
          data-position-x={position.x}
          data-position-y={position.y}
          data-label={label}
          ref={pointRef}
          style={{
            position: "absolute",
            width: `${iconWidth}px`,
            height: `${iconHeight}px`,
            backgroundColor: color,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "7px",
            color: colorText,
            fontWeight: 600,
            cursor: isEditMode ? "pointer" : "initial",
            pointerEvents: isEditMode ? "auto" : "none",
            ...labelPosition,
          }}
        >
          {label}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(KeyLabel);

import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Portal,
  Spinner,
} from "@chakra-ui/react";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import OfflineLabel from "components/ui/OfflineLabel";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import {
  RIGHT_SIDEBAR_MODAL_CLASSNAME,
  RIGHT_SIDEBAR_MODAL_CONTAINER_ID,
} from "constants/styleProps";
import useFamilyInstance from "hooks/useFamilyInstance";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  isCommissiontingTableTemplate,
  isEquipmentDataSheetTemplate,
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import FormItemDataSheet from "./FormItemDataSheet";
import FormItemPAC from "./FormItemPAC";
import FormItemPhotoLedger, {
  PhotoLedgerModalType,
} from "./FormItemPhotoLedger";
import FormItemSelfInspection from "./FormItemSelfInspection";
import useUserOfProject from "hooks/useUserOfProject";
import useMoveDocumentLable from "./hooks/useMoveDocumentLabel";
import TooltipForgeView from "components/TooltipForgeView";
import { useRoles } from "hooks/usePermission";
import IconMove from "components/icon/IconMove";

export type DocumentItemModalHandleType = {
  handleCollapse: (val?: boolean) => void;
};

interface Props extends BoxProps {
  clickInfo: ClickInfo | undefined;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  filterDocumentCategories: DocumentCategoryDTO[];
  forgeViewContainerRef: React.RefObject<HTMLDivElement>;
}

const DocumentItemModal = forwardRef<DocumentItemModalHandleType, Props>(
  (props, ref) => {
    const {
      forgeViewContainerRef,
      filterDocumentCategories,
      clickInfo,
      setClickInfo,
    } = props;

    const [photoLedgerModalType, setPhotoLedgerModalType] =
      useState<PhotoLedgerModalType>(PhotoLedgerModalType.PIN);

    const boxContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const { isCollapsed, setIsCollapsed } = useCollapseModal();
    const {
      documentCategories,
      documentCategorySelected,
      documentItemSelected,
      documentTemplates,
      isLoadedBlackboard,
      dataBlackboards,
      isLoadingDocument,
      dataLogTemp,
    } = useSelector((state: RootState) => state.document);
    const { familyInstances } = useFamilyInstance();
    const { isTakasagoGroup } = useRoles();

    const {
      levels,
      levelSelected,
      isLoadedViewer,
      isLoadedExternalId,
      isLoadedFamilyInstances,
    } = useSelector((state: RootState) => state.forgeViewer);

    const { currentUser } = useSelector((state: RootState) => state.user);

    const { listUserById, listAllUserById } = useUserOfProject();
    const {
      fromExternalLogs,
      showTooltipMoveDocumentLabel,
      toggleMoveDocumentLabelMode,
      clearFromExternalLogs,
    } = useMoveDocumentLable({ clickInfo, setClickInfo });

    const { partnerCompanies, projectDetail } = useSelector(
      (state: RootState) => state.project
    );

    const { sizePanel, isOnline } = useSelector(
      (state: RootState) => state.app
    );

    const isSelfInspection = isSelfInspectionTemplate(
      documentCategorySelected?.documentType
    );
    const isPhotoLedger = isPhotoLedgerTemplate(
      documentCategorySelected?.documentType
    );
    const isEquipmentDataSheet = isEquipmentDataSheetTemplate(
      documentCategorySelected?.documentType
    );
    const isCommissiontingTable = isCommissiontingTableTemplate(
      documentCategorySelected?.documentType
    );

    const usersAssigned = useMemo(
      () => Object.values(listUserById),
      [listUserById]
    );

    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );

    const documentTemplate = useMemo(
      () => documentTemplates?.[documentCategorySelected?.templateId || ""],
      [documentCategorySelected?.templateId, documentTemplates]
    );

    const familyInstance = useMemo(
      () => familyInstances?.[documentItemSelected?.externalId || ""],
      [documentItemSelected?.externalId, familyInstances]
    );

    const itemStatusColor = useMemo(() => {
      const status = (documentItemSelected?.status ||
        DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType;

      return MapDocumentCategoryStatusTypeColor[status];
    }, [documentItemSelected]);

    const itemDisplayOrder = useMemo(() => {
      if (!documentItemSelected?.id || !documentCategorySelected?.id) {
        return "";
      }
      const category = filterDocumentCategories.find(
        (cate) => cate.id === documentCategorySelected.id
      );
      if (!category) {
        return "";
      }

      return (
        (category?.documentItems || [])?.findIndex(
          (item) => item.id === documentItemSelected.id
        ) + 1
      );
    }, [
      documentItemSelected,
      documentCategorySelected,
      filterDocumentCategories,
    ]);

    const isShowIconToggleMoveDocumentLabel = useMemo(() => {
      if (!isTakasagoGroup) {
        return false;
      }

      if (isSelfInspection) {
        return false;
      }

      if (isPhotoLedger) {
        return photoLedgerModalType === PhotoLedgerModalType.PIN;
      }

      return true;
    }, [
      isTakasagoGroup,
      isSelfInspection,
      photoLedgerModalType,
      isPhotoLedger,
    ]);

    useEffect(() => {
      if (!showTooltipMoveDocumentLabel) {
        return;
      }

      if (isSelfInspection) {
        toggleMoveDocumentLabelMode();
      }

      if (isPhotoLedger && photoLedgerModalType === PhotoLedgerModalType.SUB) {
        toggleMoveDocumentLabelMode();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isPhotoLedger,
      photoLedgerModalType,
      isSelfInspection,
      showTooltipMoveDocumentLabel,
    ]);

    useImperativeHandle(ref, () => ({
      handleCollapse: (val = true) => {
        if (val) {
          setIsCollapsed(true);
        } else {
          setIsCollapsed(false);
        }
      },
      onClosePreview: () => {},
    }));

    const handleCollapse = () => {
      setIsCollapsed((prev) => !prev);
    };

    const onChangePhotoLedgerModalType = useCallback(
      (type: PhotoLedgerModalType) => {
        setPhotoLedgerModalType(type);
      },
      []
    );

    return (
      <Box
        className={RIGHT_SIDEBAR_MODAL_CLASSNAME}
        w={isCollapsed ? "0" : widthPanel}
        position="absolute"
        right={0}
        height="100%"
        zIndex={11}
        transition="all 200ms ease-in-out 0s"
        boxShadow="0px 4px 8px 3px #00000026"
      >
        <Box
          ref={boxContainerRef}
          backgroundColor="white"
          p="0"
          height="100%"
          color="var(--primary-text-color)"
          position="relative"
        >
          <CollapseModalIcon
            backgroundColor="white"
            borderRadius="5px 0px 0px 5px"
            borderRightStyle="hidden"
            borderLeftStyle="solid"
            height="12rem"
            width="3.8rem"
            top="50%"
            iconProps={{
              transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
            }}
            transform="translateY(-50%)"
            onClose={handleCollapse}
          />

          <Box
            id={RIGHT_SIDEBAR_MODAL_CONTAINER_ID}
            ref={containerRef}
            height="100%"
            overflowY="auto"
            width="100%"
            paddingTop={documentCategorySelected?.blockedBy ? "5rem" : "1.6rem"}
            gap="0.8rem"
          >
            {/* offline label */}
            {documentCategorySelected?.blockedBy && (
              <Box
                marginBottom="1.6rem"
                position="absolute"
                left="0"
                top="0"
                zIndex="sticky"
                width="100%"
              >
                <OfflineLabel
                  minHeight="3.6rem"
                  p="0.5rem 1.6rem"
                  blockedBy={documentCategorySelected?.blockedBy}
                />
              </Box>
            )}

            {isShowIconToggleMoveDocumentLabel && (
              <IconButton
                display="flex"
                ml="auto"
                mr="2rem"
                aria-label="move"
                onClick={toggleMoveDocumentLabelMode}
                variant="text"
                paddingInline="0.4rem"
                icon={
                  <IconMove
                    sx={{
                      path: {
                        fill: showTooltipMoveDocumentLabel
                          ? "#009be0"
                          : "#171717",
                      },
                    }}
                    width="2rem"
                    height="2rem"
                  />
                }
              />
            )}

            {documentItemSelected?.isLoadingUpdateImage && (
              <Box
                position="absolute"
                inset="0px"
                backgroundColor="rgba(0,0,0,0.2)"
                zIndex={999}
              ></Box>
            )}

            {isLoadingDocument ? (
              <Flex
                position="absolute"
                inset="0px"
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="1000"
                backgroundColor="white"
              >
                <Spinner color="blue.500" size="lg" mt="1rem" />
              </Flex>
            ) : (
              <>
                {isPhotoLedger && (
                  <FormItemPhotoLedger
                    dataLogTemp={dataLogTemp}
                    isOnline={isOnline}
                    itemStatusColor={itemStatusColor}
                    familyInstance={familyInstance}
                    documentTemplate={documentTemplate}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    documentCategories={documentCategories}
                    users={usersAssigned}
                    listAllUserById={listAllUserById}
                    listUserById={listUserById}
                    currentUser={currentUser}
                    levels={levels}
                    levelSelected={levelSelected}
                    partnerCompanies={partnerCompanies}
                    projectName={projectDetail.name}
                    isLoadedViewer={isLoadedViewer}
                    isLoadedBlackboard={isLoadedBlackboard}
                    dataBlackBoards={dataBlackboards}
                    itemDisplayOrder={itemDisplayOrder}
                    fromExternalLogs={fromExternalLogs}
                    clearFromExternalLogs={clearFromExternalLogs}
                    onChangePhotoLedgerModalType={onChangePhotoLedgerModalType}
                  />
                )}

                {isEquipmentDataSheet && (
                  <FormItemDataSheet
                    dataLogTemp={dataLogTemp}
                    isOnline={isOnline}
                    itemStatusColor={itemStatusColor}
                    isLoadedViewer={isLoadedViewer}
                    documentTemplate={documentTemplate}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    itemDisplayOrder={itemDisplayOrder}
                    fromExternalLogs={fromExternalLogs}
                    clearFromExternalLogs={clearFromExternalLogs}
                  />
                )}

                {isSelfInspection && (
                  <FormItemSelfInspection
                    itemStatusColor={itemStatusColor}
                    isLoadedViewer={isLoadedViewer}
                    isLoadedExternalId={isLoadedExternalId}
                    isLoadedFamilyInstances={isLoadedFamilyInstances}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    documentTemplate={documentTemplate}
                    clickInfo={clickInfo}
                    isOnline={isOnline}
                    itemDisplayOrder={itemDisplayOrder}
                    setClickInfo={setClickInfo}
                  />
                )}

                {isCommissiontingTable && (
                  <FormItemPAC
                    dataLogTemp={dataLogTemp}
                    isOnline={isOnline}
                    itemStatusColor={itemStatusColor}
                    isLoadedViewer={isLoadedViewer}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    documentTemplate={documentTemplate}
                    itemDisplayOrder={itemDisplayOrder}
                    fromExternalLogs={fromExternalLogs}
                    clearFromExternalLogs={clearFromExternalLogs}
                  />
                )}

                {showTooltipMoveDocumentLabel && (
                  <Portal containerRef={forgeViewContainerRef}>
                    <TooltipForgeView
                      title="書類ピン移動モード"
                      description="※移動先をクリックしてください。"
                    />
                  </Portal>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default DocumentItemModal;

import { Box, Flex, Image, Progress, Text } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { SvgIcon } from "components/SvgIcon";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIcon,
  MapInspectionItemType,
  Priority,
  PriorityBgColor,
  PriorityFontColor,
  PriorityIcon,
  PriorityOption,
} from "constants/enum";
import { errorImagePath } from "constants/file";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { taskLabel } from "utils/forge/extensions/custom-label/task";

const ImagePresigned = withPresignedUrl(Image);

interface Props {
  task: TaskDTO;
  contentTypeChanged?: string;
  taskImageHeight?: number;
}

enum IMAGE_KEYS {
  FORGE_IMAGE,
  TASK_IMAGE,
}

const TaskItem = ({
  task,
  contentTypeChanged,
  taskImageHeight = 230,
}: Props) => {
  const [mapStatusAllImage, setMapStatusAllImage] = useState<{
    [key: string]: boolean;
  }>({});
  const [isForgeImageError, setIsForgeImageError] = useState(false);
  const { taskTypes } = useSelector((state: RootState) => state.task);

  const name = useMemo(() => {
    return (
      contentTypeChanged ||
      taskTypes.find((item) => item.id === task.taskTypeId)?.title ||
      "-"
    );
  }, [contentTypeChanged, task.taskTypeId, taskTypes]);

  const label = useMemo(() => {
    const taskElement = taskLabel(
      {
        id: task.id,
        position: task.position,
        title: name,
        indexId: task.indexId,
        showImage: Number(task.images?.length) > 0,
        status: task.status,
      },
      { isDisableLabelClick: true }
    );

    return taskElement[0];
  }, [
    name,
    task.images?.length,
    task.indexId,
    task.position,
    task.status,
    task.id,
  ]);

  const onErrorForgeImage = (e: any) => {
    (e as any).target.src = errorImagePath;
    setIsForgeImageError(true);
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.FORGE_IMAGE]: true,
    }));
  };

  const onErrorTaskImage = (e: any) => {
    (e as any).target.src = errorImagePath;
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE]: true,
    }));
  };

  const onLoadForgeImage = () => {
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.FORGE_IMAGE]: true,
    }));
  };

  const onLoadTaskImage = () => {
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE]: true,
    }));
  };

  const renderLoadingImage = useCallback((isLoading: boolean) => {
    if (!isLoading) {
      return <></>;
    }

    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        width="100%"
        height="100%"
        bg="gray"
      >
        <Progress size="xs" isIndeterminate width="60%" />
      </Flex>
    );
  }, []);

  const InspectionIcon =
    MapInspectionItemIcon[
      (task?.status ||
        InspectionItemType.Defect) as keyof typeof MapInspectionItemIcon
    ];

  return (
    <Box w="100%" p="1rem" className="task-item-container" overflowX="hidden">
      <Flex
        alignItems="center"
        lineHeight="0.5!important"
        className="custom-text-download"
      >
        <Box
          h={30}
          backgroundColor="#F2F2F2"
          borderRadius="4px"
          px="1.4rem"
          mr="1rem"
          lineHeight="0.5!important"
          display="flex"
          alignItems="center"
        >
          <Text h="20px" lineHeight="1.5" fontWeight="bold">
            #{task.indexId}
          </Text>
        </Box>
        <Box>
          <Box
            bg={
              MapInspectionItemColor[
                (task.status || InspectionItemType.Defect) as InspectionItemType
              ]
            }
            h={30}
            color="#fff"
            borderRadius="4px"
            py=".3rem"
            px="1rem"
            mr="1rem"
            lineHeight="0.5!important"
            display="flex"
            alignItems="center"
          >
            <InspectionIcon />
            <Text lineHeight="1.5" h="20px">
              {
                MapInspectionItemType[
                  (task.status ||
                    InspectionItemType.Defect) as InspectionItemType
                ]
              }
            </Text>
          </Box>
        </Box>
        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              padding: "2.5px 10px",
              borderRadius: "4px",
              height: "30px",
              color:
                PriorityFontColor[(task.priority || Priority.High) as Priority],
              backgroundColor:
                PriorityBgColor[(task.priority || Priority.High) as Priority],
            }}
          >
            <SvgIcon
              src={PriorityIcon[(task.priority || Priority.High) as Priority]}
              style={{
                height: "2.6rem",
              }}
            />
            <Text
              style={{
                lineHeight: "1.5",
                height: "20px",
              }}
            >
              {PriorityOption[(task.priority || Priority.High) as Priority]}
            </Text>
          </Box>
        </Box>
      </Flex>

      <Flex
        my="1rem"
        h="3rem"
        alignItems="center"
        gap="1rem"
        className="box-title"
      >
        <Text
          className="box-title-name"
          fontSize="1.8rem"
          fontWeight="bold"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
            wordBreak: "break-word",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          {name}
        </Text>
      </Flex>

      <Flex w="100%">
        <Flex
          position="relative"
          w="calc(50% - 8px)"
          h={`${taskImageHeight}px`}
          mr="16px"
          bgColor="rgb(218 218 218 / 9%)"
          border="1px solid #e2e8f0"
          justifyContent="center"
          borderRadius="4px"
        >
          {task?.images?.length ? (
            <>
              {renderLoadingImage(!mapStatusAllImage?.[IMAGE_KEYS.TASK_IMAGE])}
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <ImagePresigned
                  src={task.images[0].src}
                  alt=""
                  crossOrigin="anonymous"
                  className="image-item"
                  display="block"
                  maxH={`${taskImageHeight}px`}
                  boxLoadingHeight={`${taskImageHeight}px`}
                  onLoad={onLoadTaskImage}
                  onError={onErrorTaskImage}
                />
              </Flex>
            </>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              h={`${taskImageHeight}px`}
              width="100%"
              textAlign="center"
            >
              <Text>写真なし</Text>
            </Flex>
          )}
        </Flex>

        <Box
          position="relative"
          w="calc(50% - 8px)"
          h={`${taskImageHeight}px`}
          border="1px solid #e2e8f0"
          borderRadius="4px"
        >
          {renderLoadingImage(!mapStatusAllImage?.[IMAGE_KEYS.FORGE_IMAGE])}

          <Flex
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <ImagePresigned
              src={task.forgeImage}
              alt=""
              crossOrigin="anonymous"
              mr="8px"
              m="auto"
              maxH={`${taskImageHeight}px`}
              boxLoadingHeight={`${taskImageHeight}px`}
              className="image-item"
              display="block"
              onLoad={onLoadForgeImage}
              onError={onErrorForgeImage}
            />
          </Flex>

          {!isForgeImageError && task.forgeImage && (
            <Box
              position="absolute"
              left="50%"
              top={`calc(50% - 5.8rem)`}
              transform="translate(0,-50%)"
              ref={(ref: any) => {
                ref?.appendChild(label);
              }}
              sx={{
                label: {
                  display: "flex",
                },

                ".label-item-displayTitle": {
                  maxW: 150,
                  display: "block",
                },
              }}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default React.memo(TaskItem);

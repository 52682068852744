import {
  clearForgeSelection,
  fitViewerToCanvasHeight,
  getCurrentViewer,
  getSizeOfModelBoundingBox,
  setCameraToHomeAsync,
  setCameraToTopAsync,
  setSelectionMutilColorByDbId,
} from "utils/forge";
import store from "redux/store";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import { ModalType } from "constants/enum";
import { sleep } from "utils/common";
import { resetState } from "redux/forgeViewerSlice";
import {
  clearHighlightMultipleObject,
  highlightMultipleObject,
} from "utils/forge/forge3d";
import { getLabelExtension } from "utils/forge/extensions/custom-label";
import { logError } from "utils/logs";

const CAPTURE_VIEWER_IMAGE_WIDTH = 2_000;

export default class CreateDocHelper {
  async captureKeynote(selections: { dbId: number; color: string }[] = []) {
    const viewer = getCurrentViewer();
    if (!viewer?.model) {
      return;
    }

    clearForgeSelection(viewer);
    let blobUrl = "";
    let imageWidth = 0;
    let imageHeight = 0;
    const isHasTaskSelected = !!store.getState().task.taskSelected?.id;
    const docCateModalEle = document.getElementById(
      RIGHT_SIDEBAR_MODAL_CLASSNAME
    );
    const modalType = store.getState().forgeViewer.modalType;
    const isModalTask = modalType === ModalType.TASK;

    const showOrHideModalElement = (isShow = false) => {
      if (docCateModalEle) {
        docCateModalEle.style.display = isShow ? "flex" : "none";
      }
    };

    try {
      showOrHideModalElement(false);
      await sleep(300);
      const isResetState = isHasTaskSelected && isModalTask;

      // gray out forge viewer and highlight object of keynote

      if (!!viewer?.model?.is2d()) {
        const newSelections = selections.map((item) => {
          const { r, g, b } = new THREE.Color(item.color);

          return { ...item, color: new THREE.Vector4(r, g, b, 1) };
        });

        setSelectionMutilColorByDbId({ viewer, selections: newSelections });
        const {
          width: widthOfModelBoundingBox,
          height: heightOfModelBoundingBox,
        } = getSizeOfModelBoundingBox();

        let width = Math.floor(widthOfModelBoundingBox);
        let height = Math.floor(heightOfModelBoundingBox);

        const ratio = width / height;
        width = CAPTURE_VIEWER_IMAGE_WIDTH;
        height = Math.floor(width / ratio);

        if (isResetState) {
          store.dispatch(resetState());
          viewer.select(undefined);
        }

        fitViewerToCanvasHeight(viewer);

        imageWidth = width;
        imageHeight = height;

        blobUrl = await new Promise((resolve) => {
          viewer.getScreenShot(width, height, (blobUrl: string) => {
            resolve(blobUrl);
          });
        });
      } else {
        if (isResetState) {
          store.dispatch(resetState());
        }
        viewer.select(undefined);
        highlightMultipleObject(selections);
        await setCameraToHomeAsync(viewer);
        await setCameraToTopAsync(viewer);

        imageWidth = viewer.canvas.clientWidth;
        imageHeight = viewer.canvas.clientHeight;

        blobUrl = await new Promise((resolve) => {
          viewer.getScreenShot(imageWidth, imageHeight, (blobUrl: string) => {
            resolve(blobUrl);
          });
        });
        const dbIds = selections.map((item) => item.dbId);
        clearHighlightMultipleObject(dbIds);
      }
      // reset position of pin in forge view
      getLabelExtension()?.updateLabels();

      showOrHideModalElement(true);
    } catch (e) {
      logError("captureViewer", e);
      showOrHideModalElement(true);
    }

    return { blobUrl, canvas: viewer.canvas, imageWidth, imageHeight };
  }
}

import { BoxProps } from "@chakra-ui/react";
import {
  Axis,
  PaperDirectionType,
  PaperType,
  SystemMode,
  SystemModeType,
  TemplateComponentType,
  TextPosition,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { NeptuneArea } from "interfaces/models/area";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import { KeynoteImageData } from "interfaces/models/documentKeynote";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import { getSizeOfComponent } from "pages/document/template-page/utils";
import { useCallback } from "react";
import { RootState } from "redux/store";
import {
  ImageUploadedNodeAttrs,
  PageComponentNodeAttrs,
  PageContentNodeAttrs,
  PreviewContainerNodeProps,
  PreviewPageNodeAttrs,
  TextBoxNodeAttrs,
} from "../extendsions";
import { KeynoteNodeAttrs } from "../extendsions/KeynoteNode";
import {
  CustomNode,
  MAP_PADDING_PREVIEW_PAGER_TYPE,
  NodeType,
  PageInfo,
} from "../type";
import { getContentNodeTypeNormalText } from "../utils";
import useGetStructureTableComponentNode from "./useGetStructureTableComponentNode";

interface Props {
  scale: number;
  pageKeynoteDirection: PaperDirectionType | undefined;
  pageKeyNoteId: string | undefined;
  hasKeyNote: boolean;
  keynoteAxis: string;
  systemMode: string;
  template: DocumentTemplate | undefined;
  documentType: string | undefined;
  documentContainerSize: RootState["document"]["documentContainerSize"];

  documentItems: DocumentItem[] | BlackBoardInfo[];
  blackboardTemplateProps?: iBlackboardTemplateProps;
  projectName: string;
  listUserById?: {
    [key: string]: User | null;
  };
  familyInstances: { [key: string]: FamilyInstanceDTO };
  documentCategorySelected: DocumentCategoryDTO | undefined;
  companiesById: Record<string, PartnerCompany>;
  neptuneAreas: NeptuneArea[] | undefined;
  keynoteImageDataProp?: KeynoteImageData;
}

const useGetStructureNode = (props: Props) => {
  const {
    scale,
    pageKeynoteDirection,
    pageKeyNoteId,
    hasKeyNote,
    keynoteAxis,
    systemMode,
    template,
    documentType,
    documentContainerSize,
    documentItems,
    projectName,
    blackboardTemplateProps,
    listUserById,
    familyInstances,
    documentCategorySelected,
    companiesById,
    neptuneAreas,
    keynoteImageDataProp,
  } = props;

  const { getStructureTableComponent } = useGetStructureTableComponentNode({
    template,
    documentContainerSize,
    zoomRatio: scale,
    documentItems,
    projectName,
    blackboardTemplateProps,
    listUserById,
    familyInstances,
    documentCategorySelected,
    companiesById,
    neptuneAreas,
  });

  // page component
  const getStructurePageComponentNode = useCallback(
    (params: {
      page: PageInfo;
      components: TemplateComponent[];
      isReverseAxis: boolean;
    }): CustomNode<PageComponentNodeAttrs>[] => {
      const { page, components, isReverseAxis } = params;

      const node: CustomNode<PageComponentNodeAttrs>[] = [];
      components.forEach((component) => {
        let { width, height } = getSizeOfComponent({
          component,
          documentType,
          numberItemPerPage: page.numberPerPage,
          limit: page.limitItemTableLinked,
        });

        if (height <= 1) {
          return;
        }

        let top = component?.detail?.isFullsize
          ? component?.realPosition?.y + 1
          : component?.realPosition?.y;
        let left = component?.realPosition?.x;

        if (isReverseAxis && systemMode === SystemMode[SystemModeType.Task]) {
          [height, width] = [width, height];
          [top, left] = [left, top];
        }

        let content: CustomNode<any>[] = [];
        let structure = null;
        switch (component.type) {
          case TemplateComponentType.LinkedImage:
          case TemplateComponentType.FilterPhoto:
          case TemplateComponentType.Table:
          case TemplateComponentType.TableHeader: {
            structure = getStructureTableComponent({ component, page });
            break;
          }

          case TemplateComponentType.ImageUploaded: {
            structure = {
              type: NodeType.IMAGE_UPLOADED,
              attrs: {
                src: component?.detail?.imageUploaded?.src || "",
                componentHeight: height,
                componentWidth: width,
              },
            } satisfies CustomNode<ImageUploadedNodeAttrs>;
            break;
          }

          case TemplateComponentType.Image: {
            if (component?.detail?.checkedImage) {
              structure = {
                type: NodeType.KEYNOTE,
                attrs: {
                  keynoteImageDataProp,
                  componentHeight: height,
                  componentWidth: width,
                },
              } satisfies CustomNode<KeynoteNodeAttrs>;
            }

            break;
          }

          case TemplateComponentType.Text: {
            structure = {
              type: NodeType.TEXT_BOX,
              attrs: {
                width,
                height,
                style: component.detail?.style,
              },
              content: [
                getContentNodeTypeNormalText({
                  text: component?.detail?.value,
                  style: component?.detail?.style,
                  height,
                  isJustify:
                    component?.detail?.style?.justifyContent ===
                    TextPosition.JUSTIFY,
                }),
              ],
            } satisfies CustomNode<TextBoxNodeAttrs>;
            break;
          }

          default:
            break;
        }

        if (structure) {
          content = [structure];
        }

        node.push({
          attrs: { width, height, top, left },
          type: NodeType.PAGE_COMPONENT,
          content,
        });
      });

      return node;
    },
    [keynoteImageDataProp, documentType, systemMode, getStructureTableComponent]
  );

  // page content
  const getStructurePageContentNode = useCallback(
    (params: {
      page: PageInfo;
      pageIndex: number;
    }): CustomNode<PageContentNodeAttrs> => {
      const { page, pageIndex } = params;

      const pageHasKeyNote = page.components?.some(
        (component) => component?.detail?.checkedImage
      );

      const pageHasFullsizeComponent = page.components?.some(
        (component) =>
          component.type === TemplateComponentType.Image &&
          component?.detail?.isFullsize
      );

      let width = Number(page?.pageSize?.width) * scale;
      let height = Number(page?.pageSize?.height) * scale;
      let isReverseAxis = false;
      let stylePageKeynote: BoxProps["style"] = {};
      if (hasKeyNote && pageKeyNoteId === page.pageId) {
        if (!pageHasFullsizeComponent && !pageHasKeyNote) {
          stylePageKeynote = { padding: 0 };
        }

        const axisDefault =
          pageKeynoteDirection === PaperDirectionType.VERTICAL
            ? Axis.VERTICAL
            : Axis.HORIZONTAL;

        if (axisDefault !== keynoteAxis) {
          isReverseAxis = true;
        }
      }

      // rotate page's direction
      if (isReverseAxis && systemMode === SystemMode[SystemModeType.Task]) {
        width = height;
        height = Number(page?.pageSize?.width) * scale;
      }

      const currentPage = template?.pages?.find(
        (pageOfTemplate) => pageOfTemplate?.pageId === page?.pageId
      );
      const { paddingX, paddingY } =
        MAP_PADDING_PREVIEW_PAGER_TYPE[currentPage?.pageSize ?? PaperType.A4];
      const content = getStructurePageComponentNode({
        page,
        components: page.components,
        isReverseAxis,
      }) as any;

      return {
        attrs: {
          scale,
          pageIndex,
          paddingX,
          paddingY,
          stylePageKeynote,
          width,
          height,
          isVertical: page.isVertical,
          pageSize: currentPage?.pageSize || PaperType.A4,
          pageDirection:
            currentPage?.pageDirection || PaperDirectionType.VERTICAL,
        },
        type: NodeType.PAGE_CONTENT,
        content,
      };
    },
    [
      scale,
      pageKeynoteDirection,
      pageKeyNoteId,
      hasKeyNote,
      keynoteAxis,
      systemMode,
      template?.pages,
      getStructurePageComponentNode,
    ]
  );

  // preview page
  const getStructurePreviewPageNode = useCallback(
    (params: {
      page: PageInfo;
      pageIndex: number;
    }): CustomNode<PreviewPageNodeAttrs> => {
      const pageContentNode = getStructurePageContentNode(params);

      return {
        attrs: { scale },
        content: [pageContentNode],
        type: NodeType.PREVIEW_PAGE,
      };
    },
    [scale, getStructurePageContentNode]
  );

  // preview container
  const getStructurePreviewContainerNodes = useCallback(
    (pages: PageInfo[]): CustomNode<PreviewContainerNodeProps> => {
      const contents: CustomNode<PreviewContainerNodeProps>[] = pages.map(
        (page, pageIndex) => getStructurePreviewPageNode({ page, pageIndex })
      );

      return {
        type: NodeType.PREVIEW_CONTAINER,
        attrs: { scale },
        content: contents,
      };
    },
    [scale, getStructurePreviewPageNode]
  );

  return { getStructurePreviewContainerNodes };
};

export default useGetStructureNode;

import { ApiResponse } from "interfaces/models/api";
import { User } from "interfaces/models/user";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";
import { presignedAndDownloadFileS3 } from "utils/file";

const path = "/v2/users";

export const getUserList = async (
  params: any = {},
  shouldCache = false
): Promise<ApiResponse<User[]>> => {
  return axiosECS.get(path, {
    params: {
      shouldCache,
      ...params,
    },
  });
};

const getProfile = async (): Promise<ApiResponse<User>> => {
  return axiosECS.get(`${path}/profile`, {
    params: {
      // not use cache's data in service worker
      useCache: false,
    },
  });
};

export const updateProfile = async (user: User): Promise<ApiResponse<User>> => {
  return axiosECS.patch(`${path}/profile`, validateBodyReq(user));
};

export const handleGetProfile = async (shouldCache?: boolean) => {
  const { data: user } = await getProfile();
  if (user && shouldCache && !!user.avatar) {
    await presignedAndDownloadFileS3(user.avatar, shouldCache);
  }

  return user;
};

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconDownload(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#171717"
        d="M11.999 14.9c-.1 0-.19-.02-.28-.05a.8.8 0 0 1-.22-.14l-3-3a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l1.72 1.72V6.93c0-.41.34-.75.75-.75s.75.34.75.75v5.44l1.72-1.72c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-3 3c-.06.06-.13.1-.21.13-.09.04-.19.06-.29.06zm4.501 2.748h-9c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9c.41 0 .75.34.75.75s-.34.75-.75.75"
      />
      <path
        fill="#171717"
        d="M12 22.25c-5.65 0-10.25-4.6-10.25-10.25S6.35 1.75 12 1.75 22.25 6.35 22.25 12 17.65 22.25 12 22.25m0-19c-4.82 0-8.75 3.93-8.75 8.75s3.93 8.75 8.75 8.75 8.75-3.92 8.75-8.75S16.83 3.25 12 3.25"
      />
    </Icon>
  );
}

import { UserDTO } from "interfaces/dtos/UserDTO";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const useUserOfProject = () => {
  const {
    currentUser,
    users,
    listUserAssigned,
    isFetchingUserAssigned,
    isFetchingUsers,
    isFetchedUserAssigned,
    isFetchedUsers,
  } = useSelector((state: RootState) => state.user);

  const listUserById = useMemo(() => {
    // only users assign
    const listUserById: { [key: string]: UserDTO } = {};

    listUserAssigned?.forEach((user: UserDTO) => {
      if (!user.id) return;
      listUserById[user.id] = user;
    });

    return listUserById;
  }, [listUserAssigned]);

  const listAllUserById = useMemo(() => {
    // list users assign with admin
    const listAllUserById: { [key: string]: UserDTO } = {};
    users?.forEach((user: UserDTO) => {
      if (!user.id) return;
      listAllUserById[user.id] = user;
    });

    return listAllUserById;
  }, [users]);

  return {
    currentUser,
    listAllUserById,
    listUserById,
    users,
    isFetchingUserAssigned,
    isFetchingUsers,
    isFetchedUserAssigned,
    isFetchedUsers,
  };
};

export default useUserOfProject;

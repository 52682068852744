import { documentCategoryApi, documentItemApi } from "apiClient/v2";
import {
  CellDisplayValueType,
  DEFAULT_TITLE_SLEEVE_PIPE_DOCUMENT_ITEM,
  DocumentCategoryStatusType,
  DocumentTemplateType,
  FamilyTitleDisplayType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { NeptuneArea } from "interfaces/models/area";
import {
  FamilyTitleDisplay,
  FamilyTitleDisplayForCustomDocumentItem,
  TitleDisplay,
} from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { FamilyInstance } from "interfaces/models/familyInstance";
import cloneDeep from "lodash/cloneDeep";
import {
  isSelfInspectionTemplate,
  updateDocumentCategoriesWhenHidePhoto,
} from "models/documentCategory";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { selectDbIds } from "./forge";
import { selectLabel } from "./forge/extensions/custom-label";
import { transformBodyForCombineData } from "./offline";

interface iHandleDocumentItemTitleByFamilyTitle {
  familyInstance: FamilyInstance;
  familyTitle: TitleDisplay;
}

export const transformDocumentItemTitle = ({
  documentItem,
  documentTemplate,
  familyTitleDisplayType,
  isAddedFromForge = false,
  familyInstances,
}: {
  documentTemplate: DocumentTemplate;
  documentItem: DocumentItemDTO;
  familyTitleDisplayType: FamilyTitleDisplayType;
  isAddedFromForge?: boolean;
  familyInstances: { [key: string]: FamilyInstanceDTO };
}) => {
  const familyTitleDisplayForCustomDocumentItem: FamilyTitleDisplayForCustomDocumentItem =
    documentTemplate?.familyTitleDocItem || {};
  const familyInstance = familyInstances[documentItem.externalId!];
  const objectTypes = familyInstance?.objectTypes || [];
  const familyTitleDisplay: FamilyTitleDisplay =
    documentTemplate?.familyTitle || {};
  let objectTypesOfFFamilyTitle = Object.keys(
    familyTitleDisplay?.[familyTitleDisplayType] || {}
  );
  const objectTypesId = objectTypes?.map((item) => item.id);
  objectTypesOfFFamilyTitle = objectTypesOfFFamilyTitle.filter((item) =>
    objectTypesId.includes(item)
  );

  let familyTitle =
    familyTitleDisplay?.[familyTitleDisplayType]?.[
      objectTypesOfFFamilyTitle?.[0]
    ];

  if (isAddedFromForge) {
    familyTitle =
      familyTitleDisplayForCustomDocumentItem?.[familyTitleDisplayType];
  }

  if (documentTemplate?.documentType === DocumentTemplateType.SELF_INSPECTION) {
    return documentItem.title;
  }

  const title = getDocumentItemTitleByFamilyTitle({
    familyInstance,
    familyTitle,
  });

  return title;
};

export const getDocumentItemTitleByFamilyTitle = ({
  familyInstance,
  familyTitle,
}: iHandleDocumentItemTitleByFamilyTitle) => {
  const displayType = familyTitle?.type;
  let title: any = "";

  switch (displayType) {
    case CellDisplayValueType.FAMILY_NAME:
      title = familyInstance?.name;
      break;

    case CellDisplayValueType.CUSTOM_VALUE:
      title =
        familyTitle?.customValue || DEFAULT_TITLE_SLEEVE_PIPE_DOCUMENT_ITEM;
      break;

    default:
      title = familyInstance?.symbol;
      break;
  }

  return title;
};

export const hightLightDocumentItem = (documentItem: DocumentItemDTO) => {
  if (documentItem.id) {
    selectLabel([documentItem.id]);
  }
  if (documentItem.externalId) {
    // selectLabel([`${documentItem.templateId}/${documentItem.externalId}`]);
    selectDbIds([documentItem.externalId], {
      color:
        MapDocumentCategoryStatusTypeColor[
          (documentItem.status ||
            DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
        ],
    });
  }
};

export const getDocumentItemsFromDocumentParent = (
  documentCategory: DocumentCategoryDTO
) => {
  let documentItems = documentCategory?.documentItems || [];

  if (
    documentCategory?.documentType === DocumentTemplateType.PARENT_TEMPLATE &&
    documentCategory?.childTemplates?.length
  ) {
    let newDocumentItems: DocumentItemDTO[] = [];

    documentCategory.childTemplates.forEach((doc) => {
      newDocumentItems = [...newDocumentItems, ...(doc?.documentItems || [])];
    });

    documentItems = newDocumentItems;
  }

  return documentItems;
};

export const transformDocumentItemTitleByOrder = ({
  displayOrderOfSub,
  displayOrder = 0,
  title,
}: {
  displayOrderOfSub?: number;
  displayOrder?: number;
  title?: string;
}) => {
  if (displayOrderOfSub !== undefined) {
    return `No. ${displayOrderOfSub + 1}-${displayOrder + 1} : ${title || "-"}`;
  }

  return `No. ${displayOrder + 1} : ${title || "-"}`;
};

export const checkDocumentItemUpdatedData = (documentItem: DocumentItem) => {
  const now = new Date();

  return (
    Date.parse(`${documentItem.createdAt || now}`) !==
    Date.parse(`${documentItem.updatedAt || now}`)
  );
};

export const mapItemAreaIds = ({
  item,
  familyInstances,
  areas,
}: {
  areas: NeptuneArea[];
  familyInstances: { [key: string]: FamilyInstanceDTO };
  item: DocumentItemDTO;
}) => {
  // only map when create new document items
  if (item.id || !item.externalId) return item;
  const exactExternalId = (item.externalId || "").split("/").pop();
  if (!exactExternalId) return item;

  const mapItem = cloneDeep(item);
  const isSelfInspection = isSelfInspectionTemplate(item.documentType);
  const familyInstance = isSelfInspection
    ? undefined
    : familyInstances[item.externalId || ""];
  mapItem.areaIds = [];
  areas.forEach((area) => {
    const isValid = area.externalIds.some((id) => {
      if (isSelfInspection) return id === exactExternalId;
      if (!familyInstance?.spaceIds?.length) return false;

      return familyInstance.spaceIds?.includes(id);
    });

    if (isValid) mapItem.areaIds!.push(area.id);
  });

  return mapItem;
};
export const updateForgeWhenSelectDocumentItem = (
  documentCategory: DocumentCategoryDTO,
  documentItem: DocumentItemDTO
) => {
  const selectIds = [documentItem?.id];
  if (isSelfInspectionTemplate(documentCategory?.documentType)) {
    selectIds.push(documentCategory?.id || "");
  }
  selectLabel(selectIds);
};

export const getStatusVisibleOfItem = (documentItem: DocumentItemDTO) => {
  let isHideAllItem = true;
  let isShowAllItem = true;
  (documentItem?.subItems || []).every((subItem) => {
    if (subItem?.isHidden) {
      isShowAllItem = false;
    } else {
      isHideAllItem = false;
    }

    return isShowAllItem || isHideAllItem;
  });

  return { isHideAllItem, isShowAllItem };
};

export const handleVisiblePinNotPhotoLedger = async (params: {
  isVisible: boolean;
  documentItem: DocumentItemDTO;
  requestId?: string;
  documentCategory: DocumentCategoryDTO;
  isAwait?: boolean;
}) => {
  const {
    isVisible,
    documentItem,
    requestId,
    documentCategory,
    isAwait = true,
  } = params;

  const now = new Date();
  const selectedExternalIds = new Set(
    documentCategory?.selectedExternalIds || []
  );
  isVisible
    ? selectedExternalIds.add(documentItem.id)
    : selectedExternalIds.delete(documentItem.id);

  const promise = documentCategoryApi.updateCategory(
    transformBodyForCombineData({
      body: {
        id: documentCategory.id,
        selectedExternalIds: Array.from(selectedExternalIds),
        requestId,
        updatedAt: new Date(),
      } as DocumentCategoryDTO,
      bodyBefore: documentCategory,
      typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
    })
  );
  if (isAwait) {
    await promise;
  }

  return {
    ...documentCategory,
    selectedExternalIds: Array.from(selectedExternalIds),
    updatedAt: now,
  } as DocumentCategoryDTO;
};

export const handleVisiblePinPhotoLedger = async (params: {
  isVisible: boolean;
  documentItem: DocumentItemDTO;
  requestId?: string;
  documentCategories: DocumentCategoryDTO[];
}) => {
  const {
    isVisible,
    documentItem: _documentItem,
    requestId,
    documentCategories,
  } = params;
  const documentItem = structuredClone(_documentItem);
  documentItem.subItems = documentItem.subItems?.map((subItem) => ({
    ...subItem,
    isHidden: !isVisible,
  }));

  await Promise.all([
    documentItem.subItems?.map((subItem) =>
      documentItemApi.updateSubItem({
        id: subItem.id,
        requestId,
        isHidden: subItem.isHidden,
      } as DocumentSubItemDTO)
    ),
    updateDocumentCategoriesWhenHidePhoto({
      documentItem,
      documentCategories,
      requestId,
    }),
  ]);

  return documentItem;
};

import { ApiResponse } from "interfaces/models/api";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { axiosECS } from "services/baseAxios";

const path = "/v2/bim-files";

export const getProjectList = async (): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(path);
};

export const getUsingBimFiles = async (): Promise<ApiResponse<string[]>> => {
  return axiosECS.get(`${path}/ids`);
};

export const getLastUpdatedResource = async (body: {
  bimFileId: string;
  localTime: string;
}): Promise<ApiResponse<{ lastUpdated: string; resource: string }[]>> => {
  return axiosECS.post(`${path}/last-updated-resources`, body);
};

export const createProject = async (body: any) => {
  return axiosECS.post(path, body);
};

export const updateProject = async (body: any) => {
  return axiosECS.patch(path, body);
};

export const getProject = async (
  bimFileId: string,
  useCache = false
): Promise<ApiResponse<DataProjectModel>> => {
  return axiosECS.get(`${path}/${bimFileId}`, {
    params: {
      useCache,
    },
  });
};

export const deleteBimFileList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids } });
};

import { Text } from "@chakra-ui/react";
import {
  InspectionItemType,
  MapInspectionItemType,
  TASK_PRINT_MODE,
} from "constants/enum";
import { TaskLogTypeKey } from "constants/task";
import isEqual from "lodash/isEqual";

import {
  ACTION_CHANGE_DATA,
  DEFAULT_FIELD_COLOR,
  DEFAULT_VALUE_COLOR,
  generateLogTextForDefaultPosition,
  getCommonFormatLog,
  getDateDisplay,
  getTextChangeImage,
  KeyMapCommonTaskLogType,
  MapCommonTaskLogType,
  MapTitleForPrintMode,
  MapTitleForTask,
  renderSuffixRevert,
} from "./";

import { PARTNER_ROLES, TAKASAGO_ROLES } from "constants/user";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { EditTaskModalPermission } from "interfaces/models/role";
import { TaskComment } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import { ReactNode } from "react";
import { getPermissionByRole } from "utils/user";
import { getTaskContentLog } from "models/commentLog";

type ParamsTransFormLogForTask = {
  log: TaskComment;
  user: User | null;
  listUserById: { [key: string]: UserDTO | null };
  mapTaskType: Map<string, string>;
  mapPartnerCompany: { [key: string]: string };
  taskCreatedAt: string | undefined;
};

const FIELDS_TASK_TYPE_OPTION: (keyof TaskDTO)[] = [
  "taskTypeId",
  "userAssigned",
  "userConfirmed",
  "partnerCompany",
];

const FIELDS_OF_TAKASAGA_GROUP: (keyof TaskDTO)[] = ["status", "position"];

const MapEditTaskModalPermissionByField: {
  [field in keyof Partial<TaskDTO>]: keyof EditTaskModalPermission;
} = {
  taskDate: "canEditTaskDateField",
  images: "canEditImagesField",
  confirmedImages: "canEditConfirmedImagesField",
  taskTypeId: "canEditTaskTypeField",
  tags: "canEditTagsField",
  printMode: "canEditTaskTypeField",
  memo: "canEditMemoField",
  userAssigned: "canEditAssignedUserField",
  deadline: "canEditDeadlineField",
  partnerCompany: "canEditPartnerCompaniesField",
  confirmedMemo: "canEditConfirmedMemoField",
  endDateScheduled: "canEditEndDateScheduledField",
  confirmedDateTime: "canEditConfirmedDateTimeField",
  userConfirmed: "canEditConfirmedUserField",
  attaches: "canEditAttachsField",
};

const getActionLogChangeImageForTask = (log: TaskComment) => {
  const type = log.type!;
  let action = "";
  const isEditImage = [
    TaskLogTypeKey.EDIT_IMAGE,
    TaskLogTypeKey.EDIT_POINTED_OUT_PHOTO,
  ].includes(type);
  const isAddImage = [
    TaskLogTypeKey.POINTED_OUT_PHOTO,
    TaskLogTypeKey.IMAGE,
    TaskLogTypeKey.ATTACH,
  ].includes(type);
  const isDeleteImage = [
    TaskLogTypeKey.DELETE_IMAGE,
    TaskLogTypeKey.DELETE_POINTED_OUT_PHOTO,
    TaskLogTypeKey.DELETE_ATTACH,
  ].includes(type);
  if (isAddImage) {
    action = ACTION_CHANGE_DATA.REGISTER;
  } else if (isEditImage) {
    action = ACTION_CHANGE_DATA.EDIT;
  } else if (isDeleteImage) {
    action = ACTION_CHANGE_DATA.DELETE;
  }

  return action;
};

export const getChangeValueForLogTask = (params: ParamsTransFormLogForTask) => {
  const { log, listUserById, mapTaskType, mapPartnerCompany } = params;
  const field = log.field;
  const data = log.value?.data;
  let valueAfterChange = "";
  switch (field as keyof TaskDTO) {
    case "status":
      const key = (data as InspectionItemType) || InspectionItemType.Defect;
      valueAfterChange = MapInspectionItemType?.[key] || "";
      break;
    case "confirmedDateTime":
    case "endDateScheduled":
    case "deadline":
    case "taskDate":
      valueAfterChange = getDateDisplay(data);
      break;
    case "tags":
      valueAfterChange = (data as string[])?.join(",") || "";
      break;
    case "taskTypeId":
      valueAfterChange = mapTaskType?.get(data || "") || "";
      break;
    case "userAssigned":
    case "userConfirmed":
      valueAfterChange = listUserById?.[data || ""]?.name || "";
      break;
    case "partnerCompany":
      valueAfterChange = mapPartnerCompany?.[data || ""] || "";
      break;
    case "printMode":
      const keyMap = data as keyof typeof MapTitleForPrintMode;
      valueAfterChange = MapTitleForPrintMode[keyMap];
      break;
    case "attaches":
    case "confirmedImages":
    case "images":
      valueAfterChange = "";
      break;
    default:
      valueAfterChange = data || "";
      break;
  }

  const fieldTypeMedia: (keyof TaskDTO)[] = [
    "attaches",
    "images",
    "confirmedImages",
  ];

  if (!fieldTypeMedia.includes(field as any)) {
    valueAfterChange = valueAfterChange || "";
  }

  return valueAfterChange;
};

export const checkPermissionRevertTaskLog = (
  field: keyof TaskDTO | undefined,
  user: User | null
) => {
  const isPartnerRole = PARTNER_ROLES.includes(user?.role as any);
  if (!isPartnerRole || !field) {
    return true;
  }

  const isTakasagoGroup = TAKASAGO_ROLES.includes(user?.role as any);
  if (FIELDS_OF_TAKASAGA_GROUP.includes(field) && !isTakasagoGroup) {
    return false;
  }

  const permissionKey = MapEditTaskModalPermissionByField?.[field];
  if (!permissionKey) {
    return true;
  }
  const canEditTaskModal = getPermissionByRole(user?.role).canEditTaskModal;

  return canEditTaskModal?.[permissionKey] ?? true;
};

export const transformTextForTaskLog = (params: ParamsTransFormLogForTask) => {
  const { log, user, taskCreatedAt } = params;
  const type = log.type;
  const field = log.field;
  const displayValue = log.value?.displayValue;
  const isRevertDataByLog = !!log?.value?.isRevertDataByLog;
  let logText = <Text></Text>;
  const commonLogType = MapCommonTaskLogType[type as KeyMapCommonTaskLogType];
  let isRevert = false;
  let isDisabledRevert = false;

  const isCreateTaskAtPosition =
    taskCreatedAt === log.createdAt && log.type === TaskLogTypeKey.MOVE_TASK;
  let revertTooltip = undefined;

  if (isCreateTaskAtPosition) {
    const { externalId, ...position } = log?.value?.data || {};
    logText = generateLogTextForDefaultPosition({ type: "task", position });

    revertTooltip = {
      pc: "指摘が作成された時の初期位置へ戻す",
      ipad: "指摘が作成された時の初期位置へ戻す",
    };

    isRevert = true;
  } else if (commonLogType) {
    const { title, action } = commonLogType;
    const msg = `が${title ? `${title}を` : ""}${action}しました`;
    logText = (
      <Text display="inline-block" mx="1rem">
        {msg}
        {renderSuffixRevert(isRevertDataByLog)}
      </Text>
    );
    isRevert = type === TaskLogTypeKey.MOVE_TASK;
  } else if (field && type) {
    isRevert = true;
    // common format: Ex fullMsg: Dat TranがピンをOFFへ変更しました
    let prefixAction = "へ";
    let afterLabel = "を";
    let actionType = ACTION_CHANGE_DATA.EDIT;
    const changeValue = getChangeValueForLogTask(params);
    const textChange =
      displayValue !== undefined &&
      FIELDS_TASK_TYPE_OPTION.includes(field as any)
        ? displayValue
        : changeValue;
    const isLogDefault = log.value?.isLogDefault;
    // case create element: Ex fullMsg: Dat Tranがタイトルの“FolderName”でフォルダーを作成しました。
    const isMediaField = ["attaches", "confirmedImages", "images"].includes(
      field
    );
    if (isLogDefault && textChange) {
      const titleTask = MapCommonTaskLogType[TaskLogTypeKey.CREATE_TASK].title;
      prefixAction = `で${titleTask}を`;
      afterLabel = "の";
      actionType = ACTION_CHANGE_DATA.CREATE;
    } else if (!isMediaField) {
      // case edit but empty change -> using format other
      //Ex: Dat Tranが是正内容の値 をクリアしました
      if (typeof textChange === "string" && !textChange?.trim()) {
        // case clear data
        prefixAction = "を";
        afterLabel = "の値";
        actionType = ACTION_CHANGE_DATA.CLEAR;
      }
    }
    const titleForTask = MapTitleForTask[field as keyof typeof MapTitleForTask];
    const labelOfField = titleForTask?.title || "";
    const fieldColor = titleForTask?.fieldColor || DEFAULT_FIELD_COLOR;
    const valueColor = titleForTask?.valueColor || DEFAULT_VALUE_COLOR;
    let action = `${actionType}しました`;
    let moreText: ReactNode = "";
    if (isMediaField) {
      const actionChange = getActionLogChangeImageForTask(log);
      action = `${actionChange}しました。`;
      prefixAction = "";
      moreText = getTextChangeImage(actionChange, fieldColor, labelOfField);
    }
    logText = getCommonFormatLog({
      action: prefixAction + action,
      changeValue: textChange,
      fieldColor,
      valueColor,
      labelOfField,
      moreText,
      afterLabel,
      isRevertDataByLog,
    });

    isDisabledRevert =
      displayValue !== undefined && displayValue !== changeValue;
  }

  if (!checkPermissionRevertTaskLog(field as keyof TaskDTO, user)) {
    isDisabledRevert = true;
  }

  return {
    logText,
    revertTooltip,
    data: log.value?.data,
    isDisabledRevert,
    isRevert,
  };
};

export function getTypeKeyByField(
  key: string,
  currentValue?: any,
  value?: any
) {
  let typeKey = -1;
  switch (key) {
    case "status":
      typeKey = TaskLogTypeKey.STATUS;
      break;
    case "tags":
      typeKey = TaskLogTypeKey.TAGS;
      break;
    case "confirmedDateTime":
      typeKey = TaskLogTypeKey.CONFIRM_DATE;
      break;
    case "userConfirmed":
      typeKey = TaskLogTypeKey.USER_CREATED;
      break;
    case "endDateScheduled":
      typeKey = TaskLogTypeKey.COMPLETE_DATE;
      break;
    case "deadline":
      typeKey = TaskLogTypeKey.TARGET_DATE;
      break;
    case "taskDate":
      typeKey = TaskLogTypeKey.TASK_DATE;
      break;
    case "partnerCompany":
      typeKey = TaskLogTypeKey.PARTNERS;
      break;
    case "taskTypeId":
      typeKey = TaskLogTypeKey.CONTENT_TYPE;
      break;
    case "userAssigned":
      typeKey = TaskLogTypeKey.ASSIGN;
      break;
    case "confirmedMemo":
      typeKey = TaskLogTypeKey.DESCRIPTION;
      break;
    case "memo":
      typeKey = TaskLogTypeKey.DESCRIPTION_CRITICISM;
      break;
    case "printMode":
      typeKey =
        value === TASK_PRINT_MODE.PRINTABLE
          ? TaskLogTypeKey.PRINT_TARGET
          : TaskLogTypeKey.UNPRINT_TARGET;
      break;

    case "attaches":
    case "images":
    case "confirmedImages": {
      if (!currentValue) {
        currentValue = [];
      }
      const isAdd = value.length > currentValue.length;
      const isDelete = value.length < currentValue.length;
      const isEdit = !isEqual(currentValue, value) && !isDelete && !isAdd;
      if (!isDelete && !isAdd && !isEdit) {
        return;
      }
      if (key === "attaches" && (isDelete || isAdd)) {
        typeKey = isAdd ? TaskLogTypeKey.ATTACH : TaskLogTypeKey.DELETE_ATTACH;
      } else if (key === "images") {
        typeKey = isAdd
          ? TaskLogTypeKey.POINTED_OUT_PHOTO
          : isEdit
          ? TaskLogTypeKey.EDIT_POINTED_OUT_PHOTO
          : TaskLogTypeKey.DELETE_POINTED_OUT_PHOTO;
      } else if (key === "confirmedImages") {
        typeKey = isAdd
          ? TaskLogTypeKey.IMAGE
          : isEdit
          ? TaskLogTypeKey.EDIT_IMAGE
          : TaskLogTypeKey.DELETE_IMAGE;
      }
      break;
    }
    default:
      break;
  }

  return typeKey;
}

export const getLogContents = (
  taskId: string,
  fieldUpdates: { [field: string]: any },
  task: TaskDTO | undefined | null
) => {
  const logs: any = [];
  Object.keys(fieldUpdates).forEach((field) => {
    const value = fieldUpdates[field];
    const type = getTypeKeyByField(
      field,
      task?.[field as keyof TaskDTO],
      value
    );
    if (!type) {
      return;
    }
    const logItem = getTaskContentLog({
      taskId,
      type,
      field: field as any,
      value,
    });
    logs.push(logItem);
  });

  return logs;
};

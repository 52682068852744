import { useCallback, useState } from "react";
import { saveAs } from "file-saver";

export default function useDownloadOriginImage() {
  const [isDownloadOriginImage, setIsDownloadOriginImage] = useState(false);
  const handleDownloadOriginImage = useCallback(
    async (link: string | undefined) => {
      if (!link) {
        return;
      }

      setIsDownloadOriginImage(true);
      const blob = await fetch(link).then((res) => res.blob());
      const path = new URL(link).pathname;
      const fileName = path.substring(path.lastIndexOf("/") + 1);
      setIsDownloadOriginImage(false);
      saveAs(blob, fileName);
    },
    []
  );

  return {
    isDownloadOriginImage,
    setIsDownloadOriginImage,
    handleDownloadOriginImage,
  };
}

import { Box } from "@chakra-ui/react";
import { PaperDirectionType } from "constants/enum";
import useRender from "hooks/useWatchingRender";
import React, { useMemo } from "react";

const Page: React.FC<any> = ({
  renderPreviewComponent,
  styleTaskSheetBox,
  pageIndex,
  page,
  scale,
  pages,
  getDisplayComponent,
  isKeyplanFullPage,
  isAxisHorizontal,
  pageDirection,
  axis,
  pageId,
  pageHeight,
  paddingY,
}) => {
  const components = useMemo(() => {
    return page.components.map((component: any) => {
      const componentSize = getDisplayComponent(page, scale, component);
      let componentSizeWidth = componentSize.size.width;
      let componentSizeHeight = componentSize.size.height;
      let componentPositionX = component.position.x * scale.displayX;
      let componentPositionY = component.position.y * scale.displayY;
      const isVertical = pageDirection === PaperDirectionType.VERTICAL;

      if (isKeyplanFullPage) {
        [componentSizeWidth, componentSizeHeight] = isAxisHorizontal
          ? isVertical
            ? [componentSizeHeight, componentSizeWidth]
            : [componentSizeWidth, componentSizeHeight]
          : isVertical
          ? [componentSizeWidth, componentSizeHeight]
          : axis
          ? [componentSizeHeight, componentSizeWidth]
          : [componentSizeWidth, componentSizeHeight];

        [componentPositionX, componentPositionY] = isAxisHorizontal
          ? isVertical
            ? [componentPositionX, componentPositionY]
            : [componentPositionY, componentPositionX]
          : isVertical
          ? [componentPositionX, componentPositionY]
          : axis
          ? [componentPositionY, componentPositionX]
          : [componentPositionX, componentPositionY];
      }

      return (
        <Box
          key={component.componentId}
          position="absolute"
          top={`${componentPositionY}px`}
          left={`${componentPositionX}px`}
          width={`${componentSizeWidth}px`}
          height={`${componentSizeHeight}px`}
        >
          {renderPreviewComponent({
            component,
            page,
            pageId,
            isKeyplanFullPage,
            componentSizeHeight,
            pageHeight,
            paddingPageY: paddingY,
          })}
        </Box>
      );
    });
  }, [
    axis,
    getDisplayComponent,
    isAxisHorizontal,
    isKeyplanFullPage,
    paddingY,
    page,
    pageDirection,
    pageHeight,
    pageId,
    renderPreviewComponent,
    scale,
  ]);

  const setRef = useRender(pageId);

  return (
    <Box py="7px">
      <Box
        {...styleTaskSheetBox}
        ref={(ref) => {
          ref && setRef(ref);
        }}
      >
        <Box position="relative" height="100%" width="100%">
          {components}
        </Box>

        <Box
          position="absolute"
          bottom="1rem"
          right="2rem"
          padding="2px"
          bg="white"
          minWidth="10rem"
          textAlign="right"
          fontSize="1.2rem"
        >
          {pageIndex + 1} / {pages.length}
        </Box>
      </Box>
    </Box>
  );
};

export default Page;

import { Attribute, Node } from "@tiptap/core";
import { NodeType } from "../type";

export interface TableTrComponentNodeAttrs {
  rowId: string;
  rowIndex: number;
  rowHeight: number | undefined;
}

export const TableTrComponentNode = Node.create({
  name: NodeType.TABLE_TR_COMPONENT,
  group: "block",
  content: `${NodeType.TABLE_TD_COMPONENT}*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  addAttributes() {
    const attrs: { [key in keyof TableTrComponentNodeAttrs]: Attribute } = {
      rowId: { default: null },
      rowIndex: { default: 0 },
      rowHeight: { default: 1 },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "tr",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { rowHeight, rowId } = HTMLAttributes as TableTrComponentNodeAttrs;
    let style = "line-height: 1.2; ";
    if (rowHeight !== undefined) {
      style = `${style} height: ${rowHeight}px`;
    }

    return ["tr", { id: rowId, style }, 0];
  },
});

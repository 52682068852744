import { Vector3 } from "interfaces/models";
import { ___viewer3d } from "./forge3d";

export let ___viewer2d: Autodesk.Viewing.GuiViewer3D | undefined;
export const setViewer2d = (viewer?: any) => {
  ___viewer2d = viewer;
};

export let ___sheetTransformMatrix: THREE.Matrix4 | undefined = undefined;
export const setSheetTransformMatrix = (matrix: THREE.Matrix4 | undefined) => {
  ___sheetTransformMatrix = matrix;
};

export const find2DBounds = (
  fragList: any,
  instanceTree: any,
  dbId: number
) => {
  const bounds = new THREE.Box3();
  const bc = new Autodesk.Viewing.Private.BoundsCallback(bounds);
  instanceTree?.enumNodeFragments(dbId, (fragId: number) => {
    const mesh = fragList.getVizmesh(fragId);
    const vbr = new Autodesk.Viewing.Private.VertexBufferReader(mesh.geometry);
    vbr.enumGeomsForObject(dbId, bc);
  });

  return bounds;
};

export const expandBoundingBoxByScale = (box: THREE.Box3, scale: number) => {
  box.max.x = box.max.x * (1 + scale);
  box.max.y = box.max.y * (1 + scale);
  box.min.x = box.min.x * (1 - scale);
  box.min.y = box.min.y * (1 - scale);
};

export const find2dPosition = (dbId: number) => {
  const viewer = ___viewer2d;
  if (!viewer) {
    return;
  }
  const fragList = viewer.model.getFragmentList();
  const instanceTree = viewer.model.getInstanceTree();
  if (!fragList || !instanceTree) {
    return;
  }

  return find2DBounds(fragList, instanceTree, dbId).getCenter();
};

export const calculatePositionOnSheet = (
  position?: Vector3 | THREE.Vector3
) => {
  let pos = new THREE.Vector3(position?.x, position?.y, position?.z);
  pos =
    !!___viewer2d && !!___sheetTransformMatrix
      ? pos.clone().applyMatrix4(___sheetTransformMatrix)
      : pos;

  return new THREE.Vector3(pos.x, pos.y, 0);
};

export const calculatePositionFromSheet = (
  position: Vector3 | THREE.Vector3
) => {
  const pos = new THREE.Vector3(position?.x, position?.y, 0);
  if (!!___viewer3d || !___sheetTransformMatrix) {
    return pos;
  }

  const inverseMatrix = new THREE.Matrix4().getInverse(___sheetTransformMatrix);

  return pos.clone().applyMatrix4(inverseMatrix);
};

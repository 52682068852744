import React, { memo, ReactNode, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
} from "@chakra-ui/react";
import { css } from "@emotion/react";

export type ModalInputProps = {
  isOpen: boolean;
  title: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  minWidth?: number | string;
  isCentered?: boolean;
  okLabel?: ReactNode;
  cancelLabel?: ReactNode;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  btnPropsCancel?: ButtonProps;
  btnPropsOk?: ButtonProps;
  btnOk?: ReactNode;
  modalHeaderProps?: ModalHeaderProps;
  modalFooterProps?: ModalHeaderProps;
  modalContentProps?: ModalContentProps;
  onOk?: () => any;
  onCancel?: () => any;
  icon?: ReactNode;
  bodyProps?: any;
};

const ModalInput = memo<ModalInputProps>(
  ({
    isOpen,
    title,
    onClose,
    minWidth = 1000,
    isCentered = true,
    children,
    okLabel,
    cancelLabel,
    okDisabled,
    cancelDisabled,
    btnPropsCancel,
    btnPropsOk,
    modalHeaderProps,
    modalFooterProps,
    btnOk,
    modalContentProps,
    onOk,
    onCancel,
    icon,
    bodyProps = {},
  }) => {
    const onHandleClose = useCallback(() => {
      onClose && onClose();
    }, [onClose]);

    return (
      <>
        <Modal isOpen={isOpen} onClose={onHandleClose} isCentered={isCentered}>
          <ModalOverlay />
          <ModalContent
            maxW={"100%"}
            flex={`0 0 ${minWidth}`}
            {...modalContentProps}
          >
            <ModalHeader
              css={css`
                border-bottom: 1px solid #dedede;
              `}
              pt="3rem"
              {...modalHeaderProps}
            >
              <ModalCloseButton
                fontSize="12px"
                _focusVisible={{}}
                _focus={{
                  border: "none",
                }}
              />
              <Box
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding-top: 3rem;
                  gap: 1rem;
                `}
              >
                {icon}
                <Box
                  css={css`
                    font-size: 2.4rem; /* 36px */
                    line-height: 2.88rem; /* 40px */
                  `}
                >
                  {title}
                </Box>
              </Box>
            </ModalHeader>
            <ModalBody {...bodyProps}>{children}</ModalBody>
            <ModalFooter
              css={css`
                border-top: 1px solid #dedede;
                padding-top: 2rem;
                padding-bottom: 2rem;
                gap: 1rem;
                background: white;
              `}
              {...modalFooterProps}
            >
              {!!cancelLabel && (
                <ActionButton
                  btnProps={btnPropsCancel}
                  disabled={cancelDisabled}
                  onClick={onCancel}
                >
                  {cancelLabel}
                </ActionButton>
              )}
              {btnOk
                ? btnOk
                : !!okLabel && (
                    <ActionButton
                      btnProps={{
                        variant: "filled",
                        ...btnPropsOk,
                      }}
                      onClick={onOk}
                      disabled={okDisabled}
                    >
                      {okLabel}
                    </ActionButton>
                  )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export const ModalInputSection = memo<{
  children: ReactNode;
  label?: ReactNode;
  tag?: ReactNode;
  tagColor?: "blue" | "red" | string;
  divider?: boolean;
  alignItems?: "center" | "start";
}>(
  ({
    children,
    label,
    tag,
    tagColor: _tagColor = "blue",
    divider,
    alignItems = "center",
  }) => {
    const tagColor = useMemo(() => {
      return (
        (
          {
            blue: "#009be0",
            red: "#ef4444",
          } as any
        )[_tagColor as any] || _tagColor
      );
    }, [_tagColor]);

    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          gap="3rem"
          alignContent={alignItems}
          borderBottom={divider ? "1px solid #dedede" : "unset"}
          paddingBlockStart={{
            base: "2rem",
            xl: "3rem",
          }}
          paddingBlockEnd={
            divider
              ? {
                  base: "2rem",
                  xl: "3rem",
                }
              : "0px"
          }
          marginInline={{
            base: "1.6rem",
            md: "2.4rem",
            lg: "3.2rem",
            xl: "7rem",
          }}
        >
          <Box
            minWidth={{
              base: "200px",
              xl: "250px",
            }}
            fontSize="1.5rem"
            lineHeight="2rem"
            display="flex"
            flexDirection="row"
            gap="1rem"
            alignItems="center"
          >
            <Box flexGrow={1}>{label || null}</Box>
            {!!tag && (
              <Box
                fontSize="1.25rem"
                lineHeight="1"
                border="1px solid"
                borderColor={tagColor}
                color={tagColor}
                padding="0.5rem"
              >
                {tag}
              </Box>
            )}
          </Box>
          <Box
            flexGrow={1}
            maxW={{
              base: "calc(100% - 200px - 3rem)",
              xl: "calc(100% - 250px - 3rem)",
            }}
            sx={{
              "*": {
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </>
    );
  }
);

export const ActionButton = memo<{
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => any;
  btnProps?: ButtonProps;
}>(({ children, disabled, btnProps, onClick }) => {
  return (
    <Button
      onClick={disabled ? undefined : onClick}
      isDisabled={disabled}
      {...btnProps}
    >
      {children}
    </Button>
  );
});

export default ModalInput;

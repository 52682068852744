import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconBoxPlane(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m19.537 7.47-7.543 4.101m0 0L4.45 7.47m7.544 4.102v8.248m7.986-4.728v-7.04c.018-.19 0-.39-.043-.582a.9.9 0 0 0-.191-.321 2.1 2.1 0 0 0-.496-.322l-6.57-3.572a1.8 1.8 0 0 0-.513-.234.8.8 0 0 0-.356 0 1.8 1.8 0 0 0-.513.234l-6.561 3.58a2 2 0 0 0-.496.314.9.9 0 0 0-.19.313c-.044.19-.062.382-.044.582v7.048c-.018.191 0 .391.043.582a.8.8 0 0 0 .191.313q.222.193.496.322l6.57 3.572c.156.104.33.182.513.235a.8.8 0 0 0 .356 0 1.8 1.8 0 0 0 .513-.235l6.57-3.572c.183-.078.348-.191.495-.322a.9.9 0 0 0 .192-.313 2.1 2.1 0 0 0 .052-.573z"
      />
      <path fill="#171717" d="M11.993 11.571v8.69l7.822-4.344V7.66z" />
    </Icon>
  );
}

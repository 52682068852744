export const SUPPORTED_FILE_TYPES_IMAGE = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

export const defaultIconEditDocument = "/img/default-edit-document.svg";
export const defaultImagePath = "/img/default-image.svg";
export const defaultAvatarPath = "/img/default-avatar.svg";
export const fanBlackIconPath = "/img/icon-fan-black.svg";
export const errorImagePath = "/img/error-image.png";
export const folderOutlineBoldIconPath = "/img/folder-outline-bold.svg";
export const folderIconPath = "/img/icon-folder.svg";
export const documentIconPath = "/img/icon-document-edit.svg";
export const documentsIconPath = "/img/section-analysis.svg";
export const areaIconPath = "/img/icon-area-doc.svg";
export const checkDocumentItemIconPath = "/img/icon-check-document-item.svg";

export const SUPPORTED_FILE_TYPES_ATTACH = [
  ...SUPPORTED_FILE_TYPES_IMAGE,
  "application/pdf",
  "video/mp4",
];

export const LIMIT_FILE_SIZE = 104857600;

export enum TypeOfFile {
  IMAGE = 1,
  ATTACH = 2,
  AUDIO = 3,
}

export const FILE_SIZE_ERROR_MESSAGE =
  "アップロード可能なファイルサイズは100MBまでです。";

export const UPLOAD_MULTIPLE_VIDEOS_MESSAGE =
  "一度にアップロードできるMP4の動画ファイルは 1つだけです。";

export const IMAGE_UPLOAD_ERROR_MESSAGE =
  "アップロードできるファイル形式は、画像ファイルのみです。";

export const ATTACH_UPLOAD_ERROR_MESSAGE =
  "アップロードできるファイル形式は、画像/PDF/MP4ファイルのみです。";

export const FileType = {
  IMAGE_PNG: "image/png",
  IMAGE_GIF: "image/gif",
  IMAGE_JPEG: "image/jpeg",
  IMAGE_TIFF: "image/tiff",
  IMAGE_WEBP: "image/webp",
  APPLICATION_PDF: "application/pdf",
  VIDEO_MP4: "video/mp4",
  UNKNOWN: "unknown",
};

export const VIDEO_FILE_EXTENSIONS = ["mp4"];

export const PATH_ICONS = {
  IconPaintBucket: "/img/icon-paint-bucket.svg",
  IconPalette: "/img/icon-palette.svg",
  IconChevronDown: "/img/icon-chevron-down.svg",
  IconCheck: "/img/icon-check.svg",
  IconSliderHorizontal: "/img/icon-sliders-horizontal.svg",
  IconTransparent: "/img/icon-transparent.svg",
};

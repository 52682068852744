import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userSettingApi } from "apiClient/v2";
import { REDUCER_KEY } from "constants/redux";
import { DEFAULT_USER_SETTING } from "constants/user";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { User, UserSetting } from "interfaces/models/user";
import { getListUser } from "utils/user";

interface Mailing {
  id: string;
  name: string;
  list: {
    name: string;
    value: string;
  }[];
}

interface State {
  currentUser: User | null;
  userId: string;
  users: UserDTO[];
  listUserAssigned: UserDTO[];
  mailingList: { [listId: string]: Mailing };
  settings: UserSetting;
  isFetchingUsers: boolean;
  isFetchingUserAssigned: boolean;
  isFetchedUsers: boolean;
  token: string | null;
  isFetchedUserAssigned: boolean;
}

const initialState: State = {
  isFetchingUsers: false,
  isFetchedUsers: false,
  currentUser: null,
  userId: "",
  users: [],
  mailingList: {},
  settings: { ...DEFAULT_USER_SETTING },
  token: null,
  listUserAssigned: [],
  isFetchingUserAssigned: false,
  isFetchedUserAssigned: false,
};

export const mailingListId = "1"; // TODO: handle multi list

export const fetchUserSetting = createAsyncThunk(
  "user/fetchUserSetting",
  async () => {
    return await userSettingApi.getUserSetting();
  }
);

export const fetchListUser = createAsyncThunk(
  "user/fetchListUser",
  async () => {
    return await getListUser();
  }
);

export const fetchListUserAssigned = createAsyncThunk(
  "user/fetchListUserAssigned",
  async (bimFileId: string) => {
    return await getListUser({ bimFileId });
  }
);

export const userSlice = createSlice({
  name: REDUCER_KEY.USER,
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload;
      state.userId = action.payload?.id || action.payload?.name || "";
    },
    setMailingList: (
      state,
      action: PayloadAction<{ listId: string; mailingList: Mailing }>
    ) => {
      const { listId, mailingList } = action.payload;
      state.mailingList[listId] = mailingList;
    },
    setUserSetting: (
      state,
      action: PayloadAction<{ settings: UserSetting; isUpdateData?: boolean }>
    ) => {
      const { settings: _settings, isUpdateData = true } = action.payload;
      const settings = Object.assign({}, _settings);
      if (state.currentUser) {
        settings.userId = state.currentUser.id || "";
        if (isUpdateData) {
          userSettingApi.updateUserSetting(settings);
        }
      }

      state.settings = settings;
    },
    updateUserSetting: (state, action: PayloadAction<any>) => {
      let settings = Object.assign({}, state.settings);
      settings = { ...settings, ...action.payload };

      if (state.currentUser) {
        settings.userId = state.currentUser.id || "";
        userSettingApi.updateUserSetting(settings);
      }

      state.settings = settings;
    },

    setIsFetchedUsers: (state, action: PayloadAction<boolean>) => {
      state.isFetchedUsers = action.payload;
    },

    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserSetting.fulfilled, (state, action) => {
      if (action.payload) {
        state.settings = action.payload.data?.[0];
      }
    });
    builder.addCase(fetchListUser.pending, (state, action) => {
      state.isFetchingUsers = true;
    });
    builder.addCase(fetchListUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.users = action.payload;
      }
      state.isFetchingUsers = false;
      state.isFetchedUsers = true;
    });
    builder.addCase(fetchListUserAssigned.pending, (state, action) => {
      state.isFetchingUserAssigned = true;
      state.listUserAssigned = [];
    });
    builder.addCase(fetchListUserAssigned.fulfilled, (state, action) => {
      if (action.payload) {
        state.listUserAssigned = action.payload;
      }
      state.isFetchingUserAssigned = false;
      state.isFetchedUserAssigned = true;
    });
  },
});

export const {
  setCurrentUser,
  setMailingList,
  setUserSetting,
  updateUserSetting,
  setIsFetchedUsers,
  setToken,
} = userSlice.actions;

export default userSlice.reducer;

import { documentItemApi } from "apiClient/v2";
import { message } from "components/base";
import { DocumentItemKey, ModalType, SystemModeType } from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DataLog } from "interfaces/models/dataLog";
import { insertDataLogToIndexedDb, transformMapTitleKey } from "models/dataLog";
import { getColorByStatus } from "models/document";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import {
  setIsCreateDocumentItem,
  setIsMoveDocumentLabel,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { uuid } from "utils/common";
import { selectDbIds } from "utils/forge";
import { getDbIdByExternalId } from "utils/forge/data";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { logDev } from "utils/logs";
import { transformBodyForCombineData } from "utils/offline";

interface Props {
  clickInfo: ClickInfo | undefined;
  setClickInfo:
    | React.Dispatch<React.SetStateAction<ClickInfo | undefined>>
    | undefined;
}

const useMoveDocumentLable = (props: Props) => {
  const { clickInfo, setClickInfo } = props;

  const dispatch = useDispatch();
  const [fromExternalLogs, setFromExternalLogs] = useState<
    DataLog[] | undefined
  >(undefined);
  const { isMoveDocumentLabel, systemMode, modalType } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { documentItemSelected } = useSelector(
    (state: RootState) => state.document
  );

  const { socket } = useForgeViewerContext();
  const documentItemSelectedRef = useRef<DocumentItemDTO>();

  const showTooltipMoveDocumentLabel = useMemo(
    () => systemMode === SystemModeType.Document && isMoveDocumentLabel,
    [systemMode, isMoveDocumentLabel]
  );

  useEffect(() => {
    return () => {
      dispatch(setIsMoveDocumentLabel(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!documentItemSelected?.id || modalType !== ModalType.DOC_ITEM) {
      dispatch(setIsMoveDocumentLabel(false));
    }

    documentItemSelectedRef.current = documentItemSelected;
  }, [modalType, documentItemSelected, dispatch]);

  useEffect(() => {
    if (
      !clickInfo ||
      modalType !== ModalType.DOC_ITEM ||
      systemMode !== SystemModeType.Document ||
      !clickInfo.forgeData?.position ||
      !isMoveDocumentLabel
    ) {
      return;
    }

    handleMoveDocumentLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo, isMoveDocumentLabel]);

  const handleMoveDocumentLabel = async () => {
    try {
      const position = clickInfo?.forgeData?.position;
      // If externalId does not exist (select outside the object),
      // then create itself to turn off highlighting.
      const externalId = clickInfo?.forgeData?.externalId || uuid();
      const itemSelected = documentItemSelectedRef?.current;
      if (!position || !itemSelected?.id) {
        return;
      }

      const requestId = uuid();
      const now = new Date();
      const { mapTitleKey } = transformMapTitleKey([
        {
          field: DocumentItemKey.POSITION,
          value: position,
        },
      ]);

      const newItem = {
        ...(itemSelected as DocumentItemDTO),
        position,
        externalId,
        updatedAt: now,
      } as DocumentItemDTO;

      dispatch(updateDocumentItem(newItem));
      const result = await documentItemApi.updateItem(
        transformBodyForCombineData<DocumentItemDTO>({
          body: {
            id: itemSelected.id,
            position,
            externalId,
            mapTitleKey,
            requestId,
            updatedAt: now,
          } as DocumentItemDTO,
          bodyBefore: itemSelected,
          typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
        })
      );

      if (!result) {
        dispatch(updateDocumentItem(itemSelected));

        return;
      }

      socket.updateDocItem(newItem, {
        position,
        externalId,
        updatedAt: now,
      });

      const log = await insertDataLogToIndexedDb({
        contentLogParams: {
          field: DocumentItemKey.POSITION,
          value: { ...position, externalId },
          requestId,
        },
        createdBy: currentUser!.id!,
        identifyValue: itemSelected.id,
        identifyCol: "itemId",
        type: "item",
      });

      setFromExternalLogs([log]);
      socket.addDocItemLog(itemSelected.id, log);
      message.success("書類ピンの移動が完了しました。");
    } catch (error) {
      logDev(error);
    } finally {
      setClickInfo?.(undefined);
      dispatch(setIsMoveDocumentLabel(false));
    }
  };

  const toggleMoveDocumentLabelMode = useCallback(() => {
    if (!isMoveDocumentLabel) {
      dispatch(setIsCreateDocumentItem(false));
    }

    dispatch(setIsMoveDocumentLabel(!isMoveDocumentLabel));
  }, [dispatch, isMoveDocumentLabel]);

  const clearFromExternalLogs = useCallback(() => {
    setFromExternalLogs(undefined);
  }, []);

  return {
    fromExternalLogs,
    showTooltipMoveDocumentLabel,
    toggleMoveDocumentLabelMode,
    clearFromExternalLogs,
  };
};

export default useMoveDocumentLable;

import { createContext } from "react";
import { HEX_TRANSPARENT } from "../constants";

export type StateColorPickerMicrosoft = {
  color: string;
  opacity: number;
};

export const DEFAULT_COLOR_PICKER_MICROSOFT: StateColorPickerMicrosoft = {
  color: HEX_TRANSPARENT,
  opacity: 1,
};

export type ParamsChangeComplete = {
  color: string;
  opacity?: number;
};

export const ColorPickerMicrosoftContext = createContext<{
  colorPicker: StateColorPickerMicrosoft;
  setColorPicker: React.Dispatch<
    React.SetStateAction<StateColorPickerMicrosoft>
  >;
  onHandleChangeComplete: (color: ParamsChangeComplete) => void;
  isOpenMoreColors: boolean;
  setIsOpenMoreColors: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  colorPicker: DEFAULT_COLOR_PICKER_MICROSOFT,
  setColorPicker: () => {},
  onHandleChangeComplete: () => {},
  isOpenMoreColors: false,
  setIsOpenMoreColors: () => {},
});

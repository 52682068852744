import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconClose(props: IconProps) {
  return (
    <Icon width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="#fff"
        d="m8 7.058 3.3-3.3.943.942-3.3 3.3 3.3 3.3-.943.943-3.3-3.3-3.3 3.3-.942-.943 3.3-3.3-3.3-3.3.942-.942z"
      />
    </Icon>
  );
}

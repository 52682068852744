import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import ImageWithBlackboard from "components/editor-builder/component-preview/TableComponentPreview/ui/ImageWithBlackboard";
import { CellProperty, LinkedDataField } from "constants/enum";
import { CellType } from "interfaces/models/component";
import { iLinkedImageProps } from "interfaces/models/documentTemplate";
import { useCallback } from "react";
import NormalTextPreview from "../../NormalTextPreview";

const useGetContentTypeLinkedImage = (props: iUseGetContentOfCell) => {
  const { linkedImageProps = {} as iLinkedImageProps } = props;
  const { familyInstances, blackboardTemplateProps = {} } = linkedImageProps;

  const contentOfCellTypeLinkedImage = useCallback(
    (cell: CellType) => {
      const data = structuredClone(cell);
      const documentItem = linkedImageProps?.option?.documentItem;

      switch (data?.cellProperty) {
        case CellProperty.IMAGE:
          return (
            <ImageWithBlackboard
              imageSrc={documentItem?.images?.src}
              isShowBlackboard={documentItem?.isShowBlackboard}
              blackboardImagePosition={documentItem?.blackboardImagePosition}
              blackboard={(documentItem as any)?.blackBoard}
              blackboardTemplateProps={{
                ...(blackboardTemplateProps || ({} as any)),
                documentSubItem: documentItem as any,
                familyInstance:
                  familyInstances?.[documentItem?.externalId || ""],
              }}
            />
          );

        case CellProperty.DOCUMENT_DATA:
          if (
            data?.cellLinkedData?.field === LinkedDataField.DOCUMENT_ITEM.TITLE
          ) {
            data.value = !documentItem
              ? ""
              : `No. ${documentItem.keyNoteIndex} : ${
                  documentItem.title || "-"
                }`;
          }

          break;

        default:
          break;
      }

      if (!data) {
        return null;
      }

      switch (data?.cellProperty) {
        case CellProperty.NO:
        case CellProperty.TEXT:
        case CellProperty.INPUT_DATA:
        case CellProperty.CALENDAR: {
          return <NormalTextPreview data={data} />;
        }

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [linkedImageProps, familyInstances, blackboardTemplateProps]
  );

  return {
    contentOfCellTypeLinkedImage,
  };
};

export default useGetContentTypeLinkedImage;

import { Attribute, mergeAttributes, Node } from "@tiptap/core";
import { DOMOutputSpec } from "@tiptap/pm/model";
import { NodeType } from "../type";

export interface SubTableComponentNodeAttrs {
  tableId: string;
}

export const SubTableComponentNode = Node.create({
  name: NodeType.SUB_TABLE_COMPONENT,
  group: "block",
  content: `${NodeType.TABLE_TR_COMPONENT}*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  addAttributes() {
    const attrs: { [key in keyof SubTableComponentNodeAttrs]: Attribute } = {
      tableId: { default: null },
    };

    return attrs;
  },

  renderHTML({ HTMLAttributes }) {
    const { tableId } = HTMLAttributes as SubTableComponentNodeAttrs;
    const table: DOMOutputSpec = [
      "table",
      mergeAttributes({
        id: `subTable--${tableId}`,
        style: `width: 100%; height: 100%; z-index: 99`,
      }),
      ["tbody", 0],
    ];

    return table;
  },
});

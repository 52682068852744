import { CellProperty } from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { GetContentTypeDocumentType } from "../type";
import { getContentNodeTypeNormalText, getContentTypeCheckbox } from "../utils";
import BaseStructureTable, {
  BaseStructureTableProps,
} from "./BaseStructureTable";
import ContentTdTypeCommon from "./ContentTdTypeCommon";

class ContentTdTypeSelfInspection extends BaseStructureTable {
  protected contentTdTypeCommon: ContentTdTypeCommon;

  constructor(params: BaseStructureTableProps) {
    super(params);
    this.contentTdTypeCommon = new ContentTdTypeCommon(params);
  }

  getStructure(params: GetContentTypeDocumentType) {
    if (!this.page || !this.component) {
      return null;
    }

    const {
      parentCell,
      cell,
      cellIndex,
      rowIndex,
      subRowIndex = 1,
      subCellIndex = 1,
    } = params;

    const { offsetItemLinked } = this.page;
    const documentItem: DocumentItemDTO | undefined = this.documentItems?.find(
      (doc) => doc.linkedDataId === this.component?.subcategoryIdSelected
    );
    const dataRow = (documentItem?.subItems?.[rowIndex] ||
      {}) as DocumentItemDTO;
    if (!dataRow.id) {
      return null;
    }

    // return type common
    const commonData = this.contentTdTypeCommon.getStructure({
      cell,
      currentCell: parentCell ?? cell,
      rowIndex,
      cellIndex,
      subRowIndex,
      subCellIndex,
      documentItem: dataRow,
    });

    if (commonData) {
      return commonData;
    }

    switch (cell.cellProperty) {
      case CellProperty.NO: {
        return getContentNodeTypeNormalText({
          text: `${Number(cell.value) + offsetItemLinked}`,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }

      case CellProperty.DOCUMENT_DATA:
        switch (cell?.cellLinkedData?.field) {
          case CellProperty.CHECK_BOX:
            return getContentTypeCheckbox({
              cell,
              documentItem: dataRow,
              documentType: this.template?.documentType,
              component: this.component,
            });

          default:
            return getContentNodeTypeNormalText({
              text: cell.value,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
        }

      default:
        return getContentNodeTypeNormalText({
          text: cell.value,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
    }
  }
}

export default ContentTdTypeSelfInspection;

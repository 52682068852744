import { Text } from "@chakra-ui/react";
import { LINE_HEIGHT_DEFAULT } from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { EditData, FieldData } from "interfaces/models";
import unescape from "lodash/unescape";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { tryJsonParse } from "utils/common";
import { transformSizeForTextElement } from "utils/download-pdf";

interface Props {
  editMode: boolean;
  defaultValue?: string;
  value?: string;
  isJsonText?: boolean;
  type: "editData" | "fieldData";
  editDataStyle: EditData["style"];
  style?: CSSProperties;
  pageId?: string;
  color?: string;
  onEditSubmit: (data: EditData | FieldData) => void;
}

const TEXT_COLOR_DEFAULT = "#000000";

const TableSheetCell = ({
  pageId,
  editMode,
  defaultValue,
  value,
  isJsonText,
  color = TEXT_COLOR_DEFAULT,
  type = "editData",
  editDataStyle,
  style,
  onEditSubmit,
}: Props) => {
  const [isPrevEditMode, setIsPrevEditMode] = useState(false);
  const [initTextValue, setInitTextValue] = useState<string | null>(null);

  const textElementRef = useRef<HTMLParagraphElement>(null);
  const lastTextRef = useRef<string>();
  const isCutOffTextRef = useRef(false);

  const editDataStyleRef = useRef({
    fontSize: 14,
    lineHeight: 14 * LINE_HEIGHT_DEFAULT,
  });
  const sizeDefaultRef = useRef({
    fontSize: 14,
    lineHeight: 14 * LINE_HEIGHT_DEFAULT,
  });

  const content = useMemo(() => {
    const newValue = value ?? (defaultValue || "");
    const jsonValue = tryJsonParse(newValue);

    return isJsonText && jsonValue && typeof jsonValue === "string"
      ? unescape(jsonValue.replace(/<[a-zA-Z][^>]*>/g, "").replace(/\n/g, " "))
      : newValue;
  }, [defaultValue, isJsonText, value]);

  const otherConditionCheckTextOverflow = useCallback(() => {
    if (!pageId) {
      return false;
    }
    const pageElement = document.getElementById(pageId || "")
      ?.childNodes?.[0] as HTMLElement | null;
    if (!pageElement) {
      return false;
    }

    return pageElement.clientHeight < pageElement.scrollHeight;
  }, [pageId]);

  useEffect(() => {
    if (editMode) {
      setIsPrevEditMode(true);
    }
  }, [editMode]);

  useEffect(() => {
    const element = textElementRef.current;
    if (!element || initTextValue === null || !isPrevEditMode || editMode) {
      return;
    }

    // reset text
    element.style.color = isCutOffTextRef.current ? "red" : color;
    element.innerHTML = initTextValue.replaceAll("\n", "<br/>");
    element.style.fontSize = `${
      editDataStyle?.fontSize || editDataStyleRef.current.fontSize
    }px`;
    element.style.lineHeight = `${
      editDataStyle?.lineHeight || editDataStyleRef.current.lineHeight
    }px`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, editDataStyle, initTextValue, isPrevEditMode]);

  const handleSubmit = useCallback(
    ({ resetColor = true }: { resetColor: boolean }) => {
      const element = textElementRef.current;
      if (!element) {
        return;
      }

      const text = element.innerHTML;
      const fontSizeNumber = Number(
        getComputedStyle(element).fontSize.replace("px", "")
      );
      if (resetColor) {
        element.style.color = color;
      }

      let data = {};
      const style = {
        fontSize: fontSizeNumber,
        lineHeight: fontSizeNumber * LINE_HEIGHT_DEFAULT,
      };
      if (type === "editData") {
        data = {
          editValue: text,
          style,
        } as EditData;
      }

      if (type === "fieldData") {
        data = {
          value: text,
          style,
        } as FieldData;
      }

      onEditSubmit(data);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, color]
  );

  // transform size when first time
  useEffect(() => {
    const element = textElementRef.current;

    if (element) {
      element.innerHTML = content.replaceAll("\n", "<br/>");
      element.style.fontSize = `${
        editDataStyle?.fontSize || sizeDefaultRef.current.fontSize
      }px`;
      element.style.lineHeight = `${
        editDataStyle?.lineHeight || sizeDefaultRef.current.lineHeight
      }px`;
      setInitTextValue(content || "");
    }

    if (!element || !content) {
      return;
    }

    const { text, shouldUpdate } = transformSizeForTextElement({
      isShowMessage: false,
      element,
      lastTextRef,
      sizeDefault: sizeDefaultRef.current,
    });

    if (shouldUpdate) {
      isCutOffTextRef.current = true;
      element.style.color = "red";
      setInitTextValue(text || "");
      element.innerHTML = text;
      lastTextRef.current = text;

      const fontSizeNumber = Number(
        getComputedStyle(element).fontSize.replace("px", "")
      );
      editDataStyleRef.current = {
        fontSize: fontSizeNumber,
        lineHeight: fontSizeNumber * LINE_HEIGHT_DEFAULT,
      };
    } else {
      isCutOffTextRef.current = false;
    }

    handleSubmit({ resetColor: false });
  }, [content, editDataStyle, handleSubmit]);

  const handleInput = () => {
    const element = textElementRef.current;
    if (!element) {
      return;
    }

    const { text, shouldUpdate } = transformSizeForTextElement({
      element,
      lastTextRef: lastTextRef,
      sizeDefault: sizeDefaultRef.current,
      otherConditionCheckTextOverflow,
    });

    if (shouldUpdate) {
      element.innerHTML = text;
      moveCaretToEnd();
    }

    handleSubmit({ resetColor: true });
  };

  const moveCaretToEnd = () => {
    const element = textElementRef.current;
    if (!element) {
      return;
    }

    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
    element.focus();
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLParagraphElement>) => {
    event.preventDefault();
    const element = textElementRef.current;
    if (!element) {
      return;
    }

    const pasteValue = event.clipboardData.getData("text");
    const selection = window.getSelection();
    if (!selection?.rangeCount) {
      return;
    }

    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(pasteValue));
    selection.collapseToEnd();

    const { text, shouldUpdate } = transformSizeForTextElement({
      element,
      lastTextRef: lastTextRef,
      sizeDefault: sizeDefaultRef.current,
    });

    if (shouldUpdate) {
      element.innerHTML = text;
    }

    handleSubmit({ resetColor: true });
  };

  return (
    <>
      <Text
        ref={textElementRef}
        contentEditable={editMode}
        onInput={handleInput}
        onPaste={handlePaste}
        suppressContentEditableWarning={editMode}
        className="td-content"
        textAlign="center"
        minHeight="1em"
        width="100%"
        lineHeight={`${
          editDataStyle?.lineHeight || sizeDefaultRef.current.lineHeight
        }px`}
        fontSize={`${
          editDataStyle?.fontSize || sizeDefaultRef.current.fontSize
        }px`}
        padding="0px!important"
        style={style}
      />
    </>
  );
};

export default TableSheetCell;

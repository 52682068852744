import { FileUploadInfo } from "interfaces/models";
import { getPreSignUrl } from "./file";

export const checkIsImageExists = async (imgUrl: string): Promise<boolean> => {
  if (!imgUrl) {
    return false;
  }

  return new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
    image.src = imgUrl;
  });
};

export const getUrlDisplayOfFileUpload = async (
  images: FileUploadInfo[]
): Promise<string[]> => {
  const urls = await Promise.all(
    (images || [])?.map(async (image) => {
      const src = image?.originSrc || image?.src || "";
      const isBase64 =
        src?.includes("data:image/") ||
        src?.includes("application/octet-stream;base64");

      if (!src || isBase64) {
        return "";
      }

      return await getPreSignUrl(src, "").catch(() => "");
    })
  );

  return urls;
};

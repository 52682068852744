import SubTableComponentPreview from "components/editor-builder/component-preview/TableComponentPreview/SubTableComponentPreview";
import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import TableSheetCell from "components/widgets/TaskSheet/TableSheetCell";
import {
  CellProperty,
  LinkedDataField,
  TableDefaultStyle,
} from "constants/enum";
import useUserOfProject from "hooks/useUserOfProject";
import { CellType } from "interfaces/models/component";
import { DocumentTaskDataFieldTask } from "interfaces/models/documentTask";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { formatDate } from "utils/date";
import { getTaskMap } from "utils/task";
import NormalTextPreview from "../../NormalTextPreview";

const useGetContentTypeTaskItemData = (props: iUseGetContentOfCell) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,
    taskItemDataProps,
  } = props;

  const { listAllUserById } = useUserOfProject();
  const { taskTypes } = useSelector((state: RootState) => state.task);

  const documentTaskData = useMemo(
    () => taskItemDataProps?.documentTaskData,
    [taskItemDataProps?.documentTaskData]
  );

  const handleDocumentTaskDetailProps = useMemo(
    () => taskItemDataProps?.handleDocumentTaskDetailProps,
    [taskItemDataProps?.handleDocumentTaskDetailProps]
  );
  const currentTaskMap = useMemo((): {
    [key: string]: DocumentTaskDataFieldTask;
  } => {
    return getTaskMap(documentTaskData?.tasks || []);
  }, [documentTaskData?.tasks]);

  const getContentTypeTaskItemData = useCallback(
    (cell: CellType) => {
      if (!handleDocumentTaskDetailProps) {
        return null;
      }

      const page = taskItemDataProps?.page || 1;
      const numOfRepeat =
        taskItemDataProps?.component?.detail?.numOfRepeat || 1;
      const rowIndex = taskItemDataProps?.option?.rowIndex || 0;
      const taskItem = taskItemDataProps?.taskItems?.[rowIndex];
      const mapPartnerCompanyById =
        taskItemDataProps?.mapPartnerCompanyById || {};
      const currentTaskData = currentTaskMap[taskItem?.id || ""];
      const data = structuredClone(cell);
      const color = cell?.style?.color;
      let value;
      let style;
      //const color = cell.repeatedTableIndex
      const { editMode, onEditTask } = handleDocumentTaskDetailProps;

      if (!taskItem) {
        return null;
      }

      switch (cell.cellLinkedData?.field) {
        case LinkedDataField.COMMON.NO:
          data.value = String(rowIndex + 1 + numOfRepeat * (page - 1));

          return <NormalTextPreview data={data} />;

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_ID:
          data.value = String(taskItem?.indexId || "");

          return <NormalTextPreview data={data} />;

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_TITLE:
          value =
            currentTaskData?.contentType?.editValue ??
            taskTypes?.find(
              (item) => item?.id === currentTaskData?.contentType?.origin
            )?.title;
          style = currentTaskData?.contentType?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={
                taskTypes?.find((item) => item?.id === taskItem?.id)?.title ||
                "-"
              }
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "contentType",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_CONFIRMED_MEMO:
          value = currentTaskData?.confirmedMemo?.editValue;
          style = currentTaskData?.confirmedMemo?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={taskItem?.confirmedMemo}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "confirmedMemo",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_USER_TREATED:
          value =
            currentTaskData?.userTreated?.editValue ??
            listAllUserById[currentTaskData?.userTreated?.origin || ""]?.name;
          style = currentTaskData?.userTreated?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={listAllUserById[taskItem?.userTreated || ""]?.name}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "userTreated",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_DEADLINE:
          value = currentTaskData?.deadline?.editValue;
          style = currentTaskData?.deadline?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={formatDate(taskItem?.deadline, "YYYY/MM/DD")}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "deadline",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_FIXED_DATE:
          value = currentTaskData?.endDateScheduled?.editValue;
          style = currentTaskData?.endDateScheduled?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={formatDate(
                taskItem?.endDateScheduled,
                "YYYY/MM/DD"
              )}
              value={value}
              editDataStyle={style}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "endDateScheduled",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_CONFIRMED_DATE:
          value = currentTaskData?.confirmedDateTime?.editValue;
          style = currentTaskData?.confirmedDateTime?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={formatDate(
                taskItem?.confirmedDateTime,
                "YYYY/MM/DD"
              )}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "confirmedDateTime",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_MANAGER:
          value = currentTaskData?.userAssigned?.editValue;
          style = currentTaskData?.userAssigned?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={
                listAllUserById[(taskItem as any)?.userAssigned || ""]?.name
              }
              value={value}
              editDataStyle={style}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "userAssigned",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_PARTNER_COMPANY:
          value = currentTaskData?.partnerCompany?.editValue;
          style = currentTaskData?.partnerCompany?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={
                mapPartnerCompanyById[taskItem?.partnerCompany || ""]?.name
              }
              value={value}
              editDataStyle={style}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "partnerCompany",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_USER_CONFIRMED:
          value = currentTaskData?.userConfirmed?.editValue;
          style = currentTaskData?.userConfirmed?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={
                listAllUserById[taskItem?.userConfirmed || ""]?.name
              }
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "userConfirmed",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.TASK_MEMO:
          value = currentTaskData?.memo?.editValue;
          style = currentTaskData?.memo?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={taskItem?.memo}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              isJsonText
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTask({
                  editData,
                  task: taskItem,
                  fieldName: "memo",
                })
              }
            />
          );

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [
      taskItemDataProps,
      currentTaskMap,
      taskTypes,
      listAllUserById,
      handleDocumentTaskDetailProps,
    ]
  );

  const contentOfCellTypeTaskItemData = useCallback(
    (cell: CellType) => {
      const data = structuredClone(cell);
      const rowIndex = taskItemDataProps?.option?.rowIndex || 0;
      const taskItem = taskItemDataProps?.taskItems?.[rowIndex];

      if (data?.subTable?.rows?.length) {
        return (
          <SubTableComponentPreview
            component={component}
            parentCell={cell}
            displaySize={{
              width:
                (width / component.size.width) * displaySize.width -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
              height:
                (height / component.size.height) * displaySize.height -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            }}
            zoomRatio={zoomRatio}
            cellSize={cellSize}
            sizePageRatio={sizePageRatio}
            pageDirectionRatio={pageDirectionRatio}
            isOnlyView={isOnlyView}
            isDuplicateRow={isDuplicateRow}
            isParentLastColumn={isLastColumn}
            limit={limit}
            isBlackboardTemplateImage={isBlackboardTemplateImage}
            components={components}
            selectedCells={selectedCells}
            documentContainerSize={documentContainerSize}
            currentTemplate={currentTemplate}
            componentSelected={componentSelected}
            isComponentDragging={isComponentDragging}
            contentType={contentType}
            taskItemDataProps={taskItemDataProps}
          />
        );
      }

      switch (cell.cellProperty) {
        case CellProperty.TASK_ITEM_DATA:
          return getContentTypeTaskItemData(cell);

        default:
          break;
      }

      if (!taskItem) {
        return null;
      }

      return <NormalTextPreview data={data} />;
    },
    [
      component,
      taskItemDataProps,
      currentTemplate,
      zoomRatio,
      isBlackboardTemplateImage,
      displaySize,
      width,
      height,
      limit,
      isLastColumn,
      isDuplicateRow,
      sizePageRatio,
      pageDirectionRatio,
      cellSize,
      isOnlyView,
      components,
      selectedCells,
      documentContainerSize,
      componentSelected,
      isComponentDragging,
      contentType,
      getContentTypeTaskItemData,
    ]
  );

  return { contentOfCellTypeTaskItemData };
};

export default useGetContentTypeTaskItemData;

import { Box, Flex, Input, Spinner, Text } from "@chakra-ui/react";
import { documentGroupApi } from "apiClient/v2";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import { OPTION_ALL_AREA, OPTION_ALL_FLOOR } from "constants/area";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import { MessageType } from "constants/websocket";
import { useAuthorization } from "hooks/usePermission";
import useUserOfProject from "hooks/useUserOfProject";
import { DataLog } from "interfaces/models/dataLog";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { insertDataLogToIndexedDb } from "models/dataLog";

import { LeftPanelHandleType } from "pages/forge-viewer/LeftPanel";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentGroup } from "redux/documentSlice";
import { RootState } from "redux/store";
import { uuid } from "utils/common";
import { ENTITY_NAME } from "utils/data-logs";
import { getLocalStorageUser } from "utils/user";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import ItemLog from "../DocumentItemModal/ItemLog";
import useGroupLogs from "./useGroupLogs";
import { sortArrayByField } from "utils/array";
import { isLatestByUpdatedTime } from "utils/date";
import IconFolder from "components/icon/IconFolder";

interface Props {
  documentGroup: DocumentGroup;
  leftPanelRef?: React.RefObject<LeftPanelHandleType>;
  isLoadingDocument: boolean;
}

export type DocumentGroupModelHandleType = {
  handleCollapse: (val?: boolean) => void;
};

const DocumentGroupModal = forwardRef<DocumentGroupModelHandleType, Props>(
  ({ documentGroup, isLoadingDocument }, ref) => {
    const [documentGroupSelected, setDocumentGroupSelected] =
      useState<DocumentGroup>();
    const [formInfo, setFormInfo] = useState<DocumentGroup>();
    const { isCollapsed, setIsCollapsed } = useCollapseModal();
    const dispatch = useDispatch();
    const roles = useAuthorization();
    const lastLoadingDocumentRef = useRef(isLoadingDocument);
    const { isLoadMoreLogs, groupLogs, setGroupLogs, handleRevertLog } =
      useGroupLogs({
        groupId: documentGroup.id,
      });
    const { currentUser, listUserById, listAllUserById } = useUserOfProject();

    const onRevertDataByLog = useCallback(
      (log: DataLog) => {
        handleRevertLog(log, formInfo, setFormInfo);
      },
      [handleRevertLog, formInfo, setFormInfo]
    );

    useEffect(() => {
      if (
        !isLoadingDocument &&
        lastLoadingDocumentRef.current !== isLoadingDocument
      ) {
        // reset form data
        setFormInfo({ ...(documentGroup || {}) });
        setDocumentGroupSelected({ ...(documentGroup || {}) });
      }
      lastLoadingDocumentRef.current = isLoadingDocument;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingDocument]);

    const { sizePanel } = useSelector((state: RootState) => state.app);

    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );

    const { socket, webSocketMessages } = useForgeViewerContext();

    useEffect(() => {
      if (!webSocketMessages.length) return;
      webSocketMessages.forEach((e) => {
        if (
          e.type === MessageType.CHANGE_DOC_GROUP &&
          e.docGroupId === formInfo?.id
        ) {
          setFormInfo((formInfo) => {
            if (!isLatestByUpdatedTime(formInfo, e.data)) return formInfo;

            return {
              ...formInfo,
              ...e.data,
            };
          });
        }
      });
    }, [formInfo?.id, webSocketMessages]);

    useEffect(() => {
      if (documentGroupSelected?.id !== documentGroup?.id) {
        setIsCollapsed(false);
        // set new data
        setTimeout(() => {
          setFormInfo({ ...(documentGroup || {}) });
          setDocumentGroupSelected({ ...(documentGroup || {}) });
        }, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentGroupSelected?.id, documentGroup?.id]);

    const parentRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      handleCollapse: (val = true) => {
        if (val) {
          setIsCollapsed(true);
        } else {
          setIsCollapsed(false);
        }
      },
    }));

    const handleUpdateData = useCallback(async () => {
      if (documentGroupSelected?.id === formInfo?.id) {
        if (
          documentGroupSelected?.name === formInfo?.name ||
          !formInfo?.name?.trim()
        ) {
          return;
        }
        const dataClone = {
          ...formInfo,
          updatedAt: formInfo.updatedAt || new Date(),
        } as any;
        if ((dataClone as any).documentItems) {
          delete (dataClone as any).documentItems;
        }
        if ((dataClone as any).documentSubCategories) {
          delete (dataClone as any).documentSubCategories;
        }

        dataClone.neptuneAreaIds = dataClone.neptuneAreaIds?.filter(
          (e: string) => ![OPTION_ALL_AREA, OPTION_ALL_FLOOR].includes(e)
        );

        const requestId = uuid();
        dataClone.requestId = requestId;
        const response = await documentGroupApi.updateGroup(dataClone);
        dispatch(updateDocumentGroup({ ...formInfo, ...response.data } as any));
        setDocumentGroupSelected(formInfo);
        const field: keyof DocumentGroup = "name";
        const currentUser = getLocalStorageUser();
        const log = await insertDataLogToIndexedDb({
          contentLogParams: {
            field: field as any,
            value: formInfo.name,
            requestId,
          },
          createdBy: currentUser?.id!,
          identifyValue: formInfo.id,
          identifyCol: "groupId",
        });

        setGroupLogs((prev) =>
          sortArrayByField<DataLog>([log, ...prev], "createdAt", false)
        );

        socket.changeDocGroup(formInfo?.id, {
          name: formInfo.name,
          updatedAt: response.data.updatedAt,
        });
        socket.addDocGroupLog(formInfo?.id, log);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      documentGroupSelected?.id,
      documentGroupSelected?.name,
      formInfo,
      dispatch,
    ]);

    const onChangeName = (e: any) => {
      setFormInfo({
        ...formInfo,
        name: e.target.value,
        updatedAt: new Date(),
      } as DocumentGroup);
    };

    const onBlurName = () => {
      console.log("...");
      handleUpdateData();
    };

    const handleCollapse = () => {
      !isCollapsed && handleUpdateData();
      setIsCollapsed((prev) => !prev);
    };

    return (
      <>
        <Box
          className={RIGHT_SIDEBAR_MODAL_CLASSNAME}
          w={isCollapsed ? "0" : widthPanel}
          position="absolute"
          right={0}
          height="100%"
          zIndex={11}
          transition="all 200ms ease-in-out 0s"
          filter={`drop-shadow(${"rgba(0, 0, 0, 0.15)"} 0px 8px 12px) drop-shadow(${"rgba(0, 0, 0, 0.3)"} 0px 4px 4px)`}
        >
          <Box
            ref={parentRef}
            bgColor="#FFFFFF"
            p="0"
            width="100%"
            height="100%"
            maxW={{ md: "480px" }}
            position="relative"
            flexDirection="column"
          >
            <CollapseModalIcon
              backgroundColor="white"
              borderRadius="5px 0px 0px 5px"
              borderRightStyle="hidden"
              borderLeftStyle="solid"
              height="12rem"
              width="3.8rem"
              top="50%"
              iconProps={{
                transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
              }}
              transform="translateY(-50%)"
              onClose={handleCollapse}
            />

            <Flex
              ref={containerRef}
              flexDirection="column"
              overflow="hidden auto"
              gap="20rem"
              h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
            >
              <>
                {(isLoadingDocument || !formInfo?.id) && (
                  <Flex
                    position="absolute"
                    inset="0px"
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    zIndex="1000"
                    backgroundColor="white"
                  >
                    <Spinner color="blue.500" size="lg" mt="1rem" />
                  </Flex>
                )}

                {/* document category name */}
                <Flex p="16px" direction={"column"}>
                  <Flex alignItems="center" flexDirection="row">
                    <IconFolder width="4.8rem" height="4.8rem" />
                    <Flex ml={"1.2rem"} w="100%" flexDirection="column">
                      <Text
                        fontSize="1.4rem"
                        mb="0.5rem"
                        as={"span"}
                        textAlign="left"
                        color="#171717"
                      >
                        フォルダ名
                      </Text>
                      <Input
                        value={formInfo?.name || ""}
                        height="4rem"
                        fontSize="1.4rem"
                        w="100%"
                        _placeholder={{
                          color: "#A3A3A3",
                          opacity: 1,
                        }}
                        placeholder="フォルダ名"
                        borderColor="#A3A3A3"
                        onChange={onChangeName}
                        onBlur={onBlurName}
                        isDisabled={!roles.canEditFolder}
                      />
                      {!formInfo?.name?.trim() && formInfo?.id && (
                        <Text fontSize="1.2rem" mt="0.3rem" color="#EF4444">
                          フォルダ名が必要です。
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Box backgroundColor="#E8E8E8" mt="auto" padding="2rem 1.6rem">
                  <Text
                    display="block"
                    fontSize="1.6rem"
                    color="var(--primary-text-color)"
                    fontWeight="bold"
                    mb="1.2rem"
                  >
                    変更履歴
                  </Text>
                  {groupLogs?.map((item) => (
                    <ItemLog
                      key={item.id}
                      log={item}
                      entityName={ENTITY_NAME.FOLDER}
                      currentUser={currentUser}
                      user={listAllUserById?.[item?.createdBy || ""]}
                      listUserById={listUserById}
                      mapDynamicFieldTitle={{}}
                      documentItem={undefined}
                      onRevertDataByLog={onRevertDataByLog}
                    />
                  ))}

                  {isLoadMoreLogs && (
                    <Flex
                      height="4rem"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Spinner size="md" color="blue.500" />
                    </Flex>
                  )}
                </Box>
              </>
            </Flex>
          </Box>
        </Box>
      </>
    );
  }
);

export default DocumentGroupModal;

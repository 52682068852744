import { Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import CrossLine from "components/editor-builder/component-preview/TableComponentPreview/CrossLine";
import { NodeType } from "../type";

const Component = () => {
  return (
    <NodeViewWrapper
      style={{ position: "absolute", width: "100%", height: "100%", inset: 0 }}
    >
      <CrossLine />
    </NodeViewWrapper>
  );
};

export const CrossLineNode = Node.create({
  name: NodeType.CROSS_LINE,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  parseHTML() {
    return [
      {
        tag: "cross-line",
      },
    ];
  },
  renderHTML() {
    return ["cross-line"];
  },
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

import { useEffect, useState } from "react";
import { doGetMCEKey, doRefreshToken } from "utils/authen";

const PrivateRoute = ({
  children,
  callbackUrl,
}: {
  children: JSX.Element;
  callbackUrl?: string;
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await doRefreshToken();
      doGetMCEKey();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <></>;
  }

  return children;
};

export default PrivateRoute;

import { PlacementWithLogical, Text, TextProps } from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";
import useScroll from "hooks/useScroll";
import useTextHover from "hooks/useTextHover";
import { memo, useMemo } from "react";

interface Props {
  parentRef: React.RefObject<HTMLDivElement>;
  label?: string | JSX.Element;
  isSelected?: boolean;
  isDisabled?: boolean;
  placement?: PlacementWithLogical;
  isCheckTextOverflow?: boolean;
  offset?: [number, number];
  children?: JSX.Element;
}

const CustomTooltipByScroll = ({
  parentRef,
  label,
  isSelected,
  isDisabled = false,
  placement,
  isCheckTextOverflow = false,
  offset = [0, 0],
  className = "",
  children,
  ...rest
}: Props & TextProps) => {
  const { hoverStatus, textElementRef } = useTextHover();
  const { isScroll } = useScroll({ parentRef });

  const renderChildren = useMemo(() => {
    if (children) {
      return children;
    }

    return (
      <Text
        ref={textElementRef}
        color="#171717"
        fontWeight={700}
        _before={{ content: '""' }}
        width="100%"
        whiteSpace="pre"
        lineHeight="normal"
        noOfLines={1}
        className={`text-ellipsis ${className} ${
          isSelected ? "selected-cell" : ""
        }`}
        {...rest}
      >
        {label || "-"}
      </Text>
    );
  }, [textElementRef, children, className, isSelected, label, rest]);

  if (isScroll) {
    return <>{renderChildren}</>;
  }

  return (
    <PCTooltip
      label={label || "-"}
      isDisabled={isCheckTextOverflow ? !hoverStatus : isDisabled}
      placement={placement || "bottom"}
      offset={offset}
      fontSize="1.2rem"
    >
      {renderChildren}
    </PCTooltip>
  );
};

export default memo(CustomTooltipByScroll);

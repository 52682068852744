type Callback = (element: HTMLElement) => void;

class EventRender {
  protected listeners: Record<string, Callback[]> = {};
  protected renders = new Map<string, HTMLElement>();

  mount(key: string, element: HTMLElement) {
    this.renders.set(key, element);
    // console.log(Array.from(this.renders));
    if (!this.listeners[key]) return;
    this.listeners[key].map((callback) => callback(element));
    this.listeners[key] = [];
  }

  unmount<T = any>(key: string) {
    // console.log(Array.from(this.renders));
    this.renders.delete(key);
  }

  onMount(key: string, callback: Callback) {
    if (!this.listeners[key]) this.listeners[key] = [];
    this.listeners[key].push(callback);
    if (event.isMounted(key)) {
      callback(this.renders.get(key)!);
      this.listeners[key] = this.listeners[key].filter(
        (func) => func !== callback
      );
    }
  }

  isMounted(id: string) {
    return this.renders.has(id);
  }
}

const event = new EventRender();

export const callMountEvent = (id: string, element: HTMLElement) =>
  event.mount(id, element);

export const callUnMountEvent = (id: string) => event.unmount(id);

export const waitRenderEvent = (id: string): Promise<HTMLElement> => {
  return new Promise((resolve) => {
    event.onMount(id, (element: HTMLElement) => {
      resolve(element);
    });
  });
};

import {
  getContentTypeComment,
  getValueFromFamilyInstace,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  FamilyInstanceKey,
  LinkedDataField,
  TemplateComponentType,
  TextPosition,
} from "constants/enum";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { FileUploadInfo } from "interfaces/models";
import { CellType } from "interfaces/models/component";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import { ImageWithBlackboardNodeAttrs } from "../extendsions";
import { CustomNode, GetContentTypeDocumentType, NodeType } from "../type";
import { getContentNodeTypeNormalText } from "../utils";
import BaseStructureTable, {
  BaseStructureTableProps,
} from "./BaseStructureTable";
import ContentTdTypeCommon from "./ContentTdTypeCommon";
import ContentTdTypeLinkedImage from "./ContentTdTypeLinkedImage";

class ContentTdTypePhotoLedger extends BaseStructureTable {
  protected contentTdTypeLinkedImage: ContentTdTypeLinkedImage;
  protected contentTdTypeCommon: ContentTdTypeCommon;

  constructor(params: BaseStructureTableProps) {
    super(params);
    this.contentTdTypeLinkedImage = new ContentTdTypeLinkedImage(params);
    this.contentTdTypeCommon = new ContentTdTypeCommon(params);
  }

  getContentTdTypeCommentMangeExecute(params: {
    cells: CellType[];
    familyInstance: FamilyInstanceDTO;
    commentManageExecute: any[];
  }) {
    const { cells, familyInstance, commentManageExecute } = params;

    if (!commentManageExecute?.length || !Array.isArray(commentManageExecute)) {
      if (!cells.length) {
        return [
          getContentNodeTypeNormalText({
            text: undefined,
            style: undefined,
            isMultiple: true,
            isSubCell: true,
          }),
        ];
      }

      return cells.map((cell) => {
        let value = cell?.value || undefined;
        const linkedDataField = cell.cellLinkedData?.field || "";
        if (cell?.cellProperty === CellProperty.FAMILY_INSTANCE) {
          const familyInstanceValue = getValueFromFamilyInstace(
            linkedDataField as FamilyInstanceKey,
            familyInstance
          );
          value = familyInstanceValue || undefined;
        }

        return getContentNodeTypeNormalText({
          text: value,
          style: cell.style,
          isMultiple: true,
          isSubCell: true,
        });
      });
    }

    return commentManageExecute.map(
      (comment: { value: string; textAlign?: TextPosition }) => {
        return getContentNodeTypeNormalText({
          text: comment.value,
          style: { justifyContent: comment.textAlign || TextPosition.START },
          isMultiple: true,
          isSubCell: true,
        });
      }
    );
  }

  getStructure(params: GetContentTypeDocumentType) {
    if (!this.page || !this.component) {
      return null;
    }

    const {
      parentCell,
      cell,
      width,
      height,
      cellIndex,
      rowIndex,
      subRowIndex = 1,
      subCellIndex = 1,
    } = params;

    let cellValue = cell.value;
    const linkedDataField = cell?.cellLinkedData?.field;

    if (this.component.type === TemplateComponentType.LinkedImage) {
      return this.contentTdTypeLinkedImage.getStructure(params);
    }

    const { offsetItemLinked } = this.page;
    const documentItem: DocumentItemDTO =
      this.documentItems?.[offsetItemLinked + rowIndex];
    const images: FileUploadInfo = documentItem?.images as any;
    const imageSrc = images?.src;
    const hasData = this.documentItems.length > 0;
    const noData = String(
      hasData
        ? documentItem?.keyNoteIndex || ""
        : isNumber(rowIndex)
        ? rowIndex + 1
        : ""
    );

    // transform old structure
    if ("cellData" in cell && cell.cellData === CellProperty.NO) {
      cellValue = noData;
    }

    // return type common
    const commonData = this.contentTdTypeCommon.getStructure({
      cell,
      currentCell: parentCell ?? cell,
      rowIndex,
      cellIndex,
      subRowIndex,
      subCellIndex,
      documentItem,
    });
    if (commonData) {
      return commonData;
    }

    // transform data
    switch (cell.cellProperty) {
      case CellProperty.TEXT: {
        return getContentNodeTypeNormalText({
          text: cellValue,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }

      case CellProperty.NO: {
        return getContentNodeTypeNormalText({
          text: noData,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }

      case CellProperty.IMAGE: {
        if (!documentItem || !imageSrc) {
          return {
            type: NodeType.CROSS_LINE,
          } as CustomNode<any>;
        }

        return {
          type: NodeType.IMAGE_WITH_BLACKBOARD,
          attrs: {
            imageSrc,
            width,
            height,
            isShowBlackboard: documentItem?.isShowBlackboard,
            blackboardImagePosition: documentItem?.blackboardImagePosition,
            blackboard: (documentItem as any)?.blackBoard,
            blackboardTemplateProps: {
              ...(this.blackboardTemplateProps || ({} as any)),
              documentSubItem: documentItem as any,
              familyInstance:
                this.familyInstances?.[documentItem.externalId || ""],
            },
          },
        } satisfies CustomNode<ImageWithBlackboardNodeAttrs>;
      }

      case CellProperty.BLACK_BOARD_DATA: {
        const takeImageDates = isEmpty(images)
          ? documentItem?.takeImageDates || []
          : [
              (images?.lastModifiedDateFile as any) ??
                (images?.uploadTime || ""),
            ];
        switch (linkedDataField) {
          case LinkedDataField.COMMON.NO: {
            return getContentNodeTypeNormalText({
              text: String(documentItem?.keyNoteIndex || ""),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE: {
            if (!documentItem || !imageSrc) {
              return getContentNodeTypeNormalText({
                text: undefined,
                style: cell.style,
                isSubCell: !!cell?.isSubCell,
              });
            }

            const familyInstance = this.component?.detail?.isRepeatTable
              ? this.familyInstances[
                  this.documentItems?.[subRowIndex - 1]?.externalId || ""
                ]
              : this.familyInstances[documentItem?.externalId || ""];
            const blackboardCells =
              cell?.cellLinkedData?.options?.blackboard?.cells || [];

            return this.getContentTdTypeCommentMangeExecute({
              cells: blackboardCells as CellType[],
              familyInstance,
              commentManageExecute: (documentItem as any)?.blackBoard
                ?.commentManageExecute,
            });
          }

          default: {
            return getContentNodeTypeNormalText({
              text: !images?.src
                ? undefined
                : (getContentTypeComment({
                    field: linkedDataField,
                    blackBoard: (documentItem as any)?.blackBoard,
                    documentItem: {
                      ...(documentItem as DocumentSubItemDTO),
                      takeImageDates,
                    },
                    projectName: this.projectName,
                    listOptionUsers:
                      this.blackboardTemplateProps?.listOptionUsers || [],
                    listAllUserById: this.listAllUserById,
                  }) as any),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }
        }
      }

      case CellProperty.DOCUMENT_DATA: {
        switch (linkedDataField) {
          case LinkedDataField.DOCUMENT_ITEM.TITLE:
            return getContentNodeTypeNormalText({
              text: !documentItem ? undefined : documentItem?.title || "-",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });

          case LinkedDataField.COMMON.IMAGE:
            if (!documentItem || !imageSrc) {
              return {
                type: NodeType.CROSS_LINE,
              } as CustomNode<any>;
            }

            return {
              type: NodeType.IMAGE_WITH_BLACKBOARD,
              attrs: {
                imageSrc,
                width,
                height,
                isShowBlackboard: documentItem?.isShowBlackboard,
                blackboardImagePosition: documentItem?.blackboardImagePosition,
                blackboard: (documentItem as any)?.blackBoard,
                blackboardTemplateProps: {
                  ...(this.blackboardTemplateProps || ({} as any)),
                  documentSubItem: documentItem as any,
                  familyInstance:
                    this.familyInstances?.[documentItem.externalId || ""],
                },
              },
            } as CustomNode<ImageWithBlackboardNodeAttrs>;

          default:
            return getContentNodeTypeNormalText({
              text: undefined,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
        }
      }

      default:
        return getContentNodeTypeNormalText({
          text: cellValue,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
    }
  }
}

export default ContentTdTypePhotoLedger;

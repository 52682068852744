import { message } from "components/base";
import {
  setIsCheckedPrecacheMissStaticFile,
  setIsIsInstallingServiceWorker,
  setIsServiceWorkerReady,
} from "redux/appSlice";
import store from "redux/store";
import { cacheDefaultData } from "utils/cache";
import { sleep } from "utils/common";
import { getIndexedDb } from "utils/indexedDb";
import { logDev, logError } from "utils/logs";

/* eslint-disable no-console */
/* eslint-disable no-console */
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const disableSW = process.env.NODE_ENV === "development";

export function register() {
  if (disableSW) {
    return;
  }
  if ("serviceWorker" in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    if (isLocalhost) {
      checkValidServiceWorker(swUrl);
      navigator.serviceWorker.ready.then(() => {
        console.warn(
          "This web app is being served cache-fir st by a service worker."
        );
      });
    } else {
      registerValidSW(swUrl);
    }
  }
}

async function invokeServiceWorkerUpdateFlow(
  registration: ServiceWorkerRegistration,
  shouldReload: boolean
) {
  await cacheDefaultData();
  if (registration.waiting) {
    registration.waiting?.postMessage("SKIP_WAITING");
    if (shouldReload) {
      await sleep(1000);
      window.location.reload();
    }
  }
}

async function registerValidSW(swUrl: string) {
  const updateStateInstalling = (isInstalling = true) => {
    store.dispatch(setIsServiceWorkerReady(!isInstalling));
    store.dispatch(setIsIsInstallingServiceWorker(isInstalling));
  };

  try {
    const registration = await navigator.serviceWorker.register(swUrl);

    const onAfterInstallSW = async (shouldReload = true) => {
      if (registration.active) {
        await invokeServiceWorkerUpdateFlow(registration, shouldReload);
        if (!shouldReload) {
          updateStateInstalling(false);
        }
        console.log("Close all tabs to get updates. (installing and active)");
      } else {
        try {
          await getIndexedDb();
          await cacheDefaultData();
        } catch (err) {
          console.log("err", err);
        }
        registration?.waiting?.postMessage("SKIP_WAITING");
        console.log("Content is cached for the first time (only install)");
        window.location.reload();
      }
    };

    navigator.serviceWorker.addEventListener("message", (e) => {
      if (e.data.type === "errorCache") {
        message.error("アプリのインストールに失敗しました");
        logError(e.data.message);
      } else if (e.data.type === "versionInfo") {
        const style = "color:green; font-size:16px;";
        console.log(`%c Current version is:  ${e.data.message}`, style);
      }
    });

    if (registration.waiting || registration.installing) {
      updateStateInstalling();
    }
    if (registration.waiting) {
      // Basically there is no need to reload when update service worker because we always get network first index.html page
      await onAfterInstallSW(false);
      registration?.waiting?.postMessage("SKIP_WAITING");
      console.log("Close all tabs when has sw waiting");

      return;
    }

    navigator.serviceWorker.ready.then((registration) => {
      console.log("service worker ready");
      store.dispatch(setIsServiceWorkerReady(true));
      updateStateInstalling(false);
      registration.active?.postMessage("GET_VERSION");
    });

    registration.addEventListener("updatefound", (event) => {
      if (registration.installing) {
        console.log("installing sw");
        updateStateInstalling();
        registration.installing.addEventListener(
          "statechange",
          async (event: any) => {
            if (event.target.state === "installed") {
              onAfterInstallSW();
            }
          }
        );
      }
    });
  } catch (err) {
    message.error("アプリのインストールに失敗しました");
    updateStateInstalling();
    logError(err);
  }
}

function checkValidServiceWorker(swUrl: string) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        store.dispatch(setIsCheckedPrecacheMissStaticFile(true));
        navigator.serviceWorker.ready.then((registration) => {
          console.log("unregister");
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.warn(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    // send a message command to the service-worker to self-destroy, and the service-worker in turn reloads all controlled clients.
    navigator.serviceWorker.ready.then((registration: any) => {
      registration.active.postMessage("unregister");
    });
  }
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { blackboardApi, blackboardTemplateApi } from "apiClient/v2";
import { DEFAULT_TEMPLATE } from "constants/documentTemplate";
import {
  DocumentCategoryKey,
  DocumentCategoryStatusType,
  DocumentItemKey,
  TemplateComponentType,
} from "constants/enum";
import { REDUCER_KEY } from "constants/redux";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import {
  CellType,
  CustomTemplateComponent,
  PageFormat,
  TemplateComponent,
} from "interfaces/models/component";
import { DataLog } from "interfaces/models/dataLog";
import { DocumentSubCategory } from "interfaces/models/documentCategory";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import sortBy from "lodash/sortBy";
import { isPhotoLedgerTemplate } from "models/documentCategory";
import { sortArrayByField, updateElementInArray } from "utils/array";
import { getStatusVisibleOfItem } from "utils/documentItem";
import { selectDbIds } from "utils/forge";

export const DOCUMENT_DEFAULT_STATE = {
  documents: [],
  selectedCells: [],
  componentSelected: {} as TemplateComponent,
  components: [],
  customComponents: [],
  customComponentSelected: {} as CustomTemplateComponent,
  isEditText: false,
  isCreateComponent: false,
  documentContainerSize: {
    width: 0,
    height: 0,
  },
  isDisableHeaderDragging: false,
  isComponentDragging: false,
  dropComponentSelected: TemplateComponentType.Table,
  dropComponentType: TemplateComponentType.Table,
  currentTemplate: DEFAULT_TEMPLATE,
  currentTemplateRawData: {} as DocumentTemplate,
  isShowModalBlackBoard: false,
  isOpenSelectBlackboardTemplateModal: false,
  isOpenBlackboardTemplateSettingModal: false,
  documentCategories: [],
  documentItems: [],
  documentGroupSelected: undefined,
  documentGroups: [],
  documentTemplates: {},
  defaultDocumentTemplates: {},
  dataBlackboards: [],
  isLoadingDocument: true,
  isContentFromS3: null, // has 3 values: null (init), false, true
  pageSelected: DEFAULT_TEMPLATE.pages?.[0] as PageFormat,
  currentBlackboardTemplate: {} as BlackboardTemplate,
  blackboardTemplateList: [],
  isLoadedBlackboard: false,
  isEditingFamily: false,
  isMovingDoc: false,
  selectedItemIdsWhenMovingDoc: [],
  isCaptureKeynoteByOperation: false,
  shouldSaveTemplate: false,
  shouldSaveTaskSheetTemplate: false,
  isLoadingDocumentItem: false,
  isFetchingDocument: false,
  isGeneratingPdf: false,
  mapPresignedUrl: {},
  totalPagePreview: 0,
  nextPreviewPage: 0,
  documentItemIdsCreated: [],
  dataLogTemp: undefined,
};

export interface DocumentState {
  isGeneratingPdf: boolean;
  mapPresignedUrl: { [k: string]: string };
  totalPagePreview: number;
  nextPreviewPage: number;
  documents: DocumentDTO[];
  selectedCells: CellType[];
  componentSelected: TemplateComponent;
  components: TemplateComponent[];
  customComponents: CustomTemplateComponent[];
  customComponentSelected: CustomTemplateComponent;
  isEditText: boolean;
  isCreateComponent: boolean;
  documentContainerSize: {
    width: number;
    height: number;
  };
  isDisableHeaderDragging?: boolean;
  isComponentDragging?: boolean;
  dropComponentSelected?: string;
  dropComponentType?: string;
  currentTemplate: DocumentTemplate;
  currentTemplateRawData: DocumentTemplate;
  isShowModalBlackBoard: boolean;
  isOpenSelectBlackboardTemplateModal: boolean;
  isOpenBlackboardTemplateSettingModal: boolean;
  documentCategories: DocumentCategoryDTO[];
  documentItems: DocumentItemDTO[];
  documentTemplates: { [key: string]: DocumentTemplateDTO };
  defaultDocumentTemplates: { [key: string]: DocumentTemplate };
  documentItemSelected?: DocumentItemDTO;
  documentCategorySelected?: DocumentCategoryDTO;
  documentSubCategorySelected?: DocumentSubCategory;
  dataBlackboards: Blackboard[];
  documentGroups: DocumentGroup[];
  documentGroupSelected: DocumentGroup | undefined;
  isLoadingDocument: boolean;
  isFetchingDocument: boolean;
  isLoadedBlackboard: boolean;
  isEditingFamily: boolean;
  isMovingDoc: boolean;
  selectedItemIdsWhenMovingDoc: string[];
  isContentFromS3: boolean | null;
  pageSelected: PageFormat;
  currentBlackboardTemplate: BlackboardTemplate;
  blackboardTemplateList: BlackboardTemplate[];
  documentTemplateSelected?: DocumentTemplateDTO;
  isCaptureKeynoteByOperation: boolean;
  shouldSaveTemplate: boolean;
  shouldSaveTaskSheetTemplate: boolean;
  isLoadingDocumentItem: boolean;
  documentItemIdsCreated: Array<string>;
  dataLogTemp?: DataLog | undefined;
}

const initialState: DocumentState = {
  ...DOCUMENT_DEFAULT_STATE,
};

export const fetchBlackBoardByCategoryId = createAsyncThunk(
  "document/fetchBlackBoardByCategoryId",
  async (documentCategoryId: string) => {
    const { data } = await blackboardApi.getBlackboardList({
      documentCategoryId,
    });

    return data || [];
  }
);

export const fetchBlackboardTemplates = createAsyncThunk(
  "document/fetchBlackboardTemplates",
  async () => {
    const { data } = (await blackboardTemplateApi.getTemplateList()) || [];

    return data || [];
  }
);

const updateDocumentItemsCallBack =
  (updatedDocumentItems: DocumentItemDTO[]) => (docItem: DocumentItemDTO) => {
    const updatedDocumentItem = updatedDocumentItems?.find(
      (item) => item.id === docItem.id
    ) as DocumentItemDTO;

    if (
      updatedDocumentItems?.some(
        (updatedDocItem) => updatedDocItem.id === docItem.id
      )
    ) {
      return updatedDocumentItem;
    }

    return docItem;
  };

export const documentSlice = createSlice({
  name: REDUCER_KEY.DOCUMENT,
  initialState,
  reducers: {
    setDocuments: (state, action: PayloadAction<DocumentDTO[]>) => {
      state.documents = Array.isArray(action.payload) ? action.payload : [];
    },
    setDocumentGroups: (state, action: PayloadAction<DocumentGroup[]>) => {
      state.documentGroups = Array.isArray(action.payload)
        ? action.payload
        : [];
    },
    setDocumentGroupSelected: (
      state,
      action: PayloadAction<DocumentGroup | undefined>
    ) => {
      state.documentGroupSelected = action.payload;
    },
    addDocumentGroup: (state, action: PayloadAction<DocumentGroup>) => {
      state.documentGroups = [action.payload, ...state.documentGroups];
    },
    removeDocumentGroup: (state, action: PayloadAction<string>) => {
      state.documentGroups = state.documentGroups.filter(
        (item) => item.id !== action.payload
      );
      if (state.documentGroupSelected?.id === action.payload) {
        state.documentGroupSelected = undefined;
      }
    },
    updateDocumentGroup: (state, action: PayloadAction<DocumentGroup>) => {
      updateElementInArray({
        array: state.documentGroups,
        keyIndex: "id",
        element: action.payload,
      });
      if (action.payload?.id === state.documentGroupSelected?.id) {
        state.documentGroupSelected = action.payload;
      }
    },
    setSelectedCell: (state, action: PayloadAction<CellType[]>) => {
      state.selectedCells = [...action.payload];
    },
    setComponentSelected: (
      state,
      action: PayloadAction<TemplateComponent | undefined>
    ) => {
      state.componentSelected = action.payload || ({} as TemplateComponent);
    },
    setCustomComponentSelected: (
      state,
      action: PayloadAction<CustomTemplateComponent | undefined>
    ) => {
      state.customComponentSelected =
        action.payload || ({} as CustomTemplateComponent);
    },
    updateComponent: (state, action: PayloadAction<TemplateComponent>) => {
      const component = action.payload;

      updateElementInArray({
        array: state.components,
        keyIndex: "componentId",
        element: component,
      });

      if (component.componentId === state.componentSelected.componentId) {
        state.componentSelected = component;
      }
    },
    setComponents: (state, action: PayloadAction<TemplateComponent[]>) => {
      state.components = [...action.payload];
      state.componentSelected =
        state.components.find(
          (i) => i.componentId === state.componentSelected?.componentId
        ) ?? ({} as TemplateComponent);
    },
    setCustomComponents: (
      state,
      action: PayloadAction<CustomTemplateComponent[]>
    ) => {
      state.customComponents = [...action.payload];
    },
    setIsEditText: (state, action: PayloadAction<boolean>) => {
      state.isEditText = action.payload;
    },
    setIsCreateComponent: (state, action: PayloadAction<boolean>) => {
      state.isCreateComponent = action.payload;
    },
    setDocumentContainerSize: (
      state,
      action: PayloadAction<{ width: number; height: number }>
    ) => {
      state.documentContainerSize = action.payload;
    },
    setIsDisableHeaderDragging: (state, action: PayloadAction<boolean>) => {
      state.isDisableHeaderDragging = action.payload;
    },
    setIsComponentDragging: (state, action: PayloadAction<boolean>) => {
      state.isComponentDragging = action.payload;
    },
    setDropComponentSelected: (state, action: PayloadAction<string>) => {
      state.dropComponentSelected = action.payload;
    },
    setDropComponentType: (state, action: PayloadAction<string>) => {
      state.dropComponentType = action.payload;
    },
    setCurrentTemplate: (state, action: PayloadAction<DocumentTemplate>) => {
      state.currentTemplate = {
        ...action.payload,
        isDefault: !action?.payload?.projectId,
      };
    },
    setCurrentTemplateRawData: (
      state,
      action: PayloadAction<DocumentTemplate>
    ) => {
      state.currentTemplateRawData = action.payload;
    },
    setShowModalBlackBoard: (state, action: PayloadAction<boolean>) => {
      state.isShowModalBlackBoard = action.payload;
    },
    setOpenSelectBlackboardTemplateModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenSelectBlackboardTemplateModal = action.payload;
    },
    setOpenBlackboardTemplateSettingModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenBlackboardTemplateSettingModal = action.payload;
    },
    setDocumentCategories(state, action: PayloadAction<DocumentCategoryDTO[]>) {
      state.documentCategories = action.payload;
    },
    setDocumentCategoriesAndSelected(
      state,
      action: PayloadAction<DocumentCategoryDTO[]>
    ) {
      const newDocumentCategories = action.payload;
      const documentCategorySelected = state.documentCategorySelected;
      state.documentCategories = newDocumentCategories;
      if (documentCategorySelected?.id) {
        const newDocumentCategorySelected = newDocumentCategories.find(
          (item) => item.id === documentCategorySelected.id
        );
        state.documentCategorySelected = newDocumentCategorySelected;
      }
    },
    setDocumentItems(state, action: PayloadAction<DocumentItemDTO[]>) {
      const documentItems = action.payload;
      state.documentItems = documentItems;

      const documentItemSelected = documentItems.find(
        (item) => state.documentItemSelected?.id === item.id
      );
      if (documentItemSelected) {
        state.documentItemSelected = documentItemSelected;
      }
    },
    addDocumentItems(state, action: PayloadAction<DocumentItemDTO[]>) {
      const newDocumentItems = action.payload;
      const documentItems = state.documentItems.concat(newDocumentItems);

      state.documentItems = sortArrayByField(documentItems, "indexId");
    },
    removeDocumentItems(state, action: PayloadAction<string[]>) {
      const deleteItemIds = action.payload;

      state.documentItems = state.documentItems.filter(
        (doc) => doc.id && !deleteItemIds.includes(doc.id)
      );

      if (
        state.documentItemSelected &&
        deleteItemIds.includes(state.documentItemSelected.id)
      ) {
        state.documentItemSelected = undefined;
      }
    },
    setIsLoadingDocument(state, action: PayloadAction<boolean>) {
      state.isLoadingDocument = action.payload;
    },
    setIsLoadingDocumentItem(state, action: PayloadAction<boolean>) {
      state.isLoadingDocumentItem = action.payload;
    },
    toggleIsEditingFamily(state, action: PayloadAction<boolean>) {
      state.isEditingFamily = action.payload;
    },
    toggleIsMovingDoc(state, action: PayloadAction<boolean>) {
      state.isMovingDoc = action.payload;
    },
    toggleIsCaptureKeynoteByOperation(state, action: PayloadAction<boolean>) {
      state.isCaptureKeynoteByOperation = action.payload;
    },
    toggleShouldSaveTemplate(state, action: PayloadAction<boolean>) {
      state.shouldSaveTemplate = action.payload;
    },
    toggleShouldSaveTaskSheetTemplate(state, action: PayloadAction<boolean>) {
      state.shouldSaveTaskSheetTemplate = action.payload;
    },
    setDataBlackboards: (state, action: PayloadAction<Blackboard[]>) => {
      state.dataBlackboards = action.payload || [];
    },
    setSelectedItemIdsWhenMovingDoc: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.selectedItemIdsWhenMovingDoc = action.payload || [];
    },
    setDocumentTemplateSelected: (
      state,
      action: PayloadAction<DocumentTemplateDTO | undefined>
    ) => {
      state.documentTemplateSelected = action.payload;
    },
    setDataBlackboard: (state, action: PayloadAction<Blackboard>) => {
      const dataBlackboard = action.payload;
      const dataBlackboards = [...state.dataBlackboards];
      const index = dataBlackboards.findIndex(
        (d) => d.id === dataBlackboard.id
      );
      if (index !== -1) {
        dataBlackboards[index] = {
          ...dataBlackboards[index],
          ...dataBlackboard,
        };
      } else {
        dataBlackboards.push(dataBlackboard);
      }

      state.dataBlackboards = dataBlackboards;
    },
    setBlackboardWithIndex: (
      state,
      {
        payload: { blackboard, index },
      }: PayloadAction<{ blackboard: Blackboard; index: number }>
    ) => {
      const dataBlackboards = [...state.dataBlackboards];
      if (index !== -1) {
        state.dataBlackboards[index] = {
          ...dataBlackboards[index],
          ...blackboard,
        };
      } else {
        state.dataBlackboards.push(blackboard);
      }
    },
    setPageSeleted: (state, action: PayloadAction<PageFormat>) => {
      state.pageSelected = action.payload;
    },
    updateDocumentItem: (
      state,
      action: PayloadAction<
        DocumentItemDTO & {
          selectedExternalIds?: string[];
          isUpdateVisibleItem?: boolean;
        }
      >
    ) => {
      const payload = action.payload;
      const documentItem = structuredClone(payload);
      const selectedExternalIds = payload?.selectedExternalIds;
      const isUpdateVisibleItem = payload?.isUpdateVisibleItem;
      delete documentItem?.selectedExternalIds;
      delete documentItem?.isUpdateVisibleItem;

      updateElementInArray({
        array: state.documentItems,
        keyIndex: DocumentItemKey.ID,
        element: documentItem,
      });

      const documentCategories = state.documentCategories.filter(
        (item) =>
          item.level === documentItem.level &&
          item.templateId === documentItem.templateId
      );

      const mapDocumentCategories: { [id: string]: DocumentCategoryDTO } = {};

      const isUpdateStatus = [
        DocumentCategoryStatusType.SendBack,
        DocumentCategoryStatusType.Approved,
      ].includes(documentItem.status as any);

      documentCategories.forEach((documentCategory) => {
        updateElementInArray({
          array: documentCategory?.documentItems || [],
          keyIndex: DocumentItemKey.ID,
          element: { ...documentItem, documentCategoryId: documentCategory.id },
        });

        if (isUpdateStatus) {
          documentCategory.status = documentItem.status;
        }

        documentCategory.updatedAt = new Date();
        if (
          isPhotoLedgerTemplate(documentCategory.documentType) &&
          isUpdateVisibleItem
        ) {
          const { isHideAllItem, isShowAllItem } =
            getStatusVisibleOfItem(documentItem);

          const setSelectedExternalIds = new Set(
            documentCategory?.selectedExternalIds || []
          );
          isHideAllItem && setSelectedExternalIds.delete(documentItem.id);
          isShowAllItem && setSelectedExternalIds.add(documentItem.id);
          documentCategory.selectedExternalIds = Array.from(
            setSelectedExternalIds
          );
        } else if (selectedExternalIds) {
          documentCategory.selectedExternalIds = selectedExternalIds;
        }

        mapDocumentCategories[documentCategory.id] = documentCategory;
      });

      const documentCategorySelected =
        mapDocumentCategories[state.documentCategorySelected?.id || ""];

      if (documentCategorySelected?.id) {
        state.documentCategorySelected = documentCategorySelected;
      }

      if (state.documentItemSelected?.id === documentItem?.id) {
        if (documentCategorySelected?.id) {
          state.documentItemSelected = {
            ...documentItem,
            documentCategoryId: documentCategorySelected?.id,
          };
        } else {
          state.documentItemSelected = documentItem;
        }
      }

      state.documentCategories = state.documentCategories.map((category) => {
        return mapDocumentCategories?.[category?.id] || category;
      });
    },
    setAllDocItemAndDocCategory: (
      state,
      action: PayloadAction<{
        documentCategories: DocumentCategoryDTO[];
        documentItems: DocumentItemDTO[];
      }>
    ) => {
      state.documentCategories = action.payload.documentCategories;
      state.documentItems = action.payload.documentItems;
      if (state.documentCategorySelected) {
        state.documentCategorySelected = action.payload.documentCategories.find(
          (item) => item.id === state.documentCategorySelected?.id
        );
      }
      if (state.documentItemSelected) {
        const category = action.payload.documentCategories.find(
          (category) =>
            category.id === state.documentItemSelected?.documentCategoryId
        );

        const item = (category?.documentItems || []).find(
          (i) => i.id === state.documentItemSelected?.id
        );
        state.documentItemSelected = item || undefined;
        if (!item) {
          selectDbIds([], {});
        }
      }
    },
    updateDocumentItems: (state, action: PayloadAction<DocumentItemDTO[]>) => {
      const updatedDocumentItems = action.payload;

      /* Updating the documentItems */
      state.documentItems = state.documentItems.map(
        updateDocumentItemsCallBack(updatedDocumentItems)
      );

      /* Updating the documentCategories */
      const temp = state.documentCategories.map((category) => {
        const isIncludesCategory = updatedDocumentItems?.some(
          (updatedDocItem) =>
            updatedDocItem.level === category.level &&
            updatedDocItem.templateId === category.templateId &&
            updatedDocItem.areaIds?.some((id) =>
              category.neptuneAreaIds?.includes(id)
            )
        );

        if (isIncludesCategory) {
          return {
            ...category,
            documentItems: sortBy(
              category.documentItems?.map(
                updateDocumentItemsCallBack(updatedDocumentItems)
              ),
              [(item) => Number(item?.displayOrder)]
            ),
            documentSubCategories: category?.documentSubCategories?.map(
              (documentSubcategory) => {
                const isIncludesSubcategory = updatedDocumentItems?.some(
                  (item) =>
                    item.documentSubCategoryId === documentSubcategory.id
                );

                if (isIncludesSubcategory) {
                  return {
                    ...documentSubcategory,
                    documentItems: documentSubcategory?.documentItems?.map(
                      updateDocumentItemsCallBack(updatedDocumentItems)
                    ),
                  };
                }

                return documentSubcategory;
              }
            ),
          } as DocumentCategoryDTO;
        }

        return category;
      });

      state.documentCategories = temp;

      /* Updating the documentCategorySelected */
      state.documentCategorySelected = {
        ...state.documentCategorySelected,
        documentItems: sortBy(
          state.documentCategorySelected?.documentItems?.map(
            updateDocumentItemsCallBack(updatedDocumentItems)
          ),
          [(item) => Number(item?.displayOrder)]
        ),
        documentSubCategories:
          state.documentCategorySelected?.documentSubCategories?.map(
            (documentSubcategory) => {
              const isIncludesSubcategory = updatedDocumentItems?.some(
                (item) => item.documentSubCategoryId === documentSubcategory.id
              );

              if (isIncludesSubcategory) {
                return {
                  ...documentSubcategory,
                  documentItems: documentSubcategory?.documentItems?.map(
                    updateDocumentItemsCallBack(updatedDocumentItems)
                  ),
                };
              }

              return documentSubcategory;
            }
          ),
      } as DocumentCategoryDTO;

      /* Updating the documentItemSelected */
      const newStatus = updatedDocumentItems?.find(
        (item) => item?.id === state.documentItemSelected?.id
      )?.status;

      if (newStatus) {
        state.documentItemSelected = {
          ...state.documentItemSelected,
          status: newStatus,
        } as DocumentItemDTO;
      }
    },
    setDocumentItemSelected: (
      state,
      action: PayloadAction<DocumentItemDTO | undefined>
    ) => {
      state.documentItemSelected = action.payload;
    },
    updateDocumentInfoOfTemplate: (
      state,
      action: PayloadAction<{
        documentItems: DocumentItemDTO[];
        documentCategories: DocumentCategoryDTO[];
      }>
    ) => {
      const mapAllItems: { [id: string]: DocumentItemDTO } = Object.assign(
        {},
        ...state.documentItems.map((item) => ({ [item.id]: item }))
      );
      action.payload.documentItems.forEach((item) => {
        mapAllItems[item.id] = item;
      });
      const mapAllCategories: { [id: string]: DocumentCategoryDTO } =
        Object.assign(
          {},
          ...state.documentCategories.map((category) => ({
            [category.id]: category,
          }))
        );
      action.payload.documentCategories?.forEach((category) => {
        const currentCategory = mapAllCategories[category.id];
        if (!currentCategory?.id) {
          return;
        }
        mapAllCategories[category.id] = category;
      });
      const documentCategorySelected = action.payload.documentCategories?.find(
        (category) => category.id === state?.documentCategorySelected?.id
      );

      if (documentCategorySelected?.id) {
        state.documentCategorySelected = documentCategorySelected;
      }
      state.documentCategories = Object.values(mapAllCategories);
      state.documentItems = Object.values(mapAllItems);
    },
    updateDocumentTemplate: (
      state,
      action: PayloadAction<DocumentTemplateDTO>
    ) => {
      const documentTemplate = action.payload;
      const currentDocumentTemplate =
        state.documentTemplates?.[documentTemplate?.id || ""];
      if (!currentDocumentTemplate?.id || !documentTemplate.id) {
        return;
      }
      state.documentTemplates[documentTemplate.id] = documentTemplate;
    },
    updateDocumentCategories: (
      state,
      action: PayloadAction<DocumentCategoryDTO[]>
    ) => {
      const documentCategories = action.payload;
      // update categories
      documentCategories.forEach((documentCategory) => {
        updateElementInArray({
          array: state.documentCategories,
          keyIndex: DocumentCategoryKey.ID,
          element: documentCategory,
        });

        if (documentCategory.id === state.documentCategorySelected?.id) {
          state.documentCategorySelected = documentCategory;
        }
      });
    },
    updateDocumentCategory: (
      state,
      action: PayloadAction<DocumentCategoryDTO>
    ) => {
      const documentCategory = action.payload;
      // update categories
      updateElementInArray({
        array: state.documentCategories,
        keyIndex: DocumentCategoryKey.ID,
        element: documentCategory,
      });

      // update document items
      const mapUpdateDocumentItem = new Map();
      documentCategory?.documentItems?.forEach((item) => {
        mapUpdateDocumentItem.set(item.id, item);
      });

      state.documentItems = state.documentItems.map((item) => {
        if (mapUpdateDocumentItem.has(item.id)) {
          return mapUpdateDocumentItem.get(item.id);
        }

        return item;
      });

      if (state.documentItemSelected) {
        const documentItemSelected = (
          documentCategory?.documentItems || []
        )?.find(
          (item) =>
            item.id === state.documentItemSelected?.id &&
            item?.documentCategoryId ===
              state?.documentItemSelected?.documentCategoryId
        );
        if (documentItemSelected) {
          state.documentItemSelected = documentItemSelected;
        }
      }

      if (state.documentCategorySelected?.id === documentCategory.id) {
        state.documentCategorySelected = documentCategory;
      }
    },
    addDocumentCategory: (
      state,
      action: PayloadAction<DocumentCategoryDTO>
    ) => {
      state.documentCategories = [...state.documentCategories, action.payload];
    },
    removeDocumentCategory: (state, action: PayloadAction<string>) => {
      let newDocumentCategories = cloneDeep(state.documentCategories);
      const documentCategoryId = action.payload;
      const documentCategory = newDocumentCategories.find(
        (c) => c.id === documentCategoryId
      );
      if (!documentCategory) {
        return;
      }

      newDocumentCategories = newDocumentCategories.filter(
        (item) => item.id !== documentCategory.id
      );
      state.documentCategories = newDocumentCategories;
      if (state.documentCategorySelected?.id === documentCategoryId) {
        state.documentCategorySelected = undefined;
      }
    },
    setDocumentCategorySelected: (
      state,
      action: PayloadAction<DocumentCategoryDTO | undefined>
    ) => {
      state.documentCategorySelected = action.payload;
    },
    setDocumentSubCategorySelected: (
      state,
      action: PayloadAction<DocumentSubCategory | undefined>
    ) => {
      state.documentSubCategorySelected = action.payload;
    },
    setDocumentCategory: (
      state,
      action: PayloadAction<DocumentCategoryDTO>
    ) => {
      const documentCategory = action.payload;
      updateElementInArray({
        array: state.documentCategories,
        keyIndex: DocumentCategoryKey.ID,
        element: documentCategory,
      });

      if (documentCategory.id === state.documentCategorySelected?.id) {
        state.documentCategorySelected = documentCategory;
      }
    },
    resetFlexibleCategoryChildTemplates: (
      state,
      action: PayloadAction<{
        documentCategoryId: string;
        childTemplates?: DocumentCategoryDTO[];
      }>
    ) => {
      // Only fix for SELF_INSPECTION parent
      // debugger;
      state.documentCategorySelected = {
        ...state.documentCategorySelected,
        childTemplates: action.payload.childTemplates,
      } as DocumentCategoryDTO;
    },
    setDocumentTemplates: (
      state,
      action: PayloadAction<DocumentTemplate[]>
    ) => {
      const template: { [key: string]: DocumentTemplate } = {};
      (action?.payload || [])?.forEach((item) => {
        if (item?.id) {
          template[item.id] = item;
        }
      });
      state.documentTemplates = template;
    },
    setDefaultDocumentTemplates: (
      state,
      action: PayloadAction<DocumentTemplate[]>
    ) => {
      const template: { [key: string]: DocumentTemplate } = {};
      (action?.payload || [])?.forEach((item) => {
        if (item?.id) {
          template[item.id] = item;
        }
      });
      state.defaultDocumentTemplates = template;
    },
    setStatusContentFromS3: (state, action: PayloadAction<boolean | null>) => {
      state.isContentFromS3 = action.payload;
    },
    setCurrentBlackboardTemplate: (
      state,
      action: PayloadAction<Partial<BlackboardTemplate>>
    ) => {
      const data = action.payload;
      if (!data) {
        state.currentBlackboardTemplate = {} as any;

        return;
      }

      state.currentBlackboardTemplate = {
        ...state.currentBlackboardTemplate,
        ...data,
      };
    },
    setBlackboardTemplateList: (
      state,
      action: PayloadAction<BlackboardTemplate[]>
    ) => {
      state.blackboardTemplateList = action.payload;
    },

    setIsLoadedBlackboard: (state, action: PayloadAction<boolean>) => {
      state.isLoadedBlackboard = action.payload;
    },

    clearState: () => initialState,

    setIsFetchingDocument: (state, action: PayloadAction<boolean>) => {
      state.isFetchingDocument = action.payload;
    },

    addDocumentTemplate: (
      state,
      action: PayloadAction<DocumentTemplateDTO>
    ) => {
      state.documentTemplates[action.payload.id!] = action.payload;
    },

    deleteDocumentTemplate: (
      state,
      action: PayloadAction<string | string[]>
    ) => {
      if (isArray(action.payload)) {
        action.payload.forEach((id) => {
          delete state.documentTemplates[id];
        });
      } else {
        delete state.documentTemplates[action.payload];
      }
    },
    insertDocumentItemIdCreated: (state, action: PayloadAction<string>) => {
      state.documentItemIdsCreated = [
        ...state.documentItemIdsCreated,
        action.payload,
      ];
    },
    resetDocumentItemIdsCreated: (state) => {
      state.documentItemIdsCreated = [];
    },
    setDataLogTemp: (state, action: PayloadAction<DataLog | undefined>) => {
      state.dataLogTemp = action.payload;
    },
    setIsGeneratingPdf: (state, action: PayloadAction<boolean>) => {
      state.isGeneratingPdf = action.payload;
    },
    setMapPresignedUrl: (
      state,
      action: PayloadAction<{ [k: string]: string }>
    ) => {
      state.mapPresignedUrl = action.payload;
    },
    addMapPresignedUrl: (
      state,
      action: PayloadAction<{ url: string; presigned: string }>
    ) => {
      state.mapPresignedUrl[action.payload.url] = action.payload.presigned;
    },
    setTotalPagePreview: (state, action: PayloadAction<number>) => {
      state.totalPagePreview = action.payload;
    },
    setNextPreviewPage: (state, action: PayloadAction<number>) => {
      state.nextPreviewPage = action.payload;
    },
    updateVisibleDocumentItem: (
      state,
      action: PayloadAction<{
        itemId: string;
        cateId: string | undefined;
        isVisible: boolean;
        documentType: string | undefined;
      }>
    ) => {
      const { itemId, isVisible, cateId, documentType } = action.payload;
      const isPhotoLedger = isPhotoLedgerTemplate(documentType);
      const docItem = state.documentItems.find((i) => i.id === itemId);
      if (!docItem) {
        return;
      }

      if (isPhotoLedger) {
        docItem.subItems = (docItem?.subItems || [])?.map((sub) => ({
          ...sub,
          isHidden: !isVisible,
        }));

        updateElementInArray({
          array: state.documentItems,
          keyIndex: DocumentItemKey.ID,
          element: docItem,
        });
      }

      const documentCategories = state.documentCategories.filter(
        (category) => category.templateId === docItem.templateId
      );
      const mapDocumentCategories: { [id: string]: DocumentCategoryDTO } = {};

      documentCategories.forEach((category) => {
        if (category.id !== cateId && !isPhotoLedger) {
          return;
        }

        updateElementInArray({
          array: category?.documentItems || [],
          keyIndex: DocumentItemKey.ID,
          element: { ...docItem, documentCategoryId: category.id },
        });

        const setSelectedExternalIds = new Set(
          category?.selectedExternalIds || []
        );
        if (isPhotoLedger) {
          const { isHideAllItem, isShowAllItem } =
            getStatusVisibleOfItem(docItem);

          isHideAllItem && setSelectedExternalIds.delete(docItem.id);
          isShowAllItem && setSelectedExternalIds.add(docItem.id);
        } else if (isVisible) {
          setSelectedExternalIds.add(docItem.id);
        } else {
          setSelectedExternalIds.delete(docItem.id);
        }

        category.selectedExternalIds = Array.from(setSelectedExternalIds);
        mapDocumentCategories[category.id] = category;
      });

      const documentCategorySelected =
        mapDocumentCategories[state.documentCategorySelected?.id || ""];

      if (documentCategorySelected?.id) {
        state.documentCategorySelected = documentCategorySelected;
      }

      if (state.documentItemSelected?.id === docItem?.id) {
        if (documentCategorySelected?.id) {
          state.documentItemSelected = {
            ...docItem,
            documentCategoryId: documentCategorySelected?.id,
          };
        } else {
          state.documentItemSelected = docItem;
        }
      }

      state.documentCategories = state.documentCategories.map((category) => {
        return mapDocumentCategories?.[category?.id] || category;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlackBoardByCategoryId.fulfilled, (state, action) => {
      state.dataBlackboards = action.payload || [];
      state.isLoadedBlackboard = true;
    });
    builder.addCase(fetchBlackboardTemplates.fulfilled, (state, action) => {
      state.blackboardTemplateList = action.payload || [];
    });
    builder.addCase(fetchBlackBoardByCategoryId.pending, (state) => {
      state.isLoadedBlackboard = false;
    });
  },
});

export const {
  setDataBlackboard,
  setDocuments,
  setSelectedCell,
  setComponentSelected,
  setComponents,
  updateComponent,
  setCustomComponents,
  setCustomComponentSelected,
  setIsEditText,
  setIsCreateComponent,
  setDocumentContainerSize,
  setIsDisableHeaderDragging,
  setIsComponentDragging,
  setDropComponentSelected,
  setDropComponentType,
  setCurrentTemplate,
  setCurrentTemplateRawData,
  setShowModalBlackBoard,
  setDocumentCategories,
  setDocumentCategory,
  setDocumentCategorySelected,
  setDocumentItemSelected,
  setDocumentItems,
  removeDocumentItems,
  updateDocumentItem,
  setDataBlackboards,
  setIsLoadingDocument,
  setIsLoadingDocumentItem,
  toggleIsEditingFamily,
  toggleIsMovingDoc,
  toggleIsCaptureKeynoteByOperation,
  toggleShouldSaveTemplate,
  resetFlexibleCategoryChildTemplates,
  setDocumentTemplates,
  setStatusContentFromS3,
  clearState,
  updateDocumentItems,
  setDocumentSubCategorySelected,
  setPageSeleted,
  setCurrentBlackboardTemplate,
  addDocumentItems,
  setOpenSelectBlackboardTemplateModal,
  setOpenBlackboardTemplateSettingModal,
  setBlackboardTemplateList,
  setDocumentCategoriesAndSelected,
  setDefaultDocumentTemplates,
  setSelectedItemIdsWhenMovingDoc,
  addDocumentCategory,
  removeDocumentCategory,
  setDocumentTemplateSelected,
  updateDocumentCategory,
  updateDocumentTemplate,
  toggleShouldSaveTaskSheetTemplate,
  setDocumentGroups,
  setDocumentGroupSelected,
  addDocumentGroup,
  updateDocumentGroup,
  removeDocumentGroup,
  updateDocumentInfoOfTemplate,
  setAllDocItemAndDocCategory,
  setIsLoadedBlackboard,
  setIsFetchingDocument,
  addDocumentTemplate,
  deleteDocumentTemplate,
  setIsGeneratingPdf,
  setMapPresignedUrl,
  setNextPreviewPage,
  setTotalPagePreview,
  insertDocumentItemIdCreated,
  resetDocumentItemIdsCreated,
  setDataLogTemp,
  updateDocumentCategories,
  setBlackboardWithIndex,
  addMapPresignedUrl,
  updateVisibleDocumentItem,
} = documentSlice.actions;

export default documentSlice.reducer;

import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import {
  renderCellPropertyCheckbox,
  renderContentTypeCommon,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  LinkedDataField,
  LinkedDynamicFieldsType,
  TableDefaultStyle,
} from "constants/enum";
import useFamilyInstance from "hooks/useFamilyInstance";

import { CellType } from "interfaces/models/component";
import { iCommonDocumentTemplateProps } from "interfaces/models/documentTemplate";
import { useCallback } from "react";
import NormalTextPreview from "../../NormalTextPreview";

const useGetContentTypeModuleChiller = (props: iUseGetContentOfCell) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,
    moduleChillerProps,
  } = props;

  const { familyInstances } = useFamilyInstance();

  const contentOfCellTypeModuleChiller = useCallback(
    (cell: CellType) => {
      if (!moduleChillerProps) {
        return <></>;
      }

      const data = structuredClone(cell);

      const headerComponent = moduleChillerProps?.headerComponent || [];
      const cellIndex = moduleChillerProps?.option?.cellIndex || 0;
      const subRowIndex = moduleChillerProps?.option?.subRowIndex || 0;
      const subCellIndex = moduleChillerProps?.option?.subCellIndex || 0;
      const documentItem = moduleChillerProps?.documentItem;

      const documentItemData = documentItem?.data || {};
      const cellEle = document.getElementById(cell.cellId);
      const isSubCell = !!cell?.isSubCell;

      const headerTitleId = isSubCell
        ? headerComponent?.[cellIndex]?.subTable?.rows?.[subRowIndex]?.cells?.[
            subCellIndex
          ]?.cellId
        : headerComponent?.[cellIndex]?.cellId;

      const renderCommon = renderContentTypeCommon({
        contentType,
        contentCommonProps: (moduleChillerProps ||
          {}) as iCommonDocumentTemplateProps,
        cell,
        familyInstances,
        documentItems: [],
        documentItem,
        component,
        subTableComponentPreviewProps: {
          component,
          parentCell: cell,
          displaySize: {
            width:
              (width / component.size.width) * displaySize.width -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            height:
              (height / component.size.height) * displaySize.height -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
          },
          zoomRatio,
          cellSize,
          sizePageRatio,
          pageDirectionRatio,
          isOnlyView,
          isDuplicateRow,
          isParentLastColumn: isLastColumn,
          limit,
          isBlackboardTemplateImage,
          components,
          selectedCells,
          documentContainerSize,
          currentTemplate,
          componentSelected,
          isComponentDragging,
          contentType,
          moduleChillerProps,
        },
      });

      if (renderCommon) {
        return renderCommon;
      }

      switch (cell?.cellProperty) {
        case CellProperty.DYNAMIC_FIELDS_FOR_ITEM:
          {
            const dynamicFieldItem =
              documentItemData[`${cell.cellId}-${documentItem?.id}`];
            data.value = dynamicFieldItem;
          }
          break;

        default:
          break;
      }

      if (!data) {
        return null;
      }

      cellEle?.setAttribute("data-document-id", documentItem?.id || "");
      cellEle?.setAttribute("data-header-title-id", headerTitleId || "");
      cellEle?.setAttribute("data-field-content", data?.value || "");
      cellEle?.setAttribute(
        "data-field-name",
        data?.cellLinkedData?.field || ""
      );
      cellEle?.setAttribute(
        "data-options",
        data?.cellLinkedData?.options?.valueOfCheckbox || ""
      );

      switch (data?.cellProperty) {
        case CellProperty.NO:
        case CellProperty.TEXT:
        case CellProperty.INPUT_DATA:
        case CellProperty.CALENDAR: {
          return <NormalTextPreview data={data} />;
        }

        case CellProperty.DYNAMIC_FIELDS_FOR_ITEM:
          switch (data?.cellLinkedData?.options?.dynamicFieldType) {
            case LinkedDynamicFieldsType.CHECK:
              return renderCellPropertyCheckbox(data, component, {
                contentType,
                documentItem,
              });

            default:
              return <NormalTextPreview data={data} />;
          }

        case CellProperty.DOCUMENT_DATA:
          switch (data?.cellLinkedData?.field) {
            case LinkedDataField.COMMON.CHECK_BOX:
              return renderCellPropertyCheckbox(data, component);

            default:
              return <NormalTextPreview data={data} />;
          }

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [
      contentType,
      isComponentDragging,
      components,
      selectedCells,
      documentContainerSize,
      currentTemplate,
      componentSelected,
      limit,
      isLastColumn,
      cellSize,
      displaySize,
      width,
      height,
      zoomRatio,
      sizePageRatio,
      pageDirectionRatio,
      isOnlyView,
      isDuplicateRow,
      isBlackboardTemplateImage,
      familyInstances,
      component,
      moduleChillerProps,
    ]
  );

  return {
    contentOfCellTypeModuleChiller,
  };
};

export default useGetContentTypeModuleChiller;

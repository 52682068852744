import { Box, Button, Flex, HStack, Show } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { fetchFirstBim } from "redux/bimSlice";
import { fetchDataProjectDetail, fetchProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";
import { getBimFileInfo } from "utils/bim";
import { message } from "./base";
import Header from "./Layout/Header";
import { SvgIcon } from "./SvgIcon";

function LayoutTabs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectDetail({ projectId }));
      dispatch(fetchDataProjectDetail(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleClick3D = useCallback(async () => {
    if (dataProjectDetail?.defaultBimPathId) {
      const urn = dataProjectDetail.defaultBimPathId?.split("/").pop();
      if (!urn) {
        message.error("Missing bim file");

        return;
      }
      const { bimFileId, version } = getBimFileInfo(urn);
      navigate(
        generatePath(routePath.ForgeViewer, {
          projectId: projectId ?? "",
          bimFileId: encodeURIComponent(bimFileId),
          version,
        })
      );
    } else {
      setLoading(true);
      const res: any = await dispatch(fetchFirstBim(projectId));
      setLoading(false);
      const urn = res?.payload?.id.split("/").pop();
      if (!urn) {
        message.error("Project has no bim file");

        return;
      }

      const [bimFileId, version] = urn?.split("?");
      navigate(
        generatePath(routePath.ForgeViewer, {
          projectId: projectId ?? "",
          bimFileId: encodeURIComponent(bimFileId),
          version,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectDetail, projectId]);

  const headerLayout = (
    <Flex alignItems="center" position="absolute" right={0} top={0} bottom={0}>
      <Button
        variant="filled"
        w="13.6rem"
        minW="12rem"
        leftIcon={<SvgIcon src={"/img/icon-maps-object.svg"} />}
        isLoading={loading}
        onClick={handleClick3D}
      >
        3D view
      </Button>
      <Box w="7rem"></Box>
    </Flex>
  );

  return (
    <Box
      w="100%"
      h="100%"
      position="absolute"
      overflowY={{ base: "auto", md: "hidden" }}
      overflowX="hidden"
    >
      <Flex
        position="sticky"
        zIndex="10"
        h={{ base: "auto", md: "var(--header-height)" }}
        flexDirection={{
          base: matchPath(routePath.Home, pathname) ? "row" : "column",
          md: "row",
        }}
      >
        <Header w="100%" maxW={{ md: "32rem" }} />
        <HStack
          w="100%"
          maxW={{ md: "calc(100% - 32rem)" }}
          bgColor="#D2D2D2"
          spacing="1.6rem"
          overflowX="auto"
          minH="var(--header-height)"
          pr="0.8rem"
        >
          <Show above="md">{headerLayout}</Show>
        </HStack>
      </Flex>
      <React.Suspense fallback={null}>
        <Outlet />
      </React.Suspense>
    </Box>
  );
}

export default LayoutTabs;

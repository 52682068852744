import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import {
  CrossLineNode,
  Enter,
  ImageUploadedNode,
  ImageWithBlackboardNode,
  JustifyTextNode,
  KeynoteNode,
  MultipleNormalTextNode,
  NormalTextNode,
  PageComponentNode,
  PageContentNode,
  PreviewContainerNode,
  PreviewPageNode,
  SubTableComponentNode,
  TableComponentNode,
  TableTdComponentNode,
  TableTrComponentNode,
  TaskItem,
  TaskList,
  TaskOptNode,
  TextBoxNode,
  LoadContent,
  Selection,
  LinkedImageLineNode,
} from ".";

export const EditorExtensions = [
  LoadContent,
  Selection,
  Underline,
  Enter,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
    HTMLAttributes: {
      class: "tiptap-link",
    },
  }),
  StarterKit,
  PreviewContainerNode,
  PreviewPageNode,
  PageContentNode,
  PageComponentNode,
  TableComponentNode,
  SubTableComponentNode,
  TableTrComponentNode,
  TableTdComponentNode,
  NormalTextNode,
  MultipleNormalTextNode,
  JustifyTextNode,
  TextBoxNode,
  CrossLineNode,
  ImageWithBlackboardNode,
  TaskList,
  TaskItem,
  TaskOptNode,
  ImageUploadedNode,
  KeynoteNode,
  LinkedImageLineNode,
];

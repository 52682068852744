import { sleep } from "utils/common";
import { bimFileApi } from "apiClient/v2";
import { message } from "components/base";
import { PROJECT_CACHE_INFO } from "constants/serviceWorker";
import { PROJECT_RESOURCE } from "constants/cache";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch } from "react-redux";
import { clearState as clearAppState, setSyncDataOption } from "redux/appSlice";
import { checkCacheProject, setCachingProject } from "redux/projectSlice";
import store from "redux/store";
import {
  axiosCacheController,
  resetAxiosCacheController,
} from "services/baseAxios";
import { doRefreshToken } from "utils/authen";
import { getBimFileInfo } from "utils/bim";
import {
  cacheCommonModelData,
  cacheDefaultData,
  cacheModelData,
  cacheProjectData,
  deleteModelCache,
  deleteProjectCache,
  getCacheProgress,
  getResourceCachedOfProject,
  getUrlsOfModel,
  saveProgressToStorage,
  updateMapSyncDataTimeWhenCacheDone,
  _currentCachingProgress,
} from "utils/cache";
import { cacheAbortController, renewCacheAbortController } from "utils/fetch";
import { getCacheProjectById, getIndexedDb } from "utils/indexedDb";
import { logError } from "utils/logs";
import { arrayToObject } from "utils/object";

export interface iCachedProject {
  cached: number;
  total: number;
  progress: number;
  lastCacheTime?: Date;
  isPause?: boolean | undefined;
}

interface iProps {
  project: DataProjectModel | undefined;
  isServiceWorkerReady: boolean;
  cachingBimFileId: string | undefined;
  isOnline: boolean;
  cachedInfo: iCachedProject | undefined;
  handleClickProject?: any;
}

const useCheckProjectCached = ({
  project,
  isServiceWorkerReady,
  cachingBimFileId,
  cachedInfo,
  isOnline,
  handleClickProject,
}: iProps) => {
  const dispatch = useDispatch();
  const [cachingStatus, setCachingStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [isClearing, setIsClearing] = useState<boolean>(false);
  const [{ isSafari }] = useDeviceSelectors(window.navigator.userAgent);
  const isCachingRef = useRef(false);

  const isCaching = useMemo(() => {
    return !!project?.id && cachingBimFileId === project.id;
  }, [cachingBimFileId, project?.id]);

  useEffect(() => {
    isCachingRef.current = isCaching;
  }, [isCaching]);

  useEffect(() => {
    if (!project?.id || !("caches" in window)) {
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const currentCacheInfo =
          _currentCachingProgress[project?.id || ""] || {};

        if (!isCaching) {
          if (cachedInfo) {
            setProgress(cachedInfo.progress);
            _currentCachingProgress[project.id] = {
              total: cachedInfo.total,
              cached: cachedInfo.cached,
            };
          } else {
            setProgress(0);
          }
        } else {
          setProgress(
            Math.floor(
              ((currentCacheInfo.cached ?? 0) * 100) / currentCacheInfo.total
            )
          );
        }

        setIsLoading(false);
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.log(e);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id, cachedInfo]);

  const handleEvent = (e: any) => {
    if (!isCachingRef.current || !e.detail) {
      return;
    }
    const payload = e.detail;
    const progress = Math.floor((payload.cached * 100) / payload.total);
    setProgress(progress);
    if (progress >= 100) {
      dispatch(setCachingProject(undefined));
      setCachingStatus(false);
      if (!store.getState().project.dataProjectDetail?.id) {
        handleClickProject?.();
      }
    }
  };

  // stop download project when offline
  useEffect(() => {
    if (!isOnline && isCaching) {
      stopCacheProject();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaching, isOnline]);

  useEffect(() => {
    if (!project?.id || !isServiceWorkerReady || !isOnline) {
      return;
    }

    document.addEventListener(`cache-${project.id}`, handleEvent);

    return function cleanUp() {
      document.removeEventListener(`cache-${project.id}`, handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id, isServiceWorkerReady, isOnline]);

  useEffect(() => {
    if (!isCaching && cachingStatus) {
      setCachingStatus(false);
    }
  }, [isCaching, cachingStatus]);

  const callAbortCacheApi = useCallback(() => {
    try {
      axiosCacheController.abort("cancel cache");
      cacheAbortController.abort("cancel cache");
    } catch (err) {
      logError(err);
    }
  }, []);

  const showErrorCache = useCallback(
    (e: any) => {
      const msg = `「${project?.name}」のデータダウンロードが失敗しました。再度試してください。`;
      message.error(msg);
    },
    [project]
  );

  const cacheProject = useCallback(
    async (isIgnoreCheckCaching = false) => {
      const currentCaching = await getCacheProjectById(project?.id || "");
      if (
        (cachingStatus && !isIgnoreCheckCaching) ||
        !isServiceWorkerReady ||
        (isCaching && !isIgnoreCheckCaching) ||
        !project?.id ||
        (!!currentCaching?.total &&
          currentCaching?.cached === currentCaching?.total)
      ) {
        return;
      }
      if (!project.isGenerated) {
        message.error(
          `${project.name}のデータが生成されていないため、データをダウンロードできません。`
        );

        return;
      }
      let stopDownloadProjectId: string | undefined;
      const onStopDownloadProject = (e: any) => {
        stopDownloadProjectId = e.detail.id;
      };
      window.addEventListener("stopDownloadProject", onStopDownloadProject);
      dispatch(setCachingProject(project.id));
      setCachingStatus(false);
      resetAxiosCacheController();
      renewCacheAbortController();
      setTimeout(async () => {
        try {
          await doRefreshToken();
          const { data: bimFileData } = await bimFileApi.getProject(project.id);
          const {
            cachedLength,
            totalLength,
            cachedModelData,
            cachedProjectData,
          } = await getCacheProgress(bimFileData, isSafari);
          await cacheDefaultData();
          _currentCachingProgress[project.id] = {
            total: totalLength,
            cached: cachedLength,
            isPause: false,
          };
          const newProgress = Math.floor(
            ((cachedLength ?? 0) * 100) / totalLength
          );
          setProgress(newProgress);
          await saveProgressToStorage({
            progress: newProgress,
            cached: cachedLength,
            total: totalLength,
            projectId: project?.id || "",
            isForceUpdate: true,
          } as any);

          _currentCachingProgress[project.id].cached = cachedLength;
          // call api for get full data of project bim file
          await cacheCommonModelData(bimFileData, cachedModelData, isSafari);
          await cacheProjectData(bimFileData, cachedProjectData);
          await cacheModelData(bimFileData, cachedModelData);
          setCachingStatus(true);
        } catch (e: any) {
          if (project?.id === stopDownloadProjectId) {
            return;
          }
          logError(e);
          callAbortCacheApi();
          showErrorCache(e);
          dispatch(setCachingProject(undefined));
        } finally {
          window.removeEventListener(
            "stopDownloadProject",
            onStopDownloadProject
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      project,
      cachingStatus,
      isServiceWorkerReady,
      isCaching,
      isSafari,
      showErrorCache,
      dispatch,
      callAbortCacheApi,
    ]
  );

  const dispatchEventStopDownload = useCallback(() => {
    const stopDownloadEvent = new CustomEvent("stopDownloadProject", {
      detail: {
        id: project?.id,
      },
    });
    window.dispatchEvent(stopDownloadEvent);
  }, [project?.id]);

  const stopCacheProject = useCallback(async () => {
    if (!isCaching || !cachingBimFileId || !project?.id) {
      return;
    }
    if (!_currentCachingProgress[project.id]) {
      _currentCachingProgress[project.id] = {
        isPause: false,
        total: 0,
        cached: 0,
      };
    }
    _currentCachingProgress[project.id].isPause = true;
    const indexedDb = await getIndexedDb();
    const cachedProject: any = await getCacheProjectById(project.id);
    if (cachedProject) {
      const newCache = {
        ...cachedProject,
        progress: Math.floor(
          ((cachedProject.cached ?? 0) * 100) / cachedProject.total
        ),
        isPause: true,
      };
      await indexedDb?.put(project.id, newCache, PROJECT_CACHE_INFO);
    }

    dispatch(checkCacheProject());
    dispatch(setCachingProject(undefined));
    setIsLoading(false);
    setCachingStatus(false);
    dispatchEventStopDownload();
    await sleep(100);
    callAbortCacheApi();
  }, [
    isCaching,
    cachingBimFileId,
    project?.id,
    callAbortCacheApi,
    dispatch,
    dispatchEventStopDownload,
  ]);

  const clearProjectCache = useCallback(
    async (isResetAppState = true, isForceClear = false) => {
      if (!project?.id || !isServiceWorkerReady) {
        return;
      }
      if (!isForceClear && isCaching) {
        return;
      }
      const resetState = () => {
        dispatch(checkCacheProject());
        if (isForceClear && isCaching) {
          dispatch(setCachingProject(undefined));
        }
        setIsClearing(false);
      };
      let modelData;
      // here we need split multiple try catch , because when stop cache project error, we can still receive exception error -> clear cache can be stopped
      // And we need call api before stop cache project, because if call later request can be canceled

      try {
        const { data: bimFileData } = await bimFileApi.getProject(project.id);
        modelData = await getUrlsOfModel(bimFileData);
      } catch (err) {
        return message.error(`Clear cache project ${project.name} failed.`);
      }

      try {
        dispatchEventStopDownload();
        if (isCaching) {
          await stopCacheProject();
        }
      } catch (e: any) {
        /* eslint-disable-next-line no-console */
        console.log(e.stack);
      }

      try {
        if (isForceClear && isCaching) {
          dispatch(setCachingProject("clear cache"));
        }
        setIsClearing(true);
        const indexedDb = await getIndexedDb();
        await indexedDb?.delete(project.id, PROJECT_CACHE_INFO);
        // clear cache modal data
        if (modelData) {
          await deleteModelCache(modelData);
        }
        // clear api cache's data
        await deleteProjectCache(project.id);
        if (_currentCachingProgress[project.id]) {
          _currentCachingProgress[project.id].cached = 0;
        }
        setProgress(0);
        const syncDataOption = structuredClone(
          store.getState().app.syncDataOption
        );
        const { mapSyncDataTimeByProject } = syncDataOption || {};
        const newMapSyncDataTime = { ...(mapSyncDataTimeByProject || {}) };
        delete newMapSyncDataTime[project.id];
        dispatch(
          setSyncDataOption({
            ...syncDataOption,
            mapSyncDataTimeByProject: newMapSyncDataTime,
          })
        );
        resetState();
        if (isResetAppState) {
          dispatch(clearAppState());
        }
      } catch (err) {
        message.error(`Clear cache project ${project.name} failed.`);
        resetState();
      }
    },
    [
      project,
      isServiceWorkerReady,
      isCaching,
      dispatchEventStopDownload,
      dispatch,
      stopCacheProject,
    ]
  );

  const syncNewProjectData = useCallback(
    async (cachedUrls?: string[]) => {
      if (!isServiceWorkerReady || isCaching || !project?.id) {
        return;
      }
      if (!project.isGenerated) {
        message.error(
          `${project.name}のデータが生成されていないため、データをダウンロードできません。`
        );

        return;
      }

      resetAxiosCacheController();
      renewCacheAbortController();
      dispatch(setCachingProject(project.id));
      const resetState = () => {
        dispatch(checkCacheProject());
        dispatch(setCachingProject(undefined));
      };
      const indexedDb = await getIndexedDb();
      let stopDownloadProjectId: string | undefined;
      const onStopDownloadProject = (e: any) => {
        stopDownloadProjectId = e.detail.id;
      };
      window.addEventListener("stopDownloadProject", onStopDownloadProject);
      try {
        const projectDataCacheProgress: {
          id: string;
          key: string;
          projectId: string;
        }[] = (await indexedDb.getProjectDataCacheProgress()).filter(
          (item: any) => item.projectId === project.id
        );
        const mapProjectDataCacheProgressByKey = arrayToObject(
          projectDataCacheProgress,
          "key"
        );

        const deletedIds: string[] = [];
        const resourceNotCached: string[] = Object.values(
          PROJECT_RESOURCE
        ).filter((res) => !cachedUrls?.length || !cachedUrls.includes(res));

        resourceNotCached.forEach((key) => {
          const id = mapProjectDataCacheProgressByKey?.[key]?.id;
          id && deletedIds.push(id);
        });

        // delete older resource before sync new data
        await indexedDb.deleteProjectProgressByIds(deletedIds);
        let cacheProject = await getCacheProjectById(project?.id);

        // calculate the number of cached requests
        if (cacheProject?.cached) {
          cacheProject.cached -= resourceNotCached.length || 0;
        } else {
          cacheProject = {
            cached: 0,
            progress: 0,
            total: 0,
            id: project.id,
          } as iCachedProject;
        }
        cacheProject.isPause = false;
        // store data to global variable and local storage
        const progress = Math.floor(
          ((cacheProject.cached ?? 0) * 100) / cacheProject.total
        );
        cacheProject = {
          ...cacheProject,
          progress,
        };
        _currentCachingProgress[project.id] = cacheProject;
        await indexedDb?.put(project.id, cacheProject, PROJECT_CACHE_INFO);
        setProgress(progress);
        // call api for get full data of project bim file
        const { data: bimFileData } = await bimFileApi.getProject(project.id);
        // cache api's data necessary
        await doRefreshToken();
        await cacheDefaultData();
        await cacheProjectData(bimFileData, cachedUrls || []);
        // set cache's time
        updateMapSyncDataTimeWhenCacheDone(
          project.id,
          project.defaultBimPathId
        );
        resetState();
      } catch (e: any) {
        if (stopDownloadProjectId === project?.id) {
          return;
        }
        logError(e);
        callAbortCacheApi();

        showErrorCache(e);
        resetState();
      } finally {
        window.removeEventListener(
          "stopDownloadProject",
          onStopDownloadProject
        );
      }
    },
    [
      isCaching,
      isServiceWorkerReady,
      project,
      showErrorCache,
      callAbortCacheApi,
      dispatch,
    ]
  );

  const handleBeforeSyncProjectData = useCallback(async () => {
    if (progress === 0 || progress !== 100) {
      await cacheProject();

      return;
    }
    const syncDataOption = structuredClone(store.getState().app.syncDataOption);
    const syncDataTime =
      syncDataOption?.mapSyncDataTimeByProject?.[project?.id || ""] || null;
    if (!project?.defaultBimPathId) {
      return;
    }
    const urn = project?.defaultBimPathId?.split("/").pop();
    const currentBimFileId = getBimFileInfo(urn || "")?.bimFileId || "";
    if (!currentBimFileId) {
      return;
    }
    let cachedUrls: string[] | undefined = [];
    if (syncDataTime && currentBimFileId) {
      cachedUrls = await getResourceCachedOfProject(
        currentBimFileId,
        syncDataTime
      );
      if (!cachedUrls) {
        return;
      }
    }

    await syncNewProjectData(cachedUrls);
  }, [progress, project, cacheProject, syncNewProjectData]);

  return {
    handleBeforeSyncProjectData,
    syncNewProjectData,
    clearProjectCache,
    cacheProject,
    stopCacheProject,
    progress,
    isLoading,
    isClearing,
  };
};

export default useCheckProjectCached;

import {
  Button,
  Flex,
  IconButton,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import { documentTaskApi } from "apiClient/v2";
import { message } from "components/base";
import { NumberInputFormat } from "components/input";
import Select from "components/input/Select";
import { SvgIcon } from "components/SvgIcon";
import { MAX_WIDTH_SCREEN_SM } from "constants/app";
import { Axis } from "constants/enum";
import { AXIS_OPTIONS } from "constants/header";
import { S3_PATH } from "constants/s3";
import {
  buttonOutlineStyle,
  buttonPrimaryStyle,
  buttonRedStyle,
} from "constants/styleProps";
import { usePermission, useRoles } from "hooks/usePermission";
import { useResize } from "hooks/useResize";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentTask, DocumentTaskData } from "interfaces/models/documentTask";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import {
  checkDuplicateDocumentTaskTitle,
  getLastIndexDuplicateTitle,
  getTaskLabelOfDocumentTask,
  transformDocumentTitle,
} from "models/documentTask";
import useTaskSheetTemplateSelectModal from "pages/document/tasksheet-template/ui/hooks/useTaskSheetTemplateSelectModal";
import TaskSheetTemplateSelectModal from "pages/document/tasksheet-template/ui/TaskSheetTemplateSelectModal";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useSelector } from "react-redux";
import { createUpdateProjectApi } from "redux/projectSlice";
import { RootState, useAppDispatch } from "redux/store";
import { uploadFileToS3 } from "utils/file";

interface iProps {
  documentTasks: DocumentTask[];
  documentTaskData: DocumentTaskData;
  bimFileId: string;
  isKeyplanFullPage: boolean;
  taskSheetTemplateId: string;
  zoomPageValue: number;
  editMode: boolean;
  loadingEdit: boolean;
  loading: boolean;
  loadingDownload: boolean;
  loadingPrint: boolean;
  currentPage: number;
  totalPage: number;
  documentTask: DocumentTask;
  taskSheetTemplateList: TaskSheetTemplate[];
  loadingCheckTaskSheetTemplate: boolean;
  sheetOptions:
    | {
        title: string;
        options: { name: string; value: string }[];
      }
    | undefined;
  sheetIdSelected: string;
  axisKeyplanDefault: Axis;

  onEnableEditMode: () => void;
  onCancelEdit: () => void;
  onViewTaskList: () => void;
  onPrevPage: () => void;
  onNextPage: () => void;
  onChangePage: (valueAsString: string, valueAsNumber: number) => void;
  onBlurPage: () => void;
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
  onEditDocumentTask: () => void;
  onClose: () => void;
  onDownloadPDF: (isPrint?: boolean) => () => any;
  onChangeRatio: (axis: Axis) => void;
  onChangeSheet: (value: string) => void;
  onChangeZoomPage: (
    preValue: string,
    valueNumner: number,
    name?: string,
    inputType?: string
  ) => void;
  onChangeTaskSheetTemplate: (
    templateId: string,
    documentTitle: string
  ) => void;
  onUpdateDocumentTask?: (documentTask: DocumentTask) => void;
}

const TaskSheetHeader = (props: iProps) => {
  const {
    documentTasks,
    documentTaskData,
    bimFileId,
    axisKeyplanDefault,
    sheetOptions,
    sheetIdSelected,
    isKeyplanFullPage,
    loadingCheckTaskSheetTemplate,
    taskSheetTemplateId,
    taskSheetTemplateList,
    zoomPageValue,
    documentTask,
    editMode,
    loadingEdit,
    loading,
    loadingDownload,
    loadingPrint,
    currentPage,
    totalPage,
    onViewTaskList,
    onPrevPage,
    onNextPage,
    onChangePage,
    onBlurPage,
    onEditDocumentTask,
    onClose,
    onDownloadPDF,
    onChangeRatio,
    onChangeSheet,
    onChangeZoomPage,
    onChangeTaskSheetTemplate,
    setTitle,
    onUpdateDocumentTask,
    onCancelEdit,
    onEnableEditMode,
  } = props;
  const [
    isLoadingSelectTaskSheetTemplate,
    setIsLoadingSelectTaskSheetTemplate,
  ] = useState(false);

  const {
    isOpenTaskSheetTemplateSelectModal,
    onOpenTaskSheetTemplateSelectModal,
    onCloseTaskSheetTemplateSelectModal,
  } = useTaskSheetTemplateSelectModal();
  const { width } = useResize();
  const { isOnline } = useSelector((state: RootState) => state.app);
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const appDispatch = useAppDispatch();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { canEditTaskList } = usePermission(currentUser?.role);

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { isTakasagoGroup } = useRoles();


  const axisOptionValue = useMemo(() => {
    const taskLabelOfDocumentTask = getTaskLabelOfDocumentTask(documentTask);
    const taskLabelByGuid = taskLabelOfDocumentTask?.taskLabelByGuid;

    return taskLabelByGuid?.axis || axisKeyplanDefault;
  }, [documentTask, axisKeyplanDefault]);

  useEffect(() => {
    const checkIsTemplateExists = (templageId: string | undefined | null) =>
      taskSheetTemplateList?.findIndex((tem) => tem.id === templageId) !== -1;

    if (
      loadingCheckTaskSheetTemplate ||
      !documentTask?.id ||
      checkIsTemplateExists(documentTask?.taskSheetTemplateId) ||
      checkIsTemplateExists(dataProjectDetail?.taskSheetTemplateId)
    ) {
      return;
    }

    onOpenTaskSheetTemplateSelectModal();
  }, [
    taskSheetTemplateList,
    loadingCheckTaskSheetTemplate,
    documentTask?.id,
    documentTask?.taskSheetTemplateId,
    dataProjectDetail?.taskSheetTemplateId,
    onOpenTaskSheetTemplateSelectModal,
  ]);

  const saveTaskSheetTemplateDefault = useCallback(
    async (templateId: string) => {
      const body: any = {
        ...dataProjectDetail,
        taskSheetTemplateId: templateId,
        userRole: currentUser?.role || "",
      };

      await appDispatch(createUpdateProjectApi(body));
    },
    [appDispatch, dataProjectDetail, currentUser?.role]
  );

  const handleSelectTaskSheetTemplate = useCallback(
    async (template: TaskSheetTemplate) => {
      const final = () => {
        setIsLoadingSelectTaskSheetTemplate(false);
        onCloseTaskSheetTemplateSelectModal();
      };

      const isShouldSaveTemplateDefault =
        !documentTask?.taskSheetTemplateId &&
        !taskSheetTemplateList?.find(
          (tem) => tem.id === dataProjectDetail?.taskSheetTemplateId
        );

      if (template.id === documentTask.taskSheetTemplateId) {
        final();

        return;
      }

      let title = transformDocumentTitle({
        taskSheetTemplateId: template.id,
        levelLabel: documentTask?.level,
        taskSheetTemplateList,
        date: documentTask.createdAt,
      });

      // check duplicate title
      const isDuplicateTitle = await checkDuplicateDocumentTaskTitle({
        title,
        bimFileId,
      });

      if (isDuplicateTitle) {
        const lastIndex = getLastIndexDuplicateTitle({
          titleDuplicate: title,
          documentTasks,
          currentDocumentTask: documentTask,
        });
        title = `${title} (${lastIndex + 1})`;
      }

      setTitle(title);
      onChangeTaskSheetTemplate(template.id!, title);

      try {
        setIsLoadingSelectTaskSheetTemplate(true);

        if (isShouldSaveTemplateDefault) {
          await saveTaskSheetTemplateDefault(template.id || "");
        } else if(isTakasagoGroup) {
          const filePath = `${documentTask.id}${Date.now()}`;
          const newDocumentTaskData = {
            ...documentTaskData,
            title: {},
          };
          const changedDataUrl =
            (await uploadFileToS3(
              new File([JSON.stringify(newDocumentTaskData)], filePath),
              filePath,
              S3_PATH.Task
            )) || "";

          const body: DocumentTask = {
            ...documentTask,
            taskSheetTemplateId: template.id,
            title,
            detailDataUrl: changedDataUrl,
          };

          const newDocumentTask =
            await documentTaskApi.createUpdateDocumentTask(body);
          onUpdateDocumentTask?.(newDocumentTask);
        }

        message.success("是正指示書テンプレートが選択されました。");

        final();
      } catch {
        final();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onCloseTaskSheetTemplateSelectModal,
      onChangeTaskSheetTemplate,
      saveTaskSheetTemplateDefault,
      bimFileId,
      documentTasks,
      taskSheetTemplateList,
      dataProjectDetail?.taskSheetTemplateId,
      documentTask,
      isTakasagoGroup
    ]
  );

  return (
    <>
      <Flex
        p="1rem 1.6rem"
        bgColor="#F2F2F2"
        position="sticky"
        top="0px"
        zIndex="11"
        minHeight={"6rem"}
      >
        {editMode ? (
          <>
            <Button
              ml="1rem"
              isLoading={loadingEdit}
              onClick={onEditDocumentTask}
            >
              保存して終了
            </Button>
            <Button variant="danger" ml="1rem" onClick={onCancelEdit}>
              変更を破棄
            </Button>
          </>
        ) : (
          <>
            <Flex
              alignItems="center"
              className="button"
              {...(loading || loadingPrint || loadingDownload
                ? {
                    cursor: "not-allowed",
                  }
                : {
                    onClick: onClose,
                  })}
            >
              <SvgIcon src="/img/icon-navigation-chevron_left.svg" />
            </Flex>
            <Button
              variant="filled"
              ml="1rem"
              isDisabled={loading || loadingPrint || loadingDownload}
              isLoading={loadingDownload}
              onClick={onDownloadPDF()}
            >
              {width <= MAX_WIDTH_SCREEN_SM
                ? "ダウンロード"
                : "PDFダウンロード"}
            </Button>
            {/*Temporarily hide the pdf download button on iPad*/}
            {!isMobile && (
              <Button
                ml="1rem"
                isDisabled={loading || loadingPrint || loadingDownload}
                isLoading={loadingPrint}
                onClick={onDownloadPDF(true)}
              >
                印刷
              </Button>
            )}
            {canEditTaskList && (
              <Button
                ml="1rem"
                isDisabled={loading || loadingPrint || loadingDownload}
                onClick={onEnableEditMode}
              >
                書類情報の編集
              </Button>
            )}
            <Flex
              borderLeft="1px solid #B4B4B4"
              ml={{
                md: "2.4rem",
                sm: "1rem",
              }}
              alignItems="center"
            >
              <NumberInput
                isDisabled={loading}
                ml={{
                  md: "2.4rem",
                  sm: "1rem",
                }}
                border="none"
                height="4rem"
                width="4rem"
                bgColor="#fff"
                onBlur={onBlurPage}
                onChange={onChangePage}
                min={1}
                max={totalPage || 1}
                value={isNaN(currentPage) ? 0 : currentPage + 1}
                clampValueOnBlur={true}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    (e.target as any).blur();
                  }
                }}
              >
                <NumberInputField
                  h="100%"
                  textAlign="center"
                  fontSize={{ base: "1rem", "2xl": "1.4rem", xl: "1rem" }}
                  fontWeight="bold"
                  border="none"
                  paddingInline="0"
                />
              </NumberInput>
              <Text w="55px" px="1rem" fontSize="1.4rem" fontWeight="bold">
                {`/ ${totalPage}`}
              </Text>
              <IconButton
                p={1}
                aria-label="Page down"
                variant="text"
                color="font.default"
                icon={
                  <SvgIcon
                    cursor={
                      currentPage + 1 <= 1 || loading
                        ? "not-allowed"
                        : "pointer"
                    }
                    src="/img/icon-navigation-chevron_up.svg"
                  />
                }
                isDisabled={currentPage + 1 <= 1 || loading}
                onClick={onPrevPage}
              />
              <IconButton
                p={1}
                aria-label="Page up"
                variant="text"
                color="font.default"
                icon={
                  <SvgIcon
                    cursor={
                      currentPage + 1 >= totalPage || loading
                        ? "not-allowed"
                        : "pointer"
                    }
                    src="/img/icon-navigation-chevron_down.svg"
                  />
                }
                isDisabled={currentPage + 1 >= totalPage || loading}
                onClick={onNextPage}
              />
            </Flex>

            <Flex ml="auto" alignItems="center" gap="1rem">
              {canEditTaskList && (
                <Button
                  isDisabled={
                    loading || loadingPrint || loadingDownload || !isOnline
                  }
                  onClick={onOpenTaskSheetTemplateSelectModal}
                  flexShrink="0"
                >
                  是正指示書テンプレ選択
                </Button>
              )}

              <NumberInputFormat
                disableTyping={true}
                isDisabled={loading || loadingPrint || loadingDownload}
                border="1px solid #A3A3A3"
                w="11rem"
                height="4rem"
                unit="%表示"
                initValue={String(Math.floor(zoomPageValue * 100))}
                min={50}
                max={200}
                step={10}
                onChangeStyle={onChangeZoomPage}
                borderRadius="6px"
                sx={{
                  "& > input": {
                    border: "none !important",
                    borderRadius: "6px !important",
                  },
                }}
              />

              <Select
                isDisabled={
                  loading ||
                  loadingPrint ||
                  loadingDownload ||
                  !isKeyplanFullPage
                }
                value={axisOptionValue}
                options={AXIS_OPTIONS}
                height="4rem"
                minW={isMobile ? "10rem" : "12rem"}
                maxW={isMobile ? "10rem" : "12rem"}
                showIcon
                onChange={onChangeRatio}
              />

              <Select
                isDisabled={
                  loading ||
                  loadingPrint ||
                  loadingDownload ||
                  !sheetOptions?.options?.length
                }
                value={sheetIdSelected}
                groupOptions={[sheetOptions || ({} as any)]}
                height="4rem"
                minW={isMobile ? "14rem" : "24rem"}
                maxW={isMobile ? "14rem" : "24rem"}
                showIcon
                onChange={onChangeSheet}
              />

              <Button
                variant="filled"
                isDisabled={loading || loadingPrint || loadingDownload}
                onClick={onViewTaskList}
                flexShrink="0"
              >
                指摘一覧を表示
              </Button>
            </Flex>
          </>
        )}
      </Flex>

      {isOpenTaskSheetTemplateSelectModal && (
        <TaskSheetTemplateSelectModal
          isShowCancel={
            !!dataProjectDetail?.taskSheetTemplateId ||
            !!documentTask?.taskSheetTemplateId
          }
          isLoadingSelectTaskSheetTemplate={isLoadingSelectTaskSheetTemplate}
          taskSheetTemplateList={taskSheetTemplateList}
          value={taskSheetTemplateId}
          isOpen={isOpenTaskSheetTemplateSelectModal}
          onClose={onCloseTaskSheetTemplateSelectModal}
          onApply={handleSelectTaskSheetTemplate}
        />
      )}
    </>
  );
};

export default memo(TaskSheetHeader);

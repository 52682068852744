import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconSelectionAnalysis(props: IconProps) {
  return (
    <Icon
      width="1.9rem"
      height="2.2rem"
      fill="none"
      viewBox="0 0 19 22"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#171717"
          d="M18.34 6.71s.02-.09.02-.14c.01-.29-.01-.58-.08-.86 0-.03-.02-.07-.03-.1-.09-.25-.23-.46-.43-.66-.25-.21-.5-.38-.71-.46L9.62.42a3 3 0 0 0-.8-.37.6.6 0 0 0-.23-.03c-.44-.11-.93.25-.93.73v3.18l1.5.82V1.87l7.32 3.97c.12.05.25.13.33.21l.03.06q.03.135.03.27c-.01.06-.02.12-.02.18v7.78l-2.33-1.26V9.22s.02-.09.02-.13c.01-.28-.01-.57-.08-.86 0-.03-.02-.07-.03-.1-.1-.25-.23-.46-.42-.65-.24-.22-.5-.38-.71-.47L9.16 4.76l-1.5-.82L5.8 2.93a3 3 0 0 0-.8-.37 1 1 0 0 0-.23-.03c-.44-.12-.93.25-.93.73v3.18L1.98 5.43a3 3 0 0 0-.8-.37 1 1 0 0 0-.23-.03c-.44-.11-.93.25-.93.73v9.85c0 .27.15.53.39.66l9.05 4.91c.11.06.24.09.36.09.27 0 .52-.14.66-.39.02-.05.04-.09.06-.14.05-.07.08-.16.1-.25.07-.31.09-.61.07-.84v-2.38l2.58 1.4c.11.06.24.09.36.09.27 0 .52-.14.66-.39.02-.05.04-.09.06-.14.05-.07.08-.16.1-.25.07-.31.09-.61.07-.84v-2.38l2.58 1.4c.11.06.24.09.36.09.27 0 .52-.14.66-.39.02-.05.04-.09.06-.14.05-.07.08-.16.1-.25.07-.31.09-.61.07-.84V6.69zM9.19 19.33 1.5 15.16V6.87l2.33 1.26 1.5.81 2.33 1.26 1.16.63c.12.05.25.13.33.21l.03.06q.03.135.03.27c-.01.06-.02.12-.02.18zm3.83-2.5-2.33-1.27v-3.85s.02-.09.02-.14c0-.2 0-.4-.04-.59-.01-.09-.02-.18-.04-.27 0-.04-.02-.07-.03-.1-.09-.25-.23-.46-.42-.65-.24-.22-.5-.38-.71-.47l-.31-.17-1.5-.81-2.33-1.26V4.37l2.33 1.26 1.5.81 3.49 1.89c.12.05.25.13.33.21 0 0 .01.02.03.05q.03.135.03.27c-.01.06-.02.12-.02.18z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18.36v21.27H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
}

import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconTrash(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M14.2 23H9.8c-1.84 0-2.76 0-3.62-.44a4 4 0 0 1-1.75-1.75c-.44-.86-.44-1.78-.44-3.62V7H3c-.55 0-1-.45-1-1s.45-1 1-1h4c0-1.11.02-1.75.33-2.36.29-.57.74-1.02 1.31-1.31C9.28 1 9.97 1 11.2 1h1.6c1.24 0 1.92 0 2.56.33.57.29 1.02.74 1.31 1.31.31.61.33 1.25.33 2.36h4c.55 0 1 .45 1 1s-.45 1-1 1h-1v10.2c0 1.84 0 2.76-.44 3.62a4 4 0 0 1-1.75 1.75c-.86.44-1.78.44-3.62.44zM6 7v10.2c0 1.47 0 2.28.22 2.71.19.38.5.68.87.87.43.22 1.24.22 2.71.22h4.4c1.47 0 2.28 0 2.71-.22.38-.19.68-.5.87-.87.22-.43.22-1.24.22-2.71V7zm3-2h6c0-.76 0-1.26-.11-1.46-.1-.19-.25-.34-.44-.44-.21-.11-.79-.11-1.65-.11h-1.6c-.87 0-1.44 0-1.66.11-.19.1-.34.25-.44.44-.1.2-.11.7-.11 1.45zm5 12.5c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1s1 .45 1 1v5c0 .55-.45 1-1 1m-4 0c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1s1 .45 1 1v5c0 .55-.45 1-1 1"
      />
    </Icon>
  );
}

import { Box, BoxProps } from "@chakra-ui/react";
import IconNavigationExpandMore from "components/icon/IconNavigationExpandMore";
import { memo, useState } from "react";
import { Props as SVGProps } from "react-inlinesvg";

interface iProps extends BoxProps {
  onClose?: () => void;
  iconProps?: Omit<Partial<BoxProps & SVGProps>, "width" | "height">;
}

export function useCollapseModal() {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return { isCollapsed, handleCollapse, setIsCollapsed };
}

const CollapseModalIcon = ({ onClose, iconProps, ...rest }: iProps) => {
  return (
    <Box
      display="flex"
      zIndex={5}
      alignItems="center"
      justifyContent="center"
      position="absolute"
      backgroundColor="#FAFAFB"
      right="100%"
      top="1rem"
      border="1px solid"
      borderRightStyle="hidden"
      borderRadius="5px 0px 0px 5px"
      borderColor="var(--primary-border-color)"
      boxShadow="0 5px 2px -2px gray"
      cursor="pointer"
      width="4.6rem"
      height="4.6rem"
      {...rest}
      onClick={onClose}
    >
      <IconNavigationExpandMore
        transform="rotate(-90deg)"
        width="3.2rem"
        height="3.2rem"
        transition="transform 200ms ease-in-out 0s"
        {...(iconProps as any)}
      />
    </Box>
  );
};

export default memo(CollapseModalIcon);

import IconStatusConfirm from "components/icon/task/IconStatusConfirm";
import IconStatusDefect from "components/icon/task/IconStatusDefect";
import IconStatusTreated from "components/icon/task/IconStatusTreated";
import IconInspectionTreated from "components/icon/task/IconInspectionTreated";
import IconInspectionConfirm from "components/icon/task/IconInspectionConfirm";
import IconInspectionDefect from "components/icon/task/IconInspectionDefect";

export enum keyLabelArea {
  AREA_1,
  AREA_2,
  AREA_3,
  AREA_4,
}

export enum TASK_PRINT_MODE {
  PRINTABLE = "1",
  NOT_PRINTABLE = "2",
  // for filter by user setting
  ALL = "0",
}

export enum TaskItemDataKey {
  TASK_ITEM_TITLE = "TASK_ITEM_TITLE",
  TASK_ITEM_IMAGE = "TASK_ITEM_IMAGE",
  TASK_ITEM_FORGE_IMAGE = "TASK_ITEM_FORGE_IMAGE",
  TASK_ITEM_INDEX_ID = "TASK_ITEM_INDEX_ID",
  TASK_ITEM_STATUS = "TASK_ITEM_STATUS",
  TASK_ITEM_PRIORITY = "TASK_ITEM_PRIORITY",
}

export enum DocumentTaskDataKey {
  DOCUMENT_TASK_INFO_CREATION_DATE_TIME = "DOCUMENT_TASK_INFO_CREATION_DATE_TIME",
  DOCUMENT_TASK_INFO_INSPECTOR = "DOCUMENT_TASK_INFO_INSPECTOR",
  DOCUMENT_TASK_INFO_EXAMINEE = "DOCUMENT_TASK_INFO_EXAMINEE",

  QR_CODE = "QR_CODE",

  PROJECT_NAME = "PROJECT_NAME",
  TITLE_INSTRUCTION = "TITLE_INSTRUCTION",
  TITLE_REPORT = "TITLE_REPORT",

  TASK_ID = "TASK_ID",
  TASK_TITLE = "TASK_TITLE",
  TASK_MEMO = "TASK_MEMO",
  TASK_MANAGER = "TASK_MANAGER",
  TASK_DEADLINE = "TASK_DEADLINE",
  TASK_PARTNER_COMPANY = "TASK_PARTNER_COMPANY",
  TASK_CONFIRMED_MEMO = "TASK_CONFIRMED_MEMO",
  TASK_FIXED_DATE = "TASK_FIXED_DATE",
  TASK_CONFIRMED_DATE = "TASK_CONFIRMED_DATE",
  TASK_USER_CONFIRMED = "TASK_USER_CONFIRMED",
  TASK_USER_TREATED = "TASK_USER_TREATED",

  CORPORATE_COMPANY = "CORPORATE_COMPANY",
}

export enum BlackboardKey {
  ID = "id",
  SUB_ITEM_ID = "subItemId",
  WORK_TYPE_TEXT = "workTypeText",
  WORK_TYPE_ID = "workTypeId",
  TYPE_TEXT = "typeText",
  TYPE_ID = "typeId",
  SUB_TYPE_TEXT = "subTypeText",
  SUB_TYPE_ID = "subTypeId",
  CONTENT = "content",
  WITNESS_FIRST_ID = "witnessFirstId",
  WITNESS_SECOND_ID = "witnessSecondId",
  WITNESS = "witness",
  USER_CHECK = "userCheck",

  COMMENT_MANAGE_EXECUTE = "commentManageExecute",
  CONSTRUCTION_NAME_TEXT = "constructionNameText",
  CONSTRUCTION_NAME_ID = "constructionNameId",
  CONSTRUCTION_ADDRESS_TEXT = "constructionAddressText",
  CONSTRUCTION_ADDRESS_ID = "constructionAddressId",
  SYSTEM_NAME_TEXT = "systemNameText",
  SHOOTING_AREA_ID = "shootingAreaId",
  BUILDER_FIRST_TEXT = "builderFirstText",
  BUILDER_FIRST_ID = "builderFirstId",
  BUILDER_SECOND_TEXT = "builderSecondText",
  BUILDER_SECOND_ID = "builderSecondId",
  SHOOTING_AREA_TEXT = "shootingAreaText",
  SHOOTING_FLOOR_TEXT = "shootingFloorText",
  SHOOTING_FLOOR_ID = "shootingFloorId",
  SHOOTING_TIME = "shootingTime",
  START_TIME = "startTime",
  END_TIME = "endTime",
  MAINTAIN_TIME = "maintainTime",
  PRESSURE_START = "pressureStart",
  PRESSURE_END = "pressureEnd",
  PRESSURE_TEST = "pressureTest",
  SUPERVISOR_TEXT = "supervisorText",
  SUPERVISOR_ID = "supervisorId",
  USER_EXECUTE_ID = "userExecuteId",
  USER_EXECUTE_TEXT = "userExecuteText",

  UPDATED_AT = "updatedAt",
  CREATED_BY = "createdBy",
  FORM_TYPE = "formType",
  OFFLINE_ID = "offlineId",
  REQUEST_ID = "requestId",
  THUMBNAIL = "thumbnail",
}

export enum BlackboardDTOKey {
  IS_INSERT_LOG = "isInsertLog",
  IS_REVERT_DATA_BY_LOG = "isRevertDataByLog",
  BLACKBOARD_TEMPLATE_ID = "blackboardTemplateId",
}

export enum DocumentGroupKey {
  NAME = "name",
}

export enum DocumentCategoryKey {
  ID = "id",
  PROJECT_ID = "projectId",
  BIMFILE_ID = "bimFileId",
  TITLE = "title",
  DOCUMENT_TYPE = "documentType",
  LEVEL = "level",
  STATUS = "status",
  PRIORITY = "priority",
  DEADLINE = "deadline",
  TAGS = "tags",
  USER_ASSIGNED = "userAssigned",
  USER_MEASURE = "userMeasure",
  USER_MANAGE = "userManage",
  MANAGER = "manager",
  SYSTEM_NAME = "systemName",
  MANUFACTOR = "manufactor",
  CONSTRUCTION_NAME = "constructionName",
  PARTNER_COMPANY = "partnerCompany",
  CORPORATE_COMPANY = "corporateCompany",
  EXECUTE_DATE = "executeDate",
  MEASURE_DATE = "measureDate",
  USER_CREATED = "userCreated",
  USER_UPDATED = "userUpdated",
  TEMPLATE_ID = "templateId",
  SELECTED_EXTERNAL_IDS = "selectedExternalIds",
  USER_APPROVED = "userApproved",
  START_DATE_SCHEDULED = "startDateScheduled",
  END_DATE_SCHEDULED = "endDateScheduled",
  PERSON_IN_CHARGE = "personInCharge",
  WEATHER = "weather",
  USER_IMPLEMENT = "userImplement",
  INSPECTION_RESULT = "inspectionResult",
  INSPECTION_DATE = "inspectionDate",
  DUCT_SYSTEM = "ductSystem",
  MEASURING_DEVICE = "measuringDevice",
  DATA = "data",
  DOCUMENT_ITEMS = "documentItems",
  DOCUMENT_SUB_CATEGORIES = "documentSubCategories",
  PROJECT_NAME = "projectName",
  AREA_NAME = "areaName",
  IS_DEADLINE = "isDeadline",
  MIN_DEADLINE = "minDeadline",
  APPROVE_DATE = "approveDate",
  IMPLEMENT_DATE = "implementDate",
  KEYNOTE = "keynote",
  TEMPLATE_PARENT_ID = "templateParentId",
  CHILD_TEMPLATES = "childTemplates",
  FORCE_TO_SHOW = "forceToShow",
  AREAS = "areas",
  PARTNER_USERS = "partnerUsers",
  TEMPLATE_NAME = "templateName",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  DELETED_AT = "deletedAt",
  OFFLINE_ID = "offlineId",
  NEPTUNE_AREA_IDS = "neptuneAreaIds",
  SATURN_AREA_IDS = "saturnAreaIds",
  ALL_FLOOR = "allFloor",
  AREA_ID = "areaId", // need update
  ITEM_IDS = "itemIds",
  NO_AREA = "noArea",
  GROUP_ID = "groupId",
  BLOCKED_BY = "blockedBy",
  IS_BLOCKED = "isBlocked",
  MAP_TITLE_KEY = "mapTitleKey",
  MAP_DISPLAY_VALUE_KEY = "mapDisplayValueKey",
  MAP_DISPLAY_LABEL_KEY = "mapDisplayLabelKey",
  MAP_DISPLAY_VALUE_TYPE_KEY = "mapDisplayValueTypeKey",
}

export enum DocumentItemKey {
  ID = "id",
  SYSTEM_NAMES = "systemNames",
  POSITION = "position",
  EXTERNAL_ID = "externalId",
  TITLE = "title",
  BLACKBOARD_ID = "blackboardId",
  BLACKBOARD_IMAGE_POSITION = "blackboardImagePosition",
  ORIGIN_IMAGES = "originImages",
  IMAGES = "images",
  TAKE_IMAGE_DATES = "takeImageDates",
  STATUS = "status",
  DEADLINE = "deadline",
  TAGS = "tags",
  INDEX_ID = "indexId",
  PRIORITY = "priority",
  MEMO = "memo",
  ATTACHES = "attaches",
  IS_CONFIRM_BLACKBOARD = "isConfirmBlackboard",
  USER_CREATED = "userCreated",
  USER_ASSIGNED = "userAssigned",
  BIMFILE_ID = "bimFileId",
  PROJECT_ID = "projectId",
  CONTENT_TYPE = "contentType",
  LEVEL = "level",
  OBJECT_TYPES = "objectTypes",
  LINKED_DATA_ID = "linkedDataId",
  TEMPLATE_ID = "templateId",
  START_DATE_SCHEDULED = "startDateScheduled",
  END_DATE_SCHEDULED = "endDateScheduled",
  DATA = "data",
  PARENT_ID = "parentId",
  LAST_CHANGE_STATUS_DATETIME = "lastChangeStatusDateTime",
  MEASURE_START_TIME = "measureStartTime",
  MEASURE_END_TIME = "measureEndTime",
  BLACKBOARD_TEMPLATE_ID = "blackboardTemplateId",
  IS_SHOW_BLACKBOARD = "isShowBlackboard",
  DISPLAY_ORDER = "displayOrder",
  APPROVED_DATE_TIME = "approvedDateTime",
  IS_ADDED_FROM_FORGE = "isAddedFromForge",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  DELETED_AT = "deletedAt",
  IS_MOVED_FROM_OTHER = "isMovedFromOther",
  OFFLINE_ID = "offlineId",
  IS_CUSTOM_TITLE = "isCustomTitle",
  SUB_CATEGORY_TITLE = "subCategoryTitle",
  DOCUMENT_TYPE = "documentType",
  AREA_IDS = "areaIds",
  SUB_ITEM_IDS = "subItemIds",
  SUB_ITEMS = "subItems",
  FIELDS_TYPE_ARRAY = "fieldsTypeArray",
  IS_HIDE_PIN = "isHidePin",
}

export enum FamilyInstanceKey {
  NAME = "name",
  POSITION = "position",
  BOUNDS = "bounds",
  DB_ID = "dbId",
  EXTERNAL_ID = "externalId",
  TYPE_NAME = "typeName",
  SYMBOL = "symbol",
  LEVEL = "level",
  SYSTEM_TYPE = "systemType",
  SYSTEM_NAME = "systemName",
  FAN_TYPE = "fanType",
  DESIGN_OPTION = "designOption",
  SIGN = "sign",
  ESTIMATE_CONSTRUCTION_CATEGORY = "estimateConstructionCategory",
  FORM = "form",
  SIZE = "size",
  DIAMETER_RADIUS = "diameterRadius",
  AIR_VOLUME = "airVolume",
  ROOM = "room",
  SPACE_IDS = "spaceIds",
  OPENING_RATE = "openingRate",
  FACE_WIND_SPEED = "faceWindSpeed",
}

export enum LinkedFamilyData {
  NAME = "name",
  SYMBOL = "symbol",
  TYPE_NAME = "typeName",
  OBJECT_TYPES = "objectTypes",
  LEVEL = "level",
  SYSTEM_TYPE = "systemType",
  SYSTEM_NAME = "systemName",
  SIGN = "sign",
  ESTIMATE_CONSTRUCTION_CATEGORY = "estimateConstructionCategory",
  FORM = "form",
  SIZE = "size",
  DIAMETER_RADIUS = "diameterRadius",
  AIR_VOLUMN = "airVolume",
  ROOM = "room",
  OPENING_RATE = "openingRate",
  FACE_WIND_SPEED = "faceWindSpeed",
}

export enum LinkedDynamicFieldsType {
  TEXT = "TEXT",
  CHECK = "CHECK",
  USER_DROPDOWN = "USER_DROPDOWN",
  DATE_TIME = "DATE_TIME",
  MEASURE = "MEASURE",
  WEATHER = "WEATHER",
  FIXED_VALUE = "FIXED_VALUE",
  TEXT_AREA = "TEXT_AREA",
  NUMBER = "NUMBER",
  MEASURE_VALUE = "MEASURE_VALUE",
  MEASURE_AVERAGE_VALUE = "MEASURE_AVERAGE_VALUE",
}

export enum CommentCellData {
  FLOOR = "floor",
  TITLE = "title",
  WORK_TYPE_TEXT = "workTypeText",
  SUB_TYPE_TEXT = "subTypeText",
  SYSTEM_NAME_TEXT = "systemNameText",
  START_TIME = "startTime",
  END_TIME = "endTime",
  PRESSURE_START = "pressureStart",
  PRESSURE_END = "pressureEnd",
  COMMENT_MANAGE_EXECUTE = "commentManageExecute",
  SHOOTING_LOCATION = "shootingLocation",
  SHOOTING_DATE = "shootingDate",

  CONSTRUCTION_ADDRESS_TEXT = "constructionAddressText",
  USER_EXECUTE_TEXT = "userExecuteText",
  CONSTRUCTION_NAME = "constructionName",
}

export enum UploadType {
  Photo = "photo",
  BIM = "bim",
}

export enum DocumentCreateType {
  Standard = "1",
  FieldReport = "2",
}

export enum DocumentScreenType {
  Photo = "photo",
  Inspection = "inspection",
}

export enum FilterPhotoOptionType {
  WorkType = "workType",
  ShootingDate = "shootingDate",
  Type = "type",
  Subdivision = "subdivision",
  LargeClassificationPhotographs = "largeClassificationPhotographs",
}

export enum WorkType {
  WaterproofRepair = "WaterproofRepair",
  Waterproofing = "Waterproofing",
  SteelConstruction = "SteelConstruction",
  ReinforcingBar = "ReinforcingBar",
  Research = "Research",
  Equipment = "Equipment",
  Paving = "Paving",
}
export enum WorkTypeGroup {
  All = "All",
  NotSet = "NotSet",
}

export const MapWorkType: { [key: string]: string } = {
  [WorkTypeGroup.All]: "すべての工種",
  [WorkTypeGroup.NotSet]: "未設定",
  [WorkType.WaterproofRepair]: "防水改修工事",
  [WorkType.Waterproofing]: "防水工事",
  [WorkType.SteelConstruction]: "鉄骨工事",
  [WorkType.ReinforcingBar]: "鉄筋工事",
  [WorkType.Research]: "調査",
  [WorkType.Equipment]: "設備工事",
  [WorkType.Paving]: "設備工事",
};

export enum PhotoListType {
  ALL_PHOTO = "all",
  GARBAGE_PHOTO = "garbage",
}

export enum InspectionItemType {
  Confirmed = "confirmed",
  Defect = "defect",
  Treated = "treated",
}

export const DefaultKeynoteRatio = 16 / 9;

export const MapInspectionItemColor = {
  [InspectionItemType.Confirmed]: "#0284C7",
  [InspectionItemType.Defect]: "#DC2626",
  [InspectionItemType.Treated]: "#9766A7",
  "": "#fff",
};

export const SIZE_ICON_TASK_STATUS = {
  [InspectionItemType.Confirmed]: {
    width: "8px",
    height: "7px",
  },
  [InspectionItemType.Defect]: {
    width: "8px",
    height: "8px",
  },
  [InspectionItemType.Treated]: {
    width: "8px",
    height: "8px",
  },
};

export const MapInspectionItemIconStatus = {
  [InspectionItemType.Confirmed]: IconStatusConfirm,
  [InspectionItemType.Defect]: IconStatusDefect,
  [InspectionItemType.Treated]: IconStatusTreated,
};

export const MapInspectionItemIcon = {
  [InspectionItemType.Confirmed]: IconInspectionConfirm,
  [InspectionItemType.Defect]: IconInspectionDefect,
  [InspectionItemType.Treated]: IconInspectionTreated,
};

export const MapInspectionItemType = {
  [InspectionItemType.Confirmed]: "確認済",
  [InspectionItemType.Defect]: "不具合",
  [InspectionItemType.Treated]: "処置済",
};
export const MapInspectionItemTypeFilter = {
  "": "全ての指摘",
  [InspectionItemType.Confirmed]: "確認済みの指摘",
  [InspectionItemType.Defect]: "不具合の指摘",
  [InspectionItemType.Treated]: "処置済みの指摘",
};

export enum DocumentCategoryStatusType {
  NotStarted = "NotStarted",
  Underway = "Underway",
  SendBack = "SendBack",
  WaitConfirm = "WaitConfirm",
  Approved = "Approved",
}

export const DocumentCategoryStatusPriority = {
  0: DocumentCategoryStatusType.NotStarted,
  1: DocumentCategoryStatusType.Underway,
  2: DocumentCategoryStatusType.SendBack,
  3: DocumentCategoryStatusType.WaitConfirm,
  4: DocumentCategoryStatusType.Approved,
};

export const MapDocumentCategoryStatusType = {
  [DocumentCategoryStatusType.NotStarted]: "未着手",
  [DocumentCategoryStatusType.Underway]: "着手中",
  [DocumentCategoryStatusType.SendBack]: "指摘あり",
  [DocumentCategoryStatusType.WaitConfirm]: "承認待ち",
  [DocumentCategoryStatusType.Approved]: "承認済み",
};

export const MapDocumentCategoryStatusTypeColor = {
  [DocumentCategoryStatusType.NotStarted]: "#A0A8C6",
  [DocumentCategoryStatusType.Underway]: "#D97706",
  [DocumentCategoryStatusType.WaitConfirm]: "#009BE0",
  [DocumentCategoryStatusType.Approved]: "#338D88",
  [DocumentCategoryStatusType.SendBack]: "#BA0F0F",
};

export const MapDocumentCategoryStatusTypeColorText = {
  [DocumentCategoryStatusType.NotStarted]: "#171717",
  [DocumentCategoryStatusType.Underway]: "#FFFFFF",
  [DocumentCategoryStatusType.WaitConfirm]: "#FFFFFF",
  [DocumentCategoryStatusType.Approved]: "#FFFFFF",
  [DocumentCategoryStatusType.SendBack]: "#FFFFFF",
};

export const MapDocumentCategoryStatusTypeProgressColor = {
  [DocumentCategoryStatusType.NotStarted]: "#A0A8C6",
  [DocumentCategoryStatusType.Underway]: "#D97706",
  [DocumentCategoryStatusType.WaitConfirm]: "#009BE0",
  [DocumentCategoryStatusType.Approved]: "#338D88",
  [DocumentCategoryStatusType.SendBack]: "#BA0F0F",
};

export enum CheckboxDisplayOptionsEnum {
  Value = "value",
  Options = "options",
  Checkbox = "checkbox",
}

export enum CheckboxDirectionOptionsEnum {
  HORIZONTAL = "column",
  VERTICAL = "row",
}

export enum DocumentCheckboxStatusOptionsType {
  YesNo = "有・無",
  OX = "O・X",
  GoodBad = "良・否",
  Agree = "合・不",
  Qualified = "合格　・不合格", // space is added to the text to display the balanced text when adjusting the text position (left/center/right)
  GoodDefective = "良　・不良",
}

export enum DocumentStatusType {
  Incomplete = "0",
  Complete = "1",
  NotGood = "2",
}

export const MapExtractionInspectionResultType = {
  [DocumentStatusType.Incomplete]: "未着手",
  [DocumentStatusType.Complete]: "良",
  [DocumentStatusType.NotGood]: "否",
};

export const MapDocumentStatusTypeColor = {
  [DocumentStatusType.Complete]: "#18A881",
  [DocumentStatusType.Incomplete]: "#ECB209",
  [DocumentStatusType.NotGood]: "#DC2626",
};

export const MapDocumentStatusTypeIcon = {
  [DocumentStatusType.Complete]: "/img/document-completed-24.svg",
  [DocumentStatusType.Incomplete]: "/img/document-incompleted-24.svg",
  [DocumentStatusType.NotGood]: "/img/document-notgood-24.svg",
};

export const MapDocumentStatusType = {
  [DocumentStatusType.Complete]: "検査後",
  [DocumentStatusType.Incomplete]: "検査前",
  [DocumentStatusType.NotGood]: "不良",
};

export const MapDocumentStatusCheckboxLabel = {
  [DocumentStatusType.Complete]: "良",
  [DocumentStatusType.Incomplete]: "",
  [DocumentStatusType.NotGood]: "不良",
};

export const MapDocumentStatusWantCheckboxLabel = {
  [DocumentStatusType.Complete]: "要",
  [DocumentStatusType.Incomplete]: "",
  [DocumentStatusType.NotGood]: "不要",
};

export enum Priority {
  High = 1,
  Normal = 2,
  Low = 3,
}

export const PriorityLabel = {
  [Priority.High]: "高",
  [Priority.Normal]: "中",
  [Priority.Low]: "低",
} as const;

// todo: PriorityLabel & Priority と統合管理
// todo: color theme
export const PriorityColor = {
  [Priority.High]: { color: "rgb(196,72,24)", bgcolor: "rgb(246,226,218)" },
  [Priority.Normal]: { color: "rgb(255,148,64)", bgcolor: "rgb(255,240,226)" },
  [Priority.Low]: { color: "rgb(250,200,150)", bgcolor: "rgb(240,240,240)" },
} as const;

export const PriorityOption = {
  [Priority.High]: "緊急度 高",
  [Priority.Normal]: "緊急度 中",
  [Priority.Low]: "緊急度 低",
};

export const PriorityIcon = {
  [Priority.High]: "/img/priority-high-arrow.svg",
  [Priority.Normal]: "/img/priority-medium.svg",
  [Priority.Low]: "/img/priority-low.svg",
};

export const PriorityFontColor = {
  [Priority.High]: "#DC2626",
  [Priority.Normal]: "#D97706",
  [Priority.Low]: "#D97706",
};

export const PriorityBgColor = {
  [Priority.High]: "#FEF2F2",
  [Priority.Normal]: "#FFFBEB",
  [Priority.Low]: "#E5E5E5",
};

export enum YesNoAllFilterType {
  Yes = "1",
  No = "0",
  All = "",
}

export const MapDocumentFilterType = {
  [YesNoAllFilterType.Yes]: "有り",
  [YesNoAllFilterType.No]: "なし",
  [YesNoAllFilterType.All]: "すべて",
};

export const MapImageFilterType = {
  [YesNoAllFilterType.Yes]: "有り",
  [YesNoAllFilterType.No]: "なし",
  [YesNoAllFilterType.All]: "すべて",
};

export enum ItemBIMType {
  FOLDERS = "folders",
  ITEMS = "items",
  GEOMETRY = "geometry",
}

export enum StatusType {
  SUCCESS = "success",
}

export enum SystemModeType {
  Task = "1",
  Document = "2",
}

export const SystemMode = {
  [SystemModeType.Document]: "書類一覧",
  [SystemModeType.Task]: "指摘一覧",
};

export enum SortDocumentType {
  None = "none",
  Manager = "manager",
  Author = "author",
  Deadline = "deadline",
  CreationTime = "creationTime",
}

export enum SortTaskType {
  Id = "id",
  Title = "title",
}

export const SortDocumentMode = {
  [SortDocumentType.None]: "なし",
  [SortDocumentType.Manager]: "担当者",
  [SortDocumentType.Author]: "作成者",
  [SortDocumentType.Deadline]: "期日",
  [SortDocumentType.CreationTime]: "作成時間",
};

export const SortTaskMode = {
  [SortTaskType.Id]: "タスクID",
  [SortTaskType.Title]: "タイトル",
};

export enum ChecklistPropertyType {
  CheckboxOnly = "1",
  Good = "2",
  Bad = "3",
  CustomText = "4",
}

export const ChecklistProperties = {
  [ChecklistPropertyType.CheckboxOnly]: "チェックボックスのみ",
  [ChecklistPropertyType.Good]: "良",
  [ChecklistPropertyType.Bad]: "不良",
  [ChecklistPropertyType.CustomText]: "カスタムテキスト",
};

export enum DocumentImageType {
  Image = "1",
  Blackboard = "2",
}

export enum PaperType {
  A3 = "A3",
  A4 = "A4",
}

export const PaperSize = {
  [PaperType.A3]: {
    width: "29.7cm",
    height: "42cm",
    numOfRows: 77,
    numOfColumns: 51,
  },
  [PaperType.A4]: {
    width: "21cm",
    height: "29.7cm",
    numOfRows: 54,
    numOfColumns: 36,
  },
};

export enum PaperDirectionType {
  VERTICAL = "1",
  HORIZONTAL = "2",
}

export const PaperDirection = {
  [PaperDirectionType.VERTICAL]: "縦向",
  [PaperDirectionType.HORIZONTAL]: "横向",
};

export enum TemplateComponentType {
  Table = "TABLE",
  Image = "IMAGE",
  Text = "TEXT",
  TableHeader = "TABLE_HEADER",
  FilterPhoto = "FILTER_PHOTO",
  LinkedImage = "LINKED_IMAGE",

  QR_CODE = "QR_CODE",
  Keyplan = "KEYPLAN",
  TasksImage = "TASKS_IMAGE",

  PhotoBooksInstruction = "PHOTO_BOOKS_INSTRUCTION",
  PhotoBooksReport = "PHOTO_BOOKS_REPORT",
  ImageUploaded = "IMAGE_UPLOADED",
}

export enum DragComponentAction {
  Create = "CREATE",
  Move = "MOVE",
}

export const TemplateComponentProps = {
  [TemplateComponentType.TableHeader]: {
    name: "ヘッダーの配置",
    iconSrc: "/img/icon-table-header.svg",
  },
  [TemplateComponentType.Table]: {
    name: "テーブルの配置",
    iconSrc: "/img/grid.svg",
  },
  [TemplateComponentType.Text]: {
    name: "テキストボックス",
    iconSrc: "/img/text-box.svg",
  },
  [TemplateComponentType.ImageUploaded]: {
    name: "画像挿入",
    iconSrc: "/img/image-icon.svg",
  },
  [TemplateComponentType.Image]: {
    name: "キーノート",
    iconSrc: "/img/image-icon.svg",
  },
  [TemplateComponentType.FilterPhoto]: {
    name: "帳票用写真",
    iconSrc: "/img/image-icon.svg",
  },
  [TemplateComponentType.LinkedImage]: {
    name: "連番写真",
    iconSrc: "/img/image-icon.svg",
  },

  [TemplateComponentType.QR_CODE]: {
    name: "QR Code",
    iconSrc: "/img/image-icon.svg",
  },
  [TemplateComponentType.Keyplan]: {
    name: "キープラン",
    iconSrc: "/img/image-icon.svg",
  },
  [TemplateComponentType.TasksImage]: {
    name: "写真帳（旧）",
    iconSrc: "/img/image-icon.svg",
  },
  [TemplateComponentType.PhotoBooksInstruction]: {
    name: "是正指示の写真帳",
    iconSrc: "/img/image-icon.svg",
  },
  [TemplateComponentType.PhotoBooksReport]: {
    name: "是正報告の写真帳",
    iconSrc: "/img/image-icon.svg",
  },
};

export enum DocumentTemplateType {
  PHOTO_LEDGER = "1",
  SELF_INSPECTION = "2",
  COMMISSIONING_TABLE = "3",
  EQUIPMENT_DATA_SHEET = "4",
  PARENT_TEMPLATE = "5",
}

export const LinkedDataField = {
  DOCUMENT_TASK_DATA: DocumentTaskDataKey,
  TASK_ITEM_DATA: TaskItemDataKey,
  BLACKBOARD: { ...BlackboardKey, IMAGE_UPLOAD_INFO: "image-upload-info" },
  DOCUMENT_ITEM: DocumentItemKey,
  DOCUMENT_CATEGORY: DocumentCategoryKey,
  FAMILY_INSTANCE: FamilyInstanceKey,
  COMMON: {
    NO: "No.",
    DYNAMIC_FIELDS_FOR_ITEM: "dynamic-fields-for-item",
    DYNAMIC_FIELDS_FOR_CATEGORY: "dynamic-fiels-for-category",
    COMMENT: "comment",
    INPUT_DATA: "input-data",
    CHECK_BOX: "check-box",
    REMARKS: "remarks",
    PASS_CRITERIA: "pass-or-fail-criteria",
    MEASUREMENT_ITEM: "measurement-item",
    MEASURED_VALUE: "measured-value",
    DESIGN_VALUE: "design-value",
    JUDGEMENT: "judgement",
    CHECKLIST: "checklist",
    CONFIRMATION: "confirmation",
    IMAGE: "image",
    ACTION_TAKEN: "action-taken",
    REFERENCE_MATERIAL: "reference-material",
    TITLE: "title",
  },
};

export enum CellProperty {
  TASK_ITEM_FORGE_IMAGE = "TASK_ITEM_FORGE_IMAGE",
  TASK_ITEM_IMAGE = "TASK_ITEM_IMAGE",
  TASK_ITEM_TITLE = "TASK_ITEM_TITLE",
  TASK_ITEM_HEADER = "TASK_ITEM_HEADER",
  LINKED_IMAGE_DEFAULT_TEXT = "LINKED_IMAGE_DEFAULT_TEXT",
  LINKED_IMAGE_LINE = "LINKED_IMAGE_LINE",
  DOCUMENT_TASK_DATA = "DOCUMENT_TASK_DATA",
  TASK_ITEM_DATA = "TASK_ITEM_DATA",
  TEXT = "text",
  INPUT_DATA = "input-data",
  CALENDAR = "calendar",
  CHECK_BOX = "check-box",
  IMAGE = "image",
  ICON = "icon",
  COMMENT = "comment",
  DOCUMENT_DATA = "document-data",
  FILTER_PHOTO_COMMENT = "filter-photo-comment",
  BLACK_BOARD_DATA = "black-board-data",
  SUB_TABLE = "sub-table",
  FAMILY_INSTANCE = "family-instace",
  NO = "No.",
  DYNAMIC_FIELDS_FOR_ITEM = "dynamic-fields-for-item",
  DYNAMIC_FIELDS_FOR_CATEGORY = "dynamic-fiels-for-category",

  // map ios app
  TYPE_OF_EQUIPMENT = "type-of-equipment",
}

export enum CellDisplayValueType {
  DEVICE_NUMBER = "1",
  FAMILY_NAME = "2",
  CUSTOM_VALUE = "3",
}

export const NoDataIndex = "No.";
export enum CellDataValue {}

export const DEFAULT_TITLE_SLEEVE_PIPE_DOCUMENT_ITEM =
  "防火区画貫通処理状況確認";

export enum StyleType {
  ROWS = "rows",
  COLUMNS = "columns",
  BORDER = "border",
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
  LINE_THROUGH = "lineThrough",
  TEXT_ALIGN = "textAlign",
  TAB_LEFT = "tabLeft",
  TAB_RIGHT = "tabRight",
  JUSTIFY_CONTENT = "justifyContent",
}

export enum MergeCellType {
  COL_SPAN = "colSpan",
  ROW_SPAN = "rowSpan",
}

export enum TableElementType {
  COL = "col",
  ROW = "row",
  COL_LINKED = "linked",
}

export enum CellSizeSetting {
  DEFAULT_WIDTH = 300,
  DEFAULT_HEIGHT = 100,
  MIN_WIDTH = 20,
  MIN_HEIGHT = 20,
  WIDTH = "width",
  HEIGHT = "height",
}

export enum TableSizeSetting {
  DEFAULT_WIDTH = 300,
  DEFAULT_HEIGHT = 100,
  DEFAULT_COLUMN_COUNT = 2,
  DEFAULT_ROW_COUNT = 1,
  MIN_DISTANCE = 10,
}

export enum BorderSizeEventComponent {
  SIZE = 20,
  PADDING = 5,
}

export enum FontFamiLyType {
  HIRAGINO_KAKUGO_W3 = "1",
  NOTO_SANS = "2",
  NOTO_SERIF = "3",
}

export enum DocumentLevelModeType {
  Document = "1",
  Floor = "2",
}

export const DocumentLevelMode = {
  [DocumentLevelModeType.Document]: "書類",
  [DocumentLevelModeType.Floor]: "フロア",
};

export enum DocumentFormatModeType {
  Project = "1",
  DocumentType = "2",
}

export const DocumentFormatMode = {
  [DocumentFormatModeType.Project]: "プロジェクト",
  [DocumentFormatModeType.DocumentType]: "書類テンプレートの種類",
};

export enum ImageComponentSize {
  MIN_WIDTH = 20,
  MIN_HEIGHT = 20,
}

export enum TextBoxComponentSize {
  MIN_WIDTH = 20,
  MIN_HEIGHT = 20,
}

export enum TableDefaultStyle {
  DEFAULT_BORDER_SIZE = 1,
}

export enum KeyBoardCode {
  KeyZ = "KeyZ",
  KeyC = "KeyC",
  KeyY = "KeyY",
  KeyS = "KeyS",
  Backspace = "Backspace",
  Delete = "Delete",
  Enter = "Enter",
}

export enum Axis {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

export enum AcceptFileTypes {
  CSV = "csv",
  IMAGE = "image/*",
  AUDIO = "audio/*",
  VIDEO = "video/*",
}

export const MapDocumentTitle = {
  [DocumentTemplateType.PHOTO_LEDGER]: "機器番号",
  [DocumentTemplateType.COMMISSIONING_TABLE]: "機器番号",
  [DocumentTemplateType.SELF_INSPECTION]: "検査項目",
  [DocumentTemplateType.EQUIPMENT_DATA_SHEET]: "機器番号",
  [DocumentTemplateType.PARENT_TEMPLATE]: "検査グループ",
};

export enum TextPosition {
  START = "start",
  CENTER = "center",
  END = "end",
  JUSTIFY = "space-between",
  LEFT = "left",
  RIGHT = "right",
}

export enum TypeFieldDynamic {
  TYPE_CATEGORY = "category",
  TYPE_ITEM = "item",
  INPUT_CATEGORY_LABEL = "customInputCategoryLinkedLabel",
  INPUT_ITEM_LABEL = "customInputItemLinkedLabel",
  INPUT_CATEGORY_DATA = "customInputCategoryLinkedData",
  INPUT_ITEM_DATA = "customInputItemLinkedData",
}

export enum WidgetsMode {
  NONE = 0,
  PROJECT_DASHBOARD = 1,
  USER_DASHBOARD = 2,
  DOCUMENT_DASHBOARD = 3,
  DOCUMENT_FORMAT_LIST = 4,
}

export enum TypeOfCellDataDisplay {
  DISPLAY_VALUE = "display-value",
  DEFAULT_VALUE = "default-value",
  DATE_FORMAT = "date-format",
}

export enum GoodBadImage {
  GOOD = "goodImage",
  BAD = "badImage",
}

export enum ModalType {
  TASK = "TASK",
  AREA = "AREA",
  SYSTEM = "SYSTEM",
  SYSTEM_TYPE = "SYSTEM_TYPE",
  FAMILY_INSTANCE = "FAMILY_INSTANCE",
  DOC_CATEGORY = "DOC_CATEGORY",
  DOC_GROUP = "DOC_GROUP",
  DOC_ITEM = "DOC_ITEM",
  DOC_SUB_CATEGORY = "DOC_SUB_CATEGORY",
  DOC_TEMPLATE = "DOC_TEMPLATE",
  NONE = "NONE",
}

export enum ContentType {
  EDITOR = "EDITOR",
  PREVIEW = "PREVIEW",
  PAC = "PAC",
  PHOTO_LEDGER = "PHOTO_LEDGER",
  SELF_INSPECTION = "SELF_INSPECTION",
  EQUIPMENT_DATA_SHEET = "EQUIPMENT_DATA_SHEET",
  BLACKBOARD_TEMPLATE = "BLACKBOARD_TEMPLATE",
  LINKED_IMAGE = "LINKED_IMAGE",

  // task sheet template
  DOCUMENT_TASK_DATA = "DOCUMENT_TASK_DATA",
  TASK_ITEM_DATA = "TASK_ITEM_DATA",
}

export enum FamilyTitleDisplayType {
  SubCategory = "sub-category",
  DocumentItem = "document-item",
}

export enum MapTaskDealineTitle {
  expired = "期限切れ",
  aboutToExpire = "期限間近",
  notExpired = "直近",
}

export enum DeadlineType {
  expired = "expired",
  aboutToExpire = "aboutToExpire",
  notExpired = "notExpired",
}

export enum FilterInputType {
  MANAGER = "manager",
  STATUS = "status",
  TAGS = "tags",
  FLOORS = "floor",
  END_DATE_SCHEDULED = "endDateScheduled",
  START_DATE_SCHEDULED = "startDateScheduled",
  END_DATE = "endDate",
  START_DATE = "startDate",
  PROJECTS = "projects",
  IMAGES = "images",
  FAMILY = "family",
  IS_EXPIRED = "isExpired",
  PARTNER_COMPANY = "partnerCompany",
}

export enum FilterInputLabel {
  MANAGER = "担当者",
  STATUS = "ステータス",
  TAGS = "タグ",
  FLOORS = "フロア",
  END_DATE_SCHEDULED = "完了予定日",
  START_DATE_SCHEDULED = "開始予定日",
  END_DATE = "完了日",
  START_DATE = "開始日",
  PROJECTS = "プロジェクト",
  IMAGES = "写真",
  FAMILY = "ファミリ",
  PARTNER_COMPANY = "協力会社",
}

export enum FilterType {
  Project = "project",
  User = "user",
}

export enum SetImageType {
  SetImage = "写真設定済",
  NotSetImage = "要写真",
}

export enum QuickFilterKey {
  dateRange = "dateRange",
  levels = "levels",
  tags = "tags",
  mode = "mode",
  projects = "projects",
}

export enum DataModeE {
  ALL = "ALL",
  TASK = "TASK",
  DOC = "DOC",
  MANAGE = "MANAGE",
}

export enum STATUS_UPDATE_FORGE_VERSION {
  CHECKING = "CHECKING",
  SHOULD_UPDATE = "SHOULD_UPDATE",
  CHECKED = "CHECKED",
}

export enum PHOTO_BOOK_CONTENT_TYPE {
  REPORT = "REPORT",
  INSTRUCTION = "INSTRUCTION",
}

export enum ZoomType {
  ZOOM_IN = "zoomin",
  ZOOM_OUT = "zoomout",
}

export enum ORIENTATION_TYPE {
  PORTRAIT,
  LANDSCAPE,
}

export enum SubItemKey {
  ID = "id",
  ITEM_ID = "itemId",
  BLACKBOARD_ID = "blackboardId",
  BLACKBOARD_TEMPLATE_ID = "blackboardTemplateId",
  IS_CUSTOM_TITLE = "isCustomTitle",
  IS_ADDED_FROM_FORGE = "isAddedFromForge",
  IS_HIDDEN = "isHidden",
  STATUS = "status",
  TAKE_IMAGE_DATES = "takeImageDates",
  TITLE = "title",
  LINKED_DATA_ID = "linkedDataId",
  BLACKBOARD_IMAGE_POSITION = "blackboardImagePosition",
  DATA = "data",
  IS_SHOW_BLACKBOARD = "isShowBlackboard",
  MEMO = "memo",
  ATTACHES = "attaches",
  IMAGES = "images",
  CREATED_BY = "createdBy",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  DELETED_AT = "deletedAt",
}

import {
  CellDisplayValueType,
  CellProperty,
  CellSizeSetting,
  CheckboxDirectionOptionsEnum,
  CheckboxDisplayOptionsEnum,
  DEFAULT_TITLE_SLEEVE_PIPE_DOCUMENT_ITEM,
  DocumentCheckboxStatusOptionsType,
  DocumentTemplateType,
  FontFamiLyType,
  LinkedDataField,
  LinkedDynamicFieldsType,
  LinkedFamilyData,
  PaperDirectionType,
  PaperSize,
  PaperType,
  TableDefaultStyle,
  NoDataIndex,
} from "constants/enum";
import { CellPropertyOption } from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import {
  TaskSheetTemplate,
  TEMPLATE_TASKSHEET_TYPE,
} from "interfaces/models/taskSheetTemplate";
import { generateRandomString } from "utils/string";

export const COMPONENT_SELECTED_OPACITY = 0.4;

export const SIZE_PAGE_RATIO_A4 = 1;
export const SIZE_PAGE_RATIO_A3 =
  PaperSize[PaperType.A3].numOfColumns / PaperSize[PaperType.A4].numOfColumns;
export const PAGE_DIRECTION_RATIO_VERTICAL = 1;

export const DEFAULT_TEMPLATE: DocumentTemplate = {
  pages: [
    {
      pageSize: PaperType.A3,
      pageDirection: PaperDirectionType.VERTICAL,
      numOfRows: PaperSize[PaperType.A3].numOfRows,
      numOfColumns: PaperSize[PaperType.A3].numOfColumns,
      sizePageRatio: SIZE_PAGE_RATIO_A3,
      pageDirectionRatio: PAGE_DIRECTION_RATIO_VERTICAL,
      pageId: generateRandomString(36, 10),
    },
  ],
  documentType: DocumentTemplateType.PHOTO_LEDGER,
  components: [],
  isActive: false,
  bimFileId: "",
  projectId: undefined,
  templateName: "",
  createdBy: "",
  isDefault: true,
  forceToShow: true,
  families: [],
};

export const DEFAULT_TASK_SHEET_TEMPLATE: TaskSheetTemplate = {
  // page horizontal config
  id: "",
  pages: [
    {
      pageSize: PaperType.A4,
      pageDirection: PaperDirectionType.HORIZONTAL,
      numOfRows: PaperSize[PaperType.A4].numOfColumns,
      numOfColumns: PaperSize[PaperType.A4].numOfRows,
      sizePageRatio: SIZE_PAGE_RATIO_A4,
      pageDirectionRatio:
        PaperSize[PaperType.A4].numOfRows /
        PaperSize[PaperType.A4].numOfColumns,
      pageId: generateRandomString(36, 10),
    },
  ],
  components: [],
  templateName: "",
  createdBy: "",
  templateType: TEMPLATE_TASKSHEET_TYPE.INSTRUCTIONS,
  isDefault: false,
};

export const DEFAULT_GRID_SIZE = {
  width:
    PaperSize[PaperType.A4].numOfColumns * CellSizeSetting.MIN_WIDTH +
    TableDefaultStyle.DEFAULT_BORDER_SIZE,
  height:
    PaperSize[PaperType.A4].numOfRows * CellSizeSetting.MIN_HEIGHT +
    TableDefaultStyle.DEFAULT_BORDER_SIZE,
};

export const CellPropertyDetail = {
  [CellProperty.TEXT]: {
    name: "文字列",
    value: CellProperty.TEXT,
    iconSrc: "/img/icon-text.svg",
  },
  [CellProperty.INPUT_DATA]: {
    name: "テキスト入力欄",
    value: CellProperty.INPUT_DATA,
    iconSrc: "/img/text-box.svg",
  },
  [CellProperty.DOCUMENT_DATA]: {
    name: "書類のデータ",
    value: CellProperty.DOCUMENT_DATA,
    iconSrc: "/img/icon-dropdown.svg",
  },
  [CellProperty.DOCUMENT_TASK_DATA]: {
    name: "Document task data",
    value: CellProperty.DOCUMENT_TASK_DATA,
    iconSrc: "/img/icon-dropdown.svg",
  },
  [CellProperty.TASK_ITEM_DATA]: {
    name: "指摘ピン",
    value: CellProperty.TASK_ITEM_DATA,
    iconSrc: "/img/icon-dropdown.svg",
  },
  [CellProperty.CHECK_BOX]: {
    name: "チェックボックス",
    value: CellProperty.CHECK_BOX,
    iconSrc: "/img/check-box-empty.svg",
  },
  [CellProperty.BLACK_BOARD_DATA]: {
    name: "黒板のデータ",
    value: CellProperty.BLACK_BOARD_DATA,
    iconSrc: "/img/icon-dropdown.svg",
  },
};

export const FamilyInstanceTypeOptions = [
  {
    name: "器具No.",
    value: LinkedFamilyData.SYMBOL,
  },
  {
    name: "階",
    value: LinkedFamilyData.LEVEL,
  },
  {
    name: "室名",
    value: LinkedFamilyData.ROOM,
  },
  {
    name: "型式",
    value: LinkedFamilyData.TYPE_NAME,
  },
  {
    name: "サイズ",
    value: LinkedFamilyData.SIZE,
  },
  {
    name: "断面積",
    value: LinkedFamilyData.DIAMETER_RADIUS,
  },
  {
    name: "風量",
    value: LinkedFamilyData.AIR_VOLUMN,
  },
  {
    name: "場　所",
    value: LinkedFamilyData.ESTIMATE_CONSTRUCTION_CATEGORY,
  },
  {
    name: "符号",
    value: LinkedFamilyData.SIGN,
  },
  {
    name: "開口率",
    value: LinkedFamilyData.OPENING_RATE,
  },
  {
    name: "面風速",
    value: LinkedFamilyData.FACE_WIND_SPEED,
  },
];

export const TASK_ITEM_DATA = [
  {
    name: "Task item index id",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_INDEX_ID,
  },
  {
    name: "Task item status",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_STATUS,
  },
  {
    name: "Task item priority",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_PRIORITY,
  },
  {
    name: "Task item image",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_IMAGE,
  },
  {
    name: "Task item forge image",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_FORGE_IMAGE,
  },
];

export const DynamicFieldsDataTypeOptions = [
  {
    name: "テキスト",
    value: LinkedDynamicFieldsType.TEXT,
  },
  {
    name: "数値",
    value: LinkedDynamicFieldsType.NUMBER,
  },
  {
    name: "チェック項目",
    value: LinkedDynamicFieldsType.CHECK,
  },
  {
    name: "測定値入力項目",
    value: LinkedDynamicFieldsType.MEASURE_VALUE,
  },
  {
    name: "測定値の平均値",
    value: LinkedDynamicFieldsType.MEASURE_AVERAGE_VALUE,
  },
  {
    name: "ユーザー選択",
    value: LinkedDynamicFieldsType.USER_DROPDOWN,
  },
  {
    name: "日付",
    value: LinkedDynamicFieldsType.DATE_TIME,
  },
  {
    name: "測定値の平均値（3回測定値入力）",
    value: LinkedDynamicFieldsType.MEASURE,
  },
  {
    name: "天気",
    value: LinkedDynamicFieldsType.WEATHER,
  },
  {
    name: "固定値",
    value: LinkedDynamicFieldsType.FIXED_VALUE,
  },
  {
    name: "テキストエリア",
    value: LinkedDynamicFieldsType.TEXT_AREA,
  },
];

export const CellPropertyForBlackboardTemplateOptions = [
  {
    name: "工事件名",
    value: LinkedDataField.BLACKBOARD.CONSTRUCTION_NAME_TEXT,
  },
  {
    name: "撮影日",
    value: LinkedDataField.BLACKBOARD.SHOOTING_TIME,
  },
  {
    name: "撮影場所",
    value: LinkedDataField.BLACKBOARD.SHOOTING_FLOOR_TEXT,
  },
  {
    name: "工事場所",
    value: LinkedDataField.BLACKBOARD.CONSTRUCTION_ADDRESS_TEXT,
  },
  {
    name: "内容",
    value: LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE,
  },
  {
    name: "工事種別",
    value: LinkedDataField.BLACKBOARD.WORK_TYPE_TEXT,
  },
  {
    name: "試験種類",
    value: LinkedDataField.BLACKBOARD.SUB_TYPE_TEXT,
  },
  {
    name: "系統名",
    value: LinkedDataField.BLACKBOARD.SYSTEM_NAME_TEXT,
  },
  {
    name: "開始時間",
    value: LinkedDataField.BLACKBOARD.START_TIME,
  },
  {
    name: "終了時間",
    value: LinkedDataField.BLACKBOARD.END_TIME,
  },
  {
    name: "初圧",
    value: LinkedDataField.BLACKBOARD.PRESSURE_START,
  },
  {
    name: "終圧",
    value: LinkedDataField.BLACKBOARD.PRESSURE_END,
  },
  {
    name: "施工者",
    value: LinkedDataField.BLACKBOARD.BUILDER_FIRST_TEXT,
  },
  {
    name: "立会者",
    value: LinkedDataField.BLACKBOARD.SUPERVISOR_TEXT,
  },
  {
    name: "実施者",
    value: LinkedDataField.BLACKBOARD.USER_EXECUTE_TEXT,
  },
  {
    name: "階",
    value: LinkedDataField.BLACKBOARD.SHOOTING_AREA_TEXT,
  },
  {
    name: "保持時間",
    value: LinkedDataField.BLACKBOARD.MAINTAIN_TIME,
  },
  {
    name: "試験圧力",
    value: LinkedDataField.BLACKBOARD.PRESSURE_TEST,
  },
  {
    name: "撮影",
    value: LinkedDataField.BLACKBOARD.IMAGE_UPLOAD_INFO,
  },
];

export const CellPropertyDocumentTaskDataOptions = [
  {
    name: "是正指示のタイトル",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TITLE_INSTRUCTION,
  },
  {
    name: "是正報告のタイトル",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TITLE_REPORT,
  },
  {
    name: "プロジェクト名",
    value: LinkedDataField.DOCUMENT_TASK_DATA.PROJECT_NAME,
  },
  {
    name: "協力会社",
    value: LinkedDataField.DOCUMENT_TASK_DATA.CORPORATE_COMPANY,
  },
  {
    name: "検査日",
    value:
      LinkedDataField.DOCUMENT_TASK_DATA.DOCUMENT_TASK_INFO_CREATION_DATE_TIME,
  },
  {
    name: "検査者",
    value: LinkedDataField.DOCUMENT_TASK_DATA.DOCUMENT_TASK_INFO_INSPECTOR,
  },
  {
    name: "受検者",
    value: LinkedDataField.DOCUMENT_TASK_DATA.DOCUMENT_TASK_INFO_EXAMINEE,
  },
];

export const CellPropertyTasksImageDataOptions = [
  {
    name: "ID",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_INDEX_ID,
  },
  {
    name: "ステータス",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_STATUS,
  },
  {
    name: "Priority",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_PRIORITY,
  },
  {
    name: "タイトル",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_TITLE,
  },
  {
    name: "Image",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_IMAGE,
  },
  {
    name: "Forge image",
    value: LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_FORGE_IMAGE,
  },
];

export enum CellPropertyDocumentDataForTableOptionType {
  SELECT,
  INPUT,
  DATE,
  TEXT,
  DYNAMIC,
}

export const CellPropertyDocumentDataForTableOptions = [
  {
    name: "書類担当者",
    value: LinkedDataField.DOCUMENT_CATEGORY.USER_ASSIGNED,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "書類名",
    value: LinkedDataField.DOCUMENT_CATEGORY.TEMPLATE_NAME,
    type: CellPropertyDocumentDataForTableOptionType.TEXT,
  },
  {
    name: "担当者",
    value: LinkedDataField.DOCUMENT_CATEGORY.MANAGER,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "協力会社",
    value: LinkedDataField.DOCUMENT_CATEGORY.PARTNER_COMPANY,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "承認社名",
    value: LinkedDataField.DOCUMENT_CATEGORY.CORPORATE_COMPANY,
    type: CellPropertyDocumentDataForTableOptionType.INPUT,
  },
  {
    name: "フロア",
    value: LinkedDataField.DOCUMENT_CATEGORY.LEVEL,
    type: CellPropertyDocumentDataForTableOptionType.TEXT,
  },
  {
    name: "部屋",
    value: LinkedDataField.DOCUMENT_CATEGORY.AREA_ID,
    type: CellPropertyDocumentDataForTableOptionType.TEXT,
  },
  {
    name: "工事名称",
    value: LinkedDataField.DOCUMENT_CATEGORY.CONSTRUCTION_NAME,
    type: CellPropertyDocumentDataForTableOptionType.INPUT,
  },
  {
    name: "書類承認日",
    value: LinkedDataField.DOCUMENT_CATEGORY.APPROVE_DATE,
    type: CellPropertyDocumentDataForTableOptionType.DATE,
  },
  {
    name: "実施日",
    value: LinkedDataField.DOCUMENT_CATEGORY.IMPLEMENT_DATE,
    type: CellPropertyDocumentDataForTableOptionType.DATE,
  },
  {
    name: "現場管理人",
    value: LinkedDataField.DOCUMENT_CATEGORY.USER_MANAGE,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "系統名",
    value: LinkedDataField.DOCUMENT_CATEGORY.SYSTEM_NAME,
    type: CellPropertyDocumentDataForTableOptionType.INPUT,
  },
  {
    name: "期日",
    value: LinkedDataField.DOCUMENT_CATEGORY.DEADLINE,
    type: CellPropertyDocumentDataForTableOptionType.DATE,
  },
  {
    name: "測定年月日",
    value: LinkedDataField.DOCUMENT_CATEGORY.MEASURE_DATE,
    type: CellPropertyDocumentDataForTableOptionType.DATE,
  },
  {
    name: "測定者",
    value: LinkedDataField.DOCUMENT_CATEGORY.USER_MEASURE,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "製造者名",
    value: LinkedDataField.DOCUMENT_CATEGORY.MANUFACTOR,
    type: CellPropertyDocumentDataForTableOptionType.INPUT,
  },
  {
    name: "タグ",
    value: LinkedDataField.DOCUMENT_CATEGORY.TAGS,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "プロジェクト名",
    value: LinkedDataField.DOCUMENT_CATEGORY.PROJECT_NAME,
    type: CellPropertyDocumentDataForTableOptionType.TEXT,
  },
  {
    name: "検査日時",
    value: LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_DATE,
    type: CellPropertyDocumentDataForTableOptionType.DATE,
  },
  {
    name: "ダクト系統並用途",
    value: LinkedDataField.DOCUMENT_CATEGORY.DUCT_SYSTEM,
    type: CellPropertyDocumentDataForTableOptionType.INPUT,
  },
  {
    name: "引抜検査結果良否",
    value: LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_RESULT,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "実施者氏名",
    value: LinkedDataField.DOCUMENT_CATEGORY.USER_IMPLEMENT,
    type: CellPropertyDocumentDataForTableOptionType.SELECT,
  },
  {
    name: "測定器",
    value: LinkedDataField.DOCUMENT_CATEGORY.MEASURING_DEVICE,
    type: CellPropertyDocumentDataForTableOptionType.INPUT,
  },
  {
    name: "カテゴリ用のカスタム入力項目",
    value: CellProperty.DYNAMIC_FIELDS_FOR_CATEGORY,
    linkedDataType: CellProperty.DYNAMIC_FIELDS_FOR_CATEGORY,
    type: CellPropertyDocumentDataForTableOptionType.DYNAMIC,
  },
];

export const fontFamilyDocument = [
  {
    value: FontFamiLyType.HIRAGINO_KAKUGO_W3,
    name: "ヒラギノ角ゴ 　W3",
  },
  {
    value: FontFamiLyType.NOTO_SANS,
    name: "Noto Sans Japanese",
  },
  {
    value: FontFamiLyType.NOTO_SERIF,
    name: "Noto Serif Japanese",
  },
];

export const CheckboxNumberOptions = [
  {
    value: "1",
    name: "1個",
  },
  {
    value: "2",
    name: "2個",
  },
];

export const CheckboxDisplayOptions = [
  { value: CheckboxDisplayOptionsEnum.Checkbox, name: "チェックボックス" },
  { value: CheckboxDisplayOptionsEnum.Options, name: "丸囲い" },
  { value: CheckboxDisplayOptionsEnum.Value, name: "値表示" },
];

export const CheckboxDirectionOptions = [
  { value: CheckboxDirectionOptionsEnum.HORIZONTAL, name: "垂直" },
  { value: CheckboxDirectionOptionsEnum.VERTICAL, name: "水平" },
];

export const CheckboxValueOptions = [
  {
    value: "",
    name: "チェックボックスのみ",
    length: 1,
  },
  {
    value: DocumentCheckboxStatusOptionsType.GoodBad,
    name: DocumentCheckboxStatusOptionsType.GoodBad,
    length: 2,
  },
  {
    value: DocumentCheckboxStatusOptionsType.YesNo,
    name: DocumentCheckboxStatusOptionsType.YesNo,
    length: 2,
  },
  {
    value: DocumentCheckboxStatusOptionsType.OX,
    name: DocumentCheckboxStatusOptionsType.OX,
    length: 2,
  },
  {
    value: DocumentCheckboxStatusOptionsType.Agree,
    name: DocumentCheckboxStatusOptionsType.Agree,
    length: 2,
  },
  {
    value: DocumentCheckboxStatusOptionsType.Qualified,
    name: DocumentCheckboxStatusOptionsType.Qualified,
    length: 2,
  },
  {
    value: DocumentCheckboxStatusOptionsType.GoodDefective,
    name: DocumentCheckboxStatusOptionsType.GoodDefective,
    length: 2,
  },
];

export const HISTORY_VERSIONS_LIMIT = 100;

export const unitOptions = [
  {
    value: "1",
    name: "Ｌ/min",
  },
  {
    value: "2",
    name: "℃",
  },
  {
    value: "3",
    name: "ＭPa",
  },
  {
    value: "4",
    name: "kW",
  },
];

export const DEFAULT_TITLE_DYNAMIC_FIELDS_FOR_ITEM =
  "サイドバーから取得するデータ";

export const CellPropertyForTableHeaderForTaskSheetTemplateOptions: CellPropertyOption[] =
  [
    {
      name: "テキスト入力欄",
      value: CellProperty.TEXT,
      property: CellProperty.TEXT,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "指摘ピン",
      value: CellProperty.TASK_ITEM_DATA,
      property: CellProperty.TASK_ITEM_DATA,
      iconSrc: "/img/icon-dropdown.svg",
    },
  ];

export const CellPropertyTaskItemDataOptions: CellPropertyOption[] = [
  {
    name: NoDataIndex,
    value: LinkedDataField.COMMON.NO,
    property: CellProperty.NO,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.COMMON.NO,
    linkedDataType: CellProperty.NO,
  },
  {
    name: "ID",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_ID,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_ID,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "タイトル",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_TITLE,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_TITLE,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "指摘内容",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_MEMO,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_MEMO,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "高砂担当者",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_MANAGER,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_MANAGER,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "完了期限",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_DEADLINE,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_DEADLINE,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "協力会社",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_PARTNER_COMPANY,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_PARTNER_COMPANY,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "是正内容",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_CONFIRMED_MEMO,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_CONFIRMED_MEMO,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "是正完了日",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_FIXED_DATE,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_FIXED_DATE,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "高砂確認日",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_CONFIRMED_DATE,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_CONFIRMED_DATE,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "高砂確認者",
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_USER_CONFIRMED,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_USER_CONFIRMED,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "対応者", // 不具合から処置済みへ変更したユーザー
    value: LinkedDataField.DOCUMENT_TASK_DATA.TASK_USER_TREATED,
    property: CellProperty.TASK_ITEM_DATA,
    dataType: CellProperty.TEXT,
    linkedDataField: LinkedDataField.DOCUMENT_TASK_DATA.TASK_USER_TREATED,
    linkedDataType: CellProperty.TEXT,
  },
];

export const CellPropertyForTableHeaderOptions: CellPropertyOption[] = [
  {
    name: NoDataIndex,
    value: NoDataIndex,
    property: CellProperty.NO,
    linkedDataType: CellProperty.NO,
  },
  {
    name: "テキスト入力欄",
    value: CellProperty.TEXT,
    property: CellProperty.TEXT,
    linkedDataType: CellProperty.TEXT,
  },
  {
    name: "書類のデータ",
    value: CellProperty.DOCUMENT_DATA,
    property: CellProperty.DOCUMENT_DATA,
    iconSrc: "/img/icon-dropdown.svg",
  },
  {
    name: DEFAULT_TITLE_DYNAMIC_FIELDS_FOR_ITEM,
    value: CellProperty.DYNAMIC_FIELDS_FOR_ITEM,
    property: CellProperty.DYNAMIC_FIELDS_FOR_ITEM,
    linkedDataType: CellProperty.DYNAMIC_FIELDS_FOR_ITEM,
  },
  {
    name: "インスタンスから取得するデータ",
    value: CellProperty.FAMILY_INSTANCE,
    property: CellProperty.FAMILY_INSTANCE,
    linkedDataType: CellProperty.FAMILY_INSTANCE,
  },
];

export const CellPropertyDocumentDataForTableHeaderOptions: {
  [key: string]: CellPropertyOption[];
} = {
  [DocumentTemplateType.PHOTO_LEDGER]: [
    {
      name: "タイトル",
      value: LinkedDataField.DOCUMENT_ITEM.TITLE,
      property: CellProperty.DOCUMENT_DATA,
      dataType: CellProperty.TEXT,
      linkedDataType: CellProperty.INPUT_DATA,
      linkedDataField: LinkedDataField.DOCUMENT_ITEM.TITLE,
      defaultValue: DEFAULT_TITLE_SLEEVE_PIPE_DOCUMENT_ITEM,
    },
    {
      name: "写真",
      value: LinkedDataField.COMMON.IMAGE,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataType: LinkedDataField.COMMON.IMAGE,
      dataType: CellProperty.TEXT,
    },
    {
      name: "コメント",
      nameOption: "黒板と紐づけるコメント",
      value: LinkedDataField.COMMON.COMMENT,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataType: CellProperty.COMMENT,
      dataType: CellProperty.TEXT,
    },
  ],
  [DocumentTemplateType.COMMISSIONING_TABLE]: [
    {
      name: "階",
      value: LinkedDataField.DOCUMENT_ITEM.LEVEL,
      property: CellProperty.DOCUMENT_DATA,
      iconSrc: "/img/icon-maps-floor.svg",
      dataType: CellProperty.TEXT,
      linkedDataType: CellProperty.INPUT_DATA,
      linkedDataField: LinkedDataField.DOCUMENT_ITEM.LEVEL,
      placeholder: " ",
    },
    {
      name: "ファミリ",
      value: LinkedDataField.DOCUMENT_ITEM.TITLE,
      property: CellProperty.DOCUMENT_DATA,
      dataType: CellProperty.TEXT,
      linkedDataType: CellProperty.INPUT_DATA,
      linkedDataField: LinkedDataField.DOCUMENT_ITEM.TITLE,
    },
    // replace by dynamic field typ checkbox
    // {
    // name: "チェックボックス",
    // value: CellProperty.CHECK_BOX,
    // property: CellProperty.DOCUMENT_DATA,
    // linkedDataType: CellProperty.CHECK_BOX,
    // dataType: CellProperty.TEXT,
    // },
    {
      name: "備考",
      value: LinkedDataField.COMMON.REMARKS,
      property: CellProperty.DOCUMENT_DATA,
      dataType: CellProperty.TEXT,
      linkedDataType: CellProperty.INPUT_DATA,
      linkedDataField: LinkedDataField.COMMON.REMARKS,
    },
    {
      name: "試験日",
      value: LinkedDataField.DOCUMENT_ITEM.LAST_CHANGE_STATUS_DATETIME,
      property: CellProperty.DOCUMENT_DATA,
      dataType: CellProperty.TEXT,
      linkedDataField:
        LinkedDataField.DOCUMENT_ITEM.LAST_CHANGE_STATUS_DATETIME,
      linkedDataType: CellProperty.CALENDAR,
    },
  ],
  [DocumentTemplateType.SELF_INSPECTION]: [
    {
      name: "項目",
      value: LinkedDataField.DOCUMENT_ITEM.TITLE,
      property: CellProperty.DOCUMENT_DATA,
      dataType: CellProperty.TEXT,
      linkedDataField: LinkedDataField.DOCUMENT_ITEM.TITLE,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "参考資料",
      value: LinkedDataField.COMMON.REFERENCE_MATERIAL,
      property: CellProperty.TEXT,
      linkedDataType: CellProperty.TEXT,
      linkedDataField: LinkedDataField.COMMON.REFERENCE_MATERIAL,
    },
    {
      name: "合否判定基準",
      value: LinkedDataField.COMMON.PASS_CRITERIA,
      property: CellProperty.TEXT,
      linkedDataField: LinkedDataField.COMMON.PASS_CRITERIA,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "チェック",
      value: CellProperty.CHECK_BOX,
      property: CellProperty.CHECK_BOX,
      linkedDataType: CellProperty.CHECK_BOX,
    },
    {
      name: "処置内容",
      value: LinkedDataField.COMMON.ACTION_TAKEN,
      property: CellProperty.TEXT,
      linkedDataType: CellProperty.TEXT,
      linkedDataField: LinkedDataField.COMMON.ACTION_TAKEN,
    },
  ],
  [DocumentTemplateType.EQUIPMENT_DATA_SHEET]: [
    {
      name: "測定項目",
      value: LinkedDataField.COMMON.MEASUREMENT_ITEM,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataField: LinkedDataField.COMMON.MEASUREMENT_ITEM,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "測定値",
      value: LinkedDataField.COMMON.MEASURED_VALUE,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataField: LinkedDataField.COMMON.MEASURED_VALUE,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "設計値",
      value: LinkedDataField.COMMON.DESIGN_VALUE,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataField: LinkedDataField.COMMON.DESIGN_VALUE,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "判　　定",
      value: LinkedDataField.COMMON.JUDGEMENT,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataField: LinkedDataField.COMMON.JUDGEMENT,
      linkedDataType: CellProperty.CHECK_BOX,
    },
    {
      name: "備　　考",
      value: LinkedDataField.COMMON.REMARKS,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataField: LinkedDataField.COMMON.REMARKS,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "確　認　事　項",
      value: LinkedDataField.COMMON.CHECKLIST,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataField: LinkedDataField.COMMON.CHECKLIST,
      linkedDataType: CellProperty.TEXT,
    },
    {
      name: "確　　　認",
      value: LinkedDataField.COMMON.CONFIRMATION,
      property: CellProperty.DOCUMENT_DATA,
      linkedDataField: LinkedDataField.COMMON.CONFIRMATION,
      linkedDataType: CellProperty.CHECK_BOX,
    },
  ],
};

export const DEFAULT_FAMILY_DISPLAY_OPTIONS = [
  {
    name: "固定タイトル",
    value: CellDisplayValueType.CUSTOM_VALUE,
  },
  {
    name: "ファミリ名",
    value: CellDisplayValueType.FAMILY_NAME,
  },
  {
    name: "機器番号",
    value: CellDisplayValueType.DEVICE_NUMBER,
  },
];

export const DEFAULT_FORMAT_DATE_OPTIONS = [
  {
    name: "YYYY/MM/DD",
    value: "YYYY/MM/DD",
  },
  {
    name: "YYYY-MM-DD",
    value: "YYYY-MM-DD",
  },
  {
    name: "YYYY/MM/DD HH:mm:ss",
    value: "YYYY/MM/DD HH:mm:ss",
  },
  {
    name: "YYYY年MM月DD日",
    value: "YYYY年MM月DD日",
  },
  {
    name: "YYYY年MM月",
    value: "YYYY年MM月",
  },
];

export enum TypeOfEquipment {
  MIRU_MIRU_KUN = "0",
}

export enum TypeOfEquipmentField {
  START_TIME = "0",
  END_TIME = "1",
}

export const TypeOfEquipmentOptions = [
  {
    name: "みるみるくん",
    value: TypeOfEquipment.MIRU_MIRU_KUN,
  },
];

export const TypeOfEquipmentValueOptions = [
  {
    name: "開始時間と始値",
    value: TypeOfEquipmentField.START_TIME,
  },
  {
    name: "終了時間と終値",
    value: TypeOfEquipmentField.END_TIME,
  },
];

import { Text } from "@chakra-ui/react";
import { LINE_HEIGHT_DEFAULT } from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { EditData } from "interfaces/models";
import { memo, useEffect, useRef, useState } from "react";
import {
  moveCaretToEnd,
  transformSizeForTextElement,
} from "utils/download-pdf";

interface iProps {
  value: string;
  editMode: boolean;
  editDataStyle: EditData["style"];
  onEditSubmit: (data: EditData) => void;
}

const ContentOfField = ({
  value,
  editMode,
  editDataStyle,
  onEditSubmit,
}: iProps) => {
  const [isPrevEditMode, setIsPrevEditMode] = useState(false);

  const lastTextRef = useRef<string>();
  const textElementRef = useRef<HTMLParagraphElement>(null);
  const initTextValueRef = useRef<string | null>(null);
  const editDataStyleRef = useRef({
    fontSize: 14,
    lineHeight: 14 * LINE_HEIGHT_DEFAULT,
  });
  const sizeDefaultRef = useRef({
    fontSize: 14,
    lineHeight: 14 * LINE_HEIGHT_DEFAULT,
  });
  const isCutOffTextRef = useRef(false);

  useEffect(() => {
    if (editMode) {
      setIsPrevEditMode(true);
    }
  }, [editMode]);

  useEffect(() => {
    const element = textElementRef.current;
    const parentElement = element?.parentElement;
    if (
      !element ||
      initTextValueRef.current === null ||
      !isPrevEditMode ||
      editMode ||
      !parentElement
    ) {
      return;
    }

    // reset text
    element.style.color = isCutOffTextRef.current ? "red" : "inherit";
    element.innerHTML = initTextValueRef.current.replaceAll("\n", "<br/>");

    parentElement.style.fontSize = `${
      editDataStyle?.fontSize || editDataStyleRef.current.fontSize
    }px`;
    parentElement.style.lineHeight = `${
      editDataStyle?.lineHeight || editDataStyleRef.current.lineHeight
    }px`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, editDataStyle, isPrevEditMode]);

  useEffect(() => {
    const element = textElementRef.current;
    const parentElement = element?.parentElement;

    if (!element || !parentElement) {
      return;
    }

    element.innerHTML = value.replaceAll("\n", "<br/>");
    initTextValueRef.current = value || "";

    if (!value) {
      return;
    }

    // transform size
    const { text, shouldUpdate } = transformSizeForTextElement({
      isShowMessage: false,
      element,
      lastTextRef,
      sizeDefault: sizeDefaultRef.current,
      isTransformForParent: true,
    });

    const fontSizeNumber = Number(
      getComputedStyle(parentElement).fontSize.replace("px", "")
    );
    editDataStyleRef.current = {
      fontSize: fontSizeNumber,
      lineHeight: fontSizeNumber * LINE_HEIGHT_DEFAULT,
    };

    if (shouldUpdate) {
      element.style.color = "red";
      element.innerHTML = text;
      isCutOffTextRef.current = true;
      initTextValueRef.current = text;
    } else {
      isCutOffTextRef.current = false;
    }
  }, [value]);

  const handleInput = () => {
    const element = textElementRef.current;
    if (!element) {
      return;
    }

    const { text, shouldUpdate } = transformSizeForTextElement({
      isTransformForParent: true,
      element,
      lastTextRef: lastTextRef,
      sizeDefault: sizeDefaultRef.current,
    });

    if (shouldUpdate) {
      lastTextRef.current = text;
      element.innerHTML = text;
      moveCaretToEnd(element);
    }

    handleSubmit();
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLParagraphElement>) => {
    event.preventDefault();
    const element = textElementRef.current;
    if (!element) {
      return;
    }

    const pasteValue = event.clipboardData.getData("text");
    const selection = window.getSelection();
    if (!selection?.rangeCount) {
      return;
    }

    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(pasteValue));
    selection.collapseToEnd();

    const { text, shouldUpdate } = transformSizeForTextElement({
      isTransformForParent: true,
      element,
      lastTextRef: lastTextRef,
      sizeDefault: sizeDefaultRef.current,
    });

    if (shouldUpdate) {
      element.innerHTML = text;
    }

    handleSubmit();
  };

  const handleSubmit = () => {
    const element = textElementRef.current;
    if (!element) {
      return;
    }

    const text = element.innerHTML;
    onEditSubmit({
      editValue: text,
    });
  };

  return (
    <Text
      ref={textElementRef}
      contentEditable={editMode}
      suppressContentEditableWarning={editMode}
      minHeight="1em"
      wordBreak="break-all"
      onInput={handleInput}
      onPaste={handlePaste}
    />
  );
};

export default memo(ContentOfField);

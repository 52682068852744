import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconOnline(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#171717"
        d="M17.5 19.75h-11c-2.9 0-5.25-2.36-5.25-5.25 0-2.54 1.77-4.66 4.2-5.15.74-2.99 3.41-5.1 6.55-5.1s5.81 2.12 6.55 5.1c2.43.49 4.2 2.61 4.2 5.15 0 2.89-2.36 5.25-5.25 5.25m-5.5-14c-2.58 0-4.76 1.85-5.18 4.39-.06.34-.33.59-.67.62-1.94.18-3.4 1.78-3.4 3.73 0 2.07 1.68 3.75 3.75 3.75h11c2.07 0 3.75-1.68 3.75-3.75 0-1.95-1.46-3.55-3.4-3.73a.744.744 0 0 1-.67-.62A5.234 5.234 0 0 0 12 5.75"
      />
      <path
        fill="#171717"
        d="M11.029 15.969c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l1.47 1.47 3.41-3.41c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-3.94 3.94c-.15.15-.34.22-.53.22"
      />
    </Icon>
  );
}

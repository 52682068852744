import { toast } from "App";
import CustomComponent from "./CustomComponent";
import { MessageMethods, NoticeType, MessageMethodType } from "./interface";

const methods: (keyof MessageMethods)[] = [
  "success",
  "info",
  "warning",
  "error",
  "loading",
];

const staticMethods = {} as MessageMethods;

const DURATION_TYPE = {
  // millisecond
  success: 2000,
  info: 2000,
  warning: 10000,
  error: 5000,
  loading: 2000,
};

const showMessage = (type: NoticeType, args: Parameters<MessageMethodType>) => {
  const [content, options, onCloseCallback] = args;

  toast({
    status: type,
    position: options?.placement ?? "top",
    duration: options?.duration ?? DURATION_TYPE[type],
    render: ({ onClose }) => (
      <CustomComponent
        status={type}
        onClose={onClose}
        onCloseCallback={onCloseCallback}
        content={content}
      />
    ),
  });
};

methods.forEach((type: keyof MessageMethods) => {
  staticMethods[type] = (...args: Parameters<MessageMethodType>) =>
    showMessage(type, args);
});

export default staticMethods;

import { KEYNOTE_IMAGE_CLASSNAME } from "constants/styleProps";
import { KeynoteImageData } from "interfaces/models/documentKeynote";
import { getLabelsOfKeynote, transformKeyLabelData } from "models/documentTask";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface iProps {
  keynoteImageData?: KeynoteImageData;
  isChangeSheet: boolean;
  keynoteImageClass?: string;
}

const useKeynoteImage = ({
  keynoteImageData,
  isChangeSheet,
  keynoteImageClass = KEYNOTE_IMAGE_CLASSNAME,
}: iProps) => {
  const [isLoadKeynoteImage, setIsLoadKeynoteImage] = useState(true);
  const [keynoteImage, setKeynoteImage] = useState("");
  const [keynoteImageWidth, setKeynoteImageWidth] = useState(0);
  const [keynoteImageHeight, setKeynoteImageHeight] = useState(0);

  const keynoteContainerRef = useRef<HTMLDivElement>(null);

  const onLoadKeynoteImage = () => {
    const keynoteImageElement = document.getElementById(keynoteImageClass);

    if (keynoteImageElement) {
      setKeynoteImageWidth(keynoteImageElement.clientWidth);
      setKeynoteImageHeight(keynoteImageElement.clientHeight);
    }

    setIsLoadKeynoteImage(false);
  };

  const keyLabelsProps = useMemo(() => {
    if (!isLoadKeynoteImage && !keynoteImage) {
      return [];
    }

    if (
      !keynoteImageData?.guid ||
      isLoadKeynoteImage ||
      !keynoteImageWidth ||
      !keynoteImageHeight
    ) {
      return null;
    }

    const labels = getLabelsOfKeynote(keynoteImageData);
    const labelByGuid = labels?.labelByGuid;

    if (!labelByGuid) {
      return [];
    }

    const currentImageWidth = labelByGuid?.imageWidth;
    const currentImageHeight = labelByGuid?.imageHeight;
    const keyLabelsData = labelByGuid?.keyLabels || [];
    const keylabelsEdited = labelByGuid?.keyLabelsEdited || [];

    const scaleX = keynoteImageWidth / currentImageWidth;
    const scaleY = keynoteImageHeight / currentImageHeight;

    return keyLabelsData?.map((keylabel) => {
      const keylabelEdited =
        keylabelsEdited.find((item) => item.id === keylabel.id) || {};

      return transformKeyLabelData({
        keylabel: { ...keylabel, ...keylabelEdited },
        scaleX,
        scaleY,
      });
    });
  }, [
    keynoteImageHeight,
    keynoteImageWidth,
    keynoteImage,
    isLoadKeynoteImage,
    keynoteImageData,
  ]);

  const keynoteImageSize = useMemo(
    () => ({
      width: keynoteImageWidth,
      height: keynoteImageHeight,
    }),
    [keynoteImageWidth, keynoteImageHeight]
  );

  useEffect(() => {
    if (!isChangeSheet) {
      return;
    }

    setKeynoteImageWidth(0);
    setKeynoteImageHeight(0);
    setIsLoadKeynoteImage(true);
    setKeynoteImage("");
  }, [isChangeSheet]);

  useEffect(() => {
    if (!keynoteImageData?.guid || isChangeSheet) {
      return;
    }

    const labels = getLabelsOfKeynote(keynoteImageData);
    const labelByGuid = labels?.labelByGuid;
    const image = labelByGuid?.imageUrl || "";

    setKeynoteImage(image);
    if (!image) {
      setIsLoadKeynoteImage(false);
    }
  }, [keynoteImageData, isChangeSheet]);

  return {
    keynoteImageSize,
    keyLabelsProps,
    isLoadKeynoteImage,
    keynoteImageWidth,
    keynoteImageHeight,
    keynoteContainerRef,
    keynoteImage,
    onLoadKeynoteImage,
  };
};

export default useKeynoteImage;

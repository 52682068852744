import { Box, Flex, FlexProps, StackProps, Text } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { TextPosition } from "constants/enum";

interface CustomCellProps extends FlexProps {
  color?: string;
  fontSize?: string;
  items: { icon: string; title: string }[];
  justifyContent?: string;
  zoomRatio?: number;
  isBlackboardTemplateImage?: boolean;
}

const CustomCell = ({
  items,
  justifyContent,
  zoomRatio,
  isBlackboardTemplateImage = false,
  fontSize,
  color,
  ...rest
}: CustomCellProps & StackProps) => {
  return (
    <Flex
      height="100%"
      direction="column"
      justifyContent="center"
      gap="0.1rem"
      width="100%"
      overflow="hidden"
      p={justifyContent !== TextPosition.JUSTIFY ? "5px" : "5px 0"}
      {...rest}
    >
      {items?.map((item, index) => (
        <Flex
          key={index}
          alignItems="center"
          justifyContent={justifyContent || "center"}
          width="100%"
          //overflow="hidden"
          gap="0.5rem"
        >
          {item?.icon && (
            <Box width={`${16 * Number(zoomRatio)}px`}>
              <SvgIcon
                width={`${16 * Number(zoomRatio)}px`}
                height={`${16 * Number(zoomRatio)}px`}
                src={item.icon}
                sx={{
                  rect: {
                    stroke: color || "#A3A3A3",
                    strokeWidth: 1,
                  },
                  path: {
                    fill: color || "#A3A3A3",
                  },
                }}
              />
            </Box>
          )}
          {item?.title && (
            <Text
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: color || "#A3A3A3",
                whiteSpace: "nowrap",
                textAlign: justifyContent === "center" ? "center" : "left",
                fontSize: fontSize || `${1.4 * Number(zoomRatio)}rem`,
              }}
            >
              {item?.title}
            </Text>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default CustomCell;

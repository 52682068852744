import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconYPlane(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m19.837 18.766-9.05-4.915V3.998m9.14 14.573c.05-.22.07-.45.05-.67V9.8l.02-.01q.015-.33-.06-.66c-.05-.13-.12-.26-.22-.36-.17-.15-.36-.28-.57-.37l-7.56-4.11c-.18-.12-.38-.21-.59-.27m-4.399 9.03L5.5 17.15l4.098 1.098m5.429-6.6-8.66 5"
      />
    </Icon>
  );
}

import {
  Button,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseModalProps,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";

interface Props extends UseModalProps {
  href?: string;
}

function ContentIndicationModal({ isOpen, onClose, href }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        my="auto"
        w="95%"
        h="95%"
        maxH="85.3rem"
        maxW="132.9rem"
        p="2rem"
      >
        <ModalCloseButton fontSize="12px" _focusVisible={{}} />

        <Flex mx="auto" h="100%" w="80%" justifyContent="center">
          <Image src="/img/pdf-content-indication.png" />
        </Flex>
        <Flex
          justifyContent="right"
          mt="auto"
          position="absolute"
          right="2rem"
          bottom="2rem"
        >
          <Button mt="2rem" onClick={onClose}>
            閲覧終了
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default ContentIndicationModal;

import {
  Box,
  BoxProps,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { SvgIcon } from "components/SvgIcon";
import { useEffect, useRef, useState } from "react";
import { hexToRgbA } from "utils/common";

export const DEFAULT_COLOR = "rgba(242, 71, 38, 1)";

interface Props {
  onSelect: (color: string) => void;
  children: JSX.Element;
  defaultColor?: string;
}

interface ColorProps {
  color: string;
  isSelected?: boolean;
}

function ColorPicker({
  onSelect,
  children,
  defaultColor = DEFAULT_COLOR,
}: Props) {
  const [colors, setColors] = useState<ColorProps[]>([
    { color: "transparent" },
    { color: "rgba(255, 255, 255, 1)" },
    { color: "rgba(254, 244, 69, 1)" },
    { color: "rgba(250, 199, 16, 1)" },
    { color: DEFAULT_COLOR },
    { color: "rgba(230, 230, 230, 1)" },
    { color: "rgba(206, 231, 65, 1)" },
    { color: "rgba(143, 209, 79, 1)" },
    { color: "rgba(218, 0, 99, 1)" },
    { color: "rgba(128, 128, 128, 1)" },
    { color: "rgba(18, 205, 212, 1)" },
    { color: "rgba(12, 167, 137, 1)" },
    { color: "rgba(149, 16, 172, 1)" },
    { color: "rgba(26, 26, 26, 1)" },
    { color: "rgba(45, 155, 240, 1)" },
    { color: "rgba(65, 75, 178, 1)" },
    { color: "rgba(101, 44, 179, 1)" },
  ]);
  useEffect(() => {
    setColors((prevColors) => {
      for (const c of prevColors) {
        if (c.color == defaultColor) {
          c.isSelected = true;
        } else c.isSelected = false;
      }

      return [...prevColors];
    });
  }, [defaultColor]);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [opacity, setOpacity] = useState(100);
  const currColor = useRef(DEFAULT_COLOR);

  const _onSelect = (color: string, op?: number) => {
    currColor.current = color;
    const opacityColor = color.replace("1)", `${(op ?? opacity) / 100})`);
    onSelect(opacityColor);
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          px={0}
          bg="transparent"
          _hover={{}}
          variant="ghost"
          _active={{}}
        >
          {children}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="auto" p="1rem">
        <PopoverArrow />
        <PopoverBody>
          <Slider
            mb="3rem"
            aria-label="slider-opacity"
            colorScheme="gray"
            defaultValue={100}
            onChange={(value) => {
              setOpacity(value);
              _onSelect(currColor.current, value);
            }}
            step={5}
          >
            <SliderMark value={0} mt="1rem">
              Opacity
            </SliderMark>
            <SliderMark value={100} mt="1rem" right={0} left="unset !important">
              {`${opacity}%`}
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Flex w="16.5rem" gap="1.5rem" flexWrap="wrap">
            {colors.map((c, ci) => (
              <ColorItem
                key={ci}
                {...c}
                onClick={() => {
                  _onSelect(c.color);
                  setColors((s) => {
                    s.forEach((cs) => {
                      cs.isSelected = false;
                    });
                    c.isSelected = true;

                    return [...s];
                  });
                }}
              />
            ))}
            <SelectColor
              onSelect={(color) => {
                if (isOpen) {
                  onClose();
                  colors.forEach((cs) => {
                    cs.isSelected = false;
                  });
                  colors.push({ color: color, isSelected: true });
                  setColors([...colors]);
                } else {
                  const currentItem = colors[colors.length - 1];
                  currentItem.color = color;
                  currentItem.isSelected = true;
                  setColors([...colors]);
                }
                _onSelect(color);
              }}
            />
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default ColorPicker;

const SelectColor = ({ onSelect }: { onSelect: (color: string) => void }) => {
  const colorRef = useRef<HTMLInputElement>(null);
  const [color, setColor] = useState("#fff");
  const [isActive, setActive] = useState(false);

  useOutsideClick({
    ref: colorRef,
    handler: () => setActive(false),
  });

  return (
    <>
      {isActive ? (
        <ColorItem color={color} />
      ) : (
        <Flex
          w="3rem"
          h="3rem"
          alignItems="center"
          justifyContent="center"
          className="button"
          onClick={(ev) => {
            colorRef.current?.click();
            setActive(true);
          }}
        >
          <Box
            css={css`
              --b: 3px;
              width: 2rem !important;
              height: 2rem !important;
              aspect-ratio: 1;
              background: conic-gradient(
                  from 90deg at var(--b) var(--b),
                  #fff 90deg,
                  gray 0
                )
                calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) /
                calc(50% + var(--b)) calc(50% + var(--b));
            `}
          ></Box>
        </Flex>
      )}
      <Box
        as="input"
        ref={colorRef}
        opacity={1}
        type="color"
        position="absolute"
        bottom="0"
        right="0"
        onChange={(ev) => {
          const value = hexToRgbA((ev.target as any).value);
          setColor(value);
          onSelect(value);
          setActive(false);
        }}
      />
    </>
  );
};

const ColorItem = ({ color, isSelected, ...props }: ColorProps & BoxProps) => {
  return color == "transparent" ? (
    <SvgIcon
      w="3rem"
      h="3rem"
      src="/img/transparent_icon.svg"
      pathFill={isSelected ? "rgba(66, 98, 255, 1)" : ""}
      onClick={props.onClick as any}
      className="button"
    />
  ) : (
    <Box
      className="button"
      w="3rem"
      h="3rem"
      border="1px solid rgba(0, 0, 0, 0.1)"
      borderRadius="50%"
      bgColor={color}
      boxShadow={
        isSelected ? "0 0 0 2px #fff, 0 0 0 4px rgba(66, 98, 255, 1)" : "none"
      }
      {...props}
    />
  );
};

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconAttachFile(props: IconProps) {
  return (
    <Icon width="2.4rem" height="2.4rem" viewBox="0 0 24 24" {...props}>
      <path
        fill="none"
        stroke="#767676"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m18.744 11.226-6.642 6.642a3.868 3.868 0 0 1-5.47-5.47l6.642-6.647a2.582 2.582 0 1 1 3.651 3.652l-6.385 6.381a1.29 1.29 0 0 1-1.824-1.823l5.6-5.6"
      />
    </Icon>
  );
}

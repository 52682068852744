import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconSupport(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#737373"
        d="M11.989 17.615a.98.98 0 0 0 .723-.295 1 1 0 0 0 .296-.724.98.98 0 0 0-.296-.724 1 1 0 0 0-.724-.295.98.98 0 0 0-.723.296 1 1 0 0 0-.296.724q0 .428.296.723a1 1 0 0 0 .724.295m.013 3.885a9.3 9.3 0 0 1-3.706-.748 9.6 9.6 0 0 1-3.016-2.03 9.6 9.6 0 0 1-2.032-3.016 9.25 9.25 0 0 1-.748-3.704q0-1.972.748-3.706a9.6 9.6 0 0 1 2.03-3.016 9.6 9.6 0 0 1 3.016-2.032 9.25 9.25 0 0 1 3.704-.748q1.972 0 3.706.748a9.6 9.6 0 0 1 3.017 2.03 9.6 9.6 0 0 1 2.03 3.016 9.25 9.25 0 0 1 .749 3.704q0 1.972-.748 3.706a9.6 9.6 0 0 1-2.03 3.017 9.6 9.6 0 0 1-3.016 2.03 9.25 9.25 0 0 1-3.704.749M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4 6.325 6.325 4 12t2.325 5.675T12 20m.081-12.28q.691 0 1.198.435.506.435.506 1.09 0 .549-.324.984-.323.435-.738.81a6.4 6.4 0 0 0-.959 1.062 2.34 2.34 0 0 0-.443 1.291.57.57 0 0 0 .193.458.68.68 0 0 0 .47.184.68.68 0 0 0 .489-.192.9.9 0 0 0 .256-.47 2.06 2.06 0 0 1 .426-.919 8 8 0 0 1 .706-.766q.547-.534.954-1.162.408-.63.408-1.404 0-1.188-.937-1.953-.936-.764-2.186-.764-.893 0-1.683.395a2.9 2.9 0 0 0-1.23 1.153.7.7 0 0 0-.088.49q.049.257.264.39.274.152.562.087a.85.85 0 0 0 .49-.328q.304-.394.736-.633.432-.24.93-.239"
      />
    </Icon>
  );
}

import { TemplateComponentType } from "constants/enum";
import { TemplateComponent } from "interfaces/models/component";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentTask } from "interfaces/models/documentTask";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface iProps {
  dataProjectDetail?: DataProjectModel;
  documentTask: DocumentTask;
}

const useGetTaskSheetTemplate = ({
  dataProjectDetail,
  documentTask,
}: iProps) => {
  const { taskSheetTemplateList } = useSelector(
    (state: RootState) => state.task
  );

  const taskSheetTemplateId = useMemo(() => {
    const checkIsTemplateExists = (templageId: string | undefined | null) =>
      taskSheetTemplateList?.findIndex((tem) => tem.id === templageId) !== -1;

    if (
      documentTask?.taskSheetTemplateId &&
      checkIsTemplateExists(documentTask?.taskSheetTemplateId)
    ) {
      return documentTask.taskSheetTemplateId;
    }

    if (checkIsTemplateExists(dataProjectDetail?.taskSheetTemplateId)) {
      return dataProjectDetail?.taskSheetTemplateId;
    }

    return null;
  }, [
    taskSheetTemplateList,
    documentTask?.taskSheetTemplateId,
    dataProjectDetail?.taskSheetTemplateId,
  ]);

  const taskSheetTemplate = useMemo(() => {
    if (!documentTask?.id || !taskSheetTemplateId) {
      return null;
    }

    return taskSheetTemplateList?.find((t) => t.id === taskSheetTemplateId);
  }, [taskSheetTemplateList, documentTask?.id, taskSheetTemplateId]);

  const componentsOfTemplate = useMemo(
    (): TemplateComponent[] => taskSheetTemplate?.components || [],
    [taskSheetTemplate?.components]
  );

  const photoBooksType = useMemo(() => {
    return componentsOfTemplate.find((com) =>
      [
        TemplateComponentType.PhotoBooksReport,
        TemplateComponentType.PhotoBooksInstruction,
      ].includes(com.type)
    )?.type;
  }, [componentsOfTemplate]);

  return {
    taskSheetTemplateList,
    componentsOfTemplate,
    taskSheetTemplate,
    taskSheetTemplateId,
    photoBooksType,
  };
};

export default useGetTaskSheetTemplate;

import { listOtherPartnerCompanies } from "constants/blackBoardTemplate";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { Level } from "interfaces/models";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { TemplateComponent } from "interfaces/models/component";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { transformKeynoteIndex } from "models/document";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLevels } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";

interface Props {
  documentItemsHasImage: DocumentSubItemDTO[] | undefined;
  documentCategorySelected: DocumentCategoryDTO | undefined;
  documentItemSelected: DocumentItemDTO | undefined;
  components: TemplateComponent[] | undefined;
  template: DocumentTemplate | undefined;
}

const usePreviewData = ({
  documentCategorySelected,
  documentItemsHasImage,
  components,
  template,
}: Props) => {
  const { documentCategories, dataBlackboards, isGeneratingPdf } = useSelector(
    (state: RootState) => state.document
  );
  const { users } = useSelector((state: RootState) => state.user);
  const { levelSelected, levels } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { dataProjectDetail, partnerCompanies, projectDetail } = useSelector(
    (state: RootState) => state.project
  );

  const dispatch = useDispatch();

  const listOptionUsers: { label: string; value: string }[] = useMemo(() => {
    return users
      ?.map((user) => ({
        label: user?.name || "",
        value: user?.id || "",
      }))
      ?.filter((user) => !!user?.value);
  }, [users]);

  const listOptionFloor: { label: string; value: string }[] = useMemo(() => {
    return levels
      ?.filter((level) => !!level.guid)
      ?.map((item) => {
        return {
          value: String(item?.guid),
          label: String(item?.label),
        };
      });
  }, [levels]);

  const listPartnerCompanies: { label: string; value: string }[] =
    useMemo(() => {
      return [
        ...listOtherPartnerCompanies,
        ...(partnerCompanies
          ?.map((company) => ({
            label: company.name || "",
            value: company.id || "",
          }))
          ?.filter((company) => !!company.value) || []),
      ];
    }, [partnerCompanies]);

  const blackboardTemplateProps = useMemo(
    (): iBlackboardTemplateProps => ({
      levelSelected,
      projectName: projectDetail?.name,
      blackboardTemplate: template?.blackboardTemplateDetail,
      listOptionUsers,
      listOptionFloor,
      listPartnerCompanies,
      documentCategorySelected,
    }),
    [
      projectDetail?.name,
      documentCategorySelected,
      template?.blackboardTemplateDetail,
      listOptionUsers,
      listOptionFloor,
      listPartnerCompanies,
      levelSelected,
    ]
  );

  const isShowSheetMenu = useMemo(() => {
    return components?.some((component) => component.detail?.checkedImage);
  }, [components]);

  const transformDocumentItemsPhotoLedger = useCallback(
    ({
      documentCategory,
      documentItemsHasImage,
    }: {
      documentCategory: DocumentCategoryDTO;
      documentItemsHasImage: DocumentSubItemDTO[];
    }) => {
      const mapExternal = new Map<string, string>();
      (documentCategory?.selectedExternalIds || []).forEach((externalId) => {
        mapExternal.set(externalId, externalId);
      });
      const documentItems: DocumentItemDTO[] = [];

      // Update display order with index value of array documentItems when hidden pin or add new pin
      documentCategory?.documentItems?.forEach((docItem, index) => {
        if (mapExternal.get(docItem.id)) {
          documentItems.push({
            ...docItem,
            displayOrder: index,
          });
        }
      });

      const sortSubItems = documentItems
        .map((item) => item?.subItems || [])
        .flat(1)
        .filter((sub) => !sub?.isHidden);
      const mapDocumentItemsHasImage = Object.assign(
        {},
        ...(documentItemsHasImage || [])?.map((item) => ({
          [item?.id || ""]: item,
        }))
      );

      return sortSubItems.map((documentSubItem) => {
        const keyNoteIndex = transformKeynoteIndex({
          documentItems,
          documentSubItem,
        });

        const blackBoardInfo = (
          mapDocumentItemsHasImage?.[documentSubItem?.id || ""] || {}
        )?.blackBoardInfo;

        return {
          ...documentSubItem,
          ...blackBoardInfo,
          keyNoteIndex,
        } as any;
      });
    },
    []
  );

  const formatDocumentItems: BlackBoardInfo[] = useMemo(() => {
    return transformDocumentItemsPhotoLedger({
      documentCategory: documentCategorySelected || ({} as DocumentCategoryDTO),
      documentItemsHasImage: documentItemsHasImage || [],
    });
  }, [
    documentItemsHasImage,
    documentCategorySelected,
    transformDocumentItemsPhotoLedger,
  ]);

  useEffect(() => {
    if (levels?.length) {
      return;
    }

    const levelData = (dataProjectDetail?.levelData || {}) as {
      [key: string]: Level;
    };

    dispatch(setLevels(Object.values(levelData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectDetail, levels?.length]);

  return {
    isGeneratingPdf,
    projectName: projectDetail?.name,
    isShowSheetMenu,
    documentCategories,
    formatDocumentItems,
    dataBlackboards,
    transformDocumentItemsPhotoLedger,
    blackboardTemplateProps,
  };
};

export default usePreviewData;

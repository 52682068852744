import { ApiResponse } from "interfaces/models/api";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListUuid } from "utils/common";

const path = "/v2/partner-companies";

export const getPartnerList = async (params?: {
  bimFileId?: string;
  shouldCache?: boolean;
}): Promise<ApiResponse<PartnerCompany[]>> => {
  return axiosECS.get(path, {
    params,
  });
};

export const createPartner = async (
  partnerCompany: PartnerCompany
): Promise<ApiResponse<PartnerCompany>> => {
  return axiosECS.post(path, validateBodyReq(partnerCompany));
};

export const updatePartner = async (
  partnerCompany: PartnerCompany
): Promise<ApiResponse<PartnerCompany>> => {
  return axiosECS.patch(path, validateBodyReq(partnerCompany));
};

export const deletePartnerList = async (
  ids: string[]
): Promise<ApiResponse<PartnerCompany>> => {
  return axiosECS.delete(path, {
    data: { ids: validateListUuid(ids) },
  });
};

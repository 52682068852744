import { Box, BoxProps } from "@chakra-ui/react";
import { ContentType } from "constants/enum";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { useEffect, useMemo, useRef, useState } from "react";
import BlackboardTemplateImage from "./BlackboardTemplateImage";
import { PresignedImageWithRef } from "./PresignedImage";

interface Props extends BoxProps {
  src: string;
  blackBoard?: Blackboard;
  positionBlackBoard?: number[];
  fallbackSrc?: string;
  styleImage?: React.CSSProperties;
  blackboardTemplateDetail?: BlackboardTemplate;
  blackboardTemplateProps: iBlackboardTemplateProps;
}

export default function InspectionImage({
  src,
  positionBlackBoard,
  blackBoard,
  fallbackSrc,
  styleImage,
  blackboardTemplateDetail,
  blackboardTemplateProps,
  ...rest
}: Props) {
  const imgRef = useRef<HTMLImageElement>();
  const bbRef = useRef<HTMLDivElement>();
  const bbContainerRef = useRef<HTMLDivElement>(null);
  const [, , width, , height] = positionBlackBoard || [];
  const [isLoaded, setIsLoaded] = useState(false);
  const [bbWidth, setBbWidth] = useState(0);
  const [bbHeight, setBbHeight] = useState(0);
  const [bbX, setBbX] = useState(0);
  const [bbY, setBbY] = useState(0);
  const styleBoxBlackboardTemplateContainer =
    useMemo((): React.CSSProperties => {
      if (!!positionBlackBoard?.length) {
        return {
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 9px",

          position: "absolute",
          top: bbY,
          left: bbX,
          pointerEvents: "none",
          maxWidth: "unset",

          width: bbWidth,
          height: bbHeight,
        };
      }

      return {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 9px",
        position: "absolute",
        bottom: 20,
        left: 20,
        transform: `scale(0.2)`,
        transformOrigin: "left bottom",
        pointerEvents: "none",
        maxWidth: "unset",
        width,
        height,
      };
    }, [
      height,
      width,
      bbWidth,
      bbHeight,
      bbX,
      bbY,
      positionBlackBoard?.length,
    ]);

  const calculateScale = () => {
    if (!imgRef?.current || !bbContainerRef.current) {
      return;
    }

    bbContainerRef.current.style.width = `${imgRef?.current?.width || 0}px`;
    bbContainerRef.current.style.height = `${imgRef?.current?.height || 0}px`;

    const currentImageWidth = imgRef.current.clientWidth;
    const currentImageHeight = imgRef.current.clientHeight;
    const [x, y, width, imgWidth, height, imgHeight] = positionBlackBoard || [];

    const scaleImageWidth = currentImageWidth / imgWidth;
    const scaleImageHeight = currentImageHeight / imgHeight;

    setBbWidth(width * scaleImageWidth);
    setBbHeight(height * scaleImageHeight);
    setBbX(x * scaleImageWidth);
    setBbY(y * scaleImageHeight);
  };

  const onImageLoad = () => {
    setIsLoaded(true);
    calculateScale();
  };

  const onChangeImageRef = (e: any) => {
    if (e) {
      imgRef.current = e;
    }
  };

  const changeBlackBoardRef = (e: any) => {
    if (e) {
      bbRef.current = e;
      calculateScale();
    }
  };

  useEffect(() => {
    calculateScale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionBlackBoard, blackBoard]);

  return (
    <Box
      className="disalbe-br mceNonEditable"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <PresignedImageWithRef
        isConvertToBase64={true}
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
        }}
        crossOrigin="anonymous"
        src={src}
        data-x={bbX || 0}
        fallbackSrc={fallbackSrc}
        _ref={onChangeImageRef}
        onLoad={onImageLoad}
      />

      <Box ref={bbContainerRef} style={{ position: "absolute" }}>
        {blackBoard && isLoaded && blackboardTemplateDetail && (
          <Box
            ref={changeBlackBoardRef}
            style={styleBoxBlackboardTemplateContainer}
          >
            <BlackboardTemplateImage
              blackboardTemplateProps={{
                ...blackboardTemplateProps,
                isOnlyView: true,
              }}
              blackboardData={blackBoard}
              data={blackboardTemplateDetail}
              contentType={ContentType.BLACKBOARD_TEMPLATE}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

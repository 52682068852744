import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Input } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";

interface SearchProps {
  onSearch(value: string): void;
}

const Search: React.FC<SearchProps> = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      onSearch(searchValue?.toLowerCase());
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [onSearch, searchValue]);

  return (
    <Box position={"relative"}>
      <Input
        borderRadius="4px"
        bgColor="#FFFFFF"
        fontSize="1.4rem"
        _placeholder={{ color: "#A3A3A3", fontSize: "1.4rem" }}
        height="4rem"
        value={searchValue}
        pr={"4rem"}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {searchValue && (
        <Box
          position={"absolute"}
          right={"3px"}
          top={0}
          height={"100%"}
          zIndex={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            backgroundColor={"transparent"}
            borderColor={"transparent"}
            onClick={() => setSearchValue("")}
            borderRadius={"50%"}
            aspectRatio={1}
            color="#DC2626"
          >
            <DeleteIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Search;

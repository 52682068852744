import { documentItemApi } from "apiClient/v2";
import { DocumentItemKey } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { insertDataLogToIndexedDb } from "models/dataLog";
import { isPhotoLedgerTemplate } from "models/documentCategory";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setDataLogTemp,
  updateDocumentCategory,
  updateVisibleDocumentItem,
} from "redux/documentSlice";
import store from "redux/store";
import { updateElementInArray } from "utils/array";
import { uuid } from "utils/common";
import {
  handleVisiblePinNotPhotoLedger,
  handleVisiblePinPhotoLedger,
} from "utils/documentItem";
import { logError } from "utils/logs";
import { getLocalStorageUser } from "utils/user";

interface Props {
  documentCategories: DocumentCategoryDTO[];
}

const useVisibleDocumentItem = (props: Props) => {
  const { documentCategories } = props;
  const { socket } = useForgeViewerContext();
  const dispatch = useDispatch();

  const onToggleVisibleDocumentItem = useCallback(
    async (event: any, _documentItem: DocumentItemDTO) => {
      event.preventDefault();
      event.stopPropagation();
      const documentItem = structuredClone(_documentItem);
      const documentItemId = documentItem?.id;

      if (!documentItemId) {
        return;
      }
      const state = store.getState().document;
      const documentCategory = structuredClone(
        state.documentCategories.find(
          (item) => item.id === documentItem.documentCategoryId
        )
      );
      if (!documentCategory) {
        return;
      }
      let isVisible = false;
      const isPhotoLedger = isPhotoLedgerTemplate(
        documentCategory.documentType
      );

      const requestId = uuid();
      try {
        const selectedExternalIds = documentCategory?.selectedExternalIds || [];
        const newSelectedExternalIds =
          selectedExternalIds.filter(
            (externalId) => externalId !== documentItemId
          ) || [];
        if (newSelectedExternalIds?.length === selectedExternalIds?.length) {
          isVisible = true;
          newSelectedExternalIds.push(documentItemId);
        }
        const now = new Date();
        // add log type show/hide pin
        documentItemApi.updateItem({
          id: documentItem.id,
          isHidePin: !isVisible,
          requestId,
          updatedAt: now,
        });

        const currentUserId = getLocalStorageUser()?.id;
        const dataLog = await insertDataLogToIndexedDb({
          contentLogParams: {
            field: "isHidePin",
            value: !isVisible,
            requestId,
          },
          createdBy: currentUserId!,
          identifyValue: documentItem.id,
          identifyCol: "itemId",
          type: "item",
        });
        dispatch(setDataLogTemp(dataLog));
        socket.addDocItemLog(documentItem.id, dataLog);
        const updatedAt = now;
        if (isPhotoLedger && documentItem?.subItems?.length) {
          // need handle expection
          handleVisiblePinPhotoLedger({
            requestId,
            documentItem: { ...documentItem, updatedAt },
            documentCategories,
            isVisible,
          });
        } else {
          handleVisiblePinNotPhotoLedger({
            isAwait: false,
            isVisible,
            documentItem: { ...documentItem, updatedAt },
            requestId,
            documentCategory,
          });
        }

        dispatch(
          updateVisibleDocumentItem({
            itemId: documentItem.id,
            cateId: documentItem.documentCategoryId,
            documentType: documentItem.documentType,
            isVisible,
          })
        );

        socket.changeVisibleDocItem(documentItem, { isVisible, updatedAt });
      } catch (error) {
        logError("🚀 ~ onUnVisible ~ error:", error);
        // revert if error
        const state = store.getState();
        const documentCategories = state.document.documentCategories;
        const currentCategory = structuredClone(
          documentCategories.find((cate) => cate.id === documentCategory.id)
        );
        if (!currentCategory) {
          return;
        }
        let newSelectedExternalIds: string[] = [];
        const subItems = (documentItem.subItems || []).map((subItem) => ({
          ...subItem,
          isHidden: isVisible,
        }));
        documentItem.subItems = subItems;
        updateElementInArray({
          array: currentCategory.documentItems || [],
          keyIndex: DocumentItemKey.ID,
          element: documentItem,
        });
        if (isVisible) {
          newSelectedExternalIds =
            currentCategory.selectedExternalIds?.filter(
              (id) => id !== documentItem.id
            ) || [];
        } else {
          newSelectedExternalIds = [
            ...(currentCategory.selectedExternalIds || []),
            documentItem.id,
          ];
        }
        dispatch(
          updateDocumentCategory({
            ...currentCategory,
            selectedExternalIds: newSelectedExternalIds,
          })
        );
      } finally {
        // setLoading(false);
      }
    },
    [documentCategories, socket, dispatch]
  );

  return {
    onToggleVisibleDocumentItem,
  };
};

export default useVisibleDocumentItem;

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconTag(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#737373"
        stroke="#171717"
        d="M15.193 9.834V9.3h.6v3.5c0 .11.025.583.22 1.048.2.477.638 1.052 1.48 1.052.703 0 1.15-.405 1.395-.852a2.7 2.7 0 0 0 .305-1.248V12a6.9 6.9 0 1 0-6.9 6.9h.008a6.9 6.9 0 0 0 2.607-.556l.266.539c-.91.394-1.89.604-2.884.617a7.5 7.5 0 0 1 .003-15 7.51 7.51 0 0 1 7.5 7.5v.82l.002.02a2.47 2.47 0 0 1-2.323 2.66 2 2 0 0 1-1.81-1.04l-.336-.614-.472.517A3.5 3.5 0 1 1 12.29 8.5h.001a3.46 3.46 0 0 1 2.097.717zm-4.511 4.577a2.9 2.9 0 1 0 3.222-4.823 2.9 2.9 0 0 0-3.222 4.823Z"
      />
    </Icon>
  );
}

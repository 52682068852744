import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { MessageType } from "constants/websocket";
import { DocumentItemKey, SubItemKey } from "constants/enum";
import { useAuthorization } from "hooks/usePermission";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { DataLog } from "interfaces/models/dataLog";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import {
  GetContentLog,
  insertDataLogToIndexedDb,
  OPERATION_ITEM_LOG,
  transformMapTitleKey,
} from "models/dataLog";
import { getColorTextByStatus } from "models/document";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { sortArrayByField, updateElementInArray } from "utils/array";
import { uuid } from "utils/common";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { transformBodyForCombineData } from "utils/offline";
import FieldNoComponent from "./FieldNoComponent";
import { useItemLogs } from "./hooks";
import useRevertDataByLogs from "./hooks/useRevertDataByLogs";
import ItemChildren from "./ItemChildren";
import ItemLog from "./ItemLog";
import ItemStatusMenu from "./ItemStatusMenu";
import ItemTasksForSelfInspection from "./ItemTasksForSelfInspection";
import ItemTitle, { ItemTitleType } from "./ItemTitle";
import useUserOfProject from "hooks/useUserOfProject";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";

interface Props {
  isLoadedViewer: boolean;
  documentItemSelected?: DocumentItemDTO;
  documentCategorySelected?: DocumentCategoryDTO;
  documentTemplate?: DocumentTemplate;
  itemStatusColor: string;
  isOnline: boolean;
  itemDisplayOrder: number | string;
  isLoadedFamilyInstances: boolean;
  isLoadedExternalId: boolean;

  clickInfo?: ClickInfo;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
}

const FormItemSelfInspection = (props: Props) => {
  const {
    isLoadedViewer,
    documentItemSelected,
    documentCategorySelected,
    documentTemplate,
    clickInfo,
    itemStatusColor,
    isOnline,
    itemDisplayOrder,
    isLoadedFamilyInstances,
    isLoadedExternalId,
    setClickInfo,
  } = props;
  const dispatch = useDispatch();
  const { currentUser, listUserById, listAllUserById } = useUserOfProject();
  const {
    mapDynamicFieldTitle,
    itemLogs,
    isLoadMoreLogs,
    setItemLogs,
    sendMessageToCommonChannel,
  } = useItemLogs({
    itemId: documentItemSelected?.id,
    documentType: documentItemSelected?.documentType,
    documentTemplate,
    dataLogTemp: undefined,
  });

  const { socket } = useForgeViewerContext();
  const childrenRef = useRef<DocumentSubItemDTO[]>([]);
  const rules = useAuthorization();

  useEffect(() => {
    childrenRef.current = documentItemSelected?.subItems || [];
  }, [documentItemSelected?.subItems]);

  const insertItemLog = useCallback(
    async (params: GetContentLog & { subItemId?: string }) => {
      if (!currentUser?.id || !documentItemSelected?.id) {
        return;
      }

      const subItemId = params.subItemId;
      const log = await insertDataLogToIndexedDb({
        contentLogParams: params,
        createdBy: currentUser.id,
        identifyValue: subItemId || documentItemSelected?.id,
        identifyCol: subItemId ? "subItemId" : "itemId",
        type: "item",
      });

      if (log?.id) {
        socket.addDocItemLog(documentItemSelected.id, log);
        setItemLogs((prev) =>
          sortArrayByField<DataLog>([log, ...prev], "createdAt", false)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentItemSelected?.id, currentUser?.id, documentCategorySelected?.id]
  );
  const { onRevertDataByLog } = useRevertDataByLogs({
    insertLog: insertItemLog,
    documentItemSelected,
  });

  const onBeforeRevertDataByLog = useCallback(
    (log: DataLog) => {
      onRevertDataByLog(log, childrenRef.current);
    },
    [onRevertDataByLog]
  );

  const handleSaveDynamicField = useCallback(
    async (subItem: DocumentSubItemDTO) => {
      if (!subItem?.id || !documentItemSelected?.id) {
        return;
      }

      const now = new Date();
      const initSubItem = documentItemSelected.subItems?.find(
        (sub) => sub.id === subItem.id
      );

      const body = {
        id: subItem.id,
        data: subItem.data,
        itemId: subItem.itemId,
        mapTitleKey: subItem.mapTitleKey,
        mapDisplayValueKey: subItem.mapDisplayValueKey,
        mapDisplayLabelKey: subItem.mapDisplayLabelKey,
        mapDisplayValueTypeKey: subItem.mapDisplayValueTypeKey,
        requestId: subItem.requestId,
        updatedAt: now,
      } as DocumentSubItemDTO;

      documentItemApi
        .updateSubItem(
          transformBodyForCombineData<DocumentSubItemDTO>({
            body,
            bodyBefore: initSubItem,
            typeInitData: TypeHandleInitData.SUB_ITEM,
          })
        )
        .then(() => {
          socket.updateSubItem(documentItemSelected, subItem, body);
        });

      const documentItem = structuredClone(documentItemSelected);
      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: SubItemKey.ID,
        element: {
          ...subItem,
          data: subItem.data,
          updatedAt: now,
        } as DocumentSubItemDTO,
      });
      dispatch(
        updateDocumentItem({
          ...documentItem,
          updatedAt: now,
        })
      );
    },
    [documentItemSelected, dispatch, socket]
  );

  const updateItemChild = useCallback(
    async ({
      key,
      value: e,
      child,
      operation,
      groupName,
    }: {
      key: string;
      value: any;
      child: DocumentSubItemDTO;
      operation?: OPERATION_ITEM_LOG;
      groupName?: string;
    }) => {
      if (!documentItemSelected?.id) {
        return;
      }

      const value = key === DocumentItemKey.MEMO ? e.target.value : e;
      const children = childrenRef.current.length
        ? structuredClone(childrenRef.current || [])
        : structuredClone(documentItemSelected?.subItems || []);

      const subItem = children?.find((d) => d.id === child?.id);
      if (value === (subItem?.[key as keyof typeof subItem] ?? "")) {
        return;
      }

      const now = new Date();
      const newSubItem = {
        ...subItem,
        [key]: value,
        updatedAt: now,
      } as DocumentSubItemDTO;
      updateElementInArray({
        array: children,
        keyIndex: SubItemKey.ID,
        element: newSubItem,
      });

      if (!childrenRef.current.length) {
        childrenRef.current = children || [];
      } else {
        childrenRef.current =
          updateElementInArray({
            array: structuredClone(childrenRef.current || []),
            keyIndex: SubItemKey.ID,
            element: newSubItem,
          }) || [];
      }

      dispatch(
        updateDocumentItem({ ...documentItemSelected, subItems: children })
      );
      const requestId = uuid();
      const { mapLogs, mapTitleKey, mapDisplayValueKey } = transformMapTitleKey(
        [
          {
            field: key as any,
            value,
            operation,
            prefixFieldName: groupName,
          },
        ]
      );

      if (subItem?.id) {
        await documentItemApi.updateSubItem(
          transformBodyForCombineData<DocumentSubItemDTO>({
            body: {
              id: subItem.id,
              itemId: subItem.itemId,
              [key]: value,
              requestId,
              isDeleteMedia: operation === OPERATION_ITEM_LOG.DELETE,
              updatedAt: now,
              mapTitleKey,
              mapDisplayValueKey,
            } as DocumentSubItemDTO,
            bodyBefore: subItem,
            typeInitData: TypeHandleInitData.SUB_ITEM,
          })
        );
      }

      await Promise.all(
        Object.values(mapLogs).map((params) =>
          insertItemLog({
            ...params,
            subItemId: subItem?.id,
            isDeleteMedia: params.operation === OPERATION_ITEM_LOG.DELETE,
            requestId,
          })
        )
      );

      if (subItem) {
        socket.updateSubItem(documentItemSelected, subItem, {
          [key]: value,
          updatedAt: now,
        });
      }
    },
    [documentItemSelected, dispatch, insertItemLog, socket]
  );

  const handleBlurValue = useCallback(
    ({
        key,
        child,
        groupName,
      }: {
        key: keyof DocumentSubItemDTO;
        child: DocumentSubItemDTO;
        groupName?: string;
      }) =>
      (value: any) => {
        updateItemChild({ key, child, value, groupName });
      },
    [updateItemChild]
  );

  const handleChangeValue = useCallback(
    ({
        key,
        child,
        groupName,
      }: {
        key: keyof DocumentSubItemDTO;
        child: DocumentSubItemDTO;
        groupName?: string;
      }) =>
      (value: any) => {
        if (!rules.canEditDocumentItem) return;
        if (!documentItemSelected?.id) {
          return;
        }

        if (key === SubItemKey.MEMO) {
          const children = documentItemSelected?.subItems?.map((d) => {
            if (d.id !== child?.id) return d;

            return {
              ...d,
              [key]: value.target.value,
            };
          });

          childrenRef.current = children || [];
          dispatch(
            updateDocumentItem({ ...documentItemSelected, subItems: children })
          );
        } else updateItemChild({ key, child, value, groupName });
      },
    [rules.canEditDocumentItem, documentItemSelected, updateItemChild, dispatch]
  );

  return (
    <Flex flexDirection="column" padding="0px 1.6rem 0" minHeight="100%">
      {/* title */}
      <Flex gap="1.2rem" alignItems="center" mb="3.2rem">
        <FieldNoComponent
          color={itemStatusColor}
          no={itemDisplayOrder}
          colorText={getColorTextByStatus(documentItemSelected?.status)}
        />

        <Flex flexDirection="column" flex={1}>
          <Text lineHeight="2.2rem" color="#737373" fontSize="1.4rem">
            検査帳票
          </Text>
          <ItemTitle
            type={ItemTitleType.ITEM}
            item={documentItemSelected}
            inputProps={{ isDisabled: !rules.canEditDocumentItem }}
            insertItemLog={insertItemLog}
          />
        </Flex>
      </Flex>

      {documentItemSelected?.subItems?.map((child, index) => (
        <ItemChildren
          key={child.id}
          item={child}
          index={index}
          isOnline={isOnline}
          parentItem={documentItemSelected}
          documentTemplate={documentTemplate}
          handleChangeValue={handleChangeValue}
          handleBlurValue={handleBlurValue}
          updateItemChild={updateItemChild}
          isDisabled={!rules.canEditDocumentItem}
          handleSaveDynamicField={handleSaveDynamicField}
          insertItemLog={(params) =>
            insertItemLog({ ...params, subItemId: child.id })
          }
        />
      ))}

      {/* insert task */}
      <ItemTasksForSelfInspection
        isOnline={isOnline}
        clickInfo={clickInfo}
        borderTop="1px solid rgb(163, 163, 163)"
        paddingTop="2rem"
        marginBottom="3.2rem"
        marginTop={documentItemSelected?.subItems ? "3.2rem" : "0"}
        documentItem={documentItemSelected}
        documentCategorySelected={documentCategorySelected}
        setClickInfo={setClickInfo}
        insertDocumentItemLog={insertItemLog}
        sendMessageToCommonChannel={sendMessageToCommonChannel}
        isDisabled={
          !rules.canEditDocumentItem ||
          !isLoadedExternalId ||
          !isLoadedFamilyInstances
        }
      />

      {/* logs */}
      <Box
        backgroundColor="#E8E8E8"
        ml="-1.6rem"
        mt="auto"
        width="calc(100% + 3.2rem)"
        padding="2rem 1.6rem"
      >
        <Text
          display="block"
          fontSize="1.6rem"
          color="var(--primary-text-color)"
          fontWeight="bold"
          mb="1.2rem"
        >
          変更履歴
        </Text>
        {itemLogs?.map((item) => (
          <ItemLog
            key={item.id}
            log={item}
            user={listAllUserById?.[item?.createdBy || ""]}
            currentUser={currentUser}
            listUserById={listUserById}
            mapDynamicFieldTitle={mapDynamicFieldTitle}
            documentType={
              documentItemSelected?.documentType ||
              documentCategorySelected?.documentType
            }
            documentItem={undefined}
            onRevertDataByLog={onBeforeRevertDataByLog}
          />
        ))}

        {isLoadMoreLogs && (
          <Flex height="4rem" justifyContent="center" alignItems="center">
            <Spinner size="md" color="blue.500" />
          </Flex>
        )}
      </Box>

      {/* status */}
      <Flex
        position="sticky"
        width="calc(100% + 3.2rem)"
        marginLeft="-1.6rem"
        bottom="0"
        left="0"
        height="5.8rem"
        zIndex="12"
        backgroundColor="#E8E8E8"
        borderTop="1px solid rgba(0,0,0,0.15)"
        justifyContent="space-between"
        padding="0px 1.6rem"
        alignItems="center"
        gap="0.8rem"
      >
        <Text
          flexShrink={0}
          color="var(--primary-text-color)"
          fontSize="1.4rem"
        >
          ステータス
        </Text>
        <Box flex={1} maxW="35rem">
          <ItemStatusMenu
            isLoadedViewer={isLoadedViewer}
            currentUser={currentUser}
            documentItemSelected={documentItemSelected}
            documentCategorySelected={documentCategorySelected}
            insertDocumentItemLog={insertItemLog}
            isDisabled={!rules.canEditDocumentItem}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(FormItemSelfInspection);

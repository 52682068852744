import { BoxProps, Flex, IconButton } from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import { SvgIcon } from "components/SvgIcon";
import {
  AriaLabelToolBar,
  NodeTypeText,
} from "components/TipTapEditorDoc/type";
import { TextPosition } from "constants/enum";
import { CellType } from "interfaces/models/component";
import { useCallback, useMemo } from "react";

interface Props extends BoxProps {
  editor: Editor | null;
  isDisableButton: boolean;
}

enum TAB_INDEX {
  LEFT_TEXT,
  CENTER_TEXT,
  RIGHT_TEXT,
  JUSTIFY_TEXT,
  BOLD,
  ITALIC,
  UNDERLINED,
}

const PreviewDocumentToolbar = (props: Props) => {
  const { isDisableButton, editor, ...boxProps } = props;

  const selection = editor?.state.selection;
  const isHighlightText = !!selection?.$from.pos && !!selection?.$to.pos;
  const isAllowAddLink =
    !!selection?.$from.pos && selection?.$from.pos !== selection?.$to.pos;
  const styles: CellType["style"][] = [];

  // collect style when select multiple cells
  editor?.state.doc.nodesBetween(
    selection?.$from.pos || 0,
    selection?.$to.pos || 0,
    (node) => {
      if (NodeTypeText.includes(node.type.name as any)) {
        styles.push(node.attrs.style);
      }
    }
  );

  const setTextAlign = new Set(
    (styles || []).map((style) => style?.justifyContent || TextPosition.CENTER)
  );
  const textAlign = setTextAlign.size !== 1 ? null : [...setTextAlign].at(0);

  const iconButtonStyle: BoxProps = useMemo(
    () => ({
      paddingInline: "0px",
      color: "icon.blackBold",
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none !important",
      isDisabled: isDisableButton,
      style: {
        width: "2.4rem",
        height: "2.4rem",
        maxWidth: "2.4rem",
        maxHeight: "2.4rem",
        minWidth: "2.4rem",
        minHeight: "2.4rem",
      },
      _hover: { backgroundColor: "transparent" },
    }),
    [isDisableButton]
  );

  const setLink = useCallback(() => {
    if (!editor) {
      return;
    }

    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    const extendsionLink = editor.chain().focus().extendMarkRange("link");

    // empty
    if (url === "") {
      extendsionLink?.unsetLink().run();

      return;
    }

    // update link
    extendsionLink?.setLink({ href: url }).run();
  }, [editor]);

  const onChangeTextStyle = (style: Partial<CellType["style"]>) => {
    editor?.chain?.()?.focus?.()?.setStyle?.(style)?.run();
  };

  return (
    <Flex {...boxProps} gap="5rem" flexWrap="wrap" alignItems="center">
      <IconButton
        {...(iconButtonStyle as any)}
        aria-label={AriaLabelToolBar.CURSOR}
        icon={<SvgIcon src="/img/draw-tool/mouse-cursor.svg" />}
        isDisabled={isDisableButton}
        sx={{
          'path[data-name="↳Color"]': {
            fill: editor?.isFocused ? "icon.default" : "icon.active",
          },
        }}
        onClick={() => {
          editor?.commands?.unSelection();
        }}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        aria-label={AriaLabelToolBar.UNDO}
        icon={<SvgIcon src="/img/draw-tool/undo.svg" />}
        onClick={() => {
          editor?.chain()?.focus()?.undo().run();
        }}
        isDisabled={!editor?.can?.()?.undo() || isDisableButton}
      />
      <IconButton
        {...(iconButtonStyle as any)}
        aria-label={AriaLabelToolBar.REDO}
        icon={<SvgIcon src="/img/draw-tool/redo.svg" />}
        onClick={() => {
          editor?.chain()?.focus()?.redo().run();
        }}
        isDisabled={!editor?.can?.()?.redo() || isDisableButton}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        aria-label={AriaLabelToolBar.LEFT_TEXT}
        isDisabled={!isHighlightText}
        tabIndex={TAB_INDEX.LEFT_TEXT}
        icon={<SvgIcon src="/img/left-text.svg" />}
        sx={{
          'path[data-name="stroke"]': {
            stroke: [TextPosition.LEFT, TextPosition.START].includes(
              textAlign as any
            )
              ? "icon.active"
              : "icon.default",
          },
        }}
        onClick={() => {
          onChangeTextStyle({ justifyContent: TextPosition.LEFT });
        }}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        aria-label={AriaLabelToolBar.CENTER_TEXT}
        isDisabled={!isHighlightText}
        tabIndex={TAB_INDEX.CENTER_TEXT}
        icon={<SvgIcon src="/img/center-text.svg" />}
        sx={{
          'path[data-name="stroke"]': {
            stroke:
              textAlign === TextPosition.CENTER
                ? "icon.active"
                : "icon.default",
          },
        }}
        onClick={() => {
          onChangeTextStyle({ justifyContent: TextPosition.CENTER });
        }}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        aria-label={AriaLabelToolBar.RIGHT_TEXT}
        isDisabled={!isHighlightText}
        tabIndex={TAB_INDEX.RIGHT_TEXT}
        icon={<SvgIcon src="/img/right-text.svg" />}
        sx={{
          'path[data-name="stroke"]': {
            stroke: [TextPosition.RIGHT, TextPosition.END].includes(
              textAlign as any
            )
              ? "icon.active"
              : "icon.default",
          },
        }}
        onClick={() => {
          onChangeTextStyle({ justifyContent: TextPosition.RIGHT });
        }}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        style={{
          width: "1.8rem",
          height: "1.8rem",
          minWidth: "1.8rem",
          minHeight: "1.8rem",
        }}
        aria-label={AriaLabelToolBar.BOLD}
        tabIndex={TAB_INDEX.BOLD}
        isDisabled={!isHighlightText}
        icon={<SvgIcon width="1.8rem" height="1.8rem" src="/img/bold.svg" />}
        sx={{
          'path[data-name="stroke"]': {
            stroke: editor?.isActive("bold") ? "icon.active" : "icon.default",
          },
        }}
        onClick={() => {
          editor?.chain().focus().toggleBold().run();
        }}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        style={{
          width: "1.8rem",
          height: "1.8rem",
          minWidth: "1.8rem",
          minHeight: "1.8rem",
        }}
        aria-label={AriaLabelToolBar.ITALIC}
        isDisabled={!isHighlightText}
        tabIndex={TAB_INDEX.ITALIC}
        icon={
          <SvgIcon width="1.8rem" height="1.8rem" src="/img/italicized.svg" />
        }
        sx={{
          'path[data-name="stroke"]': {
            stroke: editor?.isActive("italic") ? "icon.active" : "icon.default",
          },
        }}
        onClick={() => {
          editor?.chain().focus().toggleItalic().run();
        }}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        style={{
          width: "1.8rem",
          height: "1.8rem",
          minWidth: "1.8rem",
          minHeight: "1.8rem",
        }}
        aria-label={AriaLabelToolBar.UNDERLINED}
        isDisabled={!isHighlightText}
        tabIndex={TAB_INDEX.UNDERLINED}
        icon={
          <SvgIcon width="1.8rem" height="1.8rem" src="/img/underlined.svg" />
        }
        sx={{
          'path[data-name="stroke"]': {
            stroke: editor?.isActive("underline")
              ? "icon.active"
              : "icon.default",
          },
        }}
        onClick={() => {
          editor?.chain().focus().toggleUnderline().run();
        }}
      />

      <IconButton
        {...(iconButtonStyle as any)}
        style={{
          width: "1.8rem",
          height: "1.8rem",
          minWidth: "1.8rem",
          minHeight: "1.8rem",
        }}
        aria-label={AriaLabelToolBar.LINK}
        isDisabled={!isAllowAddLink}
        icon={<SvgIcon width="1.8rem" height="1.8rem" src="/img/attach.svg" />}
        sx={{
          'path[data-name="stroke"]': {
            stroke: editor?.isActive?.("link") ? "icon.active" : "icon.default",
          },
        }}
        onClick={setLink}
      />
    </Flex>
  );
};

export default PreviewDocumentToolbar;

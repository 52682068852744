import { getValueFromFamilyInstace } from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  DocumentTemplateType,
  FamilyInstanceKey,
  LinkedDataField,
  TemplateComponentType,
  TextPosition,
} from "constants/enum";
import { CommentManageExecute } from "interfaces/models/blackboard";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { FamilyInstance } from "interfaces/models/familyInstance";
import { getCellsByColumnId } from "utils/tableCell";

export const getSizeOfComponent = (params: {
  documentType: string | undefined;
  component: TemplateComponent;
  numberItemPerPage: number;
  limit: number;
}) => {
  const { documentType, component, numberItemPerPage = 0, limit = 0 } = params;

  const width = Number(component?.realSize?.width);
  let height = Number(component?.realSize?.height);

  const isCommon =
    component.type === TemplateComponentType.Table &&
    !!component.linkedHeaderId &&
    (documentType === DocumentTemplateType.COMMISSIONING_TABLE ||
      documentType === DocumentTemplateType.PHOTO_LEDGER);
  const isFilterPhoto = component.type === TemplateComponentType.FilterPhoto;
  const isLinkedImage = component.type === TemplateComponentType.LinkedImage;

  if (isCommon || isFilterPhoto || isLinkedImage) {
    const rowHeight = height / numberItemPerPage;
    height = rowHeight * limit;
  }

  return { width, height };
};

/**
 * @param param0
 * @returns cells need update
 */
export const resetDataForCommentCell = (params: {
  headerComponent: TemplateComponent;
  linkedTables: TemplateComponent[];
}) => {
  const { headerComponent, linkedTables } = params;
  let updatedCells: CellType[] = [];
  const commentCell = headerComponent.detail?.rows?.[0]?.cells?.find(
    (cell) => cell.cellLinkedData?.field === LinkedDataField.COMMON.COMMENT
  );
  const columnId = commentCell?.position?.idColumn;
  if (columnId) {
    updatedCells = [...linkedTables, headerComponent]
      .map((component) => {
        const cells = getCellsByColumnId({
          columnId,
          component,
        }).map(({ subTable, cellLinkedData, ...cell }) => ({
          ...cell,
          cellProperty: CellProperty.TEXT,
          value: "",
        }));

        return cells;
      })
      .flat(1);
  }

  return updatedCells;
};

/**
 * @param param0
 * @returns commentManageExecuteDefault
 */
export const getCommentManageExecuteDefault = (params: {
  cellOfBlackboards: CellType[];
  familyInstance: FamilyInstance;
}) => {
  const { cellOfBlackboards, familyInstance } = params;
  const data: CommentManageExecute[] = [];
  cellOfBlackboards?.forEach((cellOfBlackboard) => {
    let value = cellOfBlackboard?.value || "";
    let field = "" as any;
    const familyInstanceValue = getValueFromFamilyInstace(
      cellOfBlackboard?.cellLinkedData?.field as FamilyInstanceKey,
      familyInstance
    );
    const typeOfEquipment = cellOfBlackboard?.cellLinkedData?.typeOfEquipment;
    const typeOfEquipmentField =
      cellOfBlackboard?.cellLinkedData?.typeOfEquipmentField;

    if (cellOfBlackboard?.cellProperty === CellProperty.FAMILY_INSTANCE) {
      value = familyInstanceValue || "";
      field = cellOfBlackboard.cellLinkedData?.field || "";
    }

    const minRow = value.match(/\n/g);
    const otherOption: Partial<CommentManageExecute> = {};
    if (typeOfEquipment) {
      otherOption.typeOfEquipment = typeOfEquipment;
    }

    if (typeOfEquipmentField) {
      otherOption.typeOfEquipmentField = typeOfEquipmentField;
    }

    data.push({
      value,
      textAlign: (cellOfBlackboard?.style?.justifyContent ||
        TextPosition.START) as TextPosition,
      property: cellOfBlackboard?.cellProperty as CellProperty,
      field,
      minRow: (minRow?.length || 0) + 1,
      ...otherOption,
    });
  });

  if (data?.length) {
    return data;
  }

  return [
    {
      value: "",
      textAlign: TextPosition.START,
      property: CellProperty.TEXT,
    },
  ];
};

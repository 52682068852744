import { taskTypeApi } from "apiClient/v2";
import { message } from "components/base";
import { S3_PATH } from "constants/s3";
import {
  TASK_TYPE_INITIAL,
  TaskType,
  TaskTypeActionThunkParams,
} from "interfaces/models/taskType";
import { detectUrlString, uploadFileToS3 } from "utils/file";

export const COPIED_LABEL = "(コピー)";

export const checkTaskTypeExists = async (
  title: string
): Promise<{
  isExist: boolean;
  id: string;
  deletedAt?: string | null;
}> => {
  try {
    const {
      data: [taskType],
    }: {
      data: TaskType[];
    } = await taskTypeApi.getTaskTypeByTitle(title);

    return {
      isExist: !!taskType,
      id: taskType?.id || "",
      deletedAt: taskType?.deletedAt || null,
    };
  } catch (error) {
    throw error;
  }
};

export const upsertTaskType = async ({
  taskType,
}: TaskTypeActionThunkParams["upsertTaskType"]) => {
  try {
    const payload = {
      ...taskType,
      title: `${taskType.title.trim()}`,
      updatedAt: new Date(),
      deletedAt: null,
    };

    await uploadImageS3(payload);

    const { data } = await taskTypeApi.upsertTaskType(payload);

    return { ...data, columnIndex: taskType.columnIndex };
  } catch (error) {
    throw error;
  }
};

export const updateTaskType = async (taskType: TaskType) => {
  await uploadImageS3(taskType);

  return taskTypeApi.updateTaskType(taskType);
};

export const copyTaskType = async ({
  taskType,
}: TaskTypeActionThunkParams["copy"]) => {
  try {
    const { data } = await taskTypeApi.upsertTaskType(taskType);

    return { ...data, columnIndex: taskType.columnIndex };
  } catch (error) {
    throw error;
  }
};

export const uploadImageS3 = async (payload: any) => {
  if (!detectUrlString(payload.badImageUrl) && !!payload.badImageFile) {
    const badImageUrl = await uploadFileToS3(
      payload.badImageFile,
      payload.badImageFile?.name,
      S3_PATH.Task
    );

    payload.badImageUrl = badImageUrl;
    delete payload.badImageFile; // remove file after upload
  }

  if (!detectUrlString(payload.goodImageUrl) && !!payload.goodImageFile) {
    const goodImageUrl = await uploadFileToS3(
      payload.goodImageFile,
      payload.goodImageFile?.name,
      S3_PATH.Task
    );

    payload.goodImageUrl = goodImageUrl;
    delete payload.goodImageFile; // remove file after upload
  }
};

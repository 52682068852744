import { TypeOfFile } from "constants/file";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { TaskAttached } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsProcessingTaskModal } from "redux/taskSlice";

import {
  compressionImageByFile,
  fileToBase64,
  getDateTimeOriginalExif,
  uploadFileToS3,
} from "utils/file";

import { HandleSelectDrawImageProps } from "../DrawToolModal";
import { SaveTaskModalData, SelectedImages } from "./hooks";

const DEFAULT_FILE_PATH = "task";

interface Props {
  taskModalInfo: TaskDTO | undefined;
  currentUser: User | null;
  handleUpdateFieldsChangeData: (keys: (keyof TaskDTO)[]) => void;
  setTaskModalInfo: (val: any) => any;
  saveModalData: (
    val: SaveTaskModalData
  ) => Promise<TaskDTO | null | undefined>;
}

export default function useUploadFile(props: Props) {
  const {
    taskModalInfo,
    currentUser,
    setTaskModalInfo,
    handleUpdateFieldsChangeData,
    saveModalData,
  } = props;

  const [attachesSelected, setAttachesSelected] = useState<FileModel[]>([]);
  const [listImageSelected, setListImageSelected] = useState<SelectedImages>({
    images: [],
    confirmedImages: [],
  });
  const dispatch = useDispatch();
  const [indexFileUpload, setIndexFileUpload] = useState<number[]>([]);

  const handleFileSelected = async ({
    fileList,
    type,
    field,
    originFileList,
    isNeedTimeOriginal = true,
  }: {
    fileList: FileList;
    originFileList?: FileModel[];
    type: TypeOfFile;
    field?: keyof SelectedImages;
    isNeedTimeOriginal?: boolean;
  }) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const newListImageSelected = [
      ...(listImageSelected?.[field as keyof SelectedImages] || []),
    ];

    const newAttachesSelected = [...attachesSelected];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i] as File & { lastModifiedDate?: Date };
      let dateTimeOriginal;
      if (isNeedTimeOriginal) {
        dateTimeOriginal = await getDateTimeOriginalExif(file);
      }
      const imgString = await fileToBase64(file);
      const originFile = originFileList?.[i];
      const objectFile: FileModel = {
        src: imgString,
        name: file.name,
        originFile: originFile?.file,
        originName: originFile?.name,
        file,
        userUpload: currentUser?.id,
        originSrc: "",
        uploadTime: new Date(),
        lastModifiedDateFile: dateTimeOriginal || file.lastModifiedDate,
      };
      if (type === TypeOfFile.IMAGE) {
        newListImageSelected.push(objectFile);
      } else if (type === TypeOfFile.ATTACH) {
        newAttachesSelected.push(objectFile);
      }
    }

    if (type === TypeOfFile.IMAGE) {
      setListImageSelected({
        ...listImageSelected,
        [field as keyof SelectedImages]: newListImageSelected,
      });
      const newTaskSelected = {
        ...taskModalInfo,
        [field as keyof SelectedImages]: newListImageSelected?.map(
          (i) => i?.src || (i as string)
        ),
      };
      setIsDisableUploadImage(true, newTaskSelected);
    } else if (type === TypeOfFile.ATTACH) {
      setAttachesSelected(newAttachesSelected);
    }

    return {
      [type]:
        type === TypeOfFile.IMAGE ? newListImageSelected : newAttachesSelected,
    };
  };

  const setIsDisableUploadImage = useCallback(
    (loading: boolean, task?: TaskDTO) => {
      if (!taskModalInfo?.id) {
        return;
      }
      const images = (task || taskModalInfo).images;
      const confirmedImages = (task || taskModalInfo).confirmedImages;
      setTaskModalInfo((prev: TaskDTO) => ({
        ...(prev || ({} as TaskDTO)),
        images,
        confirmedImages,
      }));
      dispatch(setIsProcessingTaskModal(loading));
    },
    [dispatch, setTaskModalInfo, taskModalInfo]
  );

  const onDeleteAttachFile = async (index: number) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const newsAttaches = [...attachesSelected];
    newsAttaches.splice(index, 1);
    setAttachesSelected(newsAttaches);
    handleUpdateFieldsChangeData(["attaches"]);
    await saveModalData({
      newTaskModalData: taskModalInfo,
      fileData: {
        [TypeOfFile.ATTACH]: newsAttaches,
      },
    });
  };

  const onClickBtnSelectFile = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    target.value = "";
  };

  const onDeleteImage = async (
    index: number,
    addCommentFunc: () => void,
    field?: keyof SelectedImages
  ) => {
    if (!taskModalInfo?.id) {
      return;
    }
    handleUpdateFieldsChangeData([field as keyof TaskDTO]);
    const newsImages = [...listImageSelected[field as keyof SelectedImages]];
    const srcImage = newsImages?.[index]?.src;
    const srcOriginImage = newsImages?.[index]?.originSrc;
    const pathDeletes = [];
    if (srcImage) {
      pathDeletes.push(new URL(srcImage).pathname.substring(1));
    }
    if (srcOriginImage) {
      pathDeletes.push(new URL(srcOriginImage).pathname.substring(1));
    }
    newsImages.splice(index, 1);
    setIsDisableUploadImage(true, {
      ...taskModalInfo,
      [field as keyof SelectedImages]: newsImages?.map(
        (i) => i?.src || (i as string)
      ),
    });
    setListImageSelected({
      ...listImageSelected,
      [field as keyof SelectedImages]: newsImages,
    });
    saveModalData({
      newTaskModalData: taskModalInfo,
      addCommentFunc,
      fileData: {
        [TypeOfFile.IMAGE]: newsImages,
      },
      field,
    });
  };

  const handleSaveDrawImage =
    (field: keyof SelectedImages) =>
    async (newListImage: FileModel[], requestId?: string) => {
      if (!taskModalInfo?.id) {
        return;
      }
      setListImageSelected({
        ...listImageSelected,
        [field as keyof SelectedImages]: newListImage,
      });
      handleUpdateFieldsChangeData([field as keyof TaskDTO]);
      await saveModalData({
        newTaskModalData: taskModalInfo,
        fileData: {
          [TypeOfFile.IMAGE]: newListImage,
        },
        field,
        isCompress: false,
        requestId,
      });
    };

  const onChangeFile =
    (field?: keyof SelectedImages) =>
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      type: TypeOfFile,
      addCommentFunc?: () => void
    ) => {
      if (!taskModalInfo?.id) {
        return;
      }
      const fileList = e.target.files as FileList;
      if (!fileList.length) {
        return;
      }
      let isNeedTimeOriginal = true;
      if (type === TypeOfFile.ATTACH) {
        const indexArr: number[] = [];
        Array.from(fileList).forEach((_, index) => {
          indexArr.push(index + attachesSelected.length);
        });
        setIndexFileUpload(indexArr);
        isNeedTimeOriginal = false;
        handleUpdateFieldsChangeData(["attaches"]);
      } else {
        handleUpdateFieldsChangeData([field as keyof TaskDTO]);
      }
      const newFileData = await handleFileSelected({
        fileList,
        type,
        field,
        isNeedTimeOriginal,
      });

      saveModalData({
        newTaskModalData: taskModalInfo,
        addCommentFunc,
        fileData: newFileData,
        field,
      });
    };

  const handleSaveCaptureCamera =
    (field: keyof SelectedImages) =>
    async ({ file, originFile, isEdited }: HandleSelectDrawImageProps) => {
      if (!taskModalInfo?.id) {
        return;
      }
      handleUpdateFieldsChangeData([field as keyof TaskDTO]);
      let originImage: FileModel = undefined as any;
      if (isEdited) {
        originImage = {
          file: originFile,
          name: originFile.name,
        };
      }
      const fileData = await handleFileSelected({
        fileList: [file] as unknown as FileList,
        type: TypeOfFile.IMAGE,
        field,
        originFileList: [originImage],
      });

      await saveModalData({
        newTaskModalData: taskModalInfo,
        fileData,
        field,
      });
    };

  const handleUploadImageToS3 = async (
    files: FileModel[],
    isCompress: boolean,
    requestId: string
  ) => {
    return await Promise.all(
      files.map(async (file) => {
        let originSrc = file?.originSrc;
        if (file.originFile) {
          originSrc = await uploadFileToS3(
            file.originFile,
            file.originName || "",
            DEFAULT_FILE_PATH,
            { requestId }
          );
        }
        const lastModifiedDateFile = file.lastModifiedDateFile || undefined;
        let uploadTime = file.uploadTime;
        let userUpload = file.userUpload;
        let src = file.src || "";
        if (file.file && isCompress) {
          const newFile = await compressionImageByFile({
            file: file.file!,
            fileName: file.name,
          });
          src = await uploadFileToS3(
            newFile,
            file.name || "",
            DEFAULT_FILE_PATH,
            { requestId }
          );
          uploadTime = new Date();
          userUpload = currentUser?.id || "";
        }

        return {
          src,
          originSrc,
          uploadTime,
          userUpload,
          lastModifiedDateFile,
        };
      })
    ).then((img) => img.filter((i) => !!i.src));
  };

  const handleUploadAttach = async (
    files: FileModel[],
    requestId: string,
    crtAttaches: TaskAttached[]
  ) => {
    return (
      await Promise.all(
        files.map(async (file) => {
          const name = file.name;
          let src = file.src || "";
          const isExists = crtAttaches?.some((att) => att.src === file.src);
          if (file.file && !isExists) {
            src = await uploadFileToS3(
              file.file,
              file.name || "",
              DEFAULT_FILE_PATH,
              {
                requestId,
              }
            );
          }

          return { name, src };
        })
      )
    ).filter((item) => item.src);
  };

  const getDataImageHasModifiedDataFile = (image: FileUploadInfo) => ({
    src: image.src,
    userUpload: image.userUpload,
    uploadTime: image.uploadTime,
    originSrc: image?.originSrc,
    lastModifiedDateFile: image.lastModifiedDateFile
      ? image.lastModifiedDateFile
      : undefined,
  });

  return {
    attachesSelected,
    listImageSelected,
    indexFileUpload,
    getDataImageHasModifiedDataFile,
    handleUploadImageToS3,
    handleUploadAttach,
    setIndexFileUpload,
    setListImageSelected,
    setIsDisableUploadImage,
    onDeleteAttachFile,
    setAttachesSelected,
    onClickBtnSelectFile,
    onDeleteImage,
    handleSaveDrawImage,
    onChangeFile,
    handleSaveCaptureCamera,
  };
}

import { Editor } from "@tiptap/react";
import { PaperType } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { NeptuneArea } from "interfaces/models/area";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentKeyNote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";

export interface GetContentTypeDocumentType {
  cell: CellType;
  width: number;
  height: number;
  parentCell: CellType | undefined;
  cellIndex: number;
  rowIndex: number;
  subRowIndex: number | undefined;
  subCellIndex: number | undefined;
  currentCell: CellType | undefined;
}

export const EDITOR_SCROLL_MARGIN_TOP = 150;
export const EDITOR_SCROLL_MARGIN_TOP_ON_IPAD = 300;
export const EDITOR_SCROLL_MARGIN_BOTTOM = 100;

export const MAP_PADDING_PREVIEW_PAGER_TYPE = {
  [PaperType.A4]: {
    paddingX: 36,
    paddingY: 20,
  },
  [PaperType.A3]: {
    paddingX: 51,
    paddingY: 56,
  },
};

export enum AriaLabelToolBar {
  CURSOR = "cursor",
  UNDO = "undo",
  REDO = "redo",
  LEFT_TEXT = "left-text",
  CENTER_TEXT = "center-text",
  JUSTIFY_TEXT = "justify-text",
  RIGHT_TEXT = "right-text",
  TAB_RIGHT = "tab-right",
  TAB_LEFT = "tab-left",
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINED = "undefined",
  LINK = "link",
}

export enum MarkType {
  UNDERLINE = "underline",
  BOLD = "bold",
  ITALIC = "italic",
}

export enum NodeType {
  DOC = "doc",
  PREVIEW_CONTAINER = "previewContainer",
  PREVIEW_PAGE = "previewPage",
  PAGE_CONTENT = "pageContent",
  PAGE_COMPONENT = "pageComponent",
  SUB_TABLE_COMPONENT = "subTableComponent",
  TABLE_COMPONENT = "tableComponent",
  TABLE_TR_COMPONENT = "tableTrComponent",
  TABLE_TD_COMPONENT = "tableTdComponent",
  TABLE_TD_CONTENT_COMPONENT = "tableTdContentComponent",
  NORMAL_TEXT = "normalText",
  MULTIPLE_NORMAL_TEXT = "multipleNormalText",
  JUSTIFY_TEXT = "justifyText",
  TEXT_BOX = "textbox",
  CROSS_LINE = "crossLine",
  IMAGE_WITH_BLACKBOARD = "imageWithBlackboard",
  TASK_LIST = "taskList",
  TASK_ITEM = "taskItem",
  TASK_OPT = "taskOpt",
  TEXT = "text",
  PARAGRAPH = "paragraph",
  IMAGE_UPLOADED = "imageUploaded",
  KEYNOTE = "keynote",
  LINKED_IMAGE_LINE = "linkedImageLine",
}

export const NodeTypeText = [
  NodeType.NORMAL_TEXT,
  NodeType.MULTIPLE_NORMAL_TEXT,
  NodeType.TEXT_BOX,
];

export const NodeTypeNeedBlur: NodeType[] = [
  NodeType.DOC,
  NodeType.PREVIEW_CONTAINER,
  NodeType.PREVIEW_PAGE,
  NodeType.PAGE_CONTENT,
  NodeType.PAGE_COMPONENT,
  NodeType.SUB_TABLE_COMPONENT,
  NodeType.TABLE_COMPONENT,
  NodeType.TABLE_TR_COMPONENT,
  NodeType.TABLE_TD_COMPONENT,
  NodeType.TABLE_TD_CONTENT_COMPONENT,
  NodeType.CROSS_LINE,
  NodeType.IMAGE_WITH_BLACKBOARD,
  NodeType.IMAGE_UPLOADED,
  NodeType.KEYNOTE,
];

export interface CustomNode<T> {
  type: NodeType;
  attrs?: T;
  content?: CustomNode<any>[];
}

export interface CustomNodeComponentProps<A> {
  node: { attrs: A };
  updateAttributes: (attrs: A) => void;
  selected: boolean;
  editor: Editor;
  getPos: () => number;
  extension: Node;
}

export interface UsePreviewDocumentProps {
  isEditMode: boolean;
  currentDocument: DocumentDTO | null;
  template?: DocumentTemplate;
  components?: TemplateComponent[];
  listUserById?: {
    [key: string]: User | null;
  };
  companiesById: Record<string, PartnerCompany>;
  documentItems: DocumentItem[] | BlackBoardInfo[];
  documentCategorySelected?: DocumentCategoryDTO;
  pageZoom?: number;
  documentKeynoteDataProp?: DocumentKeyNote;
  keynoteImageDataProp?: KeynoteImageData;
  neptuneAreas?: NeptuneArea[];
  blackboardTemplateProps?: iBlackboardTemplateProps;
  familyInstances: { [key: string]: FamilyInstanceDTO };
  projectName: string;
  isSavingDocument: boolean;

  onInitEditor: (editor: Editor | null) => void | undefined;
}

export interface UseMapPageProps {
  template?: DocumentTemplate;
  components?: TemplateComponent[];
  documentItems: DocumentItem[] | BlackBoardInfo[];
}

interface PageSize {
  width: number;
  height: number;
}

export interface PageInfo {
  pageId: string;
  components: TemplateComponent[];
  numberPerPage: number;
  limitItemTableLinked: number;
  offsetItemLinked: number;
  pageSize: PageSize;
  isRepeat: boolean | undefined;
  isVertical?: boolean;
  sizePageRatio?: number;
  pageDirectionRatio?: number;

  // for equipment data sheet template
  documentItem?: DocumentItemDTO;
}

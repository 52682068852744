import { getBorderWidthByTableStyle } from "components/editor-builder/component-preview/TableComponentPreview/utils/tableStyle";
import {
  CellSizeSetting,
  DocumentTemplateType,
  TableDefaultStyle,
  TemplateComponentType,
} from "constants/enum";
import { CellType, RowType } from "interfaces/models/component";
import {
  isEquipmentDataSheetTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { getOriginalSize } from "utils/document";
import {
  SubTableComponentNodeAttrs,
  TableComponentNodeAttrs,
  TableTdComponentNodeAttrs,
  TableTrComponentNodeAttrs,
} from "../extendsions";
import { CustomNode, NodeType } from "../type";
import BaseStructureTable, {
  BaseStructureTableProps,
} from "./BaseStructureTable";
import ContentTdTypeCommissionTable from "./ContentTdTypeCommissionTable";
import ContentTdTypeEquipmentDataSheet from "./ContentTdTypeEquipmentDataSheet";
import ContentTdTypeNormalTable from "./ContentTdTypeNormalTable";
import ContentTdTypePhotoLedger from "./ContentTdTypePhotoLedger";
import ContentTdTypeSelfInspection from "./ContentTdTypeSelfInspection";

interface GetContentTypeDocumentType {
  cell: CellType;
  width: number;
  height: number;
  parentCell: CellType | undefined;
  cellIndex: number;
  rowIndex: number;
  subRowIndex: number | undefined;
  subCellIndex: number | undefined;
  currentCell: CellType | undefined;
}

class StructureTable extends BaseStructureTable {
  protected contentTdTypePhotoLedger: ContentTdTypePhotoLedger;
  protected contentTdTypeCommissionTable: ContentTdTypeCommissionTable;
  protected contentTdTypeEquipmentDataSheet: ContentTdTypeEquipmentDataSheet;
  protected contentTdTypeSelfInspection: ContentTdTypeSelfInspection;
  protected contentTdTypeNormalTable: ContentTdTypeNormalTable;

  constructor(params: BaseStructureTableProps) {
    super(params);

    this.contentTdTypePhotoLedger = new ContentTdTypePhotoLedger(params);
    this.contentTdTypeCommissionTable = new ContentTdTypeCommissionTable(
      params
    );
    this.contentTdTypeEquipmentDataSheet = new ContentTdTypeEquipmentDataSheet(
      params
    );
    this.contentTdTypeSelfInspection = new ContentTdTypeSelfInspection(params);
    this.contentTdTypeNormalTable = new ContentTdTypeNormalTable(params);
  }

  public getStructure(): CustomNode<TableComponentNodeAttrs> | null {
    if (!this.page || !this.component) {
      return null;
    }

    const content: CustomNode<TableTrComponentNodeAttrs>[] = [];
    const structureListTr = this.getListTrByDocumentType();

    if (structureListTr) {
      content.push(...structureListTr);
    }

    return {
      type: NodeType.TABLE_COMPONENT,
      attrs: {
        tableId: this.component.componentId,
        backgroundColor:
          this.component?.detail?.style?.backgroundColor || "#fff",
        offsetWidth: this.isOverlapRightSide ? 1 * (this.zoomRatio || 1) : 0,
      },
      content,
    };
  }

  private getListTrByDocumentType():
    | CustomNode<TableTrComponentNodeAttrs>[]
    | null {
    const component = this.component;
    const template = this.template;
    if (!this.page || !component || !template) {
      return null;
    }
    const documentType = template.documentType;

    const rows = component?.detail?.rows || [];
    const { limitItemTableLinked } = this.page;

    const content: CustomNode<TableTrComponentNodeAttrs>[] = [];
    const mapTrCommon = (params: { row: RowType; rowIndex: number }) => {
      const { row, rowIndex } = params;
      const rowHeight =
        (getOriginalSize(rows, row.cells?.[0]!).height /
          component.size.height) *
        this.displaySizeHeight;
      const structureTr = this.getStructureTr({
        rowIndex,
        row,
        rowHeight: Number(rowHeight.toFixed(1)),
      });

      if (structureTr) {
        content.push(structureTr);
      }
    };

    const isLinkedImage = component.type === TemplateComponentType.LinkedImage;
    const isFilterPhoto = component.type === TemplateComponentType.FilterPhoto;
    const isNormalTable = !component.linkedHeaderId && !isFilterPhoto;
    const isEquipmentDataSheet = isEquipmentDataSheetTemplate(
      this.template?.documentType
    );
    const isSelfInspection = isSelfInspectionTemplate(
      this.template?.documentType
    );

    if (
      isNormalTable ||
      isEquipmentDataSheet ||
      isLinkedImage ||
      isSelfInspection
    ) {
      rows.forEach((row, rowIndex) => {
        mapTrCommon({ row, rowIndex });
      });

      return content;
    }

    switch (documentType) {
      case DocumentTemplateType.COMMISSIONING_TABLE: {
        new Array(limitItemTableLinked).fill(0).forEach((_, rowIndex) => {
          const row = rows?.at(rowIndex) || rows?.at(0);

          if (row) {
            mapTrCommon({ row, rowIndex });
          }
        });

        break;
      }

      case DocumentTemplateType.PHOTO_LEDGER: {
        rows.forEach((row, rowIndex) => {
          const isOutLimit =
            rowIndex + 1 > limitItemTableLinked || limitItemTableLinked === 0;

          if (isOutLimit) {
            content.push({
              type: NodeType.TABLE_TR_COMPONENT,
              attrs: { rowHeight: undefined, rowIndex, rowId: row.idRow },
              content: [],
            });

            return;
          }

          mapTrCommon({ row, rowIndex });
        });

        break;
      }

      default:
        break;
    }

    return content;
  }

  private getStructureTr(params: {
    parentCell?: CellType;
    row: RowType;
    parentRowIndex?: number;
    parentCellIndex?: number;
    rowIndex: number;
    rowHeight: number;
    displaySizeWidth?: number;
    displaySizeHeight?: number;
  }): CustomNode<TableTrComponentNodeAttrs> {
    const {
      parentCell,
      parentRowIndex,
      parentCellIndex,
      rowIndex,
      row,
      rowHeight,
      displaySizeWidth: _displaySizeWidth,
      displaySizeHeight: _displaySizeHeight,
    } = params;
    const cells = row?.cells || [];
    const cellsContent: CustomNode<TableTdComponentNodeAttrs>[] = [];
    cells.forEach((cell, cellIndex) => {
      let isParentLastColumn = false;
      if (!parentCell && cell.colSpan !== undefined) {
        isParentLastColumn = (cells?.length || 0) === cellIndex + cell.colSpan!;
      }

      const structureTd = this.getStructureTd({
        rowIndex,
        parentRowIndex,
        parentCellIndex,
        cells,
        cellIndex,
        parentCell,
        isParentLastColumn,
        cell,
        prevCell: cells?.[cellIndex - 1],
        displaySizeWidth: _displaySizeWidth,
        displaySizeHeight: _displaySizeHeight,
      });

      if (structureTd) {
        Array.isArray(structureTd)
          ? cellsContent.push(...structureTd)
          : cellsContent.push(structureTd);
      }
    });

    return {
      type: NodeType.TABLE_TR_COMPONENT,
      attrs: {
        rowId: row.idRow,
        rowIndex,
        rowHeight,
      },
      content: cellsContent,
    };
  }

  private getStructureTd(params: {
    cell: CellType;
    cells: CellType[];
    prevCell?: CellType;
    parentCell?: CellType;
    rowIndex: number;
    parentRowIndex?: number;
    parentCellIndex?: number;
    cellIndex: number;
    isParentLastColumn?: boolean;
    displaySizeWidth?: number;
    displaySizeHeight?: number;
  }): CustomNode<TableTdComponentNodeAttrs> | null {
    const {
      cell,
      cells,
      prevCell,
      parentCell,
      parentRowIndex,
      parentCellIndex,
      rowIndex,
      cellIndex,
      isParentLastColumn,
      displaySizeWidth: _displaySizeWidth,
      displaySizeHeight: _displaySizeHeight,
    } = params;
    const documentType = this.template?.documentType;
    const hasSubTable = !!cell?.subTable?.rows?.length;
    const component = this.component;
    if (!component || cell.colSpan === 0 || cell.rowSpan === 0) {
      return null;
    }

    const displaySizeHeight = _displaySizeWidth ?? this.displaySizeHeight;
    const displaySizeWidth = _displaySizeHeight ?? this.displaySizeWidth;

    const cellWidth = cell.width
      ? Number(cell?.width) + 1
      : CellSizeSetting.DEFAULT_WIDTH;
    const cellHeight = cell?.height ?? CellSizeSetting.DEFAULT_HEIGHT;
    let width = parentCell
      ? cell?.width! - TableDefaultStyle.DEFAULT_BORDER_SIZE
      : (cellWidth / component.size.width) * displaySizeWidth -
        TableDefaultStyle.DEFAULT_BORDER_SIZE;
    let height = parentCell
      ? cell?.height! - TableDefaultStyle.DEFAULT_BORDER_SIZE
      : (cellHeight / component.size.height) * displaySizeHeight -
        TableDefaultStyle.DEFAULT_BORDER_SIZE;

    width = !Number.isNaN(width) ? Number(width.toFixed(1)) : width;
    height = !Number.isNaN(height) ? Number(height.toFixed(1)) : height;

    let content: CustomNode<any>[] = [];
    if (hasSubTable) {
      content = [
        this.getStructureSubTable({
          cell,
          displaySizeSubHeight: height,
          displaySizeSubWidth: width,
          parentRowIndex: rowIndex,
          parentCellIndex: cellIndex,
        }) as any,
      ];
    }

    const isTableByDocumentType =
      component.linkedHeaderId ||
      [
        TemplateComponentType.LinkedImage,
        TemplateComponentType.FilterPhoto,
      ].includes(component.type);
    const isContentTdTypeNormalTable = !hasSubTable && !isTableByDocumentType;
    if (isContentTdTypeNormalTable) {
      const contentPreview = this.contentTdTypeNormalTable.getStructure({
        cell,
        height,
        documentItem: this.documentItem,
      }) as any;
      content = Array.isArray(contentPreview)
        ? contentPreview
        : [contentPreview];
    }

    if (!hasSubTable && isTableByDocumentType) {
      let contentRaw: any | undefined = undefined;
      const paramsContentTypeDocumentType = {
        cell,
        currentCell: parentCell ?? cell,
        parentCell,
        rowIndex: parentRowIndex ?? rowIndex,
        cellIndex: parentCellIndex ?? cellIndex,
        subRowIndex: rowIndex,
        subCellIndex: cellIndex,
        width,
        height,
      } satisfies GetContentTypeDocumentType;

      switch (documentType) {
        case DocumentTemplateType.PHOTO_LEDGER: {
          contentRaw = this.contentTdTypePhotoLedger.getStructure(
            paramsContentTypeDocumentType
          );
          break;
        }

        case DocumentTemplateType.COMMISSIONING_TABLE: {
          contentRaw = this.contentTdTypeCommissionTable.getStructure(
            paramsContentTypeDocumentType
          );
          break;
        }

        case DocumentTemplateType.EQUIPMENT_DATA_SHEET: {
          contentRaw = this.contentTdTypeEquipmentDataSheet.getStructure(
            paramsContentTypeDocumentType
          );
          break;
        }

        case DocumentTemplateType.SELF_INSPECTION: {
          contentRaw = this.contentTdTypeSelfInspection.getStructure(
            paramsContentTypeDocumentType
          );

          break;
        }

        default:
          break;
      }

      if (contentRaw) {
        content = Array.isArray(contentRaw) ? contentRaw : [contentRaw];
      }
    }

    let customBorderWidth = undefined;
    if (parentCell) {
      const subTableRows = parentCell?.subTable?.rows;
      const isFirstColumn = cellIndex === 0;
      const isLastColumn =
        cells.filter((cell) => (cell?.colSpan || 0) > 0).at(-1)?.cellId ===
        cell.cellId;
      const isLastRow =
        (subTableRows?.length || 0) === rowIndex + cell.rowSpan!;

      customBorderWidth = getBorderWidthByTableStyle({
        css: null,
        prevHasSubTable: null,
        optionSubCell: {
          subCell: cell,
          component,
          isFirstColumn,
          isLastColumn,
          isLastRow,
          isParentLastColumn: !!isParentLastColumn,
        },
      });
    }

    return {
      type: NodeType.TABLE_TD_COMPONENT,
      attrs: {
        width,
        height,
        parentCellStyle: parentCell?.style || {},
        cell: {
          cellId: cell.cellId,
          colSpan: cell.colSpan ?? 1,
          rowSpan: cell.rowSpan ?? 1,
        },
        customBorderWidth,
        textStyle: cell.style || {},
        hasSubTable: !!cell?.subTable?.rows?.length,
        prevHasSubTable: !!prevCell?.subTable?.rows?.length,
        tableBackground: component?.detail?.style?.backgroundColor || "#fff",
      },
      content,
    };
  }

  private getStructureSubTable(params: {
    cell: CellType;
    displaySizeSubHeight: number;
    displaySizeSubWidth: number;
    parentRowIndex: number;
    parentCellIndex: number;
  }): CustomNode<SubTableComponentNodeAttrs> | null {
    const {
      parentCellIndex,
      parentRowIndex,
      cell,
      displaySizeSubHeight,
      displaySizeSubWidth,
    } = params;

    const component = this.component;
    if (!component) {
      return null;
    }

    const rows = cell?.subTable?.rows || [];
    const content = rows.map((row, rowIndex) => {
      const rowHeight =
        (getOriginalSize(rows, row.cells?.[0]!).height / (cell.height || 1)) *
          displaySizeSubHeight -
        (rowIndex === 0 ? 0.5 : 0);

      return this.getStructureTr({
        parentCell: cell,
        parentRowIndex,
        parentCellIndex,
        rowIndex,
        row,
        rowHeight,
        displaySizeHeight: displaySizeSubHeight,
        displaySizeWidth: displaySizeSubWidth,
      });
    });

    return {
      type: NodeType.SUB_TABLE_COMPONENT,
      attrs: { tableId: component.componentId },
      content,
    };
  }
}

export default StructureTable;

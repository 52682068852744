import { Box, Flex, Text, Textarea } from "@chakra-ui/react";
import StatusCheckbox from "components/input/StatusCheckbox";
import {
  CellProperty,
  DocumentStatusType,
  LinkedDataField,
  SubItemKey,
  TypeFieldDynamic,
} from "constants/enum";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { memo, useEffect, useMemo, useState } from "react";
import { GetContentLog, OPERATION_ITEM_LOG } from "models/dataLog";
import DynamicFields from "../DocumentCategoryModal/DynamicFields";
import AttachFile from "./AttachFile";

interface Props {
  parentItem: DocumentItemDTO;
  item: DocumentSubItemDTO;
  index: number;
  documentTemplate?: DocumentTemplate;
  isDisabled?: boolean;
  isOnline: boolean;
  insertItemLog: (params: GetContentLog) => Promise<void>;

  updateItemChild: (params: {
    key: string;
    value: any;
    child: DocumentSubItemDTO;
    operation?: OPERATION_ITEM_LOG;
    groupName?: string;
  }) => any;
  handleBlurValue: (params: {
    key: keyof DocumentSubItemDTO;
    child: DocumentSubItemDTO;
    groupName?: string;
  }) => (value: any) => void;
  handleChangeValue: (params: {
    key: keyof DocumentSubItemDTO;
    child: DocumentSubItemDTO;
    groupName?: string;
  }) => (value: any) => void;
  handleSaveDynamicField: (subItem: DocumentSubItemDTO) => Promise<void>;
}

const ItemChildren = (props: Props) => {
  const {
    item,
    index,
    documentTemplate,
    parentItem,
    isDisabled,
    isOnline,

    handleSaveDynamicField,
    insertItemLog,
    updateItemChild,
    handleBlurValue,
    handleChangeValue,
  } = props;

  const [memo, setMemo] = useState(item?.memo || "");

  useEffect(() => {
    setMemo(item?.memo || "");
  }, [item?.memo]);

  const valueCheckbox = useMemo(() => {
    if (!documentTemplate) return [];

    const components = (documentTemplate?.components ||
      []) as TemplateComponent[];
    if (!components?.length) return [];

    const arrTableLinked = components.filter(
      (c) => c?.subcategoryIdSelected === parentItem?.linkedDataId
    );
    if (!arrTableLinked?.length) return [];

    let tableLinked = arrTableLinked?.[0];
    if (arrTableLinked?.length > 1) {
      let otherRow = [] as any;
      arrTableLinked.forEach((table, tableIndex) => {
        if (tableIndex === 0) {
          return;
        }

        const rows = table.detail?.rows || [];
        otherRow = [...otherRow, ...rows];
      });
      tableLinked = {
        ...tableLinked,
        detail: {
          ...tableLinked.detail,
          rows: [...(tableLinked.detail?.rows || []), ...otherRow],
        },
      };
    }

    const cells = tableLinked?.detail?.rows?.[0]?.cells || [];

    const indexPassCriteria = cells?.findIndex(
      (cell) =>
        cell?.cellLinkedData?.field === LinkedDataField.COMMON.PASS_CRITERIA
    ) as number;
    const indexCheckbox = cells?.findIndex(
      (cell) => cell?.cellProperty === CellProperty.CHECK_BOX
    ) as number;

    return (
      tableLinked?.detail?.rows?.map((row) => ({
        value: row.cells![indexPassCriteria]?.value || "",
        numberOfCheckbox:
          row.cells![indexCheckbox]?.cellLinkedData?.options
            ?.numberOfCheckbox || 0,
      })) || []
    );
  }, [parentItem, documentTemplate]);

  const isLast = index + 1 === parentItem?.children?.length;

  return (
    <Box
      w="100%"
      paddingTop="2.4rem"
      borderTop="1px solid #D4D4D4"
      borderBottom={isLast ? "1px solid #D4D4D4" : "none"}
      marginBottom={isLast ? "2.4rem" : "none"}
    >
      <Text fontSize="1.6rem" mb="1.6rem" fontWeight="bold">
        {index + 1}. {item.title}
      </Text>

      <Flex alignItems="center" gap="1rem" mb="1.6rem">
        <StatusCheckbox
          w="1.8rem"
          h="1.8rem"
          borderRadius={4}
          status={item?.status || DocumentStatusType.Incomplete}
          numberOfValue={valueCheckbox[index]?.numberOfCheckbox || 1}
          isDisabled={isDisabled}
          onChange={handleChangeValue({
            key: SubItemKey.STATUS,
            child: item,
            groupName: item.title,
          })}
        />
        <Text
          w="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {valueCheckbox[index]?.value || ""}
        </Text>
      </Flex>

      <Flex w="100%" mt="auto" gap=".8rem">
        <Text minWidth="80px" textAlign="left">
          処置内容
        </Text>
        <Textarea
          fontSize="1.4rem"
          minH="5rem"
          maxH="7rem"
          borderRadius={4}
          w="100%"
          rows={4}
          borderColor="#A3A3A3"
          placeholder="フリーテキスト"
          value={memo}
          onChange={(e) => {
            setMemo(e.target.value);
          }}
          onBlur={handleBlurValue({
            key: SubItemKey.MEMO,
            child: item,
            groupName: item.title,
          })}
          isDisabled={isDisabled}
        />
      </Flex>
      <AttachFile
        document={item}
        isOnline={isOnline}
        groupName={item.title}
        updateDocument={updateItemChild}
        isDisabled={isDisabled}
      />

      {/* dynamic field */}
      <DynamicFields
        type={TypeFieldDynamic.TYPE_ITEM}
        template={documentTemplate}
        documentItemSelected={item as DocumentItemDTO}
        insertDocumentItemLog={insertItemLog}
        onSaveDataForDocumentItem={handleSaveDynamicField}
      />
    </Box>
  );
};

export default memo(ItemChildren);

import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  SystemModeType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { Vector2, Vector3 } from "interfaces/models";
import { UserSetting } from "interfaces/models/user";
import throttle from "lodash/throttle";
import { getDocumentStatus } from "utils/document";
import {
  fitToViewByPosition,
  getCurrentViewer,
  isInsideSectionBox,
} from "utils/forge";
import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { calculatePositionOnSheet } from "utils/forge/forge2d";
import { logDev } from "utils/logs";
import { calculateCenterPoint } from "utils/vector";
import { createAreaLabel } from "./area";
import {
  DisplayDocumentSettings,
  DisplayTaskSettings,
  LabelClicked,
  MAX_INTERSECT_LABEL,
} from "./constant";
import { documentLabel } from "./document";
import { taskLabel } from "./task";
import { onLabelClick } from "./utils";

export interface NormalDisplayItem {
  id: string;
  tempId?: string;
  indexId?: number;
  title: string;
  originId?: string;
  subTitle?: string;
  displayOrder?: number;
  status?: string;
  categoryId?: string;
  showImage?: boolean;
  externalId?: string;
  position: Vector3 | THREE.Vector3;
  templateId?: string;
  isShowLabel?: boolean;
  unVisible?: boolean;
}

export interface AreaDisplayItem {
  id: `${string}/${string}`;
  tempId?: string;
  title: string;
  status: string;
  originId?: string;
  categoryId?: string;
  externalId?: string;
  externalIds: string[];
  templateId: string;
  isShowLabel?: boolean;
  items: NormalDisplayItem[];
}

export type DisplayItem = NormalDisplayItem | AreaDisplayItem;

export interface DisplayLabel {
  position: THREE.Vector3 | Vector3;
  id: string;
  label: JQuery<HTMLElement>;
  items: DisplayItem[];
  isSelected: boolean;
  isCombined: boolean;
  isAreaMode: boolean;
  isShowLabel?: boolean;
}

export interface ShowLabelsOptions {
  isShowTempLabel?: boolean;
  forceUpdate?: boolean;
  parentId?: string | null;
  displayMode?: string;
  sheetGuid?: string;
  selectedIds?: string[];
  requireAction?: () => Promise<unknown>;
}

export class CustomLabelExtension extends Autodesk.Viewing.Extension {
  data: { [key: string]: DisplayItem } = {};
  private tempLabels: { [id: string]: JQuery<HTMLElement> } = {};
  // private tempElement: { [id: string]: HTMLElement | undefined } = {};
  private systemMode: SystemModeType;
  private selectedIds: string[] = [];
  private shouldUpdateData: boolean = true; // the showLabels() only can update data when this field == true
  private labelSize: {
    [key: string]: Vector2 & { width: number; height: number };
  } = {};
  private settings: UserSetting = {} as UserSetting;

  private parentId: string | null = null;
  private displayMode: string | null = null;
  private sheetGuid: string | null = null;
  private viewerWrapper: JQuery<HTMLElement> | undefined = undefined;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
    super(viewer, options);
    this.setSettings(options.settings);
    this.systemMode = options.systemMode || SystemModeType.Task;
  }

  private calculateAbsolutePosition(position: Vector3 | THREE.Vector3) {
    const result = this.viewer.worldToClient(
      this.viewer.model.is2d()
        ? calculatePositionOnSheet(position)
        : new THREE.Vector3(position.x, position.y, position.z)
    );

    return result;
  }

  updateIconsCallback = throttle(() => {
    this.updateLabels();
    // It's not smooth if greater 20ms
  }, 20);

  load() {
    this.viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.updateIconsCallback
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.CUTPLANES_CHANGE_EVENT,
      this.updateIconsCallback
    );

    return true;
  }

  unload() {
    this.viewer.removeEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.updateIconsCallback
    );
    this.viewer.removeEventListener(
      Autodesk.Viewing.CUTPLANES_CHANGE_EVENT,
      this.updateIconsCallback
    );
    logDev("label extension unloaded");
    this.clear();

    return true;
  }

  setShouldUpdateData(value: boolean) {
    this.shouldUpdateData = value;
  }

  setSettings(settings: UserSetting) {
    if (
      Object.keys(this.settings || {}).some(
        (key) =>
          key.includes("display") &&
          (this.settings as any)[key] !== (settings as any)[key]
      )
    ) {
      this.settings = { ...settings };
      this.updateLabelSettings();

      return;
    }
    this.settings = { ...settings };
  }

  private updateLabelSettings() {
    const settings =
      this.systemMode === SystemModeType.Task
        ? DisplayTaskSettings
        : DisplayDocumentSettings;
    Object.keys(settings).forEach((key) => {
      $(
        `.label-item-${(settings as any)[key].class}:not(.area-label-item)`
      ).css("display", (this.settings as any)[key] ? "flex" : "none");

      // For area
      $(`.label-item-${(settings as any)[key].areaClass}`).css(
        "display",
        (this.settings as any)[key] ? "flex" : "none"
      );
    });
  }

  public select(ids: string[]) {
    this.selectedIds = ids;

    $("label.area-label,.area-combined-child-label-wrapper").removeClass(
      "selected-label"
    );
    ids.forEach((id) => {
      $(`label[data-id="${id}"]`).addClass("selected-label");
    });
    this.updateLabels(true);
  }

  clearSelection(isClearChild: boolean = true, isClearId: boolean = true) {
    if (isClearId) this.selectedIds = [];
    if (isClearChild) {
      // $(".combined-label .children .group-children").remove();
      $(".combined-label .children").html("");
    }

    const labels = $(`#${this.viewer.clientContainer.id} label`);
    for (const item of labels) {
      const $label = $(item);
      const isCombinedLabel = $label.hasClass("combined-label");

      if (isCombinedLabel) {
        const ids = $label.attr("data-ids")?.split(",");

        if (ids?.length && ids.every((id) => !this.selectedIds.includes(id))) {
          $label.removeClass("selected-label");
        }
      } else {
        const id = $label.data("id");
        if (!this.selectedIds.includes(id)) {
          $label.removeClass("selected-label");
        }
      }
    }
  }

  updateLabel = (id: string, newItem: DisplayItem) => {
    this.shouldUpdateData = false;
    const labelId = newItem?.tempId ?? id;
    const $label = this.tempLabels?.[labelId];
    if (!$label) {
      return;
    }

    const tempId = newItem?.tempId;
    delete newItem?.tempId;
    this.data[id] = newItem;

    if (tempId) {
      this.labelSize[id] = this.labelSize[tempId];
      delete this.tempLabels?.[tempId];
      delete this.data?.[tempId];
      delete this.labelSize?.[tempId];
    }

    const isSelected = !!$label?.hasClass("selected-label");
    const label =
      this.systemMode === SystemModeType.Task
        ? taskLabel(newItem as NormalDisplayItem, { isSelected })
        : documentLabel(newItem as NormalDisplayItem, { isSelected });
    const classList = label.attr("class");
    if (classList) {
      $label.attr("class", classList);
    }
    $label.html(label.html());

    this.tempLabels[id] = $label;
    this.updateLabels();
  };

  public updateAreaLabel(
    item: AreaDisplayItem,
    documentCategory: DocumentCategoryDTO,
    documentItem?: DocumentItemDTO
  ) {
    const originalItem = this.data[item.id] as AreaDisplayItem;
    originalItem.title = documentCategory?.title || "-";
    const $viewer = this.getViewerWrapper();
    const statusColor =
      MapDocumentCategoryStatusTypeColor[
        (documentCategory.status ||
          DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
      ];

    const $areaLabel = $viewer.find(`.area-label[data-id='${item.id}']`);
    if ($areaLabel) {
      $areaLabel
        .find(".area-title .text-ellipsis, .area-title .area-title-tooltip")
        .html(originalItem?.title || "");
      // Color to title and border
      $areaLabel.css({
        "--status-color": statusColor,
      });
    }

    // Color to doc title
    if (documentItem?.id) {
      $viewer
        .find(`.area-combined-child-label-wrapper[data-id=${documentItem.id}]`)
        .css({
          "--status-color": getDocumentStatus(documentItem.status).bgColor,
        });
    }

    // Replace data[item.id] to update combied;
    this.data[item.id] = {
      ...originalItem,
      status: documentCategory.status as string,
      items: originalItem.items.map((it) => {
        if (it.id === documentItem?.id) {
          return {
            ...it,
            status: documentItem.status as string,
          };
        }

        return it;
      }),
    };
  }

  private createLabel = (item: NormalDisplayItem, isSelected: boolean) => {
    return this.systemMode === SystemModeType.Task
      ? taskLabel(item, { isSelected })
      : documentLabel(item, { isSelected });
  };

  public clear() {
    $(
      `#${this.viewer.clientContainer.id} div.adsk-viewing-viewer .canvas-wrap label.combined-label, #${this.viewer.clientContainer.id} div.adsk-viewing-viewer .canvas-wrap label.markup`
    ).remove();
    this.parentId = null;
  }

  public removeTempLabels(data: DisplayItem[]) {
    data.forEach((item) => {
      delete this.tempLabels?.[item.id];
      delete this.data?.[item.id];
      delete this.labelSize?.[item.id];
    });

    this.calculateLabelSize();
    this.clear();
    this.updateLabels();
  }

  // init labels
  public async showLabels({
    mode,
    data,
    options,
  }: {
    mode?: SystemModeType;
    data?: DisplayItem[];
    options?: ShowLabelsOptions;
  }) {
    const isShowTempLabel = options?.isShowTempLabel ?? false;
    if (!isShowTempLabel) {
      const forceUpdate = options?.forceUpdate ?? false;
      if (!this.shouldUpdateData && !forceUpdate) {
        this.shouldUpdateData = true;

        return;
      }

      if (
        options?.parentId &&
        this.parentId == options?.parentId &&
        this.displayMode == options?.displayMode &&
        this.sheetGuid == options?.sheetGuid &&
        !forceUpdate
      ) {
        return;
      }

      this.parentId = options?.parentId ?? null;
      this.displayMode = options?.displayMode ?? null;
      this.sheetGuid = options?.sheetGuid ?? null;

      if (options?.requireAction) {
        await options.requireAction();
      }
    }

    if (mode) {
      this.systemMode = mode;
    }

    if (data) {
      if (!isShowTempLabel) {
        this.data = {};
      }
      data.forEach((item) => {
        this.data[item.id] = item;
        this.data[item.id].isShowLabel = true;
      });
    }
    const $viewer = $(
      `#${this.viewer.clientContainer.id} div.adsk-viewing-viewer .canvas-wrap`
    );
    $viewer.find(`label.combined-label, label.markup`).remove();

    if (!isShowTempLabel) {
      this.tempLabels = {};
    }
    Object.values(this.data).forEach((item) => {
      let $label;
      if ("position" in item && item.position) {
        const isSelected = this.selectedIds.includes(item.id);
        $label = this.createLabel(item, isSelected);
      } else if ("externalIds" in item) {
        $label = createAreaLabel(item, {
          shouldBeHandleTooltip: false,
          settings: this.settings,
          selectedIds: [
            ...(this.selectedIds || []),
            ...(options?.selectedIds || []),
          ].filter((i) => !!i),
        });
      }
      if ($label) {
        this.tempLabels[item.id] = $label;
        $viewer.append($label);
      }
    });

    this.updateLabelSettings();
    this.calculateLabelSize();
    this.clear();
    this.updateLabels();
  }

  private calculateLabelSize() {
    const labels = $(
      `#${this.viewer.clientContainer.id} div.adsk-viewing-viewer .canvas-wrap label.update`
    );
    // update all
    const mapItemId = new Map();
    for (let i = 0; i < labels.length; i++) {
      const $label = $(labels[i]);
      const labelId = `${$label.data("id")}`;
      if (this.data[labelId]) {
        $label.css("display", "flex");
        mapItemId.set(labelId, $label);
      }
    }
    // get all
    for (const [labelId, $label] of mapItemId) {
      this.labelSize[labelId] = {
        width: $label.width() || 0,
        height: $label.height() || 0,
        x: $label[0].offsetWidth,
        y: $label[0].offsetHeight,
      };
    }
  }

  private checkLabelIsOutside({
    displayLabel,
    viewerBounding,
  }: {
    displayLabel: DisplayLabel;
    viewerBounding: DOMRect | undefined;
  }) {
    let isOutside = false;
    const { left, top } = ((item: DisplayLabel) => {
      if (item.isAreaMode) {
        // Center Area label
        const left =
          item.position?.x - (this.labelSize[item.items[0].id].width ?? 0) / 2;
        const top =
          item.position?.y - (this.labelSize[item.items[0].id].height ?? 0) / 2;

        return {
          left,
          top,
        };
      }
      const left = item.position?.x;

      const top = item.position?.y - this.labelSize[item.items[0].id]?.y - 10;

      return {
        left,
        top,
      };
    })(displayLabel);

    if (viewerBounding) {
      isOutside =
        top + this.labelSize[displayLabel.items[0].id].height < 0 ||
        top > viewerBounding.height ||
        left + this.labelSize[displayLabel.items[0].id].width < 0 ||
        left > viewerBounding.width;
    }

    return { left, top, isOutside };
  }

  private checkLabelsIsIntersect({
    displayItem,
    point,
    currentLabelId,
  }: {
    displayItem: DisplayLabel;
    point: THREE.Vector3;
    currentLabelId: string;
  }) {
    const displayItemSize = {
      x: this.labelSize[displayItem.items[0].id]?.x,
      y: this.labelSize[displayItem.items[0].id]?.y,
    };

    const intersectSize = {
      x:
        this.labelSize[currentLabelId]?.x +
        displayItemSize?.x -
        (Math.max(
          point?.x + this.labelSize[currentLabelId]?.x,
          displayItem.position?.x + displayItemSize?.x
        ) -
          Math.min(point?.x, displayItem.position?.x)),
      y:
        this.labelSize[currentLabelId]?.y +
        displayItemSize?.y -
        (Math.max(
          point?.y + this.labelSize[currentLabelId]?.y,
          displayItem.position?.y + displayItemSize?.y
        ) -
          Math.min(point?.y, displayItem.position?.y)),
    };
    intersectSize.x = intersectSize?.x > 0 ? intersectSize?.x : 0;
    intersectSize.y = intersectSize?.y > 0 ? intersectSize?.y : 0;

    return intersectSize?.x * intersectSize?.y > MAX_INTERSECT_LABEL;
  }

  getViewerWrapper() {
    if (!this.viewerWrapper || !this.viewerWrapper.length) {
      this.viewerWrapper = $(
        `#${this.viewer.clientContainer.id} div.adsk-viewing-viewer .canvas-wrap`
      );
    }

    return this.viewerWrapper;
  }

  // update position, combine label when view's changed
  public updateLabels(hasNotRemoveCombinedLabel?: boolean) {
    if (!Object.values(this.data)?.length || !this.viewer.model) {
      return;
    }
    // clean screen before update
    const $viewer = this.getViewerWrapper();
    const viewerBounding = $viewer.get(0)?.getBoundingClientRect();
    if (!hasNotRemoveCombinedLabel) {
      $viewer?.find(`label.combined-label`).remove();
    }
    // list label display on screen (label of item, combined label)
    const displayList: DisplayLabel[] = [];
    const areaExtension = getAreaExtension();
    for (const item of Object.values(this.data)) {
      const isAreaMode = item && "externalIds" in item;

      const position = isAreaMode
        ? areaExtension?.getPosition(item.id)
        : item?.position;

      if (!position) {
        continue;
      }

      // if item is task or document item
      const isVisible = isInsideSectionBox(
        new THREE.Vector3(position.x, position.y, position.z)
      );

      if (!isVisible) {
        continue;
      }
      const labelId = item.id;
      // only check if element if visible
      const point = isAreaMode
        ? this.viewer.worldToClient(
            new THREE.Vector3(position.x, position.y, position.z)
          )
        : this.calculateAbsolutePosition(position);

      // combine labels if they are close and have the same type
      const mapIsShowLabelById: { [id: string]: boolean } = {};
      for (let j = 0; j < displayList.length; j++) {
        const displayItem = displayList[j];
        const isIntersect = this.checkLabelsIsIntersect({
          displayItem,
          point,
          currentLabelId: labelId,
        });
        mapIsShowLabelById[displayItem.id] = !isIntersect;

        if (!isIntersect) {
          continue;
        }

        displayItem.position = calculateCenterPoint(
          point,
          displayItem.position
        );
        // displayItem?.label?.remove();
        displayItem.isShowLabel = true;
        displayItem.items.push(item);
        const isSelected =
          this.selectedIds.includes(item.id) || item?.originId
            ? this.selectedIds.includes(item.originId!)
            : false;
        const isSelectedCombinedLabel = displayItem.items.some((i) =>
          this.selectedIds.includes(i.id)
        );
        if (isSelected || isSelectedCombinedLabel) {
          displayItem.isSelected = true;
        }

        break;
      }

      const $label = this.tempLabels?.[item.id];
      const element = $label?.get(0);
      const displayLabel = {
        id: item.id,
        position: point,
        label: $label as any,
        isShowLabel: mapIsShowLabelById?.[item.id] ?? true,
        items: [item],
        isSelected:
          this.selectedIds.includes(item.id) ||
          (item?.originId ? this.selectedIds.includes(item.originId!) : false),
        isCombined: false,
        isAreaMode,
      };
      const { isOutside } = this.checkLabelIsOutside({
        displayLabel: displayLabel,
        viewerBounding,
      });

      if (isOutside) {
        displayLabel.isShowLabel = false;
        displayLabel.label.remove();
      } else {
        displayList.push(displayLabel);
        if (element && !$viewer.has(element).length && $label) {
          $viewer.append($label);
        }
      }
    }

    // update position of displaying label
    for (const item of displayList) {
      item?.label?.off("click");

      if (!item.isShowLabel) {
        item?.label?.remove();

        return;
      }

      if (!item.isAreaMode) {
        item?.label?.on("click", (e) => {
          e.stopPropagation();
          e.stopImmediatePropagation();
          onLabelClick(item.items?.[0], this.systemMode, {
            isLabelSingle: true,
          });
        });
      }

      if (item.isAreaMode) {
        const $title = item.label.find(".area-title");
        $title?.off("click");
        $title?.on("click", (e) => {
          e.stopPropagation();
          e.stopImmediatePropagation();
          getCurrentViewer()?.dispatchEvent({
            type: LabelClicked,
            payload: {
              item: item?.items?.[0],
              systemMode: SystemModeType.Document,
            },
          });
        });
        const areaItem: AreaDisplayItem = item.items?.[0] as any;
        areaItem.items.forEach((doc) => {
          const $child = item.label.find(
            `.area-combined-child-label-wrapper[data-id="${doc.id}"]`
          );
          $child?.off("click");
          $child?.on("click", () => {
            getCurrentViewer()?.dispatchEvent({
              type: LabelClicked,
              payload: {
                item: doc,
                systemMode: SystemModeType.Document,
              },
            });
          });
        });
      }

      // calculate position
      const { left, top } = this.checkLabelIsOutside({
        displayLabel: item,
        viewerBounding,
      });

      item.label.css("left", left);
      item.label.css("top", top);

      if (item.isSelected) {
        item.label.addClass("selected-label");
      } else {
        item.label.removeClass("selected-label");
      }
    }
  }

  getSelectedItems() {
    return this.selectedIds;
  }

  setSelectedItems(ids: string[]) {
    return (this.selectedIds = ids);
  }

  public zoomToLabel(id: string) {
    const item = this.data[id] as NormalDisplayItem;
    if (!item || !item.position) return;

    const lengthValue = 5; // 5m
    const fromUnit = "m";
    const toUnit = "ft";
    const calibrationFactor = 1;
    const type = "length";
    const convertedLength = Autodesk.Viewing.Private.convertUnits(
      fromUnit,
      toUnit,
      calibrationFactor,
      lengthValue,
      type
    );
    const convertedVector = new THREE.Vector3(
      convertedLength,
      convertedLength,
      convertedLength
    );
    fitToViewByPosition(item.position, false, undefined, convertedVector);
  }

  static register = () => {
    Autodesk.Viewing.theExtensionManager.registerExtension(
      "CustomLabelExtension",
      CustomLabelExtension
    );
  };
}

export const clearLabelAndForgeView = () => {
  const viewer = getCurrentViewer();
  viewer?.select(undefined);
  clearSelectedLabel();
  getAreaExtension()?.clearSelection();
};

export const clearSelectedLabel = (
  isClearChild: boolean = true,
  isClearId: boolean = true
) => {
  const ext = getCurrentViewer()?.getExtension(
    "CustomLabelExtension"
  ) as CustomLabelExtension;
  ext?.clearSelection(isClearChild, isClearId);
};

export const selectLabel = (ids: (string | undefined)[]) => {
  clearSelectedLabel(false);
  const ext = getCurrentViewer()?.getExtension(
    "CustomLabelExtension"
  ) as CustomLabelExtension;

  ext?.select(ids.filter((id) => !!id) as string[]);
};

export const updateLabel = (id: string | undefined, newItem: DisplayItem) => {
  if (!id) {
    return;
  }
  const ext = getCurrentViewer()?.getExtension(
    "CustomLabelExtension"
  ) as CustomLabelExtension;
  ext?.updateLabel(id, newItem);
};

export const getLabelExtension = () => {
  return getCurrentViewer()?.getExtension(
    "CustomLabelExtension"
  ) as CustomLabelExtension;
};

import { bimFileApi } from "apiClient/v2";
import base64 from "base-64";
import {
  AUTODESK_DB_RESOURCE,
  PROJECT_RESOURCE,
  SYNC_PROJECT_BIM_FILES,
} from "constants/cache";
import { ItemBIMType } from "constants/enum";
import { FETCH_FAILED } from "constants/error";
import { Level } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import JSZip from "jszip";
import { retryFetch } from "../fetch";
import { getForgeToken } from "../forge";
import { getLocalStorage, setLocalStorage } from "../storage";

const AUTODESK_F2D_RESOURCE = ["manifest.json.gz", "metadata.json.gz"];

export const getUrlsOfModel = async (project: DataProjectModel) => {
  const credentials = await getForgeToken();
  const bimFileId = project.defaultBimPathId?.split("/").pop() || "";
  if (!bimFileId) {
    return [] as string[];
  }
  const base64BimUrn = base64.encode(decodeURIComponent(bimFileId));
  const encodedOrigin = encodeURIComponent(document.location.origin);
  const levelData = project?.levelData || {};
  const levels = (
    Object.values(levelData).map((value) => value) as Level[]
  ).filter((level) => !!level.guid);
  const levelsNotSheets = levels.filter((level) => !level.sheets?.length);
  const sheets = levels
    .map((level) => level.sheets?.map((sheet) => [sheet.guid, sheet.name]))
    .flat(2)
    .filter((item) => !!item);
  const keysToCache = new Set(
    sheets.concat(
      levelsNotSheets
        .map((level) => [
          level.guid,
          level.label,
          encodeURIComponent(level.label || ""),
        ])
        .flat(1)
    )
  );
  const urls: string[] = [];

  urls.push(
    `https://cdn.derivative.autodesk.com/derivativeservice/v2/manifest/${encodeURIComponent(
      base64BimUrn
    )}?domain=${encodedOrigin}`
  );
  const manifest = await (
    await retryFetch(
      `https://developer.api.autodesk.com/modelderivative/v2/designdata/${base64BimUrn.replace(
        "/",
        "_"
      )}/manifest`,
      { headers: { Authorization: `Bearer ${credentials.accessToken}` } }
    )
  )?.json();

  if (!manifest) {
    throw new Error(FETCH_FAILED);
  }

  const getUrns = async (data: any) => {
    const decoder = new TextDecoder();
    const zip = new JSZip();

    if (!data) {
      return;
    }

    if (
      data?.type === ItemBIMType.GEOMETRY &&
      data?.hasThumbnail === "true" &&
      ["3d", "2d"].includes(data?.role) &&
      !keysToCache.has(data?.guid || "")
    ) {
      return;
    }

    const requests: Promise<any>[] = [];
    if (data.derivatives) {
      data.derivatives.forEach((childObj: any) =>
        requests.push(getUrns(childObj))
      );

      return await Promise.all(requests);
    }

    if (data.urn) {
      const basePath = `${data.urn.slice(0, data.urn.lastIndexOf("/"))}`;
      switch (data.mime) {
        case "application/autodesk-db":
          AUTODESK_DB_RESOURCE.forEach((resource) => {
            const resourceUrn = `${basePath}/${resource}`;
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              resourceUrn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          });
          break;
        case "application/autodesk-f2d":
          urls.push(
            `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`
          );

          AUTODESK_F2D_RESOURCE.forEach((resource) => {
            const resourceUrn = `${basePath}/${resource}`;
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              resourceUrn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          });
          break;
        case "application/autodesk-svf":
          urls.push(
            `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`
          );

          const fetchRes = await retryFetch(
            `https://developer.api.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}`,
            {
              headers: { Authorization: `Bearer ${credentials.accessToken}` },
            }
          );

          if (!fetchRes) {
            break;
          }

          const res = await fetchRes.arrayBuffer();
          const pack = await zip.loadAsync(res, {
            checkCRC32: true,
            base64: false,
          });

          const manifestData = await pack
            .file("manifest.json")
            ?.async("arraybuffer");

          if (manifestData) {
            const manifest = JSON.parse(decoder.decode(manifestData));
            if (manifest.assets) {
              const uris = manifest.assets
                .map((asset: any) => asset.URI)
                .filter(
                  (uri: string) =>
                    uri.indexOf("embed:/") === -1 &&
                    !AUTODESK_DB_RESOURCE.some((resource) =>
                      uri.includes(resource)
                    )
                )
                .map(
                  (uri: string) =>
                    `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
                      `${basePath}/${uri}`
                    )}?domain=${encodedOrigin}`
                );

              urls.push(...uris);
            }
          }
          break;

        case "application/json":
          if (data.urn.includes("AECModelData")) {
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          }

          break;

        default:
          if (
            data.urn &&
            !data.urn.includes(".sdb") &&
            [...keysToCache].some((key) => data.urn.includes(key))
          ) {
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          }
          break;
      }
    }

    if (data.children) {
      data.children.forEach((childObj: any) =>
        requests.push(getUrns(childObj))
      );

      return await Promise.all(requests);
    }
  };

  await getUrns(manifest);

  return urls;
};

export const setSyncProjectBimfile = (bimFileId: string) => {
  const currentSyncProjectBimFiles = JSON.parse(
    getLocalStorage(SYNC_PROJECT_BIM_FILES, null) || "[]"
  );
  const syncProjectBimFiles = new Set([
    ...currentSyncProjectBimFiles,
    bimFileId,
  ]);
  setLocalStorage(SYNC_PROJECT_BIM_FILES, Array.from(syncProjectBimFiles));
};

export const getResourceCachedOfProject = async (
  currentBimFileId: string,
  syncDataTime: Date
) => {
  const res = await bimFileApi.getLastUpdatedResource({
    bimFileId: currentBimFileId,
    localTime: new Date(syncDataTime).toISOString(),
  });

  if (!res?.data?.length) {
    return;
  }

  let excludeCachedUrls: string[] = [];

  res.data.forEach((item) => {
    const resource = item.resource;

    switch (resource) {
      case "sub_items":
      case "document_items":
        excludeCachedUrls.push(PROJECT_RESOURCE.documentItems);
        break;

      case "families":
        excludeCachedUrls.push(PROJECT_RESOURCE.families);
        break;

      case "task_comments":
      case "tasks":
        excludeCachedUrls.push(PROJECT_RESOURCE.tasks);
        break;

      case "blackboards":
      case "document_categories":
        excludeCachedUrls.push(PROJECT_RESOURCE.documentCategories);
        excludeCachedUrls.push(PROJECT_RESOURCE.blackboardTemplate);
        break;

      case "task_sheet_templates":
        excludeCachedUrls.push(PROJECT_RESOURCE.taskSheetTemplates);
        break;

      case "document_templates":
        excludeCachedUrls.push(PROJECT_RESOURCE.documentTemplate);
        break;

      case "document_groups":
        excludeCachedUrls.push(PROJECT_RESOURCE.documentGroup);
        break;

      case "task_types":
        excludeCachedUrls.push(PROJECT_RESOURCE.taskTypes);
        break;

      case "users":
        excludeCachedUrls.push(PROJECT_RESOURCE.listUser);
        excludeCachedUrls.push(PROJECT_RESOURCE.userSetting);
        break;

      case "project_bim_file":
        excludeCachedUrls.push(PROJECT_RESOURCE.project);
        excludeCachedUrls.push(PROJECT_RESOURCE.projectDetail);
        excludeCachedUrls.push(PROJECT_RESOURCE.projectBimFileDetail);
        excludeCachedUrls.push(PROJECT_RESOURCE.partnerCompanies);
        break;

      default:
        if (PROJECT_RESOURCE?.[resource as keyof typeof PROJECT_RESOURCE]) {
          excludeCachedUrls.push(
            PROJECT_RESOURCE?.[resource as keyof typeof PROJECT_RESOURCE]
          );
        }
        break;
    }
  });

  excludeCachedUrls = [...new Set(excludeCachedUrls)];
  const cachedUrls = Object.values(PROJECT_RESOURCE).filter(
    (res) => !excludeCachedUrls.includes(res)
  );

  return cachedUrls;
};

import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";

export function removeDocItem(
  documentCategories: DocumentCategoryDTO[],
  deleteItemIds: string[]
) {
  const categories: DocumentCategoryDTO[] = [];
  documentCategories.forEach((cate, index) => {
    const newCate = { ...cate };
    newCate.documentItems = cate.documentItems?.filter(
      (item) => !deleteItemIds.includes(item.id)
    );

    newCate.itemIds = cate.itemIds?.filter(
      (item) => !deleteItemIds.includes(item)
    );

    newCate.selectedExternalIds = cate.selectedExternalIds?.filter(
      (item) => !deleteItemIds.includes(item)
    );

    if (newCate.itemIds?.length !== documentCategories[index].itemIds?.length) {
      categories.push(newCate);
    }
  });

  return categories;
}

import { Box } from "@chakra-ui/react";
import React, {
  CSSProperties,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from "react-virtualized";
import "react-virtualized/styles.css";

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 50,
});

interface VirutalScrollProps {
  children: any;
  style?: CSSProperties | undefined;
  scale?: number;
  containerRef: React.MutableRefObject<HTMLDivElement>;
}

const VirutalScroll = React.forwardRef(
  (
    { children, style, scale = 1, containerRef }: VirutalScrollProps,
    ref: any
  ) => {
    const listRef = useRef<any>();

    const components = useMemo(
      () => React.Children.toArray(children),
      [children]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          scrollToRow: (page: number) => {
            listRef.current.scrollToRow(page);
          },
          reset: () => {
            cache.clearAll();
          },
        };
      },
      []
    );

    const rowRendererWithVS = useCallback(
      ({ index, style, key, parent }: any) => {
        return (
          <CellMeasurer
            key={key}
            parent={parent}
            cache={cache}
            columnIndex={0}
            rowIndex={index}
          >
            <Box style={style} page-review={index}>
              {components[index]}
            </Box>
          </CellMeasurer>
        );
      },
      [components]
    );

    useLayoutEffect(() => {
      cache.clearAll();
      setTimeout(() => {
        const top = containerRef?.current.scrollTop;
        /**
         * Scroll to virtual scroll recalculate height
         */
        containerRef?.current.scrollTo({
          top: top === 0 ? top + 0.5 : top - 0.5,
          behavior: "smooth",
        });
      }, 50);
    }, [scale, containerRef]);

    return (
      <Box style={style}>
        <WindowScroller
          scrollElement={containerRef?.current}
          onResize={() => cache.clearAll()}
          onScroll={({ scrollTop }) =>
            listRef.current.scrollToPosition(scrollTop)
          }
        >
          {({ height, isScrolling, registerChild, onChildScroll }: any) => {
            return (
              <AutoSizer disableHeight>
                {({ width }) => {
                  return (
                    <div ref={registerChild as any}>
                      <List
                        autoHeight
                        ref={listRef}
                        width={width}
                        height={height ?? 0}
                        rowCount={components.length}
                        rowHeight={cache.rowHeight}
                        overscanRowCount={3}
                        deferredMeasurementCache={cache}
                        rowRenderer={rowRendererWithVS}
                        onScroll={onChildScroll}
                        isScrolling={isScrolling}
                        id="preview-virtual-scroll"
                      />
                    </div>
                  );
                }}
              </AutoSizer>
            );
          }}
        </WindowScroller>
      </Box>
    );
  }
);

export default React.memo(VirutalScroll);

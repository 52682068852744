import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconFilterSetting(props: IconProps) {
  return (
    <Icon
      width="2.5rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path
        fill="#737373"
        fillRule="evenodd"
        d="M3.82 6h3.26c.41 1.16 1.51 2 2.82 2s2.4-.84 2.82-2h9.11c.55 0 1-.45 1-1s-.45-1-1-1h-9.12a2.99 2.99 0 0 0-2.82-2c-1.31 0-2.4.84-2.82 2H3.82c-.55 0-1 .45-1 1s.45 1 1 1m6.07-2c.49 0 .87.36.96.82l-.018.072A.4.4 0 0 0 10.81 5c0 .036.011.072.022.108l.018.072c-.09.46-.48.82-.96.82-.55 0-1-.45-1-1s.45-1 1-1m11.93 7h-1.11a2.99 2.99 0 0 0-2.82-2c-1.31 0-2.4.84-2.82 2H3.82c-.55 0-1 .45-1 1s.45 1 1 1h11.26c.41 1.16 1.51 2 2.82 2s2.4-.84 2.82-2h1.11c.55 0 1-.45 1-1s-.45-1-1-1zm-3.93 2c-.55 0-1-.45-1-1s.45-1 1-1c.49 0 .87.36.96.82q-.006.036-.018.072c-.01.036-.022.072-.022.108s.011.072.022.108q.011.036.018.072c-.09.46-.48.82-.96.82m-7.18 5h11.12c.55 0 1 .45 1 1s-.45 1-1 1H10.72c-.42 1.16-1.51 2-2.82 2a2.99 2.99 0 0 1-2.82-2H3.82c-.55 0-1-.45-1-1s.45-1 1-1h1.25c.42-1.16 1.51-2 2.82-2s2.41.84 2.82 2m-3.82 1c0 .55.45 1 1 1 .48 0 .87-.36.96-.82q-.006-.036-.018-.072A.4.4 0 0 1 8.81 19c0-.036.011-.072.022-.108q.011-.036.018-.072c-.09-.46-.47-.82-.96-.82-.55 0-1 .45-1 1"
        clipRule="evenodd"
      />
    </Icon>
  );
}

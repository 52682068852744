import { useRef, useState } from "react";
import { MenuPlacement } from "react-select/dist/declarations/src";

const MAX_MENU_HEIGHT = 200;
enum DIRECTION {
  BOTTOM = "bottom",
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
}

const useDropdownDirection = () => {
  const initFormRef = {
    partnerCompanyEl: null,
    confirmedUserEl: null,
    deadlineEl: null,
    confirmedDateTimeEl: null,
  };

  const formRef = useRef<{
    confirmedUserEl: HTMLDivElement | null;
    partnerCompanyEl: HTMLDivElement | null;
    confirmedDateTimeEl: HTMLDivElement | null;
    deadlineEl: HTMLDivElement | null;
  }>(initFormRef);

  const [deadlineDirection, setDeadlineDirection] = useState<MenuPlacement>(
    DIRECTION.TOP
  );
  const [confirmedUserDirection, setConfirmedUserDirection] =
    useState<MenuPlacement>(DIRECTION.TOP);
  const [confirmedDateTimeDirection, setConfirmedDateTimeDirection] =
    useState<MenuPlacement>(DIRECTION.TOP);
  const scrollTopRef = useRef<number>(0);
  const getDirectionElm = (
    containerScrollTop: number,
    elm: HTMLDivElement | null
  ) => {
    const offsetTopElm = elm?.offsetTop || 0;

    return containerScrollTop + MAX_MENU_HEIGHT > offsetTopElm
      ? DIRECTION.BOTTOM
      : DIRECTION.TOP;
  };

  const calculateScrollTop = (e: React.UIEvent<HTMLElement>) => {
    const containerEl = e.target as HTMLElement;
    const scrollTop = containerEl?.scrollTop;
    if (!formRef.current?.partnerCompanyEl || !containerEl) {
      return;
    }
    setConfirmedUserDirection(
      getDirectionElm(scrollTop, formRef.current?.confirmedUserEl)
    );
    scrollTopRef.current = scrollTop;
  };

  const checkDropdownDirection = () => {
    const scrollTop = scrollTopRef.current;

    if (!scrollTop) {
      return;
    }
    // deadline
    setDeadlineDirection(
      getDirectionElm(scrollTop, formRef.current?.deadlineEl)
    );
    // confirm date
    setConfirmedDateTimeDirection(
      getDirectionElm(scrollTop, formRef.current?.confirmedDateTimeEl)
    );
  };

  return {
    confirmedUserDirection,
    confirmedDateTimeDirection,
    deadlineDirection,
    formRef,
    calculateScrollTop,
    checkDropdownDirection,
  };
};

export default useDropdownDirection;

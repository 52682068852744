import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { taskApi } from "apiClient/v2";
import { message } from "components/base";
import DropdownHover from "components/DropdownHover";
import IconPinOnMap from "components/icon/IconPinOnMap";
import IconPlus from "components/icon/IconPlus";
import { SvgIcon } from "components/SvgIcon";
import IconPin from "components/task/IconPin";
import TaskInForgeViewer from "components/ui/TaskInForgeViewer";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIconStatus,
  SIZE_ICON_TASK_STATUS,
  SystemModeType,
  TASK_PRINT_MODE,
} from "constants/enum";
import { RIGHT_SIDEBAR_MODAL_CONTAINER_ID } from "constants/styleProps";
import { MessageType } from "constants/websocket";
import useFamilyInstance from "hooks/useFamilyInstance";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { WSMessage } from "interfaces/models/websocket";
import { addLogCreateTaskForDocItem, GetContentLog } from "models/dataLog";
import { handleSelectTask } from "models/task";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import useArea from "pages/forge-viewer/hooks/useArea";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { insertDocumentItemIdCreated } from "redux/documentSlice";
import {
  setCreateSelfInspectionTask,
  setSystemMode,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { setTasks, setTaskSelected } from "redux/taskSlice";
import { sortArrayByField } from "utils/array";
import { sleep, uuid } from "utils/common";
import { convertToUtcByOffsetLocal } from "utils/date";
import { getNameByContentType } from "utils/document";
import { selectDbIds } from "utils/forge";
import { getDbIdByExternalId, getExternalIdByDbId } from "utils/forge/data";
import {
  AreaMesh,
  getAreaExtension,
} from "utils/forge/extensions/area-extension";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { getStatusColor } from "utils/forge/extensions/custom-label/utils";
import { getPortalExtension } from "utils/forge/extensions/portal-extension";
import type ReactPanel from "utils/forge/extensions/react-panel";
import { checkPointInsideMesh } from "utils/forge/forge3d";
import { updateClickingTaskId } from "utils/task";
import { getLocalStorageUser } from "utils/user";
import Tasks from "../ItemTasks/Tasks";

interface Props extends BoxProps {
  documentItem?: DocumentItemDTO;
  documentCategorySelected?: DocumentCategoryDTO;
  clickInfo: ClickInfo | undefined;
  isOnline?: boolean;
  isDisabled?: boolean;

  sendMessageToCommonChannel: (message: WSMessage) => void;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  insertDocumentItemLog?: (params: GetContentLog) => Promise<void>;
}

const ItemTasksForSelfInspection = (props: Props) => {
  const {
    documentItem,
    documentCategorySelected,
    clickInfo,
    isOnline = true,
    isDisabled,
    setClickInfo,
    insertDocumentItemLog,
    sendMessageToCommonChannel,
    ...rest
  } = props;
  const { bimFileId } = useParams();
  const dropdownRef = useRef<any>();
  const dispatch = useDispatch();
  const rightSidebarContainerRef = useRef<any>(
    document.getElementById(RIGHT_SIDEBAR_MODAL_CONTAINER_ID)
  );
  const { socket } = useForgeViewerContext();
  const [isSaving, setSaving] = useState(false);
  const { familyInstances } = useFamilyInstance();

  const { levelSelected, isCreateSelfInspectionTask } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { tasks, taskTypes, isLoadingTaskType } = useSelector(
    (state: RootState) => state.task
  );
  const [isOpenCreateTask, setIsOpenCreateTask] = useBoolean();
  const [taskTypeId, setTaskTypeId] = useState<string | undefined>("");
  const [isAutoCreateTask, setIsAutoCreateTask] = useState(false);
  const dummyTaskRef = useRef<ReactPanel | null>(null);
  const { areas: neptuneAreas } = useArea({ isHandleSideEffect: false });

  const taskList = useMemo(() => {
    return sortArrayByField(
      tasks?.filter((c) => c.documentItemId === documentItem?.id) || [],
      "createdAt",
      true
    );
  }, [tasks, documentItem?.id]);

  const listTaskTypeSorted = useMemo(() => {
    return sortArrayByField(taskTypes || [], "createdAt", false);
  }, [taskTypes]);

  // remove dummyTask when close create task mode
  useEffect(() => {
    if (!isCreateSelfInspectionTask && dummyTaskRef.current) {
      // using setTimeout to fix bug "Attempted to synchronously unmount a root while React was already rendering"
      setTimeout(() => {
        selfRef.current.handleCloseTask();
      });
    }
  }, [isCreateSelfInspectionTask]);

  useEffect(() => {
    const element = document.getElementById(RIGHT_SIDEBAR_MODAL_CONTAINER_ID);
    if (element) {
      rightSidebarContainerRef.current = element;
    }
  }, []);

  useEffect(() => {
    if (!isAutoCreateTask || !taskTypeId) {
      return;
    }

    setIsAutoCreateTask(false);
    handleCreateTask(taskTypeId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoCreateTask, taskTypeId]);

  // Dummy task
  useEffect(
    () => {
      const portalExtension = getPortalExtension();
      const position = clickInfo?.forgeData?.position;
      if (
        !portalExtension ||
        !position ||
        isOpenCreateTask ||
        !isCreateSelfInspectionTask
      ) {
        return;
      }

      (async () => {
        const dbId = clickInfo?.forgeData?.dbId;
        const externalId = dbId ? (await getExternalIdByDbId(dbId)) || "" : "";
        const familyInstance = familyInstances?.[externalId];
        const spaces = (await getAreaExtension()?.getSpaces()) || [];
        const currentAreaIds = documentCategorySelected?.neptuneAreaIds || [];
        const currentAreas = neptuneAreas.filter((area) =>
          currentAreaIds.includes(area.id)
        );
        const externalIdsOfArea = currentAreas
          .map((area) => area.externalIds)
          .flat(1);
        const defaultPositionZ = Object.values(familyInstances).find((item) =>
          item.spaceIds?.some((id) => externalIdsOfArea.includes(id))
        )?.position?.z;

        const spaceIds = spaces
          .filter((space: AreaMesh) => {
            if (!familyInstance?.bounds && defaultPositionZ) {
              position.z = defaultPositionZ;
            }

            const scalePosition = {
              x: position.x * space.scale.x + space.position.x,
              y: position.y * space.scale.y + space.position.y,
              z: position.z * space.scale.z + space.position.z,
            } as any;

            if (familyInstance?.bounds) {
              const box = space.geometry?.boundingBox?.clone();
              box?.applyMatrix4(space.matrixWorld);

              return space && box?.containsPoint(scalePosition);
            }

            return !!space && !!checkPointInsideMesh(scalePosition, space);
          })
          .map((space) => space.externalId);

        const isInsideCurrentArea =
          currentAreas.some((area) =>
            area.externalIds.some((id) => spaceIds.includes(id))
          ) || !!documentCategorySelected?.allFloor;

        if (dummyTaskRef.current) {
          dummyTaskRef.current.setPosition(position);
        } else {
          if (!isInsideCurrentArea) {
            // need update logic check point in area
            // return message.warning("Task not in current area");
          }

          dummyTaskRef.current = portalExtension.createPortal(
            <TaskInForgeViewer title="-" />,
            {
              position: clickInfo?.forgeData?.position,
            }
          );
        }
        setIsOpenCreateTask.on();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps --  Do not add `documentCategorySelected` to deps
    [
      isCreateSelfInspectionTask,
      clickInfo,
      setIsOpenCreateTask,
      isOpenCreateTask,
      documentItem?.id,
      documentCategorySelected?.id,
    ]
  );

  useEffect(() => {
    if (dummyTaskRef.current && taskTypeId) {
      const title = taskTypes.find((item) => item.id === taskTypeId)?.title;

      dummyTaskRef.current.replaceContent(<TaskInForgeViewer title={title} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTypeId]);

  useEffect(() => {
    // TODO: Should not be trigger when the first click of doc item
    handleCloseTask({
      fitMesh: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCategorySelected?.id, documentItem?.id]);

  useEffect(() => {
    return () => {
      dispatch(setCreateSelfInspectionTask(false));
      dummyTaskRef.current?.uninitialize();
      dummyTaskRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickTask = async (task: TaskDTO) => {
    dispatch(setTaskSelected());
    dispatch(setSystemMode(SystemModeType.Task));
    updateClickingTaskId(task.id);
    await sleep(100);
    handleSelectTask(task, dispatch);
    // waiting for modal open for prevent double click
    await sleep(200);
    updateClickingTaskId("");
  };

  const handleOpenCreateTask = useCallback(() => {
    const areaExtension = getAreaExtension();
    if (!documentCategorySelected?.id) {
      return;
    }

    if (!!documentCategorySelected?.noArea) {
      return message.warning("Cannot create task");
    }

    if (isCreateSelfInspectionTask) {
      dispatch(setCreateSelfInspectionTask(false));
      areaExtension?.activateCreateTaskMode(documentCategorySelected, {
        active: false,
      });

      return;
    }

    const areas = documentCategorySelected?.neptuneAreaIds;
    if (!!areaExtension && !!areas?.length) {
      areaExtension.activateCreateTaskMode(documentCategorySelected, {
        active: true,
      });
    }

    dispatch(setCreateSelfInspectionTask(true));
  }, [dispatch, documentCategorySelected, isCreateSelfInspectionTask]);

  const handleCloseTask = useCallback<
    (options?: { fitMesh?: boolean }) => void
  >(
    (options) => {
      if (!documentCategorySelected?.id) {
        return;
      }

      const fitMesh = options?.fitMesh ?? true;
      // Clean task dummy
      if (dummyTaskRef.current) {
        dummyTaskRef.current.uninitialize();
        dummyTaskRef.current = null;
      }

      taskTypeId && setTaskTypeId("");
      setIsOpenCreateTask.off();
      dispatch(setCreateSelfInspectionTask(false));
      setClickInfo(undefined);
      getAreaExtension()?.activateCreateTaskMode(documentCategorySelected, {
        active: false,
        fitMesh,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps --  Do not add `documentCategorySelected` to deps
    [
      dispatch,
      documentCategorySelected?.id,
      setClickInfo,
      setIsOpenCreateTask,
      taskTypeId,
    ]
  );

  const handleCreateTask = useCallback(
    async (_taskTypeId = taskTypeId, title?: string) => {
      if (!documentCategorySelected?.id || !documentItem?.id) {
        return setIsAutoCreateTask(true);
      }

      // Clean task dummy
      if (dummyTaskRef.current) {
        dummyTaskRef.current.replaceContent(null);
        dummyTaskRef.current = null;
      }

      handleCloseTask();
      const position = clickInfo?.forgeData?.position;
      if (!bimFileId || !position) {
        return message.error("指摘の作成が失敗されました。");
      }

      const externalId =
        clickInfo?.forgeData?.externalId || documentItem.externalId || "";

      const taskType = taskTypes.find((i) => i.id === _taskTypeId);
      let taskTypeTitle = taskType?.title || "";
      if (!taskTypeTitle && title) {
        taskTypeTitle = title;
      }

      const requestId = uuid();
      const now = new Date();
      const currentDateUtc = new Date();
      convertToUtcByOffsetLocal(currentDateUtc);
      const newTask = {
        position,
        externalId,
        bimFileId,
        level: levelSelected?.guid ? levelSelected.label || "" : "",
        objectTypes: (familyInstances[externalId || ""]?.objectTypes ?? []).map(
          (family) => family.id
        ),
        indexId: 0,
        printMode: TASK_PRINT_MODE.PRINTABLE,
        status: InspectionItemType.Defect,
        dbId: getDbIdByExternalId(externalId || ""),
        documentItemId: documentItem.id,
        documentCategoryId: documentItem.documentCategoryId,
        templateId: documentItem.templateId,
        taskDate: currentDateUtc,
        createdAt: now,
        updatedAt: now,
        mapDisplayValueKey: { createTask: taskTypeTitle } as any,
        requestId,
        createdBy: getLocalStorageUser()?.id!,
      } as TaskDTO;

      if (_taskTypeId) {
        newTask.taskTypeId = _taskTypeId;
      }

      if (externalId) {
        selectDbIds([externalId], {
          color: MapInspectionItemColor[InspectionItemType.Defect],
        });
      }

      setTimeout(async () => {
        const res: TaskDTO = (await taskApi.createTask(newTask)).data;
        dispatch(insertDocumentItemIdCreated(res.id));

        if (res) {
          res.dbId = getDbIdByExternalId(res.externalId || "");
          res.templateId = documentItem.templateId;
          res.documentCategoryId = documentItem?.documentCategoryId;
          const newTasks = [...tasks, res] as TaskDTO[];
          dispatch(setTasks(newTasks));
          message.success("指摘が作成されました。");

          socket.addTask(res);

          await addLogCreateTaskForDocItem({
            taskTypeTitle,
            requestId,
            task: res,
            insertDocumentItemLog,
          });

          setTaskTypeId(undefined);
        }
      });
      getAreaExtension()?.activateCreateTaskMode(documentCategorySelected, {
        active: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps --  Do not add `documentCategorySelected` to deps
    [
      taskTypes,
      bimFileId,
      clickInfo?.forgeData?.externalId,
      clickInfo?.forgeData?.position,
      documentCategorySelected?.id,
      documentItem?.id,
      familyInstances,
      levelSelected?.guid,
      levelSelected.label,
      taskTypeId,
      tasks,
      dispatch,
      handleCloseTask,
      insertDocumentItemLog,
    ]
  );

  const selfRef = useRef<{
    handleCloseTask: typeof handleCloseTask;
  }>({ handleCloseTask });
  useEffect(() => {
    selfRef.current.handleCloseTask = handleCloseTask;
  }, [handleCloseTask]);

  const onSave = useCallback(async () => {
    if (taskTypeId) {
      return handleCreateTask(taskTypeId);
    }

    const response = await dropdownRef.current?.onAddTaskType();

    if (response?.id) {
      sendMessageToCommonChannel?.({
        type: MessageType.ADD_TASK_TYPE,
        data: response,
      });
    }

    handleCreateTask(response?.id, response?.title);
  }, [taskTypeId, handleCreateTask, sendMessageToCommonChannel]);

  return (
    <Box w="100%" mb="2rem" mt="3.2rem" {...rest}>
      <Text
        marginBottom="2rem"
        textAlign="left"
        fontWeight={"bold"}
        fontSize="1.6rem"
      >
        指摘
      </Text>
      {!!taskList?.length && (
        <>
          {taskList.map((task) => {
            const taskName = getNameByContentType(task.taskTypeId || "") || "-";

            return (
              <Tasks
                marginLeft={"5rem"}
                width="calc(100% - 5rem)"
                padding="0.5rem"
                marginBottom="0"
                marginTop="0"
                borderRadius={0}
                key={`${task.indexId}`}
                task={task}
                taskName={taskName}
                onClick={() => handleClickTask(task)}
                isDisabled={isDisabled}
                isLoading={isLoadingTaskType}
              />
            );
          })}
        </>
      )}
      {isOpenCreateTask ? (
        <Box my="1rem" marginLeft={"5rem"} width="calc(100% - 5rem)">
          <Flex flexDirection="row" alignItems="center">
            <IconPin status={InspectionItemType.Defect} />
            <DropdownHover
              isOnline={isOnline}
              options={listTaskTypeSorted}
              value={taskTypeId}
              parentRef={rightSidebarContainerRef}
              ref={dropdownRef}
              inputProps={{
                placeholder: "指摘を入力してください",
                backgroundColor: "white",
                _placeholder: {
                  color: "#A3A3A3",
                  opacity: 1,
                },
                isDisabled,
              }}
              isLoading={isLoadingTaskType}
              isAutoSave={false}
              isSelectToSave={false}
              onChange={(value) => {
                setTaskTypeId(value as string);
              }}
              onLoading={setSaving}
              sendMessageToCommonChannel={sendMessageToCommonChannel}
            />
          </Flex>
          {!isDisabled && (
            <ButtonGroup
              display="flex"
              justifyContent="flex-end"
              mt="1rem"
              variant="outline"
              spacing="6"
            >
              <Button onClick={() => handleCloseTask()}>キャンセル</Button>
              <Button onClick={onSave} variant="filled" isLoading={isSaving}>
                作成
              </Button>
            </ButtonGroup>
          )}
        </Box>
      ) : (
        <Box paddingLeft="calc(5.2rem + 4rem)">
          <Button
            mt="1rem"
            color={isCreateSelfInspectionTask ? "#009BE0" : "#737373"}
            lineHeight="1.6rem"
            fontSize="1.6rem"
            loadingText="写真を追加"
            variant="text"
            isDisabled={isDisabled}
            onClick={handleOpenCreateTask}
            leftIcon={<IconPlus width="2.4rem" height="2.4rem" />}
          >
            指摘を作成
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default memo(ItemTasksForSelfInspection);

import { Box, Button, Image } from "@chakra-ui/react";
import { memo, useCallback } from "react";

const ErrorFallback = () => {
  const handleRouteToHome = useCallback(() => {
    window.location.href = "/";
  }, []);

  return (
    <Box
      height="var(--app-height)"
      width="100%"
      bg="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Image
        objectFit="contain"
        height="50%"
        width="50%"
        src="/img/error-page.png"
      />

      <Button mt="2rem" variant="filled" onClick={handleRouteToHome}>
        ホームページへ
      </Button>
    </Box>
  );
};

export default memo(ErrorFallback);

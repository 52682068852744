import { Avatar, AvatarProps, Image, ImageProps } from "@chakra-ui/react";
import { defaultAvatarPath } from "constants/file";
import { useEffect, useState } from "react";
import { withPresignedUrl } from "./HOC/presignedUrl";

const AvatarPresigned = withPresignedUrl(Avatar);

export default function AvatarUser(
  props: (AvatarProps | ImageProps) & { usingDefaultImage?: boolean }
) {
  const { usingDefaultImage = false, ...rest } = props;
  const [src, setSrc] = useState(props.src || defaultAvatarPath);
  useEffect(() => {
    setSrc(props.src || defaultAvatarPath);
  }, [props.src]);

  const onError = () => {
    if (src === defaultAvatarPath) {
      return;
    }
    setSrc(defaultAvatarPath);
  };

  if (usingDefaultImage) {
    return <Image {...rest} src={src} onError={onError} />;
  }

  return <AvatarPresigned {...rest} src={src} onError={onError} />;
}

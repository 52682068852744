import flattenDeep from "lodash/flattenDeep";
import {
  DocumentTemplateType,
  NoDataIndex,
  PaperDirectionType,
  PaperSize,
  PaperType,
  TemplateComponentType,
} from "constants/enum";
import { useMemo } from "react";
import { centimetersToPixels } from "utils/measure";
import { PageInfo, UseMapPageProps } from "../type";
import { CellType } from "interfaces/models/component";
import { isEquipmentDataSheetTemplate } from "models/documentCategory";

const useMapPages = (props: UseMapPageProps) => {
  const { components, template, documentItems } = props;

  const pages = useMemo(() => {
    if (!template) {
      return [];
    }
    const mapPage: {
      [key: number]: PageInfo;
    } = {};
    template.pages.forEach((page, index) => {
      const size = PaperSize?.[page?.pageSize as PaperType];
      const isVertical = page?.pageDirection === PaperDirectionType.VERTICAL;
      const pageWidth = centimetersToPixels(size.width);
      const pageHeight = centimetersToPixels(size.height);
      const pageSize = {
        width: isVertical ? pageWidth : pageHeight,
        height: isVertical ? pageHeight : pageWidth,
      };

      mapPage[index] = {
        pageId: page.pageId,
        pageSize,
        components: [],
        numberPerPage: 0,
        limitItemTableLinked: 0,
        offsetItemLinked: 0,
        isRepeat: false,
        sizePageRatio: page.sizePageRatio,
        pageDirectionRatio: page.pageDirectionRatio,
        isVertical,
      };
    });

    const numOfRepeatTable =
      components?.find(
        (component) => component.type === TemplateComponentType.TableHeader
      )?.detail?.numOfRepeatTable || 1;

    const linkedTable = components?.find((item) => !!item.linkedHeaderId);
    const conflictComponents = (components || [])?.filter(
      (component) =>
        !!component?.linkedHeaderId || (component?.detail?.numOfCol || 0) >= 1
    );

    let perPage = Number(linkedTable?.detail?.rows?.length || 1);
    if (conflictComponents?.length > 1) {
      perPage = Math.min(
        ...conflictComponents.map((component) =>
          component.linkedHeaderId
            ? (component?.detail?.rows || [])?.length
            : (component?.detail?.numOfCol || 1) *
              (component?.detail?.numOfRepeat || 1)
        )
      );
    }

    let totalItem = documentItems?.length || 0;

    if (linkedTable && linkedTable?.detail?.isRepeatTable) {
      totalItem =
        Math.ceil(documentItems?.length / (perPage * (numOfRepeatTable || 1))) *
        perPage;
    }

    let numberItemPerPage: number = 0;
    const isTemplatePaginatePage =
      template?.documentType === DocumentTemplateType.COMMISSIONING_TABLE ||
      template?.documentType === DocumentTemplateType.PHOTO_LEDGER;

    // get number number item per page and map page
    components?.forEach((component) => {
      const page = Number(component.page || 0);
      const isComponentValid =
        !!component.linkedHeaderId ||
        [
          TemplateComponentType.FilterPhoto,
          TemplateComponentType.LinkedImage,
        ].includes(component.type);

      if (isComponentValid) {
        numberItemPerPage =
          component.detail?.numOfRepeat ||
          1 * (component?.detail?.numOfCol || 1);
        if (component.type === TemplateComponentType.LinkedImage) {
          numberItemPerPage =
            (component?.detail?.numOfRepeat || 1) *
            (component?.detail?.numOfCol || 1);
        }

        if (conflictComponents?.length > 1) {
          numberItemPerPage = Math.min(
            ...conflictComponents.map((component) =>
              component.linkedHeaderId
                ? component.detail?.numOfRepeat || 1
                : (component?.detail?.numOfCol || 1) *
                  (component?.detail?.numOfRepeat || 1)
            )
          );
        }

        mapPage[page].limitItemTableLinked = numberItemPerPage;
        mapPage[page].offsetItemLinked = 0;
        mapPage[page].numberPerPage = numberItemPerPage;
        mapPage[page].isRepeat = !!component.detail?.isRepeat;
      }

      mapPage[page].components.push(component);
    });

    // sort page add page paginate
    const listPageInfo: PageInfo[] = [];

    Object.keys(mapPage)
      .sort()
      .forEach((pageIndex: any) => {
        listPageInfo.push(mapPage[pageIndex]);

        if (
          numberItemPerPage > 0 &&
          numberItemPerPage < totalItem &&
          isTemplatePaginatePage &&
          mapPage[pageIndex].isRepeat
        ) {
          const numberPageCopy = Math.ceil(totalItem / numberItemPerPage);

          if (numberPageCopy <= 1) {
            return;
          }

          // add limit and offset for copy page
          for (let i = 2; i <= numberPageCopy; i++) {
            const newPage = { ...mapPage[pageIndex] };
            newPage.limitItemTableLinked = numberItemPerPage;
            newPage.offsetItemLinked = (i - 1) * numberItemPerPage;

            listPageInfo.push(newPage);
          }
        }
      });

    const startPageIndex =
      (flattenDeep(listPageInfo?.map((item) => item.components))?.find(
        (component) => component.type === TemplateComponentType.Table
      )?.page as number) || 0;

    const newPageInfo = listPageInfo?.map((page, pageIndex) => {
      return {
        ...page,
        components: page.components.map((component) => {
          return {
            ...component,
            detail: {
              ...component.detail,
              rows: component.detail?.rows?.map((row, rowIndex) => {
                return {
                  ...row,
                  cells: row?.cells?.map((cell) => {
                    const itemsPerPage = Number(
                      component?.detail?.rows?.length
                    );

                    const newIndex = component?.detail?.isRepeatTable
                      ? page.limitItemTableLinked *
                          (Number(cell?.repeatedTableIndex) || 0) +
                        rowIndex +
                        (pageIndex - startPageIndex) *
                          itemsPerPage *
                          Number(component?.detail?.numOfRepeatTable) +
                        1
                      : rowIndex + 1;

                    const newSubTable = cell.subTable?.rows?.length
                      ? {
                          ...cell.subTable,
                          rows: cell.subTable?.rows?.map((subRow) => ({
                            ...subRow,
                            cells: subRow.cells?.map((subCell) => ({
                              ...subCell,
                              rowIndex: newIndex,
                            })),
                          })),
                        }
                      : cell.subTable;

                    if (
                      newIndex >
                        documentItems?.length -
                          (pageIndex - startPageIndex) * itemsPerPage &&
                      !!component.linkedHeaderId
                    ) {
                      return {
                        ...cell,
                        width: cell.width,
                        height: cell.height,
                        subTable: newSubTable,
                      } as CellType;
                    }

                    if (
                      cell.cellLinkedData?.field === NoDataIndex &&
                      !!component.linkedHeaderId &&
                      component?.detail?.isRepeatTable
                    ) {
                      return {
                        ...cell,
                        value: `${newIndex || ""}`,
                        rowIndex: newIndex,
                        subTable: newSubTable,
                      };
                    }

                    return {
                      ...cell,
                      rowIndex: newIndex,
                      subTable: newSubTable,
                    };
                  }),
                };
              }),
            },
          };
        }),
      };
    });

    // remove table component when second page has type linked image
    let pageHasLinkedImage = -1;
    for (let i = newPageInfo.length - 1; i > 0; i--) {
      const hasLinkedImage =
        newPageInfo[i]?.components?.findIndex(
          (com) => com.type === TemplateComponentType.LinkedImage
        ) !== -1;
      if (hasLinkedImage) {
        pageHasLinkedImage = i;

        break;
      }
    }
    if (pageHasLinkedImage !== -1) {
      const processNewPageInfo = newPageInfo.map((page, index) => {
        let clonePage = page;
        if (index > pageHasLinkedImage) {
          const linkedImageComponent = page.components.find(
            (e) => e.type === TemplateComponentType.LinkedImage
          );

          if (!!linkedImageComponent) {
            const newComponent = {
              ...linkedImageComponent,
              position: {
                x: 0,
                y: 0,
              },
            };

            clonePage = {
              ...clonePage,
              components: [newComponent],
            };
          }
        }

        return clonePage;
      });

      return processNewPageInfo;
    }

    if (isEquipmentDataSheetTemplate(template.documentType)) {
      return documentItems
        .map((documentItem) =>
          newPageInfo.map((page) => ({ ...page, documentItem }))
        )
        .flat(1);
    }

    return newPageInfo as PageInfo[];
  }, [components, template, documentItems]);

  return { pages };
};

export default useMapPages;

import {
  defaultSuggestionsFilter,
  MentionData,
} from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { taskApi, taskCommentApi } from "apiClient/v2";
import { message } from "components/base";
import { InspectionItemType, MapInspectionItemColor } from "constants/enum";
import { defaultAvatarPath } from "constants/file";
import { SetTaskLogTypeComment, TaskLogTypeKey } from "constants/task";
import { MessageType } from "constants/websocket";
import { EditorState, getDefaultKeyBinding, KeyBindingUtil } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import useUserOfProject from "hooks/useUserOfProject";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileModel } from "interfaces/models";
import { Task, TaskComment, TaskLog, TaskLogDTO } from "interfaces/models/task";
import { TaskType } from "interfaces/models/taskType";
import get from "lodash/get";
import throttle from "lodash/throttle";
import uniqBy from "lodash/uniqBy";
import {
  getMapTaskTypeKeyFromTaskLogs,
  getTaskContentLog,
} from "models/commentLog";
import { addOrUpdateLog, insertTaskLogToIndexedDb } from "models/dataLog";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "redux/store";
import { setTask } from "redux/taskSlice";
import { sortArrayByField } from "utils/array";
import { sleep, uuid } from "utils/common";
import { now } from "utils/date";
import { isAudio, isImage, uploadFileToS3 } from "utils/file";
import { selectDbIds } from "utils/forge";
import { getDbIdByExternalId } from "utils/forge/data";
import { updateLabel } from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";

const MIN_HEIGHT_SCROLL_LOAD_MORE = 300;
const OFFSET_SCROLL_LOAD_MORE = 5;

const useChat = ({
  taskTypes = [],
  setTaskModalInfo,
}: {
  setTaskModalInfo: React.Dispatch<React.SetStateAction<TaskDTO | undefined>>;
  taskTypes: TaskType[];
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [comments, setComments] = useState<TaskComment[]>([]);
  const { isSyncOfflineData } = useSelector((state: RootState) => state.app);
  const { taskSelected } = useSelector((state: RootState) => state.task);
  const [disabled, setDisabled] = useState(false);
  const containerChatRef = useRef<HTMLInputElement | null>(null);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [loadingChat, setLoadingChat] = useState<boolean>(false);
  const loadingChatRef = useRef(true);
  const timeOutIdRef = useRef<any>(null);
  const timeOutWaitLoadDomRef = useRef<any>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const hasComment = useRef(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const [nextToken, setNextToken] = useState("");
  const scrollTopRef = useRef<null | number>(null);
  const scrollHeightRef = useRef<null | number>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const dispatch = useDispatch();
  const { hasCommandModifier } = KeyBindingUtil;
  const { webSocketMessages, socket } = useForgeViewerContext();
  const {
    listUserById,
    listAllUserById,
    isFetchingUsers,
    isFetchingUserAssigned,
  } = useUserOfProject();

  const chatSavedRef = useRef<TaskDTO | undefined | null>(null);
  const isInitRef = useRef(false);
  const initChat = useCallback(() => {
    hasComment.current = false;
    scrollTopRef.current = null;
    scrollHeightRef.current = null;
    loadingChatRef.current = true;
    timeOutIdRef.current = null;
    clearTimeout(timeOutWaitLoadDomRef.current);
    setComments([]);
    setNextToken("");
    (async () => {
      setEditorState(EditorState.createEmpty());
      setLoadingChat(true);
      let token: any = "";
      const contentModalEle = document.getElementById(
        "pin-detail-content-modal"
      );
      const newComments = [];
      if (contentModalEle) {
        timeOutWaitLoadDomRef.current = await sleep(100);
        let isHasScroll = false;

        do {
          isHasScroll =
            contentModalEle.scrollHeight > contentModalEle.clientHeight;

          const result = await taskCommentApi.handleGetTaskComments({
            taskId: taskSelected?.id || "",
            cursor: token || "",
          });

          token = result?.pagination?.cursor ?? null;
          newComments.push(...(result?.data || []));
          setComments(
            sortArrayByField<TaskComment>(newComments, "createdAt", false)
          );
        } while (!isHasScroll && !!token);
        setNextToken(token);
      }

      setLoadingChat(false);
      loadingChatRef.current = false;
      isInitRef.current = true;
    })();
  }, [taskSelected?.id]);

  useEffect(() => {
    initChat();

    return () => {
      if (timeOutWaitLoadDomRef.current) {
        clearTimeout(timeOutWaitLoadDomRef.current);
      }

      if (timeOutIdRef.current) {
        clearTimeout(timeOutIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskSelected?.id, isSyncOfflineData]);

  useEffect(() => {
    if (!webSocketMessages.length) return;
    webSocketMessages.forEach((e) => {
      if (e?.type === MessageType.RELOAD_TASK) {
        initChat();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  useEffect(() => {
    if (!taskSelected?.id || !webSocketMessages.length || !isInitRef.current)
      return;
    webSocketMessages.forEach((e) => {
      const { type, data, taskId } = e;

      if (taskId !== taskSelected.id) {
        return;
      }

      switch (type) {
        case MessageType.ADD_TASK_COMMENTS:
          setComments(addOrUpdateLog(data));
          break;
        case MessageType.UPDATE_TASK_COMMENT:
          setComments(addOrUpdateLog(data, true));
          break;
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskSelected?.id, webSocketMessages]);

  const mentions: MentionData[] = useMemo(() => {
    const data = Object.keys(listUserById)
      .filter((key) => {
        const user = listUserById[key];

        return !!user?.name;
      })
      .map((key) => {
        const user = listUserById[key];

        return {
          name: user?.name || "",
          avatar: user?.avatar || defaultAvatarPath,
          id: user?.id || "",
        };
      });

    return data;
  }, [listUserById]);

  useEffect(() => {
    setSuggestions(mentions);
  }, [mentions]);

  const loadComment = useCallback(
    async (token = "", isInit = false) => {
      if (!taskSelected?.id || token === null) {
        return;
      }
      logDev("__load more");
      try {
        setLoadingChat(true);
        const result = await taskCommentApi.handleGetTaskComments({
          taskId: taskSelected?.id || "",
          cursor: token || "",
        });

        if (!result) {
          setLoadingChat(false);

          return;
        }

        setNextToken((result.pagination?.cursor ?? null) as any);
        const newComments = result.data || [];
        hasComment.current = !!newComments.length;
        if (token === "") {
          setComments(
            sortArrayByField<TaskComment>(newComments, "createdAt", false)
          );
        } else {
          setComments((comments) =>
            sortArrayByField<TaskComment>(
              [...comments, ...newComments],
              "createdAt",
              false
            )
          );
        }

        if (isInit) {
          logDev("__init load comment");
          timeOutIdRef.current = setTimeout(() => {
            // Scroll to last item.
            moveToLast();
            // When the image finishes loading, the height of the comment list will be changed.
            // In some case: 1000ms can't load finished comment contain image => so it won't be able to scroll to the end.
          }, 1000);
        }

        setLoadingChat(false);
      } finally {
      }
    },
    [taskSelected?.id]
  );

  const debounceLoadChat = throttle(async () => {
    if ((nextToken && !comments.length) || nextToken === null) {
      scrollTopRef.current = null;
      scrollHeightRef.current = null;

      return;
    }
    setLoadingChat(true);
    scrollTopRef.current = containerChatRef?.current?.scrollTop ?? 0;
    scrollHeightRef.current = containerChatRef?.current?.scrollHeight ?? 0;
    await loadComment(nextToken);
    setLoadingChat(false);
  }, 500);

  const onScroll = async (e: any) => {
    const container: HTMLElement = e.target as any;
    const isLoadMoreComment =
      container.scrollTop + container.clientHeight + OFFSET_SCROLL_LOAD_MORE >
        container.scrollHeight &&
      !loadingChatRef.current &&
      !loadingChat &&
      nextToken !== null;

    if (isLoadMoreComment) {
      debounceLoadChat();
    }
  };

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [mentions]
  );

  const uploadListFilToS3 = async (files: FileModel[]) => {
    const listFileS3 = await Promise.all(
      files.map(async (file) => {
        const name = file.name;
        const src = file.src || "";
        if (file.file) {
          return await uploadFileToS3(file.file, name || "");
        } else {
          JSON.stringify({
            name,
            src,
          });
        }
      })
    );

    return listFileS3;
  };

  const addTaskLog = useCallback(
    async (logs: TaskLog[], requestId: string) => {
      const filterLogs = logs.filter((log) => log.type > -1);
      if (!filterLogs?.length) {
        return;
      }
      setComments((comments) =>
        sortArrayByField<TaskComment>(
          uniqBy([...filterLogs, ...comments], "id"),
          "createdAt",
          false
        )
      );
      socket.addTaskComments(taskSelected?.id!, filterLogs);
      filterLogs.forEach((log) => {
        insertTaskLogToIndexedDb({ taskLog: log, requestId });
      });
    },
    [socket, taskSelected?.id]
  );

  const addChatMessage = useCallback(
    async (
      content: string,
      type: TaskLogTypeKey,
      files?: any,
      taskId?: string,
      statusChange?: string
    ) => {
      const isChatComment = SetTaskLogTypeComment.has(type);
      if (isChatComment) {
        setDisabled(true);
      }

      const newComment = {
        taskId: taskId || taskSelected?.id || "",
        content,
        type,
        images: files,
        statusChange,
        createdBy: currentUser?.id,
        createdAt: new Date(),
      } as TaskComment;

      const { data: res } = await taskCommentApi.createComment(newComment);
      if (res?.id) {
        setComments((comments) =>
          sortArrayByField<TaskComment>([res, ...comments], "createdAt", false)
        );

        socket.addTaskComments(taskId || taskSelected?.id!, [res]);

        if (isChatComment) {
          setTimeout(() => {
            moveToLast();
          }, 1);
          setEditorState(EditorState.createEmpty());
        }
      }
      if (isChatComment) {
        setDisabled(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.id, taskSelected]
  );

  const updateStatusAndNumberImageTask = useCallback(
    async (
      numberFileImage: number,
      content?: string,
      statusChange?: string
    ) => {
      if (!taskSelected?.id) {
        return;
      }

      const { dbId: _, ...newTaskReq } = taskSelected;
      const isDiffCurrentStatus = (status: string) =>
        newTaskReq.status !== statusChange && statusChange === status;

      const bodyUpdate: Partial<TaskDTO> = {
        numberImage: (newTaskReq?.numberImage || 0) + numberFileImage,
      };

      if (isDiffCurrentStatus(InspectionItemType.Confirmed)) {
        bodyUpdate.status = statusChange;
        if (!newTaskReq?.confirmedDateTime) {
          bodyUpdate.confirmedDateTime = now();
        }

        if (!newTaskReq?.userConfirmed) {
          bodyUpdate.userConfirmed = currentUser?.id;
        }
      }

      if (isDiffCurrentStatus(InspectionItemType.Treated)) {
        bodyUpdate.status = statusChange;
        bodyUpdate.userTreated = currentUser?.id;
        if (content) {
          bodyUpdate.treatedComment = content;
        }
        if (!newTaskReq?.endDateScheduled) {
          bodyUpdate.endDateScheduled = now();
        }
      }

      const taskReq = {
        ...newTaskReq,
        ...bodyUpdate,
      } as Task;

      const isUpdateStatus = statusChange && isDiffCurrentStatus(statusChange);
      const requestId = uuid();
      const taskLogs: TaskLog[] = Object.keys(bodyUpdate).map((k) => {
        const key: keyof TaskDTO = k as any;
        let typeKey = -1;
        switch (key) {
          case "status":
            typeKey = TaskLogTypeKey.STATUS;
            break;
          case "confirmedDateTime":
            typeKey = TaskLogTypeKey.CONFIRM_DATE;
            break;
          case "userConfirmed":
            typeKey = TaskLogTypeKey.USER_CREATED;
            break;
          case "endDateScheduled":
            typeKey = TaskLogTypeKey.COMPLETE_DATE;
            break;
          default:
            break;
        }

        return getTaskContentLog({
          field: key,
          taskId: taskReq.id,
          value: bodyUpdate[key as keyof typeof bodyUpdate],
          type: typeKey,
        });
      });

      const payload = {
        id: taskReq.id,
        ...bodyUpdate,
        mapTaskTypeKey: getMapTaskTypeKeyFromTaskLogs(taskLogs as TaskLogDTO[]),
        requestId,
        updatedAt: new Date(),
      } as TaskDTO;
      let { data: newTask } = await taskApi.updateTask(payload);

      addTaskLog(taskLogs, requestId);
      newTask = { ...taskReq, ...newTask };

      if (newTask) {
        newTask.dbId = getDbIdByExternalId(newTask.externalId);
        dispatch(setTask(newTask));

        if (isUpdateStatus) {
          updateLabel(newTask.id, {
            id: newTask.id,
            position: newTask.position,
            title:
              taskTypes.find((taskType) => taskType.id === taskReq.taskTypeId)
                ?.title || "-",
            indexId: newTask.indexId,
            showImage: Number(newTask.images?.length) > 0,
            status: newTask.status,
            externalId: newTask.externalId,
          });
        }

        setTimeout(() => {
          if (newTask.id === store.getState().task.taskSelected?.id) {
            if (isUpdateStatus && newTask?.dbId) {
              selectDbIds(newTask?.dbId, {
                color:
                  MapInspectionItemColor[
                    (taskReq.status ||
                      InspectionItemType.Defect) as InspectionItemType
                  ],
              });
            }

            setTaskModalInfo(newTask);
          }
        });

        socket.updateTask({
          ...payload,
          level: newTask.level,
          updatedAt: newTask.updatedAt,
        });
      }
    },
    [
      taskSelected,
      currentUser?.id,
      taskTypes,
      addTaskLog,
      dispatch,
      setTaskModalInfo,
      socket,
    ]
  );

  const toHTML = useCallback(() => {
    const contentState = editorState.getCurrentContent();
    const options = {
      entityStyleFn: (entity: any) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "mention") {
          const data = entity.getData();

          return {
            element: "span",
            attributes: {
              "data-mention-id": get(data, "mention.id"),
              class: "mention_class",
            },
            style: {
              background: "#e6f3ff",
            },
          };
        }
      },
    };

    return stateToHTML(contentState, options);
  }, [editorState]);

  const sendHtmlText = useCallback(
    async (files: FileModel[], statusChange?: string) => {
      chatSavedRef.current = taskSelected;
      setDisabled(true);
      const plainText = editorState.getCurrentContent().getPlainText();
      if (!plainText && !files.length && !statusChange) {
        setDisabled(false);

        return message.error("コメントを入力してください");
      }
      const listFile = await uploadListFilToS3(files);
      let numberFileImage = 0;
      let hasAudio = false;
      let hasOtherFile = false;

      listFile.forEach((file) => {
        const fileName = file?.split("?")[0].split("#")[0] || "";
        if (isImage(fileName)) {
          numberFileImage++;
        } else if (isAudio(fileName)) {
          hasAudio = true;
        } else {
          hasOtherFile = true;
        }
      });
      // update number file image
      const text = !!plainText.trim() ? JSON.stringify(toHTML()) : "";
      let commentType = TaskLogTypeKey.ADD_COMMENT;
      if (hasAudio && !hasOtherFile && numberFileImage <= 0) {
        commentType = TaskLogTypeKey.ADD_COMMENT_AUDIO;
      } else if (numberFileImage > 0 && !hasAudio && !hasOtherFile) {
        commentType = TaskLogTypeKey.ADD_COMMENT_IMAGE;
      } else if (listFile.length) {
        commentType = TaskLogTypeKey.ADD_COMMENT_FILE;
      }
      if (listFile.length || statusChange) {
        await updateStatusAndNumberImageTask(
          numberFileImage,
          text,
          statusChange
        );
      }

      if (text.trim() || listFile.length) {
        await addChatMessage(
          text,
          commentType,
          listFile,
          undefined,
          statusChange
        );
        // scroll to top of list comment
        containerChatRef!.current?.scrollIntoView({
          //@ts-ignore
          behavior: "instant",
          block: "start",
        });
      }
      setDisabled(false);
      chatSavedRef.current = null;
    },
    [
      addChatMessage,
      editorState,
      taskSelected,
      toHTML,
      updateStatusAndNumberImageTask,
    ]
  );

  const onAddMessage = useCallback(
    async (file: FileModel[], statusChange: string) => {
      sendHtmlText(file, statusChange);
    },
    [sendHtmlText]
  );
  const handleKeyCommand = async (command: any) => {
    if (command === "myEditor_submit") {
      // sendHtmlText();
    }
  };

  const onKeyUp = (e: any) => {
    if (e.ctrlKey && e.code === "Enter" && hasCommandModifier(e)) {
      return "myEditor_submit";
    }

    return getDefaultKeyBinding(e);
  };

  const moveToLast = () => {
    if (containerChatRef) {
      const scrollToBottom =
        scrollTopRef.current + (containerChatRef?.current as any)?.scrollHeight;
      (containerChatRef?.current as any)?.scroll({
        top: scrollToBottom || MIN_HEIGHT_SCROLL_LOAD_MORE,
      });
    }
  };

  const onDeleteImageChat = useCallback(
    async (comment: TaskComment, dataImage: string) => {
      setLoadingImage(true);
      const listImage = comment.images;
      const newListImage = listImage?.filter((item) => item !== dataImage);
      const newContent: TaskComment = {
        ...comment,
        images: newListImage,
        createdBy: currentUser?.id,
        type: TaskLogTypeKey.ADD_COMMENT,
        id: comment.id,
        updatedAt: new Date(),
      };
      try {
        const { data: res } = await taskCommentApi.updateComment(newContent);

        if (res?.id) {
          await updateStatusAndNumberImageTask(-1);

          socket.updateTaskComment(taskSelected!.id!, newContent);

          setComments((comments) => {
            const currCmt = comments.find((cmt) => cmt.id === res?.id);
            if (currCmt) {
              currCmt.images = newListImage;
            }

            return sortArrayByField<TaskComment>(
              [...comments],
              "createdAt",
              false
            );
          });
        }
      } catch (error) {
      } finally {
        setLoadingImage(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.id, taskSelected?.id, socket]
  );

  const onUploadFileToS3 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as any;
    const file = (target.files as FileList)[0];
    const imageFileURL = file ? await uploadFileToS3(file, file.name) : "";

    return imageFileURL;
  };

  const onUpdateImageComment = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      comment: TaskComment,
      dataImage: string
    ) => {
      if (!taskSelected?.id) {
        setLoadingImage(false);

        return;
      }

      const imageS3 = (await onUploadFileToS3(e)) || "";
      const listImage = comment.images;
      const newListImage = listImage?.map((imageItem: string) => {
        let cloneImage = imageItem;
        if (imageItem === dataImage) {
          cloneImage = imageS3;
        }

        return cloneImage;
      });
      const newContent: TaskComment = {
        ...comment,
        createdBy: currentUser?.id,
        images: newListImage,
        id: comment.id,
      };
      try {
        const { data: res } = await taskCommentApi.updateComment(newContent);
        if (res?.id) {
          socket.updateTaskComment(taskSelected?.id, newContent);
          setComments((comments) => {
            const newComments = comments.map((comment) =>
              comment.id === res.id
                ? { ...comment, images: newListImage }
                : comment
            );

            return sortArrayByField<TaskComment>(
              [...newComments],
              "createdAt",
              false
            );
          });
        }
      } catch (error) {
      } finally {
        setLoadingImage(false);
      }
    },
    [socket, currentUser?.id, taskSelected?.id]
  );

  const onClear = () => {
    if (inputRef?.current?.value) {
      inputRef.current.value = "";
    }
  };

  const mailingList = useMemo(() => {
    return Object.values(listUserById).reduce<
      { name: string; value: string; highlight?: string }[]
    >((prev, curr, i) => {
      if (curr?.name) {
        prev.push({
          name: curr?.name,
          value: curr?.name ?? `${i}`,
          highlight: curr?.name,
        });
      }

      return prev;
    }, []);
  }, [listUserById]);

  return {
    comments,
    listAllUserById,
    listUserById,
    mailingList,
    loadingChat,
    loadingImage,
    inputRef,
    containerChatRef,
    disabled,
    mentions,
    editorState,
    suggestions,
    chatSavedRef,
    isFetchingUsers,
    isFetchingUserAssigned,
    onSearchChange,
    setEditorState,
    addChatMessage,
    moveToLast,
    onKeyUp,
    onAddMessage,
    onClear,
    onScroll,
    handleKeyCommand,
    onDeleteImageChat,
    onUpdateImageComment,
    addTaskLog,
  };
};

export default useChat;

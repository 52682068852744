import { Box, Flex, FlexProps, Input, Text } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import DayPicker from "components/ui/DayPicker";
import {
  CellProperty,
  DocumentItemKey,
  LinkedDataField,
  TemplateComponentType,
} from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { CellType } from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import uniqBy from "lodash/uniqBy";
import { GetContentLog, transformMapTitleKey } from "models/dataLog";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { uuid } from "utils/common";
import { transformBodyForCombineData } from "utils/offline";
import { getAllCells } from "utils/tableCell";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";

interface Props extends FlexProps {
  template?: DocumentTemplate;
  item?: DocumentItemDTO;
  isDisabled?: boolean;
  insertItemLog?: (params: GetContentLog) => Promise<void>;
}

const FormItemForDocumentData = (props: Props) => {
  const {
    template,
    item = {} as DocumentItemDTO,
    isDisabled,
    insertItemLog,
    ...rest
  } = props;
  const { socket } = useForgeViewerContext();
  const [values, setValues] = useState<DocumentItemDTO>(item);

  const dispatch = useDispatch();
  const documentDataCells = useMemo(() => {
    if (!template) {
      return [];
    }

    const components = (template?.components || []).filter(
      (component) => component.type === TemplateComponentType.TableHeader
    );

    const cells = components
      .map((component) => getAllCells(component))
      .flat(1)
      .filter((cell) => {
        const field = cell?.cellLinkedData?.field;

        return cell.cellProperty === CellProperty.DOCUMENT_DATA && !!field;
      });

    return uniqBy(cells, "cellLinkedData.field");
  }, [template]);

  const handleBlurData = useCallback(
    (key: keyof DocumentItemDTO) => async (value: any) => {
      saveRef.current.valueChange = undefined;

      if (item?.[key] === value) {
        return;
      }

      const now = new Date();
      if (key === DocumentItemKey.START_DATE_SCHEDULED) {
        const isDiffDate =
          Date.parse(`${item?.[key] || now}`) !== Date.parse(`${value || now}`);

        if (!isDiffDate) {
          return;
        }
      }

      const bodyUpdate: Partial<DocumentItemDTO> = {
        id: item.id,
        [key]: value,
        updatedAt: now,
      };
      const newItem: DocumentItemDTO = {
        ...item,
        [key]: value,
        updatedAt: now,
      };
      dispatch(updateDocumentItem(newItem));
      const requestId = uuid();
      const { mapLogs, mapTitleKey, mapDisplayValueKey } = transformMapTitleKey(
        [
          {
            field: key as any,
            value,
          },
        ]
      );

      bodyUpdate.requestId = requestId;
      bodyUpdate.mapTitleKey = mapTitleKey;
      bodyUpdate.mapDisplayValueKey = mapDisplayValueKey;
      documentItemApi
        .updateItem(
          transformBodyForCombineData<DocumentItemDTO>({
            body: bodyUpdate as DocumentItemDTO,
            bodyBefore: item,
            typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
          })
        )
        .then(({ data }) => {
          socket.updateDocItem(item, {
            [key]: value,
            updatedAt: data.updatedAt,
          });
          insertItemLog?.({ ...mapLogs[key], requestId });
        });
    },
    [item, dispatch, insertItemLog, socket]
  );

  const saveRef = useRef<{
    handleBlurData: typeof handleBlurData;
    valueChange?: { key: keyof DocumentItemDTO; value: any };
  }>({
    handleBlurData,
  });
  useEffect(() => {
    saveRef.current.handleBlurData = handleBlurData;
  }, [handleBlurData]);

  // save data when unmount but not blur
  useEffect(() => {
    const saveUtil = saveRef?.current;

    return () => {
      const key = saveUtil?.valueChange?.key;
      const value = saveUtil?.valueChange?.value;

      key && saveUtil?.handleBlurData?.(key)(value);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValues(item);
  }, [item]);

  const handleChangeData = useCallback(
    (key: keyof DocumentItemDTO) => (value: any) => {
      saveRef.current.valueChange = { key, value };
      setValues((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const renderField = useCallback(
    (cell: CellType) => {
      const field = cell?.cellLinkedData?.field;
      const name = cell.value;

      let element = null;
      switch (field) {
        case LinkedDataField.DOCUMENT_ITEM.LAST_CHANGE_STATUS_DATETIME:
          element = (
            <Box flex="1">
              <DayPicker
                name={name}
                value={values?.startDateScheduled}
                onSelectedDay={handleChangeData(
                  DocumentItemKey.START_DATE_SCHEDULED
                )}
                isDisabled={isDisabled}
                onClose={(value) => {
                  handleBlurData(DocumentItemKey.START_DATE_SCHEDULED)(value);
                }}
              />
            </Box>
          );
          break;

        case LinkedDataField.COMMON.REMARKS:
          element = (
            <Box w="calc(100% - 8rem)" cursor="initial" flex="1">
              <Input
                height="4rem"
                fontSize="1.4rem"
                width="100%"
                placeholder={name}
                isDisabled={isDisabled}
                onBlur={(e) => {
                  handleBlurData(DocumentItemKey.MEMO)(e.target.value);
                }}
                onChange={(e) => {
                  handleChangeData(DocumentItemKey.MEMO)(e.target.value);
                }}
                value={values?.memo || ""}
              />
            </Box>
          );
          break;
      }

      return { name, element };
    },
    [values, handleBlurData, handleChangeData, isDisabled]
  );

  const cellElements = useMemo(() => {
    let hasData = false;

    const content = documentDataCells.map((cell) => {
      const { name, element } = renderField(cell);

      if (!element) {
        return <Fragment key={cell.cellId}></Fragment>;
      }
      hasData = true;

      return (
        <Flex w="100%" alignItems="center" key={cell.cellId} minH="4rem">
          <Text textAlign="left" flexBasis="8rem">
            {name}
          </Text>
          {element}
        </Flex>
      );
    });

    return { content, hasData };
  }, [documentDataCells, renderField]);

  if (!cellElements.hasData) {
    return <></>;
  }

  return (
    <Flex width="100%" flexDirection="column" gap="1rem" {...rest}>
      {cellElements.content}
    </Flex>
  );
};

export default memo(FormItemForDocumentData);

import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Grid,
  MenuItemOption,
  Stack,
  Text,
} from "@chakra-ui/react";
import AvatarUser from "components/AvatarUser";
import { SvgIcon } from "components/SvgIcon";
import { TagsInput } from "components/ui/TagInput";
import {
  DocumentCategoryStatusType,
  DocumentTemplateType,
  MapDocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  ModalType,
} from "constants/enum";
import { defaultAvatarPath } from "constants/file";
import { useScrollToElement } from "hooks/useScrollToElement";
import { NeptuneArea } from "interfaces/models/area";
import { User, UserSetting } from "interfaces/models/user";
import debounce from "lodash/debounce";
import { getColorTextByStatus } from "models/document";
import { sortDocumentCategories } from "models/documentCategory";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFlexibleCategoryChildTemplates,
  resetDocumentItemIdsCreated,
  setDocumentCategorySelected,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  setDocumentSubCategorySelected,
} from "redux/documentSlice";
import { setModalType } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { updateForgeWhenSelectCategory } from "utils/document";
import { selectDbIds } from "utils/forge";
import { clearSelectedLabel } from "utils/forge/extensions/custom-label";
import { MultiSelectBox } from "./MultiSelectBox";

const FilterBoxDocument: FC<{
  filterDataDefault?: UserSetting;
  userList: User[];
  filterData: UserSetting;
  clearData: () => void;
  setFilterData: (data: UserSetting) => void;
  areas: NeptuneArea[];
}> = ({
  userList,
  setFilterData,
  filterData,
  clearData,
  areas,
  filterDataDefault,
}) => {
  const [filterDataTemp, setFilterDataTemp] = useState<UserSetting>({} as any);
  const {
    documentItemSelected,
    documentCategorySelected,
    documentSubCategorySelected,
    documentCategories,
  } = useSelector((state: RootState) => state.document);

  const { families, levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const dispatch = useDispatch();

  const isSelectItem = useMemo(
    () =>
      documentItemSelected?.id ||
      documentCategorySelected?.id ||
      documentSubCategorySelected?.id,
    [
      documentItemSelected?.id,
      documentCategorySelected?.id,
      documentSubCategorySelected?.id,
    ]
  );

  const resetItemSelected = useCallback(() => {
    if (isSelectItem) {
      clearSelectedLabel();
      dispatch(setDocumentItemSelected());
      dispatch(setDocumentCategorySelected());
      console.log("reset item");
      dispatch(setDocumentSubCategorySelected());
      dispatch(setDocumentGroupSelected());
      dispatch(resetDocumentItemIdsCreated());
      dispatch(setModalType(ModalType.NONE));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectItem]);

  useEffect(() => {
    const isFilterDataTempNull = !Object.keys(filterDataTemp || {})?.length;
    const isFilterDataNotNull = !!Object.keys(filterData || {})?.length;

    if (isFilterDataTempNull && isFilterDataNotNull) {
      setFilterDataTemp(filterData);
    }
  }, [filterDataTemp, filterData]);

  const listNameDocuments = useMemo(() => {
    const categories = sortDocumentCategories(documentCategories).filter(
      (category) =>
        !levelSelected.guid || levelSelected.label === category.level
    );
    const filterCategoryByTemplateId: { name: string; value: string }[] = [];
    categories.forEach((category) => {
      filterCategoryByTemplateId.push({
        name: category.title || "",
        value: category.id,
      });
    });

    return filterCategoryByTemplateId;
  }, [documentCategories, levelSelected.guid, levelSelected.label]);

  const userBox = (id: string) => {
    const user = userList?.find((e) => e.id === id);

    return (
      <Flex alignItems="center" mr={8} key={id}>
        {user ? (
          <AvatarUser
            w="3.2rem"
            h="3.2rem"
            mr="1rem"
            src={user?.avatar || defaultAvatarPath}
          />
        ) : (
          <Box w="3.2rem" mr="1rem" />
        )}
        <Text
          ml="1rem"
          color="#000"
          style={{
            maxWidth: "17.5rem",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {user?.name || "未設定"}
        </Text>
      </Flex>
    );
  };

  const onChange = useCallback(
    (value: any, field: string) => {
      const newFilterData = { ...filterData, [field]: value };
      setFilterDataTemp(newFilterData);
      resetItemSelected();
      dispatch(resetDocumentItemIdsCreated());
      setFilterData(newFilterData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterData, resetItemSelected, setFilterData]
  );

  const scrollToElement = useScrollToElement();

  const onClearData = () => {
    setFilterDataTemp(filterDataDefault || ({} as any));

    clearData();
    const originalDocumentCategory = documentCategories.find(
      (doc) => doc.id === documentCategorySelected?.id
    );
    const isSelfInspection =
      documentCategorySelected?.documentType ===
      DocumentTemplateType.SELF_INSPECTION;
    // Only fix for SELF_INSPECTION parent
    if (originalDocumentCategory && isSelfInspection) {
      dispatch(
        resetFlexibleCategoryChildTemplates({
          documentCategoryId: documentCategorySelected.id,
          childTemplates: originalDocumentCategory.childTemplates,
        })
      );
    } else if (originalDocumentCategory) {
      dispatch(setDocumentCategorySelected(originalDocumentCategory));
      selectDbIds([], {});
      updateForgeWhenSelectCategory(originalDocumentCategory);
    }

    if (documentCategorySelected) {
      setTimeout(() => {
        scrollToElement(
          document.getElementById(`doc-category-${documentCategorySelected.id}`)
        );
      }, 200);
    }
  };

  return (
    <Stack width="100%" height="100%" overflow="hidden auto" bgColor="white">
      <Stack spacing={4} p={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight="bold">フィルター</Text>
          <Button
            onClick={onClearData}
            variant="text"
            textDecoration="underline"
            fontSize={9}
          >
            クリア
          </Button>
        </Flex>
        <MultiSelectBox<string>
          isFullWidth
          label="作成者"
          value={filterDataTemp?.documentUserCreated || []}
          inputItems={
            filterDataTemp?.documentUserCreated?.map((e) => userBox(e)) || []
          }
          menuItems={userList
            .filter(({ name }) => name)
            .map(({ id }) => (
              <MenuItemOption
                key={id}
                value={id}
                p=".5rem 1.5rem"
                borderBottom="1px solid var(--primary-border-color)"
                _last={{ borderBottom: "none" }}
                style={{
                  maxWidth: "29rem",
                }}
                icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
                onClick={({ currentTarget }) =>
                  onChange(
                    filterDataTemp?.documentUserCreated?.includes(
                      currentTarget.value
                    )
                      ? filterDataTemp?.documentUserCreated.filter(
                          (v) => v !== currentTarget.value
                        )
                      : [
                          ...(filterDataTemp?.documentUserCreated || []),
                          currentTarget.value,
                        ],
                    "documentUserCreated"
                  )
                }
              >
                {id && userBox(id)}
              </MenuItemOption>
            ))}
          disabled={!userList.filter(({ name }) => name).length}
        />
        <MultiSelectBox<string>
          isFullWidth
          label="担当者"
          value={filterDataTemp?.documentUserAssigned || []}
          inputItems={
            filterDataTemp?.documentUserAssigned?.map((e) => userBox(e)) || []
          }
          menuItems={[
            {
              name: "未設定",
              id: "",
            },
            ...userList,
          ]
            .filter(({ name }) => name)
            .map(({ id }) => (
              <MenuItemOption
                key={id}
                value={id}
                p=".5rem 1.5rem"
                borderBottom="1px solid var(--primary-border-color)"
                _last={{ borderBottom: "none" }}
                icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
                onClick={({ currentTarget }) =>
                  onChange(
                    filterDataTemp?.documentUserAssigned?.includes(
                      currentTarget.value
                    )
                      ? filterDataTemp?.documentUserAssigned?.filter(
                          (v) => v !== currentTarget.value
                        )
                      : [
                          ...(filterDataTemp?.documentUserAssigned || []),
                          currentTarget.value,
                        ],
                    "documentUserAssigned"
                  )
                }
              >
                {userBox(id as string)}
              </MenuItemOption>
            ))}
          disabled={!userList.filter(({ name }) => name).length}
        />
        <MultiSelectBox<string>
          label="書類種類"
          value={filterDataTemp?.documentTypeDocuments || []}
          inputItems={
            filterDataTemp?.documentTypeDocuments?.map((e) => {
              const result = listNameDocuments?.find(
                (item) => e === item.value
              );

              return result ? (
                <Badge
                  variant="pin"
                  key={e}
                  bg="gray"
                  sx={{ textTransform: "none" }}
                >
                  {result.name}
                </Badge>
              ) : (
                <></>
              );
            }) || []
          }
          menuItems={
            listNameDocuments?.map((item) => (
              <MenuItemOption
                key={item.value}
                value={item.value}
                p=".5rem 1.5rem"
                borderBottom="1px solid var(--primary-border-color)"
                _last={{ borderBottom: "none" }}
                style={{ maxWidth: "29rem" }}
                icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
                onClick={({ currentTarget }) => {
                  onChange(
                    filterDataTemp?.documentTypeDocuments?.includes(
                      currentTarget.value
                    )
                      ? filterDataTemp?.documentTypeDocuments?.filter(
                          (v) => v !== currentTarget.value
                        )
                      : [
                          ...(filterDataTemp?.documentTypeDocuments || []),
                          currentTarget.value,
                        ],
                    "documentTypeDocuments"
                  );
                }}
              >
                <Text
                  style={{
                    maxWidth: "23.8rem",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.name}
                </Text>
              </MenuItemOption>
            )) || []
          }
          disabled={!listNameDocuments?.length}
        />
        {/* <MultiSelectBox<string>
        label="フォルダ"
        value={filterDataTemp?.documentGroups || []}
        inputItems={
          documentGroups
            .filter(doc => filterDataTemp?.documentGroups?.includes(doc.id))
            .map(({ name, id }) => {
              return <Badge variant="pin" key={id} bg="gray">{name || ""}</Badge>
            })
        }
        disabled={!documentGroups.length}
        menuItems={documentGroups.map((group) => {
          return (
            <MenuItemOption
              key={group.id}
              value={group.id}
              icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
              onClick={({ currentTarget: { value } }) => {
                onChange(
                  filterDataTemp?.documentGroups?.includes(value)
                    ? filterDataTemp?.documentGroups?.filter((v) => v !== value)
                    : [...(filterDataTemp?.documentGroups || []), value],
                  "documentGroups"
                );
              }}
            >
              <Badge variant="pin" key={group.id} bg="gray">
                {group.name || ""}
              </Badge>
            </MenuItemOption>
          );
        })}
      /> */}
        <MultiSelectBox<string>
          label="エリア"
          value={filterDataTemp?.documentAreas || []}
          inputItems={
            filterDataTemp?.documentAreas
              ?.filter((areaId) => areas.find((item) => item.id === areaId))
              .map((areaId) => {
                const result = areas.find(
                  (item: any) =>
                    item.inspectionAreaId === areaId || item.id === areaId
                );

                return (
                  <Badge variant="pin" key={areaId} bg="gray">
                    {result?.name || ""}
                  </Badge>
                );
              }) || []
          }
          disabled={!areas.length}
          menuItems={areas.map((area: NeptuneArea) => {
            return (
              <MenuItemOption
                key={area.id}
                value={area.id}
                icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
                onClick={({ currentTarget: { value } }) =>
                  onChange(
                    filterDataTemp?.documentAreas?.includes(value)
                      ? filterDataTemp?.documentAreas?.filter(
                          (v) => v !== value
                        )
                      : [...(filterDataTemp?.documentAreas || []), value],
                    "documentAreas"
                  )
                }
              >
                <Badge variant="pin" bg="gray" mr="3rem">
                  {area.name || ""}
                </Badge>
              </MenuItemOption>
            );
          })}
        />
        <Stack>
          <FormLabel as="legend" w="100%">
            タグ
            <TagsInput
              options={families.map((family) => ({
                name: family.name,
                value: family.name,
              }))}
              value={filterDataTemp?.documentTags}
              placeHolder="タグ"
              onChange={(value) => onChange(value, "documentTags")}
              isWithSubmenu
            />
          </FormLabel>
        </Stack>

        <Stack>
          <FormLabel as="legend">
            写真
            <Grid gridTemplateColumns={"50% 50%"}>
              <Checkbox
                flexGrow={1}
                isChecked={filterDataTemp?.documentHasImage}
                onChange={debounce(() =>
                  onChange(
                    !filterDataTemp?.documentHasImage,
                    "documentHasImage"
                  )
                )}
              >
                写真設定済
              </Checkbox>
              <Checkbox
                flexGrow={1}
                isChecked={filterDataTemp?.documentHasNoImage}
                onChange={debounce(() =>
                  onChange(
                    !filterDataTemp?.documentHasNoImage,
                    "documentHasNoImage"
                  )
                )}
              >
                写真未設定
              </Checkbox>
            </Grid>
          </FormLabel>
        </Stack>
      </Stack>
      <Box>
        <Divider borderColor={"#D4D4D4"} />
      </Box>
      <Stack spacing={4} p={4}>
        <Flex
          flexDirection="column"
          gap="1rem"
          padding="1rem 0"
          color="#171717"
          bgColor="#fff"
          borderRadius={4}
        >
          <Text
            fontSize="1.4rem"
            mb="1rem"
            whiteSpace="nowrap"
            fontWeight="bold"
          >
            ピンをステータスで絞り込む
          </Text>
          {Object.values(DocumentCategoryStatusType).map((type, index) => (
            <Flex gap="5px" alignItems="center" key={index}>
              <Checkbox
                sx={{
                  "[aria-hidden=true][data-checked],[aria-hidden=true][data-checked]:hover":
                    {
                      borderColor: "#009BE0 !important",
                      bgColor: "#009BE0 !important",
                    },
                  "[data-focus]": {
                    boxShadow: "none!important",
                  },
                  top: "1px",
                }}
                isChecked={filterDataTemp?.documentStatus?.includes(type)}
                onChange={(e) => {
                  const checked = (e.target as any).checked;
                  let newStatus = [...filterDataTemp?.documentStatus];
                  if (checked) {
                    newStatus.push(type);
                  } else {
                    newStatus = newStatus.filter((t) => t !== type);
                  }
                  resetItemSelected();
                  onChange(newStatus, "documentStatus");
                }}
              />

              <Text
                w="7rem"
                py="1px"
                textAlign="center"
                borderRadius={3}
                color={getColorTextByStatus(type)}
                bgColor={
                  MapDocumentCategoryStatusTypeColor[
                    type as DocumentCategoryStatusType
                  ]
                }
              >
                {
                  MapDocumentCategoryStatusType[
                    type as DocumentCategoryStatusType
                  ]
                }
              </Text>
            </Flex>
          ))}
        </Flex>
      </Stack>
    </Stack>
  );
};

export default FilterBoxDocument;

import { MessageType } from "constants/websocket";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useEffect, useState } from "react";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { DataLog } from "interfaces/models/dataLog";
import { useDispatch } from "react-redux";
import { sortArrayByField } from "utils/array";
import { setDataLogTemp } from "redux/documentSlice";
import useDocumentLogs from "hooks/useDocumentLogs";
import { addOrUpdateLog } from "models/dataLog";

interface Props {
  itemId?: string;
  documentType?: string;
  documentTemplate: DocumentTemplateDTO | undefined;
  dataLogTemp: DataLog | undefined;
}

const useItemLogs = (props?: Props) => {
  const { dataLogTemp, documentTemplate, itemId, documentType } = props || {};
  const dispatch = useDispatch();

  const { webSocketMessages, sendMessageToCommonChannel } =
    useForgeViewerContext();

  const {
    isLoadMoreLogs,
    mapDynamicFieldTitle,
    dataLogs: itemLogs,
    setDataLogs: setItemLogs,
    initDataLogs,
    isInit,
  } = useDocumentLogs({
    paramsGetDataLogs: { itemId, documentType },
    documentTemplate,
  });

  useEffect(() => {
    if (!webSocketMessages.length || !isInit) {
      return;
    }

    webSocketMessages.forEach((e) => {
      const { type, data, docItemId } = e;
      if (type === MessageType.ADD_DOCUMENT_ITEM_LOG && docItemId === itemId) {
        setItemLogs(addOrUpdateLog(data));
      } else if (type === MessageType.RELOAD_DOCUMENT) {
        initDataLogs();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages, itemId]);

  useEffect(() => {
    if (!dataLogTemp || !itemId) {
      return;
    }

    if (dataLogTemp?.itemId === itemId) {
      setItemLogs((prev) =>
        sortArrayByField<DataLog>([dataLogTemp, ...prev], "createdAt", false)
      );
      dispatch(setDataLogTemp(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLogTemp, itemId]);

  // init data for logs
  useEffect(() => {
    if (!itemId) {
      return;
    }

    initDataLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return {
    itemLogs,
    isLoadMoreLogs,
    setItemLogs,
    mapDynamicFieldTitle,
    sendMessageToCommonChannel,
  };
};

export default useItemLogs;

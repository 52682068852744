import { DocumentGroupDTO } from "interfaces/dtos/documentGroupDTO";
import useDocumentLogs from "hooks/useDocumentLogs";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { useCallback, useEffect } from "react";
import { DataLog } from "interfaces/models/dataLog";
import { getLocalStorageUser } from "utils/user";
import { documentGroupApi } from "apiClient/v2";
import { updateDocumentGroup } from "redux/documentSlice";
import { useDispatch } from "react-redux";
import { addOrUpdateLog, insertDataLogToIndexedDb } from "models/dataLog";
import { sortArrayByField } from "utils/array";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { MessageType } from "constants/websocket";

interface Props {
  groupId?: string;
}

const useGroupLogs = (props: Props) => {
  const { groupId } = props || {};
  const { socket, webSocketMessages } = useForgeViewerContext();
  const {
    isLoadMoreLogs,
    dataLogs: groupLogs,
    setDataLogs: setGroupLogs,
    initDataLogs,
    isInit,
  } = useDocumentLogs({
    paramsGetDataLogs: { groupId },
    documentTemplate: undefined,
  });
  const dispatch = useDispatch();

  // init data for logs
  useEffect(() => {
    if (!groupId) {
      return;
    }
    initDataLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const handleRevertLog = useCallback(
    async (
      dataLog: DataLog,
      formInfo: DocumentGroup | undefined,
      setFormInfo: (val: DocumentGroup | undefined) => void
    ) => {
      if (!formInfo?.id) {
        return;
      }
      const field = dataLog.field as keyof DataLog;
      const logData = dataLog?.value?.data;
      const currentUser = getLocalStorageUser();

      const response = await documentGroupApi.updateGroup({
        id: formInfo.id,
        [field]: logData,
        isRevertDataByLog: true,
      });
      const payload = { ...formInfo, [field]: logData };
      dispatch(updateDocumentGroup(payload));
      const log = await insertDataLogToIndexedDb({
        contentLogParams: {
          field: field as any,
          value: logData,
          isTypeRevert: true,
        },
        createdBy: currentUser?.id!,
        identifyValue: formInfo.id,
        identifyCol: "groupId",
      });
      setFormInfo({
        ...formInfo,
        [field]: logData,
        updatedAt: new Date(),
      } as DocumentGroup);

      setGroupLogs((prev) =>
        sortArrayByField<DataLog>([log, ...prev], "createdAt", false)
      );
      socket.changeDocGroup(formInfo?.id, {
        ...payload,
        updatedAt: response.data.updatedAt,
      });
      socket.addDocGroupLog(formInfo?.id, log);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    if (!webSocketMessages.length) return;

    webSocketMessages.forEach((e) => {
      if (e?.type !== MessageType.ADD_GROUP_LOG || !isInit) return;
      const { data, docGroupId } = e;
      if (docGroupId !== groupId) return;
      setGroupLogs(addOrUpdateLog(data));
    });
  }, [webSocketMessages, groupId]);

  // init data for logs
  useEffect(() => {
    if (!webSocketMessages.length) return;

    webSocketMessages.forEach((e) => {
      if (e?.type === MessageType.RELOAD_DOCUMENT) {
        initDataLogs();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  return {
    groupLogs,
    isLoadMoreLogs,
    setGroupLogs,
    handleRevertLog,
  };
};

export default useGroupLogs;

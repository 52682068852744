import { Flex, Text, FlexProps, Tooltip, Box, Spinner } from "@chakra-ui/react";
import IconBoardEnable from "components/icon/IconBoardEnable";
import useUserOfProject from "hooks/useUserOfProject";

interface OfflineLabelProps extends FlexProps {
  blockedBy: string;
}

const OfflineLabel: React.FC<OfflineLabelProps> = ({ blockedBy, ...props }) => {
  const { listAllUserById, isFetchingUserAssigned, isFetchingUsers } =
    useUserOfProject();

  let name: any = listAllUserById[blockedBy]?.name;

  if (!name && (isFetchingUserAssigned || isFetchingUsers)) {
    name = <Spinner style={{ position: "relative", top: "0.25rem" }} />;
  }

  const text = (
    <>
      <Text
        position={"relative"}
        as="span"
        fontWeight={700}
        fontSize={"1.2rem"}
      >
        オフライン作業中！
      </Text>
      <Text position={"relative"} as="span" fontWeight={500} fontSize={"1rem"}>
        編集しないでください
      </Text>
      <Text
        position={"relative"}
        as="span"
        fontWeight={700}
        fontSize={"1.2rem"}
      >
        （{name}）
      </Text>
    </>
  );

  return (
    <Tooltip
      label={
        <Flex
          alignItems={"center"}
          p={"0.25rem 0.5rem"}
          minHeight={"2.4rem"}
          whiteSpace={"nowrap"}
          position={"relative"}
          top="0.2rem"
        >
          {text}
        </Flex>
      }
    >
      <Box
        background="#EF4444"
        color="#fff"
        maxW={"100%"}
        width="100%"
        p={"0.25rem 0.5rem"}
        display="block"
        flexDirection="row"
        minHeight={"2.4rem"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        lineHeight={"1.5"}
        verticalAlign={"middle"}
        {...props}
      >
        <IconBoardEnable
          w="1.8rem"
          h="1.8rem"
          mr="0.5rem"
          display={"inline-block"}
          position={"relative"}
          sx={{
            path: {
              fill: "#fff",
            },
          }}
        />
        {text}
      </Box>
    </Tooltip>
  );
};

export default OfflineLabel;

import { Box, Flex, Img, Spinner, Text } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import useKeynoteImage from "components/modal/PreviewDocumentCategory/hooks/useKeynoteImage";
import KeyLabel from "components/ui/KeyLabel";
import { PresignedImageWithRef } from "components/ui/PresignedImage";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import { defaultImagePath } from "constants/file";
import { KEYNOTE_IMAGE_CLASSNAME } from "constants/styleProps";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { TemplateComponent } from "interfaces/models/component";
import { KeynoteImageData } from "interfaces/models/documentKeynote";
import { getColorTextByStatus } from "models/document";
import { memo, useContext, useMemo, useRef, useState } from "react";

const ImgPresigned = withPresignedUrl(Img);

interface Props {
  component: TemplateComponent;
  maxHeight?: number;
  imageUrl?: string;
  isLoadingKeyNote?: boolean;
  keynoteImageDataProp?: KeynoteImageData;
}

export interface Size {
  width: number;
  height: number;
}

const CONTAINER_PADDING = 20;

function Image(props: Props) {
  const {
    imageUrl = defaultImagePath,
    component,
    keynoteImageDataProp,
    isLoadingKeyNote,
    maxHeight,
  } = props;
  const [isImageError, setIsImageError] = useState(false);

  const keynoteImageRef = useRef<HTMLImageElement | null>(null);
  const { keynoteImageData: keynoteImageDataContext, isChangeSheet } =
    useContext(PreviewDocumentCategoryContext);

  const keynoteImageData = keynoteImageDataProp || keynoteImageDataContext;

  const {
    keynoteContainerRef,
    keynoteImage,
    isLoadKeynoteImage,
    keyLabelsProps,
    keynoteImageSize,
    onLoadKeynoteImage,
  } = useKeynoteImage({
    isChangeSheet,
    keynoteImageData,
  });

  const imageContainerRef = useRef<HTMLImageElement>(null);

  const isKeyNoteComponent = useMemo(
    () => component?.detail?.checkedImage,
    [component?.detail?.checkedImage]
  );

  const renderContent = () => {
    if (isImageError) {
      return (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              color: "#171717",
            }}
          >
            Image Error
          </Text>
        </Box>
      );
    }

    if (!isKeyNoteComponent) {
      return (
        <ImgPresigned
          src={imageUrl}
          id="keynote"
          alt=""
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            margin: "auto",
            display: "block",
          }}
          onLoad={onLoadKeynoteImage}
          crossOrigin="anonymous"
        />
      );
    }

    const isLoading =
      (keynoteImageData !== null &&
        (isLoadKeynoteImage || !keynoteImage || isChangeSheet)) ||
      isLoadingKeyNote;

    return (
      <>
        <Box
          ref={keynoteContainerRef}
          style={{
            position: "relative",
            width: "fit-content",
            margin: "0 auto",
            height: "fit-content",
          }}
        >
          <PresignedImageWithRef
            src={keynoteImage}
            id={KEYNOTE_IMAGE_CLASSNAME}
            _ref={keynoteImageRef}
            crossOrigin="anonymous"
            alt=""
            width="100%"
            height="auto"
            isConvertToBase64={true}
            onLoad={onLoadKeynoteImage}
            callBackPreSignError={() => {
              setIsImageError(true);
            }}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: maxHeight ? `${maxHeight}px` : "100%",
              border: "1px solid #555",
              borderWidth: "0.5px 1px 0.5px 0.5px",
              margin: "auto",
            }}
          />
          {!isLoading &&
            keyLabelsProps?.map((item) => (
              <KeyLabel
                key={item.id}
                {...item}
                imageSize={keynoteImageSize}
                colorText={getColorTextByStatus(item?.status)}
                color={
                  MapDocumentCategoryStatusTypeColor[
                    (item?.status ||
                      DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
                  ]
                }
              />
            ))}
        </Box>

        {isLoading && (
          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            width="100%"
            height="100%"
            top="50%"
            left="50%"
            transform="translate(-50%,-50%)"
            backgroundColor="#fff"
          >
            <Spinner color="blue.500" size="lg" />
          </Flex>
        )}
      </>
    );
  };

  return (
    <Box
      id="img-container"
      ref={imageContainerRef}
      style={{
        width: "100%",
        height: "100%",
        padding: isKeyNoteComponent ? 0 : `${CONTAINER_PADDING}px`,
        border: isKeyNoteComponent ? "none" : "1px solid #ccc",
        display: "flex",
        alignItems: "center",
      }}
    >
      {renderContent()}
    </Box>
  );
}

export default memo(Image);

import { Button } from "@chakra-ui/react";
import { memo } from "react";
import { SvgIcon } from "components/SvgIcon";

interface Props {
  isEditMode: boolean;

  onClose: () => void;
}

const ClosePreviewDocument = (props: Props) => {
  const { isEditMode, onClose } = props;

  return (
    <Button
      variant="text"
      position="sticky"
      left="93%"
      zIndex="overlay"
      top={`${isEditMode ? 130 : 80}px`}
      cursor="pointer"
      boxShadow="none !important"
      backgroundColor="transparent"
      mixBlendMode="difference"
      sx={{
        _hover: {
          mixBlendMode: "normal",
          "& > div": {
            background: "font.blue",
          },
        },
      }}
      onClick={onClose}
    >
      <SvgIcon
        width="3.2rem"
        height="3.2rem"
        pathFill="white"
        src="/img/icon-navigation-close.svg"
      />
    </Button>
  );
};

export default memo(ClosePreviewDocument);

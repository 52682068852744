import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { NeptuneArea } from "interfaces/models/area";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import { useCallback, useMemo } from "react";
import { RootState } from "redux/store";
import { TableComponentNodeAttrs } from "../extendsions/TableComponentNode";
import StructureTable from "../helper/StructureTable";
import { CustomNode, PageInfo } from "../type";

interface Props {
  template: DocumentTemplate | undefined;
  documentContainerSize: RootState["document"]["documentContainerSize"];
  zoomRatio: number;
  documentItems: DocumentItem[] | BlackBoardInfo[];
  blackboardTemplateProps?: iBlackboardTemplateProps;
  projectName: string;
  listUserById?: {
    [key: string]: User | null;
  };
  familyInstances: { [key: string]: FamilyInstanceDTO };
  documentCategorySelected: DocumentCategoryDTO | undefined;
  companiesById: Record<string, PartnerCompany>;
  neptuneAreas: NeptuneArea[] | undefined;
}

const useGetStructureTableComponentNode = (props: Props) => {
  const {
    template,
    documentContainerSize,
    zoomRatio,
    documentItems,
    projectName,
    listUserById,
    blackboardTemplateProps,
    familyInstances,
    documentCategorySelected,
    companiesById,
    neptuneAreas = [],
  } = props;

  const mapTitleAreaById: Record<string, string> = useMemo(() => {
    const mapObject = {};

    neptuneAreas.forEach((item) => {
      Object.assign(mapObject, {
        [String(item?.id)]: item?.name,
      });
    });

    return mapObject;
  }, [neptuneAreas]);

  const getStructureTableComponent = useCallback(
    (params: {
      component: TemplateComponent;
      page: PageInfo;
    }): CustomNode<TableComponentNodeAttrs> | null => {
      const { component, page } = params;
      if (!template?.id) {
        return null;
      }

      const structureTable = new StructureTable({
        component,
        page,
        template,
        documentItems,
        documentContainerSize,
        zoomRatio,
        projectName,
        blackboardTemplateProps,
        listAllUserById: listUserById as any,
        familyInstances,
        documentItem: page.documentItem,
        documentCategory: documentCategorySelected,
        companiesById,
        mapTitleAreaById,
      });

      return structureTable.getStructure();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mapTitleAreaById,
      companiesById,
      projectName,
      blackboardTemplateProps,
      listUserById,
      familyInstances,
      documentCategorySelected,
      documentContainerSize,
      zoomRatio,
      documentItems,
      template?.id,
      template?.documentType,
      template?.pages,
      template?.components,
    ]
  );

  return { getStructureTableComponent };
};

export default useGetStructureTableComponentNode;

import { setIsMoveTaskLabel, setModalType } from "redux/forgeViewerSlice";
import { useDisclosure } from "@chakra-ui/react";
import {
  setAllDocItemAndDocCategory,
  setDocumentCategorySelected,
  setDocumentGroupSelected,
  setDocumentItemSelected,
} from "redux/documentSlice";
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocumentCategory } from "interfaces/models/documentCategory";
import {
  createCategory,
  updateCategory,
} from "apiClient/v2/documentCategoryApi";
import { useParams } from "react-router-dom";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { RootState } from "redux/store";
import { DocumentCategoryKey, ModalType } from "constants/enum";
import useLevel from "hooks/useLevel";
import {
  getFilterDocumentCategories,
  isSelfInspectionTemplate,
  doMapDocumentCategories,
} from "models/documentCategory";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { message } from "components/base";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import { OPTION_ALL_FLOOR } from "constants/area";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { arrayToObject } from "utils/object";
import { addIndexedDBItem } from "utils/serviceWorker";
import {
  Operation,
  StoreName,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { useForgeViewerContext } from "../ForgeViewerContext";
import { dataLogApi } from "apiClient/v2";

interface Props {
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps;
  handleClickDocumentCategory(documentCategory: DocumentCategoryDTO): void;
}

const useAddDocumentCategory = ({
  filterDocumentCategoryOptions,
  handleClickDocumentCategory,
}: Props) => {
  const {
    documentItems,
    documentTemplates,
    documentCategories,
    documentCategorySelected,
    documentGroupSelected,
    documentGroups,
  } = useSelector((state: RootState) => state.document);
  const { socket } = useForgeViewerContext();
  const { levelSelected, levels } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { handleChangeFloor } = useLevel(dataProjectDetail?.id);

  const { bimFileId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [defaultValue, setDefaultValue] = useState<Partial<DocumentCategory>>(
    {}
  );

  useEffect(() => {
    if (documentGroupSelected) {
      setDefaultValue({
        [DocumentCategoryKey.GROUP_ID]: documentGroupSelected.id,
      });
    } else {
      setDefaultValue({});
    }
  }, [documentGroupSelected]);

  useEffect(() => {
    if (documentCategorySelected) {
      setDefaultValue({});
    }
  }, [documentCategorySelected]);

  const save = useCallback(
    async (category: Partial<DocumentCategory>, template: DocumentTemplate) => {
      if (loading) return;

      if (!dataProjectDetail?.projectId) {
        message.error("プロジェクトが無効であります。");

        return;
      }

      setLoading(true);

      /**
       * When only update a document category
       * Cause:
       * - (use effect) Left sidebar auto scroll to position of document category selected
       * - If we change parent folder of the document category editing, effect above will not fire
       */
      if (category.id && category.groupId !== defaultValue?.groupId) {
        dispatch(setDocumentCategorySelected());
      }
      try {
        const { data: response } = category.id
          ? await updateCategory({
              ...category,
              id: category.id!,
            })
          : await createCategory({
              category: {
                projectId: dataProjectDetail.projectId,
                bimFileId,
                ...category,
              },
            });

        let changeCategories: DocumentCategoryDTO[] = Array.isArray(response)
          ? response
          : [response];
        // Because when updating category, some fields like userAssigned on the server side will not be returned
        // So we should use old data to overwrite
        if (category.id) {
          const mapDocumentCategoriesById = arrayToObject(
            documentCategories,
            DocumentCategoryKey.ID
          );
          changeCategories = changeCategories.map(
            (category: DocumentCategoryDTO) => ({
              ...(mapDocumentCategoriesById[category.id] || {}),
              ...category,
            })
          );
        }
        const setAddDocumentCategoryId = new Set(
          changeCategories.map((item: DocumentCategoryDTO) => item.id)
        );
        let newAllDocumentCategories = documentCategories.filter(
          (item) => !setAddDocumentCategoryId.has(item.id)
        );
        newAllDocumentCategories =
          newAllDocumentCategories.concat(changeCategories);
        const newCloneDocumentCategories = newAllDocumentCategories.map(
          (cate) => {
            return { ...cate, documentItems: [] };
          }
        );
        const setDocumentItemIds = new Set();
        const changeDocumentItems: DocumentItemDTO[] = [];
        const isSelfInspection = isSelfInspectionTemplate(
          changeCategories?.[0]?.documentType
        );
        changeCategories.forEach((cate: DocumentCategoryDTO) => {
          (cate.documentItems || []).forEach((item: DocumentItemDTO) => {
            // cause self inspection document item will created on server
            // then add item to indexedDB to override data on offline
            if (isSelfInspection) {
              addIndexedDBItem({
                id: item.id,
                data: {
                  requestTime: Date.now(),
                  operation: Operation.Post,
                  store: StoreName.DOCUMENT_ITEMS,
                  status: UpdateToOnlineStatus.Success,
                  data: item,
                } as iCachedItem,
              });
            }

            if (!setDocumentItemIds.has(item.id)) {
              setDocumentItemIds.add(item.id);
              changeDocumentItems.push(item);
            }
          });
        });
        const newAllDocumentItems = documentItems
          .filter((elm) => !setDocumentItemIds.has(elm.id))
          .concat(changeDocumentItems);

        const {
          documentCategories: newDocumentCategories,
          documentItems: newDocumentItems,
        } = doMapDocumentCategories({
          documentCategories: newCloneDocumentCategories,
          documentItems: structuredClone(newAllDocumentItems),
          skipRemappingDocumentItem: true,
          documentTemplates: Object.values(documentTemplates),
        });
        const currentDocumentCategory = newDocumentCategories.find((cate) => {
          const matchingLevel =
            category.level === OPTION_ALL_FLOOR
              ? cate.level === levelSelected.label
              : cate.level === category.level;

          return cate.id === changeCategories[0]?.id && matchingLevel;
        });

        dispatch(
          setAllDocItemAndDocCategory({
            documentCategories: newDocumentCategories,
            documentItems: newDocumentItems,
          })
        );

        // Remove document items before sync
        const docSync = changeCategories.map(
          ({ documentItems, ...other }) => other
        );

        socket.changeDocCategories(docSync, !category.id);

        if (currentDocumentCategory) {
          dispatch(setDocumentItemSelected());
          dispatch(setDocumentGroupSelected());
          dispatch(setModalType(ModalType.DOC_CATEGORY));
          // Redirect to level of new category
          if (levelSelected.label !== currentDocumentCategory?.level) {
            const level = levels.find(
              (node) => node.label === currentDocumentCategory?.level
            );
            dispatch(setDocumentCategorySelected(currentDocumentCategory));
            level && handleChangeFloor(level.guid, false);
            dispatch(setIsMoveTaskLabel(false));
          } else {
            const { categories } = getFilterDocumentCategories({
              filterDocumentCategoryOptions,
              documentCategories: [currentDocumentCategory],
              levelSelected,
              documentGroups,
            });
            if (categories.length) {
              // matching with filter
              handleClickDocumentCategory(currentDocumentCategory);
            }
          }
        }
        message.success([
          category?.id ? "書類の更新" : "書類の追加",
          `${category.title ?? defaultValue?.title}を${
            category?.id ? "更新" : "追加"
          }しました。`,
        ]);
        setLoading(false);
        onClose();
        setDefaultValue({});
      } catch (e) {
        message.error("書類テンプレートの作成がエラーです。");
        setLoading(false);
      }
    },
    [
      loading,
      dataProjectDetail?.projectId,
      bimFileId,
      documentCategories,
      documentItems,
      documentTemplates,
      levelSelected,
      levels,
      filterDocumentCategoryOptions,
      documentGroups,
      dispatch,
      handleChangeFloor,
      onClose,
      handleClickDocumentCategory,
      defaultValue?.title,
      defaultValue?.groupId,
      socket,
    ]
  );

  const close = useCallback(() => {
    if (documentGroupSelected) {
      setDefaultValue({
        [DocumentCategoryKey.GROUP_ID]: documentGroupSelected.id,
      });
    } else {
      setDefaultValue({});
    }
    onClose();
  }, [onClose, setDefaultValue, documentGroupSelected]);

  const open = useCallback(() => {
    dispatch(setDocumentCategorySelected());
    dispatch(setDocumentItemSelected());
    onOpen();
  }, [dispatch, onOpen]);

  return {
    loading,
    isOpen,
    onClose: close,
    onOpen: open,
    save,
    defaultValue,
    setDefaultValue,
  };
};

export default useAddDocumentCategory;

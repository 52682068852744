import { useBoolean } from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import { documentApi, documentKeyNoteApi } from "apiClient/v2";
import { iKeyLabel } from "components/ui/KeyLabel";
import { S3_PATH } from "constants/s3";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DocumentKeyNote } from "interfaces/models/documentKeynote";
import { createDataGenerateForDocument } from "models/document";
import {
  getLabelsOfKeynote,
  transformKeylabelsByEdited,
} from "models/documentTask";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch } from "react-redux";
import { setStatusContentFromS3 } from "redux/documentSlice";
import { uuid } from "utils/common";
import { removeFileS3, uploadFileToS3 } from "utils/file";

export const DEFAULT_PAGE_ZOOM = 100;
const DEFAULT_PAGE_ZOOM_IPAD = 70;

interface Props {
  isOpen: boolean;
  editor: Editor | null;
  currentDocument: DocumentDTO | null;
  documentCategorySelected: DocumentCategoryDTO | undefined;
  documentItemSelected: DocumentItemDTO | undefined;
  onChangeCurrentDocument: (content: any) => void;

  onClose: () => void;
}

const usePreviewDocumentHeader = (props: Props) => {
  const {
    isOpen,
    documentCategorySelected,
    documentItemSelected,
    currentDocument,
    editor,
    onClose,
    onChangeCurrentDocument,
  } = props;

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const [pageZoom, setPageZoom] = useState<number>(() =>
    isMobile ? DEFAULT_PAGE_ZOOM_IPAD : DEFAULT_PAGE_ZOOM
  );
  const [isResetDataKeynote, setIsResetDataKeynote] = useBoolean(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [keyLabelsEdited, setKeyLabelsEdited] = useState<Partial<iKeyLabel>[]>(
    []
  );
  const [isSavingDocument, setIsSavingDocument] = useState(false);

  const { socket } = useForgeViewerContext();
  const dispatch = useDispatch();

  const {
    keynoteImageData,
    documentKeynoteData,
    setKeynoteImageData,
    setDocumentKeynoteData,
  } = useContext(PreviewDocumentCategoryContext);

  useEffect(() => {
    if (isOpen) {
      setIsEditMode(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEditMode || isOpen) {
      setKeyLabelsEdited([]);
    }
  }, [isEditMode, isOpen]);

  const onBeforeClose = useCallback(() => {
    const docCateModalEle = document.getElementById(
      RIGHT_SIDEBAR_MODAL_CLASSNAME
    );

    if (docCateModalEle) {
      docCateModalEle.style.display = "flex";
    }

    onClose();
    setIsEditMode(false);
  }, [onClose]);

  const onChangeEditMode = useCallback(
    (value: boolean) => {
      if (value) {
        setIsResetDataKeynote.off();
      }
      setIsEditMode(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCancelEditMode = useCallback(() => {
    setIsResetDataKeynote.on();
    setIsEditMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callbackDragKeynoteStop = useCallback(
    (keylabel: Partial<iKeyLabel>) => {
      setKeyLabelsEdited((prev) => {
        const res = structuredClone(prev);
        const index = res.findIndex((item) => item.id === keylabel?.id);
        if (index !== -1) {
          res[index] = keylabel;
        } else {
          res.push(keylabel);
        }

        return res;
      });
    },
    []
  );

  const onSaveKeynoteMoved = useCallback(async () => {
    const currentGuid = keynoteImageData.guid;
    const keylabelByGuid = getLabelsOfKeynote(keynoteImageData)?.labelByGuid;
    if (
      !keyLabelsEdited?.length ||
      !keylabelByGuid ||
      !documentKeynoteData?.documentCategoryId
    ) {
      return;
    }
    const newKeynoteImageData = structuredClone(keynoteImageData);
    const keyLabels = keylabelByGuid?.keyLabels || [];
    const currentKeyLabelsEdited = keylabelByGuid?.keyLabelsEdited || [];

    const { cloneKeyLabels, cloneKeyLabelsEdited } = transformKeylabelsByEdited(
      {
        keyLabels,
        currentKeyLabelsEdited,
        keyLabelsEdited,
      }
    );

    keylabelByGuid.keyLabelsEdited = cloneKeyLabelsEdited;
    keylabelByGuid.keyLabels = cloneKeyLabels;
    newKeynoteImageData.mapLabelsByGuid = {
      ...newKeynoteImageData.mapLabelsByGuid,
      [currentGuid]: keylabelByGuid,
    };

    const newDocumentKeynoteData: DocumentKeyNote = {
      ...documentKeynoteData,
      keynoteImageData: newKeynoteImageData,
    };

    setDocumentKeynoteData(newDocumentKeynoteData);
    setKeynoteImageData(newKeynoteImageData);
    await documentKeyNoteApi.updateKeynote(newDocumentKeynoteData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentKeynoteData, keyLabelsEdited, keynoteImageData]);

  const onSaveEditor = useCallback(async () => {
    if (!editor) {
      return;
    }

    try {
      const now = new Date();
      const content = editor.getJSON();
      const documentId = currentDocument?.id;

      setIsSavingDocument(true);
      dispatch(setStatusContentFromS3(true));
      const { dataGenerate } = await createDataGenerateForDocument(
        documentCategorySelected,
        documentItemSelected
      );

      const documentUrl = await uploadFileToS3(
        new File([JSON.stringify(content)], uuid()),
        uuid(),
        S3_PATH.Document
      );

      const bodyDocumentReq: DocumentDTO = {
        ...currentDocument,
        id: documentId || "",
        bimFileId: documentCategorySelected?.bimFileId || "",
        documentCategoryId: documentCategorySelected?.id || "",
        title: currentDocument?.title || documentCategorySelected?.title || "",
        createdAt: currentDocument?.createdAt || now,
        updatedAt: now,
        dataGenerate,
      };

      bodyDocumentReq.content = documentUrl;
      let data: DocumentDTO | undefined = undefined;
      const promiseList: Promise<any>[] = [];

      if (!documentId) {
        promiseList.push(
          documentApi.createDocument(bodyDocumentReq).then((res) => {
            data = res.data;
          })
        );
      } else {
        promiseList.push(
          documentApi
            .updateDocument({ ...bodyDocumentReq, id: documentId })
            .then((res) => {
              data = res.data;
            })
        );
      }

      promiseList.push(onSaveKeynoteMoved());
      await Promise.all(promiseList);

      if (documentUrl && currentDocument?.content) {
        removeFileS3({ keys: [currentDocument.content] });
      }

      const res = data;

      if (res) {
        socket.changeCurrentDocument(res);
      }

      setIsEditMode(false);
      setIsSavingDocument(false);
      dispatch(setStatusContentFromS3(true));
      onChangeCurrentDocument(bodyDocumentReq);

      return content;
    } catch (error) {
      setIsSavingDocument(false);
      dispatch(setStatusContentFromS3(false));
      setIsEditMode(false);

      return;
    }
  }, [
    documentItemSelected,
    documentCategorySelected,
    editor,
    currentDocument,
    socket,
    onChangeCurrentDocument,
    onSaveKeynoteMoved,
    dispatch,
  ]);

  const onChangePageZoom = useCallback((_: string, value: number) => {
    setPageZoom(value);
  }, []);

  return {
    isResetDataKeynote,
    pageZoom,
    isSavingDocument,
    isEditMode,
    onBeforeClose,
    onChangeEditMode,
    onCancelEditMode,
    onSaveEditor,
    callbackDragKeynoteStop,
    onChangePageZoom,
  };
};

export default usePreviewDocumentHeader;

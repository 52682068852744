import { Attribute, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import ImageWithBlackboard from "components/editor-builder/component-preview/TableComponentPreview/ui/ImageWithBlackboard";
import { Blackboard } from "interfaces/models/blackboard";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { CustomNodeComponentProps, NodeType } from "../type";

export interface ImageWithBlackboardNodeAttrs {
  width: number;
  height: number;
  imageSrc: string | undefined;
  isShowBlackboard?: boolean;
  blackboardImagePosition: any;
  blackboard: Blackboard;
  blackboardTemplateProps: iBlackboardTemplateProps;
}

export const ImageWithBlackboardNode = Node.create({
  name: NodeType.IMAGE_WITH_BLACKBOARD,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  addAttributes() {
    const attrs: { [key in keyof ImageWithBlackboardNodeAttrs]: Attribute } = {
      imageSrc: { default: null },
      width: { default: 0 },
      height: { default: 0 },
      isShowBlackboard: { default: null },
      blackboardImagePosition: { default: [] },
      blackboard: { default: {} },
      blackboardTemplateProps: { default: {} },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "image-with-blackboard",
      },
    ];
  },
  renderHTML() {
    return ["image-with-blackboard"];
  },
  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = (
  props: CustomNodeComponentProps<ImageWithBlackboardNodeAttrs>
) => {
  const {
    imageSrc,
    width,
    height,
    isShowBlackboard,
    blackboardImagePosition,
    blackboard,
    blackboardTemplateProps,
  } = props.node.attrs;

  return (
    <NodeViewWrapper
      style={{
        position: "absolute",
        inset: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <ImageWithBlackboard
        imageSrc={imageSrc}
        isShowBlackboard={isShowBlackboard}
        blackboardImagePosition={blackboardImagePosition}
        blackboard={blackboard}
        blackboardTemplateProps={blackboardTemplateProps}
        boxLoadingWidth={width}
        boxLoadingHeight={height}
      />
    </NodeViewWrapper>
  );
};

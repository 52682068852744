import { SystemModeType } from "constants/enum";
import { Vector3 } from "interfaces/models";
import store from "redux/store";
import { logDev } from "utils/logs";
import { clearForgeSelection, pointerToRaycaster } from "..";
import { calculatePositionFromSheet } from "../forge2d";
import { AreaMesh, getAreaExtension } from "./area-extension";
import { isEmpty } from "lodash";
import { getExternalIdByDbId, __familyInstances } from "../data";

export const SINGLE_CLICK_EVENT = "SingleClickEvent";

export interface ClickInfo {
  originalEvent?: any;
  forgeData?: {
    dbId?: number;
    externalId?: string;
    position: Vector3 | THREE.Vector3;
    level?: string;
    fragId?: number;
    distance?: number;
    rotation?: Vector3 | THREE.Vector3;
    type?: string;
    fov?: string;
    direction?: string;
    mesh?: THREE.Mesh;
    uranusStepId?: string;
    uranus_url?: string;
  };
  areaData?: AreaMesh;
}

export class ClickTool {
  private viewer: Autodesk.Viewing.GuiViewer3D;
  private name: string;
  constructor(viewer: Autodesk.Viewing.GuiViewer3D) {
    this.viewer = viewer;
    this.name = ClickTool.name;
  }

  getLevelHeight() {
    const levelSelected = store.getState().forgeViewer.levelSelected;
    if (!levelSelected.guid || (!levelSelected.zMax && !levelSelected.zMin)) {
      return 0;
    }

    return !!levelSelected?.zMin
      ? levelSelected?.zMin
      : levelSelected?.zMax || 0;
  }

  canSelectPointOnForge = () => {
    const state = store.getState();

    return !(
      (state.forgeViewer.systemMode === SystemModeType.Document &&
        (state.document.documentCategorySelected ||
          state.document.documentItemSelected)) ||
      ((state.forgeViewer.isCreateTask || !isEmpty(state.task.taskSelected)) &&
        state.forgeViewer.systemMode === SystemModeType.Task)
    );
  };

  async handleSingle3dClick(event: any, button: number): Promise<boolean> {
    if (button !== 0) {
      return true;
    }

    const canSelectOnForge = this.canSelectPointOnForge();
    if (canSelectOnForge) {
      clearForgeSelection(this.viewer);
    }
    const clickInfo: ClickInfo = {
      originalEvent: event,
    };

    const position = this.viewer.impl.intersectGround(
      event.canvasX,
      event.canvasY
    ) || { x: 0, y: 0, z: 0 };

    const levelSelected = store.getState().forgeViewer.levelSelected;
    clickInfo.forgeData = {
      level: levelSelected.label,
      position: { x: position.x, y: position.y, z: this.getLevelHeight() },
    };

    const hitTest = this.viewer.impl.hitTest(event.canvasX, event.canvasY);
    const dbId = hitTest?.dbId;
    if (dbId) {
      const externalId = (await getExternalIdByDbId(hitTest?.dbId)) || "";
      const instance = __familyInstances[externalId];
      logDev(instance);
      const isIncludeDwg =
        !!instance?.systemType?.includes(".dwg") ||
        !!instance?.typeName?.includes(".dwg");

      if (hitTest && !isIncludeDwg) {
        const position = hitTest.intersectPoint || hitTest.point;
        if (event.target?.closest("canvas")) {
          this.viewer.select([hitTest.dbId]);
        }
        clickInfo.forgeData = {
          level: levelSelected.label,
          position,
          dbId: hitTest.dbId,
          externalId,
          fragId: hitTest.fragId,
          distance: hitTest.distance,
          mesh: hitTest.object,
        };
      }
    }

    // check area
    const raycaster = pointerToRaycaster(
      this.viewer.impl.canvas,
      this.viewer.impl.camera,
      event
    );
    const areaExtension = getAreaExtension();
    if (areaExtension) {
      const objects = (await areaExtension.getSpaces()) || [];
      const intersects = raycaster.intersectObjects(objects);
      if (intersects.length > 0) {
        const area = objects.find((o) => o.uuid === intersects[0].object.uuid);
        clickInfo.areaData = area;
      }
    }

    this.viewer.dispatchEvent({
      type: SINGLE_CLICK_EVENT,
      payload: clickInfo,
    });
    logDev("clicked", clickInfo);

    return false;
  }

  async handleSingle2dClick(event: any, button: number): Promise<boolean> {
    if (button !== 0) {
      return true;
    }

    const canSelectOnForge = this.canSelectPointOnForge();
    if (canSelectOnForge) {
      clearForgeSelection(this.viewer);
    }
    const clickInfo: ClickInfo = {
      originalEvent: event,
    };

    const position = calculatePositionFromSheet(
      this.viewer.impl.intersectGround(event.canvasX, event.canvasY)
    );

    const levelSelected = store.getState().forgeViewer.levelSelected;
    clickInfo.forgeData = {
      level: levelSelected.label,
      position: new THREE.Vector3(
        position.x,
        position.y,
        this.getLevelHeight()
      ),
    };
    const hitTest = this.viewer.impl.hitTest(event.canvasX, event.canvasY);
    const dbId = hitTest?.dbId;
    if (dbId) {
      const externalId = (await getExternalIdByDbId(hitTest?.dbId)) || "";
      const instance = __familyInstances?.[externalId];
      logDev(instance);
      const isIncludeDwg =
        !!instance?.systemType?.includes(".dwg") ||
        !!instance?.typeName?.includes(".dwg");

      if (hitTest && !isIncludeDwg) {
        const position = calculatePositionFromSheet(
          hitTest.intersectPoint || hitTest.point
        );
        if (event.target?.closest("canvas")) {
          this.viewer.select([hitTest.dbId]);
        }
        const positionZOfInstance =
          instance?.position.z || instance?.bounds.max.z;
        const positionZ =
          typeof positionZOfInstance !== "undefined"
            ? positionZOfInstance
            : this.getLevelHeight();

        clickInfo.forgeData = {
          level: levelSelected.label,
          position: new THREE.Vector3(position.x, position.y, positionZ),
          dbId: hitTest.dbId,
          externalId,
          fragId: hitTest.fragId,
        };
      }
    }

    // check area
    const raycaster = pointerToRaycaster(
      this.viewer.impl.canvas,
      this.viewer.impl.camera,
      event
    );
    const areaExtension = getAreaExtension();
    if (areaExtension) {
      const objects = (await areaExtension.getSpaces()) || [];
      const intersects = raycaster.intersectObjects(objects);
      if (intersects.length > 0) {
        const area = objects.find((o) => o.uuid === intersects[0].object.uuid);
        clickInfo.areaData = area;
      }
    }

    this.viewer.dispatchEvent({
      type: SINGLE_CLICK_EVENT,
      payload: clickInfo,
    });

    logDev("clicked", clickInfo);

    return false;
  }

  async handleSingleClick(event: any, button: number): Promise<boolean> {
    if (!this.viewer?.model) return true;

    if (this.viewer.model?.is2d()) {
      return this.handleSingle2dClick(event, button);
    }

    return this.handleSingle3dClick(event, button);
  }

  async handleDoubleClick(event: any, button: number): Promise<boolean> {
    if (!this.viewer?.model) return true;

    if (this.viewer.model?.is2d()) {
      return await this.handleSingle2dClick(event, button);
    }

    return await this.handleSingle3dClick(event, button);
  }

  handleSingleTap = async (event: any) => {
    return await this.handleSingleClick(event, 0);
  };

  handleDoubleTap = async (event: any) => {
    return await this.handleDoubleClick(event, 0);
  };

  activate(name: string, viewer: Autodesk.Viewing.GuiViewer3D) {
    this.viewer = viewer;
    this.name = name;
  }

  deactivate(name: string) {
    this.name = ClickTool.name;
  }

  getNames(): string[] {
    return [this.name];
  }

  getName(): string | undefined {
    return this.name;
  }
}

export class ClickExtension extends Autodesk.Viewing.Extension {
  private tool: ClickTool;
  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
    super(viewer, options);
    this.tool = new ClickTool(viewer);
  }
  load() {
    this.viewer.toolController.registerTool(this.tool);
    this.viewer.toolController.activateTool(ClickTool.name);

    return true;
  }

  unload() {
    this.viewer.toolController.deactivateTool(ClickTool.name);
    this.viewer.toolController.deregisterTool(this.tool);
    logDev("ClickExtension unloaded.");

    return true;
  }

  static register = () => {
    Autodesk.Viewing.theExtensionManager.registerExtension(
      "ClickExtension",
      ClickExtension
    );
  };
}

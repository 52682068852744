import { Flex, Spinner } from "@chakra-ui/react";
import {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMapPresignedUrl } from "redux/documentSlice";
import { RootState } from "redux/store";
import { base64toBlob, getPreSignUrl, imageUrlToBase64 } from "utils/file";

export function withPresignedUrl<T = any>(Component: ComponentType<T>) {
  return memo(
    (
      props: T & {
        src?: string;
        isOnline?: boolean;
        isConvertToBase64?: boolean;
        isCheckLoadingPresignedUrl?: boolean;
        boxLoadingWidth?: any;
        boxLoadingHeight?: any;
        isPreviewImageOnNewTab?: boolean;
        onLoadedPresignedUrl?: (url: string) => void;
      }
    ) => {
      const {
        src,
        isConvertToBase64 = false,
        isCheckLoadingPresignedUrl = true,
        boxLoadingWidth = 0,
        boxLoadingHeight = 0,
        isOnline = true,
        isPreviewImageOnNewTab = false,
        onLoadedPresignedUrl,
        ...rest
      } = props;
      const [isLoadingPresignedUrl, setIsLoadingPresignedUrl] = useState(true);
      const [presignedUrl, setPresignedUrl] = useState<string>("");
      const { mapPresignedUrl } = useSelector(
        (state: RootState) => state.document
      );
      const srcPreviewRef = useRef<any>("");
      const dispatch = useDispatch();

      const getPresignedUrl = useCallback(async () => {
        try {
          let img = src || "";

          const isBase64 = img.includes("data:image/");
          if (!img?.includes(`${process.env.REACT_APP_S3_URL}/`) || isBase64) {
            setIsLoadingPresignedUrl(false);
            setPresignedUrl(img);
            onLoadedPresignedUrl?.(img);

            return;
          }

          setIsLoadingPresignedUrl(true);
          if (
            src &&
            src.includes(`${process.env.REACT_APP_S3_URL}/`) &&
            !src.includes("X-Amz-SignedHeaders=host")
          ) {
            if (mapPresignedUrl[`${src}`]) {
              img = mapPresignedUrl[`${src}`];
            } else {
              img = await getPreSignUrl(src, "");
              dispatch(addMapPresignedUrl({ url: src, presigned: img }));
            }
          }

          if (isConvertToBase64 && !img?.includes(".mp4")) {
            img = (await imageUrlToBase64(img)) as string;
          }

          if (isPreviewImageOnNewTab) {
            srcPreviewRef.current = base64toBlob(
              (await imageUrlToBase64(img)) as string
            );
          }

          setPresignedUrl(img);
          onLoadedPresignedUrl?.(img);
          setIsLoadingPresignedUrl(false);
        } catch (err) {
          setIsLoadingPresignedUrl(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [src, isOnline, isPreviewImageOnNewTab]);

      useEffect(() => {
        getPresignedUrl();
      }, [getPresignedUrl]);

      if (isLoadingPresignedUrl && isCheckLoadingPresignedUrl) {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            width={boxLoadingWidth}
            height={boxLoadingHeight}
          >
            <Spinner
              display={
                !!boxLoadingWidth && !!boxLoadingHeight ? "initial" : "none"
              }
              color="blue.500"
            />
          </Flex>
        );
      }

      const onPreviewImageOnNewTab = () => {
        window.open(URL.createObjectURL(srcPreviewRef.current), "_blank");
        URL.revokeObjectURL(srcPreviewRef.current);
      };

      if (!presignedUrl) {
        return <></>;
      }

      return (
        <Component
          {...(rest as any)}
          src={presignedUrl}
          crossOrigin="anonymous"
          {...(isPreviewImageOnNewTab && {
            onClick: onPreviewImageOnNewTab,
          })}
        />
      );
    }
  );
}

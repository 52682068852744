import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from "@chakra-ui/react";
import { memo } from "react";

interface Props {
  options: (MenuItemProps & { title: any })[];
  icon: (isOpen: boolean) => JSX.Element;
}

const CustomMenu = (props: Props) => {
  const { options, icon } = props;

  return (
    <Menu autoSelect={false} placement="bottom-end" isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={IconButton}
            aria-label="Options"
            flexShrink={0}
            variant="text"
            _hover={{
              "& > div > div": {
                backgroundColor: "#38BDF8",
              },
            }}
            _active={{
              boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px inset, #009BE0 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px ",
            }}
            icon={(() => icon(isOpen))()}
            onClick={(e) => e.stopPropagation()}
          />
          <MenuList
            minW="13rem"
            style={{
              padding: "0px",
              margin: "0px",
              background: "#fff",
              borderRadius: "0.4rem",
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 1px 3px 0px, rgba(0, 0, 0, 0.15) 0px 4px 8px 3px",
            }}
          >
            {options.map(({ title, ...rest }, key) => (
              <MenuItem
                key={key}
                p="0.4rem 1.6rem"
                height="4rem"
                fontSize="1.6rem"
                lineHeight="2.4rem"
                color="var(--primary-text-color)"
                _last={{ borderBottom: "none" }}
                _hover={{ bgColor: "#F0F9FF !important" }}
                {...rest}
              >
                {title}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default memo(CustomMenu);

import { Attribute, mergeAttributes, Node } from "@tiptap/core";
import { NodeType } from "../type";
import { onChangeCheckbox } from "../utils";

export interface TaskOptNodeAttrs {
  checked: boolean;
  isLastOpt: boolean;
}

export const TaskOptNode = Node.create({
  name: NodeType.TASK_OPT,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  content: "inline*",
  defining: true,

  addAttributes() {
    const attrs: {
      [key in keyof TaskOptNodeAttrs]: Attribute;
    } = {
      isLastOpt: { default: false },
      checked: {
        default: false,
        parseHTML: (element) => {
          const dataChecked = element.getAttribute("data-checked");

          return dataChecked === "" || dataChecked === "true";
        },
        renderHTML: (attributes) => ({
          "data-checked": attributes.checked,
        }),
      },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: `li[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "li",
      mergeAttributes(HTMLAttributes, {
        "data-type": this.name,
      }),
      ["div", { checked: node.attrs.checked ? "checked" : null }, 0],
    ];
  },

  addNodeView() {
    return ({ HTMLAttributes, getPos, editor }) => {
      const { isLastOpt } = HTMLAttributes as TaskOptNodeAttrs;
      const checked = HTMLAttributes["data-checked"];
      const listItem = document.createElement("li");
      const checkbox = document.createElement("div");
      checkbox.style.padding = "2px";
      checkbox.style.cursor = "pointer";
      checkbox.contentEditable = "false";
      checkbox.setAttribute("data-checked", `${checked}`);
      if (checked) {
        checkbox.classList.add("check-box-options-active");
      }

      checkbox.addEventListener("click", () => {
        // if the editor isn’t editable and we don't have a handler for
        // readonly checks we have to undo the latest change
        if (!editor.isEditable) {
          checkbox.setAttribute(
            "checked",
            `${!(checkbox.getAttribute("checked") === "true")}`
          );

          return;
        }

        if (editor.isEditable && typeof getPos === "function") {
          onChangeCheckbox({ editor, getPos });
        }
      });
      if (!isLastOpt) {
        const split = document.createElement("div");
        split.style.fontSize = "1rem";
        split.contentEditable = "false";
        split.textContent = "・";
        split.style.cursor = "default";

        listItem.append(checkbox, split);
      } else {
        listItem.append(checkbox);
      }

      return {
        dom: listItem,
        contentDOM: checkbox,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }

          listItem.dataset.checked = updatedNode.attrs.checked;
          if (updatedNode.attrs.checked) {
            checkbox.setAttribute("data-checked", "true");
            checkbox.classList.add("check-box-options-active");
          } else {
            checkbox.classList.remove("check-box-options-active");
            checkbox.removeAttribute("data-checked");
          }

          return true;
        },
      };
    };
  },
});

import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconCamera(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="none"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.714 7.513h.9a1.8 1.8 0 0 0 1.8-1.8.9.9 0 0 1 .9-.9h5.391a.9.9 0 0 1 .9.9 1.8 1.8 0 0 0 1.8 1.8h.9a1.8 1.8 0 0 1 1.8 1.8v8.086a1.8 1.8 0 0 1-1.8 1.8H5.714a1.8 1.8 0 0 1-1.8-1.8V9.305a1.8 1.8 0 0 1 1.8-1.792"
        data-name="Path 1181"
      ></path>
      <circle
        cx="2.695"
        cy="2.695"
        r="2.695"
        fill="none"
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        data-name="Ellipse 481"
        transform="translate(9.305 10.203)"
      ></circle>
    </Icon>
  );
}

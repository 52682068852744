import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconInspectionConfirm(props: IconProps) {
  return (
    <Icon width="2.4rem" height="2.4rem" viewBox="0 0 24 24" {...props}>
      <rect
        width="24"
        height="24"
        fill="#0284c7"
        data-name="Rectangle 8411"
        rx="2"
      />
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#fff"
        d="M12 20a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8m0-14.4a6.4 6.4 0 1 0 6.4 6.4A6.41 6.41 0 0 0 12 5.6"
        data-name="↳Color"
      />
      <path
        fill="#fff"
        d="m6.998 12.58 1.193-1.215 2.16 2.207 5.461-5.576 1.189 1.221-6.65 6.791Zm.469 0 2.885 2.947 6.182-6.311-6.182 6.311Z"
        data-name="Union 136"
      />
    </Icon>
  );
}

import { MessageType } from "constants/websocket";

export interface WSMessage {
  type: keyof typeof MessageType;
  data: any;
  channel?: string;
  level?: string;
  taskId?: string;
  docCategoryId?: string;
  docGroupId?: string;
  docItemId?: string;
  subItemId?: string;
  isUpdateLabel?: boolean;
}

export enum WSMessageAction {
  SEND_MESSAGE = "sendMessage",
  ADD_CHANNEL = "addChannel",
  DEFAULT = "default",
}

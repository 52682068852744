import { Flex, Input, InputProps } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { MessageType } from "constants/websocket";
import { DocumentItemKey, KeyBoardCode, SubItemKey } from "constants/enum";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { WSMessage } from "interfaces/models/websocket";
import { GetContentLog, transformMapTitleKey } from "models/dataLog";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { uuid } from "utils/common";
import { transformBodyForCombineData } from "utils/offline";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";

export enum ItemTitleType {
  ITEM,
  SUB_ITEM,
}

interface Props {
  type: ItemTitleType;
  subItem?: DocumentSubItemDTO;
  item?: DocumentItemDTO;
  inputProps?: InputProps;
  insertItemLog?: (params: GetContentLog) => Promise<void>;
}

const ItemTitle = (props: Props) => {
  const { type, subItem, item, inputProps, insertItemLog } = props;
  const { socket } = useForgeViewerContext();
  const [title, setTitle] = useState(
    (type === ItemTitleType.ITEM ? item?.title : subItem?.title) || ""
  );

  const dispatch = useDispatch();
  const titleInputRef = useRef<HTMLInputElement>(null);
  const selfRef = useRef<any>({});

  useEffect(() => {
    setTitle(
      (type === ItemTitleType.ITEM ? item?.title : subItem?.title) || ""
    );
  }, [type, item?.title, subItem?.title]);

  const onChange = (e: any) => {
    setTitle(e.target.value);
  };

  const handleBlurTitle = async () => {
    const currentTitle =
      type === ItemTitleType.ITEM ? item?.title : subItem?.title;

    if (
      !item?.id ||
      (type === ItemTitleType.SUB_ITEM && !subItem?.id) ||
      (title || "") === (currentTitle || "")
    ) {
      return;
    }
    const now = new Date();
    const requestId = uuid();
    const { mapTitleKey, mapLogs } = transformMapTitleKey([
      {
        field: DocumentItemKey.TITLE,
        value: title,
      },
    ]);

    if (type === ItemTitleType.SUB_ITEM) {
      const newItem = structuredClone(item);
      updateElementInArray({
        array: newItem?.subItems || [],
        keyIndex: SubItemKey.ID,
        element: {
          ...subItem,
          title: title,
          updatedAt: now,
        } as DocumentSubItemDTO,
      });

      dispatch(updateDocumentItem(newItem));
      if (subItem?.id) {
        await documentItemApi.updateSubItem(
          transformBodyForCombineData<DocumentSubItemDTO>({
            body: {
              id: subItem?.id,
              itemId: subItem.itemId,
              title: title || "",
              updatedAt: now,
              mapTitleKey: mapTitleKey as any,
              requestId,
            } as DocumentSubItemDTO,
            bodyBefore: subItem,
            typeInitData: TypeHandleInitData.SUB_ITEM,
          })
        );

        if (subItem) {
          socket.updateSubItem(item, subItem, {
            title: title || "",
            updatedAt: now,
          });
        }
      }
    } else if (type === ItemTitleType.ITEM) {
      const now = new Date();
      const newItem = {
        ...(item as DocumentItemDTO),
        title: title,
        updatedAt: now,
      } as DocumentItemDTO;
      dispatch(updateDocumentItem(newItem));
      await documentItemApi.updateItem(
        transformBodyForCombineData<DocumentItemDTO>({
          body: {
            id: item.id,
            title: title,
            mapTitleKey,
            requestId,
            updatedAt: now,
          } as DocumentItemDTO,
          bodyBefore: item,
          typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
        })
      );

      socket.updateDocItem(newItem, {
        title: title,
        updatedAt: now,
      });
    }

    if (insertItemLog) {
      await Promise.all(
        Object.values(mapLogs).map((params) =>
          insertItemLog({ ...params, requestId })
        )
      );
    }
  };

  selfRef.current.handleChangeTitle = handleBlurTitle;
  useEffect(() => {
    const _self = selfRef.current;

    return () => {
      _self?.handleChangeTitle();
    };
  }, []);

  return (
    <Flex width="100%" flexDirection="column">
      <Input
        ref={titleInputRef}
        height="4.4rem"
        fontSize="1.4rem"
        fontWeight="400"
        w="100%"
        borderColor="border.default"
        color="font.default"
        value={title || ""}
        onBlur={handleBlurTitle}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === KeyBoardCode.Enter) {
            titleInputRef.current?.blur();
          }
        }}
        {...inputProps}
      />
    </Flex>
  );
};

export default memo(ItemTitle);

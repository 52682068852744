import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconThreeDot(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#171717"
        d="M13.253 18.49c0-.86-.674-1.557-1.627-1.557-.975 0-1.626.697-1.626 1.557 0 .836.65 1.51 1.626 1.51.953 0 1.627-.674 1.627-1.51m0-6.467c0-.86-.674-1.556-1.627-1.556-.975 0-1.626.697-1.626 1.556 0 .837.65 1.51 1.626 1.51.953 0 1.627-.673 1.627-1.51m0-6.466c0-.86-.674-1.557-1.627-1.557C10.651 4 10 4.697 10 5.557c0 .836.65 1.51 1.626 1.51.953 0 1.627-.674 1.627-1.51"
      />
    </Icon>
  );
}

import { MessageType } from "constants/websocket";
import { useAppWebSocket } from "hooks/useAppWebSocket";
import { useRoles } from "hooks/usePermission";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { fetchTaskSheetTemplates } from "redux/taskSlice";

const useTaskSheetTemplateList = () => {
  const { taskSheetTemplateList, isFetchingTaskSheetTemplates } = useSelector(
    (state: RootState) => state.task
  );
  const { isTakasagoGroupAndPartnerLeader } = useRoles();
  const { webSocketMessages } = useAppWebSocket();

  const dispatch = useDispatch();

  const getTaskSheetTemplateList = useCallback(async () => {
    if (!isTakasagoGroupAndPartnerLeader) {
      return;
    }
    dispatch(fetchTaskSheetTemplates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getTaskSheetTemplateListRef = useRef(getTaskSheetTemplateList);
  getTaskSheetTemplateListRef.current = getTaskSheetTemplateList;

  useEffect(() => {
    if (!webSocketMessages.length) {
      return;
    }
    webSocketMessages.forEach((e) => {
      const { type } = e;
      if (type === MessageType.RELOAD_TASK) {
        getTaskSheetTemplateListRef.current();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  return {
    isLoading: isFetchingTaskSheetTemplates,
    taskSheetTemplateList,
  };
};

export default useTaskSheetTemplateList;

import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { memo } from "react";

import { useDeviceSelectors } from "react-device-detect";
import IconRevert from "./icon/IconRevert";

interface Props extends ButtonProps {
  customToolTip?: {
    ipad: string;
    pc: string;
  };
}

const RevertButton = ({ onClick, customToolTip, ...props }: Props) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  if (isMobile) {
    return (
      <Menu offset={[0, 3]}>
        <MenuButton
          p="0"
          variant="text"
          color="black"
          ml="auto"
          minHeight="2.4rem"
          as={Button}
          _expanded={{ opacity: 1 }}
          _focus={{ boxShadow: "none" }}
          boxShadow="unset"
          _active={{ boxShadow: "none" }}
          sx={{
            "& > span": {
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            },
          }}
          {...props}
        >
          <IconRevert width="2rem" height="2rem" flexShrink={0} />
        </MenuButton>
        <Portal>
          <MenuList p={0}>
            <MenuItem
              display="flex"
              alignItems="center"
              gap="0.5rem"
              backgroundColor="white"
              _hover={{ backgroundColor: "white" }}
              _active={{ backgroundColor: "#EDF2F7" }}
              onClick={onClick}
            >
              <IconRevert width="2rem" height="2rem" flexShrink={0} />
              <Text fontSize="1rem">
                {customToolTip?.ipad ?? (
                  <>
                    この項目のみ、
                    <br />
                    この時点の状態に戻す
                  </>
                )}
              </Text>
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    );
  }

  return (
    <Tooltip label={customToolTip?.pc ?? "この項目のみ、この時点の状態に戻す"}>
      <Button
        p="0"
        variant="text"
        color="black"
        ml="auto"
        minHeight="2.4rem"
        {...props}
        onClick={onClick}
      >
        <IconRevert
          style={{
            width: "2rem",
            height: "2rem",
            flexShrink: 0,
          }}
        ></IconRevert>
      </Button>
    </Tooltip>
  );
};

export default memo(RevertButton);

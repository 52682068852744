export enum REDUCER_KEY {
  APP = "app",
  PROJECT = "project",
  FORGE_VIEWER = "forgeViewer",
  BIM = "bim",
  DOCUMENT = "document",
  USER = "user",
  EDITOR = "editor",
  TASK = "task",
  TASK_TYPE = "taskType",
}

import { taskSheetTemplateApi } from "apiClient/v2";
import { Operation, StoreName } from "constants/serviceWorker";
import { saveIndexDB } from "pages/forge-viewer/hooks/useSyncData";
import { Dispatch } from "react";
import store from "redux/store";
import { setTaskSheetTemplateList } from "redux/taskSlice";

type Props = {
  dispatch: Dispatch<any>;
};

export default class ReceiveDataCommonSocketHelper {
  dispatch: Dispatch<any>;
  constructor(params: Props) {
    this.dispatch = params.dispatch;
  }

  getDocumentState() {
    return store.getState().document;
  }

  getTaskState() {
    return store.getState().task;
  }

  getProjectState() {
    return store.getState().project;
  }

  async fetchTaskSheetTemplate(id: string) {
    const { data: template } = await taskSheetTemplateApi.getTemplate(id);
    if (!template?.id) return;
    const { taskSheetTemplateList } = this.getTaskState();
    const index = taskSheetTemplateList.findIndex((item) => item.id === id);
    const newTaskSheetTemplates = [...taskSheetTemplateList];
    if (index === -1) {
      newTaskSheetTemplates.push(template);
      saveIndexDB(StoreName.TASK_SHEET_TEMPLATE, Operation.Post, template);
    } else {
      newTaskSheetTemplates[index] = template;
      saveIndexDB(StoreName.TASK_SHEET_TEMPLATE, Operation.Patch, template);
    }
    this.dispatch(setTaskSheetTemplateList(newTaskSheetTemplates));
  }
}

import { CellProperty, LinkedDataField } from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { ImageWithBlackboardNodeAttrs } from "../extendsions";
import { CustomNode, GetContentTypeDocumentType, NodeType } from "../type";
import { getContentNodeTypeNormalText } from "../utils";
import BaseStructureTable from "./BaseStructureTable";

class ContentTdTypeLinkedImage extends BaseStructureTable {
  increaseDocumentDataIndex() {
    this.rowIndexForLinkedImage.documentDataIndex++;
  }
  increaseImageIndex() {
    this.rowIndexForLinkedImage.imageIndex++;
  }

  getStructure(params: GetContentTypeDocumentType) {
    const { cell, width, height } = params;

    if (!this.page || !this.component) {
      return null;
    }

    const { offsetItemLinked, limitItemTableLinked } = this.page;
    const documentItems = structuredClone(this.documentItems || []).splice(
      offsetItemLinked,
      limitItemTableLinked
    );
    const documentData = documentItems.at(
      this.rowIndexForLinkedImage.documentDataIndex
    ) as DocumentItemDTO;
    const imageData = documentItems.at(
      this.rowIndexForLinkedImage.imageIndex
    ) as DocumentItemDTO;
    const cellLinkedField = cell.cellLinkedData?.field;
    const imageSrc = imageData?.images?.src;

    switch (cell.cellProperty) {
      case CellProperty.LINKED_IMAGE_LINE:
        return {
          type: NodeType.LINKED_IMAGE_LINE,
        } as CustomNode<any>;

      case CellProperty.LINKED_IMAGE_DEFAULT_TEXT: {
        return getContentNodeTypeNormalText({
          text: cell.value,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }

      case CellProperty.DOCUMENT_DATA: {
        switch (cellLinkedField) {
          case LinkedDataField.DOCUMENT_ITEM.TITLE: {
            this.increaseDocumentDataIndex();

            return getContentNodeTypeNormalText({
              text: documentData
                ? `No. ${documentData.keyNoteIndex} : ${
                    documentData.title || "-"
                  }`
                : undefined,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          default:
            return null;
        }
      }

      case CellProperty.IMAGE: {
        this.increaseImageIndex();
        if (!imageData || !imageSrc) {
          return {
            type: NodeType.CROSS_LINE,
          } as CustomNode<any>;
        }

        return {
          type: NodeType.IMAGE_WITH_BLACKBOARD,
          attrs: {
            imageSrc,
            width,
            height,
            isShowBlackboard: imageData?.isShowBlackboard,
            blackboardImagePosition: imageData?.blackboardImagePosition,
            blackboard: (imageData as any)?.blackBoard,
            blackboardTemplateProps: {
              ...(this.blackboardTemplateProps || ({} as any)),
              documentSubItem: imageData as any,
              familyInstance:
                this.familyInstances?.[imageData.externalId || ""],
            },
          },
        } satisfies CustomNode<ImageWithBlackboardNodeAttrs>;
      }

      default:
        return null;
    }
  }
}

export default ContentTdTypeLinkedImage;

import { Box, Flex, Text } from "@chakra-ui/react";
import { OPERATION } from "constants/task";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { Fragment, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "redux/store";
import { generatePath, useNavigate } from "react-router-dom";
import { routePath } from "routes/path";

interface Props {
  taskModalInfo: TaskDTO | undefined;
}

export default function DocumentRelated(props: Props) {
  const { documentCategories } = useSelector(
    (state: RootState) => state.document
  );
  const { projectId, bimFileId, version } = useParams();
  const navigate = useNavigate();

  const { taskModalInfo } = props;
  const documentCategoryNameOpts = useMemo(() => {
    const opts: { name: string; value: string }[] = [];
    if (taskModalInfo?.isDeleteDocItem || !taskModalInfo?.id) {
      return opts;
    }
    documentCategories.forEach((category) => {
      const validCategory =
        category.level === taskModalInfo.level &&
        category.templateId === taskModalInfo.templateId &&
        category.itemIds?.includes(taskModalInfo.documentItemId || "") &&
        (taskModalInfo?.documentCategoryId
          ? taskModalInfo?.documentCategoryId === category.id
          : true);

      if (validCategory) {
        opts.push({ name: category.title || "", value: category.id });
      }
    });

    return opts;
  }, [documentCategories, taskModalInfo]);

  const navigateDocumentItemModal = useCallback(
    (documentCategoryId: string) => {
      if (
        !bimFileId ||
        !version ||
        !taskModalInfo?.id ||
        !taskModalInfo.documentItemId
      ) {
        return;
      }

      let url = generatePath(routePath.ForgeViewer, {
        projectId,
        bimFileId: encodeURIComponent(bimFileId),
        version,
      });
      const data = {
        documentItemId: taskModalInfo.documentItemId,
        documentCategoryId,
      };
      const query = [
        `operation=${OPERATION.navigateDocumentItemModal}`,
        `data=${JSON.stringify(data)}`,
      ].join("&");
      url = `${url}?${query}`;

      navigate(url);
    },
    [
      projectId,
      bimFileId,
      version,
      taskModalInfo?.id,
      taskModalInfo?.documentItemId,
      navigate,
    ]
  );

  return (
    <>
      {!!documentCategoryNameOpts?.length && (
        <Flex
          w="100%"
          fontSize="1.4rem"
          alignItems="center"
          mb="1rem"
          my="16px"
          px="2px"
          gap="1rem"
        >
          <Text
            color="#171717"
            mr="1rem"
            mb="0.5rem"
            whiteSpace="nowrap"
            flexShrink={0}
          >
            関連書類
          </Text>
          <Box gap="0.5rem" flex={1}>
            {documentCategoryNameOpts?.map((opt, index) => (
              <Fragment key={opt.value}>
                <Text
                  display="inline"
                  width="fit-content"
                  fontWeight="bold"
                  flexShrink={0}
                  cursor="pointer"
                  color="#009BE0"
                  textDecoration="underline"
                  onClick={() => {
                    navigateDocumentItemModal(opt.value);
                  }}
                >
                  {opt.name}
                </Text>

                {documentCategoryNameOpts?.length !== index + 1 && (
                  <Text display="inline" width="fit-content">
                    ,&nbsp;&nbsp;
                  </Text>
                )}
              </Fragment>
            ))}
          </Box>
        </Flex>
      )}
    </>
  );
}

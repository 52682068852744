import { Icon, IconProps } from "@chakra-ui/icons";

interface Props extends IconProps {
  style?: React.CSSProperties;
}

export default function IconHomeSetting(props: Props) {
  return (
    <Icon width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 12.7a2.7 2.7 0 1 0 0-5.4 2.7 2.7 0 0 0 0 5.4"
      />
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.055 12.455a1.35 1.35 0 0 0 .27 1.489l.049.049a1.636 1.636 0 0 1-.531 2.67 1.64 1.64 0 0 1-1.785-.355l-.049-.049a1.35 1.35 0 0 0-1.489-.27 1.35 1.35 0 0 0-.818 1.235v.14a1.636 1.636 0 1 1-3.273 0v-.074a1.35 1.35 0 0 0-.884-1.235 1.35 1.35 0 0 0-1.489.27l-.049.049a1.635 1.635 0 0 1-2.315 0 1.635 1.635 0 0 1 0-2.316l.049-.049a1.35 1.35 0 0 0 .27-1.489 1.35 1.35 0 0 0-1.236-.818h-.139a1.636 1.636 0 1 1 0-3.273h.074a1.35 1.35 0 0 0 1.235-.884 1.35 1.35 0 0 0-.27-1.489l-.049-.049a1.636 1.636 0 1 1 2.316-2.315l.049.049a1.35 1.35 0 0 0 1.489.27h.065a1.35 1.35 0 0 0 .819-1.236v-.139a1.636 1.636 0 1 1 3.272 0v.074a1.35 1.35 0 0 0 .819 1.235 1.35 1.35 0 0 0 1.489-.27l.049-.049a1.637 1.637 0 1 1 2.315 2.316l-.049.049a1.35 1.35 0 0 0-.27 1.489v.065a1.35 1.35 0 0 0 1.235.819h.14a1.636 1.636 0 0 1 0 3.272h-.074a1.35 1.35 0 0 0-1.235.819"
      />
    </Icon>
  );
}

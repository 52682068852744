import { Box } from "@chakra-ui/react";
import ImageComponentPreview from "components/editor-builder/component-preview/ImageComponentPreview";
import TableComponentPreview, {
  iTableComponentPreview,
} from "components/editor-builder/component-preview/TableComponentPreview";
import { getDisplaySizeScale } from "components/editor-builder/component-preview/TableComponentPreview/utils/tableStyle";
import TextboxComponentPreview, {
  iTextboxComponentPreview,
} from "components/editor-builder/component-preview/TextboxComponentPreview";
import { PageInfo } from "components/TipTapEditorDoc/type";
import { DEFAULT_PAGE_RATIO, GRID_TEMPLATE_SIZE } from "constants/document";
import {
  ContentType,
  DocumentTemplateType,
  SystemMode,
  SystemModeType,
  TemplateComponentType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { NeptuneArea } from "interfaces/models/area";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import { KeynoteImageData } from "interfaces/models/documentKeynote";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import { getSizeOfComponent } from "pages/document/template-page/utils";
import { memo } from "react";
import "react-virtualized/styles.css";
import Image from "./PreviewComponent/Image";
import useRender from "hooks/useWatchingRender";
import { PREVIEW_DOCUMENT_CONTAINER_CLASS } from "constants/styleProps";

interface Props {
  pageIndex: number;
  width: number;
  height: number;
  isReverseAxis: boolean;
  stylePageKeynote: any;
  paddingX: number;
  paddingY: number;
  pageSize: string;
  pageDirection: string;
  page: any;
  hasKeyNote?: boolean;
  template?: DocumentTemplate;
  documentItems: DocumentItem[] | BlackBoardInfo[];
  documentCategorySelected?: DocumentCategoryDTO;
  blackboardTemplateProps?: iBlackboardTemplateProps;
  familyInstances: { [key: string]: FamilyInstanceDTO };
  headerComponent: CellType[];
  listUserById?: {
    [key: string]: User | null;
  };
  mapKeyNoteIndex: {
    [key: string]: number;
  };

  keynoteImageDataProp?: KeynoteImageData;
  neptuneAreas?: NeptuneArea[];
  projectName: string;
  documentItem?: DocumentItem;
  companiesById: Record<string, PartnerCompany>;
  isCreatingKeynote?: boolean;
  scale: number;
  mapLinkedTableIndex: { [key: string]: number };
  systemMode: string;
  pageId: number;
}

const PreviewPage = (props: Props) => {
  const {
    width,
    height,
    isReverseAxis,
    stylePageKeynote,
    paddingX,
    paddingY,
    pageSize,
    pageDirection,
    page,
    hasKeyNote,
    template,
    documentItems,
    documentCategorySelected,
    familyInstances,
    blackboardTemplateProps,
    headerComponent,
    listUserById,
    mapKeyNoteIndex,
    projectName,
    keynoteImageDataProp,
    neptuneAreas,
    companiesById,
    isCreatingKeynote,
    scale,
    mapLinkedTableIndex,
    systemMode,
    pageIndex,
    pageId,
  } = props;

  const setRef = useRender(`page-${pageId}`);

  const renderPreviewComponent = ({
    type,
    component,
    height,
    linkedTableIndex,
    page,
    pageIndex,
  }: {
    type: string;
    component: TemplateComponent;
    linkedTableIndex?: number;
    pageIndex?: number;
    page: PageInfo;
    height?: number;
  }) => {
    const scale = getDisplaySizeScale(page);
    const components = page!.components || [];
    const offsetItemLinked = page!.offsetItemLinked;
    const limitItemTableLinked = page!.limitItemTableLinked;

    let tableComponentProps = {
      component,
      cellSize: {
        width: GRID_TEMPLATE_SIZE,
        height: GRID_TEMPLATE_SIZE,
      },
      zoomRatio: 1,
      isOnlyView: true,
      displaySize: {
        width: component?.size.width * scale.displayX,
        height: component?.size.height * scale.displayY,
      },
      sizePageRatio: page?.sizePageRatio || DEFAULT_PAGE_RATIO,
      pageDirectionRatio: page?.pageDirectionRatio || DEFAULT_PAGE_RATIO,
      isResizable: false,
      isResizing: false,
      isBlackboardTemplateImage: false,
      hasKeyNote,
      template,
    } as iTableComponentPreview;

    const textBoxComponentProps: iTextboxComponentPreview = {
      component,
      template: template!,
      zoomRatio: 1,
      isOnlyView: true,
      isResizable: false,
    };

    switch (type) {
      case TemplateComponentType.LinkedImage: {
        const documentItemsForLinkedImage = structuredClone(
          documentItems
        ).splice(offsetItemLinked, limitItemTableLinked);

        tableComponentProps = {
          ...tableComponentProps,
          contentType: ContentType.LINKED_IMAGE,
          linkedImageProps: {
            component,
            documentCategorySelected: documentCategorySelected!,
            displayItems: documentItemsForLinkedImage,
            offsetItemLinked,
            limitItems: limitItemTableLinked,
            familyInstances,
            blackboardTemplateProps: blackboardTemplateProps || ({} as any),
          },
        };

        return <TableComponentPreview {...tableComponentProps} />;
      }

      case TemplateComponentType.FilterPhoto: {
        tableComponentProps = {
          ...tableComponentProps,
          contentType: ContentType.PHOTO_LEDGER,
          sleevePipeProps: {
            headerComponent,
            listUserById,
            component,
            offsetItemLinked,
            limitItemTableLinked,
            documentItems: (documentItems || []) as DocumentItem[],
            mapKeyNoteIndex,
            hasKeyNote,
            pageIndex,
            blackboardTemplateProps: blackboardTemplateProps || ({} as any),
            familyInstances,
          },
        };

        return <TableComponentPreview {...tableComponentProps} />;
      }

      case TemplateComponentType.Table:
      case TemplateComponentType.TableHeader:
        if (!!component.linkedHeaderId) {
          switch (template?.documentType) {
            case DocumentTemplateType.COMMISSIONING_TABLE:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.PAC,
                pacProps: {
                  headerComponent,
                  listUserById,
                  component,
                  documentCategorySelected: documentCategorySelected!,
                  displayItems: documentItems || [],
                  offsetItemLinked,
                  limitItemTableLinked,
                },
              };
              break;

            case DocumentTemplateType.PHOTO_LEDGER:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.PHOTO_LEDGER,
                sleevePipeProps: {
                  headerComponent,
                  listUserById,
                  component,
                  offsetItemLinked,
                  limitItemTableLinked,
                  documentItems: (documentItems || []) as DocumentItem[],
                  mapKeyNoteIndex,
                  hasKeyNote,
                  pageIndex,
                  blackboardTemplateProps:
                    blackboardTemplateProps || ({} as any),
                  familyInstances,
                },
              };

              break;

            case DocumentTemplateType.SELF_INSPECTION:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.SELF_INSPECTION,
                flexibleDuctProps: {
                  listUserById,
                  components: components || [],
                  component,
                  headerComponent,
                  documentItems: (documentItems || []) as DocumentItem[],
                  linkedTableIndex,
                  offsetItemLinked,
                },
              };
              break;

            case DocumentTemplateType.EQUIPMENT_DATA_SHEET:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.EQUIPMENT_DATA_SHEET,
                moduleChillerProps: {
                  listUserById,
                  component,
                  headerComponent,
                  documentItem: page.documentItem!,
                  documentCategorySelected: documentCategorySelected!,
                  offsetItemLinked,
                },
              };

              break;

            default:
              break;
          }

          return <TableComponentPreview {...tableComponentProps} />;
        }

        return (
          <TableComponentPreview
            {...tableComponentProps}
            contentType={ContentType.PREVIEW}
            previewProps={{
              documentItem: page.documentItem!,
              projectName,
              familyInstances,
              documentTemplateName: template?.templateName || "",
              neptuneAreas: neptuneAreas || [],
              documentCategorySelected:
                documentCategorySelected || ({} as DocumentCategoryDTO),
              documentItemSelected:
                page.documentItem! || ({} as DocumentItemDTO),
              listUserById,
              component,
              companiesById,
            }}
          />
        );

      case TemplateComponentType.Image: {
        return (
          <Image
            component={component}
            imageUrl={undefined}
            keynoteImageDataProp={keynoteImageDataProp}
            isLoadingKeyNote={!!isCreatingKeynote}
            maxHeight={height}
          />
        );
      }

      case TemplateComponentType.ImageUploaded: {
        return (
          <ImageComponentPreview
            component={component}
            zoomRatio={1}
            isOnlyView={true}
            contentType={ContentType.PREVIEW}
            isResizable={false}
          />
        );
      }

      case TemplateComponentType.Text: {
        return <TextboxComponentPreview {...textBoxComponentProps} />;
      }

      default: {
        return null;
      }
    }
  };

  return (
    <Box
      id={`wrapper-preview-page-${pageIndex}`}
      className="disalbe-br mceNonEditable"
      key={pageIndex}
      ref={(ref) => {
        ref && setRef(ref);
      }}
      style={{
        background: "black",
        padding: `${scale * 7}px ${scale * 3}rem`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        id={`page-${pageId}`}
        className={`${PREVIEW_DOCUMENT_CONTAINER_CLASS} page-index-${pageIndex}`}
        data-page-size={pageSize}
        data-page-direction={pageDirection}
        style={{
          background: "white",
          padding: page.isVertical
            ? `${scale * paddingY}px ${paddingX * scale}px`
            : `${paddingX * scale}px ${paddingY * scale}px`,
          width,
          height,
          margin: "0 auto",
          ...stylePageKeynote,
        }}
      >
        <Box
          style={{
            position: "relative",
            transform: `scale(${scale})`,
            transformOrigin: "0 0",
          }}
        >
          {page?.components?.map((component: any) => {
            let { width, height } = getSizeOfComponent({
              documentType: template?.documentType,
              component,
              numberItemPerPage: page.numberPerPage,
              limit: page.limitItemTableLinked,
            });

            if (height <= 1) {
              return undefined;
            }

            let top = component.detail.isFullsize
              ? component?.realPosition?.y + 1
              : component?.realPosition?.y;
            let left = component?.realPosition?.x;

            if (
              isReverseAxis &&
              systemMode === SystemMode[SystemModeType.Task]
            ) {
              [height, width] = [width, height];
              [top, left] = [left, top];
            }

            return (
              <Box
                className="disalbe-br mceNonEditable"
                key={component?.componentId}
                style={{
                  height: `${height}px`,
                  width: `${width}px`,
                  top: `${top}px`,
                  left: `${left}px`,
                  position: "absolute",
                }}
              >
                {renderPreviewComponent({
                  type: component.type,
                  component,
                  linkedTableIndex:
                    mapLinkedTableIndex[String(component?.componentId)],
                  pageIndex,
                  page,
                  height,
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PreviewPage);

import { DocumentItemKey } from "constants/enum";
import { ApiResponse } from "interfaces/models/api";
import { DataLog } from "interfaces/models/dataLog";
import { axiosECS } from "services/baseAxios";
import { sleep } from "utils/common";
import { DocumentLogTypeKey } from "utils/data-logs";
import { presignedAndDownloadFileS3 } from "utils/file";

const path = "/v2/data-logs";

const FIELD_LOGS_NEED_CACHE: string[] = [
  DocumentItemKey.IMAGES,
  DocumentItemKey.ATTACHES,
  DocumentLogTypeKey.BLACKBOARD,
];

export interface GetDataLogReq {
  subItemId?: string;
  itemId?: string;
  categoryId?: string;
  groupId?: string;
  cursor?: string;
  limit?: number;
  documentType?: string;
  shouldCache?: boolean;
}

const getDataLogList = async (
  params: GetDataLogReq
): Promise<ApiResponse<DataLog[]>> => {
  params.limit = params.limit || 20;

  return axiosECS.get(path, { params });
};

const handleCacheDataLog = async (dataLogs: DataLog[]) => {
  let requests: Promise<any>[] = [];
  dataLogs.forEach((dataLog) => {
    const field = dataLog.field;
    const value = dataLog?.value;
    if (!FIELD_LOGS_NEED_CACHE.includes(field)) {
      return;
    }
    const urls = [];
    switch (field) {
      case DocumentItemKey.IMAGES:
        value?.data?.src && urls.push(value?.data?.src);
        break;
      case DocumentLogTypeKey.BLACKBOARD:
        value?.thumbnail && urls.push(value?.thumbnail);
        break;
      case DocumentItemKey.ATTACHES:
        urls.push(...(value?.data || []));
        break;
      default:
        break;
    }

    requests.push(...urls.map((url) => presignedAndDownloadFileS3(url, true)));
  });

  if (requests.length >= 20) {
    await Promise.all(requests);
    await sleep(50);
    requests = [];
  }
};

export const handleGetAllDataLogList = async ({
  itemId,
  subItemId,
  categoryId,
  documentType,
  shouldCache,
}: GetDataLogReq) => {
  const dataLogs: DataLog[] = [];
  let cursor: string | undefined;
  do {
    const { data, pagination } = await getDataLogList({
      itemId,
      subItemId,
      categoryId,
      cursor,
      shouldCache,
      documentType,
    });
    cursor = pagination?.cursor;
    if (data?.length) {
      dataLogs.push.apply(dataLogs, data);
    }
  } while (cursor);

  if (shouldCache) {
    await handleCacheDataLog(dataLogs);
  }

  return dataLogs;
};

export const handleGetDataLogList = async ({
  itemId,
  subItemId,
  categoryId,
  groupId,
  cursor,
  shouldCache,
  documentType,
}: GetDataLogReq) => {
  const dataLogs: DataLog[] = [];
  const { data, pagination } = await getDataLogList({
    itemId,
    subItemId,
    categoryId,
    groupId,
    cursor,
    shouldCache,
    documentType,
  });
  cursor = pagination?.cursor;
  if (data?.length) {
    dataLogs.push.apply(dataLogs, data);
  }

  return { items: dataLogs, pagination };
};

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { taskApi } from "apiClient/v2";
import DropdownHover from "components/DropdownHover";
import {
  InspectionItemType,
  MapInspectionItemColor,
  SystemModeType,
  TASK_PRINT_MODE,
} from "constants/enum";
import useFamilyInstance from "hooks/useFamilyInstance";

import { RIGHT_SIDEBAR_MODAL_CONTAINER_ID } from "constants/styleProps";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { addLogCreateTaskForDocItem, GetContentLog } from "models/dataLog";
import { handleSelectTask } from "models/task";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSystemMode } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import {
  insertTaskIdCreated,
  setTasks,
  setTaskSelected,
} from "redux/taskSlice";
import { sortArrayByField } from "utils/array";
import { sleep, uuid } from "utils/common";
import { getNameByContentType } from "utils/document";
import { hightLightDocumentItem } from "utils/documentItem";
import { selectDbIds } from "utils/forge";
import { getDbIdByExternalId } from "utils/forge/data";
import Tasks from "./Tasks";
import { getLocalStorageUser } from "utils/user";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { convertToUtcByOffsetLocal } from "utils/date";
import { updateClickingTaskId } from "utils/task";
import IconPlus from "components/icon/IconPlus";
import IconPin from "components/task/IconPin";

interface Props {
  documentItem: DocumentItemDTO;
  isDisabled?: boolean;
  isOnline?: boolean;

  insertDocumentItemLog?: (params: GetContentLog) => Promise<void>;
}

const ItemTasks = ({
  documentItem,
  isDisabled,
  isOnline = true,
  insertDocumentItemLog,
}: Props) => {
  const { bimFileId } = useParams();
  const dropdownRef = useRef<any>();

  const { socket, sendMessageToCommonChannel } = useForgeViewerContext();

  const dispatch = useDispatch();
  const rightSidebarContainerRef = useRef<any>(
    document.getElementById(RIGHT_SIDEBAR_MODAL_CONTAINER_ID)
  );
  const { familyInstances } = useFamilyInstance();

  const { levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { tasks, taskTypes, isLoadingTaskType } = useSelector(
    (state: RootState) => state.task
  );
  const [isOpenCreateTask, setIsOpenCreateTask] = useBoolean();
  const [taskTypeId, setTaskTypeId] = useState<string | undefined>("");
  const canCreateTaskRef = useRef<boolean>(false);
  const [isSaving, setSaving] = useState(false);

  const taskList = useMemo(() => {
    return sortArrayByField(
      tasks?.filter((c) => c.documentItemId === documentItem.id) || [],
      "createdAt",
      true
    );
  }, [tasks, documentItem]);

  const listTaskTypeSorted = useMemo(() => {
    return sortArrayByField(taskTypes || [], "createdAt", false);
  }, [taskTypes]);

  useEffect(() => {
    const element = document.getElementById(RIGHT_SIDEBAR_MODAL_CONTAINER_ID);
    if (element) {
      rightSidebarContainerRef.current = element;
    }
  }, []);

  useEffect(() => {
    isOpenCreateTask && setIsOpenCreateTask.off();
    taskTypeId && setTaskTypeId("");
    canCreateTaskRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentItem?.id]);

  const handleClickTask = async (task: TaskDTO) => {
    dispatch(setTaskSelected());
    dispatch(setSystemMode(SystemModeType.Task));
    updateClickingTaskId(task.id);
    await sleep(100);
    handleSelectTask(task, dispatch);
    // waiting for modal open for prevent double click
    await sleep(200);
    updateClickingTaskId("");
  };

  const handleOpenCreateTask = () => {
    if (isDisabled) return;
    setIsOpenCreateTask.on();
  };

  const handleCloseTask = useCallback(() => {
    if (isDisabled) return;

    setIsOpenCreateTask.off();
    taskTypeId && setTaskTypeId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTypeId, isDisabled]);

  const handleCreateTask = useCallback(
    async (_taskTypeId = taskTypeId, title?: string) => {
      if (isDisabled) return;
      if (bimFileId && documentItem.position) {
        const taskType = taskTypes.find((i) => i.id === _taskTypeId);
        let taskTypeTitle = taskType?.title || "";
        if (!taskTypeTitle && title) {
          taskTypeTitle = title;
        }

        const requestId = uuid();
        const now = new Date();
        const currentDateUtc = new Date();
        convertToUtcByOffsetLocal(currentDateUtc);
        const newTask = {
          taskTypeId: _taskTypeId,
          position: documentItem.position,
          externalId: documentItem.externalId,
          bimFileId,
          level: levelSelected?.guid ? levelSelected.label || "" : "",
          objectTypes: (
            familyInstances[documentItem.externalId || ""]?.objectTypes ?? []
          ).map((family) => family.id),
          indexId: 0,
          status: InspectionItemType.Defect,
          printMode: TASK_PRINT_MODE.PRINTABLE,
          dbId: getDbIdByExternalId(documentItem.externalId || ""),
          documentItemId: documentItem.id,
          templateId: documentItem.templateId,
          createdAt: now,
          updatedAt: now,
          taskDate: currentDateUtc,
          mapDisplayValueKey: { createTask: taskTypeTitle } as any,
          requestId,
          createdBy: getLocalStorageUser()?.id!,
        } as TaskDTO;

        if (documentItem.externalId) {
          selectDbIds([String(documentItem.externalId)], {
            color: MapInspectionItemColor[InspectionItemType.Defect],
          });
        }

        const { data: res } = await taskApi.createTask(newTask);
        dispatch(insertTaskIdCreated(res.id));

        const newTasks = [
          ...tasks,
          { ...res, templateId: documentItem.templateId },
        ] as TaskDTO[];
        dispatch(setTasks(newTasks));
        socket.addTask({ ...res, templateId: documentItem.templateId });
        await insertDocumentItemLog?.({ field: "createTask" as any });
        await addLogCreateTaskForDocItem({
          taskTypeTitle,
          requestId,
          task: res,
          insertDocumentItemLog,
        });

        hightLightDocumentItem(documentItem);
        handleCloseTask();
        canCreateTaskRef.current = false;
        setTaskTypeId(undefined);
      }
    },
    [
      taskTypes,
      bimFileId,
      familyInstances,
      levelSelected?.guid,
      levelSelected.label,
      taskTypeId,
      tasks,
      isDisabled,
      documentItem,
      dispatch,
      handleCloseTask,
      insertDocumentItemLog,
    ]
  );

  useEffect(() => {
    if (!canCreateTaskRef.current) {
      return;
    }

    handleCreateTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTypeId]);

  const onSave = useCallback(async () => {
    if (taskTypeId) {
      return handleCreateTask(taskTypeId);
    }

    const response = await dropdownRef.current?.onAddTaskType();
    handleCreateTask(response?.id, response?.title);
  }, [taskTypeId, handleCreateTask]);

  return (
    <Box w="100%" mb="2rem">
      <Text
        marginBottom="2rem"
        textAlign="left"
        fontWeight={"bold"}
        fontSize="1.6rem"
      >
        指摘
      </Text>
      {!!taskList?.length && (
        <>
          {taskList.map((task) => {
            const taskName = getNameByContentType(task.taskTypeId || "") || "-";

            return (
              <Tasks
                marginLeft={"5rem"}
                width="calc(100% - 5rem)"
                padding="0.5rem"
                marginBottom="0"
                marginTop="0"
                borderRadius={0}
                key={`${task.indexId}`}
                task={task}
                taskName={taskName}
                onClick={() => {
                  handleClickTask(task);
                }}
              />
            );
          })}
        </>
      )}
      {isOpenCreateTask ? (
        <Box my="1rem" marginLeft={"5rem"} width="calc(100% - 5rem)">
          <Flex flexDirection="row" alignItems="center">
            <IconPin
              ml="0.5rem"
              mr="0.8rem"
              status={InspectionItemType.Defect}
            />
            <DropdownHover
              isOnline={isOnline}
              options={listTaskTypeSorted}
              value={taskTypeId}
              parentRef={rightSidebarContainerRef}
              inputProps={{
                placeholder: "指摘を入力してください",
                backgroundColor: "white",
                isDisabled,
              }}
              isLoading={isLoadingTaskType}
              ref={dropdownRef}
              isAutoSave={false}
              sendMessageToCommonChannel={sendMessageToCommonChannel}
              isSelectToSave={false}
              onChange={(value) => {
                setTaskTypeId(value as string);
              }}
              onLoading={setSaving}
            />
          </Flex>
          {!isDisabled && (
            <ButtonGroup
              display="flex"
              justifyContent="flex-end"
              mt="1rem"
              variant="outline"
              spacing="6"
            >
              <Button onClick={handleCloseTask}>キャンセル</Button>
              <Button onClick={onSave} variant="filled" isLoading={isSaving}>
                作成
              </Button>
            </ButtonGroup>
          )}
        </Box>
      ) : (
        <Box paddingLeft="calc(5.2rem + 4rem)">
          <Button
            mt="1rem"
            color="font.gray"
            lineHeight="1.6rem"
            fontSize="1.6rem"
            loadingText="写真を追加"
            variant="text"
            isDisabled={isDisabled}
            onClick={handleOpenCreateTask}
            leftIcon={<IconPlus width="2.4rem" height="2.4rem" />}
          >
            指摘を作成
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default memo(ItemTasks);

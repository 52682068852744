import { documentTaskApi } from "apiClient/v2";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { setDocumentTasks, setIsLoadedDocumentTasks } from "redux/taskSlice";

interface iProps {
  bimFileId: string | undefined;
  isFetch?: boolean;
}

const useFetchDocumentTasks = ({ bimFileId, isFetch = true }: iProps) => {
  const { isLoadedDocumentTasks } = useSelector(
    (state: RootState) => state.task
  );
  const bimFileIdCurrentRef = useRef("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadedDocumentTasks) {
      bimFileIdCurrentRef.current = "";
    }
    if (
      !bimFileId ||
      bimFileIdCurrentRef.current === bimFileId ||
      !isFetch ||
      isLoadedDocumentTasks
    ) {
      return;
    }
    bimFileIdCurrentRef.current = bimFileId;

    (async () => {
      const documentTasks =
        await documentTaskApi.getAllDocumentTasksByBimFileId({ bimFileId });
      dispatch(setDocumentTasks(documentTasks));
      dispatch(setIsLoadedDocumentTasks(true));
    })();
  }, [dispatch, bimFileId, isFetch, isLoadedDocumentTasks]);

  return {};
};

export default useFetchDocumentTasks;

import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconBoardEnable(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        data-name="↳Color"
        fill="#ef4444"
        d="M7 16.885q.378 0 .63-.255.255-.255.255-.63 0-.378-.255-.63a.86.86 0 0 0-.63-.254q-.378 0-.63.254a.86.86 0 0 0-.255.63q0 .378.255.63.255.255.63.255M7 7.25a.73.73 0 0 0-.535.216A.73.73 0 0 0 6.25 8v4.635q0 .318.216.534a.73.73 0 0 0 .534.216q.32 0 .535-.216a.73.73 0 0 0 .215-.534V8a.73.73 0 0 0-.216-.534A.73.73 0 0 0 7 7.25m10 9.5q.318 0 .534-.216A.73.73 0 0 0 17.75 16a.73.73 0 0 0-.216-.534.73.73 0 0 0-.534-.216h-5a.73.73 0 0 0-.534.216.73.73 0 0 0-.216.534q0 .32.216.535a.73.73 0 0 0 .534.215zm0-4q.318 0 .534-.216A.73.73 0 0 0 17.75 12a.73.73 0 0 0-.216-.534.73.73 0 0 0-.534-.216h-5a.73.73 0 0 0-.534.216.73.73 0 0 0-.216.534q0 .32.216.534a.73.73 0 0 0 .534.216zm0-4q.318 0 .534-.216A.73.73 0 0 0 17.75 8a.73.73 0 0 0-.216-.535A.73.73 0 0 0 17 7.25h-5a.73.73 0 0 0-.534.216.73.73 0 0 0-.216.534q0 .32.216.534A.73.73 0 0 0 12 8.75zM4.308 20.5q-.758 0-1.283-.525a1.75 1.75 0 0 1-.525-1.283V5.308q0-.758.525-1.283T4.308 3.5h15.384q.758 0 1.283.525t.525 1.283v13.384q0 .758-.525 1.283t-1.283.525zm0-1.5h15.384a.3.3 0 0 0 .212-.096.3.3 0 0 0 .096-.212V5.308a.3.3 0 0 0-.096-.212.3.3 0 0 0-.212-.096H4.308a.3.3 0 0 0-.212.096.3.3 0 0 0-.096.212v13.384q0 .116.096.212a.3.3 0 0 0 .212.096"
      />
    </Icon>
  );
}

import { SocketContext } from "contexts/WebSocketContext";
import { WSMessage } from "interfaces/models/websocket";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setStatusWebSocketBimFileOpen } from "redux/appSlice";
import { RootState } from "redux/store";

export const useAppWebSocket = (channel = "", isKeepConnect = true) => {
  const {
    isWebSocketOpen,
    joinChannel,
    emitData,
    leaveChannel,
    messageList,
    clearMessageList,
  } = useContext(SocketContext);
  const dispatch = useDispatch();
  const { bimFileId } = useParams();
  const DEFAULT_CHANNEL = useMemo(
    () => channel || bimFileId || "ALL",
    [channel, bimFileId]
  );

  const { isOnline, isWebSocketBimFileOpen } = useSelector(
    (state: RootState) => state.app
  );
  const isConnect = useMemo(() => {
    return isWebSocketOpen && isKeepConnect && isOnline;
  }, [isWebSocketOpen, isKeepConnect, isOnline]);

  // broadcast message
  const sendWebSocketMessage = useCallback(
    (message: WSMessage) => {
      if (!isConnect) return;
      const channel = message.channel || DEFAULT_CHANNEL;
      emitData({ ...message, channel });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isConnect, DEFAULT_CHANNEL]
  );

  // subscribe to channel
  useEffect(() => {
    const isValid = isConnect && !!DEFAULT_CHANNEL;
    if (isValid) {
      joinChannel(DEFAULT_CHANNEL);
    }

    return () => {
      if (isValid) leaveChannel(DEFAULT_CHANNEL);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DEFAULT_CHANNEL, isConnect]);

  const webSocketMessages = useMemo(() => {
    if (!messageList.length || !isConnect) return [];
    const data = messageList.map((e) => (e as any).message as WSMessage);
    clearMessageList();

    return data;
  }, [isConnect, messageList, clearMessageList]);

  useEffect(() => {
    if (!bimFileId || (isWebSocketBimFileOpen && isWebSocketOpen)) {
      return;
    }
    dispatch(setStatusWebSocketBimFileOpen(isWebSocketOpen));
  }, [isWebSocketBimFileOpen, isWebSocketOpen, bimFileId, dispatch]);

  return {
    webSocketMessages,
    sendWebSocketMessage,
    isWebSocketOpen,
  };
};

import { Flex, Text } from "@chakra-ui/react";
import { MentionData, MentionPluginTheme } from "@draft-js-plugins/mention";
import AvatarUser from "components/AvatarUser";
import { MouseEvent } from "react";

export interface EntryComponentProps {
  className?: string;
  onMouseDown(event: MouseEvent): void;
  onMouseUp(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  role: string;
  id: string;
  "aria-selected"?: boolean | "false" | "true";
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

const ModalMentionSuggestions = (props: EntryComponentProps) => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <Flex
        _hover={{ bgColor: "#e6f3ff" }}
        _active={{ bgColor: "#cce7ff" }}
        p="0 10px 6px"
        transition="background-color .4s cubic-bezier(.27,1.27,.48,.56)"
      >
        <AvatarUser
          w="2.4rem"
          h="2.4rem"
          borderRadius="12px"
          src={mention.avatar}
          display="flex"
        />
        <Text
          display="flex"
          fontSize=".9em"
          marginLeft="8px"
          maxWidth="368px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          alignItems="center"
        >
          {mention.name}
        </Text>
      </Flex>
    </div>
  );
};

export default ModalMentionSuggestions;

import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
  Text,
  UseModalProps,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";

interface Props extends UseModalProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  buttonConfirm?: string;
  buttonCancel?: string;
  isLoading?: boolean;
  size?: ModalProps["size"];
  modalContentProps?: ModalContentProps;
  isShowCloseIcon?: boolean;
  footer?: React.ReactNode;
  iconCloseProps?: ButtonProps;
  onProcessing?: () => void | Promise<void>;
  isDelete?: boolean;
}

function ConfirmModal({
  title,
  content,
  isOpen,
  isLoading,
  buttonConfirm,
  buttonCancel = "キャンセル",
  size = "xl",
  modalContentProps,
  isShowCloseIcon = true,
  footer,
  onClose,
  onProcessing,
  iconCloseProps,
  isDelete,
  ...rest
}: Props) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      closeOnOverlayClick={!isLoading}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        borderRadius="8px"
        p="2rem 3.7rem 2.1rem 3.2rem"
        {...modalContentProps}
      >
        {isShowCloseIcon && (
          <ModalCloseButton
            fontSize="12px"
            color="#737373"
            isDisabled={isLoading}
            _focus={{
              border: "none",
            }}
            _focusVisible={{}}
          />
        )}
        <Text fontSize="1.8rem" color="#000000CC" fontWeight="bold">
          {title}
        </Text>
        <Box mt="2rem">{content}</Box>

        {footer ? (
          footer
        ) : (
          <Flex justifyContent="right" mt="2.2rem">
            <Button
              id="cancel"
              key="cancel"
              onClick={onClose}
              isDisabled={isLoading}
            >
              {buttonCancel}
            </Button>
            <Button
              id="ok"
              key="ok"
              variant={isDelete ? "danger" : "filled"}
              onClick={onProcessing}
              ml="0.8rem"
              leftIcon={
                isDelete ? <SvgIcon src="/img/icon-trash.svg" /> : undefined
              }
              isLoading={isLoading}
            >
              {buttonConfirm}
            </Button>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;

import { Box, Flex, Image, Spinner, Text, useBoolean } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import CameraModal from "components/modal/CameraModal";
import DrawToolModal, {
  HandleSelectDrawImageProps,
} from "components/modal/DrawToolModal";
import ForgeImageTaskModal from "components/modal/ForgeImageTaskModal";
import useHandleForgeImageModal from "components/modal/ForgeImageTaskModal/hook";
import { useAuthorization } from "hooks/usePermission";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { SizePosition } from "interfaces/models/rnd";
import ComponentToggle from "pages/project-overview/ui/ComponentToggle";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SliderSlick from "react-slick";
import { RootState } from "redux/store";
import { setTaskSelected } from "redux/taskSlice";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useDeepCompareEffect from "use-deep-compare-effect";
import { sleep, uuid } from "utils/common";
import {
  fileToBase64,
  getDateModifiedDateFile,
  getImagesInfo,
  getPreSignUrl,
  imageUrlToBase64,
  uploadFileToS3,
} from "utils/file";
import { logDev } from "utils/logs";
import { obsoletableFn } from "utils/promise";
import ImageItem from "./ImageItem";

const ImagePresigned = withPresignedUrl(Image);

interface Props {
  keyImageName?: string;
  documentItem?: DocumentItemDTO;
  blackBoardTemplateDetail?: BlackboardTemplate;
  blackBoard?: Blackboard | null;
  imgSelectedTemp?: FileModel;
  listImageSelected: FileModel[];
  isOpenSelectBlackBoardPosition?: boolean;
  isDrawImageSavingParent?: boolean;
  isShowBlackboard?: boolean;
  currentTask?: TaskDTO;
  isDisableUploadImage?: boolean;
  readonly?: boolean;
  uploadForm?: any;
  setIsDrawImageSavingParent?: React.Dispatch<React.SetStateAction<boolean>>;
  originImageLinks?: string[];
  isShowOriginalLink?: boolean;

  setIsDisableUploadImage?: (loading: boolean, data?: any) => void;
  onSaveDrawnImageOtherLogic?: (params: HandleSelectDrawImageProps) => void;
  onSaveDrawImage?: (
    newListImg: FileModel[],
    requestId?: string
  ) => Promise<void>;
  onDeleteImage: (index: number) => void;
  onSelectBlackBoardPosition?: (params: {
    index: number;
    position: SizePosition;
  }) => Promise<void>;
  onCloseSelectBlackBoardPosition?: (() => void) | undefined;
  onOpenSelectBlackBoardPosition?: (() => void) | undefined;
  onEdit?: (file?: FileModel) => void;
  className?: string;
}

const Slider = ({
  originImageLinks,
  keyImageName,
  currentTask,
  isDisableUploadImage,
  documentItem,
  listImageSelected,
  isShowBlackboard = false,
  uploadForm,
  readonly,
  onSaveDrawnImageOtherLogic,
  onSaveDrawImage,
  setIsDisableUploadImage,
  setIsDrawImageSavingParent,
  onDeleteImage,
  isShowOriginalLink,
  className,
}: Props) => {
  const [mapIsLoadedImage, setMapIsLoadedImage] = useState<
    Record<number, boolean>
  >({});
  const [activeThumb, setActiveThumb] = useState(0);
  const imageCaptured = useRef<File>();
  const [isOpenDraw, setOpenDraw] = useBoolean();
  const [isOpenCameraModal, setOpenCameraModal] = useBoolean();
  const [imgSelected, setImgSelected] = useState<FileModel>({});
  const [listImageBase64, setListImageBase64] = useState<FileModel[]>([]);
  const { isOnline } = useSelector((state: RootState) => state.app);
  const { taskSelected } = useSelector((state: RootState) => state.task);
  const { documentItemSelected } = useSelector(
    (state: RootState) => state.document
  );
  const { currentUser } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const lastTaskIdRef = useRef("");
  const lastDocumentItemIdRef = useRef("");
  const activeThumbRef = useRef(0);
  const isRecaptureImageRef = useRef(false);
  const { canEditTaskModal } = useAuthorization();
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (_: number, next: number) => {
      handleChangeSelectedImage(next);
    },
  };

  const {
    isOpenForgeImageTaskModal,
    onCloseForgeImageTaskModel,
    onOpenForgeImageTaskModal,
  } = useHandleForgeImageModal();

  const { users } = useSelector((state: RootState) => state.user);

  const isListImageSelectedValid = useMemo(
    () =>
      listImageSelected?.length && listImageSelected.every((img) => !!img.src),
    [listImageSelected]
  );

  const mapUser = useMemo(() => {
    return users.reduce((prev, item) => {
      return Object.assign(prev, {
        [item.id || ""]: item.name,
      });
    }, {}) as { [key: string]: string };
  }, [users]);

  const convertListImageToBase64 = async (images: typeof listImageSelected) => {
    if (images?.length === 1) {
      return images;
    }

    const convertListImage = await Promise.all(
      images?.map(async (image) => {
        if (!image?.src?.includes(`${process.env.REACT_APP_S3_URL}/`)) {
          return image;
        }

        const presignedImageUrl = await getPreSignUrl(String(image?.src), "");
        const base64String = await imageUrlToBase64(String(presignedImageUrl));

        return {
          ...image,
          src: base64String,
        };
      })
    );

    if (convertListImage.every((img) => !img.src)) {
      return [];
    }

    return convertListImage;
  };

  const handleChangeSelectedImage = useCallback(
    (index: number) => {
      setImgSelected(listImageBase64?.[index] || listImageSelected?.[index]);
      activeThumbRef.current = index;
      setActiveThumb(index);
    },
    [listImageBase64, listImageSelected]
  );

  const onResetDataImages = useCallback(() => {
    setListImageBase64([]);
    setMapIsLoadedImage({});
    setImgSelected({});
  }, []);

  const edit = () => {
    setOpenDraw.on();
    onCloseForgeImageTaskModel();
  };

  const handleFileSelected = async ({
    file,
    isEdited,
    originFile,
    hasCapturedCamera,
  }: HandleSelectDrawImageProps) => {
    setIsDrawImageSavingParent && setIsDrawImageSavingParent(true);

    const handleFinal = (data?: any) => {
      imageCaptured.current = undefined;

      setIsDisableUploadImage && setIsDisableUploadImage(false, data);
      setIsDrawImageSavingParent && setIsDrawImageSavingParent(false);
    };

    let newTaskSelected: any = null;
    const imgString = await fileToBase64(file);
    const newListImage = (await getImagesInfo({
      images: listImageSelected,
      currentIndexImage: activeThumbRef.current,
      hasCapturedCamera,
      currentSrcImage: imgString,
      currentIdUser: currentUser?.id || "",
    })) as FileModel[];

    if (currentTask && keyImageName) {
      newTaskSelected = {
        ...currentTask,
        [keyImageName]: newListImage?.map((i) => i?.src || (i as string)),
      };
      setIsDisableUploadImage && setIsDisableUploadImage(true, newTaskSelected);
    }

    setImgSelected(newListImage?.[activeThumbRef.current]);
    try {
      if (newTaskSelected) {
        dispatch(setTaskSelected(newTaskSelected));
      }
      let isRemoveOrigin = false;
      let originFileModel: FileModel = undefined as any;
      const isRecaptureImage = isRecaptureImageRef.current;
      const image = (
        taskSelected?.[keyImageName as keyof TaskDTO] as FileUploadInfo[]
      )?.[activeThumbRef.current];
      let originSrc = image?.originSrc;
      const isExistsOriginFile = !!originSrc;
      if (isEdited && (!isExistsOriginFile || isRecaptureImage)) {
        originFileModel = { file: originFile, name: originFile.name };
        isRemoveOrigin = !!originSrc;
      } else if (!isEdited && isRecaptureImage && isExistsOriginFile) {
        isRemoveOrigin = true;
      }
      const requestId = uuid();

      let imgSrc = "";
      const request = [];
      request.push(
        uploadFileToS3(file, file?.name || Date.now().toString(), "task", {
          requestId,
        }).then((src) => {
          imgSrc = src;
        })
      );

      if (isRemoveOrigin && originSrc) {
        originSrc = "";
      }

      if (originFileModel?.file && newTaskSelected) {
        request.push(
          uploadFileToS3(
            originFileModel.file,
            originFileModel?.name || Date.now().toString(),
            "task",
            {
              requestId,
            }
          ).then((src) => {
            originSrc = src;
          })
        );
      }
      await Promise.all(request);
      const images = await getImagesInfo({
        images: listImageSelected,
        currentIndexImage: activeThumbRef.current,
        hasCapturedCamera,
        currentSrcImage: imgSrc,
        originSrc,
        currentIdUser: currentUser?.id || "",
      });

      if (newTaskSelected && keyImageName) {
        newTaskSelected = {
          ...newTaskSelected,
          [keyImageName]: images?.map((i) => i?.src || (i as string)),
        };
      }

      if (onSaveDrawnImageOtherLogic) {
        onSaveDrawnImageOtherLogic({ file, isEdited, originFile, activeThumb });
      } else {
        await onSaveDrawImage?.(images as FileModel[], requestId);
      }

      handleFinal(newTaskSelected);
    } catch (err) {
      logDev(err, "=handleFileSelected=");

      handleFinal(newTaskSelected);
    }
  };

  useEffect(() => {
    if (!listImageSelected?.length && imgSelected?.src) {
      setMapIsLoadedImage({});
      setImgSelected({});
    }
  }, [listImageSelected?.length, imgSelected]);

  useEffect(() => {
    if (lastTaskIdRef.current !== currentTask?.id) {
      onResetDataImages();

      return;
    }
  }, [taskSelected?.id, currentTask?.id]);

  useEffect(() => {
    if (
      !listImageBase64?.length ||
      !listImageSelected?.length ||
      taskSelected?.id !== lastTaskIdRef.current
    ) {
      return;
    }

    if (listImageSelected?.length > listImageBase64?.length) {
      activeThumbRef.current = listImageSelected?.length - 1;
      setActiveThumb(activeThumbRef.current);
      if (sliderRef.current) {
        (sliderRef.current as any).slickGoTo(activeThumbRef.current);
      }
    }
  }, [listImageBase64, listImageSelected, taskSelected?.id]);

  const obsoletableConvertListImageToBase64 = obsoletableFn(
    async (isObsolete: Function, images: typeof listImageSelected) => {
      const convertListImage = await convertListImageToBase64(images);
      if (isObsolete()) {
        onResetDataImages();

        return;
      }
      if (currentTask?.id) {
        lastTaskIdRef.current = currentTask.id;
      }

      if (documentItem?.id) {
        lastDocumentItemIdRef.current = documentItem.id;
      }

      await sleep(500);
      setListImageBase64(convertListImage as FileModel[]);
    }
  );

  useDeepCompareEffect(() => {
    if (!listImageSelected?.length) {
      onResetDataImages();

      return;
    }
    obsoletableConvertListImageToBase64(listImageSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listImageSelected]);

  useEffect(() => {
    if (listImageSelected?.length === 1) {
      setImgSelected(listImageSelected?.[activeThumb]);

      return;
    }

    if (
      listImageSelected.every((img) => !img.src) ||
      listImageBase64.every((img) => !img.src)
    ) {
      return;
    }

    if (listImageSelected?.length !== listImageBase64?.length) {
      return;
    }

    if (listImageBase64?.length < activeThumb + 1) {
      return;
    }

    if (listImageBase64?.[activeThumb]?.src !== imgSelected.src) {
      setImgSelected(listImageBase64?.[activeThumb]);
    }
    // set selected image when change active thumb
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeThumb, listImageSelected, listImageBase64]);

  useEffect(() => {
    // on first load set img selected
    if (!isListImageSelectedValid) {
      return;
    }

    let firstImage = listImageSelected[activeThumbRef.current];
    // reset active thumb
    if (activeThumbRef.current + 1 > listImageSelected?.length) {
      firstImage = listImageSelected?.[0];
      activeThumbRef.current = 0;
      setMapIsLoadedImage({});
      setImgSelected(firstImage);
      setActiveThumb(0);
    }

    if (!firstImage || !firstImage?.src) {
      return;
    }

    if (
      !!imgSelected?.src &&
      (documentItemSelected?.id === lastDocumentItemIdRef.current ||
        taskSelected?.id === lastTaskIdRef.current)
    ) {
      return;
    }

    setMapIsLoadedImage({});
    setImgSelected(firstImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isListImageSelectedValid,
    listImageSelected,
    taskSelected?.id,
    documentItemSelected?.id,
  ]);

  const onUpdateLoadedImage = useCallback(
    (params: { activeThumb: number; status: boolean }) => {
      const { activeThumb, status } = params;
      setMapIsLoadedImage((prev) => ({ ...prev, [activeThumb]: status }));
    },
    []
  );

  useEffect(() => {
    if (
      imgSelected?.src?.includes("data:image/") ||
      imgSelected?.src?.includes("application/octet-stream;base64") ||
      !isListImageSelectedValid
    ) {
      return;
    }

    const getPathname = (src: string | undefined) => {
      try {
        return src ? new URL(src).pathname : "";
      } catch {}
    };

    const listImageSelectedSrc = listImageSelected
      .filter((item) => !!item.src)
      .map((item) => getPathname(item.src!));
    const imgSelectedSrc = getPathname(imgSelected?.src || "");

    if (
      imgSelectedSrc?.includes("data:image/") ||
      imgSelectedSrc?.includes("application/octet-stream;base64")
    ) {
      return;
    }

    if (!listImageSelectedSrc.includes(imgSelectedSrc)) {
      setImgSelected(listImageSelected?.[0]);
      setActiveThumb(0);
      activeThumbRef.current = 0;
    }
  }, [imgSelected?.src, isListImageSelectedValid, listImageBase64]);

  const renderMainImage = useMemo(() => {
    if (!!listImageSelected?.length) {
      return (
        <Box
          minH="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb="5px"
        >
          <ComponentToggle
            isToggle={!!imgSelected?.src && !!listImageBase64?.length}
            ViewToggle={
              <ImagePresigned
                width="auto"
                height={imgSelected?.src ? "auto" : "200px"}
                src={imgSelected?.src}
                alt="product images"
                objectFit="contain"
                maxH="200px"
                boxLoadingWidth="100%"
                boxLoadingHeight="200px"
                isCheckLoadingPresignedUrl={!isDisableUploadImage}
                {...(!isShowBlackboard
                  ? {
                      onClick: onOpenForgeImageTaskModal,
                    }
                  : {})}
                onLoad={() => {
                  onUpdateLoadedImage({ activeThumb, status: true });
                }}
                onError={() => {
                  onUpdateLoadedImage({ activeThumb, status: false });
                }}
              />
            }
            ViewDefault={
              <Flex
                alignItems="center"
                width="100%"
                height="200px"
                cursor="initial"
              >
                <Spinner color="blue.500" />
              </Flex>
            }
          />
        </Box>
      );
    }

    return <></>;
  }, [
    activeThumb,
    listImageBase64,
    imgSelected?.src,
    listImageSelected,
    isDisableUploadImage,
    isShowBlackboard,
    onOpenForgeImageTaskModal,
    onUpdateLoadedImage,
  ]);

  const handleBeforeDeleteImage = () => {
    setListImageBase64((prev) =>
      prev.filter((_, index) => index !== activeThumb)
    );
    activeThumbRef.current = 0;
    setActiveThumb(0);
    setImgSelected(listImageSelected[0]);
    onDeleteImage(activeThumb);
    onCloseForgeImageTaskModel();
  };

  const handleCloseDrawToolModalAfterSave = useCallback(() => {
    imageCaptured.current = undefined;
    setOpenDraw.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDrawToolModal = useCallback(() => {
    isRecaptureImageRef.current = false;
    handleCloseDrawToolModalAfterSave();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRecaptureInDrawToolModal = useCallback(
    () => {
      isRecaptureImageRef.current = true;
      setOpenDraw.off();
      setOpenCameraModal.on();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Flex flexDir="column" maxW="100%" width="100%" className={className}>
      <Flex width="100%" flexDir="column" mb="1rem">
        <Box
          position="relative"
          cursor="pointer"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          {renderMainImage}
        </Box>

        {/* display gallery when number images > 1 */}
        {listImageSelected.length > 4 && (
          <Box className="task-slick" mt="0.3rem">
            <SliderSlick {...settings} ref={sliderRef}>
              {listImageSelected.map((item, index) => (
                <ImageItem
                  key={index}
                  index={index}
                  item={listImageBase64?.[index] || item}
                  activeThumb={activeThumb}
                  isCheckLoadingPresignedUrl={!listImageBase64?.[index]?.src}
                  onSelectImage={handleChangeSelectedImage}
                  onLoad={() => {
                    onUpdateLoadedImage({ activeThumb: index, status: true });
                  }}
                  onError={() => {
                    onUpdateLoadedImage({ activeThumb: index, status: false });
                  }}
                />
              ))}
            </SliderSlick>
          </Box>
        )}
        {listImageSelected.length > 1 && listImageSelected.length <= 4 && (
          <Flex justifyContent="center" alignItems="center">
            {listImageSelected.map((item, index) => (
              <ImageItem
                key={index}
                index={index}
                item={listImageBase64?.[index] || item}
                activeThumb={activeThumb}
                isCheckLoadingPresignedUrl={!listImageBase64?.[index]?.src}
                onSelectImage={handleChangeSelectedImage}
                width="25%"
                maxH="60px"
                onLoad={() => {
                  onUpdateLoadedImage({ activeThumb: index, status: true });
                }}
                onError={() => {
                  onUpdateLoadedImage({ activeThumb: index, status: false });
                }}
              />
            ))}
          </Flex>
        )}

        {isOpenCameraModal && (
          <CameraModal
            isOpen={isOpenCameraModal}
            onClose={setOpenCameraModal.off}
            onCapture={(file) => {
              imageCaptured.current = file;
              setOpenDraw.on();
            }}
          />
        )}

        {isOpenDraw && (
          <DrawToolModal
            isOpen={isOpenDraw}
            onSelect={handleFileSelected}
            onCloseAfterSave={handleCloseDrawToolModalAfterSave}
            onClose={handleCloseDrawToolModal}
            fileRef={imageCaptured}
            imageUrl={!imageCaptured.current ? imgSelected?.src : ""}
            reCapture={handleRecaptureInDrawToolModal}
          />
        )}

        {isOpenForgeImageTaskModal && !readonly && (
          <ForgeImageTaskModal
            isOnline={isOnline}
            isOpen={isOpenForgeImageTaskModal}
            image={imgSelected?.src}
            isLoadedImage={mapIsLoadedImage?.[activeThumb]}
            onClose={onCloseForgeImageTaskModel}
            onEdit={edit}
            onDelete={handleBeforeDeleteImage}
          />
        )}
      </Flex>

      <Flex ml="0" alignItems="center" justifyContent="space-between" px="1rem">
        <Flex flexDirection="column" color="#737373" flexShrink={0}>
          {!!imgSelected?.userUpload && (
            <Text fontSize="1rem">{`撮影：${
              mapUser[imgSelected.userUpload || ""]
            }`}</Text>
          )}
          {!!imgSelected?.uploadTime && (
            <Text fontSize="1rem">{`日時：${getDateModifiedDateFile(
              imgSelected as any
            )}`}</Text>
          )}
          {!!originImageLinks?.[activeThumb] &&
            (canEditTaskModal?.canViewImageOriginal || isShowOriginalLink) && (
              <Text
                style={
                  isOnline
                    ? {
                        fontSize: "1rem",
                        color: "#1795FE",
                        textDecoration: "underline",
                      }
                    : {
                        fontSize: "1rem",
                        color: "#737373",
                        textDecoration: "underline",
                      }
                }
                cursor={isOnline ? "pointer" : "not-allowed"}
                onClick={() => {
                  if (isOnline) {
                    window.open(originImageLinks?.[activeThumb]);
                  }
                }}
              >
                元の写真を参照
              </Text>
            )}
        </Flex>
        {typeof uploadForm === "function"
          ? uploadForm?.(activeThumb)
          : uploadForm}
      </Flex>
    </Flex>
  );
};

export default Slider;

import { Box, Flex, Spinner, Text, UseModalProps } from "@chakra-ui/react";
import TipTapEditorDoc from "components/TipTapEditorDoc";
import { usePreviewEditorDocument } from "components/TipTapEditorDoc/hook";
import { DocumentTemplateType } from "constants/enum";
import { DEFAULT_MAX_WIDTH_IPAD } from "constants/styleProps";
import EditorDocumentContext from "contexts/EditorDocumentContext";
import useFamilyInstance from "hooks/useFamilyInstance";
import { useResize } from "hooks/useResize";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { NeptuneArea } from "interfaces/models/area";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { List } from "react-virtualized";
import { setStatusContentFromS3 } from "redux/documentSlice";
import ClosePreviewDocument from "./ClosePreviewDocument";
import usePreviewData from "./hooks/usePreviewData";
import usePreviewDocumentHeader, {
  DEFAULT_PAGE_ZOOM,
} from "./hooks/usePreviewDocumentHeader";
import PreviewContainer from "./PreviewContainer";
import PreviewDocumentHeader from "./PreviewDocumentHeader";
import PreviewDocumentSlideFade from "./PreviewDocumentSlideFade";

interface Props extends UseModalProps {
  isChangeSheet?: boolean;
  isCreatingKeynote?: boolean;
  isLoadingDownload?: boolean;
  documentCategorySelected?: DocumentCategory;
  documentItemSelected?: DocumentItemDTO;
  documentItems?: DocumentItem[];
  documentItemsHasImage?: any;
  currentDocument: DocumentDTO | null;
  listAllUserById?: {
    [key: string]: User | null;
  };
  companiesById: Record<string, PartnerCompany>;
  loadingPrintPdf?: boolean;
  mainStyle?: React.CSSProperties;
  isShowFullWidth?: boolean;
  neptuneAreas?: NeptuneArea[];
  isLoadingPreview: boolean;
  template?: DocumentTemplate;
  components?: TemplateComponent[];
  onDownload: (isContentFromS3: boolean) => void;
  handlePrintPdf: (isContentFromS3: boolean) => Promise<void>;
  clearCachePdf(): void;
  handleChangeSelectedSheet?: (value: any) => void;
  onChangeCurrentDocument: (content: any) => void;
}

export interface IPreviewDocumentCategoryHandle {
  resetValuePreview: () => void;
}

const PreviewDocumentCategory = forwardRef<
  IPreviewDocumentCategoryHandle,
  Props
>(
  (
    {
      isChangeSheet = false,
      isCreatingKeynote,
      isOpen,
      isLoadingDownload,
      documentItems = [],
      documentItemsHasImage,
      documentCategorySelected,
      documentItemSelected,
      currentDocument,
      listAllUserById,
      companiesById,
      loadingPrintPdf,
      mainStyle,
      isShowFullWidth = false,
      isLoadingPreview,
      template,
      components,
      neptuneAreas,
      onDownload,
      onClose,
      handlePrintPdf,
      clearCachePdf,
      handleChangeSelectedSheet,
      onChangeCurrentDocument,
    },
    ref
  ) => {
    const {
      isGeneratingPdf,
      formatDocumentItems,
      documentCategories,
      isShowSheetMenu,
      blackboardTemplateProps,
      projectName,
    } = usePreviewData({
      documentItemsHasImage,
      documentCategorySelected,
      documentItemSelected,
      components,
      template,
    });

    const { familyInstances } = useFamilyInstance();

    const dispatch = useDispatch();
    const previewScrollRef = useRef<HTMLDivElement>();

    const autoSizerListRef = useRef<List>();
    const _editorRef = useRef(null);
    const onInitEditor = useCallback((editor: any) => {
      _editorRef.current = editor;
    }, []);

    const { width: screenWidth } = useResize();

    const {
      isEditMode,
      pageZoom,
      isSavingDocument,
      isResetDataKeynote,
      onBeforeClose,
      onChangeEditMode,
      onCancelEditMode,
      callbackDragKeynoteStop,
      onChangePageZoom,
      onSaveEditor,
    } = usePreviewDocumentHeader({
      isOpen,
      editor: _editorRef.current,
      currentDocument,
      documentCategorySelected,
      documentItemSelected,
      onClose,
      onChangeCurrentDocument,
    });
    const {
      pages,
      isLoadingContentEditor,
      isValidateContentEditor,
      editor,
      isContentFromS3,
      isLoadingShowEditor,
      setContentEditor,
      onCancelEditable,
      clearContentEditor,
    } = usePreviewEditorDocument({
      isSavingDocument,
      isEditMode,
      currentDocument,
      components,
      template,
      documentItems:
        documentCategorySelected?.documentType ===
        DocumentTemplateType.PHOTO_LEDGER
          ? formatDocumentItems
          : documentItems,
      pageZoom,
      projectName,
      blackboardTemplateProps,
      listUserById: listAllUserById,
      familyInstances,
      documentCategorySelected,
      companiesById,
      neptuneAreas,

      onInitEditor,
    });

    const isScreenWidthValid = useMemo(
      () => screenWidth > DEFAULT_MAX_WIDTH_IPAD && !isShowFullWidth,
      [screenWidth, isShowFullWidth]
    );

    const onBeforeCancelEditMode = useCallback(() => {
      onCancelEditable();
      onCancelEditMode();
    }, [onCancelEditMode, onCancelEditable]);

    const onAfterSaveEditor = useCallback(async () => {
      const content = await onSaveEditor();
      if (content) {
        setContentEditor({
          dataChange: content,
          initData: content,
          currentData: content,
        });
      }
      clearCachePdf();
    }, [onSaveEditor, setContentEditor, clearCachePdf]);

    const onBeforeChangeSheet = useCallback(
      (value: any) => {
        clearCachePdf();
        handleChangeSelectedSheet?.(value);
      },
      [clearCachePdf, handleChangeSelectedSheet]
    );

    const resetValuePreview = useCallback(() => {
      dispatch(setStatusContentFromS3(null as any));
      onBeforeCancelEditMode();
      onBeforeClose();
      clearContentEditor();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPrintPdf = useCallback(
      async (isContentFromS3: boolean) => {
        autoSizerListRef.current?.scrollToRow(0);
        handlePrintPdf(isContentFromS3);
      },
      [handlePrintPdf]
    );

    const onDownloadPdf = useCallback(
      async (isContentFromS3: boolean) => {
        autoSizerListRef.current?.scrollToRow(0);
        onDownload(isContentFromS3);
      },
      [onDownload]
    );

    useImperativeHandle(ref, () => ({
      resetValuePreview,
    }));

    useEffect(() => {
      return () => {
        resetValuePreview();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLoadSpin =
      isLoadingPreview || isLoadingContentEditor || isValidateContentEditor;

    const isDisableButton = useMemo(() => {
      return (
        isLoadSpin ||
        isChangeSheet ||
        loadingPrintPdf ||
        isLoadingDownload ||
        isCreatingKeynote
      );
    }, [
      isCreatingKeynote,
      loadingPrintPdf,
      isLoadingDownload,
      isChangeSheet,
      isLoadSpin,
    ]);

    return (
      <>
        <PreviewDocumentSlideFade
          isOpen={isOpen}
          isScreenWidthValid={isScreenWidthValid}
          onClose={onBeforeClose}
        >
          <Box
            zIndex={122}
            bgColor="#000000"
            p="0"
            h="100%"
            boxShadow="0px 0 4px #707070"
            position="relative"
            overflowY={isLoadSpin ? "hidden" : "auto"}
            style={mainStyle}
            ref={previewScrollRef as any}
          >
            <PreviewDocumentHeader
              isContentFromS3={isContentFromS3}
              isShowSheetMenu={!!isShowSheetMenu}
              isLoadSpin={isLoadSpin}
              isLoadingDownload={!!isLoadingDownload}
              isDisableButton={!!isDisableButton}
              isEditMode={isEditMode}
              isLoading={!!isLoadingPreview}
              isSavingDocument={isSavingDocument}
              loadingPrintPdf={!!loadingPrintPdf}
              pageZoom={pageZoom}
              documentCategorySelected={documentCategorySelected}
              editor={editor}
              onClose={onBeforeClose}
              onChangeEditMode={onChangeEditMode}
              onCancelEditMode={onBeforeCancelEditMode}
              onDownload={onDownloadPdf}
              onPrintPdf={onPrintPdf}
              onChangeSelectedSheet={onBeforeChangeSheet}
              onChangePageZoom={onChangePageZoom}
              onSaveEditor={onAfterSaveEditor}
            />

            {isScreenWidthValid && (
              <ClosePreviewDocument
                isEditMode={isEditMode}
                onClose={onBeforeClose}
              />
            )}

            {isLoadSpin && (
              <Flex
                position="sticky"
                width="100%"
                height="calc(100% - 64px)"
                top="64px"
                zIndex="sticky"
                bgColor="#000000"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="xl" color="white" />
              </Flex>
            )}

            {/* loading for virtual scroll */}
            {!isEditMode &&
              pageZoom === DEFAULT_PAGE_ZOOM &&
              isContentFromS3 === false && (
                <Spinner
                  id="loading-scroll"
                  position="sticky"
                  top="80%"
                  left="50%"
                  transform="translateX(-50%)"
                  size="xl"
                  color="white"
                />
              )}

            {/* preview */}
            <Box id="container-preview">
              {isContentFromS3 === false && (
                <Box display={isEditMode || isLoadSpin ? "none" : "block"}>
                  <PreviewContainer
                    autoSizerListRef={autoSizerListRef as any}
                    pages={pages}
                    pageZoom={pageZoom}
                    template={template}
                    components={components}
                    listUserById={listAllUserById}
                    companiesById={companiesById}
                    documentItems={
                      documentCategorySelected?.documentType ===
                      DocumentTemplateType.PHOTO_LEDGER
                        ? formatDocumentItems
                        : documentItems
                    }
                    documentCategories={documentCategories}
                    documentCategorySelected={documentCategorySelected}
                    currentDocument={currentDocument}
                    isCreatingKeynote={isCreatingKeynote}
                    neptuneAreas={neptuneAreas}
                    blackboardTemplateProps={blackboardTemplateProps}
                    familyInstances={familyInstances}
                    projectName={projectName}
                    previewScrollRef={previewScrollRef}
                  />
                </Box>
              )}

              <EditorDocumentContext.Provider
                value={useMemo(
                  () => ({
                    isContentFromS3: !!isContentFromS3,
                    isResetDataKeynote,
                    isEditMode,
                    isCreatingKeynote: !!isCreatingKeynote,
                    callbackDragKeynoteStop,
                  }),
                  [
                    isContentFromS3,
                    isResetDataKeynote,
                    isEditMode,
                    isCreatingKeynote,
                    callbackDragKeynoteStop,
                  ]
                )}
              >
                <Box display={isEditMode || isContentFromS3 ? "block" : "none"}>
                  <TipTapEditorDoc editor={editor} />
                </Box>
              </EditorDocumentContext.Provider>
            </Box>
          </Box>
        </PreviewDocumentSlideFade>

        {/* loading generate pdf */}
        {(isLoadingShowEditor || isGeneratingPdf) && (
          <Box
            zIndex={123}
            bgColor="white"
            h="100%"
            w="100%"
            opacity={0.8}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="3rem" fontWeight="600" color="rgba(0,0,0,0.7)">
              読み込み中...
            </Text>
          </Box>
        )}
      </>
    );
  }
);

export default PreviewDocumentCategory;

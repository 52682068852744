import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconBoardDisabled(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#ef4444"
        fillRule="evenodd"
        d="m2.894 2.859 18.46 18.46h-.01c.15.15.23.32.23.52s-.08.37-.23.53c-.15.15-.33.23-.53.23s-.38-.07-.53-.23l-1.88-1.87H4.324c-.5 0-.93-.18-1.28-.53-.36-.35-.53-.77-.53-1.28V5.309c0-.21.04-.41.1-.59l-.08-.08-.71-.71a.736.736 0 0 1 0-1.07c.15-.15.34-.23.54-.23s.37.07.53.23m1.22 16.04c.06.07.13.1.21.1h12.57l-1.5-1.5-.75-.75h-2.63c-.21 0-.39-.08-.53-.22a.7.7 0 0 1-.22-.53c0-.21.08-.39.22-.53.14-.15.32-.22.53-.22h1.13l-5.38-5.38v2.76c0 .21-.08.39-.22.53-.14.15-.32.22-.53.22s-.39-.08-.53-.22a.7.7 0 0 1-.22-.53v-4.26l-2.25-2.25v12.57c0 .08.04.15.1.21M6.883 5h12.83c.09 0 .16.03.22.09s.09.13.09.22v12.85l.73.73c.15.15.31.18.5.11.18-.07.28-.21.28-.42V5.31c0-.51-.17-.93-.53-1.28-.35-.35-.78-.53-1.28-.53H6.883c-.25 0-.44.08-.56.24s-.19.33-.19.52.06.36.19.51c.12.16.31.23.56.23m10.125 2.25c.21 0 .39.08.53.22.15.14.22.32.22.53s-.08.39-.22.53c-.14.15-.32.22-.53.22h-5c-.21 0-.39-.08-.53-.22a.7.7 0 0 1-.22-.53c0-.21.08-.39.22-.53.14-.15.32-.22.53-.22zm.001 4h-3.9l1.5 1.5h2.4c.21 0 .39-.07.53-.22.14-.14.22-.32.22-.53s-.07-.39-.22-.53a.73.73 0 0 0-.53-.22m-9.996 3.867a.85.85 0 0 0-.88.88.85.85 0 0 0 .88.88.85.85 0 0 0 .88-.88.85.85 0 0 0-.88-.88"
        clipRule="evenodd"
      />
    </Icon>
  );
}

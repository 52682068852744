import {
  Box,
  Flex,
  Input,
  InputElementProps,
  InputGroup,
  InputProps,
  InputRightElement,
  Placement,
} from "@chakra-ui/react";
import { CrossIcon } from "components/icon/crossIcon";
import { SvgIcon } from "components/SvgIcon";
import { DATE_PLACE_HOLDER, YYYY_MM_DD_SLASH } from "constants/date";
import { LinkedDynamicFieldsType } from "constants/enum";
import { DatePickerFilterProps } from "constants/styleProps";
import dayjs from "dayjs";
import { memo, useCallback, useMemo, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateCurrent, transformDataToJp } from "utils/date";
import ja from "date-fns/locale/ja";

registerLocale("ja", ja);

interface Props {
  name?: string;
  value?: Date | string;
  formatValue?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  startDate?: Date | string;
  endDate?: Date | string;
  icon?: string;
  color?: string;
  keyDynamic?: string;
  isShowIcon?: boolean;
  inputProps?: InputProps;
  inputRightElementProps?: InputElementProps;
  datePickerFilterStyles?: DatePickerFilterProps;
  isDisabled?: boolean;
  popperPlacement?: Placement;
  popperModifiers?: Object[];
  popperProps?: Object;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  shouldCloseOnSelect?: boolean;
  showPopperArrow?: boolean;
  readonly?: boolean;
  placeholder?: string;
  inputHeight?: string;
  canDeleteDate?: boolean;
  onSelectedDay?: (day: Date | string, field?: string) => void;
  onClose?: (date?: Date | string) => void;
  onSelectedDayDynamic?: (key: string, value: string, name?: string) => void;
  onCalendarOpen?: () => void;
}

const DayPicker = ({
  name,
  value,
  formatValue,
  minDate,
  maxDate,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  shouldCloseOnSelect = false,
  showPopperArrow = false,
  icon = "/img/icon-calendar-white.svg",
  color = "#171717",
  keyDynamic,
  inputProps,
  inputRightElementProps,
  datePickerFilterStyles,
  isDisabled,
  popperPlacement = "bottom-start",
  popperModifiers,
  popperProps,
  readonly,
  placeholder,
  inputHeight = "4.4rem",
  canDeleteDate = true,
  onSelectedDay,
  onClose,
  onSelectedDayDynamic,
  onCalendarOpen,
}: Props) => {
  const selectedDayRef = useRef<string | Date>("");

  const getClassDay = useCallback(
    (date: Date) => {
      const currentDate = value || new Date();

      if (!dayjs(date).isSame(currentDate, "day")) return "";

      return "calendar-date-selected";
    },
    [value]
  );

  const onChangeDay = (date?: Date) => {
    let selectedDay: Date | string = date || "";

    if (!value && date) {
      const dateJp = transformDataToJp(new Date(date));
      const formatDateJp = dateJp.format("YYYY-MM-DD");
      selectedDay = dayjs(formatDateJp).toDate().toString();
    }

    if (keyDynamic && onSelectedDayDynamic) {
      const day = selectedDay.toString();
      selectedDayRef.current = day;
      onSelectedDayDynamic(keyDynamic, day, LinkedDynamicFieldsType.DATE_TIME);
    } else {
      selectedDayRef.current = selectedDay;
      onSelectedDay?.(selectedDay, name);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRemoveDate = (e: any) => {
    e.stopPropagation();
    if (isDisabled) return;

    if (!value) return;

    onChangeDay(undefined);
    onClose?.("");
  };

  const customInput = useMemo(() => {
    return (
      <InputGroup
        display="flex"
        alignItems="center"
        zIndex={0}
        borderColor="var(--primary-border-color)"
        borderRadius={4}
        sx={{
          "& > .chakra-input__right-element": { height: "100%" },
        }}
        overflowX="hidden"
        opacity={isDisabled ? 0.4 : 1}
      >
        <Input
          value={
            value
              ? formatValue ||
                formatDateCurrent(new Date(value), YYYY_MM_DD_SLASH)
              : ""
          }
          pl={"1rem"}
          height={inputHeight}
          fontSize="1.4rem"
          placeholder={placeholder ?? DATE_PLACE_HOLDER}
          color={color}
          _focus={{
            borderColor: readonly ? "var(--primary-border-color)" : "#3182ce",
          }}
          {...inputProps}
          readOnly
          cursor={readonly ? "not-allowed" : "unset"}
          bgColor={
            readonly === undefined
              ? "unset"
              : !!readonly
              ? "#f1f1f1"
              : "#fafafa"
          }
        />

        {!readonly && (
          <InputRightElement
            children={
              <Flex alignItems="center" {...inputRightElementProps}>
                <Flex
                  alignItems="center"
                  color="hsl(0, 0%, 80%)"
                  _hover={{
                    color: "hsl(0, 0%, 60%)",
                  }}
                  aria-label="icon-remove-day"
                  opacity={canDeleteDate ? 1 : 0.5}
                  cursor={canDeleteDate ? undefined : "not-allowed"}
                  onClick={(e) => canDeleteDate && handleRemoveDate(e)}
                >
                  <CrossIcon {...datePickerFilterStyles?.crossIconProps} />
                </Flex>
                <>
                  <Box
                    width="1px"
                    height="3rem"
                    bg="hsl(0, 0%, 80%)"
                    mx={3}
                    {...datePickerFilterStyles?.dividerProps}
                  />
                  <SvgIcon
                    src={icon}
                    mr="40px"
                    {...datePickerFilterStyles?.calendarIconProps}
                  />
                </>
              </Flex>
            }
          />
        )}
      </InputGroup>
    );
  }, [
    isDisabled,
    inputHeight,
    placeholder,
    value,
    formatValue,
    color,
    readonly,
    inputProps,
    inputRightElementProps,
    canDeleteDate,
    datePickerFilterStyles?.crossIconProps,
    datePickerFilterStyles?.dividerProps,
    datePickerFilterStyles?.calendarIconProps,
    icon,
    handleRemoveDate,
  ]);

  return isDisabled || readonly ? (
    customInput
  ) : (
    <DatePicker
      popperClassName="calendar-zIndex"
      dayClassName={(date: Date) => getClassDay(date)}
      dateFormat="yyyy/MM/dd"
      showPopperArrow={showPopperArrow}
      shouldCloseOnSelect={shouldCloseOnSelect}
      selected={value ? new Date(value) : new Date()}
      locale="ja"
      {...(minDate
        ? {
            minDate: new Date(minDate),
          }
        : {})}
      {...(maxDate
        ? {
            maxDate: new Date(maxDate),
          }
        : {})}
      customInput={customInput}
      onChange={(day: Date) => {
        onChangeDay(day);
      }}
      onCalendarOpen={onCalendarOpen}
      onCalendarClose={() => {
        onClose?.(selectedDayRef.current);
      }}
      calendarClassName="date-picker-calendar"
      popperPlacement={popperPlacement}
      popperModifiers={popperModifiers}
      popperProps={popperProps}
      startDate={startDate}
      endDate={endDate}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
    />
  );
};

export default memo(DayPicker);

import { mergeAttributes, Node } from "@tiptap/core";
import {
  CheckedSVG,
  UnCheckedSVG,
} from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/Checkbox";
import { renderToStaticMarkup } from "react-dom/server";
import { NodeType } from "../type";
import { onChangeCheckbox } from "../utils";

export const TaskItem = Node.create({
  name: NodeType.TASK_ITEM,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  content: "inline*",
  defining: true,

  addAttributes() {
    return {
      checked: {
        default: false,
        keepOnSplit: false,
        parseHTML: (element) => {
          const dataChecked = element.getAttribute("data-checked");

          return dataChecked === "" || dataChecked === "true";
        },
        renderHTML: (attributes) => ({
          "data-checked": attributes.checked,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `li[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "li",
      mergeAttributes(HTMLAttributes, {
        "data-type": this.name,
      }),
      [
        "label",
        [
          "input",
          {
            type: "checkbox",
            checked: node.attrs.checked ? "checked" : null,
          },
        ],
        ["span"],
      ],
      ["div", 0],
    ];
  },

  addNodeView() {
    return ({ HTMLAttributes, getPos, editor }) => {
      const listItem = document.createElement("li");
      listItem.style.width = "100%";
      const checkboxWrapper = document.createElement("label");
      const content = document.createElement("span");
      content.contentEditable = "false";
      content.style.marginTop = "2px";
      content.style.marginLeft = "2px";
      content.style.lineHeight = "1";
      const checked = HTMLAttributes["data-checked"];
      const staticCheckboxEle = renderToStaticMarkup(
        checked ? CheckedSVG : UnCheckedSVG
      );
      const checkbox = $(staticCheckboxEle).get(0)!;
      checkboxWrapper.contentEditable = "false";

      checkbox.addEventListener("click", () => {
        if (!editor.isEditable) {
          return;
        }

        if (editor.isEditable && typeof getPos === "function") {
          onChangeCheckbox({ editor, getPos });
        }
      });

      checkboxWrapper.append(checkbox);
      listItem.append(checkboxWrapper, content);

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        listItem.setAttribute(key, value);
      });

      return {
        dom: listItem,
        contentDOM: content,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }
          const checked = updatedNode.attrs.checked;
          listItem.dataset.checked = checked;
          const staticCheckboxEle = renderToStaticMarkup(
            checked ? CheckedSVG : UnCheckedSVG
          );
          const checkboxElement = $(staticCheckboxEle).get(0)!;
          checkbox.innerHTML = checkboxElement.innerHTML;

          return true;
        },
      };
    };
  },
});

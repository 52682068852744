import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconBlackBoard(props: IconProps) {
  return (
    <Icon width="2.4rem" height="2.4rem" viewBox="0 0 24 24" {...props}>
      <g
        fill="#0d370e"
        stroke="#0d370e"
        strokeWidth="2"
        data-name="Rectangle 7940"
        transform="translate(3 4)"
      >
        <rect width="18" height="16" rx="1" stroke="none" />
        <path fill="none" d="M1 1h16v14H1z" />
      </g>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        d="M5.5 9.5h13"
        data-name="Line 189"
      />
      <g fill="none" stroke="#fff" data-name="Rectangle 7941">
        <path d="M5 6h14v12H5z" stroke="none" />
        <path d="M5.5 6.5h13v11h-13z" />
      </g>
    </Icon>
  );
}

import { Box, TextProps } from "@chakra-ui/react";
import { LINE_HEIGHT_DEFAULT } from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { LinkedDataField, TextPosition } from "constants/enum";
import { MCE_EDITABLE_CLASSNAME } from "constants/styleProps";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { DEFAULT_TEXT_COLOR } from "pages/document/template-page/hooks";
import { STEP_TAB } from "pages/document/template-page/RightPanel/TextStyle";
import { memo, useEffect, useMemo, useRef } from "react";
import { transformSizeForTextElement } from "utils/download-pdf";

interface iProps extends TextProps {
  data: CellType;
  component?: TemplateComponent;
  isAutoResize?: boolean;
  isEditable?: boolean;
  zoomRatio?: number;
}

const NormalTextPreview = ({
  data,
  style,
  isAutoResize = true,
  isEditable = true,
  zoomRatio = 1,
  ...rest
}: iProps) => {
  const textElementRef = useRef<HTMLParagraphElement>(null);
  const sizeDefault = useMemo(() => {
    const fontSizeDefault = (data?.style?.fontSize || 14) * zoomRatio;

    return {
      fontSize: fontSizeDefault,
      lineHeight: fontSizeDefault * LINE_HEIGHT_DEFAULT,
    };
  }, [data?.style?.fontSize, zoomRatio]);
  const lastTextRef = useRef<string>();
  const isStyleJustifyContent = useMemo(
    () => data?.style?.justifyContent === TextPosition.JUSTIFY,
    [data?.style?.justifyContent]
  );

  const isText = useMemo(
    () =>
      !isStyleJustifyContent &&
      typeof data?.value === "string" &&
      !data?.value?.includes("\n"),
    [data?.value, isStyleJustifyContent]
  );
  const content = useMemo(() => {
    return String(data?.value || "")?.replace(/ /g, "\u00A0");
  }, [data?.value]);

  const renderTab = useMemo(() => {
    const count = (data?.style?.tab || 0) / STEP_TAB;

    return new Array(count)
      .fill(0)
      .map(() => "\t")
      .join("");
  }, [data?.style?.tab]);

  useEffect(() => {
    const text = `${renderTab}${content?.replaceAll("\n", "<br/>")}`;
    const textEle = textElementRef.current;

    if (
      !isStyleJustifyContent &&
      textEle &&
      typeof content === "string" &&
      content.includes("\n")
    ) {
      textEle.innerHTML = text;
    }
  }, [content, renderTab, isStyleJustifyContent]);

  useEffect(() => {
    const element = textElementRef.current;
    if (!element || !content || !isAutoResize) {
      return;
    }

    const { text, shouldUpdate } = transformSizeForTextElement({
      isShowMessage: false,
      element,
      lastTextRef,
      sizeDefault,
    });

    if (shouldUpdate) {
      element.style.color = "red";
      element.innerHTML = text;
      lastTextRef.current = text;
    } else {
      element.style.color = data?.style?.color || DEFAULT_TEXT_COLOR;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, sizeDefault?.fontSize, sizeDefault?.lineHeight, isAutoResize]);

  const renderTextTypeAlignJustify = useMemo(() => {
    if (isText || !isStyleJustifyContent) {
      return <></>;
    }

    //render multiple line text spacing
    const characterStrings = content?.replace(/ /g, "\u00A0").split("\n") || [];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 0,
          width: "100%",
          height: "fit-content",
        }}
      >
        {characterStrings.map((characterString, index) => {
          const characters = characterString?.split("") || [];

          return (
            <div
              key={`${characterString}-${index}`}
              className={MCE_EDITABLE_CLASSNAME}
              style={{
                marginBottom: 0,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "100%",
                height: "100%",
              }}
            >
              {characters.map((c, i) => (
                <p key={`${c}-${i}`}>{`${
                  i === 0 && index === 0 ? renderTab : ""
                }${c}`}</p>
              ))}
            </div>
          );
        })}
      </div>
    );
  }, [isStyleJustifyContent, isText, content, renderTab]);

  return (
    <Box
      key={data?.cellId}
      className={isEditable ? MCE_EDITABLE_CLASSNAME : ""}
      ref={textElementRef}
      data-field-name={data.cellLinkedData?.field}
      data-field-type={LinkedDataField.COMMON.INPUT_DATA}
      data-text-color={data?.style?.color || "#000000"}
      style={{
        textAlign: data?.style?.justifyContent || (TextPosition.CENTER as any),
        minHeight: "1em",
        width: "100%",
        ...(style || {}),
        ...(isAutoResize
          ? {
              lineHeight: `${sizeDefault.lineHeight}px`,
              fontSize: `${sizeDefault.fontSize}px`,
            }
          : {}),
        ...style,
      }}
      {...rest}
    >
      {isText && `${renderTab}${content}`}

      {renderTextTypeAlignJustify}
    </Box>
  );
};

export default memo(NormalTextPreview);

import { Center, CenterProps } from "@chakra-ui/react";
import IconPinOnMap from "components/icon/IconPinOnMap";
import {
  InspectionItemType,
  MapInspectionItemIconStatus,
  SIZE_ICON_TASK_STATUS,
  SystemModeType,
} from "constants/enum";
import { getStatusColor } from "utils/forge/extensions/custom-label/utils";

interface Props extends CenterProps {
  status?: InspectionItemType | string;
}

export default function IconPin({ status, ...rest }: Props) {
  const SubIcon = MapInspectionItemIconStatus[
    (status || InspectionItemType.Defect) as InspectionItemType
  ] as any;

  return (
    <Center position="relative" {...rest}>
      <IconPinOnMap
        width="24px"
        height="24px"
        color={getStatusColor(status, SystemModeType.Task)}
      />
      <Center
        position="absolute"
        zIndex={1}
        inset={0}
        textAlign="center"
        width="100%"
        height="100%"
      >
        <SubIcon
          marginTop="-4px"
          //@ts-ignore
          //@ts-ignore
          w={status === InspectionItemType.Confirmed ? "14px" : undefined}
          h={status === InspectionItemType.Confirmed ? "14px" : undefined}
          {...SIZE_ICON_TASK_STATUS[
            (status || InspectionItemType.Defect) as InspectionItemType
          ]}
          sx={{
            'path[data-name="↳Color"]': {
              fill: "white",
            },
            'path[data-name="stroke"]': {
              fill: "white",
            },
          }}
        />
      </Center>
    </Center>
  );
}

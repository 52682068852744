import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconSuccess({ color, ...rest }: IconProps) {
  return (
    <Icon width="20" height="20" fill="none" viewBox="0 0 20 20" {...rest}>
      <path
        fill={color as any}
        d="M9.997 18.335a8.333 8.333 0 1 1 0-16.667 8.333 8.333 0 1 1 0 16.667m-.83-5 5.891-5.893-1.178-1.178-4.713 4.714L6.809 8.62 5.631 9.8z"
      />
    </Icon>
  );
}

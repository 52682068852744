import {
  Button,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  UseModalProps,
} from "@chakra-ui/react";
import { message } from "components/base";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { SvgIcon } from "components/SvgIcon";
import ConfirmModal from "../ConfirmDeleteModal";

const ImagePresigned = withPresignedUrl(Image);

interface Props extends UseModalProps {
  isOnline?: boolean;
  image?: string;
  isLoadedImage: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

function ForgeImageTaskModal(props: Props) {
  const {
    isOnline = true,
    isOpen,
    isLoadedImage,
    onClose,
    image,
    onEdit,
    onDelete,
    ...rest
  } = props;
  const {
    isOpen: isOpenConfirmModal,
    onOpen: openConfirmModal,
    onClose: closeConfirmModal,
  } = useDisclosure();

  const onBeforeEdit = () => {
    if (!isLoadedImage && !isOnline) {
      message.warning("画像がキャッシュされていないため、編集は出来ません。");

      return;
    }

    onEdit();
  };

  return (
    <Modal
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        position="absolute"
        borderRadius="8px"
        p="2rem"
        top="var(--header-height)"
      >
        <ModalCloseButton fontSize="12px" _focusVisible={{}} />

        <ImagePresigned
          src={image}
          width="100%"
          height="430px"
          objectFit="contain"
          alt=""
          boxLoadingWidth="100%"
          boxLoadingHeight={430}
        />

        <Flex alignItems="center" justifyContent="space-between" mt="1rem">
          <Flex alignItems="center" gap="1rem">
            <Button
              height="4rem"
              variant="outline"
              fontSize="1.4rem"
              leftIcon={
                <SvgIcon
                  w="16px"
                  h="16px"
                  src={"/img/icon-edit.svg"}
                  sx={{
                    path: {
                      stroke: "#1795FE",
                    },
                  }}
                />
              }
              onClick={onBeforeEdit}
            >
              編集
            </Button>
            <Button
              height="4rem"
              variant="danger"
              leftIcon={
                <SvgIcon
                  src={"/img/icon-delete.svg"}
                  w="16px"
                  h="16px"
                  sx={{
                    path: {
                      stroke: "white",
                    },
                  }}
                />
              }
              isDisabled={!isOnline}
              onClick={openConfirmModal}
            >
              削除
            </Button>
          </Flex>

          <Button height="4rem" variant="filled" onClick={onClose}>
            完了
          </Button>
        </Flex>
      </ModalContent>

      <ConfirmModal
        title="削除の確認"
        content="削除してよろしいですか。"
        isDelete
        buttonConfirm="削除する"
        isOpen={isOpenConfirmModal}
        onClose={closeConfirmModal}
        onProcessing={onDelete}
      />
    </Modal>
  );
}

export default ForgeImageTaskModal;

import { Box, Link, TableCellProps, Td, Textarea } from "@chakra-ui/react";
import useGetContentTypeBlackboard from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypeBlackboard";
import useGetContentTypeDocumentTaskData from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypeDocumentTaskData";
import useGetContentTypeEditor from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypeEditor";
import useGetContentTypeFlexibleDuct from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypeFlexibleDuct";
import useGetContentTypeModuleChiller from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypeModuleChiller";
import useGetContentTypePac from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypePac";
import useGetContentTypePreview from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypePreview";
import useGetContentTypeSleevePipe from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypeSleevePipe";
import useGetContentTypeTaskItemData from "components/editor-builder/component-preview/TableComponentPreview/hooks/useGetContentTypeTaskItemData";
import { iLimit } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import {
  CellProperty,
  ContentType,
  FontFamiLyType,
  TableDefaultStyle,
  TableElementType,
  TemplateComponentType,
  TextPosition,
} from "constants/enum";
import { Blackboard } from "interfaces/models/blackboard";
import {
  CellSize,
  CellType,
  DisplayComponent,
  TemplateComponent,
} from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { DEFAULT_FONT_SIZE } from "pages/document/template-page/hooks";
import { memo, useMemo } from "react";
import { DocumentState } from "redux/documentSlice";
import { getIndexCell } from "utils/document";
import { getPreviewDocumentCommonProps, iPreviewDocumentCommonProps } from "..";
import NormalTextPreview from "../../NormalTextPreview";
import useSubCellComponentPreview from "./hooks/useSubCellComponentPreview";

interface iSubCellComponentPreview
  extends TableCellProps,
    iPreviewDocumentCommonProps {
  isParentLastColumn?: boolean;
  isDuplicateRow?: boolean;
  canEditCell: boolean;
  isSelected?: boolean;
  isTableSelected?: boolean;
  isOnlyView?: boolean;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  isLastRow?: boolean;
  isBlackboardTemplateImage?: boolean;
  isBlackboardTemplate?: boolean;
  isTaskSheetTemplateItem?: boolean;
  isComponentDragging?: boolean;

  width: number;
  height: number;
  rowIndex?: number;
  zoomRatio: number;
  sizePageRatio: number;
  pageDirectionRatio: number;

  tableId: string;
  trId: string;

  cell: CellType;
  cellSize: CellSize;
  displaySize: DisplayComponent["size"];
  limit: iLimit;
  currentComponent: TemplateComponent;
  parentCell: CellType;
  components: TemplateComponent[];
  componentSelected: TemplateComponent;
  documentContainerSize: DocumentState["documentContainerSize"];
  selectedCells: CellType[];
  currentTemplate: DocumentTemplate;
  tdProps?: Partial<TableCellProps>;

  onSelectCell: (e: React.MouseEvent<HTMLElement>, cell: CellType) => void;
  onChangeBlackboardData?: (data: Blackboard) => void;
}

export interface iUseSubCellComponentPreview {
  isDuplicateRow: boolean;
  isComponentDragging: boolean;
  canEditCell: boolean;
  isTableSelected: boolean;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  isLastRow?: boolean;
  isParentLastColumn?: boolean;

  zoomRatio: number;
  sizePageRatio: number;
  pageDirectionRatio: number;
  contentType: ContentType;

  documentContainerSize: DocumentState["documentContainerSize"];
  parentCell: CellType;
  cell: CellType;
  cellSize: CellSize;
  selectedCells: CellType[];
  currentComponent: TemplateComponent;
  components: TemplateComponent[];
  limit: iLimit;
  isSelected?: boolean;
  borderColor: string;
  currentTemplate: DocumentTemplate;

  onSelectCell: (e: React.MouseEvent<HTMLElement>, cell: CellType) => void;
}

const SubCellComponentPreview = (props: iSubCellComponentPreview) => {
  const {
    //tableId,
    cell,
    parentCell,
    width,
    height,
    canEditCell,
    borderColor,
    isSelected,
    cellSize,
    isTableSelected,
    isOnlyView = false,
    isFirstColumn,
    isLastColumn,
    isLastRow,
    currentComponent,
    isDuplicateRow,
    zoomRatio,
    displaySize,
    sizePageRatio,
    pageDirectionRatio,
    isParentLastColumn,
    onSelectCell,
    limit,
    isBlackboardTemplateImage = false,
    isTaskSheetTemplateItem = false,
    isBlackboardTemplate = false,
    components,
    selectedCells,
    isComponentDragging,
    currentTemplate,
    documentContainerSize,
    componentSelected,
    tdProps,
    blackboardData,
    blackboardTemplateProps,
    onChangeBlackboardData,
    ...rest
  } = props;

  const { contentType } = getPreviewDocumentCommonProps(rest);

  const {
    inputRef,
    shouldShowInput,
    name,
    borderStyle,
    borderWidth,
    _borderColor,
    handleChangeName,
    targetLink,
    resizeMouseDown,
    handleOnClick,
    handleShowEditInput,
  } = useSubCellComponentPreview({
    isLastColumn,
    isParentLastColumn,
    isFirstColumn,
    isLastRow,
    contentType,
    cell,
    isComponentDragging: !!isComponentDragging,
    selectedCells,
    canEditCell,
    onSelectCell,
    currentComponent,
    components,
    isDuplicateRow: !!isDuplicateRow,
    parentCell,
    zoomRatio,
    sizePageRatio,
    pageDirectionRatio,
    limit,
    isTableSelected: !!isTableSelected,
    cellSize,
    documentContainerSize,
    isSelected,
    borderColor: borderColor as string,
    currentTemplate,
  });

  const css = cell?.style;
  const fontSize = useMemo(
    () => `${Math.round((css?.fontSize || 14) * zoomRatio)}px`,
    [css?.fontSize, zoomRatio]
  );

  const useGetContentOfCellProps = {
    isOnlyView,
    isBlackboardTemplateImage,
    isDuplicateRow: !!isDuplicateRow,
    isLastColumn: !!isLastColumn,
    isParentLastColumn,
    isFirstColumn,
    isLastRow,
    limit,
    displaySize,
    width,
    height,
    pageDirectionRatio,
    sizePageRatio,
    cell,
    currentTemplate,
    component: currentComponent,
    css: cell?.style || {},
    zoomRatio,
    cellSize,
    components,
    selectedCells,
    componentSelected,
    documentContainerSize,
    isComponentDragging: !!isComponentDragging,
    fontSize,

    onChangeBlackboardData,
    ...getPreviewDocumentCommonProps(rest),
  };

  const { contentOfCellTypeEditor } = useGetContentTypeEditor(
    useGetContentOfCellProps
  );
  const { contentOfCellTypePreivew } = useGetContentTypePreview(
    useGetContentOfCellProps
  );
  const { contentOfCellTypePac } = useGetContentTypePac(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeBlackboard } = useGetContentTypeBlackboard(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeSleevePipe } = useGetContentTypeSleevePipe(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeFlexibleDuct } = useGetContentTypeFlexibleDuct(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeModuleChiller } = useGetContentTypeModuleChiller(
    useGetContentOfCellProps
  );

  const { contentOfCellTypeDocumentTaskData } =
    useGetContentTypeDocumentTaskData(useGetContentOfCellProps);

  const { contentOfCellTypeTaskItemData } = useGetContentTypeTaskItemData(
    useGetContentOfCellProps
  );

  const textDecoration = useMemo(() => {
    return css?.underline && css?.lineThrough
      ? "underline line-through"
      : css?.underline
      ? "underline"
      : css?.lineThrough
      ? "line-through"
      : "";
  }, [css?.lineThrough, css?.underline]);

  const backgroundColor = useMemo(() => {
    if (
      css?.backgroundColor ===
        currentComponent?.detail?.style?.backgroundColor ||
      (!css?.backgroundColor &&
        currentComponent?.detail?.style?.backgroundColor)
    ) {
      return "initial";
    }

    return css?.backgroundColor || "#fff";
  }, [css?.backgroundColor, currentComponent.detail?.style?.backgroundColor]);

  const tdStyle = useMemo((): TableCellProps["style"] => {
    return {
      position: "relative",
      padding: "0px",
      width: `${
        (width / (parentCell.width || 1)) * displaySize.width -
        TableDefaultStyle.DEFAULT_BORDER_SIZE
      }px`,
      height: `${
        (height / (parentCell.height || 1)) * displaySize.height -
        TableDefaultStyle.DEFAULT_BORDER_SIZE
      }px`,
      borderColor: _borderColor,
      borderWidth,
      borderStyle,
      backgroundColor,
      justifyContent: css?.justifyContent || "start",
      fontWeight: css?.bold ? "bold" : "normal",
      fontStyle: css?.italic ? "italic" : "",
      fontFamily:
        css?.fontFamily === FontFamiLyType.NOTO_SANS
          ? `NotoSansCJKjp`
          : css?.fontFamily === FontFamiLyType.NOTO_SERIF
          ? `Noto Serif JP, serif`
          : "",
      textDecoration,
      fontSize,
      color: css?.color || "",
      whiteSpace: "pre-wrap",
      textOverflow: "ellipsis",
      overflowWrap: "anywhere",
      lineHeight: "1.2",
      backgroundImage: css?.crossLine
        ? `linear-gradient(to top right, transparent calc(50% - 1px), ${css?.borderColor}, transparent calc(50% + 1px)`
        : "",
    };
  }, [
    backgroundColor,
    width,
    parentCell,
    displaySize,
    height,
    _borderColor,
    borderWidth,
    borderStyle,
    css,
    textDecoration,
    fontSize,
  ]);

  const newTdProps = useMemo(() => {
    const { style: styleTdProps, ...restTdProps } = tdProps || {};

    const defaultProps: Partial<TableCellProps> = {
      id: cell.cellId,
      rowSpan: cell?.rowSpan,
      colSpan: cell?.colSpan,
      style: { ...tdStyle, ...styleTdProps },
      "data-field-name": cell?.cellLinkedData?.field,
      "data-font-size": cell?.style?.fontSize || DEFAULT_FONT_SIZE,
      "data-field-content": cell?.value,
      "data-options": cell?.cellLinkedData?.options?.valueOfCheckbox || "",
      ...restTdProps,
    } as any;

    if (isOnlyView) {
      return defaultProps;
    }

    return {
      ...defaultProps,
      onClick: handleOnClick,
      onDoubleClick: handleShowEditInput,
      sx: {
        "&::after": selectedCells.some(
          (selectedCell) => selectedCell.cellId === cell.cellId
        )
          ? {
              content: '""',
              position: "absolute",
              top: "-1px",
              left: "-1px",
              zIndex: 1,
              width: "100%",
              height: "100%",
              border: `1px solid ${borderColor}`,
            }
          : {},
      },
    };
  }, [
    borderColor,
    isOnlyView,
    cell,
    tdStyle,
    tdProps,
    selectedCells,
    handleOnClick,
    handleShowEditInput,
  ]);

  const renderInputToChangeTitle = useMemo(() => {
    if (isOnlyView || !shouldShowInput) {
      return null;
    }

    return (
      <Textarea
        position="relative"
        zIndex="2"
        rows={1}
        ref={inputRef}
        resize="none"
        overflow="hidden"
        fontSize={fontSize}
        border="none"
        overflowY="auto"
        outline="none"
        borderRadius="0px"
        _focus={{
          borderColor: "none",
        }}
        value={name}
        onChange={handleChangeName}
        className="box-scroll-bar text-area-scroll-bar"
        style={{
          width: "100%",
          height: "100%",
          maxHeight: height - 1,
          padding: "0px 5px",
        }}
      />
    );
  }, [
    height,
    fontSize,
    isOnlyView,
    shouldShowInput,
    handleChangeName,
    name,
    inputRef,
  ]);

  const renderHideBorderRight = useMemo(() => {
    if (!css?.border || css?.borderRight || isSelected) {
      return <></>;
    }

    if (selectedCells?.length) {
      const currentIndex = getIndexCell(cell);
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row === selectedIndex.row &&
        currentIndex.col + 1 === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          right: "-1px",
          top: "0px",
          width: "1px",
          height: "100%",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    css?.border,
    css?.borderRight,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderHideBorderTop = useMemo(() => {
    if (!css?.border || css?.borderTop || isSelected) {
      return <></>;
    }

    if (selectedCells?.length) {
      const currentIndex = getIndexCell(cell);
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row - 1 === selectedIndex.row &&
        currentIndex.col === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          right: "0px",
          top: "-1px",
          width: "100%",
          height: "1px",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    css?.border,
    css?.borderTop,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderHideBorderBottom = useMemo(() => {
    if (!css?.border || css?.borderBottom || isSelected) {
      return <></>;
    }

    if (selectedCells?.length) {
      const currentIndex = getIndexCell(cell);
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row + 1 === selectedIndex.row &&
        currentIndex.col === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          left: "0px",
          bottom: "-2px",
          width: "100%",
          height: "3px",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    css?.border,
    css?.borderBottom,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderHideBorderLeft = useMemo(() => {
    if (!css?.border || css?.borderLeft || isSelected) {
      return <></>;
    }

    if (selectedCells?.length) {
      const currentIndex = getIndexCell(cell);
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row === selectedIndex.row &&
        currentIndex.col - 1 === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          left: "-1px",
          top: "0px",
          width: "1px",
          height: "100%",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    css?.border,
    css?.borderLeft,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderAttach = useMemo(() => {
    if (!cell?.style?.attach) {
      return null;
    }

    return (
      <Link
        style={{
          position: "relative",
          zIndex: "1",
          cursor: "pointer",
          height: "100%",
          width: "100%",
          maxHeight: height - 1,
          marginLeft: "0.5rem",
          outline: "none",
          textDecoration,
          border: "none",
          color: "#4589eb",
        }}
        isExternal
        onClick={targetLink}
        href={cell.style.attach}
        sx={{
          "&:focus": {
            boxShadow: "none",
          },
        }}
      >
        {cell?.value}
      </Link>
    );
  }, [cell, targetLink, height, textDecoration]);

  const renderEdgeToResize = useMemo(() => {
    if (shouldShowInput || isDuplicateRow || isOnlyView) {
      return null;
    }

    return (
      <Box position="absolute" width="100%" height="100%" top="0" left="0">
        {!isLastColumn && (
          <Box
            className="resize-column"
            onMouseDown={(e) => resizeMouseDown(e, cell, TableElementType.COL)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}

        {!isLastRow && (
          <Box
            className="resize-row"
            onMouseDown={(e) => resizeMouseDown(e, cell, TableElementType.ROW)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </Box>
    );
  }, [
    isLastRow,
    isLastColumn,
    isOnlyView,
    shouldShowInput,
    isDuplicateRow,
    cell,
    resizeMouseDown,
  ]);

  const templateProps = useMemo(() => {
    const { sleevePipeProps, pacProps, flexibleDuctProps, moduleChillerProps } =
      props;

    switch (contentType) {
      case ContentType.PHOTO_LEDGER:
        return sleevePipeProps;
      case ContentType.PAC:
        return pacProps;
      case ContentType.SELF_INSPECTION:
        return flexibleDuctProps;
      case ContentType.EQUIPMENT_DATA_SHEET:
        return moduleChillerProps;
      default:
        return;
    }
  }, [contentType, props]);

  const contentDefaultOfCell = useMemo(() => {
    const rowIndex = templateProps?.option?.rowIndex || 0;
    const numberOfDocuments =
      (templateProps as any)?.displayItems?.length ||
      (templateProps as any)?.documentItems?.length ||
      0;
    const offsetItemLinked = templateProps?.offsetItemLinked || 0;

    if (templateProps && rowIndex + offsetItemLinked >= numberOfDocuments) {
      return (
        <NormalTextPreview
          isAutoResize={
            !(
              isBlackboardTemplateImage ||
              isBlackboardTemplate ||
              isTaskSheetTemplateItem
            )
          }
          data={{ ...cell, value: "" }}
        />
      ); // render empty text to edit in EditMode
    }

    const isEdiTemplate = window.location.pathname.includes(
      "document-template/edit"
    );

    const isRenderTextNormal = [
      cell.cellProperty,
      cell?.cellLinkedData?.field,
    ].includes(CellProperty.NO);

    const dynamicFieldValue = cell?.cellLinkedData?.options?.dynamicFieldValue;
    const isTableHeader =
      currentComponent?.type === TemplateComponentType.TableHeader;

    if (
      (cell.value &&
        isRenderTextNormal &&
        contentType === ContentType.EDITOR) ||
      (!isRenderTextNormal && cell.value)
    ) {
      let newValue = cell.value;

      if (dynamicFieldValue && !isTableHeader && !isEdiTemplate) {
        const headerComponent = templateProps?.headerComponent || [];
        const rowIndex =
          (templateProps?.option?.rowIndex || 0) + offsetItemLinked;
        const cellIndex = templateProps?.option?.cellIndex || 0;
        const subCellIndex = templateProps?.option?.subCellIndex || 0;
        const lastRowIndex =
          headerComponent?.[cellIndex]?.subTable?.rows?.length || 0;

        const item =
          (templateProps as any)?.displayItems ||
          (templateProps as any)?.documentItems ||
          [];
        const documentItem: DocumentItem = item?.[rowIndex] || [];
        const dataDynamic = documentItem?.data || {};
        const headerCellId =
          headerComponent?.[cellIndex]?.subTable?.rows?.[lastRowIndex - 1]
            ?.cells?.[subCellIndex]?.cellId;
        const key = `${headerCellId}-${documentItem?.id}`;

        newValue = dataDynamic?.[key] ?? (dynamicFieldValue || "");
      }

      if (css?.justifyContent !== TextPosition.JUSTIFY) {
        return (
          <NormalTextPreview
            id={cell.cellId}
            data={{
              ...cell,
              value: newValue,
            }}
            isAutoResize={
              !(
                isBlackboardTemplateImage ||
                isBlackboardTemplate ||
                isTaskSheetTemplateItem
              )
            }
            style={{
              height: "fit-content",
              textAlign: css?.justifyContent || (TextPosition.CENTER as any),
              minHeight: "1em",
              fontSize,
              color: css?.color
                ? css?.color
                : isEdiTemplate
                ? "#A3A3A3"
                : "inherit",
            }}
          />
        );
      }

      //render multiple line text spacing
      const characterStrings =
        cell.value.replace(/ /g, "\u00A0").split("\n") || [];

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "fit-content",
          }}
        >
          {characterStrings.map((characterString, index) => {
            const characters = characterString?.split("") || [];

            return (
              <div
                key={`${characterString}-${index}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "100%",
                  height: "100%",
                }}
              >
                {characters.map((c, i) => (
                  <p key={`${c}-${i}`}>{c}</p>
                ))}
              </div>
            );
          })}
        </div>
      );
    }

    switch (contentType) {
      case ContentType.EDITOR:
        return contentOfCellTypeEditor(cell);

      case ContentType.PREVIEW:
        return contentOfCellTypePreivew(cell);

      case ContentType.PAC:
        return contentOfCellTypePac(cell);

      case ContentType.BLACKBOARD_TEMPLATE:
        return contentOfCellTypeBlackboard(cell);

      case ContentType.PHOTO_LEDGER:
        return contentOfCellTypeSleevePipe(cell);

      case ContentType.SELF_INSPECTION:
        return contentOfCellTypeFlexibleDuct(cell);

      case ContentType.EQUIPMENT_DATA_SHEET:
        return contentOfCellTypeModuleChiller(cell);

      case ContentType.DOCUMENT_TASK_DATA:
        return contentOfCellTypeDocumentTaskData(cell);

      case ContentType.TASK_ITEM_DATA:
        return contentOfCellTypeTaskItemData(cell);

      default:
        break;
    }
  }, [
    isBlackboardTemplateImage,
    isBlackboardTemplate,
    isTaskSheetTemplateItem,
    contentType,
    cell,
    currentComponent,
    fontSize,
    css,
    contentOfCellTypeDocumentTaskData,
    contentOfCellTypeTaskItemData,
    contentOfCellTypeSleevePipe,
    contentOfCellTypeFlexibleDuct,
    contentOfCellTypePac,
    contentOfCellTypeModuleChiller,
    contentOfCellTypePreivew,
    contentOfCellTypeEditor,
    contentOfCellTypeBlackboard,
    templateProps,
  ]);

  const renderContentCell = useMemo(() => {
    if (shouldShowInput || cell?.style?.attach) {
      return null;
    }

    return (
      <Box
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: css?.justifyContent || TextPosition.CENTER,
          alignItems: TextPosition.CENTER,
          width: "100%",
          height: "100%",
          textAlign:
            css?.justifyContent === TextPosition.START
              ? TextPosition.LEFT
              : css?.justifyContent === TextPosition.END
              ? TextPosition.RIGHT
              : TextPosition.CENTER,
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "inherit",
          textDecoration: textDecoration,
          padding: css?.justifyContent !== TextPosition.JUSTIFY ? 0 : "0 5px",
        }}
      >
        {contentDefaultOfCell}
      </Box>
    );
  }, [contentDefaultOfCell, css, cell, shouldShowInput, textDecoration]);

  return (
    <Td {...newTdProps}>
      {renderInputToChangeTitle}
      {renderAttach}

      {renderHideBorderRight}
      {renderHideBorderTop}
      {renderHideBorderBottom}
      {renderHideBorderLeft}

      {renderEdgeToResize}
      {renderContentCell}
    </Td>
  );
};

export default memo(SubCellComponentPreview);

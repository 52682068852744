import { Box, Flex, FlexProps, Spinner, Text, Center } from "@chakra-ui/react";
import IconNavigationExpandMore from "components/icon/IconNavigationExpandMore";
import { SvgIcon } from "components/SvgIcon";
import IconPin from "components/task/IconPin";
import { DAY_OF_WEEK_JP } from "constants/date";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIconStatus,
  MapInspectionItemType,
  SIZE_ICON_TASK_STATUS,
  SystemModeType,
} from "constants/enum";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { memo } from "react";
import { transformDataToJp } from "utils/date";
import { getStatusColor } from "utils/forge/extensions/custom-label/utils";

interface Props extends FlexProps {
  task: TaskDTO;
  taskName: string;
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Tasks = ({
  task,
  taskName,
  onClick,
  isDisabled,
  isLoading,
  ...rest
}: Props) => {
  const date = transformDataToJp(task.creationDateTime || task.createdAt);

  return (
    <Flex
      w="100%"
      p="1rem"
      my=".5rem"
      borderBottom="1px solid #e2e2e3"
      borderRadius={4}
      alignItems="center"
      cursor="pointer"
      _hover={{ backgroundColor: "#f7f7f7" }}
      onClick={onClick}
      opacity={isDisabled ? 0.4 : 1}
      {...rest}
    >
      <Box mr="0.8rem">
        <IconPin status={task?.status as any} />
      </Box>
      <Flex
        bg={
          MapInspectionItemColor[
            (task.status || InspectionItemType.Defect) as InspectionItemType
          ]
        }
        color="#fff"
        alignItems="center"
        justifyContent="center"
        borderRadius="4px"
        mr="1rem"
        lineHeight="2.2rem"
        paddingInline="0.8rem"
        height="24px"
        minWidth="6rem"
      >
        {
          MapInspectionItemType[
            (task.status || InspectionItemType.Defect) as InspectionItemType
          ]
        }
      </Flex>
      <Flex overflow="hidden" flexDirection="column">
        <Box
          flex={1}
          py=".3rem"
          color="#737373"
          fontSize="1.6rem"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {isLoading ? <Spinner /> : taskName}
        </Box>
        <Text color="#737373" fontSize="1.4rem" py=".3rem">
          {date
            ? `${date.format("YYYY/MM/DD")}(${
                //@ts-ignore
                DAY_OF_WEEK_JP[date.day()]
              })`
            : ""}
        </Text>
      </Flex>
      <IconNavigationExpandMore
        ml="auto"
        transform={"rotate(-90deg)"}
        sx={{
          path: {
            fill: "#737373",
          },
        }}
      />
    </Flex>
  );
};

export default memo(Tasks);

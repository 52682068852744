import {
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { message } from "components/base";
import { DOCUMENT_TYPE_INFO } from "constants/document";
import { FamilyTitleDisplayType } from "constants/enum";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import {
  FamilyTitleDisplayForCustomDocumentItem,
  TitleDisplay,
} from "interfaces/models/component";
import { DataLog } from "interfaces/models/dataLog";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { GetContentLog, insertDataLogToIndexedDb } from "models/dataLog";
import { getColorTextByStatus } from "models/document";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { sortArrayByField } from "utils/array";
import { uuid } from "utils/common";
import { getDocumentItemTitleByFamilyTitle } from "utils/documentItem";
import { arrayToObject } from "utils/object";
import FieldNoComponent from "./FieldNoComponent";
import { useItemLogs } from "./hooks";
import useRevertDataByLogs from "./hooks/useRevertDataByLogs";
import useListenFromExternalLog from "./hooks/useListenFromExternalLog";
import ItemLog from "./ItemLog";
import ItemStatusMenu from "./ItemStatusMenu";
import ItemTasks from "./ItemTasks";
import ItemTitle, { ItemTitleType } from "./ItemTitle";
import SubItem from "./SubItem";
import IconPlus from "components/icon/IconPlus";

interface Props {
  documentItemSelected?: DocumentItemDTO;
  isLoadedViewer: boolean;
  documentCategories: DocumentCategoryDTO[];
  documentCategorySelected?: DocumentCategoryDTO;
  familyInstance: FamilyInstanceDTO;
  documentTemplate?: DocumentTemplate;
  itemStatusColor: string;
  isOnline: boolean;
  isDisabled?: boolean;
  itemDisplayOrder: number | string;
  dataLogTemp: DataLog | undefined;
  fromExternalLogs: DataLog[] | undefined;

  clearFromExternalLogs: () => void;
  onSelectSubItem: (subItem: DocumentSubItemDTO) => void;
}

const ModalTypePin = (props: Props) => {
  const {
    isLoadedViewer,
    documentItemSelected,
    documentCategorySelected,
    familyInstance,
    documentTemplate,
    itemStatusColor,
    isOnline,
    itemDisplayOrder,
    documentCategories,
    dataLogTemp,
    isDisabled,
    fromExternalLogs,

    clearFromExternalLogs,
    onSelectSubItem,
  } = props;

  const [isLoadingAddSubItem, setIsLoadingAddSubItem] = useBoolean();
  const dispatch = useDispatch();

  const { socket } = useForgeViewerContext();
  const { currentUser, listUserById, listAllUserById } = useUserOfProject();
  const { mapDynamicFieldTitle, itemLogs, isLoadMoreLogs, setItemLogs } =
    useItemLogs({
      itemId: documentItemSelected?.id,
      documentTemplate,
      dataLogTemp,
    });

  useListenFromExternalLog({
    fromExternalLogs,
    setItemLogs,
    clearFromExternalLogs,
  });

  const insertItemLog = useCallback(
    async (params: GetContentLog) => {
      if (!currentUser?.id || !documentItemSelected?.id) {
        return;
      }

      const log = await insertDataLogToIndexedDb({
        contentLogParams: params,
        createdBy: currentUser.id,
        identifyValue: documentItemSelected.id,
        identifyCol: "itemId",
        type: "item",
      });

      socket.addDocItemLog(documentItemSelected.id, log);
      setItemLogs((prev) =>
        sortArrayByField<DataLog>([log, ...prev], "createdAt", false)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentItemSelected?.id, currentUser?.id]
  );

  const { onRevertDataByLog } = useRevertDataByLogs({
    insertLog: insertItemLog,
    documentItemSelected,
  });

  const handleAddSubItem = async () => {
    if (isLoadingAddSubItem || !documentItemSelected?.id) {
      return;
    }

    setIsLoadingAddSubItem.on();
    // handle set title for sub item
    const isAddedFromForge = documentItemSelected?.isAddedFromForge;
    let familyTitle: TitleDisplay = {} as any;

    if (isAddedFromForge) {
      const familyTitleDisplayForCustomDocumentItem: FamilyTitleDisplayForCustomDocumentItem =
        documentTemplate?.familyTitleDocItem || {};
      familyTitle =
        familyTitleDisplayForCustomDocumentItem[
          FamilyTitleDisplayType.DocumentItem
        ];
    } else {
      const mapObjectTypesById = arrayToObject(
        familyInstance?.objectTypes || [],
        "id"
      );
      const displayTypeSubItem =
        (documentTemplate?.familyTitle || {})?.[
          FamilyTitleDisplayType.DocumentItem
        ] || {};
      const objectTypes = Object.keys(displayTypeSubItem).filter(
        (key) => !!mapObjectTypesById?.[key]
      );
      familyTitle =
        displayTypeSubItem?.[objectTypes?.[0] || ""] ??
        Object.values(displayTypeSubItem)?.[0] ??
        "";
    }

    const title = getDocumentItemTitleByFamilyTitle({
      familyInstance,
      familyTitle,
    });
    const requestId = uuid();
    const body: Partial<DocumentSubItemDTO> = {
      itemId: documentItemSelected.id,
      blackboardTemplateId: documentTemplate?.blackboardTemplateDetail?.id,
      isShowBlackboard: true,
      title,
      data: {},
      level: documentItemSelected?.level || "",
      templateId: documentItemSelected?.templateId || "",
      externalId: documentItemSelected?.externalId || "",
      bimFileId: documentItemSelected?.bimFileId || "",
      projectId: documentItemSelected?.projectId || "",
      isHidden: !documentCategorySelected?.selectedExternalIds?.includes(
        documentItemSelected.id
      ),
      requestId,
    };

    const documentItem = structuredClone(documentItemSelected);
    const res = await documentItemApi.createSubItem(body as DocumentSubItemDTO);
    setIsLoadingAddSubItem.off();
    const subItem = res?.data;
    if (!subItem.id) {
      return message.error("Create new sub item failure");
    }
    if (!documentItem.subItems) {
      documentItem.subItems = [];
    }

    socket.addSubItem(subItem, documentItemSelected);

    insertItemLog({ field: "createSubItem" as any, requestId });
    documentItem.subItems?.push(subItem as DocumentSubItemDTO);

    dispatch(updateDocumentItem(documentItem));
    message.success(["写真の追加", "写真を追加しました。"]);
  };

  return (
    <Flex
      zIndex="1"
      flexDirection="column"
      padding="0px 1.6rem 0"
      minHeight="100%"
    >
      {/* title */}
      <Flex gap="1.2rem" alignItems="center">
        <FieldNoComponent
          color={itemStatusColor}
          no={itemDisplayOrder}
          colorText={getColorTextByStatus(documentItemSelected?.status)}
        />

        <Flex flexDirection="column" flex={1}>
          <Text lineHeight="2.2rem" color="#737373" fontSize="1.4rem">
            {
              //@ts-ignore
              DOCUMENT_TYPE_INFO[documentItemSelected?.documentType || ""]
                ?.typeName || ""
            }
          </Text>
          <ItemTitle
            inputProps={{
              fontWeight: "bold",
              isDisabled,
            }}
            type={ItemTitleType.ITEM}
            item={documentItemSelected}
            insertItemLog={insertItemLog}
          />
        </Flex>
      </Flex>

      <Divider opacity="1" mt="3.2rem" borderColor="#D4D4D4" />

      {/* sub item */}
      <Box pl="5.2rem" mb="3rem">
        <Flex flexDirection="column">
          {(documentItemSelected?.subItems || [])?.map((subItem) => (
            <SubItem
              key={subItem.id}
              isOnline={isOnline}
              subItem={subItem}
              currentUser={currentUser}
              documentItemSelected={documentItemSelected}
              isHasBlackboard={!!documentTemplate?.blackboardTemplateDetail?.id}
              isDisabled={isDisabled}
              itemDisplayOrder={itemDisplayOrder}
              documentCategories={documentCategories}
              documentCategorySelected={documentCategorySelected}
              insertItemLog={insertItemLog}
              onSelectSubItem={onSelectSubItem}
            />
          ))}
        </Flex>

        {/* add sub item */}
        {!isDisabled && (
          <Flex mt="1rem" ml="4rem">
            <Button
              color="font.gray"
              lineHeight="1.6rem"
              fontSize="1.6rem"
              loadingText="写真を追加"
              variant="text"
              onClick={handleAddSubItem}
              isLoading={isLoadingAddSubItem}
              leftIcon={<IconPlus width="2.4rem" height="2.4rem" />}
            >
              写真を追加
            </Button>
          </Flex>
        )}
      </Box>

      {/* insert task */}
      <Box borderTop="1px solid #A3A3A3" pt="2rem">
        <ItemTasks
          isOnline={isOnline}
          documentItem={documentItemSelected!}
          isDisabled={isDisabled}
          insertDocumentItemLog={insertItemLog}
        />
      </Box>

      {/* logs */}
      <Box
        backgroundColor="#E8E8E8"
        ml="-1.6rem"
        mt="auto"
        width="calc(100% + 3.2rem)"
        padding="2rem 1.6rem"
      >
        <Text
          display="block"
          fontSize="1.6rem"
          color="var(--primary-text-color)"
          fontWeight="bold"
          mb="1.2rem"
        >
          変更履歴
        </Text>
        {itemLogs?.map((item) => (
          <ItemLog
            key={item.id}
            log={item}
            user={listAllUserById?.[item?.createdBy || ""]}
            currentUser={currentUser}
            listUserById={listUserById}
            mapDynamicFieldTitle={mapDynamicFieldTitle}
            documentItem={documentItemSelected}
            onRevertDataByLog={onRevertDataByLog}
          />
        ))}

        {isLoadMoreLogs && (
          <Flex height="4rem" justifyContent="center" alignItems="center">
            <Spinner size="md" color="blue.500" />
          </Flex>
        )}
      </Box>

      {/* status */}
      <Flex
        position="sticky"
        width="calc(100% + 3.2rem)"
        marginLeft="-1.6rem"
        bottom="0"
        left="0"
        height="5.8rem"
        zIndex="12"
        backgroundColor="#E8E8E8"
        borderTop="1px solid rgba(0,0,0,0.15)"
        justifyContent="space-between"
        padding="0px 1.6rem"
        alignItems="center"
        gap="0.8rem"
      >
        <Text
          flexShrink={0}
          color="var(--primary-text-color)"
          fontSize="1.4rem"
        >
          ステータス
        </Text>
        <Box flex={1} maxW="35rem">
          <ItemStatusMenu
            isLoadedViewer={isLoadedViewer}
            currentUser={currentUser}
            documentItemSelected={documentItemSelected}
            isDisabled={isDisabled}
            documentCategorySelected={documentCategorySelected}
            insertDocumentItemLog={insertItemLog}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(ModalTypePin);

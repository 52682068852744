import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { useMemo, useRef } from "react";
import { IconBase } from "./base";

interface Props {
  options: { name: string; value: string; highlight?: string }[];
  value?: string | string[];
  onChange: (value: string | string[]) => void;
  type?: "checkbox" | "radio";
  name?: string;
  inputHeight?: string;
  menuButtonProps?: MenuButtonProps;
  isShowIcon?: boolean;
  defaultColor?: string;
  placeholder?: string;
  isDisabled?: boolean;
}

function Dropdown({
  options,
  value,
  onChange,
  type = "radio",
  name,
  defaultColor,
  placeholder,
  inputHeight = "4.4rem",
  menuButtonProps,
  isShowIcon = true,
  isDisabled,
}: Props) {
  const dropdownRef = useRef<any>(null);

  const selected = useMemo(
    () =>
      options.find(
        (d) =>
          d.value === value || (type === "checkbox" && d.value === value?.[0])
      ),
    [options, type, value]
  );

  return (
    <Menu closeOnSelect={type === "radio"}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant="whiteIcon"
            boxShadow="none"
            height={inputHeight}
            pl="1.5rem"
            flex={1}
            w="85%"
            ref={dropdownRef}
            sx={{
              "span:nth-of-type(1)": {
                flex: "unset",
                mr: "auto",
              },
            }}
            _focus={{}}
            rightIcon={
              <IconBase
                icon="/img/icon-navigation-arrow_drop_down.svg"
                transition="0.4s"
                transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
              />
            }
            color={defaultColor ? defaultColor : "#000"}
            isDisabled={isDisabled}
            {...menuButtonProps}
          >
            {name || selected?.name || placeholder}
          </MenuButton>
          <MenuList
            minW={dropdownRef.current?.clientWidth}
            zIndex="dropdown"
            p="0px"
            outline="none"
          >
            <MenuOptionGroup
              value={value}
              title=""
              type={type}
              onChange={(value) => {
                onChange(value);
              }}
            >
              {options.map((d) => (
                <MenuItemOption
                  minH="4rem"
                  key={d.value}
                  fontSize="1.4rem"
                  fontWeight="medium"
                  p=".5rem 1.5rem"
                  borderBottom="1px solid var(--primary-border-color)"
                  _last={{ borderBottom: "none" }}
                  value={d.value}
                  _focus={{}}
                  _hover={{ backgroundColor: "#F0F9FF" }}
                  backgroundColor={d.value === value ? "#F0F9FF" : "#fff"}
                  icon={
                    isShowIcon ? (
                      <IconBase icon="/img/icon-action-check_circle.svg" />
                    ) : null
                  }
                >
                  {d.name}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default Dropdown;

import { logDev } from "utils/logs";
import { CHANNEL_COMMON, MessageType } from "constants/websocket";
import { useAppWebSocket } from "hooks/useAppWebSocket";

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteTaskSheetTemplate,
  deleteTaskType,
  fetchTaskTypes,
  setTaskType,
} from "redux/taskSlice";
import { addFamily, deleteFamily, updateFamily } from "redux/forgeViewerSlice";
import { saveIndexDB } from "./useSyncData";
import { Operation, StoreName } from "constants/serviceWorker";
import { useMatch, useNavigate } from "react-router-dom";
import { routePath } from "routes/path";
import { message } from "components/base";
import { RootState } from "redux/store";
import { fetchDataProjects, setDataProjectDetail } from "redux/projectSlice";
import { getNewURLFromBimFile } from "models/project";
import { fetchListUserAssigned } from "redux/userSlice";
import ReceiveDataCommonSocketHelper from "helper/receiveDataCommonSocketHelper";

const useSyncCommonTaskData = (watchingMessageTypes?: MessageType[]) => {
  const dispatch = useDispatch();
  const { webSocketMessages, isWebSocketOpen, sendWebSocketMessage } =
    useAppWebSocket(CHANNEL_COMMON);
  const navigate = useNavigate();
  const isVisitingForgeViewerPage = useMatch(routePath.ForgeViewer);
  const isVisitingHomePage = useMatch(routePath.Home);
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { isCreateProject } = useSelector((state: RootState) => state.bim);
  const receiveDataCommonSocketHelper = useRef(
    new ReceiveDataCommonSocketHelper({ dispatch })
  );

  const refData = useRef({
    isVisitingForgeViewerPage,
    dataProjectDetail,
    isVisitingHomePage,
    isCreateProject,
  });
  refData.current = {
    isVisitingForgeViewerPage,
    dataProjectDetail,
    isVisitingHomePage,
    isCreateProject,
  };

  useEffect(() => {
    if (!webSocketMessages.length) {
      return;
    }

    webSocketMessages.forEach((e) => {
      const { type, data } = e;
      if (watchingMessageTypes && !watchingMessageTypes?.includes(type as MessageType)) {
        return;
      }
      logDev("receive common message", webSocketMessages);

      switch (type) {
        case MessageType.UPDATE_TASK_TYPE:
          data.id && dispatch(setTaskType(data));
          saveIndexDB(StoreName.TASK_TYPES, Operation.Patch, data);
          break;
        case MessageType.ADD_TASK_TYPE:
          data?.id && dispatch(setTaskType(data));
          saveIndexDB(StoreName.TASK_TYPES, Operation.Post, data);
          break;
        case MessageType.DELETE_TASK_TYPE:
          data.id && dispatch(deleteTaskType(data.id));
          saveIndexDB(StoreName.TASK_TYPES, Operation.Delete, data);
          break;
        case MessageType.REFRESH_TASK_TYPE:
          dispatch(fetchTaskTypes());
          break;

        case MessageType.UPDATE_FAMILY:
          dispatch(updateFamily(data));
          saveIndexDB(StoreName.FAMILIES, Operation.Patch, data);
          break;

        case MessageType.ADD_FAMILY:
          dispatch(addFamily(data));
          saveIndexDB(StoreName.FAMILIES, Operation.Post, data);
          break;

        case MessageType.DELETE_FAMILY:
          dispatch(deleteFamily(data));
          saveIndexDB(StoreName.FAMILIES, Operation.Delete, { id: data });
          break;

        /**
         * Refetch tasksheet template because data very of that to heavy can't send
         * via socket
         */
        case MessageType.FETCH_TASKSHEET_TEMPLATE:
          receiveDataCommonSocketHelper.current.fetchTaskSheetTemplate(data);
          break;

        case MessageType.DELETE_TASKSHEET_TEMPLATE:
          dispatch(deleteTaskSheetTemplate(data));
          saveIndexDB(StoreName.TASK_SHEET_TEMPLATE, Operation.Delete, {
            id: data,
          });
          break;

        case MessageType.DELETE_PROJECT_BIM_FILE:
          {
            const { id, name } = data;
            /**
             * Navigate to home if user is visiting the forge viewer page
             */
            dispatch(
              fetchDataProjects({ forceGet: refData.current.isCreateProject })
            );
          }
          break;

        case MessageType.UPDATE_PROJECT_BIM_FILE:
          if (refData.current.dataProjectDetail?.id !== data.id) return;
          const newUrl = getNewURLFromBimFile(data);

          if (isVisitingForgeViewerPage && newUrl) {
            message.info([
              `${data.name}のバージョンが変わったため、新しいバージョンに更新されます。`,
            ]);

            // Reset data project before visiting forgeviewer
            dispatch(setDataProjectDetail({} as any));

            // Navigate to forgev iewer page
            navigate(newUrl);
          } else {
            dispatch(setDataProjectDetail(data));
            dispatch(fetchListUserAssigned(data.id));
          }
          break;
        case MessageType.RELOAD_TASK_TYPE:
          dispatch(fetchTaskTypes());
          break;

        default:
          break;
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages, JSON.stringify(watchingMessageTypes)]);

  return { webSocketMessages, isWebSocketOpen, sendWebSocketMessage };
};

export default useSyncCommonTaskData;

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconStatusDefect(props: IconProps) {
  return (
    <Icon width="9" height="9" fill="none" viewBox="0 0 9 9" {...props}>
      <path
        data-name="↳Color"
        fill="#fff"
        d="m4.259 5.272 2.485 2.485a.87.87 0 0 0 .625.255.85.85 0 0 0 .638-.255.86.86 0 0 0 .26-.632.86.86 0 0 0-.26-.631L5.522 4.009l2.485-2.485a.87.87 0 0 0 .255-.626.85.85 0 0 0-.255-.638.86.86 0 0 0-.632-.26.86.86 0 0 0-.631.26L4.259 2.746 1.774.26a.87.87 0 0 0-.626-.254A.85.85 0 0 0 .51.26a.86.86 0 0 0-.26.632.86.86 0 0 0 .26.632l2.485 2.485L.51 6.494a.87.87 0 0 0-.254.626.85.85 0 0 0 .254.637.86.86 0 0 0 .632.26.86.86 0 0 0 .632-.26z"
      />
    </Icon>
  );
}

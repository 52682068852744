import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function IconPause(props: IconProps) {
  return (
    <Icon width="800" height="800" viewBox="0 0 64 64" {...props}>
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="5"
        d="M25 23v18m13-18v18m15.92-30.919c12.107 12.105 12.107 31.732 0 43.838-12.106 12.108-31.734 12.108-43.839 0-12.107-12.105-12.107-31.732 0-43.838 12.105-12.108 31.732-12.108 43.839 0z"
      />
    </Icon>
  );
}

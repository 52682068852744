import { useBoolean } from "@chakra-ui/react";
import { Fragment, Slice } from "@tiptap/pm/model";
import { Editor, JSONContent, useEditor } from "@tiptap/react";
import useKeynote from "components/modal/PreviewDocumentCategory/hooks/useKeynote";
import { PREVIEW_DOCUMENT_CATEGORY_BOX_ID } from "constants/styleProps";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import isEqual from "lodash/isEqual";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  setStatusContentFromS3,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import { safelyParseJSON, sleep } from "utils/common";
import { getTextFromUrl } from "utils/file";
import { logDev } from "utils/logs";
import { EditorExtensions } from "../extendsions/list";
import {
  EDITOR_SCROLL_MARGIN_BOTTOM,
  EDITOR_SCROLL_MARGIN_TOP,
  EDITOR_SCROLL_MARGIN_TOP_ON_IPAD,
  NodeType,
  NodeTypeText,
  UsePreviewDocumentProps,
} from "../type";
import { checkValidateContentEditor } from "../utils";
import useGetStructureNode from "./useGetStructureNode";
import useMapPages from "./useMapPages";

const usePreviewEditorDocument = (props: UsePreviewDocumentProps) => {
  const {
    isEditMode,
    components,
    template,
    documentItems,
    documentKeynoteDataProp,
    projectName,
    blackboardTemplateProps,
    listUserById,
    familyInstances,
    documentCategorySelected,
    companiesById,
    neptuneAreas,
    keynoteImageDataProp,
    currentDocument,

    onInitEditor,
  } = props;

  /* Should reset loading to false */
  /* loading call api get content from s3 */
  const [isLoadingContentEditor, setIsLoadingContentEditor] = useBoolean(true);
  /* loading generate json data to html from s3 */
  const [isValidateContentEditor, setIsValidateContentEditor] =
    useBoolean(false);
  /* loading generate json data to html when preview editMode */
  const [isLoadingShowEditor, setIsLoadingShowEditor] = useBoolean(false);
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  const { systemMode } = useSelector((state: RootState) => state.forgeViewer);
  const { documentContainerSize, isContentFromS3 } = useSelector(
    (state: RootState) => state.document
  );
  const dispatch = useDispatch();
  const contentEditorRef = useRef<{
    init: JSONContent | undefined;
    current: JSONContent | undefined;
    text: string | undefined;
  }>({
    init: undefined,
    current: undefined,
    text: undefined,
  });
  const lastCaretPositionRef = useRef(0);

  // keynote
  const { documentKeynoteData: documentKeynoteDataContext } = useContext(
    PreviewDocumentCategoryContext
  );
  const documentKeynoteData =
    documentKeynoteDataProp || documentKeynoteDataContext;
  const { hasKeyNote, pageKeyNote, keynoteAxis } = useKeynote({
    components,
    template,
    documentKeynoteData,
  });

  // pages
  const { pages } = useMapPages({ components, template, documentItems });

  useEffect(() => {
    (async () => {
      try {
        contentEditorRef.current = {
          init: undefined,
          current: undefined,
          text: undefined,
        };
        setIsLoadingContentEditor.on();
        const text = await getTextFromUrl(currentDocument?.content || "");
        const json = safelyParseJSON(text);
        const isJsonValid = checkValidateContentEditor(json);
        if (isJsonValid) {
          contentEditorRef.current = {
            init: json,
            current: json,
            text: undefined,
          };

          setIsValidateContentEditor.on();
        }

        dispatch(setStatusContentFromS3(isJsonValid));
        setIsLoadingContentEditor.off();
      } catch (error) {
        logDev(error);
        dispatch(setStatusContentFromS3(false));
        setIsLoadingContentEditor.off();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument?.id, currentDocument?.content]);

  // structure node
  const { getStructurePreviewContainerNodes } = useGetStructureNode({
    scale: 1,
    systemMode,
    pageKeynoteDirection: pageKeyNote?.pageDirection,
    pageKeyNoteId: pageKeyNote?.pageId,
    hasKeyNote,
    keynoteAxis,
    template,
    documentType: template?.documentType,
    documentContainerSize,
    documentItems,
    projectName,
    blackboardTemplateProps,
    listUserById,
    familyInstances,
    documentCategorySelected,
    companiesById,
    neptuneAreas,
    keynoteImageDataProp,
  });

  const contentDefault = useMemo(() => {
    const previewContainerNodes = getStructurePreviewContainerNodes(pages);

    return {
      type: "doc",
      content: [previewContainerNodes],
    };
  }, [pages, getStructurePreviewContainerNodes]);

  if (!contentEditorRef.current.init && isContentFromS3 !== true) {
    contentEditorRef.current = {
      init: contentDefault as any,
      current: contentDefault as any,
      text: undefined,
    };
  }

  const unSelection = useCallback((editor: Editor) => {
    editor.commands.unSelection();
  }, []);

  const editor = useEditor(
    {
      extensions: EditorExtensions,
      onCreate: ({ editor }) => {
        unSelection(editor);
      },
      editorProps: {
        attributes: {
          spellcheck: "false",
        },
        scrollMargin: {
          top: EDITOR_SCROLL_MARGIN_TOP,
          left: 0,
          right: 0,
          bottom: EDITOR_SCROLL_MARGIN_BOTTOM,
        },
        handleDOMEvents: {
          click: (...args) => {
            if (!lastCaretPositionRef.current) {
              return;
            }
            const [view, event] = args;

            const range = view.state.selection.ranges.at(-1);
            const isSelectText =
              range?.$from.parentOffset !== range?.$to.parentOffset &&
              !!range?.$from &&
              !!range.$to.pos;
            if (isSelectText) {
              return;
            }

            const target: HTMLElement = event.target as any;
            const parentElement = target.parentElement;
            const dataType =
              target.getAttribute("data-type") ||
              parentElement?.getAttribute("data-type");
            const isNodeViewText = dataType === NodeType.TEXT;

            // focus last caret position
            if (isNodeViewText) {
              editor?.commands.setCustomSelection(lastCaretPositionRef.current);
            }
          },
          paste: (view, event) => {
            const text = event.clipboardData?.getData("text/plain");
            if (!text) {
              return;
            }
            // transform pasted HTML to plain text;
            const schema = view.state.schema;
            const textNode = schema.text(text.replace(/\r\n?/g, "\n"));
            const resultSlice = new Slice(Fragment.from(textNode), 0, 0);
            const tr = view.state.tr.replaceSelection(resultSlice);
            view.dispatch(
              tr
                .scrollIntoView()
                .setMeta("paste", true)
                .setMeta("uiEvent", "paste")
            );
            event.preventDefault();
          },
        },
        // store last caret position
        createSelectionBetween: (...args) => {
          const [, anchor] = args;
          lastCaretPositionRef.current = anchor.pos;

          return null;
        },
        handleClickOn: (...args) => {
          const [, , node, , , direct] = args;
          if (!direct || !lastCaretPositionRef.current) {
            return;
          }

          const nodeType = node.type.name as any;
          const isNodeTypeText = NodeTypeText.includes(nodeType);

          // focus last caret position
          if (isNodeTypeText) {
            editor?.commands.setCustomSelection(lastCaretPositionRef.current);
          }
        },
      },
      onUpdate: ({ editor }) => {
        contentEditorRef.current.current = editor.getJSON() as any;
        setIsLoadingShowEditor.off();
        setIsValidateContentEditor.off();
      },
      onFocus: ({ editor }) => {
        if (!isMobile) {
          return;
        }

        // fix auto scroll when ipad show keyboard
        const fixScrollOnIpad = async () => {
          // await sort keyboard show
          await sleep(500);
          const element = document.getElementById(
            PREVIEW_DOCUMENT_CATEGORY_BOX_ID
          )?.childNodes?.[1] as any;
          const selection = editor.state.selection;
          window.scrollTo({ top: 0 });
          const handleScroll = (e: Event) => {
            const target = e.target as HTMLElement;
            if (!target.scrollTop) {
              const viewportCoords = editor.view.coordsAtPos(selection.anchor);
              const absoluteOffset =
                element.scrollTop +
                viewportCoords.top -
                EDITOR_SCROLL_MARGIN_TOP_ON_IPAD;
              element.scrollTo({ top: absoluteOffset, behavior: "smooth" });
              window.removeEventListener("scroll", handleScroll);
            }
          };

          window.addEventListener("scroll", handleScroll);
        };
        fixScrollOnIpad();
      },
    },
    []
  );
  const editorRef = useRef(editor);
  editorRef.current = editor;
  onInitEditor?.(editor);

  const setContentEditor = useCallback(
    (params: {
      dataChange: JSONContent | undefined;
      initData: JSONContent | undefined;
      currentData: JSONContent | undefined;
    }) => {
      if (!editor) {
        return;
      }

      if (params.initData) {
        contentEditorRef.current.init = params.initData;
      }

      if (params.currentData) {
        contentEditorRef.current.current = params.currentData;
      }

      // using setTimeout to avoid flushSync error
      // PR: https://github.com/ueberdosis/tiptap/issues/3580
      setTimeout(() => {
        if (params.dataChange) {
          editor.commands.loadContent(params.dataChange);
          unSelection(editor);
          contentEditorRef.current.text = JSON.stringify(editor.getJSON());
        }
      }, 10);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  /* init data from data default */
  useEffect(() => {
    if (!editor || isLoadingContentEditor) {
      return;
    }

    editor.setEditable(isEditMode, false);

    if ([null, true].includes(isContentFromS3)) {
      return;
    }

    if (isEditMode) {
      const initData = contentEditorRef.current.init;
      const text = contentEditorRef.current.text;
      const contentText = JSON.stringify(editor.getJSON());
      if (isEqual(text, contentText)) {
        return;
      }

      setIsLoadingShowEditor.on();
      setContentEditor({
        dataChange: initData!,
        initData: undefined,
        currentData: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isContentFromS3,
    isLoadingContentEditor,
    isEditMode,
    editor,
    setContentEditor,
  ]);

  /* init data from s3 */
  useEffect(() => {
    if (!editor || isLoadingContentEditor || !isContentFromS3) {
      return;
    }

    setContentEditor({
      dataChange: contentEditorRef.current.current!,
      initData: undefined,
      currentData: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingContentEditor, isContentFromS3, editor, setContentEditor]);

  const onCancelEditable = useCallback(() => {
    if (!editor) {
      return;
    }

    const initData = contentEditorRef.current.init!;
    setContentEditor({
      dataChange: initData,
      currentData: initData,
      initData: undefined,
    });
  }, [editor, setContentEditor]);

  const clearContentEditor = useCallback(() => {
    if (!editor) {
      return;
    }

    editor.chain().clearContent(true).run();
    contentEditorRef.current = {
      init: undefined,
      current: undefined,
      text: undefined,
    };
    setIsLoadingContentEditor.on();
    setIsLoadingShowEditor.off();
    setIsValidateContentEditor.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return {
    pages,
    isEditMode,
    isLoadingShowEditor,
    isLoadingContentEditor,
    isValidateContentEditor,
    isContentFromS3,
    editorRef,
    editor,
    onCancelEditable,
    setContentEditor,
    clearContentEditor,
  };
};

export default usePreviewEditorDocument;

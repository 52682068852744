import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import {
  renderCellPropertyCheckbox,
  renderContentTypeCommon,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  LinkedDataField,
  TableDefaultStyle,
} from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { CellType } from "interfaces/models/component";
import { iCommonDocumentTemplateProps } from "interfaces/models/documentTemplate";
import { useCallback } from "react";
import NormalTextPreview from "../../NormalTextPreview";
import useFamilyInstance from "hooks/useFamilyInstance";

const useGetContentTypeFlexibleDuct = (props: iUseGetContentOfCell) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,
    flexibleDuctProps,
  } = props;

  const { familyInstances } = useFamilyInstance();
  const contentOfCellTypeFlexibleDuct = useCallback(
    (cell: CellType) => {
      const data = structuredClone(cell);
      const headerComponent = flexibleDuctProps?.headerComponent || [];
      const rowIndex = flexibleDuctProps?.option?.rowIndex || 0;
      const cellIndex = flexibleDuctProps?.option?.cellIndex || 0;
      const offsetItemLinked = flexibleDuctProps?.offsetItemLinked || 0;
      const documentItems = flexibleDuctProps?.documentItems || [];
      const documentItem = documentItems?.find(
        (doc) => doc.linkedDataId === component?.subcategoryIdSelected
      );

      const dataRow = (documentItem?.subItems?.[rowIndex] ||
        {}) as DocumentItemDTO;

      if (!dataRow?.id) {
        return null;
      }

      const cellEle = document.getElementById(cell.cellId);
      const headerTitleId = headerComponent?.[cellIndex].cellId;
      const indexCellActions = headerComponent
        .filter(
          (cellHeader) =>
            cellHeader.cellLinkedData?.field ===
            LinkedDataField.COMMON.ACTION_TAKEN
        )
        .map((cellAction) =>
          headerComponent.findIndex(
            (cell) => cell.idColumn === cellAction.idColumn
          )
        );

      const renderCommon = renderContentTypeCommon({
        contentType,
        contentCommonProps: (flexibleDuctProps ||
          {}) as iCommonDocumentTemplateProps,
        cell,
        familyInstances,
        documentItems,
        documentItem: dataRow,
        component,
        subTableComponentPreviewProps: {
          component,
          parentCell: cell,
          displaySize: {
            width:
              (width / component.size.width) * displaySize.width -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            height:
              (height / component.size.height) * displaySize.height -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
          },
          zoomRatio,
          cellSize,
          sizePageRatio,
          pageDirectionRatio,
          isOnlyView,
          isDuplicateRow,
          isParentLastColumn: isLastColumn,
          limit,
          isBlackboardTemplateImage,
          components,
          selectedCells,
          documentContainerSize,
          currentTemplate,
          componentSelected,
          isComponentDragging,
          contentType,
          flexibleDuctProps,
        },
      });

      if (renderCommon) {
        return renderCommon;
      }

      const handleCheckbox = (cell: CellType) => {
        const options =
          cell?.cellLinkedData?.options?.valueOfCheckbox
            ?.split("・")
            .filter((e) => e) || [];
        const numberOfCheckbox =
          cell?.cellLinkedData?.options?.numberOfCheckbox || 1;
        const isMultipleCheckbox = numberOfCheckbox > 1;
        const isNoneLabel = options.length === 0;

        const isAllowSave = !(isMultipleCheckbox && isNoneLabel);
        cellEle?.setAttribute("data-is-allow-save", `${isAllowSave}`);
      };

      switch (data?.cellProperty) {
        case CellProperty.TEXT:
          break;

        case CellProperty.NO:
          data.value = `${Number(cell.value) + offsetItemLinked}`;

          break;

        case CellProperty.DOCUMENT_DATA:
          if (data.cellLinkedData?.type === CellProperty.CHECK_BOX) {
            handleCheckbox(data);
            break;
          }

          break;

        default:
          break;
      }

      if (indexCellActions.includes(cellIndex) && dataRow) {
        data.value = dataRow.memo || "";
      }

      if (!data) {
        return null;
      }

      cellEle?.setAttribute("data-document-id", dataRow?.id || "");
      cellEle?.setAttribute("data-header-title-id", headerTitleId || "");
      cellEle?.setAttribute("data-field-content", data?.value || "");
      cellEle?.setAttribute(
        "data-field-name",
        data?.cellLinkedData?.field || ""
      );
      cellEle?.setAttribute(
        "data-options",
        data?.cellLinkedData?.options?.valueOfCheckbox || ""
      );

      switch (data?.cellProperty) {
        case CellProperty.NO:
        case CellProperty.TEXT:
        case CellProperty.INPUT_DATA:
        case CellProperty.CALENDAR: {
          return <NormalTextPreview data={data} />;
        }

        case CellProperty.DOCUMENT_DATA:
          switch (data?.cellLinkedData?.field) {
            case CellProperty.CHECK_BOX:
              return renderCellPropertyCheckbox(data, component, {
                contentType,
                documentItem: dataRow,
              });

            default:
              return <NormalTextPreview data={data} />;
          }

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [
      familyInstances,
      isBlackboardTemplateImage,
      contentType,
      isComponentDragging,
      components,
      selectedCells,
      documentContainerSize,
      currentTemplate,
      componentSelected,
      limit,
      isLastColumn,
      cellSize,
      displaySize,
      width,
      height,
      zoomRatio,
      sizePageRatio,
      pageDirectionRatio,
      isOnlyView,
      isDuplicateRow,
      component,
      flexibleDuctProps,
    ]
  );

  return {
    contentOfCellTypeFlexibleDuct,
  };
};

export default useGetContentTypeFlexibleDuct;

import { AllSelection, TextSelection } from "@tiptap/pm/state";
import { CommandProps, Extension } from "@tiptap/core";
import { NodeType } from "../type";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    indent: {
      indent: () => ReturnType;
      outdent: () => ReturnType;
    };
  }
}

export const Indent = Extension.create({
  name: "indent",

  addOptions() {
    return {
      types: [NodeType.NORMAL_TEXT, NodeType.MULTIPLE_NORMAL_TEXT],
      minLevel: 0,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            renderHTML: (attributes) => {
              return attributes.indent > this.options.minLevel
                ? { "data-indent": attributes.indent }
                : null;
            },
            parseHTML: (element) => {
              const indentLevel = Number(element.getAttribute("data-indent"));

              return indentLevel && indentLevel > this.options.minLevel
                ? indentLevel
                : null;
            },
          },
        },
      },
    ];
  },

  addCommands() {
    const setNodeIndentMarkup = (
      tr: CommandProps["tr"],
      pos: any,
      delta: number
    ) => {
      const node = tr?.doc?.nodeAt(pos);
      if (!node) return tr;

      const { indent: currentIndent = 0, ...currentAttrs } = node.attrs;
      const { minLevel } = this.options;
      const nextLevel = Math.max(currentIndent + delta, minLevel);

      if (nextLevel === currentIndent) return tr;

      const nodeAttrs =
        nextLevel > minLevel
          ? { ...currentAttrs, indent: nextLevel }
          : currentAttrs;

      return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
    };

    const updateIndentLevel = (tr: CommandProps["tr"], delta: number) => {
      const { doc, selection } = tr;
      if (!doc || !selection) {
        return tr;
      }

      if (
        selection instanceof TextSelection ||
        selection instanceof AllSelection
      ) {
        const { from, to } = selection;
        doc.nodesBetween(from, to, (node, pos) => {
          if (this.options.types.includes(node.type.name as NodeType)) {
            tr = setNodeIndentMarkup(tr, pos, delta);

            return false;
          }

          return true;
        });
      }

      return tr;
    };

    const applyIndent =
      (direction: number) =>
      () =>
      ({ tr, state, dispatch }: CommandProps) => {
        const { selection } = state;
        tr = tr.setSelection(selection);
        tr = updateIndentLevel(tr, direction);

        if (tr.docChanged) {
          dispatch?.(tr);

          return true;
        }

        return false;
      };

    return {
      indent: applyIndent(1),
      outdent: applyIndent(-1),
    };
  },
});

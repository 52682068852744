import { ComponentPropsWithRef, memo } from "react";

const IconNetworkOffline = memo<ComponentPropsWithRef<"svg">>(
  ({ ...props }) => {
    return (
      <svg
        viewBox="0 0 24 24"
        focusable="false"
        width="22"
        height="22"
        className="chakra-icon css-ss0ati"
        {...props}
      >
        <path
          fill="#EF4444"
          d="M19.3,20.78l-8.32-8.37c-.7.07-1.37.24-2.02.49s-1.23.59-1.75,1c-.26.18-.55.28-.86.29-.31,0-.58-.1-.79-.32-.22-.22-.32-.49-.3-.81.02-.32.16-.58.41-.77.49-.4,1.03-.75,1.63-1.05.59-.3,1.21-.53,1.84-.67l-2.96-2.96c-.54.24-1.08.53-1.64.88-.56.35-1.08.73-1.57,1.15-.25.2-.53.31-.85.31-.32,0-.58-.1-.79-.32-.22-.22-.33-.49-.31-.8.02-.31.15-.57.39-.77.48-.43.99-.82,1.51-1.18.53-.36,1.06-.67,1.6-.92l-1.67-1.67c-.14-.14-.21-.31-.21-.52,0-.21.07-.39.21-.53.14-.14.32-.22.53-.22s.38.07.53.22l16.47,16.48c.16.16.23.33.23.54s-.08.38-.23.54c-.16.14-.33.21-.54.22-.2,0-.38-.07-.54-.22ZM12,20.03c-.57,0-1.04-.2-1.43-.59-.39-.4-.59-.87-.59-1.43s.2-1.04.59-1.43c.39-.39.87-.59,1.43-.59s1.04.2,1.43.59.59.87.59,1.43-.2,1.03-.59,1.43-.87.59-1.43.59ZM18.55,13.71c-.19.19-.41.28-.66.28-.25,0-.47-.1-.66-.29l-.24-.24-.24-.24-1.63-1.63c-.18-.18-.21-.36-.09-.55.12-.19.3-.24.56-.16.53.16,1.03.36,1.5.62.48.25.93.55,1.35.88.22.17.34.39.36.65.03.27-.06.49-.25.68ZM22.68,9.62c-.22.23-.49.34-.8.33-.31,0-.6-.12-.86-.32-1.22-1.05-2.6-1.86-4.13-2.44-1.54-.58-3.17-.87-4.89-.87-.35,0-.69.01-1.03.04-.33.03-.66.06-.97.11-.31.05-.59-.01-.84-.2-.25-.18-.4-.43-.46-.74-.05-.31.01-.59.2-.84.19-.25.44-.4.75-.46.36-.05.73-.09,1.12-.12s.8-.04,1.23-.04c2.04,0,3.97.35,5.76,1.05,1.8.7,3.41,1.66,4.83,2.89.25.21.38.48.4.79.02.31-.09.58-.31.81Z"
        ></path>
      </svg>
    );
  }
);

export default IconNetworkOffline;

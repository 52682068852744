import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseModalProps,
} from "@chakra-ui/react";
import {
  DEFAULT_BLACKBOARD_HEIGHT,
  DEFAULT_BLACKBOARD_WIDTH,
  MIN_BLACKBOARD_HEIGHT,
  MIN_BLACKBOARD_WIDTH,
} from "components/ui/BlackboardImage";
import BlackboardTemplateImage from "components/ui/BlackboardTemplateImage";
import { PresignedImageWithRef } from "components/ui/PresignedImage";
import Spinner from "components/ui/Spinner";
import { MODERN_SCREENSHOT_WORKER_URL } from "constants/downloadPDF";
import { ContentType } from "constants/enum";
import { SELECT_BLACKBOARD_POSITION_CONTAINER_ID } from "constants/styleProps";
import { useBlackboardResize } from "hooks/useBlackboardResize";
import { DocumentSubItemDTO } from "interfaces/dtos/documentItemDTO";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { SizePosition } from "interfaces/models/rnd";
import { domToJpeg } from "modern-screenshot";
import { memo, useEffect, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { Rnd } from "react-rnd";
import { snapShotBlackboard } from "utils/data-logs";
import { logDev } from "utils/logs";

interface Props extends UseModalProps {
  documentSubItem?: DocumentSubItemDTO;
  blackboardTemplateDetail?: BlackboardTemplate;
  imgSrc?: string;
  blackBoard: Blackboard;
  position?: number[] | undefined;
  blackboardTemplateProps: iBlackboardTemplateProps;

  onSelect: (res: SizePosition, thumbnailTemp?: string) => void;
}

function EditPositionBlackBoardModal({
  documentSubItem,
  blackboardTemplateDetail,
  isOpen,
  blackBoard,
  imgSrc,
  position,
  blackboardTemplateProps,
  onSelect,
  onClose,
}: Props) {
  const [isLoadedImage, setIsLoadedImage] = useState(true);

  const imgRef = useRef<HTMLImageElement>(null);
  const rndRef = useRef<Rnd | null>();

  const {
    posResizingRef,
    rndSizeRef,
    pos,
    rndSize,
    bbRef,
    enableResizing,
    onResizeStop,
    onDragStop,
    onResize,
    setPos,
    setRndSize,
  } = useBlackboardResize({
    isOpen,
    parentWidth: imgRef.current?.clientWidth,
    parentHeight: imgRef.current?.clientHeight,
  });

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  useEffect(() => {
    if (position?.length) {
      const [x, y, width, imgWidth] = position;
      bbRef.current.x = x;
      bbRef.current.y = y;

      if (imgWidth) {
        bbRef.current.width = width;
        bbRef.current.imgWidth = imgWidth;
      }

      logDev(width, imgWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const getScaleWidthAndHeight = () => {
    const [, , , imgWidth, , imgHeight] = position || [];

    const newImgWidth = imgRef?.current?.offsetWidth ?? 0;
    const newImgHeight = imgRef?.current?.offsetHeight ?? 0;

    let scaleWidth = 1;
    let scaleHeight = 1;
    if (imgWidth > 0) {
      scaleWidth = newImgWidth / imgWidth;
      scaleHeight = newImgHeight / imgHeight;
    }

    return { scaleHeight, scaleWidth };
  };

  const onImageLoad = () => {
    if (!imgRef.current?.offsetWidth) return;

    bbRef.current.imgWidth = imgRef.current.offsetWidth;
    bbRef.current.imgHeight = imgRef.current.offsetHeight;
    const [x, y, width, imgWidth, height] = position || [];

    const { scaleHeight, scaleWidth } = getScaleWidthAndHeight();

    let size = {
      width: DEFAULT_BLACKBOARD_WIDTH,
      height: DEFAULT_BLACKBOARD_HEIGHT,
    };

    let pos = { x: 0, y: 0 };
    if (imgWidth > 0) {
      pos = {
        y: y * scaleHeight,
        x: x * scaleWidth,
      };

      const sizeWidth = width * scaleWidth;
      const sizeHeight = height * scaleHeight;

      size = {
        width:
          sizeWidth < MIN_BLACKBOARD_WIDTH ? MIN_BLACKBOARD_WIDTH : sizeWidth,
        height:
          sizeHeight < MIN_BLACKBOARD_HEIGHT
            ? MIN_BLACKBOARD_HEIGHT
            : sizeHeight,
      };
    }

    if (size.width > bbRef.current.imgWidth) {
      size.width = bbRef.current.imgWidth;
    }

    if (size.height > bbRef.current.imgHeight) {
      size.height = bbRef.current.imgHeight;
    }

    rndSizeRef.current = size;
    posResizingRef.current = pos;
    setRndSize(size);
    setPos(pos);
    setTimeout(() => {
      setIsLoadedImage(false);
    }, 100);
  };

  const onSelectPosition = async () => {
    const [, , , imgWidth, , imgHeight] = position || [];
    const { scaleWidth, scaleHeight } = getScaleWidthAndHeight();

    if (imgWidth) {
      bbRef.current.width = rndSize.width / scaleWidth;
      bbRef.current.height = rndSize.height / scaleHeight;

      bbRef.current.x = pos.x / scaleWidth;
      bbRef.current.y = pos.y / scaleHeight;

      bbRef.current.imgWidth = imgWidth;
      bbRef.current.imgHeight = imgHeight;
    }

    if (bbRef.current.width > bbRef.current.imgWidth) {
      bbRef.current.width = bbRef.current.imgWidth;
    }

    if (
      Number(bbRef.current.height || 0) > Number(bbRef.current.imgHeight || 0)
    ) {
      bbRef.current.height = bbRef.current.imgHeight;
    }

    const thumbnailTemp = await snapShotBlackboard({
      elementId: SELECT_BLACKBOARD_POSITION_CONTAINER_ID,
      isMobile,
    });

    onSelect(bbRef.current, thumbnailTemp);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt="10vh" w="95%" maxW="110.9rem" p={{ base: "4rem" }}>
        <ModalCloseButton fontSize="12px" _focusVisible={{}} />

        <Flex
          mt="1rem"
          justifyContent="center"
          alignItems="center"
          h="50rem"
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="contain"
          position="relative"
          sx={{
            ".bb-main": {
              h: "100%",
            },
          }}
        >
          <Box
            id={SELECT_BLACKBOARD_POSITION_CONTAINER_ID}
            position="relative"
            height="100%"
            display="inline-block"
          >
            {isLoadedImage && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                zIndex="10"
                width="100%"
                height="100%"
              >
                <Spinner width="2.4rem" height="2.4rem" />
              </Box>
            )}

            <PresignedImageWithRef
              maxH="100%"
              src={imgSrc}
              _ref={imgRef}
              onLoad={onImageLoad}
              isConvertToBase64={true}
              className="bb-img-parent1"
              style={{ height: "100%", boxShadow: "0px 0px 9px #0000003D" }}
            />
            {!isLoadedImage && (
              <Rnd
                ref={(c) => {
                  rndRef.current = c;
                }}
                minWidth={MIN_BLACKBOARD_WIDTH}
                minHeight={MIN_BLACKBOARD_HEIGHT}
                bounds=".bb-img-parent1"
                size={rndSize}
                position={pos}
                onDragStop={onDragStop}
                onResizeStop={onResizeStop}
                enableResizing={enableResizing}
                lockAspectRatio
                onResize={onResize}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 9px",
                }}
              >
                {blackboardTemplateDetail?.id && (
                  <BlackboardTemplateImage
                    blackboardTemplateProps={{
                      ...blackboardTemplateProps,
                      isOnlyView: true,
                      isShowShootingTimeTemp: true,
                    }}
                    blackboardData={{
                      ...blackBoard,
                      shootingTime: !!documentSubItem?.images?.src
                        ? blackBoard?.shootingTime
                        : undefined,
                    }}
                    data={blackboardTemplateDetail}
                    contentType={ContentType.BLACKBOARD_TEMPLATE}
                    pointerEvents="none"
                  />
                )}
              </Rnd>
            )}
          </Box>
        </Flex>
        <Flex alignItems={"center"} justifyContent={"center"} mt="3.6rem">
          <Flex ml="auto" justifyContent="right" height="4.8rem" gap="0.8rem">
            <Button onClick={onClose}>キャンセル</Button>
            <Button
              variant="filled"
              isDisabled={isLoadedImage}
              onClick={onSelectPosition}
            >
              選択
            </Button>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default memo(EditPositionBlackBoardModal);

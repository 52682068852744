import { wait } from "./common";
import { getCacheProjectById } from "./indexedDb";

export let cacheAbortController = new AbortController();

export const DEFAULT_NUM_RETRY = 3;

export const renewCacheAbortController = () => {
  cacheAbortController = new AbortController();
};

export async function retryFetch(
  url: string,
  options?: RequestInit,
  retries: number = DEFAULT_NUM_RETRY,
  bimFileId?: string,
  shouldCache = true
): Promise<Response | undefined> {
  if (bimFileId) {
    const cache = await getCacheProjectById(bimFileId);
    if (cache?.isPause) {
      return;
    }
  }

  try {
    const response = await fetchRequest(url, options, shouldCache);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response;
  } catch (error) {
    if (retries > 0) {
      /* eslint-disable-next-line no-console */
      console.warn(`Failed to fetch ${url}. Retrying in 1 second...`);
      await wait(1000);

      return retryFetch(url, options, retries - 1);
    }
    throw error;
  }
}

export const fetchRequest = async (
  url: string,
  options?: RequestInit,
  shouldCache = false
) => {
  const optionRequest = options || {};
  if (shouldCache) {
    optionRequest.signal = cacheAbortController.signal;
  }

  return await fetch(url, optionRequest);
};

import { TaskDTO } from "interfaces/dtos/taskDTO";
import { ApiResponse } from "interfaces/models/api";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";
import { taskCommentApi } from ".";
import { presignedAndDownloadFileS3 } from "utils/file";

export interface GetTasksReq {
  bimFileId?: string;
  level?: string;
  userAssigned?: string;
  autoPreSignUrl?: boolean;
  shouldCache?: boolean;
}
const path = "/v2/tasks";

const getTaskList = async (
  params: GetTasksReq
): Promise<ApiResponse<TaskDTO[]>> => {
  const { bimFileId, userAssigned, level, shouldCache } = params;

  const _params: GetTasksReq = {
    bimFileId: encodeURIComponent(bimFileId || ""),
    level,
    userAssigned,
    shouldCache,
  };

  return axiosECS.get(path, {
    params: _params,
  });
};

export const getTask = async (
  taskId: string
): Promise<ApiResponse<TaskDTO>> => {
  const result = await axiosECS.get(`${path}/${taskId}`, {
    params: {
      // not use cache's data in service worker
      useCache: false,
    },
  });

  // case service worker return response type array
  if (Array.isArray(result.data)) {
    result.data = result.data?.[0];
  }

  return result;
};

export const createTask = async (
  task: Partial<TaskDTO>
): Promise<ApiResponse<TaskDTO>> => {
  return axiosECS.post(path, validateBodyReq(task));
};

export const updateTask = async (
  task: TaskDTO
): Promise<ApiResponse<TaskDTO>> => {
  const body = structuredClone(task);
  body.updatedAt = body.updatedAt || new Date();

  return axiosECS.patch(path, validateBodyReq(body));
};

export const deleteTaskList = (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids } });
};

export const handleGetTasks = async (params: GetTasksReq) => {
  const { level, shouldCache, userAssigned } = params;
  const { data: res } = await getTaskList(params);
  const requests: Promise<any>[] = [];
  if (shouldCache) {
    for (let i = 0; i < res.length; i++) {
      res[i].images
        ?.filter((image) => image && !image.src.includes("data:image/"))
        ?.forEach((image) =>
          requests.push(presignedAndDownloadFileS3(image.src, shouldCache))
        );
      res[i].confirmedImages
        ?.filter((image) => image && !image.src.includes("data:image/"))
        ?.forEach((image) =>
          requests.push(presignedAndDownloadFileS3(image.src, shouldCache))
        );
      res[i].attaches?.forEach((file) => {
        try {
          const src = (file || {})?.src;
          if (!!src) {
            requests.push(presignedAndDownloadFileS3(src, shouldCache));
          }
        } catch (e: any) {
          /* eslint-disable-next-line no-console */
          console.log("An error occurred while getTasks ", e);
        }
      });
      requests.push(
        taskCommentApi
          .handleGetAllTaskComments({ taskId: res[i].id! }, shouldCache)
          .catch((err) => {
            if (shouldCache) {
              throw new Error(err.message);
            }

            return;
          })
      );
    }
    await Promise.all(requests);
  }

  return res.filter(
    (task) =>
      (!level || task.level === level) &&
      (!userAssigned || task.userAssigned?.includes(userAssigned))
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bimFileApi, forgeApi } from "apiClient/v2";
import { REDUCER_KEY } from "constants/redux";
import { BimFile } from "interfaces/models/bim";
import { formatDate } from "utils/date";

export interface PhotoState {
  bimList: any[];
  isFetchingBim: boolean;
  isCreateProject: boolean;
  bimFileList: BimFile[];
  isFetchingBimFileList: boolean;
}

const initialState: PhotoState = {
  bimList: [],
  isFetchingBim: false,
  isCreateProject: false,
  bimFileList: [],
  isFetchingBimFileList: false,
};

export const fetchDataBimFolders = createAsyncThunk(
  "bim/fetchDataFolders",
  async (
    {
      projectId,
      folderId,
      isLoadList = true,
    }: { projectId: string; folderId: string; isLoadList?: boolean },
    thunkAPI
  ) => {
    const { data: res } = await forgeApi.getFolderById({ projectId, folderId });

    const newChildren: any[] = [];
    const newDetail: any[] = [];
    res
      .sort((a, b) => a?.displayName.localeCompare(b?.displayName))
      .forEach((i: any) => {
        if (i.type === "folders") return newChildren.push(i);
        if (i.type === "items" || i.type === "geometry")
          return newDetail.push(i);
      });
    const formatData = newDetail.reduce(
      (init, c) => [
        ...init,
        ...(c?.versions || []).map((d: any) => ({
          ...d,
          explanation: "",
          version: `V${d.versionNumber}`,
          size: d.storageSize,
          lastModifiedTime: formatDate(d.lastModifiedTime),
        })),
      ],
      []
    );
    thunkAPI.dispatch(
      bimSlice.actions.setBimList(isLoadList ? formatData : [])
    );

    return newChildren;
  }
);

export const fetchBimListByProject = createAsyncThunk(
  "bim/fetchBimListByProject",
  async ({
    projectId,
    searchValue,
    isAllVersion,
  }: {
    projectId: string;
    searchValue: any;
    isAllVersion?: boolean;
  }) => {
    const { data: res } = await forgeApi.getBimList({
      projectId,
      searchValue,
      isAllVersion,
    });

    const formatData = res?.reduce(
      (init: any, c: any) => [
        ...init,
        ...(c?.versions || []).map((d: any) => ({
          ...d,
          explanation: "",
          version: `V${d.versionNumber}`,
          size: d.storageSize,
          lastModifiedTime: formatDate(d.lastModifiedTime),
        })),
      ],
      []
    );

    return formatData;
  }
);

export const fetchFirstBim = createAsyncThunk(
  "bim/fetchFirstBim",
  async (projectId?: string) => {
    const { data: res } = await forgeApi.getFirstBim({
      projectId: projectId || "",
    });

    return res;
  }
);

export const fetchBimFileList = createAsyncThunk(
  "bim/fetchBimFileList",
  async () => {
    const { data: res } = await bimFileApi.getProjectList();

    return res ?? [];
  }
);

export const bimSlice = createSlice({
  name: REDUCER_KEY.BIM,
  initialState,
  reducers: {
    setBimList: (state, action: PayloadAction<any>) => {
      state.bimList = Array.isArray(action.payload) ? action.payload : [];
    },
    setIsCreateProject: (state, action: PayloadAction<boolean>) => {
      state.isCreateProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataBimFolders.pending, (state, action) => {
      state.isFetchingBim = true;
    });
    builder.addCase(fetchDataBimFolders.fulfilled, (state, action) => {
      state.isFetchingBim = false;
    });
    builder.addCase(fetchDataBimFolders.rejected, (state, action) => {
      state.isFetchingBim = false;
    });
    builder.addCase(fetchBimListByProject.pending, (state, action) => {
      state.isFetchingBim = true;
    });
    builder.addCase(fetchBimListByProject.fulfilled, (state, action) => {
      state.isFetchingBim = false;
    });
    builder.addCase(fetchBimListByProject.rejected, (state, action) => {
      state.isFetchingBim = false;
    });
    builder.addCase(fetchBimFileList.pending, (state, action) => {
      state.isFetchingBim = true;
    });
    builder.addCase(fetchBimFileList.fulfilled, (state, action) => {
      state.bimFileList = action.payload;
      state.isFetchingBim = false;
    });
    builder.addCase(fetchBimFileList.rejected, (state, action) => {
      state.isFetchingBim = false;
    });
  },
});

export const { setBimList, setIsCreateProject } = bimSlice.actions;

export default bimSlice.reducer;

import {
  checkTableFullWidth,
  checkTableOverlapRightSide,
  getDisplaySizeScale,
  getMaxWidthTable,
} from "components/editor-builder/component-preview/TableComponentPreview/utils/tableStyle";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { TemplateComponent } from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { RootState } from "redux/store";
import { PageInfo } from "../type";
import ContentTdTypeCommon from "./ContentTdTypeCommon";
import ContentTdTypeLinkedImage from "./ContentTdTypeLinkedImage";
import ContentTdTypePhotoLedger from "./ContentTdTypePhotoLedger";

export interface BaseStructureTableProps {
  component: TemplateComponent | undefined;
  template: DocumentTemplate | undefined;
  page: PageInfo | undefined;
  documentItems: DocumentItem[];
  documentContainerSize:
    | RootState["document"]["documentContainerSize"]
    | undefined;
  zoomRatio: number;
  familyInstances: { [key: string]: FamilyInstanceDTO };
  documentItem: DocumentItem | undefined;
  documentCategory: DocumentCategoryDTO | undefined;

  blackboardTemplateProps: iBlackboardTemplateProps | undefined;
  listAllUserById: { [key: string]: UserDTO };
  projectName: string;
  companiesById: Record<string, PartnerCompany>;
  mapTitleAreaById: Record<string, string>;
}

class BaseStructureTable {
  page: PageInfo | undefined = undefined;
  template: DocumentTemplate | undefined = undefined;
  component: TemplateComponent | undefined = undefined;
  documentItems: DocumentItem[] = [];
  documentContainerSize:
    | RootState["document"]["documentContainerSize"]
    | undefined = undefined;
  zoomRatio: number = 1;
  blackboardTemplateProps: iBlackboardTemplateProps | undefined = {};
  listAllUserById: { [key: string]: UserDTO } = {};
  projectName: string = "";
  familyInstances: { [key: string]: FamilyInstanceDTO } = {};
  documentItem: DocumentItem | undefined = undefined;
  documentCategory: DocumentCategoryDTO | undefined = undefined;
  companiesById: Record<string, PartnerCompany> = {};
  mapTitleAreaById: Record<string, string> = {};

  protected displaySizeWidth: number = 0;
  protected displaySizeHeight: number = 0;
  protected isOverlapRightSide: boolean = false;
  protected rowIndexForLinkedImage: {
    documentDataIndex: number;
    imageIndex: number;
  } = { documentDataIndex: 0, imageIndex: 0 };

  constructor(params: BaseStructureTableProps) {
    this.documentItems = params.documentItems;
    const page = (this.page = params.page);
    const template = (this.template = params.template);
    const component = (this.component = params.component);
    const documentContainerSize = (this.documentContainerSize =
      params.documentContainerSize);
    const zoomRatio = (this.zoomRatio = params.zoomRatio);

    if (!template || !component || !documentContainerSize || !page) {
      return;
    }

    const maxWidthTable = getMaxWidthTable({
      templatePages: template?.pages || [],
      componentPage: component.page,
    });
    const isFullWidth = checkTableFullWidth({
      maxWidth: maxWidthTable,
      resizeWidth: component?.realSize?.width,
      width: component?.size?.width,
    });
    this.isOverlapRightSide = checkTableOverlapRightSide({
      components: template?.components || [],
      isFullWidth,
      component,
      documentContainerSize,
      zoomRatio,
    });

    const displaySizeScale = getDisplaySizeScale(page);
    this.displaySizeHeight = component?.size.height * displaySizeScale.displayY;
    this.displaySizeWidth = component?.size.width * displaySizeScale.displayX;
    this.blackboardTemplateProps = params.blackboardTemplateProps;
    this.listAllUserById = params.listAllUserById;
    this.projectName = params.projectName;
    this.familyInstances = params.familyInstances;
    this.documentItem = params.documentItem;
    this.documentCategory = params.documentCategory;
    this.companiesById = params.companiesById;
    this.mapTitleAreaById = params.mapTitleAreaById;

    // reset row index for linked image component when start new page
    this.rowIndexForLinkedImage = { documentDataIndex: 0, imageIndex: 0 };
  }
}

export default BaseStructureTable;

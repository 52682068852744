import { Box, Flex, FlexProps, Image, Text } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { PaperDirectionType } from "constants/enum";
import { errorImagePath } from "constants/file";
import { boxContentOfTaskForPhotoBooks } from "constants/styleProps";
import useUserOfProject from "hooks/useUserOfProject";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTaskDataFieldPhotoBooks } from "interfaces/models/documentTask";
import { TaskType } from "interfaces/models/taskType";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { DEFAULT_FORMAT_DATE_DISPLAY, formatDate } from "utils/date";
import ContentPhotoBooksInstructions from "./ContentPhotoBooksInstructions";
import ContentPhotoBooksReport from "./ContentPhotoBooksReport";
import useGetPhotoBookContentInstruction from "./hooks/useGetPhotoBookContentInstruction";
import { iHandleDocumentTaskDetail } from "./hooks/usePreviewTaskSheetTemplate";
import LoadingImageOfPhotoBooks from "./LoadingImageOfPhotoBooks";
import PhotoBooksHeader from "./PhotoBooksHeader";

const ImagePresigned = withPresignedUrl(Image);

export interface TaskPhotoBooksReport extends TaskDTO {
  isShowHeader?: boolean;
  isShowImage?: boolean;
  isShowConfirmedImage?: boolean;
  partnerCompanyName?: string;
  photoBookInstructionContentKey?: string;
  photoBookInstructionContentEdit?: DocumentTaskDataFieldPhotoBooks;
  photoBookReportContentKey?: string;
  photoBookReportContentEdit?: DocumentTaskDataFieldPhotoBooks;
}

interface Props {
  task: TaskPhotoBooksReport;
  taskTypes: TaskType[];
  contentTypeChanged?: string;
  taskImageHeight?: number;
  pageId?: string;
  editMode: boolean;
  onEditPhotoBooksContent?: iHandleDocumentTaskDetail["onEditPhotoBooksContent"];
  isLastItem?: boolean;
  maxHeightContentPhotoBooks: number;
  pageDirection?: PaperDirectionType;
}

export enum IMAGE_KEYS {
  TASK_IMAGE_CONFIRMED,
  TASK_IMAGE,
}

const FIELD_NAME_REPORTS = {
  CONFIREMED_CONTENT: "是正内容",
  END_DATE_SCHEDULED: "対応完了日",
  CONFIRMED_DATETIME: "高砂確認日",
  USER_CONFIRMED: "高砂確認者",
};

export const MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_VERTICAL = 130;
export const MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_HORIZONTAL = 100;
export const PHOTO_BOOKS_CONTAINER_PADDING_BOTTOM = 15;

const PhotoBooksReportItem = ({
  task,
  taskImageHeight = 230,
  taskTypes,
  editMode,
  isLastItem = false,
  maxHeightContentPhotoBooks,
  onEditPhotoBooksContent,
}: Props) => {
  const [mapStatusAllImage, setMapStatusAllImage] = useState<{
    [key: string]: boolean;
  }>({});
  const { listUserById } = useUserOfProject();

  const boxContentStyle: FlexProps = useMemo(
    () => ({
      ...boxContentOfTaskForPhotoBooks,
      maxH: `${maxHeightContentPhotoBooks}px`,
      minH: `${maxHeightContentPhotoBooks}px`,
      w: "100%",
      flex: 1,
      borderTopStyle: "hidden",
    }),
    [maxHeightContentPhotoBooks]
  );

  const contentPhotoBookInstruction = useGetPhotoBookContentInstruction({
    taskTypes,
    listUserById,
    task,
    photoBookContentEdit: task?.photoBookInstructionContentEdit,
  });

  const onErrorTaskImage = (e: any) => {
    const target = (e as any).target as HTMLImageElement;
    target.src = errorImagePath;
    target.setAttribute("data-error", "true");
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE]: true,
    }));
  };

  const onLoadTaskImage = () => {
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE]: true,
    }));
  };

  const onLoadImageConfirmed = () => {
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE_CONFIRMED]: true,
    }));
  };

  const onErrorImageConfirmed = (e: any) => {
    (e as any).target.src = errorImagePath;
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE_CONFIRMED]: true,
    }));
  };

  const renderInstructionItem = () => {
    if (!task.isShowImage) {
      return <></>;
    }

    return (
      <>
        {!!task?.images?.length && (
          <LoadingImageOfPhotoBooks
            isLoading={!mapStatusAllImage?.[IMAGE_KEYS.TASK_IMAGE]}
          />
        )}
        <Flex
          h={`${taskImageHeight}px`}
          bgColor="rgb(218 218 218 / 9%)"
          border="1px solid #1A1A1A"
          justifyContent="center"
        >
          {task?.images?.length ? (
            <>
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <ImagePresigned
                  src={task?.images[0].src}
                  alt=""
                  crossOrigin="anonymous"
                  className="image-item"
                  display="block"
                  maxH={`${taskImageHeight - 4}px`}
                  boxLoadingHeight={`${taskImageHeight - 4}px`}
                  isConvertToBase64={isMobile}
                  onLoad={onLoadTaskImage}
                  onError={onErrorTaskImage}
                />
              </Flex>
            </>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              h={`${taskImageHeight}px`}
              width="100%"
              textAlign="center"
            >
              <Text>写真なし</Text>
            </Flex>
          )}
        </Flex>
        <Flex {...boxContentStyle}>
          <ContentPhotoBooksInstructions
            editMode={editMode}
            editDataStyle={
              task?.photoBookInstructionContentEdit?.instructionStyle
            }
            {...contentPhotoBookInstruction}
            photoBookContentKey={task?.photoBookInstructionContentKey}
            onEditPhotoBooksContent={onEditPhotoBooksContent}
          />
        </Flex>
      </>
    );
  };

  const renderReportItem = () => {
    if (!task.isShowConfirmedImage) {
      return <></>;
    }

    const photoBookReportContentEdit = task?.photoBookReportContentEdit;

    const confiremedContent =
      photoBookReportContentEdit?.confirmedMemo?.editValue ??
      `${FIELD_NAME_REPORTS.CONFIREMED_CONTENT}: ${task?.confirmedMemo || "-"}`;
    const endDateScheduled =
      photoBookReportContentEdit?.endDateScheduled?.editValue ??
      `${FIELD_NAME_REPORTS.END_DATE_SCHEDULED}: ${
        task?.endDateScheduled
          ? formatDate(task.endDateScheduled, DEFAULT_FORMAT_DATE_DISPLAY)
          : "-"
      }`;
    const confirmedDateTime =
      photoBookReportContentEdit?.confirmedDateTime?.editValue ??
      `${FIELD_NAME_REPORTS.CONFIRMED_DATETIME}: ${
        task?.confirmedDateTime
          ? formatDate(task.confirmedDateTime, DEFAULT_FORMAT_DATE_DISPLAY)
          : "-"
      }`;
    const userConfirmed =
      photoBookReportContentEdit?.userConfirmed?.editValue ??
      `${FIELD_NAME_REPORTS.USER_CONFIRMED}: ${
        listUserById?.[task?.userConfirmed || ""]?.name || "-"
      }`;

    return (
      <>
        {!!task?.confirmedImages?.length && (
          <LoadingImageOfPhotoBooks
            isLoading={!mapStatusAllImage?.[IMAGE_KEYS.TASK_IMAGE_CONFIRMED]}
          />
        )}
        <Flex
          h={`${taskImageHeight}px`}
          bgColor="rgb(218 218 218 / 9%)"
          border="1px solid #1A1A1A"
          justifyContent="center"
        >
          {task?.confirmedImages?.length ? (
            <>
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <ImagePresigned
                  src={task?.confirmedImages[0].src}
                  alt=""
                  crossOrigin="anonymous"
                  className="image-item"
                  display="block"
                  maxH={`${taskImageHeight - 4}px`}
                  boxLoadingHeight={`${taskImageHeight - 4}px`}
                  isConvertToBase64={isMobile}
                  onLoad={onLoadImageConfirmed}
                  onError={onErrorImageConfirmed}
                />
              </Flex>
            </>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              h={`${taskImageHeight}px`}
              width="100%"
              textAlign="center"
            >
              <Text>写真なし</Text>
            </Flex>
          )}
        </Flex>
        <Flex {...boxContentStyle}>
          <ContentPhotoBooksReport
            editMode={editMode}
            task={task}
            editDataStyle={photoBookReportContentEdit?.reportStyle}
            confirmedMemo={confiremedContent}
            endDateScheduled={endDateScheduled}
            confirmedDateTime={confirmedDateTime}
            userConfirmed={userConfirmed}
            photoBookContentKey={task?.photoBookReportContentKey}
            onEditPhotoBooksContent={onEditPhotoBooksContent}
          />
        </Flex>
      </>
    );
  };

  return (
    <Box
      w="100%"
      pb={`${!isLastItem ? PHOTO_BOOKS_CONTAINER_PADDING_BOTTOM : 0}px`}
      className="task-item-container"
      overflow="hidden"
    >
      {task.isShowHeader && <PhotoBooksHeader task={task} />}

      <Flex w="100%">
        <Flex
          position="relative"
          flexDir="column"
          w="calc(50% - 8px)"
          mr="16px"
        >
          {renderInstructionItem()}
        </Flex>

        <Flex position="relative" flexDir="column" w="calc(50% - 8px)">
          {renderReportItem()}
        </Flex>
      </Flex>
    </Box>
  );
};

export default React.memo(PhotoBooksReportItem);

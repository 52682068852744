import {
  DATA_STORE,
  FILE_STORE,
  NETWORK_STORE,
  NETWORK_STORE_KEY,
  PROJECT_DATA_CACHE_PROGRESS_STORE,
  PROJECT_CACHE_INFO,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { iCachedProject } from "hooks/useCheckProjectCached";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { logError, logWarn } from "./logs";

export const INDEXED_DB_NAME = "neptune-indexed-db";

export const VERSION_INDEX_DB = 4;

export type IndexedDBType = IndexedDb;

class IndexedDb {
  private db: IDBDatabase | undefined;

  async openConnection(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(INDEXED_DB_NAME, VERSION_INDEX_DB);

      request.onerror = () => {
        reject("Failed to open indexed database");
      };

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        this.db = (event.target as IDBOpenDBRequest).result;

        if (
          !this.db.objectStoreNames.contains(PROJECT_DATA_CACHE_PROGRESS_STORE)
        ) {
          this.db.createObjectStore(PROJECT_DATA_CACHE_PROGRESS_STORE, {
            keyPath: "id",
          });
        }

        if (!this.db.objectStoreNames.contains(FILE_STORE)) {
          this.db.createObjectStore(FILE_STORE, { keyPath: "id" });
        }

        if (!this.db.objectStoreNames.contains(NETWORK_STORE)) {
          this.db.createObjectStore(NETWORK_STORE, { keyPath: "id" });
        }
        if (!this.db.objectStoreNames.contains(PROJECT_CACHE_INFO)) {
          this.db.createObjectStore(PROJECT_CACHE_INFO, { keyPath: "id" });
        }

        if (!this.db.objectStoreNames.contains(DATA_STORE)) {
          const store = this.db.createObjectStore(DATA_STORE, {
            keyPath: "id",
          });
          store.createIndex("store", "store", { unique: false });
        }
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        this.db.onversionchange = function (event) {
          (event.target as any)?.close();
        };
        resolve();
      };
    });
  }

  closeConnection() {
    this.db?.close();
  }

  emptyStore(storeName: string) {
    if (!this.db) {
      return;
    }
    try {
      const request = this.db
        ?.transaction(storeName, "readwrite")
        ?.objectStore(storeName)
        .clear();

      request.onsuccess = () => {
        /* eslint-disable-next-line no-console */
        console.log(`Object Store "${storeName}" emptied`);
      };

      request.onerror = () => {
        /* eslint-disable-next-line no-console */
        console.error(`Error to empty Object Store: ${storeName}`);
      };
    } catch (err) {
      logWarn(err);
    }
  }

  // will add a new record to the object store only if a record with the same key doesn't already exist
  async add(id: string, data: any): Promise<void> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(DATA_STORE, "readwrite");
      const store = transaction.objectStore(DATA_STORE);
      store.add({ id, ...data });
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });
    } catch (err: any) {
      logError(err.message);

      return;
    }
  }

  // will add a new record to the object store if a record with the same key doesn't already exist, or update an existing record if a record with the same key already exists
  async put(id: string, data: any, storeName = DATA_STORE): Promise<boolean> {
    if (!this.db) {
      return false;
    }
    try {
      const transaction = this.db.transaction(storeName, "readwrite");
      const store = transaction.objectStore(storeName);
      store.put({ id, ...data });
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return true;
    } catch (err: any) {
      logError(err.message);

      return false;
    }
  }

  async changeNetworkStatus(isOnline: boolean): Promise<boolean> {
    if (!this.db) {
      return false;
    }
    try {
      const transaction = this.db.transaction(NETWORK_STORE, "readwrite");
      const store = transaction.objectStore(NETWORK_STORE);
      store.put({ id: NETWORK_STORE_KEY, isOnline });
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return true;
    } catch (err: any) {
      logError(err.message);

      return false;
    }
  }

  async putFile(id: string, data: any): Promise<boolean> {
    if (!this.db) {
      return false;
    }
    try {
      const transaction = this.db.transaction(FILE_STORE, "readwrite");
      const store = transaction.objectStore(FILE_STORE);
      store.put({ id, ...data });
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return true;
    } catch (err: any) {
      logError(err.message);

      return false;
    }
  }

  async putProjectProgress(id: string, data: any): Promise<boolean> {
    if (!this.db) {
      return false;
    }
    try {
      const transaction = this.db.transaction(
        PROJECT_DATA_CACHE_PROGRESS_STORE,
        "readwrite"
      );
      const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
      store.put({ id: id, ...data });
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return true;
    } catch (err: any) {
      logError(err.message);

      return false;
    }
  }

  async get(
    id: string,
    storeName = DATA_STORE
  ): Promise<iCachedItem | undefined> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.get(id);

      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return request.result;
    } catch (err: any) {
      logError(err.message);

      return;
    }
  }

  async getAll(storeName = DATA_STORE, subStore?: string): Promise<any> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      let request;
      if (subStore) {
        request = store.index("store").getAll(subStore);
      } else {
        request = store.getAll();
      }
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return request.result;
    } catch (err: any) {
      logError(err.message);

      return [];
    }
  }

  async getNetworkStatusFromIndexedDB(): Promise<any> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(NETWORK_STORE, "readonly");
      const store = transaction.objectStore(NETWORK_STORE);
      const request = store.get(NETWORK_STORE_KEY);

      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return request?.result?.isOnline;
    } catch (err: any) {
      logError(err.message);

      // case first time access page or case clear cache page , network always is true
      return true;
    }
  }

  async getProjectDataCacheProgress(): Promise<any> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(
        PROJECT_DATA_CACHE_PROGRESS_STORE,
        "readonly"
      );
      const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
      const request = store.getAll();
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return request.result;
    } catch (err: any) {
      logError(err.message);

      return [];
    }
  }

  async delete(id: string, storeName = DATA_STORE): Promise<void> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(storeName, "readwrite");
      const store = transaction.objectStore(storeName);
      store.delete(id);
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });
    } catch (err: any) {
      logError(err.message);
    }
  }

  async deleteFile(id: string): Promise<void> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(FILE_STORE, "readwrite");
      const store = transaction.objectStore(FILE_STORE);
      store.delete(id);
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });
    } catch (err: any) {
      logError(err.message);
    }
  }

  async deleteList(ids: string[]): Promise<number> {
    if (!this.db) {
      return 0;
    }
    try {
      const transaction = this.db.transaction(DATA_STORE, "readwrite");
      const store = transaction.objectStore(DATA_STORE);
      for (const id of ids) {
        const request = store.get(id);
        request.onsuccess = () => {
          const item = request.result;

          if (item) {
            store.delete(id);
          }
        };
        request.onerror = () => {};
      }
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return ids.length;
    } catch (err: any) {
      logError(err.message);

      return 0;
    }
  }

  async deleteProjectProgressByIds(ids: string[]): Promise<number> {
    if (!this.db) {
      return 0;
    }
    try {
      const transaction = this.db.transaction(
        PROJECT_DATA_CACHE_PROGRESS_STORE,
        "readwrite"
      );
      const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
      const request = store.getAll();
      if (!request) {
        return 0;
      }
      await new Promise((resolve, reject) => {
        request!.onsuccess = (ev) => {
          resolve(ev);
        };
        request.onerror = (err) => {
          reject(err);
        };
      });

      for (const id of ids) {
        const request = store.get(id);
        request.onsuccess = () => {
          const item = request.result;

          if (item) {
            store.delete(id);
          }
        };
        request.onerror = () => {};
      }
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return ids.length;
    } catch (err: any) {
      logError(err.message);

      return 0;
    }
  }

  async deleteProjectProgress(projectId: string): Promise<number> {
    try {
      if (!this.db) {
        return 0;
      }
      const transaction = this.db.transaction(
        PROJECT_DATA_CACHE_PROGRESS_STORE,
        "readwrite"
      );
      const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
      const request = store.getAll();
      if (!request) {
        return 0;
      }
      const response: any = await new Promise((resolve, reject) => {
        request!.onsuccess = (ev) => {
          resolve(ev);
        };
        request.onerror = (err) => {
          reject(err);
        };
      });

      const ids = response.target.result
        .filter((item: any) => item.projectId === projectId)
        .map((item: any) => item.id);
      for (const id of ids) {
        const request = store.get(id);
        request.onsuccess = () => {
          const item = request.result;

          if (item) {
            store.delete(id);
          }
        };
        request.onerror = () => {};
      }
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return ids.length;
    } catch (err: any) {
      logError(err.message);

      return 0;
    }
  }

  async clearCacheProgress(): Promise<number> {
    if (!this.db) {
      return 0;
    }
    try {
      const transaction = this.db.transaction(
        PROJECT_DATA_CACHE_PROGRESS_STORE,
        "readwrite"
      );
      const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
      const request = store.getAll();
      if (!request) {
        return 0;
      }
      const response: any = await new Promise((resolve, reject) => {
        request!.onsuccess = (ev) => {
          resolve(ev);
        };
        request.onerror = (err) => {
          reject(err);
        };
      });

      const ids = response.target.result.map((item: any) => item.id);
      for (const id of ids) {
        const request = store.get(id);
        request.onsuccess = () => {
          const item = request.result;

          if (item) {
            store.delete(id);
          }
        };
        request.onerror = () => {};
      }
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return ids.length;
    } catch (err: any) {
      logError(err.message);

      return 0;
    }
  }

  async getTableSize(tableName: string): Promise<number> {
    if (!this.db) {
      return 0;
    }
    let size = 0;
    const transaction = this.db
      .transaction([tableName])
      .objectStore(tableName)
      .openCursor();

    return await new Promise<number>((resolve: any, reject: any) => {
      transaction.onsuccess = function (event: any) {
        const cursor = event.target.result;
        if (cursor) {
          const storedObject = cursor.value;
          if (storedObject.status !== UpdateToOnlineStatus.Success) {
            const json = JSON.stringify(storedObject);
            size += json.length;
          }
          if (tableName === FILE_STORE) {
            size += storedObject.data.size || 0;
          }
          cursor.continue();
        } else {
          resolve(size);
        }
      };
      transaction.onerror = function (err) {
        reject(`error in ${tableName}: ${err}`);
      };
    });
  }

  isOpen() {
    return !!this.db;
  }
}

export let _indexedDb: IndexedDb | undefined = undefined;

export const clearAllIndexDbStore = async () => {
  const indexDb = await getIndexedDb();
  if (!indexDb) {
    return;
  }
  await Promise.all(
    [
      FILE_STORE,
      DATA_STORE,
      PROJECT_DATA_CACHE_PROGRESS_STORE,
      PROJECT_CACHE_INFO,
    ].map((storeName) => {
      return indexDb.emptyStore(storeName);
    })
  );
};

export const getIndexedDb = async () => {
  if (!_indexedDb) {
    _indexedDb = new IndexedDb();
  }

  if (!_indexedDb.isOpen()) {
    await _indexedDb.openConnection();
  }

  return _indexedDb;
};

export const getDatabaseSize = async () => {
  const indexedDb = await getIndexedDb();
  if (!indexedDb) {
    return 0;
  }
  const tableNames = [FILE_STORE, DATA_STORE];

  const tableSizeGetters: Promise<number>[] = tableNames.map(
    async (tableName) => {
      try {
        return await indexedDb!.getTableSize(tableName);
      } catch (err) {
        return 0;
      }
    }
  );

  return await Promise.all(tableSizeGetters).then((sizes) => {
    return sizes.reduce(function (acc, val) {
      return acc + val;
    }, 0);
  });
};

export const changeNetworkStatusInIndexedDb = async (status: boolean) => {
  const indexedDb = await getIndexedDb();
  indexedDb.changeNetworkStatus(status);
};

export const checkNetworkStatus = async (): Promise<boolean> => {
  if (!navigator.onLine) {
    return false;
  }
  try {
    const indexedDB = await getIndexedDb();
    const networkStatus = await indexedDB?.getNetworkStatusFromIndexedDB();

    return networkStatus ?? navigator.onLine;
  } catch (err) {
    return navigator.onLine;
  }
};
export const getCacheProjectById = async (id: string) => {
  try {
    const indexedDb = await getIndexedDb();
    const cache = await indexedDb?.get(id, PROJECT_CACHE_INFO);

    return cache as iCachedProject | undefined;
  } catch (err) {
    return undefined;
  }
};

export const getAllProjectCacheInfo = async () => {
  const indexedDb = await getIndexedDb();
  const caches = await indexedDb?.getAll(PROJECT_CACHE_INFO);

  return caches || [];
};

import { MessageType } from "constants/websocket";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useEffect } from "react";
import useDocumentLogs from "hooks/useDocumentLogs";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { addOrUpdateLog } from "models/dataLog";

interface Props {
  subItemId?: string;
  documentTemplate: DocumentTemplateDTO | undefined;
}

const useSubItemLogs = (props?: Props) => {
  const { documentTemplate, subItemId } = props || {};

  const { webSocketMessages, sendWebSocketMessage } = useForgeViewerContext();

  const {
    mapDynamicFieldTitle,
    dataLogs: subItemLogs,
    isLoadMoreLogs,
    setDataLogs: setSubItemLogs,
    initDataLogs,
    isInit,
  } = useDocumentLogs({
    paramsGetDataLogs: { subItemId },
    documentTemplate,
  });

  useEffect(() => {
    if (!webSocketMessages.length || !isInit) {
      return;
    }

    webSocketMessages.forEach((e) => {
      const { type, subItemId: subItemIdSended, data } = e;
      if (
        type === MessageType.ADD_SUB_ITEM_LOG &&
        subItemIdSended === subItemId
      ) {
        setSubItemLogs(addOrUpdateLog(data));
      } else if (type === MessageType.RELOAD_DOCUMENT) {
        initDataLogs();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages, subItemId]);

  // init data for logs
  useEffect(() => {
    if (!subItemId) {
      return;
    }

    initDataLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItemId]);

  return {
    webSocketMessages,
    subItemLogs,
    setSubItemLogs,
    sendWebSocketMessage,
    mapDynamicFieldTitle,
    isLoadMoreLogs,
  };
};

export default useSubItemLogs;

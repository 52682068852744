import { handleDynamicFieldCategoryContent } from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  DocumentStatusType,
  LinkedDataField,
  MapExtractionInspectionResultType,
  TextPosition,
} from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { CellType } from "interfaces/models/component";
import { formatDate } from "utils/date";
import { NormalTextNodeAttrs } from "../extendsions";
import { CustomNode, NodeType } from "../type";
import { getContentNodeTypeNormalText, getContentTypeCheckbox } from "../utils";
import BaseStructureTable from "./BaseStructureTable";

class ContentTdTypeNormalTable extends BaseStructureTable {
  getContentTdTypeJustifyText(params: {
    text: string;
    style: CellType["style"];
    height: number;
    isSubCell: boolean;
  }) {
    const { text = undefined, style, height, isSubCell } = params;

    const characterStrings = (text?.replace(/ /g, "\u00A0").split("\n") || [])
      .map((string) => string.split(""))
      .flat(1);

    return {
      type: NodeType.JUSTIFY_TEXT,
      attrs: { height },
      content: characterStrings.map((char) =>
        getContentNodeTypeNormalText({
          isMultiple: true,
          isJustify: true,
          text: char,
          style,
          isSubCell,
        })
      ),
    } satisfies CustomNode<NormalTextNodeAttrs>;
  }

  getStructure(params: {
    cell: CellType;
    height: number;
    documentItem: DocumentItemDTO | undefined;
  }) {
    const { cell, height, documentItem } = params;
    const cellProperty = cell?.cellProperty;
    const linkedDataField = cell?.cellLinkedData?.field;

    const isJustify = cell.style?.justifyContent === TextPosition.JUSTIFY;
    switch (cellProperty) {
      case CellProperty.TEXT: {
        if (isJustify) {
          return this.getContentTdTypeJustifyText({
            text: cell.value || "",
            height,
            style: cell.style,
            isSubCell: !!cell?.isSubCell,
          });
        }

        return getContentNodeTypeNormalText({
          text: cell.value,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }

      case CellProperty.CHECK_BOX: {
        return getContentTypeCheckbox({
          cell,
          documentItem,
          documentType: this.template?.documentType,
          component: this.component,
        });
      }

      case CellProperty.DOCUMENT_DATA: {
        if (cell.value || cell.cellLinkedData?.type === CellProperty.TEXT) {
          return getContentNodeTypeNormalText({
            text: cell.value,
            style: cell.style,
            isSubCell: !!cell?.isSubCell,
          });
        }

        const dateFormat =
          cell?.cellLinkedData?.options?.dateFormat || "YYYY/MM/DD";

        switch (linkedDataField) {
          case LinkedDataField.COMMON.DYNAMIC_FIELDS_FOR_CATEGORY: {
            if (cell.cellLinkedData?.type === CellProperty.CHECK_BOX) {
              return getContentTypeCheckbox({
                cell,
                documentItem,
                documentType: this.template?.documentType,
                component: this.component,
              });
            }

            return getContentNodeTypeNormalText({
              text: handleDynamicFieldCategoryContent({
                cell: structuredClone(cell),
                documentCategorySelected: this.documentCategory,
                documentItemSelected: documentItem,
                listUserById: this.listAllUserById,
              })?.value,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_DATE:
          case LinkedDataField.DOCUMENT_CATEGORY.DEADLINE:
          case LinkedDataField.DOCUMENT_CATEGORY.EXECUTE_DATE:
          case LinkedDataField.DOCUMENT_CATEGORY.APPROVE_DATE:
          case LinkedDataField.DOCUMENT_CATEGORY.IMPLEMENT_DATE: {
            return getContentNodeTypeNormalText({
              text: formatDate(
                this.documentCategory?.[linkedDataField],
                dateFormat
              ),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.MEASURE_DATE: {
            const measureDate = this.documentCategory?.measureDate;

            return getContentNodeTypeNormalText({
              text: formatDate(measureDate, dateFormat),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.USER_ASSIGNED: {
            return getContentNodeTypeNormalText({
              text: this.documentCategory?.userAssigned
                ?.map((e) => this.listAllUserById?.[e]?.name)
                ?.join(","),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.USER_MEASURE: {
            return getContentNodeTypeNormalText({
              text:
                this.listAllUserById?.[this.documentCategory?.userMeasure || ""]
                  ?.name || "",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.USER_MANAGE: {
            return getContentNodeTypeNormalText({
              text:
                this.listAllUserById?.[this.documentCategory?.userManage || ""]
                  ?.name || "",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.MANAGER: {
            return getContentNodeTypeNormalText({
              text:
                this.listAllUserById?.[this.documentCategory?.manager || ""]
                  ?.name || "",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.USER_IMPLEMENT: {
            return getContentNodeTypeNormalText({
              text:
                this.listAllUserById?.[this.documentCategory?.userImplement!]
                  ?.name || "",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.CORPORATE_COMPANY: {
            return getContentNodeTypeNormalText({
              text: this.documentCategory?.corporateCompany || "",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.PARTNER_COMPANY: {
            return getContentNodeTypeNormalText({
              text:
                this.companiesById?.[
                  this.documentCategory?.partnerCompany || ""
                ]?.name || "",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.AREA_ID: {
            return getContentNodeTypeNormalText({
              text: this.documentCategory?.allFloor
                ? "すべてのエリア"
                : this.documentCategory?.neptuneAreaIds
                    ?.map((id) => this.mapTitleAreaById[id])
                    .join(","),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.TAGS: {
            return getContentNodeTypeNormalText({
              text: this.documentCategory?.tags?.map((e) => e).join(","),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.PROJECT_NAME: {
            return getContentNodeTypeNormalText({
              text: this.projectName,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_RESULT: {
            return getContentNodeTypeNormalText({
              text: MapExtractionInspectionResultType[
                (this.documentCategory
                  ?.inspectionResult as DocumentStatusType) ||
                  DocumentStatusType.Incomplete
              ],
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_CATEGORY.TEMPLATE_NAME: {
            return getContentNodeTypeNormalText({
              text: this.documentCategory?.title || "",
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          default:
            return getContentNodeTypeNormalText({
              text: (this.documentCategory as any)?.[linkedDataField || ""],
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
        }
      }

      default:
        if (isJustify) {
          return this.getContentTdTypeJustifyText({
            text: cell.value || "",
            height,
            style: cell.style,
            isSubCell: !!cell?.isSubCell,
          });
        }

        return getContentNodeTypeNormalText({
          text: cell.value,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
    }
  }
}

export default ContentTdTypeNormalTable;

import { Attribute, Node } from "@tiptap/core";
import { CheckboxDirectionOptionsEnum } from "constants/enum";
import { NodeType } from "../type";

export interface TaskListNodeAttrs {
  direction: CheckboxDirectionOptionsEnum;
  type: "item" | "opt";
}

export const TaskList = Node.create({
  name: NodeType.TASK_LIST,
  group: "block list",
  content: `(${NodeType.TASK_ITEM} | ${NodeType.TASK_OPT})+`,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: { [key in keyof TaskListNodeAttrs]: Attribute } = {
      direction: { default: "horizontal" },
      type: { default: "item" },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: `ul[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { direction, type } = HTMLAttributes as TaskListNodeAttrs;
    const flexDirection =
      type === "item" && direction === CheckboxDirectionOptionsEnum.HORIZONTAL
        ? "column"
        : "row";
    const flexWrap = type === "opt" ? "wrap" : "nowrap";
    const justifyContent =
      direction === CheckboxDirectionOptionsEnum.HORIZONTAL || type === "opt"
        ? "center"
        : "space-between";

    return [
      "ul",
      {
        "data-type": this.name,
        style:
          `display: flex; gap: 0.1rem; padding: 0 0.5rem; ` +
          `flex-direction: ${flexDirection}; justify-content: ${justifyContent}; ` +
          `flex-wrap: ${flexWrap}; `,
      },
      0,
    ];
  },
});

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconInfo({ color, ...rest }: IconProps) {
  return (
    <Icon
      width="24"
      height="24"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...rest}
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 16v-4m0-4h.01" />
    </Icon>
  );
}

import { MessageType } from "constants/websocket";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentGroupDTO } from "interfaces/dtos/documentGroupDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { TaskComment } from "interfaces/models/task";
import { WSMessage } from "interfaces/models/websocket";

type Props = {
  sendWebSocketMessage(wsMessage: WSMessage): void;
};

export default class SendDataSocketHelper {
  private send!: (wsMessage: WSMessage) => void;

  setParams(params: Props) {
    this.send = params.sendWebSocketMessage;
  }

  changeDocCategory(
    category: DocumentCategoryDTO,
    data: Partial<DocumentCategoryDTO>
  ) {
    return this.changeDocCategories([
      {
        id: category.id,
        level: category.level,
        updatedAt: category.updatedAt,
        ...data,
      },
    ]);
  }

  changeDocCategories(
    categories: Partial<DocumentCategoryDTO>[],
    isNew?: boolean
  ) {
    return this.send({
      type: MessageType.CHANGE_DOCUMENT_CATEGORIES,
      data: {
        categories,
        isNew,
      },
    });
  }

  addDocumentCategoryLog(id: string, log: any) {
    return this.send({
      type: MessageType.ADD_DOCUMENT_CATEGORY_LOG,
      docCategoryId: id,
      data: log,
    });
  }

  changeDocGroup(id: string, data: Partial<DocumentGroupDTO>) {
    return this.send({
      type: MessageType.CHANGE_DOC_GROUP,
      docGroupId: id,
      data: data,
    });
  }

  addDocGroupLog(id: string, log: any) {
    return this.send({
      type: MessageType.ADD_GROUP_LOG,
      docGroupId: id,
      data: log,
    });
  }

  addDocItemLog(docItemId: string, log: any) {
    return this.send({
      type: MessageType.ADD_DOCUMENT_ITEM_LOG,
      docItemId,
      data: log,
    });
  }

  updateDocItem(documentItem: DocumentItemDTO, data: any) {
    return this.send({
      type: MessageType.UPDATE_DOCUMENT_ITEM,
      docItemId: documentItem.id,
      level: documentItem.level,
      data: {
        id: documentItem.id,
        ...data,
      },
    });
  }

  updateSubItem(
    docItem: DocumentItemDTO,
    subItem: DocumentSubItemDTO,
    data: Partial<DocumentSubItemDTO & { isUpdateVisibleItem?: boolean }>
  ) {
    return this.send({
      type: MessageType.UPDATE_SUB_ITEM,
      data: {
        id: subItem?.id,
        itemId: subItem.itemId,
        ...data,
      },
      docCategoryId: docItem.documentCategoryId,
      level: docItem.level,
      docItemId: docItem.id,
    });
  }

  addSubItem(
    subItem: Partial<DocumentSubItemDTO>,
    documentItem: DocumentItemDTO
  ) {
    return this.send({
      type: MessageType.ADD_SUB_ITEM,
      subItemId: subItem.id,
      docItemId: subItem.itemId,
      docCategoryId: documentItem.documentCategoryId,
      level: documentItem.level,
      data: subItem,
    });
  }

  addSubItemLog(level: string, subItemId: string, log: any) {
    return this.send({
      type: MessageType.ADD_SUB_ITEM_LOG,
      level,
      subItemId,
      data: log,
    });
  }

  updateBlackboard(level: string, blackboardId: string, data: any) {
    return this.send({
      type: MessageType.UPDATE_BLACKBOARD,
      level,
      data: {
        id: blackboardId,
        ...data,
      },
    });
  }

  deleteSubItem(documentItem: DocumentItemDTO, subItemId: string) {
    return this.send({
      type: MessageType.DELETE_SUB_ITEM,
      docItemId: documentItem.id,
      docCategoryId: documentItem.documentCategoryId,
      level: documentItem.level,
      subItemId,
      data: {
        isUpdateVisibleItem: true,
      },
    });
  }

  changeVisibleDocItem(
    documentItem: DocumentItemDTO,
    { isVisible, updatedAt }: { isVisible: boolean; updatedAt?: string | Date }
  ) {
    return this.send({
      type: MessageType.CHANGE_VISIBLE_DOCUMENT_ITEM,
      level: documentItem.level,
      data: {
        documentCategoryId: documentItem.documentCategoryId,
        isVisible,
        documentItemId: documentItem.id,
        isUpdateVisibleItem: true,
        updatedAt,
      },
    });
  }

  addTask(data: Partial<TaskDTO> & { level: string }) {
    return this.send({
      type: MessageType.ADD_TASK,
      level: data.level,
      data,
    });
  }

  updateTask(data: Partial<TaskDTO> & { level: string }) {
    return this.send({
      type: MessageType.UPDATE_TASK,
      level: data.level,
      data,
    });
  }

  deleteTask(task: Partial<TaskDTO> & { level: string }) {
    return this.send({
      type: MessageType.DELETE_TASK,
      level: task.level,
      data: { id: task.id },
    });
  }

  changeCurrentDocument(res: DocumentDTO) {
    return this.send({
      type: MessageType.CHANGE_CURRENT_DOCUMENT,
      data: res,
    });
  }

  addTaskComments(taskId: string, filterLogs: any) {
    return this.send({
      taskId: taskId,
      type: MessageType.ADD_TASK_COMMENTS,
      data: filterLogs,
    });
  }

  updateTaskComment(taskId: string, comment: TaskComment) {
    return this.send({
      type: MessageType.UPDATE_TASK_COMMENT,
      taskId,
      data: comment,
    });
  }

  addDocGroup(documentGroup: DocumentGroupDTO) {
    return this.send({
      type: MessageType.ADD_DOC_GROUP,
      data: documentGroup,
    });
  }

  deleteDocItems(level: string, deletes: string[]) {
    return this.send({
      type: MessageType.DELETE_DOCUMENT_ITEMS,
      level,
      data: deletes,
    });
  }

  addDocItems(level: string, documentItems: DocumentItemDTO[]) {
    return this.send({
      type: MessageType.ADD_DOCUMENT_ITEM,
      level: level,
      data: {
        documentItems,
      },
    });
  }

  updateTaskByDocumentItem(level: string, data: any) {
    return this.send({
      type: MessageType.UPDATE_TASK_BY_DOCUMENT_ITEM_ID,
      level,
      data,
    });
  }

  deleteDocCategory(level: string, id: string) {
    return this.send({
      type: MessageType.DELETE_DOCUMENT_CATEGORY,
      level: level,
      data: id,
    });
  }

  deleteDocGroup(level: string, id: string) {
    return this.send({
      type: MessageType.DELETE_DOC_GROUP,
      level,
      data: id,
    });
  }
}

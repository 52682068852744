import { documentCategoryApi } from "apiClient/v2";
import { DocumentCategoryKey } from "constants/enum";
import { MessageType } from "constants/websocket";
import useDocumentLogs from "hooks/useDocumentLogs";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { Level } from "interfaces/models";
import { DataLog } from "interfaces/models/dataLog";
import isArray from "lodash/isArray";
import {
  addOrUpdateLog,
  GetContentLog,
  insertDataLogToIndexedDb,
  transformMapTitleKey,
} from "models/dataLog";
import { getCategoryFieldNecessaryForOffline } from "models/documentCategory";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentCategory } from "redux/documentSlice";
import { sortArrayByField } from "utils/array";
import { uuid } from "utils/common";
import { transformBodyForCombineData } from "utils/offline";

interface Props {
  documentCategory?: DocumentCategoryDTO;
  currentUserId?: string;
  isLoadingDocument: boolean;
  levels: Level[];
  documentTemplate: DocumentTemplateDTO | undefined;
}

const useDocumentCategoryLogs = (props: Props) => {
  const {
    documentTemplate,
    documentCategory,
    currentUserId,
    isLoadingDocument,
  } = props || {};
  const { socket } = useForgeViewerContext();

  const {
    mapDynamicFieldTitle,
    dataLogs: documentCategoryLogs,
    isLoadMoreLogs,
    setDataLogs: setDocumentCategoryLogs,
    initDataLogs,
    isInit,
  } = useDocumentLogs({
    paramsGetDataLogs: { categoryId: documentCategory?.id },
    documentTemplate,
  });
  const categoryRef = useRef(documentCategory);
  categoryRef.current = documentCategory;
  const dispatch = useDispatch();
  const { webSocketMessages } = useForgeViewerContext();

  // init data for logs
  useEffect(() => {
    if (!documentCategory?.id || isLoadingDocument) {
      return setDocumentCategoryLogs([]);
    }

    initDataLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCategory?.id, isLoadingDocument]);

  // init data for logs
  useEffect(() => {
    if (!webSocketMessages.length) return;

    webSocketMessages.forEach((e) => {
      if (e?.type === MessageType.RELOAD_DOCUMENT) {
        initDataLogs();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  const insertDocumentCategoryLog = useCallback(
    async (params: GetContentLog) => {
      if (!currentUserId || !documentCategory?.id) {
        return;
      }

      const log = await insertDataLogToIndexedDb({
        contentLogParams: params,
        createdBy: currentUserId,
        identifyValue: documentCategory?.id,
        identifyCol: "categoryId",
        type: "category",
      });

      if (log?.id) {
        socket.addDocumentCategoryLog(documentCategory.id, log);
        setDocumentCategoryLogs((prev) =>
          sortArrayByField<DataLog>([log, ...prev], "createdAt", false)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentCategory?.id, currentUserId]
  );

  const handleRevertCategoryByLogs = useCallback(
    async (log: DataLog) => {
      const documentCategory = categoryRef.current;
      if (!documentCategory?.id) {
        return;
      }
      const now = new Date();
      const field = log.field;
      const dataLog = log?.value?.data;

      const {
        mapLogs,
        mapTitleKey,
        mapDisplayValueKey,
        mapDisplayLabelKey,
        mapDisplayValueTypeKey,
      } = transformMapTitleKey([
        {
          field: field as any,
          value: dataLog,
          ...log.value,
          isTypeRevert: true,
          nameDynamicField: "unknown",
        },
      ]);

      const requestId = uuid();
      const bodyUpdate: Partial<DocumentCategoryDTO> = {
        id: documentCategory.id,
        ...getCategoryFieldNecessaryForOffline(documentCategory),
        [field]: dataLog,
        mapTitleKey,
        mapDisplayValueKey,
        mapDisplayLabelKey,
        mapDisplayValueTypeKey,
        isRevertDataByLog: true,
        requestId,
        updatedAt: now,
      };
      // Important. Not add log change level of document category -> we can skip check case change level
      const newDocumentCategory: DocumentCategoryDTO = structuredClone({
        ...documentCategory,
        ...bodyUpdate,
      });
      const result = await documentCategoryApi.updateCategory(
        transformBodyForCombineData<DocumentCategoryDTO>({
          body: bodyUpdate as DocumentCategoryDTO,
          bodyBefore: documentCategory,
          typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
        })
      );
      let fieldValue = dataLog;

      Object.values(mapLogs).forEach((params) => {
        insertDocumentCategoryLog({ ...params, requestId });
      });
      if (field === DocumentCategoryKey.DATA) {
        newDocumentCategory.data = {
          ...(documentCategory.data || {}),
          ...(result.data.data || {}),
        };
        fieldValue = newDocumentCategory.data;
      }
      dispatch(updateDocumentCategory(newDocumentCategory));
      socket.changeDocCategory(documentCategory, {
        [field]: fieldValue,
        updatedAt: result.data.updatedAt,
      });
    },
    [dispatch, insertDocumentCategoryLog, socket]
  );

  useEffect(() => {
    if (!webSocketMessages.length) return;

    webSocketMessages.forEach((e) => {
      if (e?.type !== MessageType.ADD_DOCUMENT_CATEGORY_LOG || !isInit) return;
      const { data, docCategoryId } = e;
      if (docCategoryId !== documentCategory?.id) return;
      setDocumentCategoryLogs(addOrUpdateLog(isArray(data) ? data : [data]));
    });
  }, [webSocketMessages, documentCategory?.id]);

  return {
    mapDynamicFieldTitle,
    documentCategoryLogs,
    isLoadMoreLogs,
    setDocumentCategoryLogs,
    insertDocumentCategoryLog,
    handleRevertCategoryByLogs,
  };
};

export default useDocumentCategoryLogs;

import { documentTaskApi, bimFileApi } from "apiClient/v2";
import {
  DEFAULT_MIN_NAVIGATOR_LENGTH,
  ICON_SIZE,
  iKeyLabel,
  POINT_SIZE,
  transformLabelPosition,
} from "components/ui/KeyLabel";
import { BASE_S3_URL, TYPE_USER } from "constants/app";
import { keyLabelArea } from "constants/enum";
import { ALL_LEVEL_LABEL } from "constants/forge";
import { S3_PATH } from "constants/s3";
import {
  KEYLABEL_LENGTH_CLASSNAME,
  KEYLABEL_POINT_CLASSNAME,
} from "constants/styleProps";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { Level, Sheet, Vector3 } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { KeynoteImageData } from "interfaces/models/documentKeynote";
import { DocumentTask, MainImageData } from "interfaces/models/documentTask";
import { ProjectDetail } from "interfaces/models/project";
import {
  TaskSheetTemplate,
  TEMPLATE_TASKSHEET_TYPE,
} from "interfaces/models/taskSheetTemplate";
import isEqual from "lodash/isEqual";
import store from "redux/store";
import { Editor as iEditor } from "tinymce";
import { sortArrayByField } from "utils/array";
import { uuid } from "utils/common";
import { formatDate } from "utils/date";
import { removeFileS3, uploadFileToS3 } from "utils/file";
import { getCurrentViewer } from "utils/forge";
import { calculatePositionOnSheet } from "utils/forge/forge2d";
import WorkerHandler from "worker";

interface iCheckSheetImageValid {
  documentTask: DocumentTask;
}

interface iCheckCalculatePositonKeyplanByGuid {
  guid: string;
  documentTask: DocumentTask | null;
}

interface iCheckDuplicateDocumentTaskTitle {
  title: string;
  bimFileId: string;
}

interface iGetLastIndexDuplicateTitle {
  titleDuplicate: string;
  documentTasks: DocumentTask[];
  currentDocumentTask?: DocumentTask;
}

interface iGetMapKeyLabelProps {
  taskList?: TaskDTO[];
  imageWidth: number;
  imageHeight: number;
  offsetX: number;
  offsetY: number;
  documentCategory?: DocumentCategoryDTO;
}

const ROTATION = {
  [keyLabelArea.AREA_1]: 225,
  [keyLabelArea.AREA_2]: 315,
  [keyLabelArea.AREA_3]: 45,
  [keyLabelArea.AREA_4]: 135,
};

const DEFAULT_NAVIGATOR_LENGTH = 50;
const MAX_NAVIGATOR_LENGTH = 120;
const OFFSET_ADDITION_ROTATION = 10;
const OFFSET_ADDITION_NAVIGATOR_LENGTH = 10;

const MAX_LOOP_CHECK_IMPACT_KEYNOTE_POSITION = 50;

export const checkSheetImageValid = async ({
  documentTask,
}: iCheckSheetImageValid) => {
  const taskLabelOfDocumentTask = getTaskLabelOfDocumentTask(documentTask);
  const taskLabelByGuid = taskLabelOfDocumentTask?.taskLabelByGuid;

  return {
    isImageValid: !!taskLabelByGuid?.imageUrl,
  };
};

export const getLevelNSheetData = async (projectDetail: ProjectDetail) => {
  const { data: dataProjectDetail } = await bimFileApi.getProject(
    projectDetail.id
  );

  const levelData: { [key: string]: Level } =
    dataProjectDetail?.levelData || {};

  const sheetData: Sheet[] = dataProjectDetail?.sheetData || [];

  return { levelData, sheetData };
};

export const checkCalculatePositonKeyplanByGuid = ({
  guid,
  documentTask,
}: iCheckCalculatePositonKeyplanByGuid) => {
  const mainImageData: MainImageData =
    documentTask?.mainImageData || ({} as any);

  return !!mainImageData?.mapTaskLabelsByGuid?.[guid]?.keyLabels?.length;
};

export const removeAllImageOfDataProjectDetail =
  async (): Promise<DataProjectModel | null> => {
    const dataProjectDetail = store.getState().project.dataProjectDetail;
    const currentUser = store.getState().user.currentUser;

    if (!dataProjectDetail?.id) {
      return null;
    }

    const { levelData, sheetData } = await getLevelNSheetData(
      store.getState().project.projectDetail
    );
    const imagesNeedToDelete: Set<string> = new Set();

    const mapSheet = (sheet: Sheet) => {
      const sheetImage = sheet.imageUrl;
      if (sheetImage) {
        imagesNeedToDelete.add(sheetImage);
      }

      if (sheetImage || sheet.width) {
        delete sheet.imageUrl;
        delete sheet.height;
        delete sheet.width;
      }

      return sheet;
    };

    sheetData.forEach(mapSheet);

    Object.keys(levelData)?.forEach((key) => {
      const levelDataImage = levelData[key]?.imageUrl;
      if (levelDataImage) {
        imagesNeedToDelete.add(levelDataImage);
      }

      if (levelDataImage || levelData[key]?.width) {
        delete levelData[key].imageUrl;
        delete levelData[key].height;
        delete levelData[key].width;
      }

      const sheets = levelData[key].sheets?.map(mapSheet);

      if (sheets?.length) {
        levelData[key].sheets = sheets;
      }
    });

    await Promise.all([
      Array.from(imagesNeedToDelete).map((url) =>
        removeFileS3({ keys: [url?.replace(BASE_S3_URL, "")] })
      ),
    ]);

    if (currentUser?.role === TYPE_USER.ROLE_ADMIN) {
      const { data: newDataProjectDetail } = await bimFileApi.updateProject({
        id: dataProjectDetail.id,
        defaultBimPathId: dataProjectDetail.defaultBimPathId,
        name: dataProjectDetail.name,
        levelData: levelData,
        sheetData: sheetData,
      });

      return newDataProjectDetail || null;
    }

    return null;
  };

export const getTaskLabelOfDocumentTask = (documentTask: DocumentTask) => {
  if (!documentTask?.id) {
    return null;
  }

  const mainImageData: MainImageData = documentTask?.mainImageData || {};
  const { guid, mapTaskLabelsByGuid }: MainImageData = mainImageData;

  if (!guid) {
    return null;
  }

  return { taskLabelByGuid: mapTaskLabelsByGuid?.[guid], mainImageData };
};

export const checkIsTaskLabelChangeStatusAndPosition = (
  documentTask: DocumentTask,
  tasks: TaskDTO[]
) => {
  const taskLabelOfDocumentTask = getTaskLabelOfDocumentTask(documentTask);
  const taskLabelByGuid = taskLabelOfDocumentTask?.taskLabelByGuid;

  if (!taskLabelByGuid) {
    return {
      isDiffPosition: false,
      isDiffStatus: false,
    };
  }

  const keyLabels = taskLabelByGuid.keyLabels;
  const mapTasks: { [key: string]: TaskDTO } = Object.assign(
    {},
    ...tasks.map((t) => ({ [t.id]: t }))
  );
  const isDiffStatus = keyLabels.some((item) => {
    const task = mapTasks?.[item.id];

    return task?.id && task.status !== item.status;
  });

  const isDiffPosition = keyLabels.some((item) => {
    const task = mapTasks?.[item.id];

    return task?.id && !isEqual(task?.position, item?.positionFromForge);
  });

  return { isDiffStatus, isDiffPosition };
};

export const checkDuplicateDocumentTaskTitle = async ({
  title,
  bimFileId,
}: iCheckDuplicateDocumentTaskTitle) => {
  const documentTasks = await documentTaskApi
    .getDocumentTasksByTitle({
      title,
      bimFileId,
    })
    .catch(() => []);

  return documentTasks?.length > 0;
};

export const getLastIndexDuplicateTitle = ({
  titleDuplicate,
  documentTasks,
  currentDocumentTask,
}: iGetLastIndexDuplicateTitle) => {
  const reg = /\((\w+)\)/gim;
  const arrIndex: number[] = [];
  const arrTitle = documentTasks
    ?.filter(
      (doc) =>
        doc.title.startsWith(titleDuplicate) &&
        doc?.id !== currentDocumentTask?.id
    )
    ?.map((doc) => doc.title);

  arrTitle.forEach((title) => {
    const matches = (title.replace(titleDuplicate, "").match(reg) || []).map(
      (e) => e.replace(reg, "$1")
    );

    const length = matches?.length;

    if (!length) {
      return;
    }

    const index = Number(matches[length - 1]);
    if (!isNaN(index)) {
      arrIndex.push(index);
    }
  });

  return arrIndex.length ? Math.max(...arrIndex) : 0;
};

// calculate rotation for label
export const calculateRotationForLabel = ({
  keylabel,
  imageHeight,
  imageWidth,
  maxLabelLength,
}: {
  keylabel: iKeyLabel;
  imageWidth: number;
  imageHeight: number;
  maxLabelLength: number;
}) => {
  const positionLabel = getPositionLabel({ keylabel });
  const verticalPositon =
    keylabel.rotation! > 0 && keylabel.rotation! < 180 ? 1 : -1;
  const horizontalPosition =
    keylabel.rotation! > 90 && keylabel.rotation! < 270 ? -1 : 1;
  const labelLength = maxLabelLength;
  const x =
    positionLabel.x +
    ((labelLength * ICON_SIZE.width) / 4 - POINT_SIZE / 2) * horizontalPosition;
  const y =
    positionLabel.y +
    ((labelLength * ICON_SIZE.width) / 4 - POINT_SIZE / 2) * verticalPositon;

  const isLabelOutSide = checkKeyLabelIsOutSide({
    imageWidth,
    imageHeight,
    x,
    y,
  });

  const rotation =
    keylabel.rotation! + OFFSET_ADDITION_ROTATION > 360
      ? keylabel.rotation! + OFFSET_ADDITION_ROTATION - 360
      : keylabel.rotation! + OFFSET_ADDITION_ROTATION;

  return {
    isLabelOutSide,
    rotation,
  };
};

// handle impact postion
export const handleImpactPosition = ({
  mapKeyLabelProps,
  imageHeight,
  imageWidth,
  maxLabelLength,
}: {
  maxLabelLength: number;
  mapKeyLabelProps: { [key: string]: iKeyLabel };
  imageWidth: number;
  imageHeight: number;
}) => {
  const arrKeyLabelProps = Object.values(mapKeyLabelProps);
  const newMapKeyLabelProps = structuredClone(mapKeyLabelProps);

  arrKeyLabelProps.forEach((item) => {
    if (!item?.id) {
      return;
    }

    const keylabel = newMapKeyLabelProps[item.id];
    const objMaxLoop = {
      maxLoop: MAX_LOOP_CHECK_IMPACT_KEYNOTE_POSITION,
      isReverse: false,
    };

    const dataCheckHasImpactPosition = checkHasImpactPosition({
      keylabel,
      mapKeyLabelProps: newMapKeyLabelProps,
      maxLabelLength,
    });

    let isHasImpactPosition = dataCheckHasImpactPosition?.isHasImpactPosition;
    const labelPosition = getPositionLabel({
      keylabel,
    });

    let isOutSide = checkKeyLabelIsOutSide({
      imageWidth,
      imageHeight,
      y: labelPosition.y,
      x: labelPosition.x,
    });

    while ((isHasImpactPosition || isOutSide) && objMaxLoop.maxLoop >= 0) {
      objMaxLoop.maxLoop = objMaxLoop.maxLoop - 1;

      let rotation =
        newMapKeyLabelProps[keylabel.id].rotation! + OFFSET_ADDITION_ROTATION;
      rotation = rotation > 360 ? rotation - 360 : rotation;
      let navigatorLength = keylabel.navigatorLength;
      if (navigatorLength > MAX_NAVIGATOR_LENGTH) {
        objMaxLoop.isReverse = true;
      }
      if (navigatorLength < DEFAULT_NAVIGATOR_LENGTH) {
        objMaxLoop.isReverse = false;
      }

      if (objMaxLoop.isReverse) {
        navigatorLength = navigatorLength - OFFSET_ADDITION_NAVIGATOR_LENGTH;
        navigatorLength =
          navigatorLength < DEFAULT_NAVIGATOR_LENGTH
            ? DEFAULT_NAVIGATOR_LENGTH
            : navigatorLength;
      }

      if (!objMaxLoop.isReverse) {
        navigatorLength = navigatorLength + OFFSET_ADDITION_NAVIGATOR_LENGTH;
        navigatorLength =
          navigatorLength > MAX_NAVIGATOR_LENGTH
            ? MAX_NAVIGATOR_LENGTH
            : navigatorLength;
      }

      const newKeylabel = {
        ...keylabel,
        rotation,
        navigatorLength,
      };

      // check label's position is outside
      const { rotation: newRotation } = calculateRotationForLabel({
        keylabel: newKeylabel,
        imageWidth,
        imageHeight,
        maxLabelLength,
      });
      newKeylabel.rotation = newRotation;
      newMapKeyLabelProps[keylabel.id] = newKeylabel;

      const otherDataCheckHasImpactPosition = checkHasImpactPosition({
        keylabel: newMapKeyLabelProps[keylabel.id],
        mapKeyLabelProps: newMapKeyLabelProps,
        maxLabelLength,
      });
      isHasImpactPosition =
        otherDataCheckHasImpactPosition?.isHasImpactPosition;
      const labelPosition = getPositionLabel({
        keylabel: newMapKeyLabelProps[keylabel.id],
      });

      isOutSide = checkKeyLabelIsOutSide({
        imageWidth,
        imageHeight,
        y: labelPosition.y,
        x: labelPosition.x,
      });
    }
  });

  return newMapKeyLabelProps;
};

// get position's label
export const getPositionLabel = ({
  keylabel,
}: {
  keylabel: iKeyLabel;
}): iKeyLabel["position"] => {
  let { x, y } = keylabel.position;
  const rotation = keylabel?.rotation || ROTATION[keyLabelArea.AREA_3];
  const navigatorLength = keylabel?.navigatorLength || DEFAULT_NAVIGATOR_LENGTH;

  const verticalPositon = rotation > 0 && rotation < 180 ? 1 : -1;
  const horizontalPosition = rotation > 90 && rotation < 270 ? -1 : 1;

  let radianX = 0;
  let radianY = 0;
  if (rotation > 0 && rotation <= 90) {
    radianX = Math.cos((rotation / 180) * Math.PI);
    radianY = Math.sin((rotation / 180) * Math.PI);
  } else if (rotation > 90 && rotation < 180) {
    radianX = Math.sin(((rotation - 90) / 180) * Math.PI);
    radianY = Math.cos(((rotation - 90) / 180) * Math.PI);
  } else if (rotation === 180) {
    radianX = 1;
    radianY = 0;
  } else if (rotation > 180) {
    let ro = rotation - 180;
    if (rotation > 270) {
      ro = 369 - rotation;
    }

    radianX = Math.cos((ro / 180) * Math.PI);
    radianY = Math.sin((ro / 180) * Math.PI);
  }

  x = x + navigatorLength * radianX * horizontalPosition;
  y = y + navigatorLength * radianY * verticalPositon;

  return { x, y };
};

// check outside
export const checkKeyLabelIsOutSide = ({
  x,
  y,
  imageWidth,
  imageHeight,
}: {
  x: number;
  y: number;
  imageHeight: number;
  imageWidth: number;
}) => {
  const newX = Math.floor(x);
  const newY = Math.floor(y);

  return newX < 0 || newY > imageHeight || newY < 0 || newX > imageWidth;
};

export const distanceBetweenTwoPoint = (
  point1: { x: number; y: number },
  point2: { x: number; y: number }
) => {
  const x = point2.x - point1.x;
  const y = point2.y - point1.y;

  return Math.sqrt(x * x + y * y);
};

export const pointToLineDistance = ({
  mainPoint,
  point1,
  point2,
}: {
  mainPoint: { x: number; y: number };
  point1: { x: number; y: number };
  point2: { x: number; y: number };
}) => {
  const edgeLength1 = distanceBetweenTwoPoint(mainPoint, point1);
  const edgeLength2 = distanceBetweenTwoPoint(mainPoint, point2);
  const edgeLength3 = distanceBetweenTwoPoint(point1, point2);
  const p = (edgeLength1 + edgeLength2 + edgeLength3) / 2;
  const ah =
    (2 *
      Math.sqrt(
        p * (p - edgeLength1) * (p - edgeLength2) * (p - edgeLength3)
      )) /
    edgeLength3;

  return ah;
};

// check impact position
export const checkHasImpactPosition = ({
  keylabel,
  maxLabelLength,
  mapKeyLabelProps,
}: {
  maxLabelLength: number;
  keylabel: iKeyLabel;
  mapKeyLabelProps: { [key: string]: iKeyLabel };
}) => {
  const offset = (maxLabelLength * ICON_SIZE.height) / 2;
  let maxRotation = ROTATION[keyLabelArea.AREA_3];

  const positionPointOfLabel = getPositionLabel({
    keylabel,
  });

  const arrKeylabelImpact = Object.values(mapKeyLabelProps).filter((item) => {
    if (item.label === keylabel.label) {
      return false;
    }

    const positionPointOfItem = getPositionLabel({
      keylabel: item,
    });
    const positionLineOfItem = item.position;
    const ah = pointToLineDistance({
      mainPoint: positionPointOfLabel,
      point1: positionPointOfItem,
      point2: positionLineOfItem,
    });
    const edgeLength1 = distanceBetweenTwoPoint(
      positionPointOfLabel,
      positionPointOfItem
    );
    const edgeLength2 = distanceBetweenTwoPoint(
      positionPointOfLabel,
      positionLineOfItem
    );
    const edgeLength3 = distanceBetweenTwoPoint(
      positionPointOfItem,
      positionLineOfItem
    );

    return (
      edgeLength1 < offset ||
      edgeLength2 < offset ||
      (ah < offset && edgeLength1 < edgeLength3 && edgeLength2 < edgeLength3)
    );
  });

  const arrKeylabelImpactNotCalculate = arrKeylabelImpact.filter(
    (item) => item.id !== keylabel.id
  );
  maxRotation = Math.max(
    ...arrKeylabelImpact.map(
      (item) => item.rotation || ROTATION[keyLabelArea.AREA_3]
    )
  );

  return {
    isHasImpactPosition: !!arrKeylabelImpactNotCalculate.length,
    arrKeylabelImpact,
    maxRotation,
  };
};

export const transformKeyLabelData = ({
  keylabel,
  scaleX,
  scaleY,
}: {
  keylabel: iKeyLabel;
  scaleX: number;
  scaleY: number;
}) => {
  const x = keylabel.position.x * scaleX;
  const y = keylabel.position.y * scaleY;
  const navigatorLength =
    keylabel.navigatorLength! < DEFAULT_MIN_NAVIGATOR_LENGTH
      ? DEFAULT_MIN_NAVIGATOR_LENGTH
      : keylabel.navigatorLength!;

  return {
    ...keylabel,
    position: { x, y },
    navigatorLength,
  };
};

export const getKeyLabelProps = async ({
  taskList,
  documentCategory,
  imageWidth,
  imageHeight,
  offsetX,
  offsetY,
}: iGetMapKeyLabelProps) => {
  // init postion, rotation
  let mapKeyLabelProps: {
    [key: string]: iKeyLabel;
  } = {};
  let items: Array<{
    id: string;
    position: Vector3;
    label: string;
    status?: any;
  }> = [];
  if (taskList) {
    items = sortArrayByField([...taskList], "indexId").map((task, index) => ({
      id: task.id,
      position: task.position,
      label: `${index + 1}`,
      status: task?.status,
    }));
  }

  if (documentCategory) {
    const selectedExternalIds = new Set(documentCategory?.selectedExternalIds);
    items = [];

    documentCategory?.documentItems?.forEach((item, index) => {
      if (selectedExternalIds.has(item.id)) {
        items.push({
          label: `${index + 1}`,
          id: item?.id,
          status: item.status,
          position: item.position as any,
        });
      }
    });
  }

  const viewer = getCurrentViewer()!;
  items?.forEach((item) => {
    if (!item?.position) {
      return;
    }

    const positionClient = viewer.worldToClient(
      calculatePositionOnSheet(item.position)
    );
    const label = `${item.label}`;
    const x = (positionClient?.x || 0) - offsetX;
    const y = (positionClient?.y || 0) - offsetY;
    let area = keyLabelArea.AREA_3;

    // calculate rotation
    if (x < Number(imageWidth) / 2) {
      if (y > Number(imageHeight) / 2) {
        area = keyLabelArea.AREA_4;
      } else {
        area = keyLabelArea.AREA_1;
      }
    }

    if (x > Number(imageWidth) / 2) {
      if (y > Number(imageHeight) / 2) {
        area = keyLabelArea.AREA_3;
      } else {
        area = keyLabelArea.AREA_2;
      }
    }

    const rotation = ROTATION[area as keyLabelArea];
    const isOutSide = checkKeyLabelIsOutSide({
      imageWidth,
      imageHeight,
      y,
      x,
    });

    if (!isOutSide) {
      const keylabel: iKeyLabel = {
        id: item.id,
        label,
        position: { x, y },
        positionFromForge: item.position,
        rotation,
        navigatorLength: DEFAULT_NAVIGATOR_LENGTH,
        status: item?.status,
        labelLength: label.length,
      };
      mapKeyLabelProps = {
        ...mapKeyLabelProps,
        [item.id]: keylabel,
      };
    }
  });

  // get max label length
  const maxLabelLength = Math.max(
    ...Object.values(mapKeyLabelProps)?.map((item) => item.label?.length)
  );

  Object.keys(mapKeyLabelProps).forEach(
    (key) => (mapKeyLabelProps[key].labelLength = maxLabelLength)
  );

  const handleImpactPositionWorker = new Worker(
    new URL("../worker/handleImpactPositionWorker.ts", import.meta.url)
  );

  const workerHandler = new WorkerHandler(handleImpactPositionWorker);
  let arrKeylabelProps = Object.values(mapKeyLabelProps);

  try {
    arrKeylabelProps = (await workerHandler.call({
      mapKeyLabelProps,
      imageHeight,
      imageWidth,
      maxLabelLength,
    })) as iKeyLabel[];
  } catch (err) {
    console.log(err, "getKeyLabelProps err=");
  }

  return arrKeylabelProps as iKeyLabel[];
};

export const uploadTaskListToS3 = async (taskList: TaskDTO[]) => {
  const filePath = `${uuid()}.json`;

  return await uploadFileToS3(
    new File([JSON.stringify(taskList)], filePath),
    filePath,
    S3_PATH.TaskSheet,
    {
      keepOriginName: true,
      isRetry: false,
    }
  );
};

export const transformDocumentTitle = ({
  taskSheetTemplateId,
  taskSheetTemplateList,
  levelLabel,
  date,
}: {
  taskSheetTemplateId?: string | null;
  taskSheetTemplateList: TaskSheetTemplate[];
  levelLabel: string;
  date: Date | string;
}) => {
  const template = taskSheetTemplateList.find(
    (t) => t.id === taskSheetTemplateId
  );

  let typeText = "是正指示書"; // instructions
  if (template?.templateType === TEMPLATE_TASKSHEET_TYPE.REPORT) {
    typeText = "是正報告書";
  }

  return `${formatDate(date, "YYYYMMDD")}_${typeText}_${
    levelLabel || ALL_LEVEL_LABEL
  }階`;
};

export const handleMoveKeynoteElement = ({
  id,
  label,
  editorRef,
  iconWidth,
  iconHeight,
  rotationInit,
  pointElement,
  lineElement,
  positionInit,
  imageSize,
  callbackDragStop,
}: {
  id?: string;
  label?: string;
  rotationInit: number;
  positionInit: { x: number; y: number };
  iconWidth: number;
  iconHeight: number;
  imageSize: { width: number; height: number };
  pointElement: HTMLElement;
  lineElement: HTMLElement;
  editorRef?: React.MutableRefObject<iEditor | undefined>;
  callbackDragStop?: (props: Partial<iKeyLabel>) => void;
}) => {
  let [pos1, pos2, pos3, pos4] = [0, 0, 0, 0];
  const editor = editorRef?.current;

  let isDragged = false;
  let navigatorLengthTemp = DEFAULT_MIN_NAVIGATOR_LENGTH;
  let newRotation =
    rotationInit < 0 ? 360 - Math.abs(rotationInit) : rotationInit;
  let rotationTemp =
    rotationInit < 0 ? 360 - Math.abs(rotationInit) : rotationInit;
  let positionOfPointTemp = {
    x:
      pointElement.offsetLeft +
      positionInit.x +
      iconHeight / 2 -
      POINT_SIZE / 2,
    y: pointElement.offsetTop + positionInit.y + iconWidth / 2 - POINT_SIZE / 2,
  };
  let positionOfPoint = positionOfPointTemp;
  pointElement.onmousedown = dragMouseDown;

  function dragMouseDown(e: MouseEvent) {
    e = e || window.event;
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    isDragged = false;

    if (editor) {
      editor.on("mouseup", closeDragElement);
      editor.on("mousemove", elementDrag);
    } else {
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    positionOfPointTemp = {
      x:
        pointElement.offsetLeft +
        positionInit.x +
        iconHeight / 2 -
        POINT_SIZE / 2,
      y:
        pointElement.offsetTop +
        positionInit.y +
        iconWidth / 2 -
        POINT_SIZE / 2,
    };
  }

  function elementDrag(e: MouseEvent) {
    e = e || window.event;
    e.preventDefault();

    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    let top = pointElement.offsetTop - pos2;
    let left = pointElement.offsetLeft - pos1;
    positionOfPoint = {
      x: left + positionInit.x + iconWidth / 2 - POINT_SIZE / 2,
      y: top + positionInit.y + iconHeight / 2 - POINT_SIZE / 2,
    };
    let leftAbsolute = Math.floor(left + positionInit.x);
    let topAbsolute = Math.floor(top + positionInit.y);
    const imageWidth = imageSize.width - iconWidth;
    const imageHeight = imageSize.height - iconHeight;

    // check current element's position is outside
    if (leftAbsolute + pos1 < -2) {
      left = -positionInit.x;
    }
    if (leftAbsolute + pos1 > imageWidth) {
      left = imageWidth - positionInit.x;
    }
    if (topAbsolute + pos2 < -2) {
      top = -positionInit.y;
    }

    if (Math.floor(topAbsolute + pos2) > imageHeight) {
      top = imageHeight - positionInit.y;
    }

    leftAbsolute = left + positionInit.x;
    topAbsolute = top + positionInit.y;

    // check drag is outsided
    if (
      leftAbsolute < 0 ||
      topAbsolute < 0 ||
      leftAbsolute > imageWidth ||
      topAbsolute > imageHeight
    ) {
      return closeDragElement;
    }

    isDragged = true;
    pointElement.style.top = `${top}px`;
    pointElement.style.left = `${left}px`;

    const prePositionOfPoint = positionOfPointTemp;
    const navigatorLength = distanceBetweenTwoPoint(
      positionOfPoint,
      positionInit
    );
    const edgeLength1 = distanceBetweenTwoPoint(
      prePositionOfPoint,
      positionInit
    );
    const edgeLength2 = distanceBetweenTwoPoint(
      prePositionOfPoint,
      positionOfPoint
    );
    const cos =
      (Math.pow(navigatorLength, 2) +
        Math.pow(edgeLength1, 2) -
        Math.pow(edgeLength2, 2)) /
      (2 * navigatorLength * edgeLength1);
    let deg = (Math.acos(cos) * 180) / Math.PI;

    // check point is to the right or left side of a line
    // sign((Bx - Ax) * (Y - Ay) - (By - Ay) * (X - Ax))
    const ret = Math.sign(
      (prePositionOfPoint.x - positionInit.x) *
        (positionOfPoint.y - positionInit.y) -
        (prePositionOfPoint.y - positionInit.y) *
          (positionOfPoint.x - positionInit.x)
    );
    deg = deg * (ret || 1);

    newRotation = rotationTemp + deg;
    newRotation =
      newRotation > 360
        ? Math.abs(360 - newRotation)
        : newRotation < 0
        ? 360 - Math.abs(newRotation)
        : newRotation;

    lineElement.style.width = `${navigatorLength}px`;
    lineElement.style.transform = `rotate(${newRotation}deg)`;
    navigatorLengthTemp = navigatorLength;

    if (editor && label) {
      editor.dom.setStyle(`${KEYLABEL_POINT_CLASSNAME}-${label}`, "top", null);
      editor.dom.setStyle(
        `${KEYLABEL_POINT_CLASSNAME}-${label}`,
        "bottom",
        null
      );
      editor.dom.setStyle(`${KEYLABEL_POINT_CLASSNAME}-${label}`, "left", null);
      editor.dom.setStyle(
        `${KEYLABEL_POINT_CLASSNAME}-${label}`,
        "right",
        null
      );
      const transformRotation =
        newRotation < 0 ? 360 - Math.abs(newRotation) : newRotation;
      const labelPosition = transformLabelPosition({
        transformRotation,
        navigatorLength,
        iconWidth,
        iconHeight,
      });
      Object.keys(labelPosition).forEach((key) => {
        editor.dom.setStyle(
          `${KEYLABEL_POINT_CLASSNAME}-${label}`,
          key,
          labelPosition[key]
        );
      });

      editor.dom.setAttrib(
        `${KEYLABEL_LENGTH_CLASSNAME}-${label}`,
        "data-rotation",
        newRotation
      );
      editor.dom.setStyle(
        `${KEYLABEL_LENGTH_CLASSNAME}-${label}`,
        "width",
        `${navigatorLength}px`
      );
      editor.dom.setStyle(
        `${KEYLABEL_LENGTH_CLASSNAME}-${label}`,
        "transform",
        `rotate(${newRotation}deg)`
      );
    }
  }

  function closeDragElement() {
    if (editor) {
      editor.off("mouseup");
      editor.off("mousemove");
    }

    positionOfPointTemp = positionOfPoint;
    rotationTemp = newRotation;

    if (isDragged && id && label) {
      const transformRotation =
        rotationTemp < 0 ? 360 - Math.abs(rotationTemp) : rotationTemp;
      const labelPosition = transformLabelPosition({
        transformRotation,
        navigatorLength: navigatorLengthTemp,
        iconWidth,
        iconHeight,
      });

      pointElement.style.top = null as any;
      pointElement.style.left = null as any;
      pointElement.style.left = null as any;
      pointElement.style.right = null as any;
      Object.keys(labelPosition).forEach((key) => {
        pointElement.style[key as any] = labelPosition[key];
      });

      callbackDragStop?.({
        navigatorLength: navigatorLengthTemp,
        rotation: rotationTemp,
        label,
        id,
      });
    }

    isDragged = false;
    document.onmouseup = null;
    document.onmousemove = null;
  }
};

export const getLabelsOfKeynote = (keynoteImageData: KeynoteImageData) => {
  const { guid, mapLabelsByGuid }: KeynoteImageData = keynoteImageData;

  if (!guid) {
    return null;
  }

  return { labelByGuid: mapLabelsByGuid?.[guid] };
};

export const transformKeylabelsByEdited = ({
  keyLabels,
  currentKeyLabelsEdited,
  keyLabelsEdited,
}: {
  keyLabels: iKeyLabel[];
  currentKeyLabelsEdited: Partial<iKeyLabel>[];
  keyLabelsEdited: Partial<iKeyLabel>[];
}) => {
  const cloneKeyLabels = structuredClone(keyLabels);
  const cloneKeyLabelsEdited = structuredClone(currentKeyLabelsEdited);

  keyLabelsEdited.forEach((item) => {
    const indexKeylabel = cloneKeyLabels.findIndex((i) => i.id === item?.id);
    if (indexKeylabel !== -1) {
      cloneKeyLabels[indexKeylabel] = {
        ...cloneKeyLabels[indexKeylabel],
        ...item,
      };
    }

    const indexKeylabelEdited = cloneKeyLabelsEdited.findIndex(
      (i) => i.id === item?.id
    );

    if (indexKeylabelEdited !== -1) {
      cloneKeyLabelsEdited[indexKeylabelEdited] = item;
    } else {
      cloneKeyLabelsEdited.push(item);
    }
  });

  return { cloneKeyLabelsEdited, cloneKeyLabels };
};

export const handleSyncDataKeynoteOtherDocumentTask = async ({
  mainImageData,
  currentDocumentTask,
  bimFileId,
}: {
  mainImageData?: MainImageData;
  currentDocumentTask: DocumentTask;
  bimFileId: string;
}) => {
  if (!mainImageData) {
    return [];
  }
  const currentUser = store.getState().user.currentUser;

  // get current task labels
  const currentTaskLables = getTaskLabelOfDocumentTask(currentDocumentTask);
  const keyLabelsEdited =
    currentTaskLables?.taskLabelByGuid?.keyLabelsEdited || [];
  const documentTasksByLevel =
    await documentTaskApi.getAllDocumentTasksByBimFileId({
      bimFileId,
      level: currentDocumentTask.level,
    });

  // get document tasks has include keylabel has edited
  const currentTaskIds = currentDocumentTask.taskIds;
  const documentTasksSameTaskIds = documentTasksByLevel.filter(
    (item) =>
      item.level === currentDocumentTask.level &&
      item.id !== currentDocumentTask.id &&
      item.taskIds.some((i) => currentTaskIds.includes(i))
  );
  const documentTaskDetails = await Promise.all(
    documentTasksSameTaskIds.map((item) =>
      documentTaskApi.getDocumentTask(item.id)
    )
  );

  // get document task should sync
  const currentGuid = mainImageData.guid;
  const documentTasksShouldSync: DocumentTask[] = [];
  documentTaskDetails.forEach((item) => {
    const taskLabels = getTaskLabelOfDocumentTask(item);
    const mainImageDataOfItem = taskLabels?.mainImageData;
    const mapTaskLabelsByGuid = mainImageDataOfItem?.mapTaskLabelsByGuid;
    const isExistsSheet =
      mapTaskLabelsByGuid && mapTaskLabelsByGuid?.[currentGuid];

    if (isExistsSheet) {
      mapTaskLabelsByGuid[currentGuid].keyLabelsEdited = keyLabelsEdited;
      mainImageDataOfItem.mapTaskLabelsByGuid = mapTaskLabelsByGuid;
      documentTasksShouldSync.push({
        ...item,
        mainImageData: mainImageDataOfItem,
      });
    }
  });

  // save document tasks
  if (
    documentTasksShouldSync.length &&
    currentUser?.role !== TYPE_USER.ROLE_PARTNER_LEADER
  ) {
    const res = await Promise.all(
      documentTasksShouldSync.map((item) =>
        documentTaskApi.createUpdateDocumentTask(item)
      )
    );

    return res;
  }

  return [];
};

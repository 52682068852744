import { Text } from "@chakra-ui/react";
import {
  FONT_SIZE_MIN,
  FONT_SIZE_OFFSET,
  LINE_HEIGHT_DEFAULT,
} from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { LinkedDataField, TextPosition } from "constants/enum";
import { MCE_EDITABLE_CLASSNAME } from "constants/styleProps";
import { CellType } from "interfaces/models/component";
import {
  createRef,
  CSSProperties,
  memo,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { sleep } from "utils/common";

interface iProps {
  datas: Partial<CellType>[];
  styles?: CSSProperties[];
  isAutoResize?: boolean;
  isEditable?: boolean;
}

const MAX_LOOP_CHECK_TEXT_OVERFLOW = 5000;

const NormalTextListPreview = ({
  datas,
  styles,
  isAutoResize = true,
  isEditable = true,
  ...rest
}: iProps) => {
  const [textElementRefs, setTextElementRefs] = useState<
    MutableRefObject<HTMLParagraphElement>[]
  >([]);

  const fontSizeDefault = datas?.[0]?.style?.fontSize || 14;
  const sizeDefaultRef = useRef({
    fontSize: fontSizeDefault,
    lineHeight: fontSizeDefault * LINE_HEIGHT_DEFAULT,
  });
  useEffect(() => {
    if (!datas?.length) {
      return;
    }

    setTextElementRefs((refs) => datas.map((_, i) => refs[i] || createRef()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas?.length]);

  useEffect(() => {
    if (!textElementRefs.length) {
      return;
    }

    textElementRefs.forEach((element, index) => {
      if (!element.current) {
        return;
      }

      const content = String(datas?.[index]?.value || "")?.replace(
        / /g,
        "\u00A0"
      );
      if (typeof content === "string" && content.includes("\n")) {
        element.current.innerHTML = content?.replaceAll("\n", "<br/>");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas, textElementRefs?.length]);

  const checkIsOverflow = (element: HTMLElement) => {
    const parentElement = element.parentElement;
    const allHeight = textElementRefs.reduce(
      (acc, curr) => acc + (curr.current?.clientHeight ?? 0),
      0
    );

    if (!parentElement) {
      return false;
    }

    return (
      allHeight > parentElement.clientHeight ||
      parentElement.clientHeight < parentElement.scrollHeight
    );
  };

  useEffect(() => {
    if (!datas.length || !isAutoResize || !textElementRefs.length) {
      return;
    }

    const setStyleForAll = (fs: string, lh: string) => {
      textElementRefs.forEach((element) => {
        if (!element.current) return;
        element.current.style.fontSize = fs;
        element.current.style.lineHeight = lh;
      });
    };

    const mainElement = textElementRefs?.[0].current;
    if (!mainElement) {
      return;
    }

    const fontSizeNumber = Number(
      getComputedStyle(mainElement).fontSize.replace("px", "")
    );
    let isOverflow = checkIsOverflow(mainElement);

    let maxLoop = MAX_LOOP_CHECK_TEXT_OVERFLOW;
    let fs = fontSizeNumber;
    const timeouts: any[] = [];
    let index = textElementRefs.length - 1;
    (async () => {
      while (isOverflow && maxLoop >= 0) {
        maxLoop--;
        const t = (await sleep(100)) as any;
        timeouts.push(t);
        if (isOverflow && fs !== FONT_SIZE_MIN) {
          fs = fs - FONT_SIZE_OFFSET;
          setStyleForAll(`${fs}px`, `${fs * LINE_HEIGHT_DEFAULT}px`);
        }

        isOverflow = checkIsOverflow(mainElement);
        if (index < 0) {
          break;
        }

        if (isOverflow && fs === FONT_SIZE_MIN) {
          if (textElementRefs[index].current.innerHTML) {
            textElementRefs[index].current.innerHTML = textElementRefs[
              index
            ].current.innerHTML.slice(0, -1);
          } else {
            index--;
          }
        }
      }
    })();

    return () => {
      timeouts.forEach((t) => clearTimeout(t));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas, isAutoResize, textElementRefs.length]);

  return (
    <>
      {datas.map((data, dIndex) => {
        const isText =
          typeof data?.value === "string" && !data?.value?.includes("\n");
        const content = String(data?.value || "")?.replace(/ /g, "\u00A0");

        return (
          <Text
            key={data?.cellId}
            className={isEditable ? MCE_EDITABLE_CLASSNAME : ""}
            ref={textElementRefs[dIndex]}
            data-field-name={data.cellLinkedData?.field}
            data-field-type={LinkedDataField.COMMON.INPUT_DATA}
            data-text-color={data?.style?.color || "#000000"}
            style={{
              textAlign:
                data.style?.justifyContent || (TextPosition.CENTER as any),
              minHeight: "1em",
              width: "100%",
              ...(styles?.[dIndex] || {}),
              ...(isAutoResize
                ? {
                    lineHeight: `${sizeDefaultRef.current.lineHeight}px`,
                    fontSize: `${sizeDefaultRef.current.fontSize}px`,
                  }
                : {}),
            }}
            {...rest}
          >
            {isText && content}
          </Text>
        );
      })}
    </>
  );
};

export default memo(NormalTextListPreview);

import { sortBy } from "lodash";
import { message } from "components/base";
import { MODERN_SCREENSHOT_WORKER_URL } from "constants/downloadPDF";
import { PaperDirectionType, PaperType } from "constants/enum";
import jsPDF from "jspdf";
import { domToJpeg } from "modern-screenshot";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { setIsGeneratingPdf, setNextPreviewPage } from "redux/documentSlice";
import { setIsDownloadPdfOnMobile } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { sleep } from "utils/common";
import { downloadPdfByUrl } from "utils/download-pdf";
import { waitRenderEvent } from "utils/event-render";
import { getCurrentViewer } from "utils/forge";
import { logDev } from "utils/logs";
import { useCallback, useEffect, useRef } from "react";
import {
  PREVIEW_DOCUMENT_CONTAINER_CLASS,
  TIPTAP_DOCUMENT_CONTAINER_CLASS,
} from "constants/styleProps";

interface Props {
  title?: string;
  setLoading: (isLoading: boolean) => void;
  setLoadingPrintPdf: (isLoading: boolean) => void;
  scrollToPage?(page: number): void;
}

export const DOM_TO_IMAGE_SCALE_VALUE = 2;

export const useDownloadPdf = ({
  title,
  setLoading,
  setLoadingPrintPdf,
  scrollToPage,
}: Props) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const dispatch = useDispatch();
  const viewer = getCurrentViewer();

  const closeLoading = async (isPrint = false) => {
    if (isMobile && viewer) {
      await sleep(3000);
      dispatch(setIsDownloadPdfOnMobile(false));
    }

    if (isPrint) {
      setLoadingPrintPdf(false);
    } else {
      setLoading(false);
    }
  };

  const { totalPagePreview, nextPreviewPage } = useSelector(
    (state: RootState) => state.document
  );

  const getPageInfo = (element?: HTMLElement) => {
    const pageSize = element?.getAttribute("data-page-size") || PaperType.A4;
    const pageDirection =
      element?.getAttribute("data-page-direction") ===
      PaperDirectionType.VERTICAL
        ? "p"
        : "l";
    const delay = element?.getAttribute("data-page-delay");

    return { pageSize, pageDirection, delay: delay ? +delay : null };
  };

  const pageErrors = useRef(new Set<string>());

  const generatePdf = async ({
    contents,
    isFromContentEditor,
  }: {
    contents: HTMLElement[];
    isFromContentEditor: boolean;
  }) => {
    const arrayNodes = Array.from(contents);
    const sizeContents = contents.length;
    const lastPage = Math.max(...arrayNodes.map(({ id }) => +id.slice(5)));

    await Promise.all(
      arrayNodes.map(async (node, index) => {
        const nodeId = node.id;
        if (
          mapPageRendered.current[`${nodeId}`] &&
          !pageErrors.current.has(nodeId)
        )
          return;
        if (!isFromContentEditor) {
          await waitRenderEvent(nodeId);
        }
        let pageSize = "";
        let pageDirection = "";
        const element = !isFromContentEditor
          ? node
          : index < sizeContents - 1
          ? contents[index + 1]
          : null;

        if (element) {
          const pageInfo = getPageInfo(element);
          pageSize = pageInfo.pageSize;
          pageDirection = pageInfo.pageDirection;
          if (pageInfo.delay) {
            await sleep(pageInfo.delay);
          }
        }

        const image = await domToJpeg(node, {
          scale: DOM_TO_IMAGE_SCALE_VALUE,
          font: !isMobile as any,
          workerUrl: MODERN_SCREENSHOT_WORKER_URL,
        });

        /**
         * check it's a blank page, base64 = 'data:,'
         * 'data:,'.length = 6
         */
        if (image.length === 6) {
          pageErrors.current.add(nodeId);
        } else {
          pageErrors.current.delete(nodeId);
        }

        mapPageRendered.current[`${nodeId}`] = {
          image,
          page: { pageDirection, pageSize },
        };
      })
    );

    return lastPage;
  };

  const mapPageRendered = useRef<{
    [k: string]: {
      image: string;
      page?: {
        pageSize: string;
        pageDirection: string;
      };
    };
  }>({});

  const pdfRef = useRef<jsPDF | null>(null);

  const downloadPdfByClass = async (
    classDom: string,
    isPrint = false,
    isFromContentEditor = false
  ) => {
    try {
      if (!pdfRef.current) {
        let contents: HTMLElement[] = [];
        if (isFromContentEditor) {
          const parentElement = document.getElementById("container-preview");
          if (parentElement) {
            contents =
              (parentElement.querySelectorAll(
                `.tiptap .${TIPTAP_DOCUMENT_CONTAINER_CLASS}`
              ) as unknown as HTMLElement[]) || [];
          }
        } else {
          contents = document.getElementsByClassName(
            PREVIEW_DOCUMENT_CONTAINER_CLASS
          ) as unknown as HTMLElement[];
        }

        if (!contents?.length) {
          message.error("エラーが発生しました。再度実行お願いします。");
          closeLoading(isPrint);

          return;
        }

        if (isMobile && viewer) {
          dispatch(setIsDownloadPdfOnMobile(true));
        }

        const { pageSize, pageDirection } = getPageInfo(contents[0]);
        const pdf: any = new jsPDF(pageDirection as any, "px", pageSize);

        let nextPage = 0;
        logDev(`totalPagePreview ${totalPagePreview} - ${isFromContentEditor}`);

        do {
          if (nextPage !== 0) {
            scrollToPage?.(nextPage);
            dispatch(setNextPreviewPage(nextPage));

            // Wait for scroll animation of virtual scroll
            await sleep(300);
          }

          nextPage =
            (await generatePdf({
              contents,
              isFromContentEditor,
            })) + 1;

          if (nextPage > totalPagePreview) {
            nextPage = totalPagePreview;
          }

          logDev(
            `Progress print pdf: ${Math.round(
              (nextPage / totalPagePreview) * 100
            )}% - errors ${pageErrors.current.size}`
          );

          if (pageErrors.current.size) {
            /**
             * ID = `page-${id}`
             * slice(5) -> id
             * Convert to id to number
             */
            nextPage = +Array.from(pageErrors.current.keys())[0].slice(5);
          }
        } while (nextPage < totalPagePreview);

        const keys = sortBy(
          Object.keys(mapPageRendered.current),
          (k) => +k.slice(5)
        );

        keys.forEach((key) => {
          const pageIndex = +key.replace("page-", "");
          const nexMapPage = mapPageRendered.current[`page-${pageIndex + 1}`];
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          const { image, page } = mapPageRendered.current[key];
          pdf.addImage(image, "jpeg", 0, 0, width, height, undefined, "FAST");

          if (pageIndex < totalPagePreview - 1 && nexMapPage?.page) {
            if (!isFromContentEditor) {
              pdf.addPage(
                nexMapPage.page.pageSize,
                nexMapPage.page.pageDirection
              );
            } else if (page) {
              const { pageSize, pageDirection } = page;
              pdf.addPage(pageSize, pageDirection);
            }
          }
        });

        pdfRef.current = pdf;
      }
      const blob = pdfRef.current!.output("bloburl") as any;

      if (isPrint) {
        window.open(blob, "_blank");
      } else {
        await downloadPdfByUrl(blob, `${title || "pdf"}.pdf`);
      }

      closeLoading(isPrint);
    } catch (err) {
      logDev(err, "print errror--");
      message.error("エラーが発生しました。再度実行お願いします。");
      closeLoading(isPrint);
    }
  };

  const handleDownloadPDF = async (
    isPrint = false,
    isFromContentEditor = false
  ) => {
    if (isPrint) {
      setLoadingPrintPdf(true);
    } else {
      setLoading(true);
    }
    if (isFromContentEditor) {
      clearCachePdf();
    }
    dispatch(setIsGeneratingPdf(true));
    const currentPage = nextPreviewPage;
    let isResetPage = false;
    if (!pdfRef.current) {
      isResetPage = true;
      scrollToPage?.(0);
      dispatch(setNextPreviewPage(0));
      await sleep(1000);
    }

    await downloadPdfByClass(
      "div.document-content-container",
      isPrint,
      isFromContentEditor
    );
    dispatch(setIsGeneratingPdf(false));

    if (isResetPage) {
      scrollToPage?.(currentPage);
      dispatch(setNextPreviewPage(currentPage));
    }
  };

  const handlePrintPdf = async (isFromContentEditor = false) => {
    await handleDownloadPDF(true, isFromContentEditor);
  };

  const clearCachePdf = useCallback(() => {
    mapPageRendered.current = {};
    pdfRef.current = null;
    logDev("Clear pdf cached");
  }, []);

  useEffect(() => {
    clearCachePdf();
  }, [clearCachePdf]);

  return {
    handleDownloadPDF,
    handlePrintPdf,
    clearCachePdf,
  };
};

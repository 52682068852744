import {
  COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME,
  COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI,
  PROJECT_RESOURCE,
} from "constants/cache";
import { API_GET_CACHE } from "constants/serviceWorker";

import { DataProjectModel } from "interfaces/models/dataProjectModel";

import { getIndexedDb } from "../indexedDb";
import { getUrlsOfModel } from "./common";

export const getCachedUrls = async (
  projectId: string,
  urlsOfModel: string[]
) => {
  const cachedModelData: string[] = [];
  const cache = await caches.open(API_GET_CACHE);
  const results = await Promise.all(
    urlsOfModel.map((url) => {
      return cache.match(url);
    })
  );
  results.map((result, index) => {
    if (result) {
      cachedModelData.push(urlsOfModel[index]);
    }
  });

  const indexedDb = await getIndexedDb();
  const cachedProjectData = (await indexedDb.getProjectDataCacheProgress())
    .filter((item: any) => item.projectId === projectId)
    .map((item: any) => item.key);

  return { cachedModelData, cachedProjectData };
};

export const getCacheProgress = async (
  project: DataProjectModel,
  isSafari: boolean
) => {
  const commonUrls = isSafari
    ? COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI
    : COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME;
  const modelData = [...commonUrls, ...(await getUrlsOfModel(project))];
  const projectData = [...Object.values(PROJECT_RESOURCE)];
  const totalLength = modelData.length + projectData.length;

  const { cachedModelData, cachedProjectData } = await getCachedUrls(
    project.id,
    modelData
  );
  const cachedLength = cachedModelData.length + cachedProjectData.length;

  return { cachedLength, totalLength, cachedModelData, cachedProjectData };
};

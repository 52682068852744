import { iUseSubCellComponentPreview } from "components/editor-builder/component-preview/TableComponentPreview/SubTableComponentPreview/SubCellComponentPreview";
import { GRID_TEMPLATE_SIZE } from "constants/document";
import {
  CellProperty,
  CellSizeSetting,
  DocumentTemplateType,
  LinkedDataField,
  LinkedDynamicFieldsType,
  TableDefaultStyle,
  TableElementType,
  TemplateComponentType,
} from "constants/enum";
import useOnClickOutside from "hooks/useOnClickOutside";
import { CellType, RowType, SubTableType } from "interfaces/models/component";
import { cloneDeep } from "lodash";
import { checkExistsLinkedTable } from "models/document";
import { DEFAULT_BORDER_COLOR } from "pages/document/template-page/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setComponents,
  setIsComponentDragging,
  setIsEditText,
} from "redux/documentSlice";
import {
  getMaxMovingHeight,
  getMaxMovingWidth,
  getMinHeightCurrentRow,
  getMinWidthCurrentColumn,
  getRepeatSubCellInRow,
  getRepeatSubRow,
  getTableSize,
  updateDOM,
  updateRowDOM,
} from "utils/document";
import {
  getCellPositionByCellId,
  getCellsByRowId,
  getParentCellByCellId,
  updateCells,
} from "utils/tableCell";
import { getBorderWidthByTableStyle } from "../../utils/tableStyle";

const useSubCellComponentPreview = (props: iUseSubCellComponentPreview) => {
  const {
    isLastRow,
    isFirstColumn,
    isLastColumn,
    isParentLastColumn,
    cell,
    isComponentDragging,
    canEditCell,
    onSelectCell,
    selectedCells,
    currentComponent,
    components,
    isDuplicateRow,
    parentCell,
    zoomRatio,
    sizePageRatio,
    pageDirectionRatio,
    limit,
    isTableSelected,
    cellSize,
    documentContainerSize,
    isSelected,
    borderColor,
    currentTemplate,
  } = props;

  const [shouldShowInput, setShouldShowInput] = useState<boolean>(false);
  const [name, setName] = useState<string>(cell?.value ?? "");
  const [resizeType, setResizeType] = useState("");
  const [currentCellResized, setCurrentCellResized] =
    useState<CellType | null>();

  const dispatch = useDispatch();

  const inputRef = useRef<any>(null);
  const nextCell = useRef<CellType>();
  const sizeCellCanMove = useRef<{
    width: { from: number; to: number };
    height: { from: number; to: number };
  }>({
    width: { from: 0, to: 0 },
    height: { from: 0, to: 0 },
  });
  const minCurrentCellSize = useRef<any>({
    width: 0,
    height: 0,
  });
  const positionMouseDown = useRef({ x: 0, y: 0 });
  const currentCellSize = useRef({ width: 0, height: 0 });
  const isResizeCell = useRef(false);
  const sizeDifference = useRef({ width: 0, height: 0 });
  const subTable = useRef({} as SubTableType);
  const allParentCellHeight = useRef<number[]>([]);
  const allCellWidth = useRef<number[]>([]);
  const allCellHeight = useRef<number[]>([]);

  const isExistsLinkedTable = useMemo(
    () => checkExistsLinkedTable(components),
    [components]
  );

  const displaySize = useMemo(() => {
    return {
      width: zoomRatio * sizePageRatio * pageDirectionRatio,
      height: (zoomRatio * sizePageRatio) / pageDirectionRatio,
    };
  }, [zoomRatio, sizePageRatio, pageDirectionRatio]);

  useOnClickOutside(inputRef, () => {
    setShouldShowInput(false);
    handleBlur();
  });

  useEffect(() => {
    if (cell?.value) {
      setName(cell.value);
    }
  }, [cell]);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, [inputRef, shouldShowInput]);

  const updateCellValue = (value: string) => {
    let groupTitle = "";
    const groupCells = parentCell?.subTable?.rows![0].cells;
    const isEditGroupCell =
      Number(selectedCells[0]?.rowSpan) > 0 &&
      Number(groupCells?.length) > 1 &&
      selectedCells[0]?.position?.idRow?.includes("sub-0") &&
      selectedCells[0]?.position?.idColumn?.includes("sub-0");
    const cellImpacted = [] as CellType[];
    const updatedCells = selectedCells.map((item) => ({
      ...item,
      value,
    }));

    if (selectedCells?.length === 1 && selectedCells[0].isSubCell) {
      const parentCell = getParentCellByCellId({
        cellId: selectedCells[0]?.cellId as string,
        component: currentComponent,
      });
      if (parentCell?.subTable?.rows?.length) {
        // Get group title
        groupTitle = parentCell.subTable?.rows![0]?.cells![0]?.value || "";

        // Get cells impacted when change title of cell merged in subTable
        if (isEditGroupCell) {
          const lastCellOfRow = parentCell.subTable.rows.map(
            (cell) => cell?.cells![Number(cell?.cells?.length) - 1]
          );
          cellImpacted.push(...lastCellOfRow);
        }
      }
    }

    // update all cells of the affected row
    [...selectedCells, ...cellImpacted].forEach((selectedCell) => {
      if (!selectedCell?.position) return;

      // Get cells of row
      const allCellsInCurrentRow = getCellsByRowId({
        rowId: selectedCell.position.idRow as string,
        component: currentComponent,
      });

      // mapping value to dynamicFieldLabel field
      allCellsInCurrentRow.forEach((cell) => {
        if (
          cell.cellId !== selectedCell.cellId &&
          cell?.cellLinkedData?.field ===
            LinkedDataField.COMMON.DYNAMIC_FIELDS_FOR_ITEM &&
          cell?.cellLinkedData?.options?.dynamicFieldLabelForCell &&
          cell?.cellLinkedData?.options?.dynamicFieldLabelForCell ===
            selectedCell?.cellLinkedData?.field
        ) {
          const cloneCell = cloneDeep(cell);
          let dynamicFieldLabel = "";
          if (isEditGroupCell) {
            dynamicFieldLabel = value.length
              ? `${value} ${selectedCell.value || ""}`
              : selectedCell?.value || "";
          } else {
            dynamicFieldLabel = groupTitle.length
              ? `${groupTitle} ${value}`
              : value;
          }
          updatedCells.push({
            ...cloneCell,
            cellLinkedData: {
              ...cloneCell?.cellLinkedData,
              options: {
                ...cloneCell?.cellLinkedData?.options,
                dynamicFieldLabel,
              },
            },
          } as any);
        }
      });
    });

    updateCells({
      updatedCells,
      updatedComponentIds: [String(currentComponent.componentId)],
      components,
      documentType: currentTemplate?.documentType as DocumentTemplateType,
      dispatch,
    });
  };

  const removeListeners = () => {
    window.removeEventListener("mousemove", resizeMouseMove);
    window.removeEventListener("mouseup", resizeMouseUp);
  };

  const resizeMouseUp = () => {
    setCurrentCellResized(null);
    setResizeType("");
    removeListeners();

    // change size component & column
    if (isResizeCell.current && currentCellResized && resizeType) {
      handleResizeComponent();
    }
    isResizeCell.current = false;
  };

  useEffect(() => {
    if (currentCellResized) {
      window.addEventListener("mousemove", resizeMouseMove);
      window.addEventListener("mouseup", resizeMouseUp);
    }

    return () => {
      removeListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCellResized]);

  const handleBlur = () => {
    updateCellValue(name);
    dispatch(setIsEditText(false));
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (isComponentDragging) {
      dispatch(setIsComponentDragging(false));
    }

    const isText =
      cell.cellProperty === CellProperty.TEXT ||
      cell.cellLinkedData?.type === CellProperty.TEXT ||
      cell.cellProperty === CellProperty.NO ||
      cell.cellLinkedData?.type === CellProperty.DYNAMIC_FIELDS_FOR_ITEM;

    const isDefaultValueCell =
      cell?.cellLinkedData?.options?.dynamicFieldType ===
        LinkedDynamicFieldsType.TEXT ||
      cell?.cellLinkedData?.options?.dynamicFieldType ===
        LinkedDynamicFieldsType.NUMBER;

    if (!canEditCell) {
      dispatch(setIsEditText(true));
    } else if (isText && !isDefaultValueCell) {
      handleBlur();
    }

    onSelectCell(e, cell);
  };

  const handleShowEditInput = useCallback(() => {
    const isInvalid =
      (!cell.value && cell.cellProperty !== CellProperty.TEXT) ||
      cell?.cellLinkedData?.options?.dynamicFieldType ===
        LinkedDynamicFieldsType.TEXT ||
      cell?.cellLinkedData?.options?.dynamicFieldType ===
        LinkedDynamicFieldsType.NUMBER;

    if (isInvalid) {
      return;
    }

    if (inputRef.current) {
      inputRef.current?.focus();
    }

    setShouldShowInput(true);
  }, [cell]);

  const handleChangeName = (e: React.ChangeEvent<HTMLElement>) => {
    const { value } = e.target as any;
    setName(value);
  };

  const targetLink = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (cell.style?.attach) {
      window.open(cell.style.attach);
    }
  };

  const getSizeCellMerged = (arrSize: number[], start: number, end: number) => {
    return arrSize.reduce((size, item, index) => {
      if (index < start || index > end) return size;
      else return size + item;
    }, 0);
  };

  const resizeMouseDown = (
    e: any,
    data: CellType,
    typeResize: TableElementType
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setCurrentCellResized(data);
    // prevent repeat row resize
    if (isDuplicateRow) {
      return;
    }

    subTable.current = parentCell.subTable as SubTableType;

    const subTableSize = getTableSize(subTable.current);
    let cellResize = {} as CellType;
    let nextCellSize = {
      width: 0,
      height: 0,
    };
    setResizeType(typeResize);
    const subTableRows = subTable.current?.rows || [];

    const arrHeight =
      subTableRows?.map(
        (row) =>
          row.cells?.[
            getCellPositionByCellId({
              cellId: data.cellId,
              component: currentComponent,
            })?.index?.col
          ].height ?? 0
      ) ?? [];

    subTableRows?.forEach((row, indexRow) => {
      row.cells?.forEach((cell, index) => {
        const arrWidth = row.cells?.map((i) => i.width ?? 0) ?? [];
        if (cell.cellId !== data.cellId) {
          return;
        }

        cellResize = {
          ...subTableRows![indexRow + cell.rowSpan! - 1].cells![index]!,
        };

        const newCell =
          typeResize === TableElementType.COL
            ? row.cells![index + cell.colSpan!]
            : subTableRows![indexRow + cell.rowSpan!].cells![index];

        nextCell.current =
          typeResize === TableElementType.COL &&
          index + cell.colSpan! !== subTableSize.column
            ? {
                ...newCell,
                width:
                  newCell?.colSpan && newCell?.colSpan > 1
                    ? (Number(newCell.width) -
                        getSizeCellMerged(
                          arrWidth,
                          index + cell.colSpan! + 1,
                          index + cell.colSpan! + newCell.colSpan - 1
                        )) *
                      displaySize.width
                    : Number(newCell?.width) * displaySize.width,
              }
            : typeResize === TableElementType.ROW &&
              indexRow + cell.rowSpan! !== subTableSize.row
            ? {
                ...newCell,
                height:
                  newCell?.rowSpan && newCell?.rowSpan > 1
                    ? (Number(newCell.height) -
                        getSizeCellMerged(
                          arrHeight,
                          indexRow + cell.rowSpan! + 1,
                          indexRow + cell.rowSpan! + newCell.rowSpan - 1
                        )) *
                      displaySize.height
                    : Number(newCell?.height) * displaySize.height,
              }
            : undefined;
        nextCellSize = {
          ...nextCellSize,
          width: nextCell.current?.width || 0,
          height: nextCell.current?.height || 0,
        };
      });
    });

    if (!cellResize) return;

    minCurrentCellSize.current = {
      width: getMinWidthCurrentColumn(cellResize, subTable.current),
      height: getMinHeightCurrentRow(cellResize, subTable.current),
    };

    if (typeResize === TableElementType.COL) {
      // calculate size can move left
      const sizeMoveFrom =
        (minCurrentCellSize.current.width - cellResize.width!) *
        displaySize.width;

      const maxMoveRightTable = getMaxMovingWidth(
        currentComponent,
        components,
        zoomRatio,
        sizePageRatio,
        pageDirectionRatio,
        limit
      );

      const numOfColumn =
        getTableSize(currentComponent).column +
        (currentComponent.detail?.rows?.[0].cells || ([] as CellType[])).reduce(
          (sum, item) =>
            item.subTable ? sum + getTableSize(item.subTable).column - 1 : sum,
          0
        );
      const sizeMoveTo = isTableSelected
        ? (currentComponent.size.width -
            (cellResize.width || 0) * numOfColumn +
            maxMoveRightTable) /
          numOfColumn
        : nextCellSize.width -
          getMinWidthCurrentColumn(nextCell.current!, subTable.current) *
            displaySize.width;

      // set size can move for cell
      sizeCellCanMove.current = {
        ...sizeCellCanMove.current,
        width: {
          from: sizeMoveFrom,
          to: sizeMoveTo,
        },
      };
    } else if (typeResize === TableElementType.ROW) {
      // calculate size can move bottom
      const sizeMoveFrom =
        (minCurrentCellSize.current.height - cellResize.height!) *
        displaySize.height;

      const maxMoveDownTable = getMaxMovingHeight(
        currentComponent,
        components,
        zoomRatio,
        sizePageRatio,
        pageDirectionRatio,
        limit
      );

      const currentIndexCell = getCellPositionByCellId({
        component: currentComponent,
        cellId: data?.cellId,
      });

      const numOfRow =
        getTableSize(currentComponent).row +
        (
          currentComponent.detail?.rows?.map(
            (row) => row.cells![currentIndexCell.parentIndex.col]
          ) || ([] as CellType[])
        ).reduce(
          (sum, item) =>
            item.subTable ? sum + getTableSize(item.subTable).row - 1 : sum,
          0
        );

      const sizeMoveTo = isTableSelected
        ? (currentComponent.size.height -
            (cellResize.height || 0) * numOfRow +
            maxMoveDownTable) /
          numOfRow
        : nextCellSize.height -
          getMinHeightCurrentRow(nextCell.current!, subTable.current) *
            displaySize.height;

      // set size can move for cell
      sizeCellCanMove.current = {
        ...sizeCellCanMove.current,
        height: {
          from: sizeMoveFrom,
          to: sizeMoveTo,
        },
      };
    }

    positionMouseDown.current = { x: e.clientX, y: e.clientY };
    currentCellSize.current = {
      width: cellResize?.width! * displaySize.width || 0,
      height: cellResize?.height! * displaySize.height || 0,
    };

    allCellWidth.current =
      subTable.current.rows![0].cells?.map((cell) => {
        return Math.abs(cell?.width! * displaySize.width) ?? 0;
      }) ?? [];

    allParentCellHeight.current =
      currentComponent.detail?.rows
        ?.map((row) => row.cells?.[0])
        .map((cell, index) => {
          if (cell?.rowSpan && cell?.rowSpan > 1) {
            const cells =
              currentComponent.detail?.rows!.map(
                (row) => row.cells?.[0].height ?? 0
              ) ?? [];

            return (
              (cell?.height! -
                getSizeCellMerged(cells, index + 1, index + cell.rowSpan - 1)) *
              displaySize.height
            );
          }

          return cell?.height! * displaySize.height ?? 0;
        }) ?? [];

    allCellHeight.current =
      subTable.current.rows
        ?.map((row) => row.cells?.[0])
        .map((cell, index) => {
          if (cell?.rowSpan && cell?.rowSpan > 1) {
            const cells =
              subTable.current.rows!.map((row) => row.cells?.[0].height ?? 0) ??
              [];

            return (
              (cell?.height! -
                getSizeCellMerged(cells, index + 1, index + cell.rowSpan - 1)) *
              displaySize.height
            );
          }

          return cell?.height! * displaySize.height ?? 0;
        }) ?? [];
  };

  const resizeColumn = (size: number) => {
    sizeDifference.current.width = size;
    const indexColumn = getCellPositionByCellId({
      cellId: currentCellResized?.cellId as string,
      component: currentComponent,
    }).index.col;

    if (!nextCell.current) {
      return;
    }

    const currentCellWidth = currentCellSize.current.width + size;
    const newNextCellWidth = nextCell.current.width! - size;

    allCellWidth.current = allCellWidth.current.map((item, index) =>
      index === indexColumn + cell?.colSpan! - 1
        ? currentCellWidth
        : index === indexColumn + cell?.colSpan!
        ? newNextCellWidth
        : item
    );

    components.forEach((component) => {
      const isUpdatedComponent =
        component.componentId === currentComponent.componentId ||
        (currentComponent.type === TemplateComponentType.TableHeader &&
          isExistsLinkedTable &&
          !!component.linkedHeaderId);

      if (!isUpdatedComponent) {
        return;
      }

      component.detail?.rows?.forEach((row) => {
        row.cells?.forEach((item) => {
          if (!item.subTable) {
            return;
          }

          item.subTable?.rows?.forEach((subRow, subRowIndex) => {
            if (
              subRowIndex === 0 &&
              component.type === TemplateComponentType.TableHeader
            ) {
              return;
            }

            subRow.cells?.forEach((subCell, subCellIndex) => {
              if (subCell.position?.idColumn === cell?.position?.idColumn) {
                const currentCellElement = document.getElementById(
                  subCell.cellId
                );

                if (currentCellElement) {
                  currentCellElement.style.width = `${currentCellWidth}px`;

                  const nextCellElement: HTMLElement =
                    currentCellElement.nextSibling as HTMLElement;

                  if (nextCellElement) {
                    nextCellElement.style.width = `${newNextCellWidth}px`;
                  }
                }

                if (
                  component?.type === TemplateComponentType.TableHeader ||
                  !!component.linkedHeaderId
                ) {
                  const width = Math.round(
                    allCellWidth.current[subCellIndex] -
                      TableDefaultStyle.DEFAULT_BORDER_SIZE
                  );

                  updateDOM(subCell, width, CellSizeSetting.WIDTH);

                  return;
                }

                if (subCell?.colSpan && subCell?.colSpan > 1) {
                  const width =
                    getSizeCellMerged(
                      allCellWidth.current,
                      subCellIndex,
                      subCellIndex + subCell.colSpan - 1
                    ) - TableDefaultStyle.DEFAULT_BORDER_SIZE;

                  updateDOM(subCell, width, CellSizeSetting.WIDTH);

                  return;
                }

                const width = Math.round(
                  allCellWidth.current[subCellIndex] -
                    TableDefaultStyle.DEFAULT_BORDER_SIZE
                );

                updateDOM(subCell, width, CellSizeSetting.WIDTH);
              }
            });
          });
        });
      });
    });
  };

  const resizeRow = (cellId: string, size: number) => {
    sizeDifference.current.height = size;
    const currentIndex = getCellPositionByCellId({
      cellId,
      component: currentComponent,
    });

    const indexRow = currentIndex.index.row;
    if (!nextCell.current) {
      return;
    }

    // Handle change size cell
    const currentCellHeight = currentCellSize.current.height + size;
    const newNextCellHeight = nextCell.current.height! - size;

    allCellHeight.current = allCellHeight.current.map((item, index) =>
      index === indexRow + cell?.rowSpan! - 1
        ? currentCellHeight
        : index === indexRow + cell?.rowSpan!
        ? newNextCellHeight
        : item
    );

    const subTablesInRow = currentComponent.detail?.rows?.[
      currentIndex.parentIndex.row
    ].cells?.map((cell) => cell.subTable);

    subTablesInRow?.forEach(
      (subTable) =>
        subTable &&
        subTable.rows?.forEach((row, rowIndex) => {
          const repeatSubRows = getRepeatSubRow(row, currentComponent);
          const index = indexRow + cell?.rowSpan! - 1;
          if (rowIndex === index) {
            updateRowDOM(row.idRow, currentCellHeight);
            repeatSubRows?.forEach((subRow) =>
              updateRowDOM(subRow.idRow, currentCellHeight)
            );
          } else if (rowIndex === index + 1) {
            updateRowDOM(row.idRow, newNextCellHeight);
            repeatSubRows?.forEach((subRow) =>
              updateRowDOM(subRow.idRow, newNextCellHeight)
            );
          }

          row.cells?.forEach((cell) => {
            const repeatSubCells = getRepeatSubCellInRow(
              currentComponent,
              cell.cellId
            );

            let height = 0;
            if (cell?.rowSpan && cell?.rowSpan > 1) {
              height =
                getSizeCellMerged(
                  allCellHeight.current,
                  rowIndex,
                  rowIndex + cell.rowSpan - 1
                ) - TableDefaultStyle.DEFAULT_BORDER_SIZE;
            } else {
              height = Math.round(
                allCellHeight.current[rowIndex] -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE
              );
            }
            updateDOM(cell, height, CellSizeSetting.HEIGHT);
            repeatSubCells?.forEach((repeatCell) =>
              updateDOM(repeatCell, height, CellSizeSetting.HEIGHT)
            );
          });
        })
    );
  };

  const resizeMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!currentCellResized) {
        return;
      }

      isResizeCell.current = true;
      const fromWidth = sizeCellCanMove.current.width.from;
      const toWidth = sizeCellCanMove.current.width.to;
      const fromHeight = sizeCellCanMove.current.height.from;
      const toHeight = sizeCellCanMove.current.height.to;
      const isValidSizeCellWidthCanMove = !(fromWidth === 0 && toWidth === 0);
      const isValidSizeCellHeightCanMove = !(
        fromHeight === 0 && toHeight === 0
      );

      if (resizeType === TableElementType.COL && !isValidSizeCellWidthCanMove) {
        return;
      }

      const heightMoving = e.clientY - positionMouseDown.current.y;
      const widthMoving = e.clientX - positionMouseDown.current.x;

      if (
        resizeType === TableElementType.ROW &&
        !isValidSizeCellHeightCanMove
      ) {
        return;
      }

      if (resizeType === TableElementType.COL) {
        const maxMovingLeft =
          Number(currentCellResized?.width) - GRID_TEMPLATE_SIZE;
        const maxMovingRight =
          Number(nextCell.current?.width) - GRID_TEMPLATE_SIZE;

        if (
          (widthMoving < 0 && Math.abs(widthMoving) > maxMovingLeft) ||
          (widthMoving > 0 && Math.abs(widthMoving) > maxMovingRight)
        ) {
          return;
        }
        resizeColumn(widthMoving);

        return;
      }

      if (resizeType === TableElementType.ROW) {
        if (fromHeight < heightMoving && heightMoving < toHeight) {
          resizeRow(currentCellResized.cellId, heightMoving);

          return;
        }

        if (heightMoving <= fromHeight) {
          resizeRow(currentCellResized.cellId, fromHeight);

          return;
        }

        if (heightMoving >= toHeight) {
          resizeRow(currentCellResized.cellId, toHeight);

          return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cellSize.height,
      cellSize.width,
      currentCellResized,
      components,
      documentContainerSize,
      resizeType,
      zoomRatio,
    ]
  );

  const handleResizeComponent = () => {
    const newComponents = components.map((component) => {
      const isUpdatedComponent =
        component.componentId === currentComponent.componentId ||
        (currentComponent.type === TemplateComponentType.TableHeader &&
          isExistsLinkedTable &&
          !!component.linkedHeaderId);

      if (isUpdatedComponent) {
        const currentParentCellResized = getParentCellByCellId({
          cellId: currentCellResized?.cellId as string,
          component: currentComponent,
        });
        const mapSubTableRows = (subRow: RowType, subRowIndex: number) => {
          const mapSubCell = (subCell: CellType, subCellIndex: number) => {
            let newWidth = subCell.width;
            let newHeight = subCell.height;

            if (
              resizeType === TableElementType.COL &&
              parentCell.position?.idColumn ===
                currentParentCellResized?.position?.idColumn
            ) {
              newWidth =
                allCellWidth.current?.[subCellIndex] / displaySize.width;
            }

            if (
              resizeType === TableElementType.ROW &&
              (parentCell.position?.idRow ===
                currentParentCellResized?.position?.idRow ||
                parentCell.position?.idColumn ===
                  currentParentCellResized?.position?.idColumn)
            ) {
              newHeight =
                Number(subCell.rowSpan) > 1
                  ? subCell.height
                  : allCellHeight.current?.[subRowIndex] / displaySize.height; // keep height with cell contain rowSpan > 1
            }

            return {
              ...subCell,
              width: newWidth,
              height: newHeight,
            } as CellType;
          };

          return {
            ...subRow,
            cells: subRow.cells?.map(mapSubCell),
          };
        };

        return {
          ...component,
          detail: {
            ...component?.detail,
            rows: component?.detail?.rows?.map((row) => {
              const cells = row?.cells?.map((cell) => {
                let subTable = cell.subTable;
                if (cell.subTable?.rows?.length) {
                  const subTableRows =
                    cell.subTable?.rows?.map(mapSubTableRows);
                  subTable = {
                    ...cell.subTable,
                    rows: subTableRows,
                  };
                }

                return {
                  ...cell,
                  subTable,
                };
              });

              return {
                ...row,
                cells,
              };
            }),
          },
        };
      }

      return component;
    });

    dispatch(setComponents(newComponents));
  };

  const borderStyle = useMemo(() => {
    if (isSelected) {
      if (cell?.repeatedTableIndex || cell?.isRepeatedRow) {
        return "dashed";
      }

      return "double";
    }

    if (borderColor) {
      return "solid";
    }

    return "initial";
  }, [borderColor, cell?.isRepeatedRow, cell?.repeatedTableIndex, isSelected]);

  const borderWidth = useMemo(() => {
    const isSelected = selectedCells?.some(
      (item) => item?.cellId === cell?.cellId
    );

    if (
      (cell?.repeatedTableIndex && isSelected) ||
      (cell?.isRepeatedRow && isSelected)
    ) {
      return "1.5px";
    }

    return getBorderWidthByTableStyle({
      css: null,
      prevHasSubTable: null,
      optionSubCell: {
        subCell: cell,
        component: currentComponent,
        isFirstColumn: !!isFirstColumn,
        isLastColumn: !!isLastColumn,
        isLastRow: !!isLastRow,
        isParentLastColumn: !!isParentLastColumn,
      },
    });
  }, [
    currentComponent,
    cell,
    selectedCells,
    isFirstColumn,
    isLastColumn,
    isParentLastColumn,
    isLastRow,
  ]);

  const _borderColor = useMemo(() => {
    const isSelected = selectedCells?.some(
      (item) => item.cellId === cell.cellId
    );

    if (isSelected) {
      return "#009be0";
    }

    return cell.style?.borderColor || DEFAULT_BORDER_COLOR;
  }, [cell.cellId, selectedCells, cell.style?.borderColor]);

  return {
    inputRef,
    shouldShowInput,
    name,
    borderWidth,
    borderStyle,
    _borderColor,
    targetLink,
    handleOnClick,
    handleChangeName,
    resizeMouseDown,
    handleShowEditInput,
  };
};

export default useSubCellComponentPreview;

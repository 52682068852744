import { BoxProps } from "@chakra-ui/react";
import { Attribute, Node, RawCommands } from "@tiptap/core";
import { Node as ModalNode } from "@tiptap/pm/model";
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { LINE_HEIGHT_DEFAULT } from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { TextPosition } from "constants/enum";
import { CellType } from "interfaces/models/component";
import { DEFAULT_TEXT_COLOR } from "pages/document/template-page/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { transformSizeForTextElement } from "utils/download-pdf";
import { CustomNodeComponentProps, NodeType, NodeTypeText } from "../type";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    text: {
      setStyle: (stype: Partial<CellType["style"]>) => ReturnType;
    };
  }
}

const commands: Partial<RawCommands> | undefined = {
  setStyle:
    (style: Partial<CellType["style"]>) =>
    ({ commands }) => {
      // Commands are functions that take a state and a an optional transaction dispatch function and
      // determine whether they apply to this state
      // if not, return false
      // if dispatch was passed, perform their effect, possibly by passing a transaction to dispatch
      // return true;
      commands.command(({ tr, state, dispatch }) => {
        if (!dispatch) {
          return false;
        }

        // filter valid node
        const nodesTypeText: ModalNode[] = [];
        tr.selection.ranges.forEach((range) => {
          state.doc.nodesBetween(range.$from.pos, range.$to.pos, (node) => {
            if (NodeTypeText.includes(node.type.name as any)) {
              nodesTypeText.push(node);
            }
          });
        });

        // update attributes
        nodesTypeText.forEach((node) => {
          commands.updateAttributes(node.type.name, {
            style: { ...node.attrs.style, ...style },
          });
        });

        return true;
      });

      return true;
    },
};

export interface NormalTextNodeAttrs {
  style?: CellType["style"];
  isMultiple?: boolean;
  isJustify?: boolean;
  height?: number;
  indent?: number;
  isSubCell?: boolean;
}

export const NormalTextNode = Node.create({
  name: NodeType.NORMAL_TEXT,
  group: "block",
  content: `inline*`,
  addAttributes() {
    const attrs: {
      [key in keyof NormalTextNodeAttrs]: Attribute;
    } = {
      style: { default: {} },
      indent: { default: 0 },
      isSubCell: { default: false },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "normal-text",
      },
    ];
  },
  renderHTML() {
    return ["normal-text", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addCommands() {
    return commands;
  },
});

export const MultipleNormalTextNode = Node.create({
  name: NodeType.MULTIPLE_NORMAL_TEXT,
  group: "block",
  content: `inline*`,

  addAttributes() {
    const attrs: {
      [key in keyof NormalTextNodeAttrs]: Attribute;
    } = {
      style: { default: {} },
      isMultiple: { default: true },
      isJustify: { default: false },
      indent: { default: 0 },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "multiple-normal-text",
      },
    ];
  },
  renderHTML() {
    return ["multiple-normal-text", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addCommands() {
    return commands;
  },
});

export const JustifyTextNode = Node.create({
  name: NodeType.JUSTIFY_TEXT,
  group: "block",
  content: `${NodeType.MULTIPLE_NORMAL_TEXT}*`,

  addAttributes() {
    const attrs: {
      [key in keyof NormalTextNodeAttrs]: Attribute;
    } = {
      style: { default: {} },
      isMultiple: { default: true },
      height: { default: undefined },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const { height } = HTMLAttributes as NormalTextNodeAttrs;
    let style = "display: flex; justify-content: space-between; ";
    if (height) {
      style = `${style} height: ${height}px`;
    }

    return [
      "div",
      {
        style,
      },
      0,
    ];
  },
});

const Component = (props: CustomNodeComponentProps<NormalTextNodeAttrs>) => {
  const {
    isSubCell = false,
    style,
    isMultiple = false,
    isJustify,
    indent = 0,
  } = props.node.attrs;
  const { editor } = props;
  const position = props.getPos();
  const nodePos = editor.$pos(position);
  const lastTextRef = useRef<string>();

  const [initShowComponent, setInitShowComponent] = useState(false);

  const sizeDefault = useMemo(() => {
    const fontSizeDefault = style?.fontSize || 14;

    return {
      fontSize: fontSizeDefault,
      lineHeight: fontSizeDefault * LINE_HEIGHT_DEFAULT,
    };
  }, [style?.fontSize]);

  const textDecoration = useMemo(() => {
    return style?.underline && style?.lineThrough
      ? "underline line-through"
      : style?.underline
      ? "underline"
      : style?.lineThrough
      ? "line-through"
      : "";
  }, [style?.lineThrough, style?.underline]);

  const textAlign =
    style?.justifyContent === TextPosition.START
      ? TextPosition.LEFT
      : style?.justifyContent === TextPosition.END
      ? TextPosition.RIGHT
      : style?.justifyContent === TextPosition.JUSTIFY
      ? TextPosition.CENTER
      : style?.justifyContent || TextPosition.CENTER;

  const nodeViewWrapperStyle = useMemo((): BoxProps["style"] => {
    return {
      position: isMultiple ? "relative" : "absolute",
      top: 0,
      left: 0,
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      cursor: "text",
      justifyContent: style?.justifyContent || TextPosition.CENTER,
      overflowWrap: "inherit",
      padding: 0,
    };
  }, [style, isMultiple]);

  const nodeViewContentStyle = useMemo((): BoxProps["style"] => {
    return {
      textAlign: textAlign as any,
      padding: isSubCell ? 0 : "0 0.5rem",
      minHeight: "1em",
      height: "fit-content",
      marginLeft: `${indent * 3}rem`,
      width: "100%",
      lineHeight: `${sizeDefault.lineHeight}px`,
      fontSize: `${sizeDefault.fontSize}px`,
      textDecoration,
    };
  }, [textAlign, sizeDefault, textDecoration, indent, isSubCell]);

  // auto reduce size if text is too long
  const node = editor.view.nodeDOM(position);
  useEffect(
    () => {
      // use state to check if element is visible on dom or not
      if (!initShowComponent) {
        setInitShowComponent(true);

        return;
      }

      const element: HTMLDivElement | null = node as any;
      const elementCheckOverflow = element?.childNodes?.[0]
        ?.childNodes?.[0] as any;
      const elementContainsText: HTMLDivElement | null = node?.childNodes?.[0]
        ?.childNodes?.[0]?.childNodes?.[0] as any;
      const elementChangeStyle: HTMLDivElement | null = node?.childNodes?.[0]
        ?.childNodes?.[0] as any;

      if (
        !element ||
        !elementContainsText ||
        !elementChangeStyle ||
        !elementCheckOverflow
      ) {
        return;
      }

      const { text, shouldUpdate } = transformSizeForTextElement({
        isShowMessage: false,
        element,
        elementCheckOverflow,
        elementChangeStyle,
        elementContainsText,
        lastTextRef,
        sizeDefault,
      });

      if (shouldUpdate) {
        elementChangeStyle.style.color = "red";
        elementContainsText.innerHTML = text;
        lastTextRef.current = text;
      } else {
        elementChangeStyle.style.color = style?.color || DEFAULT_TEXT_COLOR;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodePos.content.toJSON(), style?.color, isJustify, initShowComponent]
  );

  return (
    <NodeViewWrapper data-indent={indent} style={nodeViewWrapperStyle}>
      <NodeViewContent
        data-indent={indent}
        data-type={NodeType.TEXT}
        style={nodeViewContentStyle}
      />
    </NodeViewWrapper>
  );
};

const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

export const ENV = Object.freeze({
  DEV: env === "development",
  STUB: env === "stub",
  PRD: env === "production",
  TEST: env === "test",
});

export const MAX_WIDTH_SCREEN_MD = 959;
export const MAX_WIDTH_SCREEN_SM = 677;
export const MAX_WIDTH_SCREEN_MD_CHANGE_HEIGHT_HEADER = 959;

export const ZOOM_IN_OUT_DEFAULT = -350;
export const CURRENT_SYSTEM_MODE_KEY = "currentSystemMode";
export const BASE_S3_URL = `${process.env.REACT_APP_S3_URL}/` || "";

export enum TYPE_USER {
  ROLE_GUEST = "1",
  ROLE_USER = "2",
  ROLE_ADMIN = "3",
  ROLE_PARTNER_LEADER = "4",
}

export const WARNING_BIM_FILE_MSG = '初期データ生成が完了していません。'
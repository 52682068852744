import { EMPTY_OPTION } from "constants/document";
import { CellProperty, LinkedDataField } from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { formatDateCurrent } from "utils/date";
import { getHeader } from "utils/document";
import { GetContentTypeDocumentType } from "../type";
import { getContentNodeTypeNormalText, getContentTypeCheckbox } from "../utils";
import BaseStructureTable, {
  BaseStructureTableProps,
} from "./BaseStructureTable";
import ContentTdTypeCommon from "./ContentTdTypeCommon";

class ContentTdTypeCommissionTable extends BaseStructureTable {
  protected contentTdTypeCommon: ContentTdTypeCommon;

  constructor(params: BaseStructureTableProps) {
    super(params);
    this.contentTdTypeCommon = new ContentTdTypeCommon(params);
  }

  getStructure(params: GetContentTypeDocumentType) {
    const {
      cell,
      currentCell,
      cellIndex: _cellIndex,
      rowIndex: _rowIndex,
      subRowIndex = 1,
      subCellIndex = 1,
    } = params;

    if (!this.page || !this.component) {
      return null;
    }

    const componentDetail = this.component?.detail;
    let cellIndex = _cellIndex;
    let rowIndex = _rowIndex;
    const { offsetItemLinked } = this.page;

    const headerCells =
      getHeader({ components: this.template?.components || [] })?.detail
        ?.rows?.[0]?.cells || [];
    const numberOfRows = componentDetail?.rows?.length || 0;
    const numberOfActualCell =
      (componentDetail?.rows?.[0]?.cells?.length || 0) /
      (componentDetail?.numOfRepeatTable || 1);

    if (
      componentDetail?.isRepeat &&
      componentDetail?.isRepeatTable &&
      componentDetail?.numOfRepeatTable &&
      offsetItemLinked
    ) {
      rowIndex += offsetItemLinked * (componentDetail.numOfRepeatTable - 1);
    }

    if (cell?.isRepeatedTable && cell?.repeatedTableIndex) {
      rowIndex += numberOfRows * cell.repeatedTableIndex;
      cellIndex -= numberOfActualCell * cell.repeatedTableIndex;
    }

    const documentItem = this.documentItems![
      rowIndex + offsetItemLinked
    ] as DocumentItemDTO;

    const dynamicfieldData = documentItem?.data || {};
    const headerTitleId = !!cell?.isSubCell
      ? headerCells?.[cellIndex]?.subTable?.rows?.[subRowIndex]?.cells?.[
          subCellIndex
        ]?.cellId
      : headerCells?.[cellIndex]?.cellId;

    if (rowIndex + offsetItemLinked >= this.documentItems?.length) {
      return getContentNodeTypeNormalText({
        text: undefined,
        style: cell.style,
        isSubCell: !!cell?.isSubCell,
      });
    }

    // return type common
    const commonData = this.contentTdTypeCommon.getStructure({
      cell,
      currentCell: currentCell ?? cell,
      // because the getContentTdTypeCommon function has recalculated cellIndex, rowIndex
      // so in the case of RepeatTable, the initial value (_rowIndex, _cellIndex) needs to be passed
      rowIndex: _rowIndex,
      cellIndex: _cellIndex,
      subRowIndex,
      subCellIndex,
      documentItem,
    });

    if (commonData) {
      return commonData;
    }

    const cellLinkedData = cell?.cellLinkedData;
    switch (cell?.cellProperty) {
      case CellProperty.NO: {
        return getContentNodeTypeNormalText({
          text: documentItem?.id
            ? String((documentItem?.displayOrder || 0) + 1)
            : undefined,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }

      case CellProperty.DOCUMENT_DATA: {
        switch (cellLinkedData?.field) {
          case LinkedDataField.COMMON.REMARKS: {
            return getContentNodeTypeNormalText({
              text: documentItem?.memo,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_ITEM.LEVEL: {
            return getContentNodeTypeNormalText({
              text: this.documentCategory?.level,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_ITEM.TITLE: {
            return getContentNodeTypeNormalText({
              text: componentDetail?.isRepeatTable
                ? this.documentItems?.[Number(currentCell?.rowIndex) - 1]?.title
                : documentItem?.title,
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.DOCUMENT_ITEM.LAST_CHANGE_STATUS_DATETIME: {
            return getContentNodeTypeNormalText({
              text: formatDateCurrent(
                documentItem?.startDateScheduled,
                cellLinkedData?.options?.dateFormat
              ),
              style: cell.style,
              isSubCell: !!cell?.isSubCell,
            });
          }

          case LinkedDataField.COMMON.CHECK_BOX: {
            const options =
              cellLinkedData?.options?.valueOfCheckbox
                ?.split("・")
                .filter((e) => e) || [];
            const isNoneLabel = options.length === 0;
            const dynamicfieldCheckboxValue =
              dynamicfieldData[`${headerTitleId}-${documentItem?.id}`] || "";
            const checkboxValue = isNoneLabel
              ? ""
              : dynamicfieldCheckboxValue !== EMPTY_OPTION
              ? dynamicfieldCheckboxValue
              : "";

            return getContentTypeCheckbox({
              cell: { ...cell, value: checkboxValue },
              documentItem,
              documentType: this.template?.documentType,
              component: this.component,
            });
          }

          default:
            return null;
        }
      }

      default: {
        return getContentNodeTypeNormalText({
          text: cell.value,
          style: cell.style,
          isSubCell: !!cell?.isSubCell,
        });
      }
    }
  }
}

export default ContentTdTypeCommissionTable;

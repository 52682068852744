import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconPinOnMap({ color, ...rest }: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 32 32"
      fill="none"
      {...rest}
    >
      <path
        fill={color as any}
        d="M16.091 32q-6.547-5.505-9.82-10.249Q3.002 17.008 3 13.043q0-5.827 3.884-9.435T16.09 0t9.207 3.608 3.884 9.435q0 3.965-3.272 8.708T16.091 32"
      />
    </Icon>
  );
}

import { Box, Button, Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import CustomTooltipByScroll from "components/ui/CustomTooltipByScroll";
import { DocumentTemplateType } from "constants/enum";
import { documentsIconPath } from "constants/file";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import useHandleEditFamily from "pages/forge-viewer/hooks/useHandleEditFamily";
import { LeftPanelHandleType } from "pages/forge-viewer/LeftPanel";
import React, { forwardRef, Fragment, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsCreateDocumentItem } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { DocumentCategoryList } from "./DocumentCategoryList";
import useDocumentTemplate from "./hooks";

interface Props {
  leftPanelRef?: React.RefObject<LeftPanelHandleType>;
  onClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
}

export type DocumentTemplateHandleType = {
  handleCollapse: () => void;
};

const DocumentTemplateModal = forwardRef<DocumentTemplateHandleType, Props>(
  ({ leftPanelRef, onClickDocumentCategory }, ref) => {
    const { isOnline, sizePanel } = useSelector(
      (state: RootState) => state.app
    );

    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );
    const { isLoadingDocument } = useSelector(
      (state: RootState) => state.document
    );

    const dispatch = useDispatch();
    const { isCollapsed, setIsCollapsed } = useCollapseModal();

    const {
      isTakasagoGroup,
      documentTemplateModalInfo,
      documentTemplateSelected,
      onGotoDocumentTemplateDetailPage,
    } = useDocumentTemplate();

    useEffect(() => {
      if (documentTemplateModalInfo?.id !== documentTemplateSelected?.id) {
        setIsCollapsed(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentTemplateModalInfo?.id, documentTemplateSelected?.id]);
    const parentRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [{ isOpen: isEditingFamily }, { toggle: toggleEditingFamily }] =
      useHandleEditFamily();

    const handleCollapse = () => {
      setIsCollapsed((prev) => !prev);
    };

    const childrenDoc = useMemo(() => {
      return (
        <DocumentCategoryList
          documentTemplate={documentTemplateModalInfo}
          parentRef={parentRef}
          onClickDocumentCategory={onClickDocumentCategory}
        />
      );
    }, [documentTemplateModalInfo, onClickDocumentCategory]);

    const isShowButtonToggleEditFamily = useMemo(
      () =>
        [
          DocumentTemplateType.PHOTO_LEDGER,
          DocumentTemplateType.COMMISSIONING_TABLE,
        ].includes(
          documentTemplateSelected?.documentType as DocumentTemplateType
        ),
      [documentTemplateSelected?.documentType]
    );

    return (
      <>
        <Box
          id={RIGHT_SIDEBAR_MODAL_CLASSNAME}
          w={isCollapsed ? "0" : widthPanel}
          position="relative"
          height="100%"
          zIndex={11}
          transition="all 200ms ease-in-out 0s"
          filter={`drop-shadow(rgba(0, 0, 0, 0.15) 0px 8px 12px) drop-shadow(rgba(0, 0, 0, 0.3) 0px 4px 4px)`}
        >
          <Box
            ref={parentRef}
            bgColor="#FFFFFF"
            p="0"
            width="100%"
            height="100%"
            position="relative"
            flexDirection="column"
          >
            <CollapseModalIcon
              backgroundColor="white"
              borderRadius="5px 0px 0px 5px"
              borderRightStyle="hidden"
              borderLeftStyle="solid"
              height="12rem"
              width="3.8rem"
              top="50%"
              iconProps={{
                transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
              }}
              transform="translateY(-50%)"
              onClose={handleCollapse}
            />

            <Flex
              ref={containerRef}
              flexDirection="column"
              overflow="hidden scroll"
              h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
            >
              {!documentTemplateModalInfo?.id || isLoadingDocument ? (
                <Flex mt="2rem" alignItems="center" justifyContent="center">
                  <Spinner color="blue.500" size={"xl"} mt="1rem" />
                </Flex>
              ) : (
                <>
                  {isEditingFamily && (
                    <Box
                      pos="absolute"
                      zIndex="overlay"
                      backgroundColor="rgba(74,85,91,0.3)"
                      width="100%"
                      height="100%"
                      cursor="not-allowed"
                    ></Box>
                  )}

                  <Flex p="16px 22px 8px 22px" alignItems="center" gap="1rem">
                    <SvgIcon
                      width="2.4rem"
                      height="2.4rem"
                      flexShrink={0}
                      src={documentsIconPath}
                      sx={{
                        path: {
                          stroke: "#737373",
                        },
                      }}
                    />
                    <Text
                      fontSize="14px"
                      p={{ lg: "1rem", base: ".5rem" }}
                      fontWeight="bold"
                      textAlign="center"
                      justifyItems="center"
                      color="#171717"
                    >
                      書類概要
                    </Text>

                    {isShowButtonToggleEditFamily && (
                      <Button
                        position="relative"
                        ml="auto"
                        height="4rem"
                        borderRadius={6}
                        onClick={() => {
                          toggleEditingFamily?.();
                          dispatch(setIsCreateDocumentItem(false));
                        }}
                        isDisabled={!isOnline}
                        {...(isEditingFamily
                          ? {
                              zIndex: "modal",
                              variant: "blueLite",
                            }
                          : {
                              variant: "white",
                            })}
                      >
                        一括抽出・削除
                      </Button>
                    )}

                    {isTakasagoGroup && (
                      <Button
                        ml={isShowButtonToggleEditFamily ? "initial" : "auto"}
                        height="4rem"
                        fontSize="1.4rem"
                        variant="white"
                        p="1.2rem 1.6rem"
                        border="1px solid #A3A3A3"
                        borderRadius={6}
                        color="#009BE0"
                        onClick={onGotoDocumentTemplateDetailPage}
                        isDisabled={!isOnline}
                      >
                        帳票テンプレ編集
                      </Button>
                    )}
                  </Flex>

                  <VStack
                    pb="2rem"
                    spacing="0"
                    fontSize="1.4rem"
                    color="#000"
                    cursor="pointer"
                    position="relative"
                  >
                    <Flex
                      w="100%"
                      px="2rem"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={5}
                    >
                      <CustomTooltipByScroll
                        parentRef={containerRef}
                        label={documentTemplateModalInfo?.templateName || ""}
                        placement="bottom-start"
                        offset={[20, 0]}
                        textAlign="left"
                        fontSize="1.6rem"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      />
                    </Flex>
                    <Fragment key={documentTemplateModalInfo?.id}>
                      {childrenDoc}
                    </Fragment>
                  </VStack>
                </>
              )}
            </Flex>
          </Box>
        </Box>
      </>
    );
  }
);

export default DocumentTemplateModal;

import { Box, Textarea } from "@chakra-ui/react";
import { TypeOfEquipmentValueOptions } from "constants/documentTemplate";
import {
  BlackboardKey,
  CellProperty,
  LinkedFamilyData,
  TextPosition,
} from "constants/enum";
import { CommentManageExecute } from "interfaces/models/blackboard";
import {
  CellType,
  TableStyleType,
  TemplateComponent,
} from "interfaces/models/component";
import { FamilyInstance } from "interfaces/models/familyInstance";
import isEqual from "lodash/isEqual";
import { getCommentManageExecuteDefault } from "pages/document/template-page/utils";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import NormalTextListPreview from "./NormalTextListPreview";

interface iInputProps {
  value: string;
  property?: CellProperty;
  field?: LinkedFamilyData;
  textAlign?: TextPosition;
  minRow: number;
  typeOfEquipment?: string;
  typeOfEquipmentField?: string;
}

interface iProps {
  cells: CellType[];
  familyInstance: FamilyInstance;
  commentManageExecute?: any[];
  isOnlyViewBlackboard?: boolean;
  isAutoResize?: boolean;
  component: TemplateComponent;
  cellStyle?: Partial<TableStyleType>;
  onChangeCommentManageExecute?: (value: any[]) => void;
}

const LINE_HEIGHT = 1.45;

const CommentManageExecuteTextInput = ({
  cells,
  cellStyle,
  familyInstance,
  isOnlyViewBlackboard,
  isAutoResize = true,
  commentManageExecute,

  onChangeCommentManageExecute,
}: iProps) => {
  const [inputs, setInputs] = useState<iInputProps[]>([
    { value: "", textAlign: TextPosition.START, minRow: 1 },
  ]);

  const inputRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const boxContainerRef = useRef<HTMLDivElement>(null);
  const isChangedValueRef = useRef(false);
  const updateInputsRef = useRef<iInputProps[] | undefined>(undefined);
  const blurInputsRef = useRef<iInputProps[] | undefined>(undefined);

  const heightPerRow = useMemo(
    () => (cellStyle?.fontSize || 12) * LINE_HEIGHT,
    [cellStyle?.fontSize]
  );

  const commentManageExecuteDefault = useMemo(
    () =>
      getCommentManageExecuteDefault({
        cellOfBlackboards: cells,
        familyInstance,
      }),
    [cells, familyInstance]
  );

  // init value for inputs
  useEffect(() => {
    const commentManageExecutes = commentManageExecute || [];

    const inputs =
      !commentManageExecute?.length || !Array.isArray(commentManageExecutes)
        ? commentManageExecuteDefault
        : (commentManageExecutes as any[]);

    blurInputsRef.current = inputs;
    setInputs((prev) =>
      JSON.stringify(prev) !== JSON.stringify(inputs) ? inputs : prev
    );
  }, [commentManageExecute, commentManageExecuteDefault]);

  // save data when unmount but not blur
  useEffect(() => {
    return () => {
      const isChangedValue = isChangedValueRef.current;
      const updateInputs = updateInputsRef.current;

      if (isChangedValue && !!updateInputs && !!onChangeCommentManageExecute) {
        updateInputsRef.current = undefined;
        isChangedValueRef.current = false;

        onChangeCommentManageExecute?.(updateInputs);
      }
    };
  }, [onChangeCommentManageExecute]);

  const getMinRow = (params: { key: number; valueStr: string }) => {
    const { key, valueStr } = params;

    const inputEle = inputRefs.current[key];
    let minRow = (valueStr.match(/\n/g)?.length || 0) + 1;
    if (inputEle) {
      inputEle.style.minHeight = `${inputEle.scrollHeight}px`;
      minRow = Math.round(inputEle.scrollHeight / heightPerRow);
    }

    return minRow;
  };

  const tranformInputValue = (input: CommentManageExecute) => {
    let value = input.value || "";
    const typeOfEquipmentField = input?.typeOfEquipmentField || "";
    const label = TypeOfEquipmentValueOptions.find(
      (opt) => opt.value === typeOfEquipmentField
    )?.name;
    const title = `${label}: `;
    if (label) {
      value = `${label}: ${value.replaceAll(title, "")}`;
    }

    return { value, typeOfEquipmentField, title };
  };

  const handleChangeInputData = ({
    field,
    key,
    value,
  }: {
    field: keyof iInputProps;
    value: string;
    key: number;
  }) => {
    isChangedValueRef.current = true;
    const updateInputs = structuredClone(inputs);
    const minRow = getMinRow({ key, valueStr: value });

    updateInputs[key][field] = value as never;
    updateInputs[key].minRow = minRow;
    updateInputsRef.current = updateInputs;

    setInputs(updateInputs);
  };

  const handleBlurInputData = ({
    field,
    key,
    value,
    relatedTarget,
  }: {
    field: keyof iInputProps;
    value: string;
    key: number;
    relatedTarget: Element | null;
  }) => {
    if (!boxContainerRef.current) {
      return;
    }
    const parentElement = boxContainerRef.current.parentElement?.parentElement;
    if (!parentElement) {
      return;
    }
    if (
      relatedTarget?.contains(parentElement) ||
      parentElement.contains(relatedTarget)
    ) {
      return;
    }

    if (isEqual(blurInputsRef.current, inputs)) {
      return;
    }

    isChangedValueRef.current = false;
    updateInputsRef.current = undefined;
    const updateInputs = structuredClone(inputs);
    const minRow = getMinRow({ key, valueStr: value });
    updateInputs[key][field] = value as never;
    updateInputs[key].minRow = minRow;
    blurInputsRef.current = updateInputs;

    onChangeCommentManageExecute?.(updateInputs);
  };

  if (isOnlyViewBlackboard) {
    const styles = inputs.map((input) => {
      return {
        textAlign: input.textAlign || (TextPosition.START as any),
        padding: 0,
        width: "100%",
        flexShrink: 0,
      };
    });
    const datas = inputs.map((input, inputKey) => {
      const data: Partial<CellType> = {
        cellId: String(inputKey),
        cellLinkedData: {
          field: BlackboardKey.COMMENT_MANAGE_EXECUTE,
        },
      };
      const { value } = tranformInputValue(input);

      return {
        ...data,
        value,
      };
    });

    return (
      <NormalTextListPreview
        isAutoResize={isAutoResize}
        datas={datas}
        styles={styles}
      />
    );
  }

  const handleFocus = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target;
    const indexInput = inputs.findIndex(
      (_, key) => inputRefs.current[key] === target
    );
    inputRefs.current?.at(indexInput)?.focus();
  };

  return (
    <Box
      flex={1}
      ref={boxContainerRef}
      display="flex"
      flexDir="column"
      padding="0.5rem"
      border="1px solid #fff"
      borderRadius="4px"
      cursor="text"
      onClick={handleFocus}
    >
      {inputs.map((input, key) => {
        const { value, typeOfEquipmentField, title } =
          tranformInputValue(input);
        const isNotUpdateData = (value: string) =>
          typeOfEquipmentField && title.length > value.length;

        return (
          <Textarea
            key={key}
            style={{
              fontSize: cellStyle?.fontSize,
              minHeight: (input.minRow || 1) * heightPerRow,
              height: "fit-content",
              textAlign: input.textAlign as any,
              padding: 0,
            }}
            resize="none"
            border="none"
            rows={input.minRow || 1}
            _focus={{ border: "none" }}
            _focusVisible={{ boxShadow: "none" }}
            ref={(el) => (inputRefs.current[key] = el)}
            value={value}
            onBlur={(e) => {
              if (isNotUpdateData(e.target.value)) {
                return;
              }

              handleBlurInputData({
                relatedTarget: e.relatedTarget,
                field: "value",
                value: e.target.value,
                key,
              });
            }}
            onChange={(e) => {
              if (isNotUpdateData(e.target.value)) {
                return;
              }

              handleChangeInputData({
                field: "value",
                value: e.target.value,
                key,
              });
            }}
          />
        );
      })}
    </Box>
  );
};

export default memo(CommentManageExecuteTextInput);

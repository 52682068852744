import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  HTMLChakraProps,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import CustomTooltipByScroll from "components/ui/CustomTooltipByScroll";
import { CellProperty } from "constants/enum";
import {
  MutableRefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getAllCells, getComponentByCellId } from "utils/tableCell";

interface CustomMenuProps extends HTMLChakraProps<any> {
  options: {
    name: string;
    value: string;
    iconSrc?: string;
    command?: string;
    isGroupTitle?: boolean;
  }[];
  showAddItem?: boolean;
  placeHolder?: string;
  value?: string;
  defaultValueIsEmpty?: boolean;
  onChange?: (value: any, preValue?: string) => void;
  onAddNewItem?: (value: string) => void;
  defaultValue?: string;
  showIcon?: boolean;
  isDisabled?: boolean;
  boxMenuListContainerProps?: BoxProps;
  menuItemProps?: MenuItemProps;
}

const CustomMenu = (props: CustomMenuProps) => {
  const {
    options = [],
    showAddItem,
    placeHolder = "選択します",
    onChange,
    onAddNewItem,
    value,
    defaultValueIsEmpty = false,
    defaultValue,
    showIcon,
    isDisabled,
    boxMenuListContainerProps,
    menuItemProps,
    ...rest
  } = props;
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const inputRef = useRef(null) as MutableRefObject<any>;
  const { selectedCells, components } = useSelector(
    (state: RootState) => state.document
  );

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const displayValue = useMemo(() => {
    const item = options.find(
      (item) => item.value === (value || selectedValue)
    );
    if (item && (!!value || defaultValueIsEmpty)) {
      return (
        <Flex gap="5px" alignItems="center">
          {showIcon && (
            <SvgIcon src={String(item?.iconSrc)} h="1.8rem" w="1.8rem" />
          )}
          <Text>{item?.name?.replace("　", "")}</Text>
        </Flex>
      );
    }

    return <Text color="#B6BDC8">{placeHolder}</Text>;
  }, [
    options,
    placeHolder,
    selectedValue,
    showIcon,
    value,
    defaultValueIsEmpty,
  ]);

  const isExistNumOfOrderField = useMemo(() => {
    const currentComponent = getComponentByCellId({
      cellId: selectedCells?.[0]?.cellId,
      components,
    });
    const allCells = getAllCells(currentComponent);

    return allCells?.some((cell) => cell.value === CellProperty.NO);
  }, [components, selectedCells]);

  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isDisabled={isDisabled}
            ref={dropdownRef}
            variant="whiteIcon"
            as={Button}
            textAlign="left"
            pl="2rem"
            lineHeight="4rem"
            color="#171717"
            border="1px solid #A3A3A3"
            borderRadius="4px"
            rightIcon={
              <SvgIcon
                src="/img/icon-arrow-down-small.svg"
                transition="0.4s"
                transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
              />
            }
            boxShadow="unset"
            css={`
              .chakra-text {
                text-overflow: ellipsis;
                overflow: hidden !important;
                display: inline;
              }
            `}
            _expanded={{ opacity: 1 }}
            _focus={{ boxShadow: "none" }}
            {...rest}
          >
            {displayValue}
          </MenuButton>

          <MenuList
            ref={menuRef}
            position="relative"
            minW={Number(dropdownRef.current?.clientWidth)}
            maxW={dropdownRef.current?.clientWidth}
            {...(!!options?.length
              ? {}
              : {
                  p: 0,
                  borderWidth: 0,
                })}
            maxH="20rem"
            overflowY="auto"
            padding="0px"
          >
            <Box
              className="box-scroll-bar"
              maxH="27rem"
              {...boxMenuListContainerProps}
            >
              {options?.map(
                (
                  { name, value: currentValue, iconSrc, command, isGroupTitle },
                  index
                ) => (
                  <MenuItem
                    key={index}
                    lineHeight="4rem"
                    height="4rem"
                    icon={
                      showIcon && iconSrc ? (
                        <SvgIcon src={String(iconSrc)} h="1.8rem" w="1.8rem" />
                      ) : undefined
                    }
                    command={command}
                    onClick={() => handleChange(currentValue)}
                    borderBottom="1px solid var(--primary-border-color)"
                    _last={{
                      borderBottom: "none",
                    }}
                    sx={{
                      _hover: {
                        bgColor: isGroupTitle ? "inherit" : "#F0F9FF",
                      },
                      borderBottom: isGroupTitle
                        ? "none"
                        : "1px solid var(--primary-border-color)",
                      cursor: isGroupTitle ? "not-allowed" : "pointer",
                      fontWeight: isGroupTitle ? "bold" : "initial",
                    }}
                    color="#171717"
                    {...menuItemProps}
                    {...(isExistNumOfOrderField &&
                    currentValue === CellProperty.NO
                      ? {
                          cursor: "not-allowed",
                        }
                      : {
                          onClick: () => handleChange(currentValue),
                        })}
                  >
                    <CustomTooltipByScroll parentRef={menuRef} label={name}>
                      <Flex alignItems="center" gap="0.5rem" width="100%">
                        {!isGroupTitle && (
                          <Box flexShrink={0} width="1.6rem" height="1.6rem">
                            {value === currentValue && (
                              <SvgIcon
                                src="/img/icon-action-check_circle.svg"
                                width="1.6rem"
                                height="1.6rem"
                              />
                            )}
                          </Box>
                        )}

                        <Text className="text-ellipsis" color="#171717">
                          {name.replace("　", "")}
                        </Text>
                      </Flex>
                    </CustomTooltipByScroll>
                  </MenuItem>
                )
              )}
            </Box>

            {showAddItem && (
              <Flex
                h="4rem"
                w="100%"
                mt="1rem"
                position="absolute"
                bottom="-4.5rem"
                left="0"
              >
                <Input
                  h="100%"
                  bg="white"
                  ref={inputRef}
                  boxShadow="0 1px 5px 0 rgba(0, 0, 0, 0.2)"
                />
                <Button
                  onClick={() => {
                    onAddNewItem?.(inputRef?.current?.value);
                    inputRef.current.value = "";
                  }}
                  leftIcon={<AddIcon />}
                  h="4rem"
                  ml="1rem"
                  minW="7rem"
                >
                  項目追加
                </Button>
              </Flex>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default memo(CustomMenu);

import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconCreateTask(props: IconProps) {
  return (
    <Icon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M14 16.75a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M19 11.75a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-1.5 0v-8.5a.75.75 0 0 1 .75-.75"
        clipRule="evenodd"
      />
      <path
        fill="#171717"
        fillRule="evenodd"
        d="M5 3.75c-.686 0-1.25.564-1.25 1.25v14.623l1-1.219a.75.75 0 0 1 .58-.274h7.285a.75.75 0 0 1 0 1.5h-6.93L3.58 22.196a.75.75 0 0 1-1.33-.476V5A2.756 2.756 0 0 1 5 2.25h14.9A2.756 2.756 0 0 1 22.65 5v5.94a.75.75 0 0 1-1.5 0V5c0-.686-.564-1.25-1.25-1.25z"
        clipRule="evenodd"
      />
    </Icon>
  );
}

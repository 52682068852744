import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconHand(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#171717"
        fillRule="evenodd"
        d="m5.117 18.36-2.46-6.35c-.664-1.74.92-3.705 2.877-3.06l.01.004.637.215q.422.143.78.39V4.71c0-1.365 1.087-2.542 2.513-2.542q.24 0 .463.042A2.51 2.51 0 0 1 12.312.5c.973 0 1.788.548 2.208 1.33q.3-.079.63-.08c1.418 0 2.501 1.165 2.512 2.52q.159-.02.325-.02c1.426 0 2.513 1.177 2.513 2.542v8.541c0 4.473-3.535 8.167-7.986 8.167-3.283 0-6.2-2.059-7.397-5.14M17.663 5.804c-.401.14-.69.53-.69.988V12h-.81V4.292c0-.575-.454-1.042-1.014-1.042a1 1 0 0 0-.324.054c-.4.14-.69.53-.69.988V12h-.81V3.042q-.002-.219-.08-.408A1.01 1.01 0 0 0 12.311 2a1.026 1.026 0 0 0-1.014 1.042V12h-.81V4.708a1.04 1.04 0 0 0-.69-.987 1 1 0 0 0-.324-.054c-.56 0-1.013.466-1.013 1.041V14.5h-.608L6.71 11.558a1.65 1.65 0 0 0-1.006-.966l-.64-.217c-.657-.217-1.257.442-1.006 1.1l2.457 6.342c.98 2.525 3.356 4.183 6 4.183C16.097 22 19 19.017 19 15.333V6.792c0-.575-.454-1.042-1.013-1.042a1 1 0 0 0-.324.054"
        clipRule="evenodd"
      />
    </Icon>
  );
}

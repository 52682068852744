import { useBoolean } from "@chakra-ui/react";
import { areaApi, bimFileApi, documentItemApi } from "apiClient/v2";
import { message } from "components/base";
import { TYPE_USER } from "constants/app";

import {
  CURRENT_LEVEL_KEY,
  DISPLAY_MODE,
  LEVEL_ALL,
  LEVEL_OTHER,
} from "constants/forge";
import { FORGE_DATA_FOLDER_PATH } from "constants/s3";
import useFetchFamilies from "hooks/useFetchFamilies";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level } from "interfaces/models";
import { NeptuneArea, Space } from "interfaces/models/area";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setIsLoadingDocument } from "redux/documentSlice";
import {
  fetchNeptuneAreasByBimFile,
  setDisplayMode,
  setIsGeneratingFamilyIntances,
  setIsGeneratingSpaces,
  setIsLoadedExternalId,
  setIsLoadedNeptuneAreas,
  setIsLoadedSpaces,
  setLevelSelected,
  setLoadedFamilyInstances,
  setNeptuneAreas,
  setSpaces,
} from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import { getNetworkStatus } from "utils/common";

import { removeFileS3 } from "utils/file";

import {
  getDataFamilyInstance,
  uploadFamilyInstancesToS3,
  getDataSpace,
  setFamilyInstances,
} from "utils/forge/data";
import { getAreaExtension } from "utils/forge/extensions/area-extension";

import {
  getFamilyInstancesProperties,
  getSpaces,
  ___viewer3d,
} from "utils/forge/forge3d";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import { uploadMultipartToS3 } from "utils/upload-multipart";
import useArea from "./useArea";

interface Props {
  isSettingFloor: boolean;
}

export default function useGenerateData(props: Props) {
  //#region forge data
  const { isSettingFloor } = props;

  const {
    isShowArea,
    isGeneratingSpaces,
    isLoadedViewerModelData,
    isLoadedViewer,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    displayMode,
    levels,
    levelSelected,
    isLoadedLevels,
    isLoadedFamilies,
    isInitialized,
  } = useSelector((state: RootState) => state.forgeViewer);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const {
    documentCategorySelected,
    documentItemSelected,
    documentGroupSelected,
  } = useSelector((state: RootState) => state.document);

  const dispatch = useDispatch();
  const { bimFileId, version } = useParams();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const hasPermission = useMemo(
    () => !currentUser?.role || currentUser.role === TYPE_USER.ROLE_ADMIN,
    [currentUser?.role]
  );
  const [isSpaceDataAllLevel, setIsSpaceDataAllLevel] = useState(false);
  const { getObjectTypesOfFamilyInstance } = useFetchFamilies();

  const { areas, spaces, allAreas, allSpaces } = useArea({});
  const areasRef = useRef<NeptuneArea[]>([]);
  const spacesRef = useRef<Space[]>([]);
  const allAreasRef = useRef<NeptuneArea[]>([]);
  const allSpacesRef = useRef<Space[]>([]);
  const levelSelectedRef = useRef<Level | undefined>(levelSelected);
  const documentGroupSelectedRef = useRef<DocumentGroup | undefined>(
    documentGroupSelected
  );
  const documentItemSelectedRef = useRef<DocumentItemDTO | undefined>(
    documentItemSelected
  );
  const documentCategorySelectedRef = useRef<DocumentCategoryDTO | undefined>(
    documentCategorySelected
  );
  const isShowAreaRef = useRef(true);

  areasRef.current = areas;
  spacesRef.current = spaces;
  allAreasRef.current = allAreas;
  allSpacesRef.current = allSpaces;
  levelSelectedRef.current = levelSelected;
  documentItemSelectedRef.current = documentItemSelected;
  documentCategorySelectedRef.current = documentCategorySelected;
  documentGroupSelectedRef.current = documentGroupSelected;
  isShowAreaRef.current = isShowArea;

  const [shouldGenerateFamilyInstanceData, setGenerateFamilyInstanceData] =
    useBoolean();
  const [shouldGenerateSpacesData, setGenerateSpacesData] = useBoolean();

  const updateReduxDataProject = useCallback(
    (isGenerated: boolean) => {
      dispatch(
        setDataProjectDetail({
          ...dataProjectDetail,
          isGenerated,
          defaultBimPathId: dataProjectDetail?.defaultBimPathId,
        } as DataProjectModel)
      );
    },
    [dataProjectDetail, dispatch]
  );

  const lastFetchFamilyArgs = useRef<{
    bimFileId: string | null;
    version: string | null;
    levelSelected: Level | null;
  }>({ bimFileId: null, levelSelected: null, version: "" });
  useEffect(() => {
    if (
      isSettingFloor ||
      !bimFileId ||
      !version ||
      isGeneratingSpaces ||
      !isLoadedFamilies ||
      levelSelected?.guid === undefined ||
      !isInitialized
    ) {
      return;
    }

    const lastLevelSelected = lastFetchFamilyArgs.current.levelSelected;
    if (
      lastFetchFamilyArgs.current.bimFileId === bimFileId &&
      lastLevelSelected?.guid === levelSelected?.guid &&
      lastFetchFamilyArgs.current.version === version
    ) {
      // if same level -> not need refetch
      return;
    }
    lastFetchFamilyArgs.current = {
      bimFileId,
      version,
      levelSelected,
    };

    dispatch(setIsLoadingDocument(true));
    dispatch(setLoadedFamilyInstances(false));
    setFamilyInstances({});
    (async () => {
      // load family instances data
      const familyInstancesData = await getDataFamilyInstance({
        bimFileId,
        version,
        level: levelSelected?.guid ? levelSelected.label : LEVEL_OTHER.label,
      });

      const isOnline = getNetworkStatus();
      if (familyInstancesData) {
        const familyInstances = familyInstancesData?.familyInstances || {};
        const familyInstanceKeys = Object.keys(familyInstances);
        familyInstanceKeys.forEach((id: string) => {
          familyInstances[id].objectTypes = getObjectTypesOfFamilyInstance(
            familyInstances[id]
          );
        });
        if (isOnline && !dataProjectDetail?.isGenerated && hasPermission) {
          await bimFileApi.updateProject({
            id: bimFileId,
            isGenerated: true,
            defaultBimPathId: dataProjectDetail?.defaultBimPathId,
          });
          updateReduxDataProject(true);
        }
        setFamilyInstances(familyInstances);
        dispatch(setLoadedFamilyInstances(true));
        dispatch(setIsGeneratingFamilyIntances(false));
        setGenerateFamilyInstanceData.off();
      } else {
        setGenerateFamilyInstanceData.on();
        dispatch(setIsGeneratingFamilyIntances(true));
        lastFetchFamilyArgs.current.levelSelected = LEVEL_ALL;
        if (isOnline && dataProjectDetail?.isGenerated && hasPermission) {
          await bimFileApi.updateProject({
            id: bimFileId,
            isGenerated: false,
            defaultBimPathId: dataProjectDetail?.defaultBimPathId,
          });
          updateReduxDataProject(false);
          if (familyInstancesData) {
            const dataFile = `${FORGE_DATA_FOLDER_PATH}/f-data-${encodeURIComponent(
              bimFileId
            )}-v${version}.json`;
            await removeFileS3({ keys: [dataFile] });
          }
        }
        if (levelSelected.guid) {
          const currentLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};

          currentLevel[String(bimFileId)] = "";
          dispatch(setIsLoadedExternalId(false));
          setLocalStorage(CURRENT_LEVEL_KEY, currentLevel);
          dispatch(setLevelSelected(LEVEL_ALL));
          currentLevel[String(bimFileId)] = "";
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    version,
    isGeneratingSpaces,
    isLoadedFamilies,
    isSettingFloor,
    levelSelected,
    isInitialized,
    hasPermission,
    updateReduxDataProject,
  ]);

  useEffect(() => {
    const urn = dataProjectDetail?.defaultBimPathId?.split("/").pop();

    if (
      !dataProjectDetail?.defaultBimPathId ||
      !shouldGenerateFamilyInstanceData ||
      !bimFileId ||
      !version ||
      !isLoadedLevels ||
      !isLoadedViewerModelData ||
      !isLoadedViewer ||
      !___viewer3d ||
      !bimFileId ||
      !urn ||
      !isLoadedNeptuneAreas ||
      !isLoadedSpaces ||
      !isSpaceDataAllLevel
    ) {
      return;
    }
    (async () => {
      const viewer = ___viewer3d;
      // draw all area when generate family instances
      const areaExtension = getAreaExtension();
      areaExtension?.setAreasToDraw(allAreasRef.current);
      areaExtension?.setSpacesToDraw(allSpacesRef.current);
      await areaExtension?.drawAreas({
        initialize: true,
        isDrawAreaLabel: false,
      });

      const result = await generateFamilyInstancesData({
        viewer,
        bimFileId,
        version,
        projectId: dataProjectDetail.projectId,
        versionId: decodeURIComponent(urn),
      });
      if (!result) {
        return;
      }
      const defaultLevel = levels.filter((level) => level.guid)?.[0];
      lastFetchFamilyArgs.current.levelSelected = defaultLevel;
      dispatch(setLevelSelected(defaultLevel));
      dispatch(
        setDisplayMode(
          !!defaultLevel?.guid && defaultLevel?.sheets?.length
            ? DISPLAY_MODE["2D"]
            : DISPLAY_MODE["3D"]
        )
      );
      const currentLevel = getLocalStorage("currentLevel") || {};
      currentLevel[String(bimFileId)] = defaultLevel.guid;
      setLocalStorage("currentLevel", currentLevel);

      // set areas by level selected
      areaExtension?.setAreasToDraw(areasRef.current);
      areaExtension?.setSpacesToDraw(spacesRef.current);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataProjectDetail?.defaultBimPathId,
    dataProjectDetail?.id,
    bimFileId,
    shouldGenerateFamilyInstanceData,
    isLoadedViewerModelData,
    version,
    isLoadedLevels,
    levelSelected.guid,
    displayMode,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    isLoadedViewer,
    isSpaceDataAllLevel,
  ]);

  const lastFetchSpacesArgs = useRef<{
    bimFileId: string | null;
    version: string | null;
    levelSelected: Level | null;
  }>({
    bimFileId: null,
    levelSelected: null,
    version: "",
  });
  // #region generate spaces
  useEffect(() => {
    if (
      !bimFileId ||
      !version ||
      levelSelected?.guid === undefined ||
      isSpaceDataAllLevel ||
      !isInitialized
    ) {
      return;
    }
    const lastLevelSelected = lastFetchSpacesArgs.current.levelSelected;
    if (
      lastFetchSpacesArgs.current.bimFileId === bimFileId &&
      lastLevelSelected?.guid === levelSelected?.guid &&
      lastFetchSpacesArgs.current.version === version
    ) {
      // if same level -> not need refetch
      return;
    }
    lastFetchSpacesArgs.current = {
      bimFileId,
      version,
      levelSelected,
    };

    dispatch(setIsLoadedSpaces(false));
    dispatch(setIsLoadedNeptuneAreas(false));
    dispatch(setSpaces([]));

    (async () => {
      const selectedGuid = levelSelected.guid;
      const spacesData = await getDataSpace({
        bimFileId,
        version,
        level: levelSelected?.guid ? levelSelected?.label : LEVEL_OTHER.label,
      });
      if (selectedGuid !== lastFetchSpacesArgs.current.levelSelected?.guid) {
        return;
      }
      if (spacesData) {
        setIsSpaceDataAllLevel(!levelSelected.guid);
        dispatch(setIsGeneratingSpaces(false));
        const spaces = spacesData?.spaces || [];
        dispatch(setSpaces(spaces));
        dispatch(setIsLoadedSpaces(true));
        setGenerateSpacesData.off();

        const timeout = setTimeout(() => {
          dispatch(fetchNeptuneAreasByBimFile({ bimFileId }));
        }, 500);

        return () => {
          timeout && clearTimeout(timeout);
        };
      } else {
        setGenerateSpacesData.on();
        dispatch(setIsGeneratingSpaces(true));
        if (levelSelected.guid) {
          const level = levels.find((f) => !f.guid) || LEVEL_ALL;
          lastFetchSpacesArgs.current.levelSelected = level;
          dispatch(setLevelSelected(level));
          // set false for waiting refetch external id all level
          dispatch(setIsLoadedExternalId(false));
          const currentLevel = getLocalStorage("currentLevel") || {};
          currentLevel[String(bimFileId)] = "";
          setLocalStorage("currentLevel", currentLevel);

          return;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bimFileId, bimFileId, version, levelSelected, isInitialized]);

  useEffect(() => {
    if (
      isGeneratingSpaces &&
      bimFileId &&
      version &&
      isLoadedViewer &&
      isLoadedViewerModelData &&
      !!___viewer3d
    ) {
      generateSpaceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    isGeneratingSpaces,
    isLoadedViewerModelData,
    isLoadedLevels,
    isLoadedViewer,
    version,
  ]);

  const generateSpaceData = useCallback(async () => {
    const spaces = (await getSpaces()).filter((e) => e.level);
    let newAreas: NeptuneArea[] = [];
    let newSpaces: Space[] = [];
    if (spaces) {
      const uploaded = await uploadMultipartToS3({
        filePath: FORGE_DATA_FOLDER_PATH,
        fileName: `f-spaces-${encodeURIComponent(bimFileId!)}-v${version}.json`,
        fileData: { spaces },
      });

      if (uploaded) {
        await areaApi.createNeptuneArea({
          bimFileId: bimFileId!,
          version: +version!,
        });

        newAreas = (await areaApi.getNeptuneAreaList(bimFileId!)).data;
        newSpaces = spaces;
      }
    }

    // dispatch(setIsLoadedViewer(false));
    dispatch(setIsGeneratingSpaces(false));
    dispatch(setIsLoadedNeptuneAreas(true));
    dispatch(setNeptuneAreas(newAreas));
    setIsSpaceDataAllLevel(true);
    dispatch(setSpaces(newSpaces));
    dispatch(setIsLoadedSpaces(true));
  }, [bimFileId, version, dispatch]);

  // generate family instances data in first access
  const generateFamilyInstancesData = async ({
    viewer,
    bimFileId,
    versionId,
    version,
    projectId,
  }: {
    viewer: Autodesk.Viewing.GuiViewer3D;
    bimFileId: string;
    version: string;
    versionId: string;
    projectId: string;
  }) => {
    const familyInstances = await getFamilyInstancesProperties({
      viewer,
      bimFileId,
      version,
      projectId,
      versionId,
    });
    if (!familyInstances) {
      return false;
    }
    if (!levelSelected.guid) {
      const uploaded = await uploadFamilyInstancesToS3({
        bimFileId,
        version,
        levels,
        familyInstances,
      });

      if (uploaded) {
        const defaultLevel = levels.filter((level) => level.guid)?.[0];
        const newFamilyInstances: { [key: string]: FamilyInstanceDTO } = {};

        if (hasPermission) {
          await Promise.all([
            documentItemApi.updateItemArea({ bimFileId, version }),
            bimFileApi.updateProject({
              id: bimFileId,
              isGenerated: true,
              defaultBimPathId: dataProjectDetail?.defaultBimPathId,
            }),
          ]);
          updateReduxDataProject(true);
        }

        Object.keys(familyInstances).forEach((id: string) => {
          if (familyInstances[id].level !== defaultLevel?.label) {
            return;
          }
          familyInstances[id].objectTypes = getObjectTypesOfFamilyInstance(
            familyInstances[id]
          );
        });
        setFamilyInstances(newFamilyInstances);
        dispatch(setLoadedFamilyInstances(true));
        setGenerateFamilyInstanceData.off();
        dispatch(setIsGeneratingFamilyIntances(false));

        return true;
      } else {
        message.error("エラーが発生しました。再度実行お願いします。");

        return false;
      }
    }

    return false;
  };

  return {
    shouldGenerateFamilyInstanceData,
    shouldGenerateSpacesData,
  };
}

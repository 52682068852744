import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconExpand(props: IconProps) {
  return (
    <Icon
      width="2.4rem"
      height="2.4rem"
      fill="none"
      viewBox="0 0 12 20"
      {...props}
    >
      <path
        fill="#737373"
        fillRule="evenodd"
        d="M3.529 15.805a.667.667 0 0 1 0-.943L8.39 10 3.529 5.138a.667.667 0 0 1 .942-.943L9.805 9.53c.26.26.26.682 0 .942L4.47 15.805a.667.667 0 0 1-.942 0z"
        clipRule="evenodd"
      />
    </Icon>
  );
}

import { s3Api } from "apiClient/v2";

import {
  FILE_STORE,
  Operation,
  STATIC_FILES_CACHE,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { axiosECS } from "services/baseAxios";

import { getNetworkStatus, sleep } from "./common";
import { getIndexedDb, IndexedDBType } from "./indexedDb";
import { logError } from "./logs";
import { updateIndexedDBItem } from "./serviceWorker";

const createUpdateItems = async (_requestName: string, item: any) => {
  let requestName = _requestName;

  if (requestName === "sub-items") {
    requestName = "document-items/sub-items";
  }

  return await axiosECS.post(`v2/${requestName}?syncOffline=true`, item);
};

const deleteItems = async (_requestName: string, ids: string[]) => {
  let requestName = _requestName;

  if (requestName === "sub-items") {
    requestName = "document-items/sub-items";
  }

  return axiosECS.delete(`v2/${requestName}?syncOffline=true`, {
    data: { ids },
  });
};

const patchItem = async (_requestName: string, item: any) => {
  let requestName = _requestName;

  if (requestName === "sub-items") {
    requestName = "document-items/sub-items";
  }

  return axiosECS.patch(`v2/${requestName}?syncOffline=true`, item);
};

const syncDataFileToOnline = async ({
  item: _item,
  retries = 5,
  indexedDb,
}: {
  item: iCachedItem;
  retries?: number;
  indexedDb: IndexedDBType;
}) => {
  const item = _item.data;

  if (_item.operation === Operation.Post) {
    const responsePresignedUrl = await s3Api.presignedUrl(item);
    const presignedUrl = responsePresignedUrl?.data;
    if (!presignedUrl) {
      return false;
    }

    let finalUrl = `${process.env.REACT_APP_S3_URL}/${item.filePath}/${item.fileName}`;
    if (!item.filePath) {
      finalUrl = `${process.env.REACT_APP_S3_URL}/${item.fileName}`;
    }
    const cache = await caches.open(STATIC_FILES_CACHE);
    const request = new Request(finalUrl, {
      method: "GET",
    });
    const file = await (
      await cache.match(request, {
        ignoreMethod: true,
        ignoreSearch: true,
        ignoreVary: true,
      })
    )?.blob();

    let isUploadFinish = false;
    if (file) {
      try {
        await s3Api.uploadToS3({ presignedUrl, file });
        isUploadFinish = true;
      } catch (err) {
        logError(err);
      }
    }

    const isRequestFail = !isUploadFinish && getNetworkStatus();
    if (isRequestFail && retries > 0) {
      await sleep(1000);
      await syncDataFileToOnline({
        item: _item,
        retries: retries - 1,
        indexedDb,
      });
    }

    // Only delete files when call api success or
    // call api fails but there is network to retry call api
    if (isUploadFinish) {
      await indexedDb?.deleteFile(_item.id);
    }

    return !isRequestFail;
  }

  if (_item.operation === Operation.Delete) {
    let isDeleteSuccess = false;
    try {
      await s3Api.deleteFiles([item]);
      isDeleteSuccess = true;
    } catch (err) {
      logError(err);
    }

    const isRequestFail = !isDeleteSuccess && getNetworkStatus();
    if (isRequestFail && retries > 0) {
      await sleep(1000);
      await syncDataFileToOnline({
        item: _item,
        retries: retries - 1,
        indexedDb,
      });
    }

    // Only delete files when call api success or
    // call api fails but there is network to retry call api
    if (isDeleteSuccess) {
      await indexedDb?.deleteFile(_item.id);
    }

    return !isRequestFail;
  }

  return true;
};

const syncDataStoreToOnline = async ({ item }: { item: iCachedItem }) => {
  const { data, operation, store, initData } = item;
  let isSuccess = false;
  let response = undefined;
  try {
    if (operation === Operation.Delete) {
      response = await deleteItems(store, [data]);
    }
    if (operation === Operation.Post || data.offlineId) {
      response = await createUpdateItems(store, data);
    }
    if (operation === Operation.Patch && !data.offlineId) {
      data.initData = initData;
      response = await patchItem(store, data);
    }
    isSuccess = !!response?.data || (response as any) === "{}";
  } catch (err) {
    logError(err);
  } finally {
    const isOnline = getNetworkStatus();
    const isRequestFail = !isSuccess && isOnline;
    if (isOnline && isSuccess) {
      await updateIndexedDBItem({
        storeName: store,
        id: item.id,
        data: {
          ...(response?.data ? { data: response.data } : {}),
          status: UpdateToOnlineStatus.Success,
        },
      });
    }

    return !isRequestFail;
  }
};

export const syncCachedDataToOnline = async () => {
  const indexedDb = await getIndexedDb();
  const dataFiles: iCachedItem[] = await indexedDb.getAll(FILE_STORE);
  const cacheItems: (iCachedItem & { isUploadFile?: boolean })[] = [];

  const dataStore: iCachedItem[] = await indexedDb?.getAll();
  cacheItems.push(...dataStore);
  dataFiles.forEach((item) => {
    cacheItems.push({ ...item, isUploadFile: true });
  });
  cacheItems.sort((a, b) => a.requestTime - b.requestTime);
  // not delete indexDb data after sync until call api get data
  for await (const item of cacheItems) {
    const { status } = item;
    if (status !== UpdateToOnlineStatus.Fail) {
      continue;
    }
    let result;
    if (!item?.isUploadFile) {
      result = await syncDataStoreToOnline({
        item,
      });
    } else {
      result = await syncDataFileToOnline({ item, indexedDb });
    }
    if (!result) {
      return false;
    }
  }

  return true;
};

import { Attribute, Node } from "@tiptap/core";
import { CellType } from "interfaces/models/component";
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_FONT_SIZE,
} from "pages/document/template-page/hooks";
import { NodeType } from "../type";

export interface TextBoxNodeAttrs {
  width: number;
  height: number;
  style: CellType["style"];
}

export const TextBoxNode = Node.create({
  name: NodeType.TEXT_BOX,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  content: `${NodeType.NORMAL_TEXT}*`,

  addAttributes() {
    const attrs: {
      [key in keyof TextBoxNodeAttrs]: Attribute;
    } = {
      style: { default: {} },
      width: { default: 0 },
      height: { default: 0 },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { style: css, width, height } = HTMLAttributes as TextBoxNodeAttrs;
    const borderColor = css?.borderColor || DEFAULT_BORDER_COLOR;

    return [
      "div",
      {
        style:
          `display: flex; position: relative; align-items: center; ` +
          `width: ${width}px; height: ${height}px; max-width: ${width}px; max-height: ${height}px; ` +
          `background-color: ${css?.backgroundColor || "#fff"}; ` +
          `font-size: ${
            css?.fontSize || DEFAULT_FONT_SIZE
          }px; pointer-events: auto; ` +
          `border: ${css?.border ? `1px solid ${borderColor}` : "none"}; `,
      },
      0,
    ];
  },
});

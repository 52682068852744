import { setComponents } from "redux/documentSlice";
import { Dispatch } from "@reduxjs/toolkit";
import store from "redux/store";
import { DocumentTemplateType } from "constants/enum";
import { CellType, TemplateComponent } from "interfaces/models/component";

interface UpdateCellsI {
  updatedCells: CellType[];
  updatedComponentIds: string[];
  components: TemplateComponent[];
  documentType?: DocumentTemplateType;
  dispatch?: Dispatch<any>;
  option?: {
    isResetBlackboardTemplate?: boolean;
    isNotDispatch?: boolean;
  };
}

interface GetCellByCellIdI {
  cellId: string;
  component: TemplateComponent;
}

interface GetCellPositionByCellIdI extends GetCellByCellIdI {}
interface GetParentCellByCellIdI extends GetCellByCellIdI {}

interface CellPosition {
  index: {
    row: number;
    col: number;
  };
  parentIndex: { row: number; col: number };
}

interface GetComponentByCellIdI {
  cellId: string;
  components: TemplateComponent[];
}

interface GetCellsByColumnIdI {
  columnId: string;
  component: TemplateComponent;
}

interface GetCellsByRowId {
  rowId: string;
  component: TemplateComponent;
}

/**
 * This function updates cells in multiple components
 * @param {UpdateCellsI}
 */
export const updateCells = ({
  updatedCells,
  updatedComponentIds,
  components: _components,
  documentType,
  dispatch,
  option,
}: UpdateCellsI) => {
  const isResetBlackboardTemplate = option?.isResetBlackboardTemplate ?? false;
  const updatedCellIds = updatedCells.map((cell) => cell?.cellId);
  if (!updateCells.length || !updatedComponentIds.length) {
    return;
  }

  const isModuleChiller =
    documentType === DocumentTemplateType.EQUIPMENT_DATA_SHEET;
  const components = _components.map((com) => {
    // rest blackboard template id
    if (isResetBlackboardTemplate) {
      const { blackBoardTemplateId, ...restDetail } = com?.detail || {};

      return { ...com, detail: restDetail };
    }

    return com;
  });

  const newComponents = components.map((component) => {
    if (!updatedComponentIds.includes(String(component.componentId))) {
      return component;
    }

    const newComponent = {
      ...component,
      detail: {
        ...component.detail,
        rows: component.detail?.rows?.map((row) => ({
          ...row,
          cells: row.cells?.map((cell) => {
            //Cell doesn't have a subtable
            if (!cell?.subTable?.rows?.length) {
              if (updatedCellIds.includes(cell?.cellId)) {
                const updatedCell = updatedCells.find(
                  (item) => item?.cellId === cell?.cellId
                );

                return updatedCell;
              }

              return cell;
            }

            // case delete subTable
            if (
              !isModuleChiller &&
              cell?.subTable?.rows?.length &&
              updatedCellIds.includes(cell?.cellId)
            ) {
              const updatedCell = updatedCells.find(
                (item) => item?.cellId === cell?.cellId
              );

              return updatedCell;
            }

            // Cell have a subtable
            return {
              ...cell,
              subTable: {
                ...cell.subTable,
                rows: cell.subTable.rows?.map((subRow) => ({
                  ...subRow,
                  cells: subRow.cells?.map((subCell) => {
                    if (updatedCellIds.includes(subCell?.cellId)) {
                      const updatedCell = updatedCells.find(
                        (item) => item?.cellId === subCell?.cellId
                      );

                      return updatedCell;
                    }

                    return subCell;
                  }),
                })),
              },
            };
          }),
        })),
      },
    };

    return newComponent;
  });

  if (option?.isNotDispatch) {
    return newComponents;
  }

  if (dispatch) {
    dispatch(setComponents(newComponents as TemplateComponent[]));
  }
};

/**
 * This function returns the position of a cell in a table or subtable based on its cell ID.
 * @param {GetCellPositionByCellIdI}  - - `cellId`: a string representing the unique identifier of a
 * cell
 */
export const getCellPositionByCellId = ({
  cellId,
  component,
}: GetCellPositionByCellIdI) => {
  const result: CellPosition = {} as CellPosition;

  component.detail?.rows?.forEach((row, rowIndex) => {
    row.cells?.forEach((cell, cellIndex) => {
      if (cell.cellId === cellId) {
        result.index = {
          row: rowIndex,
          col: cellIndex,
        };

        return;
      }

      //Cell have a subtable
      if (cell.subTable?.rows?.length) {
        cell.subTable?.rows?.forEach((subRow, subRowIndex) => {
          subRow.cells?.forEach((subCell, subCellIndex) => {
            if (subCell.cellId === cellId) {
              result.index = {
                row: subRowIndex,
                col: subCellIndex,
              };

              result.parentIndex = {
                row: rowIndex,
                col: cellIndex,
              };

              return;
            }
          });
        });
      }
    });
  });

  return result;
};

/**
 * This function searches for a component in an array of components based on a given cell ID.
 * @param {GetComponentByCellIdI}  - - `cellId`: a string representing the ID of the cell we are
 */
export const getComponentByCellId = ({
  cellId,
  components,
}: GetComponentByCellIdI) => {
  let foundComponent: TemplateComponent = {} as TemplateComponent;

  components.forEach((component) => {
    component.detail?.rows?.forEach((row) => {
      row.cells?.forEach((cell) => {
        if (cell.cellId === cellId) {
          foundComponent = component;

          return;
        }

        //Cell have a subtable
        if (cell?.subTable?.rows?.length) {
          cell.subTable.rows?.forEach((subRow) => {
            subRow.cells?.forEach((subCell) => {
              if (subCell.cellId === cellId) {
                foundComponent = component;

                return;
              }
            });
          });
        }
      });
    });
  });

  return foundComponent;
};

/**
 * The function returns an array of all cells in a given TemplateComponent, including cells
 * in sub-tables.
 * @param {TemplateComponent} component - TemplateComponent
 */
export const getAllCells = (component: TemplateComponent) => {
  const allCells: CellType[] = [];

  component.detail?.rows?.forEach((row) => {
    row.cells?.forEach((cell) => {
      allCells.push(cell);

      //Cell have a subtable
      if (cell.subTable?.rows?.length) {
        cell.subTable.rows?.forEach((subRow) => {
          subRow.cells?.forEach((subCell) => {
            allCells.push(subCell);
          });
        });
      }
    });
  });

  return allCells;
};

/**
 * This function returns all parent cells of a given TemplateComponent.
 * @param {TemplateComponent} component
 */
export const getAllParentCells = (component: TemplateComponent) => {
  const allCells = getAllCells(component);

  return allCells?.filter((cell) => !cell.isSubCell);
};

/**
 * This TypeScript function returns all cells in a component that belong to a specific column ID.
 * @param {GetCellsByColumnIdI}  - - `columnId`: a string representing the ID of the column to filter
 * cells by.
 */
export const getCellsByColumnId = ({
  columnId,
  component,
}: GetCellsByColumnIdI) => {
  const allCells = getAllCells(component);
  if (!columnId) {
    return [];
  }

  return allCells.filter((cell) => cell.position?.idColumn === columnId);
};

/**
 * This function returns an array of cells filtered by a specific row ID.
 * @param {GetCellsByRowId}
 */
export const getCellsByRowId = ({ rowId, component }: GetCellsByRowId) => {
  const allCells = getAllCells(component);
  if (!rowId) {
    return [];
  }

  return allCells.filter((cell) => cell.position?.idRow === rowId);
};

/**
 * This function returns the parent cell of a given cell ID
 * @param {GetParentCellByCellIdI}
 */
export const getParentCellByCellId = ({
  cellId,
  component,
}: GetParentCellByCellIdI): CellType => {
  let parentCell: CellType = {} as CellType;

  component.detail?.rows?.forEach((row) => {
    row.cells?.forEach((cell) => {
      if (!cell.subTable?.rows?.length) {
        return;
      }

      cell.subTable.rows?.forEach((subRow) => {
        subRow.cells?.forEach((subCell) => {
          if (subCell.cellId === cellId) {
            parentCell = cell;

            return;
          }
        });
      });
    });
  });

  return parentCell;
};

/**
 * This function searches for a cell in a component's detail by its cellId and returns the found cell.
 * @param {GetCellByCellIdI}
 */
export const getCellByCellId = ({
  component,
  cellId,
}: GetCellByCellIdI): CellType => {
  const allCells = getAllCells(component);

  return allCells.find((cell) => cell.cellId === cellId) as CellType;
};

/**
 * This function retrieves the nearest cells (next, previous, above, and below) based on a given cell
 * ID.
 * @param {string} cellId - The `cellId` parameter is a string representing the unique identifier of a
 * cell in a table.
 */
export const getNearestCellByCellId = (cellId: string) => {
  let nextCell: CellType | null = null;
  let prevCell: CellType | null = null;
  let aboveCell: CellType | null = null;
  let belowCell: CellType | null = null;

  const state = store.getState();
  const components = state?.document?.components;
  const component = getComponentByCellId({
    cellId,
    components,
  });

  // Handling for subCell, when subTable has only 1 row, then set subCellId equal parentCellId
  const parentCell = getParentCellByCellId({ cellId, component });
  if (parentCell?.subTable?.rows?.length === 1) {
    cellId = parentCell.cellId;
  }

  component.detail?.rows?.forEach((row, rowIndex) => {
    row.cells?.forEach((cell, cellIndex) => {
      //Cell doesn't have a subtable
      if (cell.cellId === cellId) {
        if (cellIndex !== Number(row.cells?.length) - 1) {
          nextCell = row.cells?.[cellIndex + 1] as CellType;
        }

        if (cellIndex !== 0) {
          prevCell = row.cells?.[cellIndex - 1] as CellType;
        }

        if (rowIndex !== 0) {
          aboveCell = component.detail?.rows?.[rowIndex - 1]?.cells?.[
            cellIndex
          ] as CellType;
        }

        if (rowIndex !== Number(component.detail?.rows?.length) - 1) {
          belowCell = component.detail?.rows?.[rowIndex + 1]?.cells?.[
            cellIndex
          ] as CellType;
        }

        return;
      }

      //Cell has a subtable
      cell?.subTable?.rows?.forEach((subRow, subRowIndex) => {
        subRow.cells?.forEach((subCell, subCellIndex) => {
          if (subCell.cellId === cellId) {
            if (subCellIndex !== Number(subRow.cells?.length) - 1) {
              nextCell = subRow.cells?.[subCellIndex + 1] as CellType;
            }

            if (subCellIndex !== 0) {
              prevCell = subRow.cells?.[subCellIndex - 1] as CellType;
            }

            if (subRowIndex !== 0) {
              aboveCell = cell?.subTable?.rows?.[subRowIndex - 1]?.cells?.[
                subCellIndex
              ] as CellType;
            }

            if (subRowIndex !== Number(cell?.subTable?.rows?.length) - 1) {
              belowCell = cell?.subTable?.rows?.[subRowIndex + 1]?.cells?.[
                subCellIndex
              ] as CellType;
            }

            return;
          }
        });
      });
    });
  });

  return { nextCell, prevCell, aboveCell, belowCell, selectedCellId: cellId };
};

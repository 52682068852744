import {
  API_GET_CACHE,
  API_SKIP_TO_CACHE,
  AVAILABLE_STORES,
  DATA_STORE,
  Operation,
  StoreName,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { safelyParseJSON, sha256, uuid } from "./common";
import { checkNetworkStatus, getIndexedDb, IndexedDBType } from "./indexedDb";

const errorResponse = new Response("", {
  status: 503,
  statusText: "Service Unavailable",
});

export const handleForgeQuery = async (event: FetchEvent) => {
  const swOnlineStatus = await checkNetworkStatus();
  let freshResource: Promise<Response> | Response;
  if (swOnlineStatus) {
    freshResource = fetch(event.request)
      .then(async function (response) {
        if (response.ok) {
          const clonedResponse = response.clone();
          const cache = await caches.open(API_GET_CACHE);
          await cache.put(event.request, clonedResponse);
        }

        return response;
      })
      .catch(function () {
        return errorResponse.clone();
      });
  } else {
    freshResource = errorResponse.clone();
  }

  const cachedResource = caches
    .open(API_GET_CACHE)
    .then(async function (cache) {
      return cache.match(event.request).then(function (response) {
        return response || freshResource;
      });
    })
    .catch(function () {
      return freshResource;
    });

  return cachedResource;
};

export const handleCachePostQuery = async (event: FetchEvent) => {
  const swOnlineStatus = await checkNetworkStatus();
  const url = event.request.url;

  if (!swOnlineStatus && API_SKIP_TO_CACHE.includes(url)) {
    return new Response(JSON.stringify("{}"), {
      status: 202,
    });
  }

  const cache = await caches.open(API_GET_CACHE);
  const body = await event.request.clone().text();
  const hash = sha256(body);
  const cacheUrl = new URL(url);

  cacheUrl.pathname = `/posts${cacheUrl.pathname}/${hash}`;
  if (cacheUrl.pathname.includes("get-s3-file-size")) {
    cacheUrl.pathname = `${cacheUrl.pathname}?data=${body}`;
  }
  const cacheKey = new Request(cacheUrl.toString(), {
    headers: event.request.headers,
    method: "GET",
  });
  if (swOnlineStatus) {
    const res = await fetch(event.request).catch(() => {
      return errorResponse.clone();
    });
    if (res?.ok) {
      await cache.put(cacheKey, res.clone());
    }

    return res;
  } else {
    let res = await cache.match(cacheKey);
    // when offline and non-cached data, return presigned url as file path
    if (!res && cacheUrl.pathname.includes("s3-presigned-url")) {
      const response = safelyParseJSON(body);
      const filePath = response?.filePath || "";
      res = new Response(JSON.stringify({ data: filePath }), {
        status: 202,
      });
    }

    return res || errorResponse.clone();
  }
};

export const handleForgeApi = async (event: FetchEvent) => {
  const swOnlineStatus = await checkNetworkStatus();
  const request = event.request.clone();
  const cache = await caches.open(API_GET_CACHE);

  if (request.method !== "GET") {
    return handleCachePostQuery(event);
  }

  if (swOnlineStatus) {
    const res = await fetch(event.request).catch(() => {
      return errorResponse.clone();
    });
    if (res?.ok) {
      await cache.put(request, res.clone());
    }

    return res;
  } else {
    const res = await cache.match(request);

    return res || errorResponse.clone();
  }
};

export const checkRequestIsSyncData = (url: string) => {
  return new URLSearchParams(new URL(url).search).get("syncOffline") === "true";
};

const updateRequestDocumentItem = async ({
  cachedDataSubItems,
  resData,
  swOnlineStatus,
  removeCachedItemIds: currentRemoveCachedItemIds,
  isLastPage,
}: {
  cachedDataSubItems: iCachedItem[];
  resData: any;
  swOnlineStatus: boolean;
  removeCachedItemIds: string[];
  isLastPage: boolean;
}) => {
  let existed = null;
  const removeCachedItemIds: string[] = [];
  const responseData = structuredClone(resData);
  for await (const cachedData of cachedDataSubItems) {
    const subItem = cachedData.data as DocumentSubItemDTO;
    let _resData: DocumentItemDTO[] = structuredClone(responseData?.data);

    switch (cachedData.operation) {
      case Operation.Patch:
      case Operation.Post:
        if (!Array.isArray(_resData)) {
          _resData = [_resData];
        }

        existed = _resData
          .map((item) => item?.subItems || [])
          .flat(2)
          .find((sub) => sub?.id === subItem.id);

        const isOverrideData = !!existed;
        const isAddNewData =
          !isOverrideData &&
          !swOnlineStatus &&
          _resData.find((item) => item?.id === subItem.itemId) &&
          !currentRemoveCachedItemIds.includes(subItem.id);

        if (isOverrideData || isAddNewData) {
          responseData.data = _resData.map((item) => {
            if (item.id !== subItem.itemId) {
              return item;
            }

            let subItems = item?.subItems || [];
            if (isOverrideData) {
              subItems = subItems.map((sub) => {
                if (sub.id === subItem.id) {
                  return handleOverrideCacheData({
                    requestName: StoreName.SUB_ITEMS,
                    cachedItem: subItem,
                    currentItem: sub,
                    swOnlineStatus,
                  });
                }

                return sub;
              });
            } else if (isAddNewData) {
              subItems.push(subItem);
            }

            return { ...item, subItems };
          });

          if (cachedData.status === UpdateToOnlineStatus.Success) {
            removeCachedItemIds.push(cachedData.id);
          }
        }
        break;

      case Operation.Delete:
        // when operation delete cached.data is id of item and type is string
        //@ts-ignore
        const deleteId = subItem as string;

        existed = _resData
          .map((item) => item?.subItems || [])
          .flat(2)
          .find((sub) => sub?.id === deleteId);

        // we need check until page exist or last page for make sure item is cleared
        // If  deleted it on page 5 and now delete it from page 1, then when I load page 5 again, I won't see this cache item to delete anymore.
        if (existed || isLastPage) {
          responseData.data = _resData.map((item) => {
            const subItems = (item.subItems || []).filter(
              (sub) => sub.id !== deleteId
            );

            return { ...item, subItems };
          });
          if (cachedData.status === UpdateToOnlineStatus.Success) {
            removeCachedItemIds.push(cachedData.id);
            handleClearCreateUpdateItem(deleteId, StoreName.DOCUMENT_ITEMS);
          }
        }

        break;
      default:
        break;
    }
  }

  return { responseData, removeCachedItemIds };
};

const handleOverrideCacheData = (params: {
  requestName: StoreName;
  cachedItem: DocumentCategoryDTO | DocumentItemDTO | DocumentSubItemDTO;
  currentItem: DocumentCategoryDTO | DocumentItemDTO | DocumentSubItemDTO;
  swOnlineStatus: boolean;
}) => {
  const { swOnlineStatus, requestName, cachedItem, currentItem } = params;
  let result = structuredClone(currentItem) as any;
  // when online we can remove offline id of record
  if (swOnlineStatus && "offlineId" in cachedItem) {
    delete cachedItem?.offlineId;
  }
  result = swOnlineStatus
    ? { ...cachedItem, ...currentItem }
    : { ...currentItem, ...cachedItem };

  // Update the Dynamic field type because currently when updating
  // only 1 field is updated.
  const requestHasDynamicField = [
    StoreName.DOCUMENT_CATEGORIES,
    StoreName.DOCUMENT_ITEMS,
    StoreName.SUB_ITEMS,
  ].includes(requestName);
  if (requestHasDynamicField) {
    const dynamicFieldData = swOnlineStatus
      ? { ...(cachedItem?.data || {}), ...(currentItem?.data || {}) }
      : { ...(currentItem?.data || {}), ...(cachedItem?.data || {}) };
    result.data = dynamicFieldData;
  }

  return result;
};

const handleLambdaGetCommon = async ({
  params,
  cachedDatas: _cachedDatas,
  resData,
  swOnlineStatus,
  indexedDb,
  requestName,
  removeCachedItemIds: currentRemoveCachedItemIds,
  isLastPage,
}: {
  params: { [key: string]: string };
  cachedDatas: iCachedItem[];
  resData: any;
  swOnlineStatus: boolean;
  indexedDb: IndexedDBType;
  requestName: string;
  removeCachedItemIds: string[];
  isLastPage: boolean;
}) => {
  const responseData = structuredClone(resData);

  // validate params of request
  const validParams = Object.keys(params).filter((key) => !!params[key]);
  const removeCachedItemIds: Set<string> = new Set();

  const filterCachedDatas = (item: iCachedItem) => {
    if (item.operation === Operation.Patch) {
      // with case update -> only cache some field update -> can ignore verify params
      return true;
    }

    if (item.operation === Operation.Delete) {
      return !(
        validParams.includes("isDeleted") && params["isDeleted"] === "true"
      );
    }

    const checkValidParams = validParams.every((key) => {
      let itemDataKey = key;
      let itemData = (item.data as any)[itemDataKey];
      const paramsKey = key;
      const paramsData = decodeURIComponent(params[paramsKey]);
      // case api get document list use params documentCategoryIds
      // but data return hasn't field documentCategoryIds
      // so will use field documentCategoryId
      if (paramsKey === "documentCategoryIds" && !itemData) {
        itemDataKey = "documentCategoryId";
        itemData = (item.data as any)[itemDataKey];
        // same as above
      } else if (paramsKey === "isDeleted") {
        itemData = (item.data as any)[itemDataKey] ?? "false";
        // same as above
      } else if (["page", "total"].includes(paramsKey)) {
        itemData = paramsData;
      }

      return paramsData === itemData;
    });

    return checkValidParams;
  };

  // handle override or add new data from cache
  const cachedItems = _cachedDatas.filter(filterCachedDatas);

  // check data cache is push in other request
  // (eg: data cache is used for get document Tasks api or get documentTasksByTitle api)
  const keyPush = validParams?.length
    ? validParams
        .sort()
        ?.filter((key) => key !== "page")
        ?.map((key) => `${key}-${decodeURIComponent(params[key])}`)
        .join("-")
    : "emptyKey";

  let existed = null;
  if (!Array.isArray(responseData?.data)) {
    responseData.data = [responseData?.data].filter((i) => !!i);
  }

  // ignore remove cache after combine data
  const isIgnoreRemoveCache = ({ cachedData }: { cachedData: iCachedItem }) => {
    // case api get list document tasks (by title, normal)
    // only remove cache item for case normal
    if (requestName === StoreName.DOCUMENT_TASKS) {
      return (
        !cachedData?.isPushed?.["bimFileId"] || !cachedData?.isPushed?.["id"]
      );
    }

    return false;
  };

  // request log is added temporarily
  const removeRequestLogTemporarily = ({
    cachedData,
    isLastPage,
    isStatusSuccess,
  }: {
    cachedData: iCachedItem;
    isLastPage: boolean;
    isStatusSuccess: boolean;
  }) => {
    if (
      isLastPage &&
      isStatusSuccess &&
      [
        StoreName.DATA_LOGS,
        StoreName.TASK_COMMENTS,
        StoreName.TASK_TYPES,
      ].includes(requestName as any)
    ) {
      removeCachedItemIds.add(cachedData.id);
    }
  };

  const isRequestLog = [StoreName.DATA_LOGS, StoreName.TASK_COMMENTS].includes(
    requestName as any
  );

  for await (const data of cachedItems) {
    let cachedData = structuredClone(data);
    if (!Object.keys(cachedData?.isPushed || {})?.length) {
      cachedData = { ...cachedData, isPushed: {} };
    }
    const isStatusSuccess = cachedData.status === UpdateToOnlineStatus.Success;

    switch (cachedData.operation) {
      case Operation.Patch:
      case Operation.Post:
        existed = responseData?.data?.find(
          (item: any) => item.id === cachedData.data.id
        );

        // condition override data for request get list
        const isOverrideData = !!existed;
        // condition add new data for request get list
        const isAddNewData =
          !isOverrideData &&
          !swOnlineStatus &&
          // If requestName is log then it will be added at the beginning
          // otherwise it will be left at the end
          (isLastPage || (isRequestLog && isStatusSuccess)) &&
          cachedData.operation === Operation.Post &&
          !cachedData.isPushed?.[keyPush] &&
          !currentRemoveCachedItemIds.includes(cachedData.data.id);

        if (isOverrideData) {
          responseData.data = (responseData?.data || []).map((item: any) => {
            if (item.id !== cachedData.data.id) {
              return item;
            }

            return handleOverrideCacheData({
              requestName: requestName as StoreName,
              cachedItem: cachedData.data,
              currentItem: item,
              swOnlineStatus,
            });
          });
        } else if (isAddNewData) {
          responseData?.data?.push(cachedData.data);
        }

        // after override data or add new data if cachedData's status if success
        // then remove cached from indexedDb
        if (isOverrideData || isAddNewData) {
          cachedData.isPushed[keyPush] = true;
          await indexedDb?.put(cachedData.data.id, cachedData);

          // ignore remove cache after combine data
          if (isIgnoreRemoveCache({ cachedData })) {
            break;
          }

          if (isStatusSuccess) {
            removeCachedItemIds.add(cachedData.id);
          }
        }

        // request log is added temporarily
        // because the log has been inserted on the server
        // so the id will be different
        removeRequestLogTemporarily({
          cachedData,
          isLastPage,
          isStatusSuccess,
        });

        break;
      case Operation.Delete:
        if (!Array.isArray(responseData.data)) break;
        existed = responseData?.data?.find(
          (item: any) => item.id === cachedData.data
        );
        if (existed || isLastPage) {
          if (!swOnlineStatus) {
            responseData.data = responseData?.data.filter(
              (item: any) => item.id !== cachedData.data
            );
          }
          if (isStatusSuccess) {
            removeCachedItemIds.add(cachedData.id);
            handleClearCreateUpdateItem(cachedData.data, requestName);
          }
        }
        break;
      default:
        break;
    }
  }

  return { responseData, removeCachedItemIds: Array.from(removeCachedItemIds) };
};

const handleLambdaGet = async (event: FetchEvent, swOnlineStatus: boolean) => {
  const request = event.request.clone();
  const cache = await caches.open(API_GET_CACHE);
  const { params, requestName } = parseParams(request);

  let response: Response | undefined;

  // network first
  if (swOnlineStatus) {
    response = await fetch(request).catch(() => {
      return errorResponse.clone();
    });
  } else {
    response = await cache.match(request);
  }
  let resData: any = (await response?.json().catch(() => undefined)) || {
    data: [],
  };

  // case api get detail
  // eg: /api-name/id
  if (params?.["useCache"] === "false") {
    const res = new Response(JSON.stringify(resData), {
      headers: response?.headers,
      status: response?.status || 202,
      statusText: response?.statusText,
    });
    if (res.ok) {
      await cache.put(request, res.clone());
    }

    return res;
  }

  // case api get list
  // eg: /api-name
  const totalPage = params?.["total"];
  const currentPage = params?.["page"];
  const isLastPage =
    (!resData?.pagination && params?.["paging"] === "cursor") ||
    totalPage === currentPage;

  const indexedDb = await getIndexedDb();
  const cachedDatas = (
    (await indexedDb?.getAll(DATA_STORE, requestName)) as iCachedItem[]
  ).sort((a, b) => a.requestTime - b.requestTime);
  let cachedDataSubItems: iCachedItem[] = [];
  if (requestName === StoreName.DOCUMENT_ITEMS) {
    cachedDataSubItems = (await indexedDb?.getAll(
      DATA_STORE,
      StoreName.SUB_ITEMS
    )) as iCachedItem[];
    cachedDataSubItems.sort((a, b) => a.requestTime - b.requestTime);
  }
  const removeCachedItemIds: string[] = [];
  if (cachedDatas?.length) {
    const { removeCachedItemIds: _removeCachedItemIds, responseData } =
      await handleLambdaGetCommon({
        params,
        requestName,
        indexedDb,
        swOnlineStatus,
        resData,
        cachedDatas,
        removeCachedItemIds,
        isLastPage,
      });
    removeCachedItemIds.push(..._removeCachedItemIds);
    resData = responseData;
  }

  // update field sub item for document items
  if (cachedDataSubItems?.length) {
    const { responseData, removeCachedItemIds: _removeCachedItemIds } =
      await updateRequestDocumentItem({
        cachedDataSubItems,
        swOnlineStatus,
        removeCachedItemIds,
        resData,
        isLastPage,
      });
    resData = responseData;
    removeCachedItemIds.push(..._removeCachedItemIds);
  }

  if (removeCachedItemIds.length) {
    await indexedDb?.deleteList(removeCachedItemIds);
  }

  const res = new Response(JSON.stringify(resData), {
    headers: response?.headers,
    status: response?.status || 202,
    statusText: response?.statusText,
  });
  if (res.ok) {
    // if request ok -> put to cache
    await cache.put(request, res.clone());
  }

  return res;
};

export const handleClearCreateUpdateItem = async (
  itemId: string,
  subStoreName: string
) => {
  const indexedDb = await getIndexedDb();
  const cachedDatas = ((await indexedDb?.getAll(DATA_STORE, subStoreName)) ||
    []) as iCachedItem[];
  const promises = cachedDatas.map((item) => {
    if (item.data?.id === itemId) {
      return indexedDb.delete(item.id, DATA_STORE);
    }

    return Promise.resolve(true);
  });

  await Promise.all(promises);
  // clear logs and comments
  if (subStoreName === StoreName.TASKS) {
    // clear comments
    const cacheTaskComments = ((await indexedDb?.getAll(
      DATA_STORE,
      StoreName.TASK_COMMENTS
    )) || []) as iCachedItem[];
    const promises = cacheTaskComments.map((item) => {
      if (item.data?.taskId === itemId) {
        return indexedDb.delete(item.id);
      }

      return Promise.resolve(true);
    });
    await Promise.all(promises);
  }
  // now we only care doc items not care  (offline mode not allow delete doc group and doc category)
  if (
    [StoreName.DOCUMENT_ITEMS, StoreName.SUB_ITEMS].includes(
      subStoreName as any
    )
  ) {
    const isStoreDocItem = subStoreName === StoreName.DOCUMENT_ITEMS;
    const setDeleteSubItemId: Set<string> = new Set([]);
    if (isStoreDocItem) {
      const cacheSubItems = ((await indexedDb?.getAll(
        DATA_STORE,
        StoreName.SUB_ITEMS
      )) || []) as iCachedItem[];
      const promiseDeleteSubItems = cacheSubItems.map((item) => {
        if (item.data?.itemId === itemId) {
          setDeleteSubItemId.add(item.data.id);

          return indexedDb.delete(item.id);
        }

        return Promise.resolve(true);
      });
      await Promise.all(promiseDeleteSubItems);
    }
    const isStoreSubItem = subStoreName === StoreName.SUB_ITEMS;
    // clear comments
    const cacheLogs = ((await indexedDb?.getAll(
      DATA_STORE,
      StoreName.DATA_LOGS
    )) || []) as iCachedItem[];
    const promises = cacheLogs.map((item) => {
      if (
        (item.data?.itemId === itemId && isStoreDocItem) ||
        (setDeleteSubItemId.has(item.data?.subItemId) && isStoreDocItem) ||
        (item.data?.subItemId === itemId && isStoreSubItem)
      ) {
        return indexedDb.delete(item.id);
      }

      return Promise.resolve(true);
    });
    await Promise.all(promises);
    const cacheBlackBoards = ((await indexedDb?.getAll(
      DATA_STORE,
      StoreName.BLACKBOARDS
    )) || []) as iCachedItem[];

    const promiseBlackboards = cacheBlackBoards.map((item) => {
      if (
        (setDeleteSubItemId.has(item.data?.subItemId) && isStoreDocItem) ||
        (item.data?.subItemId === itemId && isStoreSubItem)
      ) {
        return indexedDb.delete(item.id);
      }

      return Promise.resolve(true);
    });
    await Promise.all(promiseBlackboards);
  }

  // clear cache document subitems
};

const handleLambdaPost = async (event: FetchEvent, swOnlineStatus: boolean) => {
  const request = event.request.clone();
  let response: Response | undefined = undefined;
  // network first

  if (swOnlineStatus) {
    response = await fetch(request).catch(() => {
      return errorResponse.clone();
    });

    // When error 400 occurs, the service worker side cannot get a response from the server
    // so the error handling logic on the baseAxios side does not work.
    if (![200, 400].includes(response?.status)) {
      return response;
    }
  }

  const { requestName } = parseParams(request);
  const _response = await (response || request).json();

  const body = _response?.items ?? _response?.data ?? _response;
  const { data, id, ...rest } = _response;

  const now = Date.now();
  const items = Array.isArray(body) ? body : [body];

  if (items.length && !checkRequestIsSyncData(request.url)) {
    const indexedDb = await getIndexedDb();
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item) {
        if (!item.id) {
          const offlineId = uuid();
          item.id = offlineId;
          item.offlineId = offlineId;
        }

        const requestTime = await indexedDb
          ?.get(item.id)
          .then((item) => item?.requestTime);
        const indexedDBPaylod = {
          requestTime: requestTime || now,
          requestId: rest?.requestId,
          store: requestName,
          operation: Operation.Post,
          status: swOnlineStatus
            ? UpdateToOnlineStatus.Success
            : UpdateToOnlineStatus.Fail,
          data: { ...item, ...rest },
        } as iCachedItem;

        await addIndexedDBItem({ id: item.id, data: indexedDBPaylod });
      }
    }
  }

  const newResponse = new Response(
    JSON.stringify(
      Array.isArray(body)
        ? { data: items, ...rest }
        : { data: { ...items?.[0], ...rest } }
    ),
    {
      headers: response?.headers,
      status: response?.status || 202,
      statusText: response?.statusText || "",
    }
  );

  return newResponse;
};

const handleLambdaPatch = async (
  event: FetchEvent,
  swOnlineStatus: boolean
) => {
  const request = event.request.clone();
  let response: Response | undefined = undefined;
  const { requestName } = parseParams(request);

  // network first
  if (swOnlineStatus) {
    response = await fetch(request).catch(() => {
      return errorResponse.clone();
    });

    // When error 400 occurs, the service worker side cannot get a response from the server
    // so the error handling logic on the baseAxios side does not work.
    if (![200, 400].includes(response?.status)) {
      return response;
    }
  }

  const item = await event.request.clone().json();
  const now = Date.now();
  const indexedDb = await getIndexedDb();
  if (indexedDb && !checkRequestIsSyncData(request.url)) {
    const currentData = {} as any;
    const isInitData = !currentData?.initData;
    const dataDb = {
      requestTime: currentData?.requestTime || now,
      requestId: item?.requestId,
      operation: Operation.Patch,
      store: requestName,
      status: swOnlineStatus
        ? UpdateToOnlineStatus.Success
        : UpdateToOnlineStatus.Fail,
      data: item,
      initData: currentData?.initData || undefined,
    } as iCachedItem;

    if (isInitData && !swOnlineStatus) {
      delete item?.initData?.initData;
      dataDb.initData = item?.initData || undefined;
    }

    if (swOnlineStatus) {
      dataDb.initData = undefined;
    }

    await addIndexedDBItem({ id: uuid(), data: dataDb });
  }

  const _response = (await response?.json()) || {
    data: item,
  };

  return new Response(JSON.stringify(_response), {
    headers: response?.headers,
    status: response?.status || 202,
    statusText: response?.statusText || "",
  });
};

const handleLambdaDelete = async (
  event: FetchEvent,
  swOnlineStatus: boolean
) => {
  const request = event.request.clone();
  let response: Response | undefined = undefined;
  // network first

  if (swOnlineStatus) {
    response = await fetch(request.clone()).catch(() => {
      return errorResponse.clone();
    });

    // When error 400 occurs, the service worker side cannot get a response from the server
    // so the error handling logic on the baseAxios side does not work.
    if (![200, 400].includes(response?.status)) {
      return response;
    }
  }

  const { requestName } = parseParams(request);

  // no need to add to index db when delete task type
  if (requestName === StoreName.TASK_TYPES) {
    return new Response(JSON.stringify("{}"), {
      status: 202,
    });
  }

  const body = await request.clone().json();
  const ids = body?.ids || [];
  const requestId = body?.requestId;
  const now = Date.now();
  const items: string[] = Array.isArray(ids) ? ids : [ids];
  if (items.length && !checkRequestIsSyncData(request.url)) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      await addIndexedDBItem({
        id: uuid(),
        data: {
          requestTime: now,
          requestId,
          store: requestName,
          operation: Operation.Delete,
          status: swOnlineStatus
            ? UpdateToOnlineStatus.Success
            : UpdateToOnlineStatus.Fail,
          data: item,
        } as iCachedItem,
      });
    }
  }

  return new Response(JSON.stringify("{}"), {
    status: 202,
  });
};

export const handleLambdaRequest = async (event: FetchEvent) => {
  const swOnlineStatus = await checkNetworkStatus();
  switch (event.request.method) {
    case "GET":
      return await handleLambdaGet(event, swOnlineStatus);
    case "PUT":
    case "POST":
      return await handleLambdaPost(event, swOnlineStatus);
    case "DELETE":
      return await handleLambdaDelete(event, swOnlineStatus);
    case "PATCH":
      return await handleLambdaPatch(event, swOnlineStatus);
    default:
      const requestClone = event.request.clone();
      if (swOnlineStatus) {
        return await fetch(requestClone);
      }
      const cache = await caches.open(API_GET_CACHE);

      return (await cache.match(requestClone)) || errorResponse;
  }
};

const parseParams = (request: Request) => {
  const uri = request.url.replace(
    `${process.env.REACT_APP_API_HOST_URL || ""}/`,
    ""
  );
  if (uri.includes("count-items")) {
    return { requestName: "count-items", params: {} };
  }
  const params: any = {};
  const queryString = uri.split("?").pop();
  const paths = uri.split("?")[0].split("/");
  const requestName =
    request.method !== "GET"
      ? paths.pop() || ""
      : paths.filter((path) => AVAILABLE_STORES.includes(path as any)).pop() ||
        paths.pop() ||
        "";

  if (queryString) {
    const getParams = ({
      result,
      key,
      value,
    }: {
      result: any;
      key: string;
      value: any;
    }) => {
      if (result[key]) {
        if (Array.isArray(result[key])) {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    };

    queryString.split("&").forEach(function (pair) {
      const keyValue = pair.split("=");
      const key = decodeURIComponent(keyValue[0]);
      const value = decodeURIComponent(keyValue[1] || "");
      if (["cursor", "limit", "paging", "shouldCache"].includes(key)) {
        return;
      }
      getParams({ result: params, key, value });
    });
  }

  return { requestName, params };
};

export const addIndexedDBItemIfNotExist = async ({
  id,
  storeName,
  data,
}: {
  id: string;
  storeName: string;
  data: Partial<iCachedItem>;
}) => {
  const indexedDb = await getIndexedDb();
  const items: iCachedItem[] = await indexedDb.getAll(DATA_STORE);
  const item = items.find((i) => i.store === storeName && i.id === id);
  if (item) return;
  await indexedDb.put(id, data);
};

export const updateIndexedDBItem = async ({
  id,
  storeName,
  data,
}: {
  id: string;
  storeName: string;
  data: Partial<iCachedItem>;
}) => {
  const indexedDb = await getIndexedDb();
  const items: iCachedItem[] = await indexedDb.getAll();
  const item = items.find((i) => i.store === storeName && i.id === id);
  if (!item) {
    return;
  }
  const cacheData: iCachedItem = {
    ...item,
    ...data,
  };

  await indexedDb.put(id, cacheData);
};

export const addIndexedDBItem = async ({
  id,
  data,
}: {
  id: string;
  data: iCachedItem;
}) => {
  const indexedDb = await getIndexedDb();
  await indexedDb.put(id, data);
};

import { documentItemApi, taskApi } from "apiClient/v2";
import { DISPLAY_MODE } from "constants/forge";
import { Level, Vector3 } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentItem } from "interfaces/models/documentItem";
import { Task } from "interfaces/models/task";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayMode, setLevelSelected } from "redux/forgeViewerSlice";
import { createUpdateProjectApi } from "redux/projectSlice";
import { RootState, useAppDispatch } from "redux/store";
import { getDbIdByExternalId } from "utils/forge/data";
import { find3dPosition, ___viewer3d } from "utils/forge/forge3d";
import { logDev } from "utils/logs";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import { MessageType } from "constants/websocket";
import { WSMessage } from "interfaces/models/websocket";

interface iProps {
  bimFileId?: string;
  dataProjectDetail?: DataProjectModel;
  isLoadedViewer: boolean;
  isLoadedLevels: boolean;
  isGeneratingFamilyInstances: boolean;
  levels: Level[];
  sendWebSocketMessage(msg: WSMessage): void;
}

const useCheckUpdateForgeVersion = ({
  bimFileId,
  dataProjectDetail,
  levels,
  isLoadedViewer,
  isLoadedLevels,
  isGeneratingFamilyInstances,
  sendWebSocketMessage,
}: iProps) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const { isLoadedExternalId } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const { currentUser } = useSelector((state: RootState) => state.user);

  const isGeneratingFamilyInstancesRef = useRef(isGeneratingFamilyInstances);
  useEffect(() => {
    isGeneratingFamilyInstancesRef.current = isGeneratingFamilyInstances;
  }, [isGeneratingFamilyInstances]);

  useEffect(() => {
    if (dataProjectDetail?.id) {
      const urn = dataProjectDetail?.defaultBimPathId?.split("/").pop();
      if (!urn || !dataProjectDetail.isDiffVersion || !___viewer3d) {
        return;
      }
    }

    if (
      !bimFileId ||
      !dataProjectDetail?.id ||
      !isLoadedViewer ||
      !isLoadedLevels ||
      !isLoadedExternalId
    ) {
      return;
    }

    const resetViewer = () => {
      if (!isGeneratingFamilyInstancesRef.current) {
        const defaultLevel = levels.filter((level) => level.guid)?.[0];
        dispatch(
          setDisplayMode(
            !!defaultLevel.guid && defaultLevel.sheets?.length
              ? DISPLAY_MODE["2D"]
              : DISPLAY_MODE["3D"]
          )
        );
        dispatch(setLevelSelected(defaultLevel));
        const currentLevel = getLocalStorage("currentLevel") || {};
        currentLevel[String(bimFileId)] = defaultLevel.guid;
        setLocalStorage("currentLevel", currentLevel);
      }
    };

    (async () => {
      const tasksBody: Partial<Task>[] = [];
      const documentItemsBody: DocumentItem[] = [];

      const [tasks, documentItems] = await Promise.all([
        taskApi.handleGetTasks({ bimFileId }),
        documentItemApi.handleGetItemList({ bimFileId }),
      ]);
      const checkIsDiffPosition = (externalId?: string, position?: Vector3) => {
        if (!externalId || !position) {
          return { isDiffPosition: false };
        }
        const dbId = getDbIdByExternalId(externalId);
        const currentPosition = find3dPosition(dbId);
        const isDiffPosition =
          (position?.x !== currentPosition?.x ||
            position?.y !== currentPosition?.y ||
            position?.z !== currentPosition?.z) &&
          currentPosition &&
          !isNaN(dbId);

        return { isDiffPosition, newPosition: currentPosition };
      };
      tasks.forEach((task) => {
        const { isDiffPosition, newPosition } = checkIsDiffPosition(
          task?.externalId,
          task?.position
        );
        if (isDiffPosition && newPosition) {
          tasksBody.push({
            id: task.id,
            position: newPosition,
          });
        }
      });

      documentItems.forEach((documentItem) => {
        const { isDiffPosition, newPosition } = checkIsDiffPosition(
          documentItem?.externalId,
          documentItem?.position
        );
        if (isDiffPosition && newPosition) {
          documentItemsBody.push({
            id: documentItem.id,
            position: newPosition,
          });
        }
      });
      const request = [];
      request.push(
        appDispatch(
          createUpdateProjectApi({
            ...dataProjectDetail,
            isDiffVersion: false,
            userRole: currentUser?.role || "",
          })
        )
      );
      if (tasksBody?.length) {
        tasksBody.map((e) => {
          request.push(taskApi.updateTask(e as Task));

          return e;
        });
      }

      if (documentItemsBody?.length) {
        documentItemsBody.map((e) => {
          return request.push(documentItemApi.updateItem(e));
        });
      }

      logDev({
        tasksBody,
        documentItemsBody,
      });
      await Promise.all(request);
      logDev("update version forge");
      sendWebSocketMessage({
        type: MessageType.UPDATE_PROJECT_BIM_FILE,
        data: dataProjectDetail,
      });
      resetViewer();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedExternalId,
    isLoadedLevels,
    isLoadedViewer,
    dataProjectDetail?.isDiffVersion,
    dataProjectDetail?.id,
    dataProjectDetail?.defaultBimPathId,
    dataProjectDetail?.prevDefaultBimPathId,
    bimFileId,
    currentUser?.role,
  ]);

  return {};
};

export default useCheckUpdateForgeVersion;

import { useBoolean } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { HandleSelectDrawImageProps } from "components/modal/DrawToolModal";
import { DocumentItemKey, SubItemKey } from "constants/enum";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import { User } from "interfaces/models/user";
import { GetContentLog, transformMapTitleKey } from "models/dataLog";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { uuid } from "utils/common";
import { fileToBase64, removeFileS3, uploadFileToS3 } from "utils/file";
import { logDev } from "utils/logs";

interface Props {
  currentUser?: User | null;
  subItem?: DocumentSubItemDTO;
  documentItemSelected?: DocumentItemDTO;
  originImgSelectedTemp: FileModel;
  isHasBlackboardWithOutPosition: boolean;
  imageCaptured: React.MutableRefObject<File | undefined>;
  blackboardTemplateId: string | undefined;
  dataBlackboard?: Blackboard;

  setImage: React.Dispatch<React.SetStateAction<FileModel | undefined>>;
  onClosePositionBlackboardModal: () => void;
  onOpenPositionBlackboardModal: () => void;
  handleOpenCamera: () => void;
  insertSubItemLog: (params: GetContentLog) => Promise<void>;
}

const useDrawImage = (props: Props) => {
  const {
    isHasBlackboardWithOutPosition,
    documentItemSelected,
    currentUser,
    subItem,
    originImgSelectedTemp,
    imageCaptured,
    blackboardTemplateId,
    dataBlackboard,

    setImage,
    handleOpenCamera,
    onClosePositionBlackboardModal,
    onOpenPositionBlackboardModal,
    insertSubItemLog,
  } = props;
  const { socket } = useForgeViewerContext();
  const [isOpenDrawImage, setIsOpenDrawImage] = useBoolean();
  const isRecaptureImageRef = useRef(false);
  const dispatch = useDispatch();
  const documentItemSelectedRef = useRef<DocumentItemDTO | undefined>(
    documentItemSelected
  );
  documentItemSelectedRef.current = documentItemSelected;

  const handleOpenDrawImage = useCallback(() => {
    setIsOpenDrawImage.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDrawImage = useCallback(() => {
    isRecaptureImageRef.current = false;
    setIsOpenDrawImage.off();
    onClosePositionBlackboardModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectDrawImage = useCallback(
    async ({ file, isEdited, originFile }: HandleSelectDrawImageProps) => {
      if (!documentItemSelected?.id || !subItem?.id) {
        return handleCloseDrawImage();
      }
      const images: FileUploadInfo | undefined = subItem?.images as any;
      const isHasOriginImgSelectedTemp = !!originImgSelectedTemp?.file;
      const isExistsOriginFile = !!images?.originSrc;
      const isRecaptureImage = isRecaptureImageRef.current;

      let lastModifiedDateCurrent: any;
      if (isEdited && !!images && !isRecaptureImage) {
        const lastModifiedDateFileFirst = images?.lastModifiedDateFile;
        const uploadTimeFileFirst = images?.uploadTime;

        lastModifiedDateCurrent = lastModifiedDateFileFirst
          ? new Date(lastModifiedDateFileFirst)
          : uploadTimeFileFirst
          ? new Date(uploadTimeFileFirst)
          : new Date();
      } else {
        lastModifiedDateCurrent = new Date(file.lastModified);
      }
      const updateDocumentSubItem = (
        changeProps: DocumentSubItemDTO | { [key: string]: any }
      ) => {
        const documentItem = structuredClone(documentItemSelectedRef.current);
        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: SubItemKey.ID,
          element: {
            ...subItem,
            ...changeProps,
          },
        });
        dispatch(updateDocumentItem(documentItem!));
      };
      const fileClone = new File([file], file.name, {
        type: file.type,
        lastModified: lastModifiedDateCurrent.getTime(),
      });
      const src = await fileToBase64(fileClone);
      setImage((prev) => ({
        ...prev,
        src,
        file: fileClone,
        isEditMedia: isEdited,
      }));
      if (isHasBlackboardWithOutPosition) {
        handleCloseDrawImage();
        onOpenPositionBlackboardModal();
      } else {
        // turn on loading
        updateDocumentSubItem({ isLoadingUpdateImage: true });

        let isRemoveOrigin = false;
        let originFileModel: FileModel = undefined as any;
        if (isHasOriginImgSelectedTemp) {
          originFileModel = originImgSelectedTemp;
        } else if (isEdited && (!isExistsOriginFile || isRecaptureImage)) {
          originFileModel = { file: originFile, name: originFile.name };
        } else if (!isEdited && isRecaptureImage) {
          isRemoveOrigin = true;
        }

        let fileUploadInfo: FileUploadInfo = {} as any;
        fileUploadInfo.src = src;
        const promiseImages = [];
        const requestId = uuid();
        promiseImages.push(
          uploadFileToS3(fileClone, fileClone?.name || "", undefined, {
            requestId,
          }).then((src) => {
            fileUploadInfo = {
              src,
              uploadTime: new Date(),
              originSrc: fileUploadInfo.originSrc ?? images?.originSrc,
              lastModifiedDateFile: lastModifiedDateCurrent,
              userUpload: currentUser?.id || "",
            };
          })
        );

        if (isRemoveOrigin) {
          fileUploadInfo.originSrc = null as any;
          if (images?.originSrc) {
            promiseImages.push(
              removeFileS3({
                keys: [new URL(images?.originSrc).pathname.substring(1)],
                requestId,
              })
            );
          }
        }
        if (originFileModel?.file) {
          promiseImages.push(
            uploadFileToS3(
              originFileModel.file,
              originFileModel?.file?.name || "",
              undefined,
              { requestId }
            ).then((src) => {
              fileUploadInfo.originSrc = src;
            })
          );
        }

        const blackboardDataBody: Partial<DocumentSubItemDTO> = {};
        if (dataBlackboard) {
          blackboardDataBody.blackboardData = dataBlackboard;
        }

        await Promise.all(promiseImages);

        const { mapLogs, mapTitleKey } = transformMapTitleKey([
          {
            field: DocumentItemKey.IMAGES as any,
            blackboardImagePosition: subItem.blackboardImagePosition,
            blackboardTemplateId,
            isEditMedia: isEdited,
            value: fileUploadInfo,
            ...blackboardDataBody,
          },
        ]);
        const response = await documentItemApi.updateSubItem({
          id: subItem.id,
          itemId: subItem.itemId,
          images: fileUploadInfo,
          blackboardImagePosition: subItem.blackboardImagePosition,
          blackboardTemplateId,
          isEditMedia: isEdited,
          mapTitleKey: mapTitleKey as any,
          ...blackboardDataBody,
          requestId,
        } as DocumentSubItemDTO);

        // handle revert when save data have been failure
        if (!response?.data?.id) {
          setImage(undefined);
          updateDocumentSubItem({
            images: subItem.images,
            updatedAt: subItem.updatedAt,
            isLoadingUpdateImage: false,
          });

          return;
        }

        socket.updateSubItem(documentItemSelected, subItem, {
          images: fileUploadInfo,
          updatedAt: response.data.updatedAt,
        });

        await Promise.all(
          Object.values(mapLogs).map((params) =>
            insertSubItemLog({ ...params, isEditMedia: isEdited, requestId })
          )
        );
        // turn off loading and set images
        updateDocumentSubItem({
          isLoadingUpdateImage: false,
          images: fileUploadInfo,
          updatedAt: response.data.updatedAt,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      socket,
      dataBlackboard,
      currentUser?.id,
      isHasBlackboardWithOutPosition,
      subItem,
      originImgSelectedTemp,
      documentItemSelected,
      blackboardTemplateId,
      handleCloseDrawImage,
      onOpenPositionBlackboardModal,
      onOpenPositionBlackboardModal,
      dispatch,
    ]
  );

  const handleRecaptureImage = useCallback(() => {
    isRecaptureImageRef.current = true;
    setIsOpenDrawImage.off();
    handleOpenCamera();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOpenCamera]);

  const handleCloseDrawImageAfterSave = useCallback(() => {
    imageCaptured.current = undefined;
    setIsOpenDrawImage.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpenDrawImage,
    handleCloseDrawImage,
    handleOpenDrawImage,
    handleSelectDrawImage,
    handleRecaptureImage,
    handleCloseDrawImageAfterSave,
  };
};

export default useDrawImage;

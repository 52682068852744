import { Icon, IconProps } from "@chakra-ui/icons";

export default function IconPlus({ color = "#737373", ...rest }: IconProps) {
  return (
    <Icon w={16} h={24} fill="none" viewBox="0 0 16 24" {...rest}>
      <path
        data-name="↳Color"
        fill={color as any}
        fillRule="evenodd"
        d="M8.67 5.999a.667.667 0 0 0-1.333 0v5.334H2.003a.667.667 0 0 0 0 1.333h5.334V18a.667.667 0 1 0 1.333 0v-5.334h5.334a.667.667 0 0 0 0-1.333H8.67z"
        clipRule="evenodd"
      />
    </Icon>
  );
}

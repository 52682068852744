import { MAX_DOCUMENT_ITEMS_TO_CACHE_LOG } from "constants/document";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { FileUploadInfo } from "interfaces/models";
import { ApiResponse, Pagination } from "interfaces/models/api";
import { DocumentItem, DocumentSubItem } from "interfaces/models/documentItem";
import { isSelfInspectionTemplate } from "models/documentCategory";
import { axiosECS } from "services/baseAxios";
import {
  getNetworkStatus,
  sleep,
  validateBodyReq,
  validateListString,
} from "utils/common";
import { checkDocumentItemUpdatedData } from "utils/documentItem";
import { presignedAndDownloadFileS3 } from "utils/file";
import { dataLogApi } from ".";
import { countTableItems } from "./commonApi";
import { GetDataLogReq } from "./dataLogApi";

interface GetDocumentItemsReq extends Pagination {
  bimFileId?: string;
  documentCategoryId?: string;
  templateId?: string;
  userAssigned?: string;
  level?: string;
  isDeleted?: boolean;
  shouldCache?: boolean;
  signal?: AbortSignal;
  isNoAreas?: boolean;
}
const path = "/v2/document-items";
const DEFAULT_LIMIT = 1000;

const getItemList = async (
  params: GetDocumentItemsReq
): Promise<ApiResponse<DocumentItem[]>> => {
  const { signal, shouldCache, ...rest } = params;

  return axiosECS.get(path, {
    params: { ...rest, limit: rest.limit || DEFAULT_LIMIT },
    signal,
  });
};

export const createItem = async (
  item: DocumentItemDTO
): Promise<ApiResponse<DocumentItemDTO>> => {
  const now = new Date();
  item.createdAt = item.updatedAt = now;

  const body = validateBodyReq(item);
  if (body.images?.length) {
    body.images = (body.images || []).filter(
      (img: any) => !img?.src?.includes("data:image/")
    );
  }

  return axiosECS.post(path, body);
};

export const updateItemArea = async (body: {
  bimFileId: string;
  version: string;
}) => {
  return axiosECS.post(`${path}/item-area`, body);
};

export const updateItem = async (
  item: Partial<DocumentItemDTO>
): Promise<ApiResponse<DocumentItemDTO>> => {
  item.updatedAt = item.updatedAt || new Date();

  const body = validateBodyReq(item);
  if (body.images?.length) {
    body.images = (body.images || []).filter(
      (img: any) => !img?.src?.includes("data:image/")
    );
  }

  delete body?.["isLoadingUpdateImage"];

  return axiosECS.patch(path, body);
};

export const deleteItemList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, {
    data: { ids: validateListString(ids) },
  });
};

export const handleGetItemList = async (params: GetDocumentItemsReq) => {
  params.paging = params.paging || "offset";
  const documentItems =
    params.paging === "cursor"
      ? await getItemListWithCursor(params)
      : await getItemListWithOffset(params);

  if (params.shouldCache) {
    await handleCacheItems({ documentItems });
  }

  return documentItems;
};

const getItemListWithCursor = async (params: GetDocumentItemsReq) => {
  const documentItems: DocumentItem[] = [];

  let cursor: string | undefined;
  do {
    const { data, pagination } = await getItemList({
      ...params,
      paging: "cursor",
      cursor,
    });
    cursor = pagination?.cursor;

    if (data.length) {
      documentItems.push.apply(documentItems, data);
    }
  } while (cursor);

  return documentItems;
};

const getItemListWithOffset = async (params: GetDocumentItemsReq) => {
  const documentItems: DocumentItem[] = [];
  const res = await countTableItems({
    path,
    params: {
      shouldCache: params.shouldCache,
      isNoAreas: params.isNoAreas,
      level: params.level,
      bimFileId: params.bimFileId,
      userAssigned: params.userAssigned,
      isDeleted: params.isDeleted,
      documentCategoryId: params.documentCategoryId,
      templateId: params.templateId,
      limit: params?.limit ? +params.limit : DEFAULT_LIMIT,
    },
  });

  const totalItem = res?.data?.totalItem;
  const totalPage = res?.data?.totalPage;
  const isOnline = getNetworkStatus();

  const promise = async (page: number) => {
    const { data } = await getItemList({
      ...params,
      page,
      paging: "offset",
      total: totalPage,
    });

    if (data?.length) documentItems.push.apply(documentItems, data);
  };

  if (totalItem && totalPage) {
    if (!isOnline) {
      const pages = Array.from({ length: totalPage }, (_, i) => i + 1);
      for await (const page of pages) {
        await promise(page);
      }
    } else {
      await Promise.all(
        Array.from({ length: totalPage }, (_, i) => i + 1).map(async (page) =>
          promise(page)
        )
      );
    }
  }

  return documentItems;
};

const handleCacheItems = async ({
  documentItems,
}: {
  documentItems: DocumentItem[];
}) => {
  let requests: Promise<any>[] = [];
  const isCacheLogItem =
    documentItems?.length <= MAX_DOCUMENT_ITEMS_TO_CACHE_LOG;

  for (let i = 0; i < documentItems.length; i++) {
    const documentItem: DocumentItemDTO = documentItems?.[i];
    if (!documentItem) {
      continue;
    }

    const subItems = documentItem?.subItems || [];

    const images = subItems
      .map((sub) => sub.images)
      .filter((i) => !!i) as FileUploadInfo[];
    const attaches = subItems
      .map((sub) => sub.attaches || [])
      .flat(1)
      .filter((i) => !!i);

    if (!!images?.length) {
      for (let j = 0; j < images!.length; j++) {
        images?.[j] &&
          requests.push(presignedAndDownloadFileS3(images![j].src, true));
      }
    }
    if (!!attaches?.length) {
      for (let j = 0; j < attaches!.length; j++) {
        attaches?.[j] &&
          requests.push(presignedAndDownloadFileS3(attaches![j], true));
      }
    }
    const isSelfInspection = isSelfInspectionTemplate(
      documentItem.documentType
    );
    if (subItems.length && !isSelfInspection) {
      for (let i = 0; i < subItems!.length; i++) {
        const subItem = subItems[0];
        const isUpdatedData = checkDocumentItemUpdatedData(subItem as any);
        isUpdatedData &&
          requests.push(
            dataLogApi.handleGetAllDataLogList({
              subItemId: subItem.id,
              shouldCache: true,
            })
          );
      }
    }

    if (isCacheLogItem) {
      const params: GetDataLogReq = {
        itemId: documentItem.id!,
        shouldCache: true,
      };
      if (isSelfInspection) {
        params.documentType = documentItem.documentType;
      }

      requests.push(dataLogApi.handleGetAllDataLogList(params));
    }

    if (requests.length >= 20) {
      await Promise.all(requests);
      await sleep(50);
      requests = [];
    }
  }

  await Promise.all(requests);
};

export const createSubItem = async (
  item: DocumentSubItem
): Promise<ApiResponse<DocumentSubItem>> => {
  const now = new Date();
  item.createdAt = item.updatedAt = now;

  const body = validateBodyReq(item);
  if (body.images?.length) {
    body.images = (body.images || []).filter(
      (img: any) => !img?.src?.includes("data:image/")
    );
  }

  return axiosECS.post(`${path}/sub-items`, body);
};

export const updateSubItem = async (
  item: DocumentSubItem
): Promise<ApiResponse<DocumentSubItem>> => {
  item.updatedAt = item.updatedAt || new Date();

  const body = validateBodyReq(item);
  if (body.images?.length) {
    body.images = (body.images || []).filter(
      (img: any) => !img?.src?.includes("data:image/")
    );
  }

  return axiosECS.patch(`${path}/sub-items`, body);
};

export const deleteSubItemList = async ({
  ids,
  isSoftDelete = false,
  requestId,
}: {
  ids: string[];
  isSoftDelete: boolean;
  requestId?: string;
}): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(`${path}/sub-items`, {
    data: { ids: validateListString(ids), isSoftDelete, requestId },
  });
};
